import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
} from "@mui/material";
import { DialogTitle } from "@tiny/mui";
import { DialogContentProvider } from "../DialogContent/DialogContent.context";
import useDialog from "./useDialog";
import { useDialogManager } from "./DialogManager.context";

const DialogManager = () => {
  const { dialogVisible, showDialog, hideDialog } = useDialog();
  const { requestedDialogConfig, clearDialogConfig } = useDialogManager();
  const {
    id: dialogId,
    overrides = {},
    titleProps,
    contentProps,
    actionProps,
    contextData = {},
    onClose,
  } = requestedDialogConfig ?? {};

  React.useLayoutEffect(() => {
    if (dialogId) {
      showDialog();
    } else {
      hideDialog();
    }
  }, [dialogId, showDialog, hideDialog]);

  const closeDialogHandler = React.useCallback(
    (event: object, reason = "") => {
      if (
        overrides.disableEscapeKeyDown &&
        (reason === "escapeKeyDown" || reason === "backdropClick")
      ) {
        return;
      }
      hideDialog();
      setTimeout(() => {
        clearDialogConfig();
        if (onClose) {
          onClose();
        }
      }, 100);
    },
    [hideDialog, onClose, clearDialogConfig, overrides]
  );

  return (
    <div>
      <Dialog
        id="MainDialogManager"
        open={dialogVisible}
        onClose={closeDialogHandler}
        disableEscapeKeyDown={false}
        {...overrides}
      >
        <DialogContentProvider value={contextData}>
          {titleProps ? (
            <DialogTitle
              {...titleProps}
              onClose={() => closeDialogHandler({})}
            />
          ) : null}
          <DialogContent
            id="dialog-content"
            {...(contentProps as DialogContentProps)}
            dividers
          />
          {actionProps ? (
            <DialogActions id="dialog-actions" {...actionProps} />
          ) : null}
        </DialogContentProvider>
      </Dialog>
    </div>
  );
};

export { DialogManager };
