import React from "react";

import { Box, Divider } from "@mui/material";
import { EmptyState } from "../../../components";
import { Search } from "@tiny/icons";
import TemplateSection from "../TemplateSection";
import { CustomCodeTabProps } from "./CustomCodeTab.types";
import { useCustomCodeTab } from "./CustomCodeTab.hooks";
import { CustomCodeTabProvider } from "./CustomCodeTab.context";
import CustomTemplateOptions from "./CustomTemplateOptions";
import CustomCodeHeader from "./CustomCodeHeader";
import UploadDialog from "../../UploadDialog";
import useBreakpoints from "../../../hooks/useBreakpoints";

function CustomCodeTab(props: CustomCodeTabProps) {
  const customCodeTabView = useCustomCodeTab(props);

  const {
    activeFilter,
    total,
    hasMore,
    existTemplatesForAccount,
    templates,
    isSelected,
    toggleSelect,
    isEmpty,
    emptyStateMessage,
    showPasteYourCode,
    openImportHtml,
    importHtmlVisible,
    closeImportHtml,
    searchKeyword,
    uploadImportHtml,
    isLoading,
  } = customCodeTabView;

  const { mdUp } = useBreakpoints();

  return (
    <CustomCodeTabProvider value={customCodeTabView}>
      {mdUp && (
        <Box p={6} pb={0}>
          <CustomTemplateOptions
            onImportHtml={openImportHtml}
            onPasteYourCode={showPasteYourCode}
          />
        </Box>
      )}
      {existTemplatesForAccount && (
        <Box pt={6}>
          <CustomCodeHeader />
          {!mdUp && <Divider />}
        </Box>
      )}
      <Box p={mdUp ? 6 : 0}>
        {isEmpty ? (
          <>
            {searchKeyword && (
              <Box bgcolor="grey.50" pt={10} pb={10}>
                <EmptyState
                  title="No templates found"
                  message={emptyStateMessage}
                  icon={<Search />}
                  iconColor="primary"
                  circleColor="white"
                  align="center"
                />
              </Box>
            )}
          </>
        ) : (
          <TemplateSection
            title={activeFilter}
            total={total}
            hasMore={hasMore}
            saved
            templates={templates}
            columnSize={2}
            refetch={false}
            selectable
            isSelected={isSelected}
            toggleSelect={toggleSelect}
            loading={isLoading}
          />
        )}
      </Box>
      <UploadDialog
        accept="text/html"
        open={importHtmlVisible}
        onClose={closeImportHtml}
        title="Import HTML files"
        onUpload={uploadImportHtml}
      />
    </CustomCodeTabProvider>
  );
}

export default CustomCodeTab;
