import React from "react";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import {
  TextField,
  MenuItem,
  IconButton,
  Box,
  Select,
  Typography,
  Button,
} from "@mui/material";
import { ChevronDown, PlusCircleOutline, TrashFull } from "@tiny/icons";
import { mapSocialIconsByType, socialOptions } from "../brandkit.utils";

function SimpleLinkFields() {
  const { register, control, watch, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "socialLinks",
  });

  const container = React.useRef<HTMLAreaElement>(null);
  const sociallinks = watch("socialLinks");

  const selectedTypes = sociallinks.map(
    (socialLinkField: any) => socialLinkField?.type
  );

  const availableOptions = socialOptions?.filter(
    (option) => !selectedTypes?.includes(option?.id)
  );

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        px: { xs: 4, md: 6 },
        py: { xs: 4, md: 4 },
        borderRadius: 2,
        mb: 5,
        border: `0.5px solid ${theme.palette.grey[60]}`,
      })}
    >
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h4" color="grey.100" sx={{ mb: 4 }}>
          Social
        </Typography>
        <Button
          variant="text"
          onClick={() => {
            append({ type: "", url: "" });
          }}
          disabled={sociallinks?.length === socialOptions?.length}
          endIcon={<PlusCircleOutline />}
          id="brand-kit-add-social-cta"
        >
          Add new
        </Button>
      </Box>
      <Box
        display="grid"
        gap={{ xs: 1, sm: 4 }}
        gridTemplateColumns={{ xs: "repeat(1, 1fr)", sm: "repeat(12, 1fr)" }}
      >
        {fields?.map((socialLinkField, index) => (
          <Box
            key={socialLinkField?.id}
            gridColumn={{ xs: "span 1", sm: "span 6" }}
          >
            <Box
              border={1}
              borderColor="grey.500"
              display="flex"
              alignItems="center"
              ref={container}
              sx={{
                mb: 2,
                borderRadius: 2,
                pr: 0.5,
              }}
            >
              <Controller
                name={`socialLinks.${index}.type`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={(e) => {
                      const val =
                        socialOptions.find((so) => so?.id === e.target?.value)
                          ?.url || "";
                      setValue(`socialLinks.${index}.url`, val);
                      onChange(e);
                    }}
                    renderValue={(selected) => (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {selected && selected?.length ? (
                          mapSocialIconsByType[selected as string]
                        ) : (
                          <ChevronDown />
                        )}
                      </Box>
                    )}
                    defaultValue=""
                    variant="standard"
                    required
                    disableUnderline
                    displayEmpty
                    sx={{
                      "& .MuiSelect-select": {
                        px: "0.5rem !important",
                        height: "100%",
                        width: "25px",
                      },
                    }}
                    IconComponent={() => null}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: (container.current?.clientWidth || 300) / 2,
                      },
                      PaperProps: {
                        sx: {
                          width: container.current?.clientWidth || 300,
                        },
                      },
                    }}
                  >
                    {availableOptions.map((option) => (
                      <MenuItem key={option?.id} value={option?.id}>
                        {mapSocialIconsByType[option?.id]}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {option?.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <TextField
                fullWidth
                label="Social Link"
                {...register(`socialLinks.${index}.url`)}
                required
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => remove(index)}>
                      <TrashFull />
                    </IconButton>
                  ),
                }}
                sx={() => ({
                  "& .MuiFormLabel-root": {
                    backgroundColor: "white",
                    px: 2,
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    backgroundColor: "white",
                    px: 2,
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      borderLeft: "1px solid",
                      borderColor: "grey.500",
                      borderRadius: 0,
                    },
                    "&:hover fieldset": {
                      border: "none",
                      borderLeft: "1px solid",
                      borderColor: "grey.500",
                      borderRadius: 0,
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                      borderLeft: "1px solid",
                      borderColor: "grey.500",
                      borderRadius: 0,
                    },
                  },
                })}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default SimpleLinkFields;
