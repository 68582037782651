import * as React from "react";
import { Typography } from "@mui/material";

function CharactersCounter(charCount: number) {
  return (
    <Typography
      variant="label2"
      sx={{
        fontWeight: 600,
        p: 0,
        ml: 1,
        mt: 1,
        color: "grey.100",
        lineHeight: 1,
      }}
    >
      {charCount}/1000
    </Typography>
  );
}

export default CharactersCounter;
