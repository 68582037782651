import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path d="M9 9H7V11H9V9Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M11 9H13V11H11V9Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M17 9H15V11H17V9Z" xmlns="http://www.w3.org/2000/svg" />
    <path
      d="M3 5V21L7.8 17.4C8.14582 17.1396 8.56713 16.9992 9 17H19C20.1046 17 21 16.1046 21 15V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5ZM5 17V5H19V15H8.334C7.90107 14.9988 7.47964 15.1393 7.134 15.4L5 17Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "MessageWriting"
);
