import React from "react";
import { TextField, Tooltip } from "@mui/material";
import { TruncatedTextFieldProps } from "@/components/TruncatedTextField/types";

function TruncatedTextField(props: TruncatedTextFieldProps) {
  const { value } = props;
  const textRef = React.useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  React.useEffect(() => {
    const isTextOverflowing =
      textRef.current!.scrollWidth > textRef.current!.clientWidth;
    setIsOverflowing(isTextOverflowing);
  }, [value]);

  return (
    <Tooltip
      title={isOverflowing ? value : ""}
      componentsProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              padding: 2,
            },
          },
        },
      }}
    >
      <TextField
        InputProps={{
          inputProps: {
            ref: textRef,
            style: {
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          },
        }}
        {...props}
      />
    </Tooltip>
  );
}

export default TruncatedTextField;
