import { Box, Button, Paper, Typography } from "@mui/material";
import { Info, ShortRight } from "@tiny/icons";
import * as React from "react";
import {
  variantBasedBackgroundColor,
  variantBasedButtonColor,
  variantBasedIconColor,
  variantBasedTextColor,
} from "./helpers";
import { TooltipContentProps } from "./types";

function TooltipContent(props: TooltipContentProps, ref: any) {
  const { message, variant, onClose, noInfoIcon } = props;
  return (
    <Paper
      elevation={3}
      ref={ref}
      sx={{ backgroundColor: variantBasedBackgroundColor(variant) }}
    >
      <Box display="flex" flexDirection="column" p={4} borderRadius={4}>
        {!noInfoIcon && (
          <Info
            fontSize="small"
            sx={{ color: variantBasedIconColor(variant) }}
          />
        )}
        <Typography variant="p2" color={variantBasedTextColor(variant)} py={2}>
          {message}
        </Typography>
        <Button
          variant="text"
          endIcon={<ShortRight />}
          onClick={onClose}
          sx={{
            color: variantBasedButtonColor(variant),
            fontWeight: 400,
            m: 0,
            p: 0,
            lineHeight: 1,
            flexBasis: "10%",
            alignSelf: "flex-start",
          }}
        >
          Got it
        </Button>
      </Box>
    </Paper>
  );
}

export default React.forwardRef(TooltipContent);
