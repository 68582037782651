import { ActionMenu } from "@/components/ActionMenu/types";
import { metricMapper } from "@/components/MetricCard/metric.mapper";
import { fetchApi } from "@tiny/utils";
import { useDialogInvokers, useDrawerInvokers } from "@tiny/lib";
import { GetAutomationsOverviewResponse } from "@/types/api/automations";
import { useStatisticsDialog } from "@/hooks/statistics.hooks";
import React from "react";
import {
  useNavigate,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RootLoader } from "@/types/RootLoader";
import AutomationPreview from "./AutomationPreview";

function useAutomationActionMenu(
  id: string,
  infoDetails: string,
  name: string,
  enabled: boolean,
  editable: boolean,
  comingSoon?: boolean
) {
  const { showStatisticsDialog } = useStatisticsDialog();
  const navigate = useNavigate();

  const { showDrawer } = useDrawerInvokers();
  const [searchParams] = useSearchParams();
  const { requestMediumDialog, exitDialog } = useDialogInvokers();
  const sm = useMediaQuery("(max-width:600px)");

  const { accountInfo } = useRouteLoaderData("root") as RootLoader;

  const showDialogBox = (description: string, title: string) => {
    requestMediumDialog({
      titleProps: { children: title, variant: "h3" },
      contentProps: {
        sx: { pb: 4 },
        children: (
          <Typography variant="p1" mt={4}>
            {description}
          </Typography>
        ),
      },
      actionProps: {
        children: (
          <Box alignItems="flex-end" sx={{ display: "flex", columnGap: 4 }}>
            <Button
              disableElevation
              onClick={() => {
                exitDialog();
              }}
              variant="contained"
            >
              Ok
            </Button>
          </Box>
        ),
      },
    });
  };

  const handleRegenerateAutomation = () => {
    const showInfoMessage: boolean =
      name === "Abandoned Cart" ||
      name === "Product Review" ||
      name === "Thank You For Purchase";
    exitDialog();
    showDrawer({
      children: (
        <AutomationPreview
          name={name}
          automationId={id}
          showRegenerate={editable}
          showInfoMessage={showInfoMessage}
          regenerate
          enabled={enabled}
        />
      ),
    });
  };

  const getAutomationsReports = () =>
    fetchApi<GetAutomationsOverviewResponse>(
      `/reportservice/automation/${id}/overview`,
      {
        method: "GET",
      }
    );

  const clickInfoHandler = async () => {
    const automationReportsData = await getAutomationsReports();
    const automationReportsMapped = metricMapper(
      automationReportsData,
      "specificAutomationInfoMessages",
      accountInfo
    );
    showStatisticsDialog(
      infoDetails,
      automationReportsMapped ?? [],
      name,
      () =>
        navigate(
          `/../main/dashboard/generated-revenue/orders?activities=${id}`
        ),
      "automations-overview-information-see-reports"
    );
  };

  const canBeChanged = !comingSoon && !enabled;

  const automationMenu: ActionMenu = [
    {
      name: "Information",
      onClickAction: clickInfoHandler,
      id: "automations-overview-list-information",
    },
  ];

  if (canBeChanged) {
    if (editable) {
      automationMenu.splice(0, 0, {
        name: "Regenerate layout",
        onClickAction: handleRegenerateAutomation,
        id: "automations-overview-list-regenerate",
      });
      automationMenu.splice(1, 0, {
        name: "Edit",
        onClickAction: () => {
          if (sm) {
            return showDialogBox(
              "Editing is not supported in the mobile view.",
              "Cannot Edit"
            );
          }
          navigate(
            `/main/automations/${id}/edit/details${
              searchParams ? `?${searchParams.toString()}` : ""
            }`
          );
        },
        id: "automations-overview-list-edit",
      });
    }
  }

  return { automationMenu };
}

export default useAutomationActionMenu;
