import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <g clipPath="url(#clip0_14319_129441)" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9699 21.9711L16.9999 20.0001H5.99992C2.87429 20.0019 0.270809 17.6039 0.0162787 14.4887C-0.238251 11.3734 1.94144 8.5846 5.02592 8.07906L5.04492 8.04506L1.99992 5.00006L3.41492 3.58606L20.3849 20.5561L18.9709 21.9701L18.9699 21.9711ZM6.81292 9.81406L5.34792 10.0531C3.29204 10.3911 1.83993 12.2506 2.0103 14.3271C2.18066 16.4036 3.91643 18.0016 5.99992 18.0001H14.9999L6.81292 9.81406ZM21.7999 19.1441L20.3409 17.6861C21.5097 17.1027 22.1667 15.8302 21.9654 14.5395C21.7641 13.2488 20.7508 12.2368 19.4599 12.0371L18.1439 11.8371L17.8219 10.5461C17.3801 8.77679 16.1575 7.30449 14.4996 6.54498C12.8417 5.78547 10.9283 5.82119 9.29992 6.64206L7.82892 5.17306C10.0012 3.84537 12.6757 3.63088 15.0318 4.59539C17.3879 5.55991 19.1442 7.58825 19.7619 10.0581C21.7954 10.3711 23.4295 11.8979 23.8798 13.9054C24.3301 15.9129 23.5048 17.9914 21.7999 19.1431V19.1441Z"
        fill="#0F1526"
      />
    </g>
    <path
      d="M18.9699 21.9711L16.9999 20.0001H5.99992C2.87429 20.0019 0.270809 17.6039 0.0162787 14.4887C-0.238251 11.3734 1.94144 8.5846 5.02592 8.07906L5.04492 8.04506L1.99992 5.00006L3.41492 3.58606L20.3849 20.5561L18.9709 21.9701L18.9699 21.9711ZM6.81292 9.81406L5.34792 10.0531C3.29204 10.3911 1.83993 12.2506 2.0103 14.3271C2.18066 16.4036 3.91643 18.0016 5.99992 18.0001H14.9999L6.81292 9.81406ZM21.7999 19.1441L20.3409 17.6861C21.5097 17.1027 22.1667 15.8302 21.9654 14.5395C21.7641 13.2488 20.7508 12.2368 19.4599 12.0371L18.1439 11.8371L17.8219 10.5461C17.3801 8.77679 16.1575 7.30449 14.4996 6.54498C12.8417 5.78547 10.9283 5.82119 9.29992 6.64206L7.82892 5.17306C10.0012 3.84537 12.6757 3.63088 15.0318 4.59539C17.3879 5.55991 19.1442 7.58825 19.7619 10.0581C21.7954 10.3711 23.4295 11.8979 23.8798 13.9054C24.3301 15.9129 23.5048 17.9914 21.7999 19.1431V19.1441Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <defs xmlns="http://www.w3.org/2000/svg">
      <clipPath id="clip0_14319_129441">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
    <clipPath id="clip0_14319_129441" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="white" />
    </clipPath>
    <rect width="24" height="24" xmlns="http://www.w3.org/2000/svg" />
  </g>,
  "CloudOff"
);
