import TinyTable from "@/components/Table";
import { ViewOrderActivitiesCTA } from "@/routes/SourcesTableRevenue/SourcesTable/ViewOrderActivitiesCTA";
import React from "react";
import {
  GetGeneratedSourcesResponse,
  Sources,
} from "@/types/api/generatedRevenueSources";
import { Typography } from "@mui/material";
import { formatNumber } from "@tiny/utils/formatUtils";
import { toTitleCase } from "@tiny/utils";
import { useAsyncValue, useRouteLoaderData } from "react-router-dom";
import { usePageSort } from "@/components/PaginationRouted/usePagination.hook";
import {
  TableFormatted,
  TableHeaderCellsTypes,
} from "@/components/Table/table.types";
import { RootLoader } from "@/types/RootLoader";
import { GetAccountResponse } from "@/types/api/account";

const makeHeaderCellData = ({ settings }: { settings: GetAccountResponse }) => {
  const { currency } = settings;
  const currencySymbol = currency
    ? Number()
        .toLocaleString(undefined, { style: "currency", currency })
        .slice(0, 1)
    : "currency N/A";
  return [
    { propName: "activityName", label: "Name", width: "40%" },
    { propName: "activityType", label: "Type", width: "20%" },
    {
      propName: "totalAmount",
      label: `Revenue (${currencySymbol})`,
      width: "20%",
    },
    { propName: "totalOrders", label: "# Orders", width: "20%" },
  ] as TableHeaderCellsTypes<Sources>[];
};

function SourcesTable({
  selected,
  setSelected,
}: {
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const sources = useAsyncValue() as GetGeneratedSourcesResponse;

  const { accountInfo } = useRouteLoaderData("root") as RootLoader;

  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [currentSort, onChangeSort] = usePageSort("activityName,asc");

  const formattedSourcesRows = sources.content.map((source: Sources) => ({
    ...source,
    totalAmount: (
      <Typography variant="label2R" color="grey.80">
        {formatNumber(source.totalAmount)}
      </Typography>
    ),
    activityName: (
      <Typography variant="label2R" color="grey.100">
        {source.activityName}
      </Typography>
    ),
    activityType: toTitleCase(source.activityType),
  }));

  const headerCellData = makeHeaderCellData({ settings: accountInfo });

  const toggleSelectAll = () => {
    setIsAllSelected((s) => !s);
  };

  const isSelected = (source: TableFormatted<Sources>) =>
    selected.includes(source.activityId as string);

  const toggleSelection =
    ({
      totalRowsCount,
      pageRows,
    }: {
      totalRowsCount: number;
      pageRows: Sources[];
    }) =>
    (source: TableFormatted<Sources>) => {
      if (isAllSelected) {
        setIsAllSelected(false);
        const selectedIds = pageRows
          .filter((row) => row.activityId !== source.activityId)
          .map((row) => row.activityId);
        return setSelected(selectedIds);
      }
      setSelected((arr) =>
        isSelected(source)
          ? arr.filter((id) => id !== source.activityId)
          : [...arr, source.activityId as string]
      );
      if (selected.length === totalRowsCount - 1 && !isSelected(source)) {
        setIsAllSelected(true);
      }
    };

  React.useEffect(() => {
    if (isAllSelected) {
      setSelected([]);
    }
  }, [isAllSelected, setSelected]);

  return (
    <TinyTable
      headerCells={headerCellData}
      rows={formattedSourcesRows}
      rowsData={sources.content}
      options={{
        isSelectable: true,
        isSelected,
        selectHandler: toggleSelection({
          totalRowsCount: sources.totalElements,
          pageRows: sources.content,
        }),
        isAllSelected,
        selectAllHandler: toggleSelectAll,
        currentSort,
        onSort: onChangeSort,
        ActionCTA: ViewOrderActivitiesCTA,
      }}
    />
  );
}

export default SourcesTable;
