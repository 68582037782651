import * as React from "react";
import CampaignCard from "@/components/Campaign";
import PageSize from "@/components/PaginationRouted/PageSize";
import PageSort from "@/components/PaginationRouted/PageSort";
import TinyPagination from "@/components/PaginationRouted/Pagination";
import { useCampaignsRefetch } from "@/components/CampaignsList/useCampaignsRefetch";
import TextSearch from "@/components/TextSearch";
import type {
  Campaign as CampaignInfo,
  GetCampaignResponse,
} from "@/types/api/campaigns";
import { Box, Grid } from "@mui/material";
import RegenerateAll from "@/components/RegenerateAll";
import { useIsLargerThan } from "@tiny/theme/utils";
import WaitCampaignsGenerating from "@/components/CampaignsList/WaitCampaignsGenerating";
import NoCampaignsFoundMessage from "@/components/CampaignsList/NoCampaignsFoundMessage";
import { useTextSearch } from "@/components/PaginationRouted/usePagination.hook";
import { CampaignsContext } from "@/routes/Campaigns/Context";
import { useLocation } from "react-router-dom";
import { isPast } from "date-fns";
import AddNewCampaign from "../AddNewCampaign";
import CampaignsTable from "./CampaignsTable";

function CampaignsList({
  campaigns,
  component,
  showHeader = true,
}: {
  campaigns: GetCampaignResponse;
  component?: React.ReactNode;
  showHeader?: boolean;
}) {
  const [keyword] = useTextSearch("searchTextName");
  useCampaignsRefetch(campaigns.campaigns.content);
  const location = useLocation();
  const isAllCampaignFuture =
    campaigns.campaigns?.content?.filter((campaign) =>
      isPast(new Date(campaign.startTime))
    )?.length !== campaigns?.campaigns?.content?.length;
  const isDraftTab =
    location.pathname === "/main/campaigns/overview/draft-campaigns";
  const isRecurringTab =
    location.pathname === "/main/campaigns/overview/recurring-campaigns";
  const isStatisticsTab =
    location.pathname === "/main/campaigns/overview/statistics";

  const showRegenerate =
    isAllCampaignFuture && !isRecurringTab && !isStatisticsTab;

  const isMdUp = useIsLargerThan("md");
  const [currentHoveredCampaign, setCurrentHoveredCampaign] = React.useState<
    string | undefined
  >();

  const { view } = React.useContext(CampaignsContext);

  const onCampaignHover = (id: string) => {
    setCurrentHoveredCampaign(id);
  };
  const onCampaignUnhover = () => {
    setCurrentHoveredCampaign(undefined);
  };
  const isCampaignHovered = (id: string) => currentHoveredCampaign === id;

  const getEmptyStateDescription = () => {
    if (isDraftTab) {
      return `Please click on "Add New" Button to create a new campaign.`;
    }

    if (isRecurringTab) {
      return `No Recurring campaigns found`;
    }

    return `We are in the process of generating campaigns for you. Kindly hit the
    "regenerate" button after a few seconds.`;
  };

  return (
    <>
      {showHeader && (
        <Box display="flex" justifyContent="space-between" mb={5}>
          <TextSearch paramName="searchTextName" id="search-by-campaign-name" />
          <Box display="flex" gap={5}>
            {isMdUp && <AddNewCampaign />}
            {showRegenerate && (
              <>
                <RegenerateAll
                  method="put"
                  action={location.pathname}
                  confirmationMessage="Do you want to retrain tinyAlbert with updated Campaigns? Doing this may recreate some or all of your
          templates."
                  successMessage='You have successfully "Albertized" your campaigns.'
                  id="campaigns-overview-regenerate-all"
                />
              </>
            )}
            <PageSort
              defaultSortOption={
                !isAllCampaignFuture ? "startTime,desc" : "startTime,asc"
              }
            />
          </Box>
        </Box>
      )}
      {component}
      {campaigns.campaigns.empty && !keyword ? (
        <WaitCampaignsGenerating description={getEmptyStateDescription()} />
      ) : (
        ""
      )}
      {campaigns.campaigns.empty && keyword ? <NoCampaignsFoundMessage /> : ""}
      {!campaigns.campaigns.empty ? (
        <>
          {view === "CARD" ? (
            <Grid
              container
              id="campaign-cards-section"
              spacing={{ xs: 3, md: 5 }}
              columns={{ xs: 12, md: 12 }}
            >
              {campaigns.campaigns.content.map((campaign: CampaignInfo) => (
                <Grid item xs={12} md={4} key={campaign.id}>
                  <CampaignCard
                    {...campaign}
                    isCampaignHovered={isCampaignHovered}
                    onCampaignHover={onCampaignHover}
                    onCampaignUnhover={onCampaignUnhover}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <CampaignsTable rowsData={campaigns.campaigns.content} />
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={
              view === "CARD"
                ? {
                    mt: 5,
                  }
                : {
                    p: 4,
                    backgroundColor: "common.white",
                  }
            }
          >
            <PageSize />
            <TinyPagination pagesCount={campaigns.campaigns.totalPages} />
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export { CampaignsList };
