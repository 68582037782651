import React from "react";
import {
  FormHelperText as MuiFormHelperText,
  FormHelperTextProps,
} from "@mui/material";

function FormHelperText(props: FormHelperTextProps) {
  return (
    <MuiFormHelperText
      {...props}
      sx={{
        display: "inline",
        alignSelf: "baseline",
        minHeight: 10,
        lineHeight: "10px",
        marginBottom: 7,
        marginTop: 4,
        marginLeft: 10,
        padding: "0 4px",
        fontSize: 10,
        background: "transparent",
        borderRadius: 2,
      }}
    />
  );
}

export default FormHelperText;
