import { TemplateProps } from "@/routes/Campaigns/Editor/types";
import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";

const templateEditorLoader = async ({
  params,
  request,
}: LoaderFunctionArgs) => {
  const templateDataPromise = fetchApi<TemplateProps>(
    "/campaignservice/campaign-layout",
    {
      signal: request.signal,
      method: "PUT",
      body: JSON.stringify({ campaignId: params.id }),
    }
  );
  return defer({ templateData: await templateDataPromise });
};
export default templateEditorLoader;
