import React from "react";
import { DateField, FormItem, Form, FieldGroup } from "../../../components";
import {
  DialogProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  FormControlLabel,
  Typography,
  Switch,
  Alert,
} from "@mui/material";
import { useScheduleDialog } from "./ScheduleDialog.hooks";
import { LoadingButton } from "@mui/lab";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { add } from "date-fns";
import { Controller } from "react-hook-form";
import DayOfWeekSelector from "../../../customComponents/DayOfWeekSelector";

function ScheduleDialog(props: DialogProps) {
  const { open, onClose } = props;

  const {
    campaignStatus,
    handleCancelScheduledCampaign,
    handleSchedule,
    handleSend,
    form,
    onChange,
    timezone,
    resetDialog,
    sending,
    scheduling,
    isRecurringEnabled,
    setIsRecurringEnabled,
  } = useScheduleDialog();
  const { control } = form;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      TransitionProps={{ onExit: resetDialog }}
    >
      <DialogTitle>Schedule campaign</DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        {form?.formState?.errors?.date && (
          <Alert severity="error">
            {form?.formState?.errors?.date?.message}
          </Alert>
        )}
        <Form form={form}>
          <Box
            sx={{
              display: "flex",
              mt: 5,
              flexDirection: "column",
              gap: 5,
            }}
          >
            {!isRecurringEnabled && (
              <Controller
                name="date"
                control={control}
                defaultValue={add(new Date(), { days: 0 })}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label="Scheduled Date"
                    renderInput={(props) => (
                      <TextField fullWidth size="small" {...props} />
                    )}
                    minDate={add(new Date(), { days: 0 })}
                    onChange={onChange}
                    value={value}
                    disablePast
                  />
                )}
              />
            )}
            <Box
              sx={{
                display: "flex",
                gap: 5,
                alignItems: "flex-start",
              }}
            >
              <Controller
                name="time"
                control={control}
                defaultValue={add(new Date(), { days: 0 })}
                render={({ field: { onChange, value } }) => (
                  <TimePicker
                    label="Time"
                    onChange={onChange}
                    value={value}
                    renderInput={(props) => (
                      <TextField fullWidth size="small" {...props} />
                    )}
                  />
                )}
              />

              <TextField
                fullWidth
                size="small"
                label="Time Zone"
                value={timezone}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              mt: 5,
              ml: 3,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <FormControlLabel
              control={
                <Typography variant="p2" color={"grey"}>
                  Recurring
                </Typography>
              }
              label={
                <Switch
                  checked={isRecurringEnabled}
                  onChange={(e) => {
                    setIsRecurringEnabled(e.target.checked);
                    if (e.target.checked) {
                      form.setValue("days", [
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                        "SATURDAY",
                        "SUNDAY",
                      ]);
                    } else {
                      form.setValue("days", undefined);
                    }
                  }}
                  size="small"
                  disabled={campaignStatus === "SCHEDULED"}
                />
              }
            />
            {isRecurringEnabled && (
              <Controller
                name="days"
                control={control}
                render={({ field }) => (
                  <DayOfWeekSelector
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
            )}
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="text"
          onClick={handleSend}
          loading={sending}
          disabled={scheduling || sending}
        >
          Send now
        </LoadingButton>
        <LoadingButton
          variant="contained"
          disabled={scheduling || sending}
          loading={scheduling}
          onClick={
            campaignStatus === "SCHEDULED"
              ? handleCancelScheduledCampaign
              : handleSchedule
          }
        >
          {campaignStatus === "SCHEDULED" ? "Cancel scheduled" : "Schedule"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ScheduleDialog;
