import { SenderDetailsRow } from "@/routes/AccountSettings/SenderDetails/SenderDetails.types";
import { Button, Theme } from "@mui/material";

function EditDetailsCTA({ tableRowData }: { tableRowData: SenderDetailsRow }) {
  return (
    <Button
      size="small"
      sx={(theme: Theme) => ({
        p: 0,
        fontWeight: theme.typography.fontWeightRegular,
        position: "absolute",
        right: theme.spacing(4),
        top: "50%",
        transform: "translateY(-50%)",
      })}
      variant="outlined"
    >
      Edit
    </Button>
  );
}

export { EditDetailsCTA };
