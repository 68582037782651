import React from "react";

import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
} from "@mui/material";
import { Info, Info2Outline, ShortRight } from "@tiny/icons";

export interface InfoTooltipProps {
  bgColor?: "grey" | "white";
  focusIcon?: React.ReactElement;
  icon?: React.ReactElement;
  modifiers?: any;
  size?: "medium" | "small" | "tiny";
  title: React.ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
  isError?: boolean;
  className?: string;
  placement?: TooltipProps["placement"];
}

function InfoTooltip(props: InfoTooltipProps): JSX.Element {
  const {
    title: titleProp,
    icon,
    focusIcon,
    modifiers,
    size,
    buttonText = "Got it",
    onButtonClick,
    className,
    placement = "bottom",
  } = props;

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const [open, setOpen] = React.useState(false);

  const refLabel = React.createRef<any>();

  const toolTipIcon = open && focusIcon ? focusIcon : icon;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onButtonClick && onButtonClick();
  };

  const handleCloseAway = (event: any) => {
    !refLabel?.current?.contains(event.target) && setOpen(false);
  };

  const handleKeyDownClose = (
    event: React.KeyboardEvent<HTMLButtonElement>
  ) => {
    if (
      event.key === "Enter" ||
      event.key === "Tab" ||
      event.key === "Escape"
    ) {
      setTimeout(() => {
        buttonRef.current?.focus();
      });
    }
  };

  const title = (
    <Box
      ref={refLabel}
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        p: 6,
        border: "1px solid #ccc",
        boxShadow: "rgba(0, 0, 0, 0.2) 1px 1px 6px",
      })}
    >
      <Info
        sx={(theme) => ({
          width: 24,
          height: 24,
          marginBottom: 4,
          color: theme.palette.grey[100],
        })}
      />
      <Typography m={0} mb={2} variant="label2" component="p" color="grey">
        {titleProp}
      </Typography>
      <Button
        color="primary"
        variant="text"
        onClick={handleClose}
        onKeyDown={handleKeyDownClose}
        endIcon={<ShortRight />}
        autoFocus
        sx={{
          height: "20px !important",
          padding: "0 !important",
          fontWeight: "normal",
          fontSize: "14px !important",
        }}
      >
        {buttonText}
      </Button>
      <a
        href="#"
        aria-hidden="true"
        style={{
          position: "absolute",
          opacity: 0,
        }}
      ></a>
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={handleCloseAway}>
      <Tooltip
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={open}
        placement={placement}
        title={title}
        PopperProps={{
          modifiers,
        }}
      >
        <IconButton onClick={handleOpen} ref={buttonRef} type="button">
          {toolTipIcon || <Info2Outline sx={{ fontSize: 20, m: 0 }} />}
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

export default InfoTooltip;
