import React from "react";

import { useQuitCampaign } from "./QuitCampaign.hooks";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Divider,
} from "@mui/material";

function QuitCampaign() {
  const {
    quitCampaignVisible,
    hideQuitCampaign,
    quitWithoutSave,
    quitWithSave,
  } = useQuitCampaign();

  return (
    <Dialog
      open={quitCampaignVisible}
      onClose={hideQuitCampaign}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Quit campaign</DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography variant="inherit" color="grey">
          Would you like to save the campaign?
        </Typography>
        <Divider
          sx={{
            mt: 4,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={quitWithoutSave}>
          No thanks
        </Button>
        <Button variant="contained" onClick={quitWithSave}>
          Yes, save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QuitCampaign;
