import { Backdrop, Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import * as React from "react";
import { PreviewType, TemplatePreviewProps } from "./types";
import { HtmlPreview } from "../HtmlPreview";
import { MobilePreview } from "../MobilePreview";

function TemplatePreview(props: TemplatePreviewProps) {
  const { html, css, isLoading = false } = props;
  const [value, setValue] = React.useState<PreviewType>("desktop");
  const handleTabChange = React.useCallback(
    (_: React.SyntheticEvent, newValue: PreviewType) => {
      setValue(newValue);
    },
    []
  );
  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", py: 4 }}>
        <Tabs value={value} onChange={handleTabChange} id="preview-tabs">
          <Tab value="desktop" label="Desktop" id="desktop-tab" />
          <Tab value="mobile" label="Mobile" id="mobile-tab" />
        </Tabs>
      </Box>
      <Box pb={4} pt={value === "mobile" ? 4 : 0} sx={{ position: "relative" }}>
        <TabPanel value="desktop">
          <HtmlPreview
            width="100%"
            height="31.25rem"
            contentWidth={50} /* as rem */
            html={html}
            css={css}
            scrollable
          />
        </TabPanel>
        <TabPanel
          value="mobile"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <MobilePreview
            width="16.25rem"
            height="31.25rem"
            contentWidth={22.5} /* as rem */
            html={html}
            css={css}
            scrollable
          />
        </TabPanel>
        <Backdrop
          sx={{
            color: "#fff",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </TabContext>
  );
}

export { TemplatePreview };
