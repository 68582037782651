import { AppLocalStorage } from "./localStorageMock";

const appLocalStorage = new AppLocalStorage();

function applyLocalStorageMock() {
  try {
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    window.localStorage;
  } catch {
    Object.defineProperty(window, "localStorage", {
      value: appLocalStorage,
    });
    Object.defineProperty(window, "sessionStorage", {
      value: appLocalStorage,
    });
  }
}

export { applyLocalStorageMock };
