import { FormControlLabel, Switch, Theme, Typography } from "@mui/material";
import { GlassHour } from "@tiny/icons";

interface FormStatusSwitcherProps {
  status: string;
  activeSwitch: boolean;
  onChangeSwitch: (_: any, isActivated: boolean) => void;
  id?: string;
}

function FormStatusSwitcher({
  status,
  activeSwitch,
  onChangeSwitch,
  id,
}: FormStatusSwitcherProps) {
  const switcherLabel = (active: boolean) =>
    active ? "Deactivate" : "Activate";
  const switcherLabelColor = (active: boolean, theme: Theme) =>
    active ? theme.palette.grey[100] : theme.palette.primary.main;

  let statusIcon;
  let statusLabel;

  switch (status) {
    case "GENERATE":
      statusIcon = (
        <GlassHour
          sx={(theme) => ({
            fill: theme.palette.fuchsia.main,
            width: theme.spacing(5),
            height: theme.spacing(5),
          })}
        />
      );
      statusLabel = "Generating...";
      break;
    default:
      statusIcon = (
        <FormControlLabel
          control={
            <Switch
              checked={activeSwitch}
              onChange={onChangeSwitch}
              name="active"
              color="primary"
              size="small"
              id={id}
            />
          }
          labelPlacement="start"
          label={switcherLabel(activeSwitch)}
          sx={(theme) => ({
            color: switcherLabelColor(activeSwitch, theme),
            ml: 0,
            gap: 2,
            fontSize: 14,
          })}
          className="activation-toggle"
        />
      );
      statusLabel = "";
      break;
  }
  return (
    <>
      {statusIcon}
      <Typography variant="p2">{statusLabel}</Typography>
    </>
  );
}

export { FormStatusSwitcher };
