import { usePageSort } from "@/components/PaginationRouted/usePagination.hook";
import TinyTable from "@/components/Table";
import { headerColumns } from "@/routes/Audience/Subscribers/tableConfig";
import { GetAudienceSubscribersResponse } from "@/types/api/subscribers";
import { Typography } from "@mui/material";
import { formatDate } from "@tiny/utils/dateUtils";
import { useAsyncValue } from "react-router-dom";

function SubscribersTable() {
  const subscribers = useAsyncValue() as GetAudienceSubscribersResponse["page"];
  const formattedRows = subscribers.content.map((s) => ({
    ...s,
    email: (
      <Typography variant="label2R" color="grey.90">
        {s.email}
      </Typography>
    ),
    status: (
      <Typography
        variant="label2R"
        color={s.status === "Unsubscribed" ? "red.125" : "primary.100"}
      >
        {s.status}
      </Typography>
    ),
    birthday: s.birthday ? (
      <Typography variant="label2R" color="grey.80">
        {formatDate(new Date(s.birthday), "dd MMM yyyy")}
      </Typography>
    ) : undefined,
  }));

  const [currentSort, onChangeSort] = usePageSort("email,asc");

  return (
    <TinyTable
      rows={formattedRows}
      rowsData={subscribers.content}
      headerCells={headerColumns}
      options={{
        currentSort,
        onSort: onChangeSort,
      }}
    />
  );
}

export { SubscribersTable };
