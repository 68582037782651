const variantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h4R: "h4",
  p1: "p",
  p2: "p",
  p3: "p",
  label1: "span",
  label1R: "span",
  label2: "span",
  label2R: "span",
  label3: "span",
  label3R: "span",
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
};

const defaultProps = {
  variantMapping,
};
export { defaultProps };
