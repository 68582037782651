import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M3 11V3H11V11H3ZM6 6V8H8V6H6Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M13 11V3H21V11H13ZM16 6V8H18V6H16Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M3 13V21H11V13H3ZM8 16V18H6V16H8Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M16 13H13V15H16V19H15V17H13V21H17V19H19V21H21V18H19V17H18V16H21V13H18V15H16V13Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "QrCode"
);
