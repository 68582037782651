import { SortDirection } from "@/components/Table/table.types";
import React from "react";
import { Button, Typography, useTheme } from "@mui/material";
import { LongDown, SortDown, SortUp } from "@tiny/icons";

export interface SortableHeaderCellProps {
  label: string;
  propName: string;
  isSortable?: boolean;
  isActive?: boolean;
  sortDirection: SortDirection;
  sortHandlerCallback: (updatedSort: string) => void;
}

function getReverseDirection(currentDirection: "asc" | "desc") {
  if (currentDirection === "asc") return "desc";
  return "asc";
}

function SortableHeaderCell(props: SortableHeaderCellProps) {
  const {
    isActive,
    isSortable,
    sortDirection,
    label,
    propName,
    sortHandlerCallback,
  } = props;

  const theme = useTheme();

  const [showSortingIcon, setShowSortingIcon] = React.useState(isActive);

  React.useEffect(() => {
    if (!isActive) {
      setShowSortingIcon(false);
    }
  }, [isActive]);

  const sortClickHandler = () => {
    const direction = isActive
      ? getReverseDirection(sortDirection)
      : sortDirection;
    if (isSortable) {
      sortHandlerCallback(`${propName},${direction}`);
    }
  };

  const handleShowingSortingIcon = () => {
    setShowSortingIcon((prevState) => !prevState);
  };

  const SortingIcon = sortDirection === "asc" ? SortDown : SortUp;

  return (
    <Button
      variant="text"
      onClick={sortClickHandler}
      style={{
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 1,
        textDecoration: "none",
      }}
      onMouseEnter={handleShowingSortingIcon}
      onMouseLeave={handleShowingSortingIcon}
    >
      <Typography
        color="grey.80"
        variant={isActive ? "label2" : "label2R"}
        fontWeight={600}
        sx={{ position: "relative", cursor: "pointer", whiteSpace: "nowrap" }}
      >
        {label}
        {(isSortable && label && showSortingIcon) || isActive ? (
          <LongDown
            sx={{
              marginLeft: 1,
              position: "absolute",
              width: theme.spacing(3),
              height: theme.spacing(3),
              transform: sortDirection === "asc" ? "" : "rotate(180deg)",
            }}
          />
        ) : null}
      </Typography>
    </Button>
  );
}

export default SortableHeaderCell;
