import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import {
  SelectField,
  SelectList,
  SimpleList,
  SimpleListItem,
} from "../../../../components";
import { ChevronBigRight } from "@tiny/icons";
import ListHeader from "../../../ListHeader/ListHeader";
import TagsDialog from "../../../TagsDialog";
import { filterOptions } from "../CustomCodeTab.helpers";
import { useCustomCodeHeader } from "./CustomCodeHeader.hooks";

function CustomCodeHeader() {
  const {
    activeFilter,
    allSelected,
    allSelectedDisabled,
    changeActiveFilter,
    closeFilters,
    closeMore,
    closeSectionsFilter,
    deleteSelected,
    deselectAll,
    deselectTag,
    filtersOpen,
    moreOpen,
    openMore,
    openSectionsFilter,
    search,
    sectionsFilterOpen,
    selectedTags,
    selectedIds,
    tags,
    toggleAll,
    updateSelectedTags,
    tempActiveFilter,
    setTempActiveFilter,
    applySectionFilter,
  } = useCustomCodeHeader();

  return React.useMemo(
    () => (
      <>
        <ListHeader
          allSelected={allSelected}
          allSelectedDisabled={allSelectedDisabled}
          bulkDeleteEnabled
          onDeleteClick={deleteSelected}
          onDeselectAll={deselectAll}
          onMoreMobile={openMore}
          onSearchChange={search}
          onTagDelete={deselectTag}
          onToggleAll={toggleAll}
          totalSelected={selectedIds.length}
          tags={selectedTags}
          actions={
            <Box width={140}>
              <FormControl size="small" fullWidth>
                <Select
                  fullWidth
                  //@ts-ignore
                  onChange={(e) => changeActiveFilter(e.target.value)}
                  value={activeFilter}
                  aria-label="Sort"
                >
                  {filterOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
        />
        <TagsDialog
          open={filtersOpen}
          onClose={closeFilters}
          onSave={updateSelectedTags}
          tags={tags}
          selected={selectedTags}
        />
        <Dialog open={moreOpen} onClose={closeMore} disableScrollLock>
          <DialogContent
            sx={{
              pt: 2,
              pb: 2,
            }}
          >
            <SimpleList>
              <SimpleListItem
                icon={<ChevronBigRight />}
                size="large"
                onClick={openSectionsFilter}
              >
                Section ({activeFilter})
              </SimpleListItem>
            </SimpleList>
          </DialogContent>
        </Dialog>
        <Dialog
          open={sectionsFilterOpen}
          onClose={closeSectionsFilter}
          disableScrollLock
        >
          <DialogTitle>Section</DialogTitle>
          <DialogContent
            sx={{
              pt: 2,
            }}
          >
            <Box mb={4}>
              <Divider />
            </Box>
            <FormControl size="small" fullWidth>
              <Select
                fullWidth
                //@ts-ignore
                onChange={(e) => setTempActiveFilter(e.target.value)}
                value={tempActiveFilter}
              >
                {filterOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mt={4}>
              <Divider />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" fullWidth onClick={applySectionFilter}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    ),
    [
      activeFilter,
      allSelected,
      allSelectedDisabled,
      applySectionFilter,
      changeActiveFilter,
      closeFilters,
      closeMore,
      closeSectionsFilter,
      deleteSelected,
      deselectAll,
      deselectTag,
      filtersOpen,
      moreOpen,
      openMore,
      openSectionsFilter,
      search,
      sectionsFilterOpen,
      selectedIds.length,
      selectedTags,
      setTempActiveFilter,
      tags,
      tempActiveFilter,
      toggleAll,
      updateSelectedTags,
    ]
  );
}

export default CustomCodeHeader;
