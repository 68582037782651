import React from "react";
import { Button, Grid, Box, Typography } from "@mui/material";

import { ShortRight, GlassHour } from "@tiny/icons";
import { SelectCardProps } from "./SelectCard.types";

function SelectCard(props: SelectCardProps) {
  const { onSelect, icon, disabled, title, description } = props;

  return (
    <Box
      p={5}
      border={1}
      borderColor={!disabled ? "grey.300" : "transparent"}
      borderRadius={4}
      sx={(theme) => ({
        bgcolor: disabled
          ? theme.palette.background.default
          : theme.palette.background.paper,
      })}
    >
      <Box display="flex" gap={5}>
        <Box>
          <Grid
            item
            flex="0 0 40px"
            sx={(theme) => ({
              bgcolor: theme.palette.background.default,
              p: 3,
              borderRadius: 999,
            })}
          >
            {icon}
          </Grid>
        </Box>
        <Grid item flex="1">
          <Typography
            variant="h4"
            component="strong"
            mt={0}
            mb={1}
            fontWeight="semiBold"
            color="primary"
          >
            {title}
          </Typography>
          <Typography variant="p2" component="p" mt={1} mb={2} color="grey">
            {description}
          </Typography>
          {disabled ? (
            <Button
              sx={{ p: 0 }}
              variant="text"
              endIcon={<GlassHour />}
              disabled
            >
              Coming soon
            </Button>
          ) : (
            <Button
              sx={{ p: 0 }}
              variant="text"
              endIcon={<ShortRight />}
              onClick={onSelect}
            >
              Select
            </Button>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default SelectCard;
