import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <g clipPath="url(#clip0_14319_129449)" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.73852e-08 14C-0.000366542 11.0623 2.12636 8.5564 5.025 8.07903C6.6298 5.2202 9.81642 3.62986 13.0657 4.06618C16.315 4.50249 18.9692 6.87713 19.763 10.058C22.3471 10.4551 24.189 12.7759 23.989 15.3826C23.7889 17.9894 21.6144 20.0019 19 20H6C2.68629 20 4.73852e-08 17.3137 4.73852e-08 14Z"
        fill="#0F1526"
      />
    </g>
    <path
      d="M4.73852e-08 14C-0.000366542 11.0623 2.12636 8.5564 5.025 8.07903C6.6298 5.2202 9.81642 3.62986 13.0657 4.06618C16.315 4.50249 18.9692 6.87713 19.763 10.058C22.3471 10.4551 24.189 12.7759 23.989 15.3826C23.7889 17.9894 21.6144 20.0019 19 20H6C2.68629 20 4.73852e-08 17.3137 4.73852e-08 14Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <defs xmlns="http://www.w3.org/2000/svg">
      <clipPath id="clip0_14319_129449">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
    <clipPath id="clip0_14319_129449" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="white" />
    </clipPath>
    <rect width="24" height="24" xmlns="http://www.w3.org/2000/svg" />
  </g>,
  "Cloud"
);
