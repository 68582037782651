import React from "react";
import { Box, Grid, TextField } from "@mui/material";
import { ContactInfoFormProps } from "@/components/ProfileForm/types";
import TruncatedTextField from "@/components/TruncatedTextField";

function ContactInfoForm(props: ContactInfoFormProps) {
  const { companyName, websiteUrl, country, state, city, address, zipCode } =
    props;

  return (
    <Box borderRadius={2} bgcolor="common.white" p={6}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TruncatedTextField
            fullWidth
            size="small"
            disabled
            label="Company name"
            value={companyName}
            id="company-name"
            name="companyName"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TruncatedTextField
            fullWidth
            size="small"
            id="website-url"
            value={websiteUrl}
            name="websiteUrl"
            disabled
            label="Website"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            fullWidth
            id="country"
            value={country}
            name="country"
            disabled
            label="Country"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            fullWidth
            id="state"
            value={state}
            name="state"
            disabled
            label="State/Province/Territory"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            fullWidth
            id="city"
            value={city}
            name="city"
            disabled
            label="City"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            fullWidth
            id="zip-code"
            value={zipCode}
            name="zipCode"
            disabled
            label="Zip code"
          />
        </Grid>
        <Grid item xs={12}>
          <TruncatedTextField
            size="small"
            fullWidth
            id="address"
            value={address}
            name="address"
            disabled
            label="Address"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactInfoForm;
