import { IssueCta } from "@/types/api/notifications";
import { Link as RouterLink } from "react-router-dom";
import useNotificationActionMapper from "@/components/Notifications/useNotificationActionMapper";
import { isLinkCta } from "@/components/Notifications/types";
import { Link, useTheme } from "@mui/material";
import { collapseAllNotifications } from "@/components/Notifications/notificationsClient";

function NotificationMessageCta({ meta }: { meta: IssueCta }) {
  const { ctaName } = meta;
  const { links, actions } = useNotificationActionMapper();
  const theme = useTheme();

  if (isLinkCta(meta)) {
    const link = links[meta.ctaAction];
    return (
      <RouterLink
        style={{ textDecoration: "none", color: theme.palette.primary.main }}
        to={link}
        onClick={collapseAllNotifications}
      >
        {ctaName}
      </RouterLink>
    );
  }

  const action = actions[meta.ctaAction];
  return (
    <Link
      variant="label2R"
      underline="none"
      sx={{ cursor: "pointer" }}
      onClick={() => {
        collapseAllNotifications();
        action();
      }}
    >
      {ctaName}
    </Link>
  );
}

export default NotificationMessageCta;
