import React from "react";
import { useDialogInvokers } from "@tiny/lib/DialogManager";
import { TinyCsvImporter } from ".";
import { TinyImporterProps } from "./TinyImporter.types";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const useTinyImporter = () => {
  const { requestLargeDialog } = useDialogInvokers();
  const emitGTM = (state: string) => {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ event: `tiny-csv-importer-${state}` });
    }
  };
  const proceedToImporter = ({
    onSuccess,
    importType,
    audienceName,
    audienceId,
  }: TinyImporterProps) => {
    emitGTM("open");
    requestLargeDialog({
      titleProps: { children: "Import Subscribers", variant: "h3" },
      contentProps: {
        children: (
          <TinyCsvImporter
            importType={importType}
            audienceId={audienceId}
            audienceName={audienceName}
            // onSuccess={onSuccess}
            onSuccess={(data: any) => {
              emitGTM("success");
              onSuccess(data);
            }}
          />
        ),
        dividers: true,
        sx: {
          border: "none",
        },
      },
      onClose: () => {
        emitGTM("close");
      },
    });
  };
  return { proceedToImporter };
};
