import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React from "react";
import { FilterItemCheckboxProps } from "./FilterItemCheckbox.types";

function FilterItemCheckbox(props: FilterItemCheckboxProps) {
  const { checked, onChange, label } = props;

  return (
    <Grid item xs={12} md={6}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.checked)
            }
          />
        }
        sx={{
          mx: 2,
        }}
        label={
          <Typography variant="p2" color="grey">
            {label}
          </Typography>
        }
      />
    </Grid>
  );
}

export default FilterItemCheckbox;
