import * as React from "react";
import { Box, Typography } from "@mui/material";
import ActionMenu from "@/components/ActionMenu";
import { FormHeaderProps } from "./types";
import useFormActionMenu from "./useFormActionMenu";

function FormHeader(props: FormHeaderProps) {
  const { active = false, name, infoDetails, id } = props;

  const { formMenu } = useFormActionMenu(name, infoDetails, id, active);

  return (
    <Box display="flex" width="100%">
      <Typography
        variant="h4"
        color={active ? "grey.100" : "grey.80"}
        sx={{ flex: 1 }}
        className="form-name"
      >
        {name}
      </Typography>

      <ActionMenu menuActions={formMenu} />
    </Box>
  );
}

export default FormHeader;
