import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path d="M13 6H11V9H13V6Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M11 10H13V14H11V10Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M13 15H11V18H13V15Z" xmlns="http://www.w3.org/2000/svg" />
    <path
      d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5ZM5 5L5 19H19V5H5Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "CombineCells"
);
