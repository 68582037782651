import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7ZM9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9C8.10457 9 9 8.10457 9 7Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M21 11.5C21 13.433 19.433 15 17.5 15C15.567 15 14 13.433 14 11.5C14 9.567 15.567 8 17.5 8C19.433 8 21 9.567 21 11.5ZM19 11.5C19 10.6716 18.3284 10 17.5 10C16.6716 10 16 10.6716 16 11.5C16 12.3284 16.6716 13 17.5 13C18.3284 13 19 12.3284 19 11.5Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M10 21V17C10 15.3431 8.65685 14 7 14C5.34315 14 4 15.3431 4 17V21H2V17C2 14.2386 4.23858 12 7 12C9.76142 12 12 14.2386 12 17V21H10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M20 20.5V21H22V20.5C22 18.0147 19.9853 16 17.5 16C15.0147 16 13 18.0147 13 20.5V21H15V20.5C15 19.1193 16.1193 18 17.5 18C18.8807 18 20 19.1193 20 20.5Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "GroupAlt"
);
