import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { fetchApi } from "@tiny/utils";
import { Audience } from "@/types/api/audience";
import { useForm, FormProvider } from "react-hook-form";
import TinyImporterSample from "./TinyImporterSample/TinyImporterSample";
import { useTinyImporter } from "./useTinyImporter";
import { TinyImporterProps } from "./TinyImporter.types";

interface FormValue {
  name: string;
}

export default function TinyImporterDialog({ onSuccess }: TinyImporterProps) {
  const [allowedToSend, setAllowedToSend] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const methods = useForm<FormValue>();
  const { proceedToImporter } = useTinyImporter();

  const AudienceCheckExist = async (name: string) => {
    setIsLoading(true);
    const response = await fetchApi<Audience>(
      `/customerservice/audience/name?name=${name}`,
      {
        method: "GET",
      }
    );
    setIsLoading(false);
    return response.id !== undefined;
  };

  const handleSubmit = React.useCallback(
    async (data: FormValue) => {
      try {
        const isAudience = await AudienceCheckExist(data.name);
        if (isAudience) {
          methods.setError("name", {
            type: "manual",
            message: "Audience name already exists",
          });
        } else {
          proceedToImporter({
            importType: "CREATE",
            onSuccess,
            audienceName: data.name,
          });
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    },
    [methods.watch("name")]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Box
          justifyContent="center"
          alignItems="start"
          display="flex"
          flexDirection="column"
          my={4}
        >
          <TextField
            placeholder="Give your audience an easy to identify name"
            label="Audience Name"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            {...methods.register("name", {
              // limit set for 140 characters and only alphanumerics. Special symbols failing the api call are blocked
              pattern: {
                value: /^(?![$#])[a-zA-Z0-9#$\s]{0,140}$/,
                message:
                  "Invalid Input, name should be upto 140 alphanumeric characters and should not contain special characters like # $ & [ ] { } | \\ ^",
              },
            })}
          />
          {methods.formState.errors.name && (
            <Typography
              color="error"
              sx={{
                fontSize: 12,
                fontWeight: 400,
                lineHeight: 1.5,
                letterSpacing: "0.00938em",
              }}
            >
              {methods.formState.errors.name.message}
            </Typography>
          )}
        </Box>
        <TinyImporterSample />
        <Divider sx={{ marginTop: 4 }} />

        <Box justifyContent="space-between" display="flex" mt={4} gap={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allowedToSend}
                onChange={(e) => setAllowedToSend(e.target.checked)}
                sx={{
                  mx: 2,
                }}
              />
            }
            label="I have the permission to send to these recipients."
            sx={{
              mt: 4,
              fontSize: 14,
            }}
          />
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            disabled={!allowedToSend}
            type="submit"
          >
            Continue
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
}
