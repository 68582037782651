import { EndpointConfig } from "../types";
import { API_BASE_URL, APP_ID } from "../../envVars";
import { getEinsteinEndpointConfig } from "./einsteinEndpointsConfig";
import { prepareRequestHeaders } from "./requestHeaders";

export function prepareRequest(url: string): {
  url: string | URL;
  requestConfig?: RequestInit;
} {
  let endpointConfig: EndpointConfig = { path: url };
  let baseUrl = "";
  if (url.startsWith("/")) {
    baseUrl = API_BASE_URL;
    switch (APP_ID) {
      case "einstein":
        endpointConfig = getEinsteinEndpointConfig(url);
        break;
      default:
        break;
    }
  }
  return {
    url: `${baseUrl}${endpointConfig.path}`,
    requestConfig: { headers: prepareRequestHeaders(endpointConfig) },
  };
}
