import { Box, Button } from "@mui/material";
import TextSearch from "@/components/TextSearch";
import PageFilter from "@/components/PageFilter";
import React from "react";
import { ordersFilterOptions } from "@/routes/OrdersTableRevenue";
import { useNavigate } from "react-router-dom";
import { usePageFilter } from "@/hooks/usePageFilter";
import ExportOrdersButton from "@/routes/OrdersTableRevenue/ExportOrdersButton";
import OrdersChips from "@/routes/OrdersTableRevenue/OrdersChips";
import { ChevronLeft } from "@tiny/icons";

function OrdersTableRevenueHeader() {
  const [period, onChangeFilter] = usePageFilter("ALL_TIME");
  const navigate = useNavigate();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={5}
        gap={{ xs: 2, sm: 0 }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <TextSearch id="search-orders" paramName="searchTextName" />
        <Box
          display="flex"
          gap={5}
          justifyContent={{ xs: "space-between", sm: "flex-start" }}
          sx={{
            flexWrap: { xs: "wrap-reverse", sm: "no-wrap" },
          }}
        >
          <ExportOrdersButton />
          <Button
            variant="outlined"
            startIcon={<ChevronLeft />}
            onClick={() => navigate(-1)}
          >
            Go back to sources
          </Button>
          <PageFilter
            sx={{ width: { xs: "100%", sm: "inherit" } }}
            filterOptions={ordersFilterOptions}
            onChangeFilter={onChangeFilter}
            value={period}
          />
        </Box>
      </Box>
      <OrdersChips />
    </>
  );
}

export default OrdersTableRevenueHeader;
