import React from "react";
import { useCampaignPageView } from "../CampaignPage.context";
import { QuitCampaignView } from "./QuitCampaign.types";
import { useConfigView } from "../../Config.context";

export function useQuitCampaign(): QuitCampaignView {
  const {
    quitCampaignVisible,
    hideQuitCampaign,
    saveStep,
    requireSave,
    campaignData,
    saveCampaign,
  } = useCampaignPageView();
  const { config } = useConfigView();

  const [onSave, setOnSave] = React.useState(false);

  const quit = React.useCallback(() => {
    if (config.exitWithoutSaving) {
      config.exitWithoutSaving();
    }
    return;
  }, []);

  const quitWithoutSave = React.useCallback(() => {
    setOnSave(false);
    hideQuitCampaign();
    quit();
  }, [hideQuitCampaign, quit]);

  const quitWithSave = React.useCallback(async () => {
    setOnSave(true);
    console.log(campaignData);
    await saveCampaign(campaignData);
    hideQuitCampaign();
    saveStep();
    quit();
  }, [hideQuitCampaign, saveStep, campaignData]);

  React.useEffect(() => {
    if (
      onSave &&
      (Object.keys(requireSave).length === 0 ||
        Object.values(requireSave).filter((a) => a != null).length === 0)
    ) {
      setOnSave(false);
      quit();
    }
  }, [onSave, quit, requireSave]);

  return {
    quitCampaignVisible,
    hideQuitCampaign,
    quitWithoutSave,
    quitWithSave,
  };
}
