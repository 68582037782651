interface TemplateProps {
  html: string;
  css: string;
  subjectLine: string;
  preview?: string;
}
export enum NavigationChipsItemType {
  active = "ACTIVE",
  inactive = "INACTIVE",
  complete = "COMPLETE",
}

interface NavigationChipsItem {
  title: string;
  type: NavigationChipsItemType;
  navigate: string;
}
interface TopBarProps {
  subject: string;
  ctaAction: CallableFunction;
  cancelAction: CallableFunction;
  ctaText: string;
  navigationChips: Array<NavigationChipsItem>;
  type?: "button" | "submit" | "reset" | undefined;
  ctaId?: string;
  startPath?: string;
  ctaActivateAction?: CallableFunction;
}
export type { TemplateProps };
export type { TopBarProps };
