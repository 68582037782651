import { ValidateResult } from "react-hook-form";
import tinycolor from "tinycolor2";
import { isBlankString } from "./stringUtils";

export function validateColor(value: string): ValidateResult {
  const color = tinycolor(value);
  if (value && !color.isValid()) {
    return "Invalid color";
  }
}

export function validateNumber(value: number | null | string): ValidateResult {
  if (typeof value === "string") {
    return "Invalid number";
  }
}

export function validateEmail(email: string): ValidateResult {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(String(email).toLowerCase())) {
    return "Invalid email.";
  } else {
    const groupsRe = /^(.+)@(.+)$/;
    const match = email.match(groupsRe);
    if (match && match[1].length > 64) {
      return "Email address is too long. It should be less than 65 characters before '@'";
    } else if (match && match[2].length > 255) {
      return "Email address is too long. It should be less than 256 characters after '@'";
    }
  }
}

export function validateEmailList(emails: string): ValidateResult {
  const splited = emails.split(",");
  for (const email of splited) {
    const error = validateEmail(email.trim());
    if (error) {
      return error;
    }
  }
}

export function validatePassword(password: string): ValidateResult {
  if (
    !hasLowercaseChar(password) ||
    !hasSpecialChar(password) ||
    !hasUpercaseChar(password) ||
    !hasMinLength(password) ||
    !hasMaxLength(password) ||
    !hasNumber(password)
  ) {
    return "A password must consist of letters, numbers, special characters, at least one capital letter and be no shorter than 8 symbols. For example, Toronto21!";
  }
}

export function validateName(value: string): ValidateResult {
  if (hasSpecialChar(value)) {
    return "Special characters are not allowed";
  }
}

export function validatePhone(value: string): ValidateResult {
  if (!hasOnlyNumbersOrSpace(value)) {
    return "Only numbers are allowed";
  }
}

export function validateZipCode(value: string): ValidateResult {
  if (hasSpecialChar(value)) {
    return "Special characters are not allowed";
  }
}

export function validateCanadaZipCode(value: string): ValidateResult {
  if (!/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i.test(value)) {
    return "Invalid Zip code";
  }
}

export function validateDomainUrl(value: string): ValidateResult {
  if (
    !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    )
  ) {
    return "Wrong URL format";
  }
}

export function validateUrl(value: string): ValidateResult {
  const exp =
    /^((https?):\/\/)?(www\.)?[-a-zA-Z0-9:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{2,}\b([-a-zA-Z0-9():%_\\+.~#?&//=]*)?$/i;
  if (!exp.test(value)) {
    return "Invalid URL";
  }
}

export function validateCardNumber(value: string): ValidateResult {
  const val = value
    .replace(/\s+/g, "")
    .replace(/[^0-9]/gi, "")
    .trim();

  const exp = /^[0-9]{1,19}$/g;
  if (!exp.test(val) || val.length < 13) {
    return "Invalid Card Number";
  } else if (val.length > 19) {
    return "The maximum number of digits is 19";
  }
}

export function validateCardExpiry(value: string): ValidateResult {
  const val = value.replace(/[^0-9]/gi, "").trim();

  const exp = /^(0[1-9]|1[0-2])(\d{4})$/;
  const matches = val.match(exp);
  if (!matches) {
    return "Invalid Card Expiry";
  } else {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    if (
      +matches[2] - currentYear < 0 ||
      (+matches[2] - currentYear === 0 && +matches[1] - currentMonth < 0)
    ) {
      return "Expiry Date in Past";
    } else if (+matches[2] > currentYear + 9) {
      return "Your card's expiration year is invalid";
    }
  }
}

export interface Requirement {
  requirement: string;
  pass: boolean;
}

export function getPasswordRequirements(password: string): Requirement[] {
  return [
    {
      requirement: "One lowercase character",
      pass: hasLowercaseChar(password),
    },
    {
      requirement: "One special character",
      pass: hasSpecialChar(password),
    },
    {
      requirement: "One upercase character",
      pass: hasUpercaseChar(password),
    },
    {
      requirement:
        password.length < 8 ? "8 characters minimum" : "64 characters maximum",
      pass:
        password.length < 8 ? hasMinLength(password) : hasMaxLength(password),
    },
    {
      requirement: "One number",
      pass: hasNumber(password),
    },
  ];
}

function hasLowercaseChar(str: string) {
  return /[a-z]/.test(str);
}

function hasSpecialChar(str: string) {
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(str);
}

function hasUpercaseChar(str: string) {
  return /[A-Z]/.test(str);
}

function hasMinLength(str: string, minLength = 8) {
  return str.length >= minLength;
}

function hasMaxLength(str: string, maxLength = 64) {
  return str.length <= maxLength;
}

function hasNumber(str: string) {
  return /[0-9]/.test(str);
}

function hasOnlyNumbersOrSpace(str: string) {
  return /^[\d ]*$/.test(str);
}

export const canadaZipCodeRules = {
  required: {
    value: true,
    message: "Zip code is required",
  },
  maxLength: {
    value: 10,
    message: "The maximum number of characters is 10",
  },
  validate: validateCanadaZipCode,
};

export const emptyZipCodeRules = {
  required: {
    value: true,
    message: "Zip code is required",
  },
  maxLength: {
    value: 10,
    message: "The maximum number of characters is 10",
  },
  validate: (): undefined => undefined,
};

export function validateNoBlank(
  value: string,
  fieldName: string
): ValidateResult {
  if (typeof value === "string" && isBlankString(value)) {
    return `${fieldName} cannot be blank string`;
  }
}

export function validateCampaignName(value: string): ValidateResult {
  if (/[/\\*?"<>|]/.test(value)) {
    return "No Special characters allowed";
  }
}

const ONE_MB = 1000000;

export const validateFileSize = (file: string | File): ValidateResult => {
  if (!file) {
    return undefined;
  }
  if (typeof file === "string") {
    return undefined;
  }
  if (file.size > ONE_MB) {
    return "The maximum file size is 1MB";
  }
  return undefined;
};
