export function upperFirst(str: string): string {
  if (!str) {
    return str;
  }
  return `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;
}

export function extractParenthesesContent(str: string): string[] {
  const result: string[] = [];
  const exp = /\(([^}]+)\)/g;
  let curMatch;
  while ((curMatch = exp.exec(str))) {
    result.push(curMatch[1]);
  }
  return result;
}

export function removeParenthesesAndContent(str: string): string {
  let result = str;
  const exp = /\(([^)]+)\)/g;
  let curMatch;
  while ((curMatch = exp.exec(str))) {
    result = result.replace(`(${curMatch[1]})`, "");
  }
  return result;
}

export function removeNotNumbers(str: string): string {
  return str.replace(/\D/g, "");
}

export function encodeObjBase64(data: string): string {
  return Buffer.from(data).toString("base64");
}

export function decodeObjBase64(data: string): string {
  return Buffer.from(data, "base64").toString("ascii");
}

export function lastChars(value: string, count: number): string {
  if (value && value.length >= count) {
    return value.substring(value.length - count);
  } else {
    return value;
  }
}

export function isBlankString(str: string): boolean {
  return str != null && str.trim().length === 0;
}

export function isEmptyOrUndefined(str?: string): boolean {
  return str == null || str.trim().length === 0;
}

export function trimAllStringFields(obj: { [key: string]: any }): any {
  if (obj == null) {
    return obj;
  }
  const copy = { ...obj };
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] =
        typeof obj[k] == "string"
          ? (copy[k] = obj[k].trim())
          : (copy[k] = obj[k]))
  );
  return copy;
}

// returns lower cased string with the first letter upper case
export function formatString(str: string) {
  return str
    .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
    .replace(/^[^ ]/g, (match) => match.toUpperCase());
}
