import { useSearchParams } from "react-router-dom";

function useSearchParam(paramName: string, defaultValue: string) {
  const [searchParams] = useSearchParams() as [URLSearchParams, () => void];
  const paramValue = searchParams.get(paramName) ?? defaultValue;
  const paramMutationFn = (newValue: string) => {
    const currentParams = new URLSearchParams(searchParams);
    currentParams.set(paramName, newValue);
    return currentParams.toString();
  };
  return [paramValue, paramMutationFn] as const;
}

export default useSearchParam;
