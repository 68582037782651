import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { CampaignBuilderCardProps } from "../AddNewCampaigns.types";

const CreateCampaignBuilderCard = ({
  image,
  title,
  description,
  ctaText,
  active,
  ctaAction,
}: CampaignBuilderCardProps) => (
  <Box
    sx={(theme) => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      p: 5,
      borderRadius: 2,
      gap: 5,
      width: "50%",
      border: `0.5px solid ${theme.palette.grey[60]}`,
    })}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        pr: 4,
      }}
    >
      <img
        src={image}
        className="metric-image"
        alt={title}
        height={64}
        width={64}
      />
      <Typography variant="h3" mt={3}>
        {title}
      </Typography>
      <Typography variant="p2" color="grey.80">
        {description}
      </Typography>
    </Box>
    <Button
      variant="contained"
      disabled={!active}
      onClick={() => {
        if (ctaAction) {
          ctaAction();
        }
      }}
    >
      {ctaText}
    </Button>
  </Box>
);

const AddNewCampaignDialog = ({
  campaignBuilders,
}: {
  campaignBuilders: CampaignBuilderCardProps[];
}) => (
  <Box display="flex" gap={5} py={4}>
    {campaignBuilders.map((campaignBuilder) => (
      <CreateCampaignBuilderCard
        title={campaignBuilder.title}
        description={campaignBuilder.description}
        image={campaignBuilder.image}
        ctaText={campaignBuilder.ctaText}
        ctaAction={campaignBuilder.ctaAction}
        active={campaignBuilder.active}
        key={campaignBuilder.title}
      />
    ))}
  </Box>
);

export default AddNewCampaignDialog;
