import React from "react";
import { Box } from "@mui/material";
import { PlanSelectionProps } from "./types";
import Plan from "./Plan";

function PlanSelection(props: PlanSelectionProps) {
  const { plans, sx } = props;

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
      gap={5}
      sx={[{ width: "100%" }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {plans.map((planData, index) => (
        <Plan
          key={planData.id}
          id={planData.id}
          name={planData.name}
          trialPeriod={planData.trialPeriod}
          price={planData.price}
          features={planData.features}
          comingSoon={planData.comingSoon}
          index={index}
          currentPlan={planData.currentPlan}
          isChanged={planData.isChanged}
          discountDescription={planData.discountDescription}
          couponCode={planData.couponCode}
        />
      ))}
    </Box>
  );
}

export default PlanSelection;
