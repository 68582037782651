import {
  Audience,
  AudienceOutline,
  Automations,
  AutomationsOutline,
  Calendar,
  Campaigns,
  CampaignsOutline,
  Dashboard,
  DashboardOutline,
  Forms,
} from "@tiny/icons";
import { Chip, Theme } from "@mui/material";
import { MenuItem } from "./types";

const SecondaryLabel = () => (
  <Chip
    sx={(theme: Theme) => ({
      height: theme.spacing(6),
      backgroundColor: theme.palette.grey[30],
      color: theme.palette.grey[100],
      mr: 1,
      fontSize: theme.spacing(3),
      lineHeight: theme.spacing(4),
      "& .MuiChip-label": { fontWeight: 400 },
    })}
    label="Beta"
  />
);

export const mainMenu: MenuItem[] = [
  {
    name: "Dashboard",
    tooltip: "Dashboard",
    to: "dashboard/overview",
    Icon: DashboardOutline,
    IconActive: Dashboard,
    webOnly: false,
    exact: true,
  },
  {
    name: "Automations",
    tooltip: "Automations",
    to: "automations/overview",
    Icon: AutomationsOutline,
    IconActive: Automations,
    webOnly: false,
    exact: false,
  },
  {
    name: "Campaigns",
    tooltip: "Campaigns",
    to: "campaigns/overview/future-campaigns",
    Icon: CampaignsOutline,
    IconActive: Campaigns,
    webOnly: false,
    exact: false,
  },
  {
    name: "Calendar",
    tooltip: "Campaigns",
    to: "campaigns/calendar",
    Icon: Calendar,
    IconActive: Calendar,
    webOnly: false,
    exact: true,
    SecondaryLabel: <SecondaryLabel />,
  },
  {
    name: "Forms",
    tooltip: "Forms",
    to: "forms",
    Icon: Forms,
    IconActive: Forms,
    webOnly: false,
    exact: false,
  },
  {
    name: "Audience",
    tooltip: "Audience",
    to: "audience?sort=name,asc",
    Icon: AudienceOutline,
    IconActive: Audience,
    webOnly: false,
    exact: false,
  },
];
