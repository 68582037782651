const computeErrorText = (
  textLength: number,
  isSubmitError: boolean,
  isEmptyDetails: boolean
) => {
  const maxLengthExceededError = textLength >= 1000 ? "MAX length is 1000" : "";
  const requiredFieldError =
    isSubmitError && isEmptyDetails
      ? "'Company details' is a required field"
      : "";
  return maxLengthExceededError || requiredFieldError;
};

export { computeErrorText };
