import { CampaignsList } from "@/components/CampaignsList";
import React from "react";
import { useLoaderData } from "react-router-dom";
import { CampaignsLoaderProps } from "../loader";

function CampaignContainer() {
  const { campaigns } = useLoaderData() as CampaignsLoaderProps;
  return <CampaignsList campaigns={campaigns} />;
}

export default CampaignContainer;
