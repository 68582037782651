import React from "react";
import { useDeleteActivityMutation } from "../../../app/useGeneratedApi";
import { useTemplateListView } from "../TemplateList.context";
import {
  TemplateFilter,
  TemplateTag,
  TemplateType,
} from "../TemplateList.types";
import useInfiniteTemplatesQuery from "../useInfiniteTemplatesQuery";
import { sectionPropsByFilter } from "./DragAndDropTab.helpers";
import { DragAndDropTabProps } from "./DragAndDropTab.types";
import { useTemplateSectionQuery } from "./useTemplateSectionQuery";

export function useDragAndDropTab(props: DragAndDropTabProps) {
  const { refetchSections, emptyStateMessage, showSelected } = props;

  const { selectedTemplate, selectable } = useTemplateListView();

  const [searchKeyword, setSearchKeyword] = React.useState("");

  const [activeFilter, setActiveFilter] = React.useState<TemplateFilter>("All");

  const [activeTitle, setActiveTitle] = React.useState<string>();

  const [activeType, setActiveType] = React.useState<TemplateType>();

  const [activeSaved, setActiveSaved] = React.useState<boolean>();

  const [isFavoritesSelected, setIsFavoritesSelected] = React.useState(false);

  const [activeRenderWithEjs, setActiveRenderWithEjs] =
    React.useState<boolean>();

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const [selectedTags, setSelectedTags] = React.useState<TemplateTag[]>([]);

  const tagIds = selectedTags.map((tag) => tag.id!);

  const shouldShowFilteredSection = activeFilter !== "All";

  const {
    savedTemplates,
    staffPicksTemplates,
    brandedTemplates,
    basicTemplates,
    libraryTemplates,
    shouldShowSavedAndStaffPicks,
    shouldShowSaved,
    shouldShowBranded,
    shouldShowBasic,
    shouldShowLibrary,
    totalSaved,
    totalStaffPicks,
    totalBranded,
    totalBasic,
    totalLibrary,
    isEmpty: isTemplatesEmpty,
    isLastSaved,
    isLastStaffPicks,
    isLastBranded,
    isLastBasic,
    isLastLibrary,
    isLoadingBasic,
    isLoadingBranded,
    isLoadingLibrary,
    isLoadingSaved,
    isLoadingStaffPicks,
    refetchTemplates,
    removeTemplates,
  } = useTemplateSectionQuery({
    searchKeyword,
    tagIds,
    favorited: isFavoritesSelected || undefined,
    enabled: !shouldShowFilteredSection,
    refetchSections,
  });

  const {
    templates: filteredTemplates,
    fetchMore,
    hasMore,
    total: totalFilteredTemplates,
    refetch: refetchFilterdSections,
    isEmpty: isFilteredTemplatesEmpty,
    loading: isLoading,
  } = useInfiniteTemplatesQuery(
    activeSaved,
    activeType,
    searchKeyword,
    selectedTags,
    isFavoritesSelected || undefined,
    "DRAG_AND_DROP",
    shouldShowFilteredSection
  );

  const refreshPage = shouldShowFilteredSection
    ? refetchFilterdSections
    : refetchTemplates;

  const { mutateAsync: deleteActivityMutateAsync } =
    useDeleteActivityMutation();

  const search = React.useCallback((value: string) => {
    setSearchKeyword(value);
  }, []);

  const deleteSelected = React.useCallback(async () => {
    await deleteActivityMutateAsync({ data: { ids: selectedIds } });
    setSelectedIds([]);
    refreshPage();
  }, [deleteActivityMutateAsync, refreshPage, selectedIds]);

  const deselectAll = React.useCallback(() => {
    setSelectedIds([]);
  }, []);

  const updateSelectedTags = React.useCallback((tags?: TemplateTag[]) => {
    setSelectedTags(tags || []);
  }, []);

  const deselectTag = React.useCallback((tagId: string) => {
    setSelectedTags((prev) => {
      return prev.filter((tag) => tag.id !== tagId);
    });
  }, []);

  const toggleSelect = React.useCallback((id?: string) => {
    if (!id) {
      return;
    }
    setSelectedIds((prev) => {
      const index = prev.findIndex((i) => i === id);
      if (index === -1) {
        return [...prev, id];
      } else {
        const next = [...prev];
        next.splice(index, 1);
        return next;
      }
    });
  }, []);

  const isSelected = React.useCallback(
    (id?: string) => {
      if (!id) {
        return false;
      }
      return selectedIds.includes(id);
    },
    [selectedIds]
  );

  const changeActiveFilter = React.useCallback(
    (value: TemplateFilter) => {
      const { title, saved, type, renderWithEjs } = sectionPropsByFilter(value);
      setActiveTitle(title);
      setActiveSaved(saved);
      setActiveType(type);
      setActiveFilter(value);
      setActiveRenderWithEjs(renderWithEjs);
      removeTemplates();
    },
    [removeTemplates]
  );

  const toggleAll = React.useCallback(
    (value: boolean) => {
      if (value) {
        const ids = shouldShowFilteredSection
          ? filteredTemplates.map((template) => template.id!)
          : savedTemplates.map((template) => template.id!);
        setSelectedIds(ids);
      } else {
        setSelectedIds([]);
      }
    },
    [filteredTemplates, savedTemplates, shouldShowFilteredSection]
  );

  const showAll = React.useCallback(
    (saved?: boolean, type?: TemplateType) => {
      if (saved) {
        changeActiveFilter("Saved");
      } else if (type === "STAFF_PICKED") {
        changeActiveFilter("StaffPicks");
        // } else if (type === "CUSTOM") {
        //   changeActiveFilter("Branded");
      } else if (type === "BASIC") {
        changeActiveFilter("Basic");
      } else if (type === "LIBRARY") {
        changeActiveFilter("Library");
      }
    },
    [changeActiveFilter]
  );

  const allSelected = React.useMemo(() => {
    if (shouldShowFilteredSection) {
      return (
        activeFilter === "Saved" &&
        filteredTemplates.length > 0 &&
        filteredTemplates.length === selectedIds.length
      );
    } else {
      return (
        savedTemplates.length === selectedIds.length &&
        savedTemplates.length > 0
      );
    }
  }, [
    activeFilter,
    filteredTemplates.length,
    savedTemplates.length,
    selectedIds.length,
    shouldShowFilteredSection,
  ]);

  const allSelectedDisabled = React.useMemo(() => {
    if (shouldShowFilteredSection) {
      return activeFilter !== "Saved" || filteredTemplates.length === 0;
    } else {
      return savedTemplates.length === 0;
    }
  }, [
    activeFilter,
    filteredTemplates.length,
    savedTemplates.length,
    shouldShowFilteredSection,
  ]);

  const isEmpty = shouldShowFilteredSection
    ? isFilteredTemplatesEmpty
    : isTemplatesEmpty;

  return {
    allSelectedDisabled,
    toggleAll,
    selectedIds,
    allSelected,
    activeFilter,
    basicTemplates,
    brandedTemplates,
    changeActiveFilter,
    deleteSelected,
    deselectAll,
    deselectTag,
    isLoading,
    isLoadingBasic,
    isLoadingBranded,
    isLoadingLibrary,
    isLoadingSaved,
    isLoadingStaffPicks,
    isSelected,
    libraryTemplates,
    refetchSections,
    savedTemplates,
    search,
    selectedTags,
    shouldShowBasic,
    shouldShowBranded,
    shouldShowLibrary,
    shouldShowSaved,
    shouldShowSavedAndStaffPicks,
    showSelected,
    staffPicksTemplates,
    toggleSelect,
    updateSelectedTags,
    totalSaved,
    totalStaffPicks,
    totalBranded,
    totalBasic,
    totalLibrary,
    isEmpty,
    emptyStateMessage,
    isLastSaved,
    isLastStaffPicks,
    isLastBranded,
    isLastBasic,
    isLastLibrary,
    searchKeyword,
    shouldShowFilteredSection,
    filteredTemplates,
    fetchMore,
    hasMore,
    totalFilteredTemplates,
    activeTitle,
    activeSaved,
    activeType,
    activeRenderWithEjs,
    showAll,
    selectedTemplate,
    selectable,
    isFavoritesSelected,
    setIsFavoritesSelected,
  };
}
