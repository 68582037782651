import React from "react";
import {
  Await,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import PageSize from "@/components/PaginationRouted/PageSize";
import TinyPagination from "@/components/PaginationRouted/Pagination";
import { Box, Theme } from "@mui/material";
import EmptyState from "@/components/EmptyState";
import { Search } from "@tiny/icons";
import { GetGeneratedSourcesResponse } from "@/types/api/generatedRevenueSources";
import SourcesTableHeader from "@/routes/SourcesTableRevenue/SourcesTableHeader";
import SourcesTable from "@/routes/SourcesTableRevenue/SourcesTable";

/**
 * TODO extract custom hooks and optimize callback functions. Do not propagate
 * to other components in this form
 */
function SourcesTableRevenue() {
  const { generatedSources } = useLoaderData() as {
    generatedSources: GetGeneratedSourcesResponse;
  };

  const [selected, setSelected] = React.useState<string[]>([]);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const exploreOrdersForSelectedActivities = () => {
    const activities = selected.join(",");
    navigate(
      `../orders?activities=${activities}&activityType=${
        searchParams.get("filter") ?? ""
      }`
    );
  };

  return (
    <React.Suspense>
      <Await resolve={generatedSources}>
        {(awaitedData: GetGeneratedSourcesResponse) => (
          <>
            <SourcesTableHeader
              isEmpty={awaitedData.empty}
              seeResultsHandler={exploreOrdersForSelectedActivities}
            />
            {awaitedData.empty ? (
              <EmptyState
                Icon={
                  <Search
                    sx={(theme: Theme) => ({
                      color: "grey.80",
                      width: theme.spacing(6),
                      height: theme.spacing(6),
                    })}
                  />
                }
                message="Please try again later or adjust your search criteria."
                title="No revenue generated yet or no results found."
              />
            ) : (
              <>
                <SourcesTable selected={selected} setSelected={setSelected} />
                <Box display="flex" justifyContent="space-between" mt={5}>
                  <PageSize />
                  <TinyPagination pagesCount={awaitedData.totalPages} />
                </Box>
              </>
            )}
          </>
        )}
      </Await>
    </React.Suspense>
  );
}

export default SourcesTableRevenue;
