import { useMediaQuery, useTheme } from "@mui/material";

function useBreakpoints() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const mdUp = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"), { noSsr: true });
  return {
    smUp,
    mdUp,
    lgUp,
  };
}

export default useBreakpoints;
