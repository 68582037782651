import { StylesOverridesProps } from "../../../types/stylesOverrides";

const root = ({ theme }: StylesOverridesProps) => ({
  padding: 0,
  paddingTop: theme.spacing(4),
});

const styleOverrides = {
  root,
};

export { styleOverrides };
