import React from "react";
import { Box, Chip, Link, List, Theme } from "@mui/material";
import { CampaignStep, Step } from "../../CampaignPage.types";
import { CheckBold, LongRight, MoreHorizontal, CloseBig } from "@tiny/icons";
import { Typography } from "@mui/material";

interface StepItemProps {
  activeStep: Step;
  setActiveStep: React.Dispatch<React.SetStateAction<Step>>;
  step: CampaignStep;
  disabled?: boolean;
}

export function StepItem(props: StepItemProps): JSX.Element {
  const { step, activeStep, setActiveStep } = props;
  const iconStyle = {
    width: 16,
    height: 16,
    marginRight: 8,
  };

  const iconColor = step.completed ? "primary" : "inherit";

  let icon = <MoreHorizontal color={iconColor} sx={iconStyle} />;
  if (step.disabled) {
    icon = <CloseBig color={iconColor} sx={iconStyle} />;
  } else if (step.step === activeStep) {
    icon = <LongRight color={iconColor} sx={iconStyle} />;
  } else if (step.completed) {
    icon = <CheckBold color={iconColor} sx={iconStyle} />;
  }

  const navigableStep = React.useMemo(
    () => step.completed || step.step === activeStep,
    [activeStep, step.completed, step.step]
  );

  const clickableStep = (step: CampaignStep) =>
    !step.disabled && !!step.link && !!navigableStep;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        position: "relative",
        height: 40,
        lineHeight: "40px",
        padding: "0 16px",
        fontSize: 12,
        color: theme.palette.grey[700],
        fontWeight: "normal",
        "& > a": {
          textDecoration: "none",
          color: "inherit",
        },
      })}
    >
      <Chip
        key={step.name}
        label={step.name}
        variant="filled"
        size="medium"
        sx={(theme) => ({
          color: step.completed
            ? theme.palette.primary.main
            : step.step === activeStep
            ? "black"
            : "grey",
          backgroundColor:
            step.step === activeStep ? "whitesmoke" : "transparent",
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: theme.spacing(3.5),
          p: 2,
        })}
        icon={icon}
        clickable={clickableStep(step)}
        onClick={() => {
          if (clickableStep(step)) {
            setActiveStep(step.step);
          }
        }}
      />
    </Box>
  );
}
