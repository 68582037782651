import { StylesOverridesProps } from "../../../types/stylesOverrides";

const track = ({ theme }: StylesOverridesProps) => ({
  backgroundColor: theme.palette.grey[50],
  "&.Mui-checked": {
    backgroundColor: theme.palette.common.white,
  },
});

const switchBase = ({ theme }: StylesOverridesProps) => ({
  "&.Mui-disabled": {
    color: theme.palette.grey[70],
  },

  color: theme.palette.grey[70],

  "&.Mui-checked": {
    color: theme.palette.primary.main,
    "& + .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
      opacity: 1,
      border: "1px solid",
      borderColor: theme.palette.grey[60],
    },
  },
});

const styleOverrides = {
  switchBase,
  track,
};

export { styleOverrides };
