import { ActionMenu } from "@/components/ActionMenu/types";
import * as React from "react";
import { useNavigate, useSubmit } from "react-router-dom";

function useFormActionMenu(
  name: string,
  infoDetails: string,
  id: string,
  enabled: boolean
) {
  const submit = useSubmit();
  const navigate = useNavigate();

  const handleRegenerateForm = React.useCallback(() => {
    const form = new FormData();
    form.append("_id", id);
    form.append("_action", "regenerate");
    submit(form, {
      method: "put",
      action: `/main/forms/${id}/preview`,
    });
  }, [id, submit]);

  const clickInfoHandler = () => {
    navigate(`${id}/info`);
  };

  const formMenu: ActionMenu = [
    {
      name: "Information",
      onClickAction: clickInfoHandler,
      id: "forms-overview-list-information",
    },
  ];

  if (!enabled) {
    formMenu.splice(0, 0, {
      name: "Regenerate layout",
      onClickAction: handleRegenerateForm,
      id: "forms-overview-list-regenerate",
    });
  }

  return { formMenu };
}

export default useFormActionMenu;
