import React from "react";
import { Box, Button, Theme } from "@mui/material";
import MetricInfoValue from "@/components/MetricCard/MetricInfoValue";
import MetricInfoTitle from "@/components/MetricCard/MetricInfoTitle";
import { ChevronRight } from "@tiny/icons";
import { Metric } from "./types";

function MetricInfo({ data }: { data: Metric }) {
  const { title, shortTitle, rate, image, value, seeReportsHandler, ctaID } =
    data;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      <Box overflow="hidden" display="flex" gap={2} flexDirection="column">
        <MetricInfoTitle title={title} shortTitle={shortTitle} />
        <MetricInfoValue value={value} rate={rate} />
        {seeReportsHandler && (
          <Button
            sx={(theme: Theme) => ({
              p: 0,
              fontWeight: theme.typography.fontWeightRegular,
              justifyContent: "flex-start",
            })}
            onClick={seeReportsHandler}
            variant="text"
            endIcon={<ChevronRight />}
            id={ctaID}
          >
            See reports
          </Button>
        )}
      </Box>
      <Box>
        {image && (
          <img src={data.image} alt="tinyAlbert" className="metric-image" />
        )}
      </Box>
    </Box>
  );
}

export default MetricInfo;
