import { SenderDetailsRow } from "@/routes/AccountSettings/SenderDetails/SenderDetails.types";
import { Audience, GetAudienceResponse } from "@/types/api/audience";
import { fetchApi } from "@tiny/utils";
import React from "react";
import {
  useNavigate,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";

const useTinyCampaignBuilder = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { senderDetails } = (useRouteLoaderData("main") as {
    senderDetails: SenderDetailsRow;
  }) || {
    senderDetails: {},
  };
  const [audiences, setAudiences] = React.useState<Audience[]>([]);

  const fetchAudiences = async () => {
    const response = await fetchApi<GetAudienceResponse>(
      "/customerservice/audience?sort=name,asc",
      {
        method: "get",
      }
    );
    setAudiences(response.audiences);
  };

  const exitWithoutSaving = () => {
    navigate(-1);
  };

  React.useEffect(() => {
    fetchAudiences();
  }, []);

  return {
    config: {
      campaignId: searchParams.get("id") ?? "",
      senderDetails,
      audiences,
      exitWithoutSaving,
    },
  };
};

export default useTinyCampaignBuilder;
