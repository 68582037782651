import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import MetricCard from "../MetricCard";
import { AutomationContent } from "../Automation/types";

function StatisticsDialogContent(props: AutomationContent) {
  const { description, metricData } = props;

  return (
    <>
      <Box display="flex" gap={1} flexDirection="column">
        <Typography variant="h4" mt={4}>
          How it works
        </Typography>
        <Typography variant="p2">{description}</Typography>
      </Box>
      <Typography variant="h4" mb={2} mt={4}>
        How it performed
      </Typography>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 12, md: 12 }}
        mb={4}
      >
        {metricData.map((metric) => (
          <Grid item xs={12} md={6} key={metric.id}>
            <MetricCard
              metric={metric}
              sx={{
                border: `1px solid`,
                borderColor: "grey.40",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default StatisticsDialogContent;
