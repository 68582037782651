import { GetSenderDetailsResponse } from "@/types/api/senderDetails";

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

function prepareForEdit(details: GetSenderDetailsResponse) {
  return (
    Object.entries(details) as Entries<GetSenderDetailsResponse>
  ).reduce<GetSenderDetailsResponse>(
    (acc, [key, value]) => {
      if (typeof value === "string" && value === "Not indicated") {
        acc[key] = "";
      }
      return acc;
    },
    { ...details }
  );
}

function prepareForUpdate(details: GetSenderDetailsResponse) {
  return (
    Object.entries(details) as Entries<GetSenderDetailsResponse>
  ).reduce<GetSenderDetailsResponse>(
    (acc, [key, value]) => {
      if (typeof value === "string" && value === "") {
        acc[key] = "Not indicated";
      }
      return acc;
    },
    { ...details }
  );
}

export { prepareForEdit, prepareForUpdate };
