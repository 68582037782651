import {
  Backdrop,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  Container,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import * as React from "react";
import { PreviewType, TemplatePreviewProps } from "./types";
import { HtmlPreview } from "../HtmlPreview";
import { MobilePreview } from "../MobilePreview";

function TemplatePreview(props: TemplatePreviewProps) {
  const {
    html,
    css,
    isLoading = false,
    subject,
    from,
    variant = "default",
  } = props;
  const [value, setValue] = React.useState<PreviewType>("desktop");
  const handleTabChange = React.useCallback(
    (_: React.SyntheticEvent, newValue: PreviewType) => {
      setValue(newValue);
    },
    []
  );
  return (
    <TabContext value={value}>
      <Box sx={{ pt: 4 }}>
        <Tabs value={value} onChange={handleTabChange} id="preview-tabs">
          <Tab value="desktop" label="Desktop" id="desktop-tab" />
          <Tab value="mobile" label="Mobile" id="mobile-tab" />
        </Tabs>
      </Box>
      <Box mt={value === "mobile" ? 4 : 0} sx={{ height: "100%" }}>
        <TabPanel
          value="desktop"
          sx={{ display: value === "desktop" ? "block" : "none", pb: 4 }}
        >
          <Box
            my={5}
            sx={(theme) => ({
              position: "relative",
              display: "flex",
              flexDirection: "column",
              borderRadius: 2,
              border: "0.5px solid " + theme.palette.grey[60],
              alignItems: "center",
            })}
          >
            {subject && (
              <Box
                py={4}
                px={5}
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey[20],
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  borderBottom: "0.5px solid " + theme.palette.grey[50],
                  position: "relative",
                })}
              >
                <Typography variant="p2">
                  <Typography
                    component="span"
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    From:
                  </Typography>{" "}
                  {from}
                </Typography>
                <Typography variant="p2">
                  <Typography
                    component="span"
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    Subject:
                  </Typography>{" "}
                  {subject}
                </Typography>
              </Box>
            )}
            <HtmlPreview
              width="100%"
              height={variant === "default" ? "28.25rem" : "44.25rem"}
              contentWidth={50} /* as rem */
              html={html}
              css={css}
              scrollable
            />
            <Backdrop
              sx={{
                color: "#fff",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0.1,
              }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </TabPanel>
        <TabPanel
          value="mobile"
          sx={{
            display: value === "mobile" ? "flex" : "none",
            justifyContent: "center",
            height: "100%",
            mb: 4,
          }}
        >
          <MobilePreview
            width="17.25rem"
            height="33.25rem"
            contentWidth={22.5} /* as rem */
            html={html}
            css={css}
            scrollable
            subject={subject}
            from={from}
            isLoading={isLoading}
          />
        </TabPanel>
      </Box>
    </TabContext>
  );
}

export { TemplatePreview };
