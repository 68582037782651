import { Theme } from "@mui/material";

const root = ({ theme }: { theme: Theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  padding: theme.spacing(1),
});

const styleOverrides = {
  root,
};

export { styleOverrides };
