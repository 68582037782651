import * as React from "react";
import { useDrawerManager } from "./DrawerManager.context";
import { SwipeableDrawer } from "@mui/material";

type DrawerCloseEvent = React.SyntheticEvent<any, Event> & {
  reason: "backdropClick" | "escapeKeyDown";
};

const DrawerManager = () => {
  const { drawerVisible, drawerProps, hideDrawer, showDrawer } =
    useDrawerManager();
  return (
    <SwipeableDrawer
      {...drawerProps}
      PaperProps={{
        style: {
          borderRadius: "20px 0 0 0",
        },
      }}
      anchor="right"
      open={drawerVisible}
      onClose={(event: DrawerCloseEvent) => {
        if (drawerProps?.onClose) {
          drawerProps.onClose(event, event?.reason);
        }
        hideDrawer();
      }}
      onOpen={() => {}}
      style={{
        borderRadius: "20px 0px 0px 0px",
      }}
    />
  );
};

export { DrawerManager };
