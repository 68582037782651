import { fetchApi } from "@tiny/utils";
import { LoaderFunctionArgs, defer } from "react-router-dom";
import {
  GetReportOverviewResponse,
  GetReportsOverviewResponse,
} from "@/types/api/reportsOverview";
import { GetAutomationsResponse } from "@/types/api/automations";
import { mapGetAutomations } from "@/components/Automation/mapper";
import {
  GetCampaignCountResponse,
  GetCampaignResponse,
} from "@/types/api/campaigns";

const getCurrentDateString = () => {
  const dateIsoString = new Date().toISOString();
  const date = dateIsoString.substring(0, dateIsoString.length - 5);
  return date;
};

export const defaultApiQueryParams = {
  page: "0",
  size: "6",
  "scheduled-to": getCurrentDateString(),
};

export const dashboardLoader = async ({ request }: LoaderFunctionArgs) => {
  const dashboardOverview = await fetchApi<GetReportsOverviewResponse>(
    "/reportservice/dashboard/overview",
    {
      signal: request.signal,
    }
  );

  const automations = await fetchApi<GetAutomationsResponse>(
    "/automationservice/automation",
    { signal: request.signal }
  ).then((r) => mapGetAutomations(r));

  const activeAutomations = automations?.filter((a) => a?.active)?.length;

  const automationsReports = await fetchApi<GetReportOverviewResponse>(
    "/reportservice/automation/overview",
    { signal: request.signal }
  );

  const campaignsSent = await fetchApi<GetCampaignCountResponse>(
    `/campaignservice/campaign/completed/true/count`,
    { signal: request.signal }
  );

  const campaignsReports = await fetchApi<GetReportOverviewResponse>(
    "/reportservice/campaign/overview",
    { signal: request.signal }
  );

  const url = new URL(request.url);
  const requestParams = Object.fromEntries(url.searchParams);
  const apiPage = (parseInt(requestParams.page ?? 1, 10) - 1).toString();

  const apiParams = {
    ...defaultApiQueryParams,
    ...requestParams,
    page: apiPage,
    sort: requestParams.sort || "startTime,desc",
  };

  const apiQueryString = new URLSearchParams(apiParams).toString();

  const campaigns = await fetchApi<GetCampaignResponse>(
    `/campaignservice/campaign?${apiQueryString}`,
    { signal: request.signal }
  );

  return defer({
    ...dashboardOverview,
    automationsReports: { ...automationsReports, activeAutomations },
    campaignsReports: {
      ...campaignsReports,
      sentCampaigns: campaignsSent.count,
    },
    campaigns,
  });
};
