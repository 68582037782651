import { Theme } from "@mui/material";

const root = ({ theme }: { theme: Theme }) => ({
  borderBottom: "none",
  "&.MuiTableCell-head": {
    lineHeight: theme.spacing(3),
    borderBottom: "1px solid",
    borderColor: theme.palette.grey[40],
  },
});

const styleOverrides = {
  root,
};

export { styleOverrides };
