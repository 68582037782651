import * as React from "react";
import { Outlet, useRouteLoaderData } from "react-router-dom";
import type { RootLoader } from "@/types/RootLoader";
import { useSentryUser } from "@tiny/sentry/hooks";
import ZohoSalesIQ from "@/components/ZohoSalesIQ/ZohoSalesIQ";

function RootRoute() {
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;
  const {
    userEmail,
    firstName,
    lastName,
    phoneNumber,
    site,
    providerIdentifier,
  } = accountInfo ?? {};
  useSentryUser({ email: userEmail, username: userEmail, site });

  return (
    <>
      <Outlet />
      <ZohoSalesIQ
        visitorInfo={{
          name: `${firstName} ${lastName}`,
          email: userEmail || "",
          contactnumber: phoneNumber || "",
        }}
      />
    </>
  );
}

export default RootRoute;
export { rootLoader } from "./loader";
