import React from "react";
import StripoContext from "./StripoContext";
import { StripoProviderProps } from "./StripoProvider.types";

function StripoProvider(props: StripoProviderProps) {
  const { accountId, children, userFullName } = props;

  const contextValue = React.useMemo(() => {
    return {
      accountId,
      userFullName,
    };
  }, [accountId, userFullName]);

  return (
    <StripoContext.Provider value={contextValue}>
      {children}
    </StripoContext.Provider>
  );
}

export default StripoProvider;
