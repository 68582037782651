import { Box, Container, Divider, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useScreenLoaderInvokers } from "@tiny/lib";
import * as React from "react";
import { fetchApi } from "@tiny/utils";
import {
  useLoaderData,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useConfirmationDialog } from "@/routes/Automations/useConfirmationDialog.hook";
import { TemplateProps, NavigationChipsItemType } from "./types";
import { TemplateEditorTopBar } from "./TemplateEditorTopBar";

// TODO parameterize properly and pass context data
const TemplateEditorDetailsPage = () => {
  const navigate = useNavigate();
  const { showScreenLoader } = useScreenLoaderInvokers();
  const { id } = useParams();
  const { templateData } = useLoaderData() as {
    templateData: TemplateProps;
  };
  const cancelActionHandler = (): void => {
    showScreenLoader();
    navigate(
      `/main/automations/overview${
        searchParams ? `?${searchParams.toString()}` : ""
      }`
    );
  };

  const sm = useMediaQuery("(max-width:600px)");
  const { showConfirmationDialog } = useConfirmationDialog();
  const [searchParams] = useSearchParams();
  const [subject, setSubject] = React.useState<string>(
    templateData.subjectLine
  );
  const [preview, setPreview] = React.useState<string | undefined>(
    templateData.preview
  );
  const [subjectInputValue, setSubjectInputValue] =
    React.useState<string>(subject);
  const [previewInputValue, setPreviewInputValue] = React.useState<
    string | undefined
  >(preview || "");
  const subjectFormValue = { subject, preview };

  const saveSubjectHandler = async (data: any) => {
    await fetchApi(`/automationservice/automation/${id || "id"}/details`, {
      method: "PUT",
      body: JSON.stringify({
        automationId: id,
        subjectLine: data.subject,
        preview: data.preview,
      }),
    });
    setSubject(data.subject);
    setPreview(data.preview);
    return true;
  };
  const formSubmitHandler = async (data: any): Promise<void> => {
    showScreenLoader();
    await saveSubjectHandler({
      subject: data.subject,
      preview: data.preview,
    });
    navigate(`../design${searchParams ? `?${searchParams.toString()}` : ""}`);
  };
  React.useEffect(() => {
    if (sm) {
      showConfirmationDialog(
        "Editing is not supported in the mobile view.",
        "Cannot Edit",
        "Ok",
        () => {
          navigate(
            `/main/automations/overview${
              searchParams ? `?${searchParams.toString()}` : ""
            }`
          );
        },
        false
      );
    }
  }, [sm]);

  return !sm ? (
    <FormContainer
      defaultValues={subjectFormValue}
      values={subjectFormValue}
      onSuccess={formSubmitHandler}
    >
      <Container
        sx={{
          position: "fixed",
          m: 0,
          p: 0,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "grey.20",
          minWidth: "100vw",
          minHeight: "100vh",
        }}
        disableGutters
        maxWidth={false}
      >
        <TemplateEditorTopBar
          subject={subject}
          ctaAction={() => {}}
          cancelAction={cancelActionHandler}
          ctaText="Next"
          type="submit"
          navigationChips={[
            {
              title: "Details",
              type: NavigationChipsItemType.active,
              navigate: "details",
            },
            {
              title: "Design",
              type: NavigationChipsItemType.inactive,
              navigate: "designs",
            },
          ]}
          ctaId="automations-overview-edit-save-details"
        />
        <Container
          sx={{
            px: 20,
            py: 8,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "transparent",
          }}
          disableGutters
          maxWidth={false}
        >
          <Typography variant="h2" color="grey.100">
            Details
          </Typography>
          <Divider
            sx={{
              width: 1,
              my: 5,
            }}
          />
          <Box
            sx={(theme) => ({
              border: `1px solid ${theme.palette.grey[60]}`,
              backgroundColor: "grey.0",
              p: 5,
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            })}
          >
            <Typography variant="h4" color="grey.100">
              Template
            </Typography>
            <Typography variant="p2" color="grey.80">
              Enter the automation subject and description text
            </Typography>
            <Divider
              sx={{
                width: 1,
                my: 5,
              }}
            />
            <Box sx={{ position: "relative" }}>
              <TextFieldElement
                name="subject"
                size="small"
                value={subjectInputValue}
                onChange={(e) => setSubjectInputValue(e.target.value)}
                required
                fullWidth
                sx={(theme) => ({
                  flexBasis: "100%",
                  fontFamily: theme.typography.fontFamily,
                })}
                label="Subject"
                InputLabelProps={{ required: false }}
                inputProps={{
                  maxLength: "150",
                  style: { paddingRight: "7.5%" },
                }}
              />
              <Typography
                variant="p2"
                color="grey.80"
                sx={(theme) => ({
                  position: "absolute",
                  right: theme.spacing(3),
                  top: theme.spacing(3),
                })}
              >
                {subjectInputValue?.length}/150
              </Typography>
            </Box>
            <Box sx={{ position: "relative", p: 0, mt: 5 }}>
              <TextFieldElement
                name="preview"
                size="small"
                fullWidth
                sx={(theme) => ({
                  flexBasis: "100%",
                  fontFamily: theme.typography.fontFamily,
                })}
                label="Preview Text"
                multiline
                InputLabelProps={{ required: false }}
                value={previewInputValue}
                onChange={(e) => setPreviewInputValue(e.target.value)}
                inputProps={{
                  maxLength: "150",
                  style: { paddingRight: "7.5%" },
                }}
              />
              <Typography
                variant="p2"
                color="grey.80"
                sx={(theme) => ({
                  position: "absolute",
                  right: theme.spacing(3),
                  top: theme.spacing(3),
                })}
              >
                {previewInputValue?.length}/150
              </Typography>
            </Box>
          </Box>
        </Container>
      </Container>
    </FormContainer>
  ) : (
    <div />
  );
};

export default TemplateEditorDetailsPage;
