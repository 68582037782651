import { useWatch } from "react-hook-form";

import { FormWatchProps } from "./FormWatch.types";

function FormWatch(props: FormWatchProps): JSX.Element {
  const { name, defaultValue, children, form } = props;

  const result = useWatch({
    control: form?.control,
    name,
    defaultValue,
  });

  return children(result);
}

export default FormWatch;
