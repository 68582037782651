import * as React from "react";
import { ConfirmationFormActivationContent } from "@/components/ContentSetupStrategy/Dialogs/ConfirmationFormActivationContent";
import { useFetcher } from "react-router-dom";
import { useDialogInvokers } from "@tiny/lib";

interface ActivateFormConfirmationDialogProps {
  confirmClickHandler: () => void;
  formData: FormData;
  activeForms: string[];
}
function ActivateFormConfirmationDialog({
  confirmClickHandler,
  formData,
  activeForms,
}: ActivateFormConfirmationDialogProps) {
  const { exitDialog } = useDialogInvokers();
  const fetcher = useFetcher();
  const submitCompleted = fetcher.data as boolean;
  const submitHandler = React.useCallback(() => {
    fetcher.submit(formData, {
      method: "put",
      action: "/main/forms",
    });
  }, [fetcher, formData]);

  React.useEffect(() => {
    if (submitCompleted && fetcher.state === "idle") {
      confirmClickHandler();
      exitDialog();
    }
  }, [submitCompleted, exitDialog, fetcher.state, confirmClickHandler]);
  return (
    <ConfirmationFormActivationContent
      confirmClickHandler={submitHandler}
      activeForms={activeForms}
    />
  );
}

export { ActivateFormConfirmationDialog };
