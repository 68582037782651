import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <g xmlns="http://www.w3.org/2000/svg">
      <title>Layer 1</title>
      <rect
        id="svg_1"
        fill="white"
        rx="3.5"
        height="19"
        width="19"
        y="0.5"
        x="0.5"
      />
      <path
        id="svg_2"
        d="m0.5,4c0,-1.90183 1.59817,-3.5 3.5,-3.5l12,0c1.90183,0 3.5,1.59817 3.5,3.5l0,12c0,1.90183 -1.59817,3.5 -3.5,3.5l-12,0c-1.90183,0 -3.5,-1.59817 -3.5,-3.5l0,-12z"
        opacity="undefined"
        stroke="#9FA1A8"
        fill="white"
      />
      <rect
        stroke="#9FA1A8"
        rx="1"
        opacity="undefined"
        id="svg_3"
        height="0.93848"
        width="12.51301"
        y="8.87904"
        x="3.84777"
        fill="white"
      />
    </g>
    <title>Layer 1</title>
    <rect
      id="svg_1"
      rx="3.5"
      height="19"
      width="19"
      y="0.5"
      x="0.5"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      id="svg_2"
      d="m0.5,4c0,-1.90183 1.59817,-3.5 3.5,-3.5l12,0c1.90183,0 3.5,1.59817 3.5,3.5l0,12c0,1.90183 -1.59817,3.5 -3.5,3.5l-12,0c-1.90183,0 -3.5,-1.59817 -3.5,-3.5l0,-12z"
      opacity="undefined"
      stroke="#9FA1A8"
      xmlns="http://www.w3.org/2000/svg"
    />
    <rect
      stroke="#9FA1A8"
      rx="1"
      opacity="undefined"
      id="svg_3"
      height="0.93848"
      width="12.51301"
      y="8.87904"
      x="3.84777"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "UncheckAll"
);
