import React from "react";
import { FormProvider } from "react-hook-form";

import { FormProps } from "./Form.types";

function Form<T>(props: FormProps<T>) {
  const {
    children,
    form,
    onSubmit,
    onSubmitError,
    preventSubmitTime = 500,
    ...other
  } = props;

  const submitTimeoutRef = React.useRef<number>();

  const hasSubmittedRef = React.useRef(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (preventSubmitTime && onSubmit) {
      if (!hasSubmittedRef.current) {
        form.handleSubmit(onSubmit, onSubmitError)();
      }
      hasSubmittedRef.current = true;
      clearTimeout(submitTimeoutRef.current);
      submitTimeoutRef.current = window.setTimeout(() => {
        hasSubmittedRef.current = false;
      }, preventSubmitTime);
    } else if (onSubmit) {
      form.handleSubmit(onSubmit, onSubmitError)();
    }
  };

  return (
    //@ts-ignore
    <FormProvider {...form} data-valid={form.formState.isValid}>
      <form {...other} onSubmit={handleSubmit} noValidate>
        {children}
      </form>
    </FormProvider>
  );
}

export default Form;
