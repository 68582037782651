import { useDrawerManager } from "./DrawerManager.context";

const useDrawerInvokers = () => {
  const { showDrawer, hideDrawer } = useDrawerManager();
  return {
    showDrawer,
    hideDrawer,
  };
};

export { useDrawerInvokers };
