import { useDialogInvokers } from "@tiny/lib";
import { Button } from "@mui/material";
import React from "react";
import { TemplatePreview } from "@tiny/lib/ContentPreview";

interface PreviewerProps {
  html: string;
  css?: string | undefined;
}

function useTemplateLayoutPreviewer() {
  const { requestMediumDialog, exitDialog } = useDialogInvokers();

  return React.useCallback(
    ({ html, css }: PreviewerProps) => {
      requestMediumDialog({
        titleProps: { children: "Preview", variant: "h3" },
        contentProps: {
          children: <TemplatePreview html={html} css={css} />,
        },
        actionProps: {
          children: (
            <Button
              variant="contained"
              onClick={exitDialog}
              sx={{ flex: { xs: "1", md: "0" } }}
            >
              Close
            </Button>
          ),
        },
      });
    },
    [requestMediumDialog, exitDialog]
  );
}

export default useTemplateLayoutPreviewer;
