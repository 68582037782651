import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { CheckBold } from "@tiny/icons";

type Feature = {
  name: string;
};

interface PlanFeaturesProps {
  features: Feature[];
  comingSoon: boolean;
}
function PlanFeatures(props: PlanFeaturesProps) {
  const { features, comingSoon } = props;
  return (
    <Stack direction="column" gap={2} mt={4}>
      <Typography variant="label3" color={comingSoon ? "grey.80" : "grey.100"}>
        Features:
      </Typography>
      {features.map((featureName) => (
        <Box key={featureName.name} display="flex" alignItems="center" gap={1}>
          <CheckBold
            sx={(theme) => ({
              fontSize: 12,
              fill: comingSoon
                ? theme.palette.grey[80]
                : theme.palette.primary.main,
            })}
          />
          <Typography
            variant="label3R"
            color={comingSoon ? "grey.80" : "grey.100"}
          >
            {featureName.name}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
}

export default PlanFeatures;
