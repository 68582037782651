import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M10 4V11H4V4H2V20H4V13H10V20H12V4H10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M22 11.75C22 9.679 20.2091 8 18 8C15.7909 8 14 9.679 14 11.75H16.1333L16.1397 11.606C16.2176 10.707 17.0208 10 18 10C19.0304 10 19.8667 10.784 19.8667 11.75C19.8667 12.189 19.6939 12.591 19.408 12.898L14 18.444V20H22V18H17.5029L21.0187 14.21L21.1765 14.03C21.6928 13.398 22 12.607 22 11.75Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "HeadingH2"
);
