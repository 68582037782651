import React from "react";
import { Box, Card, Chip, Theme } from "@mui/material";
import { CircleCheckOutline } from "@tiny/icons";
import ActionMenu from "@/components/ActionMenu";
import { HeroImage } from "../../../routes/AccountSettings/HeroImage/types";
import useHeroImageActionMenu from "../useHeroImageActionMenu";
import { useHeroImage } from "../../../routes/AccountSettings/HeroImage/Hooks/useHeroimage";

function HeroImageCard({ heroImage }: { heroImage: HeroImage }) {
  const { id, attributes, imageType } = heroImage;
  const { heroImageMenu } = useHeroImageActionMenu(heroImage);
  const { selectHeroImage } = useHeroImage();

  const handleSelect = async () => {
    await selectHeroImage(id, imageType);
  };

  return (
    <Card
      id={`hero-image-${id}`}
      sx={(theme: Theme) => ({
        bgcolor: "common.white",
        display: "flex",
        flexDirection: "column",
        border: `0.5px solid ${theme.palette.grey[70]}`,
        borderRadius: 2,
        height: "100%",
        gap: 2,
        boxShadow: "none",
        position: "relative",
      })}
      className={
        heroImage.imageType === "HERO"
          ? "hero-image-deselect"
          : "hero-image-select"
      }
    >
      <Box width="100%" height="14rem">
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
          onClick={handleSelect}
        >
          <ActionMenu
            menuActions={heroImageMenu}
            withBackgroundAndBorderStyles
          />
          <Box
            sx={(theme) => ({
              position: "absolute",
              zIndex: 1,
              top: theme.spacing(4),
              left: theme.spacing(4),
            })}
          >
            <Box display="flex" justifyContent="center">
              {heroImage.imageType === "HERO" ? (
                <>
                  <CircleCheckOutline
                    sx={(theme) => ({
                      fontSize: 30,
                      color: theme.palette.primary[100],
                    })}
                  />
                  <Chip
                    label="Selected"
                    title="Selected"
                    sx={(theme) => ({
                      background: theme.palette.primary[100],
                      color: theme.palette.common.white,
                      fontWeight: 600,
                      fontSize: theme.spacing(4),
                      maxWidth: theme.spacing(100),
                      ml: theme.spacing(1.5),
                    })}
                  />
                </>
              ) : (
                <Box
                  sx={(theme) => ({
                    height: 24,
                    width: 24,
                    borderRadius: "50%",
                    borderColor: theme.palette.grey[80],
                    borderWidth: 2,
                    m: 0.5,
                    borderStyle: "solid",
                    display: "inline-block",
                    backgroundColor: theme.palette.common.white,
                  })}
                >
                  {" "}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <img
          src={attributes?.src}
          height="100%"
          width="100%"
          alt=""
          style={{ objectFit: "contain" }}
        />
      </Box>
    </Card>
  );
}

export default HeroImageCard;
