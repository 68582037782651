import { CampaignInfoView } from "../../pages/CampaignPage.types";
import { Color } from "@mui/material";

export type ChipColor = "black" | "grey" | Color | undefined;

export function getChipColor(status: CampaignInfoView["campaignStatus"]): any {
  switch (status) {
    case "SCHEDULED":
      return "yellow";
    case "QUEUED":
      return "blue";
    case "LAUNCHED":
      return "purple";
    case "COMPLETED":
      return "primary";
    case "PAUSED":
      return "red";
    case "FAILED":
      return "fuchsia";
    default:
      return "grey";
  }
}
