/* eslint-disable max-classes-per-file */
import { CustomError } from "./CustomError";
import { BackEndError } from "../api";

export class UnauthenticatedRequestError extends CustomError {}
export class RefreshTokenError extends CustomError {}

type ApiErrorConstructorParams = [
  message: string,
  status: number,
  body: BackEndError | string,
  url: string
];

export class ApiError extends CustomError {
  constructor(
    message: string,
    public status: number,
    public body: BackEndError | string,
    public url: string
  ) {
    super(message);
    this.name = "ApiError";
  }
}

export class HandledApiError extends ApiError {
  constructor(...args: ApiErrorConstructorParams) {
    super(...args);
    this.name = "HandledApiError";
  }
}

export class CampaignNotFoundError extends HandledApiError {
  constructor(...args: ApiErrorConstructorParams) {
    super(...args);
    this.name = "CampaignNotFoundError";
  }
}

export class CampaignIsOutdatedError extends HandledApiError {
  constructor(...args: ApiErrorConstructorParams) {
    super(...args);
    this.name = "CampaignIsOutdatedError";
  }
}

export class InvalidCampaignStatusError extends HandledApiError {
  constructor(...args: ApiErrorConstructorParams) {
    super(...args);
    this.name = "InvalidCampaignStatusError";
  }
}

export class SenderNotConfirmedError extends HandledApiError {
  constructor(...args: ApiErrorConstructorParams) {
    super(...args);
    this.name = "SenderNotConfirmedError";
  }
}

export class InvalidCouponCodeError extends HandledApiError {
  constructor(...args: ApiErrorConstructorParams) {
    super(...args);
    this.name = "InvalidCouponCodeError";
  }
}

export class UnhandledApiError extends ApiError {
  constructor(...args: ApiErrorConstructorParams) {
    super(...args);
    this.name = "UnhandledApiError";
  }

  public toString = (): string => `${this.name}: ${this.message}
    url: ${this.url} => ${this.status}`;
}
