import * as React from "react";
import { LayoutCategory } from "@/types/api/accountTemplateLayouts";
import { ConfirmationRequestContent } from "./ConfirmationRequestContent";
import SuccessMessageContent from "./SuccessMessageContent";
import { useFormSubmission } from "../formSubmition.hooks";

interface RegenerateConfirmationDialogProps {
  closeDialogHandler: () => void;
  accountDetailsRef: React.MutableRefObject<string>;
  selectedToneRef: React.MutableRefObject<string>;
  selectedLanguageRef: React.MutableRefObject<string>;
  selectedPrimaryColorRef: React.MutableRefObject<string>;
  selectedSecondaryColorRef: React.MutableRefObject<string>;
  selectedLayoutRef: React.MutableRefObject<LayoutCategory>;
}
function RegenerateConfirmationDialog({
  closeDialogHandler,
  accountDetailsRef,
  selectedToneRef,
  selectedLanguageRef,
  selectedLayoutRef,
  selectedPrimaryColorRef,
  selectedSecondaryColorRef,
}: RegenerateConfirmationDialogProps) {
  const {
    submitHandler,
    isSubmitting: isRegenerating,
    submitCompleted,
  } = useFormSubmission({
    selectedToneRef,
    accountDetailsRef,
    selectedLanguageRef,
    selectedLayoutRef,
    selectedPrimaryColorRef,
    selectedSecondaryColorRef,
    actionRoute: "/main/account-settings/ai-tuning",
    method: "put",
  });

  if (!submitCompleted) {
    return (
      <ConfirmationRequestContent
        confirmClickHandler={submitHandler}
        closeDialogHandler={closeDialogHandler}
        isGenerationInProgress={isRegenerating}
        description="Do you want to retrain tinyAlbert with an updated Tone of Voice , Content language and
        Company/Product details? Doing this may recreate some or all of your
        messages."
      />
    );
  }
  return (
    <SuccessMessageContent
      closeDialogHandler={closeDialogHandler}
      successMessage='You have successfully "Albertized" your store.`'
    />
  );
}

export { RegenerateConfirmationDialog };
