import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M10 4V11H4V4H2V20H4V13H10V20H12V4H10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M17.999 12.32L20.597 8H18.288L14.546 14.2218C14.199 14.791 14 15.4534 14 16.16C14 18.2816 15.79 20 18 20C20.21 20 22 18.2816 22 16.16C22 14.0384 20.209 12.32 17.999 12.32ZM16 16.16C16 15.0992 16.895 14.24 18 14.24C19.105 14.24 20 15.0992 20 16.16C20 17.2208 19.105 18.08 18 18.08C16.895 18.08 16 17.2208 16 16.16Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "HeadingH6"
);
