import useSearchParam from "@/hooks/router/useSearchParam";
import { debounce } from "@tiny/utils/debounce";
import { useSearchParams } from "react-router-dom";

function usePageNumber() {
  const [page, mutatePageParam] = useSearchParam("page", "1");
  const parsedPage = parseInt(page, 10);

  return [parsedPage, mutatePageParam] as const;
}

function usePageSize() {
  const paramName = "size";
  const [pageSize] = useSearchParam(paramName, "6");

  const [searchParams, setSearchParams] = useSearchParams();
  const onChangeSize = (newValue: string) => {
    searchParams.set(paramName, newValue);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  return [pageSize, onChangeSize] as const;
}

function usePageSort(defaultSort = "startTime,asc") {
  const paramName = "sort";
  const [pageSort] = useSearchParam(paramName, defaultSort);

  const [searchParams, setSearchParams] = useSearchParams();
  const onChangeSort = (newValue: string) => {
    searchParams.set(paramName, newValue);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  return [pageSort, onChangeSort] as const;
}

function useTextSearch(paramName = "searchTextName") {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchedText = searchParams.get(paramName);
  const onKeywordChange = debounce((newValue: string) => {
    searchParams.set(paramName, newValue);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  }, 600);

  return [searchedText, onKeywordChange] as const;
}

export { usePageNumber, usePageSize, usePageSort, useTextSearch };
