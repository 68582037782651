export function prepareTooltipTitle(
  title: string | undefined,
  minLenght?: number
) {
  if (minLenght && title) {
    return title.length > minLenght ? title : "";
  }
  return title || "";
}

export function prepareChipLabel(
  title: string | undefined,
  maxLenght?: number
) {
  if (maxLenght && title) {
    return title.length > maxLenght ? title.slice(0, maxLenght) + "..." : title;
  }
  return title || "";
}
