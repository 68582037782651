import React from "react";
import { IconButton, Menu, useTheme } from "@mui/material";
import { useIsLargerThan } from "@tiny/theme/utils";
import { MoreHorizontal } from "@tiny/icons";
import { hex2rgb } from "@tiny/utils/colors";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem";
import useActionMenuMobile from "@/components/ActionMenu/useActionMenuMobile";
import { ActionMenuProps } from "./types";

function ActionMenu(props: ActionMenuProps) {
  const { menuActions, withBackgroundAndBorderStyles, sx, onCloseMenu } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMdUp = useIsLargerThan("md");

  const openMenu = Boolean(anchorEl);

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenuHandler = () => {
    setAnchorEl(null);
    if (onCloseMenu) {
      onCloseMenu();
    }
  };

  const { onOpenActionMenuMobileClick } = useActionMenuMobile(
    menuActions,
    theme,
    onCloseMenuHandler
  );

  return (
    <>
      <IconButton
        id="action-menu-icon-button"
        aria-controls={openMenu ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={isMdUp ? handleOpenMenuClick : onOpenActionMenuMobileClick}
        sx={
          sx || [
            {
              width: theme.spacing(7),
              height: theme.spacing(7),
              position: "absolute",
              zIndex: 1,
              top: theme.spacing(4),
              right: theme.spacing(4),
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
            withBackgroundAndBorderStyles
              ? {
                  backgroundColor: "common.white",
                  border: "0.5px solid",
                  "&:hover": {
                    backgroundColor: "common.white",
                  },
                }
              : null,
            openMenu && {
              color: "primary.main",
            },
          ]
        }
      >
        <MoreHorizontal
          sx={{
            width: theme.spacing(5),
            height: theme.spacing(5),
          }}
        />
      </IconButton>
      <Menu
        open={openMenu}
        sx={{ mt: 3 }}
        id="action-menu"
        anchorEl={anchorEl}
        onClose={(event: React.MouseEvent<HTMLButtonElement>) => {
          event?.stopPropagation();
          onCloseMenuHandler();
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: `0px 30px 84px ${hex2rgb(
              theme.palette.grey[80],
              0.08
            )}, 0px 8px 32px ${hex2rgb(
              theme.palette.grey[80],
              0.07
            )}, 0px 3px 14px ${hex2rgb(
              theme.palette.grey[80],
              0.03
            )}, 0px 1px 3px ${hex2rgb(theme.palette.grey[80], 0.13)}`,
            width: theme.spacing(41),
          },
        }}
      >
        {menuActions.map((menuAction) => (
          <ActionMenuItem
            key={menuAction.name}
            theme={theme}
            name={menuAction.name}
            onClickAction={menuAction.onClickAction}
            handleCloseMenu={onCloseMenuHandler}
            id={menuAction.id}
          />
        ))}
      </Menu>
    </>
  );
}

export default ActionMenu;
