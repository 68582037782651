import {
  Facebook,
  Instagram,
  Linkedin,
  Pinterest,
  Twitter,
  Youtube,
} from "@tiny/icons";
import { Font } from "./brandkit.types";

export const fonts: Font[] = [
  { name: "Arial", safe: true },
  { name: "Brush Script MT", safe: true },
  { name: "Courier New", safe: true },
  { name: "Georgia", safe: true },
  { name: "Helvetica", safe: true },
  { name: "Tahoma", safe: true },
  { name: "Times New Roman", safe: true },
  { name: "Trebuchet MS", safe: true },
  { name: "Verdana", safe: true },
];

export interface Social {
  id: string;
  name: string;
  url: string;
}

export const socialOptions = [
  { id: "facebook", name: "Facebook", url: "https://www.facebook.com/" },
  { id: "instagram", name: "Instagram", url: "https://www.instagram.com/" },
  { id: "linkedin", name: "LinkedIn", url: "https://www.linkedin.com/" },
  { id: "pinterest", name: "Pinterest", url: "https://pinterest.com/" },
  { id: "x", name: "X", url: "https://x.com/" },
  { id: "youtube", name: "Youtube", url: "https://youtube.com/" },
];

export const mapSocialIconsByType: {
  [key: string]: React.ReactNode;
} = {
  facebook: <Facebook sx={{ color: "#3b5998" }} />,
  instagram: <Instagram sx={{ color: "#E1306C" }} />,
  linkedin: <Linkedin sx={{ color: "#0A66C2" }} />,
  pinterest: <Pinterest sx={{ color: "#c8232c" }} />,
  x: <Twitter sx={{ color: "#000000" }} />,
  youtube: <Youtube sx={{ color: "#ff0000" }} />,
};

export const getContrastColor = (inputColor: string): string => {
  let hexColor = inputColor;
  if (hexColor.charAt(0) === "#") {
    hexColor = hexColor.slice(1);
  }

  if (hexColor.length === 3) {
    hexColor = hexColor
      .split("")
      .map((hex: string) => hex + hex)
      .join("");
  }

  const r: number = parseInt(hexColor.substr(0, 2), 16);
  const g: number = parseInt(hexColor.substr(2, 2), 16);
  const b: number = parseInt(hexColor.substr(4, 2), 16);
  const lumens: number = (r * 299 + g * 587 + b * 114) / 1000;
  // here lumens can be between 0 and 256. so based on half of it we are deciding the black or white
  return lumens >= 128 ? "#000000" : "#ffffff";
};
