import { Box, Typography } from "@mui/material";
import { useDialogInvokers } from "@tiny/lib";
import { useRef } from "react";
import { RegenerateConfirmationDialog } from "@/components/ContentSetupStrategy/Dialogs/RegenerateConfirmationDialog";
import { useFormValidation } from "@/components/ContentSetupStrategy/formValidation.hooks";
import { LayoutCategory } from "@/types/api/accountTemplateLayouts";
import EditableFormContent from "@/components/ContentSetupStrategy/EditableFormContent";
import FormFooterCta from "@/components/ContentSetupStrategy/FormFooterCta";

function AiTuning() {
  const { requestInfoDialog, exitDialog } = useDialogInvokers();
  const selectedToneRef = useRef("");
  const selectedLanguageRef = useRef("");
  const accountDetailsRef = useRef("");
  const selectedLayoutRef = useRef<LayoutCategory>("SECOND");
  const selectedPrimaryColorRef = useRef("");
  const selectedSecondaryColorRef = useRef("");

  const { validate, errors } = useFormValidation({ accountDetailsRef });

  const clickHandler = () => {
    const { isValid } = validate();
    if (isValid) {
      requestInfoDialog(
        <RegenerateConfirmationDialog
          closeDialogHandler={exitDialog}
          accountDetailsRef={accountDetailsRef}
          selectedLanguageRef={selectedLanguageRef}
          selectedToneRef={selectedToneRef}
          selectedLayoutRef={selectedLayoutRef}
          selectedPrimaryColorRef={selectedPrimaryColorRef}
          selectedSecondaryColorRef={selectedSecondaryColorRef}
        />
      );
    }
  };

  return (
    <>
      <Typography variant="h4" mt={6} mb={4}>
        Setup Strategy
      </Typography>
      <Box sx={{ position: "relative" }}>
        <EditableFormContent
          accountDetailsRef={accountDetailsRef}
          selectedToneRef={selectedToneRef}
          selectedLanguageRef={selectedLanguageRef}
          selectedLayoutRef={selectedLayoutRef}
          selectedPrimaryColorRef={selectedPrimaryColorRef}
          selectedSecondaryColorRef={selectedSecondaryColorRef}
          errors={errors}
        />
        <FormFooterCta
          clickHandler={clickHandler}
          id="myaccount-aituning-generate"
        >
          Generate
        </FormFooterCta>
      </Box>
    </>
  );
}

export { AiTuning };
export { submitSetupStrategyAction } from "../../common";
