import { ActionFunctionArgs } from "react-router-dom";
import { fetchApi } from "@tiny/utils";
import { ApiError, InvalidCouponCodeError } from "@tiny/utils/errors";
import { PostChargeSubscriptionResponse } from "@/types/api/chargeSubscription";
import { GetSubscriptionsResponse } from "@/types/api/subscriptions";

type Actions = "applyCoupon" | "selectPlan";

async function planSelectionActions({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const action = formData.get("_action") as Actions;

  switch (action) {
    case "applyCoupon":
      return applyCoupon(formData, request.signal);
    case "selectPlan":
      return selectPlan(formData, request.signal);
    default:
      console.error("no action provided");
  }
}

async function applyCoupon(formData: FormData, signal: AbortSignal) {
  const couponCode = formData.get("couponCode");

  if (couponCode) {
    return fetchApi<GetSubscriptionsResponse>(
      `/accountservice/subscriptions?couponCode=${encodeURIComponent(
        couponCode.toString()
      )}`,
      {
        method: "get",
        signal,
      }
    )
      .then((response) => ({ couponCode, ...response }))
      .catch((err: ApiError) => {
        if (err instanceof InvalidCouponCodeError) {
          return err.body;
        }
        throw err;
      });
  }
}

async function selectPlan(formData: FormData, signal: AbortSignal) {
  const planId = formData.get("planId");
  const couponCode = formData.get("couponCode");

  await fetchApi<PostChargeSubscriptionResponse>(
    "/accountservice/charge-subscription",
    {
      method: "POST",
      body: JSON.stringify({
        id: planId,
        couponCode: couponCode ?? "",
      }),
      signal,
    }
  ).then((response) => {
    if (window.top && response?.confirmationUrl) {
      window.top.location.href = response.confirmationUrl;
    }
  });

  return null;
}

export { planSelectionActions };
