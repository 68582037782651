import * as React from "react";
import { CampaignsLoaderProps } from "@/routes/Campaigns/loader";
import { Box } from "@mui/material";
import { Await, Outlet, useLoaderData } from "react-router-dom";
import TabsHeader from "./TabsHeader";
import { CampaignsProvider } from "./Context";

function Campaigns() {
  const loaderData = useLoaderData() as CampaignsLoaderProps;

  return (
    <CampaignsProvider>
      <TabsHeader />
      <Box mt={6}>
        <Await resolve={loaderData}>
          <Outlet />
        </Await>
      </Box>
    </CampaignsProvider>
  );
}

export default Campaigns;
export { campaignsLoader } from "./loader";
export { draftCampaignsLoader } from "./draftsLoader";
