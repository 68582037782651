import { fetchApi } from "@tiny/utils";
import { GetSubscriptionsResponse } from "@/types/api/subscriptions";
import { LoaderFunctionArgs } from "react-router-dom";

const planSelectionLoader = ({ request }: LoaderFunctionArgs) =>
  fetchApi<GetSubscriptionsResponse>("/accountservice/subscriptions", {
    signal: request.signal,
  });

export { planSelectionLoader };
