import React from "react";
import clsx from "clsx";
import "./StripoView.css";

interface StripoViewProps {
  codeEditorButtonRef: React.RefObject<HTMLButtonElement>;
  historyButtonRef: React.RefObject<HTMLButtonElement>;
  settingsVisible?: boolean;
  historyVisible?: boolean;
  lgUp?: boolean;
}

function StripoView(props: StripoViewProps) {
  const {
    codeEditorButtonRef,
    historyButtonRef,
    settingsVisible,
    historyVisible,
    lgUp,
  } = props;

  const settingsClassName = clsx("stripo-settings", {
    "stripo-settings--hidden": !settingsVisible && !historyVisible,
    "stripo-settings--large": lgUp,
    "stripo-settings--panel-hidden": !settingsVisible,
    "stripo-settings--revision": !settingsVisible,
    "stripo-settings--icon": !settingsVisible,
  });

  return (
    <div className="stripo-root">
      <div className={settingsClassName} id="stripoSettingsContainer"></div>
      <div className="stripo-preview" id="stripoPreviewContainer"></div>
      <button
        type="button"
        ref={codeEditorButtonRef}
        className="stripo-codeeditor-button"
        id="stripoCodeEditorButton"
      >
        Code editor
      </button>
      <button
        type="button"
        ref={historyButtonRef}
        className="stripo-history-button"
        id="stripoHistoryButton"
      >
        History
      </button>
    </div>
  );
}

export default React.memo(StripoView);
