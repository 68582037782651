import { Breadcrumbs } from "@/components/Breadcrumbs";
import CenteredContainer from "@/components/CenteredContainer";
import { useState } from "react";
import * as React from "react";
import { Box } from "@mui/material";
import { Await, Outlet, useLoaderData, useNavigation } from "react-router-dom";
import {
  TinyDialogProvider,
  useScreenLoaderInvokers,
  TinyDrawerProvider,
} from "@tiny/lib";
import NotificationsManager from "@/components/Notifications";
import { GetNotificationResponse } from "@/types/api/notifications";
import { useIsSmallerThen } from "@tiny/theme/utils";
import Navigation from "./Navigation";

function LayoutMain() {
  const isSmDown = useIsSmallerThen("sm");
  const [openMenu, setOpenMenu] = React.useState(!isSmDown);
  const notificationData = useLoaderData() as {
    notifications: GetNotificationResponse;
  };
  const { hideScreenLoader, showScreenLoader } = useScreenLoaderInvokers();
  const navigation = useNavigation();

  const [isNotificationsVisible, setIsNotificationVisible] = useState(
    () => notificationData.notifications.accountIssues.length > 0
  );
  const toggleNavigation = React.useCallback(() => {
    if (isSmDown) {
      if (document.body.classList.contains("overflow-content")) {
        document.body.classList.remove("overflow-content");
      } else {
        document.body.classList.add("overflow-content");
      }
    }

    setOpenMenu((isOpened) => !isOpened);
  }, [isSmDown]);

  React.useEffect(() => {
    if (navigation.state === "loading") {
      showScreenLoader();
    } else {
      hideScreenLoader();
    }
  }, [navigation.state]);

  return (
    <TinyDialogProvider>
      <TinyDrawerProvider>
        <Box
          id="layout-main-route"
          sx={[
            {
              display: "flex",
              alignItems: "stretch",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            },
          ]}
        >
          <Navigation openMenu={openMenu} toggleNavigation={toggleNavigation} />
          <React.Suspense>
            <Await resolve={notificationData.notifications}>
              <NotificationsManager
                onVisibleNotificationsChange={setIsNotificationVisible}
                isMenuOpened={openMenu}
              />
            </Await>
          </React.Suspense>
          <Box
            component="main"
            id="main-content"
            flex="1 1 auto"
            sx={{ width: { sm: "60%" } }}
          >
            <CenteredContainer
              maxWidth="xl"
              sx={[
                {
                  m: 0,
                  justifyContent: "space-between",
                  mb: [12, 0],
                  pt: {
                    xs: 18,
                  },
                },
                !isNotificationsVisible && {
                  pt: {
                    xs: 4,
                    sm: 6,
                  },
                },
              ]}
            >
              <Breadcrumbs sx={{ mb: [5, 12] }} />
              <Outlet />
            </CenteredContainer>
          </Box>
        </Box>
      </TinyDrawerProvider>
    </TinyDialogProvider>
  );
}

export default LayoutMain;
export { mainLayoutLoader } from "./loader";
