import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import RefreshButton from "@/components/RefreshButton";
import { PageTitleProps } from "@/components/PageTitle/types";
import Edit from "@tiny/icons/generated/Edit";
import { useScreenLoaderInvokers } from "@tiny/lib";

function PageTitle(props: PageTitleProps) {
  const { title, subTitle, canRefresh, sx, onEdit = () => {}, canEdit } = props;
  const [editTitle, setEditTitle] = React.useState(false);
  const [titleValue, setTitleValue] = React.useState<string>(title);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderInvokers();

  const onSubmit = async () => {
    setErrorMessage("");
    if (titleValue !== title) {
      showScreenLoader();
      const response: any = await onEdit(titleValue);
      hideScreenLoader();
      if (!response?.id) {
        return setErrorMessage("Audience name already exists");
      }
    }
    return setEditTitle(false);
  };

  const onCancel = () => {
    setTitleValue(title);
    setErrorMessage("");
    setEditTitle(!editTitle);
  };

  React.useEffect(() => {
    setTitleValue(title);
  }, [title]);

  return (
    <Box
      id="page-title"
      display="flex"
      justifyContent="flex-start"
      flexDirection="column"
      mb={{ xs: 5, sm: 6 }}
      mt={0}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {editTitle ? (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" alignItems="center" sx={{ columnGap: 2 }}>
              <TextField
                label="Audience Name"
                variant="outlined"
                size="small"
                fullWidth
                required
                value={titleValue}
                onChange={(e) => setTitleValue(e.target.value)}
                onKeyUp={(e) => e.key === "Enter" && onSubmit()}
              />
              <Box display="flex" alignItems="center" sx={{ columnGap: 2 }}>
                <Button variant="contained" onClick={onSubmit}>
                  {" "}
                  Save{" "}
                </Button>
                <Button variant="outlined" onClick={onCancel}>
                  {" "}
                  Cancel{" "}
                </Button>
              </Box>
            </Box>
            <Box>
              <Typography
                color="error.50"
                sx={(theme) => ({
                  color: theme.palette.error.dark,
                  fontSize: 14,
                  ml: 2,
                })}
              >
                {errorMessage}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography variant="h2" m={0} color="grey.100" noWrap>
            {title}{" "}
            {canEdit && (
              <Edit
                onClick={() => setEditTitle(!editTitle)}
                sx={{ cursor: "pointer", fontSize: 18 }}
              />
            )}
          </Typography>
        )}

        {canRefresh && <RefreshButton />}
      </Box>

      {subTitle && (
        <Typography color="grey.70" variant="p3" display="block" mt={1}>
          {subTitle}
        </Typography>
      )}
    </Box>
  );
}

export default PageTitle;
