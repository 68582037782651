import * as React from "react";
import { useDialogManagerState } from "./DialogManagerState.hooks";
import { DialogManagerProvider } from "./DialogManager.context";
import { DialogManager } from "./DialogManager";

function TinyDialogProvider({ children }: { children: React.ReactNode }) {
  const dialogManagerContextValue = useDialogManagerState();
  return (
    <DialogManagerProvider value={dialogManagerContextValue}>
      {children}
      <DialogManager />
    </DialogManagerProvider>
  );
}

export { TinyDialogProvider };
