import { usePageNumber } from "@/components/PaginationRouted/usePagination.hook";
import { Pagination, PaginationItem, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

interface TinyPaginationProps {
  pagesCount: number;
}

function TinyPagination({ pagesCount }: TinyPaginationProps) {
  const [activePage, mutatePageParam] = usePageNumber();

  return (
    <Pagination
      page={activePage}
      count={pagesCount}
      shape="rounded"
      className="pagination"
      renderItem={(item) => (
        <PaginationItem
          component={item.page === activePage ? Typography : Link}
          to={`?${mutatePageParam((item.page ?? 0).toString())}`}
          sx={[item.page === activePage && { cursor: "auto" }]}
          {...item}
        />
      )}
    />
  );
}

export default TinyPagination;
