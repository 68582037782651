import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useCouponCodeDialog } from "@/dialogs/CouponCodeDialog";

function CouponCode({ couponFormAction }: { couponFormAction: string }) {
  const { onCouponLinkClickHandler } = useCouponCodeDialog(couponFormAction);

  return (
    <Box display="flex" sx={{ width: "100%", mb: 6 }}>
      <Typography variant="p2">Do you have a coupon code?</Typography>
      <Link
        underline="none"
        variant="p2"
        sx={{ pl: 1, cursor: "pointer" }}
        onClick={onCouponLinkClickHandler}
      >
        Use it now.
      </Link>
    </Box>
  );
}

export default CouponCode;
