import React from "react";

export function useRefCallBack<T extends (...args: any) => ReturnType<T>>(
  callback?: T
) {
  const funcRef = React.useRef<T | undefined>(callback);

  React.useLayoutEffect(() => {
    funcRef.current = callback;
  });

  return React.useCallback(
    // @ts-ignore
    (...args: Parameters<T>) => funcRef.current?.(...args),
    []
  );
}
