import * as React from "react";
import { authStorage } from "@tiny/utils";
import { initializeAuthStorage } from "./authInit";

const useOAuthInit = (url: URL) => {
  initializeAuthStorage(url);
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    authStorage.isInitialized()
  );

  return {
    isAuthenticated,
    setIsAuthenticated,
  };
};

export { useOAuthInit };
