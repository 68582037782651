import { useDialogInvokers } from "@tiny/lib";
import React from "react";
import CouponCodeForm from "@/components/DialogContent/CouponCodeForm";

function useCouponCodeDialog(couponFormAction: string) {
  const { requestSmallDialog } = useDialogInvokers();

  const onCouponLinkClickHandler = React.useCallback(
    () =>
      requestSmallDialog({
        titleProps: { children: "Coupon code", variant: "h3" },
        contentProps: {
          dividers: false,
          sx: {
            border: "none",
          },
          children: <CouponCodeForm action={couponFormAction} />,
        },
      }),
    [couponFormAction, requestSmallDialog]
  );

  return { onCouponLinkClickHandler };
}

export { useCouponCodeDialog };
