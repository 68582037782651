import { Button } from "@mui/material";
import { Filter } from "@tiny/icons";
import useFilterSubscriberDialog from "./useFilterSubscriberDialog";

function FilterCTA() {
  const showEditDialog = () => {
    showFilterDialog();
  };

  const { showFilterDialog } = useFilterSubscriberDialog();
  return (
    <Button
      onClick={showEditDialog}
      variant="outlined"
      endIcon={<Filter />}
      sx={{
        height: 40,
      }}
    >
      Filter
    </Button>
  );
}

export { FilterCTA };
