import { Box } from "@mui/material";
import React, { useRef } from "react";
import { LoadingButton } from "@mui/lab";
import { useFormSubmission } from "@/components/ContentSetupStrategy/formSubmition.hooks";
import { LayoutCategory } from "@/types/api/accountTemplateLayouts";
import OnboardingPageTitle from "@/components/OnboardingPageTitle";
import { useIsSmallerThen } from "@tiny/theme/utils";
import CenteredContainer from "../../components/CenteredContainer";
import EditableFormContent from "../../components/ContentSetupStrategy/EditableFormContent";

function BrandVoice() {
  const selectedToneRef = useRef("");
  const selectedLanguageRef = useRef("");
  const accountDetailsRef = useRef("");
  const selectedLayoutRef = useRef<LayoutCategory>("FIRST");
  const selectedPrimaryColorRef = useRef("");
  const selectedSecondaryColorRef = useRef("");
  const isSmDown = useIsSmallerThen("sm");

  const { submitHandler, isSubmitting, errors } = useFormSubmission({
    selectedToneRef,
    accountDetailsRef,
    selectedLanguageRef,
    selectedLayoutRef,
    selectedPrimaryColorRef,
    selectedSecondaryColorRef,
    actionRoute: "/onboarding/brand-voice",
    method: "post",
  });

  return (
    <CenteredContainer maxWidth={false}>
      <OnboardingPageTitle
        title="Onboarding"
        subTitle="Tell tinyAlbert about your business so your AI marketing manager can craft a unique voice for your brand."
      />
      <Box sx={{ position: "relative" }}>
        <EditableFormContent
          accountDetailsRef={accountDetailsRef}
          selectedToneRef={selectedToneRef}
          selectedLanguageRef={selectedLanguageRef}
          selectedLayoutRef={selectedLayoutRef}
          selectedPrimaryColorRef={selectedPrimaryColorRef}
          selectedSecondaryColorRef={selectedSecondaryColorRef}
          errors={errors}
        />
        <LoadingButton
          loading={isSubmitting}
          loadingIndicator="..."
          variant="contained"
          onClick={submitHandler}
          sx={{ position: "absolute", right: 0, mt: 5 }}
          fullWidth={isSmDown}
        >
          Submit
        </LoadingButton>
      </Box>
    </CenteredContainer>
  );
}

export default BrandVoice;
export * from "./action";
