import { RefreshTokenError } from "../errors";
import { TokenModel } from "./types";
import { APP_REFRESH_TOKEN_USER, APP_REFRESH_TOKEN_PASS } from "../envVars";
import { prepareRequest } from "./fetch/requestConfig";

export async function requestNewToken(refreshToken: string) {
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refreshToken);
  const { url } = prepareRequest(
    `/authorization-server/oauth/token?${params.toString()}`
  );
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${btoa(
          `${APP_REFRESH_TOKEN_USER}:${APP_REFRESH_TOKEN_PASS}`
        )}`,
      },
    });
    return (await response.json()) as TokenModel;
  } catch (e) {
    throw new RefreshTokenError("Failed to refresh access token");
  }
}
