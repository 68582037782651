import { ActionFunctionArgs } from "react-router-dom";
import { fetchApi } from "@tiny/utils";

type Actions = "regenerateAll";

async function campaignsActions({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const action = formData.get("_action") as Actions;

  switch (action) {
    case "regenerateAll":
      return regenerateAllCampaigns();
    default:
      console.log("no action provided");
  }
}

async function regenerateAllCampaigns() {
  return fetchApi("/campaignservice/campaign/generate", {
    method: "PUT",
  }).then(() => ({ ok: true }));
}

export { campaignsActions };
