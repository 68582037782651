import React from "react";

import { SimpleListItemProps } from "./SimpleListItem.types";
import { Box, ListItem } from "@mui/material";

function SimpleListItem(props: SimpleListItemProps) {
  const {
    children,
    bullet,
    className,
    contentClassName: contentClassNameProp,
    onClick,
    icon,
    size,
  } = props;

  return (
    <ListItem
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        position: "relative",
        minHeight: 52,
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        "&:first-of-type": {
          borderTop: 0,
        },
        [theme.breakpoints.up("lg")]: {
          minHeight: 40,
        },
        rootBullet: (props: SimpleListItemProps) => ({
          paddingLeft: 24,
          "&::before": {
            content: "''",
            position: "absolute",
            left: 0,
            width: 12,
            height: 12,
            marginTop: -3,
            background: props.bulletColor,
            borderRadius: 999,
          },
        }),
      })}
      onClick={onClick}
      tabIndex={onClick ? 0 : -1}
    >
      <Box
        sx={{
          flex: "1",
          position: "relative",
        }}
      >
        {children}
      </Box>
      {icon && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            "& > svg": {
              width: 20,
              height: 20,
            },
          }}
        >
          {icon}
        </Box>
      )}
    </ListItem>
  );
}

export default SimpleListItem;
