import { useAsyncValue, useRouteLoaderData } from "react-router-dom";
import { TableHeaderCellsTypes } from "@/components/Table/table.types";
import { usePageSort } from "@/components/PaginationRouted/usePagination.hook";
import { Box, Typography, useTheme } from "@mui/material";
import { formatNumber } from "@tiny/utils/formatUtils";
import { formatDate } from "@tiny/utils/dateUtils";
import React from "react";
import TinyTable from "@/components/Table";
import {
  GetGeneratedOrdersResponse,
  Orders,
} from "@/types/api/generatedRevenueOrders";
import { GetAccountResponse } from "@/types/api/account";
import { RootLoader } from "@/types/RootLoader";

const makeHeaderSourcesData = ({
  settings,
}: {
  settings: GetAccountResponse;
}) => {
  const { currency } = settings;
  const currencySymbol = currency
    ? Number()
        .toLocaleString(undefined, { style: "currency", currency })
        .slice(0, 1)
    : "currency N/A";
  return [
    { propName: "customerName", label: "Name / Email address" },
    { propName: "activityName", label: "Source" },
    { propName: "orderAmount", label: `Order value (${currencySymbol})` },
    { propName: "orderDate", label: "Date of order" },
    { propName: "orderId", label: "Order ID" },
    { propName: "totalProducts", label: "Unique products" },
  ] as TableHeaderCellsTypes<Orders>[];
};

function OrdersTable() {
  const orders = useAsyncValue() as GetGeneratedOrdersResponse;
  const theme = useTheme();
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;

  const formattedOrdersRow = orders.content.map((order: Orders) => ({
    ...order,
    customerName: (
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{
          paddingTop: { xs: 2, sm: 0 },
        }}
      >
        <Typography variant="label2R" color="grey.100">
          {order.customerName}
        </Typography>
        <Typography variant="label2R" color="grey.80">
          {order.customerEmail}
        </Typography>
      </Box>
    ),
    orderAmount: (
      <Typography variant="label2R" color="grey.80">
        {formatNumber(order.orderAmount)}
      </Typography>
    ),
    orderDate: (
      <Typography variant="label2R" color="grey.80">
        {formatDate(new Date(order.orderDate), "dd.MM.yyy")}
      </Typography>
    ),
    orderId: (
      <a
        href={
          order.orderUrl.startsWith("https://")
            ? order.orderUrl
            : `https://${order.orderUrl}`
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: theme.palette.grey[80] }}
      >
        {order.orderId}
      </a>
    ),
    activityName: <Box sx={{ width: 220 }}>{order.activityName}</Box>,
  }));

  const headerCellData = makeHeaderSourcesData({ settings: accountInfo });

  const [currentSort, onChangeSort] = usePageSort("customerName,asc");

  return (
    <TinyTable
      headerCells={headerCellData}
      rows={formattedOrdersRow}
      rowsData={orders.content}
      options={{ currentSort, onSort: onChangeSort }}
    />
  );
}

export default OrdersTable;
