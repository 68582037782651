import { fetchApi } from "@tiny/utils";
import { downloadFileData } from "@tiny/utils/fileUtils";

export type ActivityReportType = "CAMPAIGN" | "AUTOMATION";

function useExportReports() {
  return async function exportReports(
    type: ActivityReportType[],
    id?: Array<string>
  ) {
    const requestBody = {
      from: null,
      to: null,
      activityTypes: type,
      activityIds: id,
    };

    await fetchApi("/reportservice/order/export/filter", {
      body: JSON.stringify(requestBody),
      method: "PUT",
    }).then(async (r: any) => {
      const fileBlob = await r.blob();

      const headers = {
        "content-disposition": r.headers.get("content-disposition"),
        "content-length": r.headers.get("content-length"),
        "content-type": r.headers.get("content-type"),
      };

      downloadFileData(fileBlob, headers);
    });
  };
}

export { useExportReports };
