import React from "react";
import ImageUpload from "@/components/FormItems/ImageUpload/ImageUpload";
import { Controller, useFormContext } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { useToast } from "@/components/ToastService/useToast";

function LogoSection() {
  const { control } = useFormContext();
  const { showToast } = useToast();
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        px: { xs: 4, md: 6 },
        py: { xs: 4, md: 4 },
        borderRadius: 2,
        mb: 5,
        border: `0.5px solid ${theme.palette.grey[60]}`,
      })}
    >
      <Typography variant="h4" color="grey.100" sx={{ width: "100%", mb: 4 }}>
        Logo
      </Typography>
      <Controller
        name="logoUrl"
        control={control}
        render={({ field: { onChange, value } }) => (
          <ImageUpload
            value={value}
            maxSize={1}
            onChangeValue={(file) => {
              onChange(file);
            }}
            onError={(msg) => {
              showToast({
                message: msg,
                type: "error",
                duration: 3000,
                portalId: "toast-container",
              });
            }}
            acceptedTypes={["image/jpeg", "image/jpg", "image/png"]}
          />
        )}
      />
    </Box>
  );
}

export default LogoSection;
