import { FontWeight } from "../../typography";

const styleOverrides = {
  root: {
    root: {
      color: "grey.80",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
      fontWeight: FontWeight.semibold,
    },
  },
};

export { styleOverrides };
