import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import { GetCampaignResponse } from "@/types/api/campaigns";

export const defaultApiQueryParams = {
  page: "0",
  size: "6",
};

interface CampaignsLoaderProps {
  campaigns: GetCampaignResponse;
}

const campaignsLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const requestParams = Object.fromEntries(url.searchParams);
  const apiPage = (parseInt(requestParams.page ?? 1, 10) - 1).toString();

  const paths = url.pathname.split("/");
  const path = paths[paths.length - 1];
  let additionalProp = {};
  const dateIsoString = new Date().toISOString();
  const date = dateIsoString.substring(0, dateIsoString.length - 5);
  let sortParam = "";

  if (path === "recurring-campaigns") {
    const apiParams = {
      ...defaultApiQueryParams,
      ...requestParams,
      page: apiPage,
    };

    const apiQueryString = new URLSearchParams(apiParams).toString();

    const recurringCampaigns = await fetchApi<GetCampaignResponse>(
      `/campaignservice/campaign/find/filter?${apiQueryString}`,
      {
        signal: request.signal,
        method: "put",
        body: JSON.stringify({
          campaignStatuses: ["RECURRING", "STOPPED"],
        }),
      }
    );

    return defer({
      campaigns: { campaigns: recurringCampaigns },
    });
  }

  if (path === "past-campaigns") {
    additionalProp = { "scheduled-to": date };
    sortParam = "startTime,desc";
  } else if (path === "future-campaigns") {
    additionalProp = { "scheduled-from": date };
    sortParam = "startTime,asc";
  }
  sortParam = requestParams.sort ? requestParams.sort : sortParam;
  const apiParams = {
    ...defaultApiQueryParams,
    ...requestParams,
    ...additionalProp,
    page: apiPage,
    sort: sortParam,
  };
  const apiQueryString = new URLSearchParams(apiParams).toString();

  const campaignsPromise = fetchApi<GetCampaignResponse>(
    `/campaignservice/campaign?${apiQueryString}`,
    { signal: request.signal }
  );

  return defer({
    campaigns: await campaignsPromise,
  });
};

export { campaignsLoader };
export type { CampaignsLoaderProps };
