import React from "react";
import { useApp } from "./App.hooks";

type ContextValue = ReturnType<typeof useApp>;

const AppContext = React.createContext<ContextValue | null>(null);

export function useAppView() {
  const contextValue = React.useContext(AppContext);
  if (!contextValue) {
    throw Error("Should be used inside the AppProvider");
  }
  return contextValue;
}

export const AppProvider = AppContext.Provider;
