import ErrorBoundary from "@/components/ErrorBoundary/index";
import { useNavigate } from "react-router-dom";

/**
 * Uses browser's history to ga back to previous route, without triggering page refresh
 */
function ErrorBoundaryGoBack() {
  const navigate = useNavigate();
  return (
    <ErrorBoundary reloadHandler={() => navigate(-1)} buttonName="Go Back" />
  );
}

export { ErrorBoundaryGoBack };
