import { FormStatusSwitcher } from "@/components/Form/FormStatusSwitcher";
import { Box, Button, Typography } from "@mui/material";
import { ExternalLink, WindowCheck } from "@tiny/icons";
import * as React from "react";
import { AutomationFooterProps } from "@/components/Automation/types";
import { useDialogInvokers, useDrawerInvokers } from "@tiny/lib";
import AutomationPreview from "./AutomationPreview";

function AutomationFooter(props: AutomationFooterProps) {
  const {
    active = false,
    onChangeActivation,
    status,
    id,
    comingSoon,
    editable,
    automationName,
  } = props;
  const { showDrawer } = useDrawerInvokers();
  const { exitDialog } = useDialogInvokers();

  const previewColor = React.useMemo(
    () => (active ? "primary.100" : "grey.80"),
    [active]
  );

  const onChange = React.useCallback(
    (_: any, isActivated: boolean) => {
      onChangeActivation?.(isActivated);
    },
    [onChangeActivation]
  );

  const previewClickHandler = () => {
    const showInfoMessage: boolean =
      automationName === "Abandoned Cart" ||
      automationName === "Product Review" ||
      automationName === "Thank You For Purchase";
    exitDialog();
    showDrawer({
      children: (
        <AutomationPreview
          name={automationName || ""}
          automationId={id}
          showRegenerate={editable}
          showInfoMessage={showInfoMessage}
          regenerate={false}
          enabled={active}
        />
      ),
    });
  };

  return !comingSoon ? (
    <Box display="flex" width="100%" justifyContent="space-between">
      <Button
        className="preview-cta"
        variant="text"
        endIcon={<ExternalLink />}
        sx={{ fontWeight: 400, m: 0, p: 0, lineHeight: 1, color: previewColor }}
        onClick={previewClickHandler}
        id="automations-overview-list-preview"
      >
        Preview
      </Button>
      <Box display="flex" alignItems="center" gap={1}>
        <FormStatusSwitcher
          status={status}
          activeSwitch={active}
          onChangeSwitch={onChange}
          id="automations-overview-list-toggle-activate"
        />
      </Box>
    </Box>
  ) : (
    <Box display="flex" gap={1} alignItems="center">
      <WindowCheck sx={{ color: "primary.light" }} />
      <Typography color="primary.light" variant="p2">
        Coming soon
      </Typography>
    </Box>
  );
}

export default AutomationFooter;
