import * as React from "react";

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
function createCtx<A>() {
  const ctx = React.createContext<A | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (c === undefined) {
      throw new Error("useCtx must be inside a Provider with a value");
    }
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

/**
 * Variant of createCtx util, which doesn't throw error when useCtx hook
 * is invoked outside of Context Provider
 * TODO: refactor somehow the 2 separate utils into a reusable one, without breaking
 * current types and usages
 */
function createOptionalCtx<A>() {
  const ctx = React.createContext<A | undefined>(undefined);
  function useCtx() {
    return React.useContext(ctx);
  }
  return [useCtx, ctx.Provider] as const;
}

/**
 * Variant of the createCtx util
 * When useCtx hook is executed, if components is not a child of the Context Provider,
 * don't throw, but return a default value provided on Context creation, instead
 */
function createCtxWithDefaultValue<A>(defaultContext: A) {
  const ctx = React.createContext<A | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (c === undefined) {
      return defaultContext;
    }
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

export { createCtx, createOptionalCtx, createCtxWithDefaultValue };
