import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import { GetHeroImageResponse } from "./types";

export const defaultApiQueryParams = {
  page: "0",
  size: "6",
};

interface HeroImageLoaderProps {
  hero: GetHeroImageResponse;
}

const heroImageLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request?.url);
  const requestParams = Object.fromEntries(url?.searchParams);
  const apiPage = (parseInt(requestParams?.page ?? 1, 10) - 1)?.toString();
  let body = {};
  if (requestParams?.imageType === "HERO") {
    body = {
      imageTypes: ["HERO"],
    };
  } else {
    body = {
      imageTypes: ["HERO_CANDIDATE", "HERO"],
    };
  }
  const apiParams = {
    ...defaultApiQueryParams,
    ...requestParams,
    page: apiPage,
  };
  const apiQueryString = new URLSearchParams(apiParams)?.toString();

  const heroImagePromise = await fetchApi<GetHeroImageResponse>(
    `/accountservice/account/image?${apiQueryString}`,
    { signal: request?.signal, method: "PUT", body: JSON.stringify(body) }
  );

  return defer({
    heroImages: await heroImagePromise,
  });
};

export { heroImageLoader };
export type { HeroImageLoaderProps };
