import { v4 as uuid } from "uuid";
import {
  DialogConfig,
  DialogPropsOverrides,
  InstantiatedDialogConfig,
} from "./DialogManager.types";

const createDialogConfig = (
  dialogOverrides: DialogPropsOverrides,
  requestedConfig: DialogConfig
): InstantiatedDialogConfig => {
  const {
    overrides = {},
    titleProps,
    contentProps,
    actionProps,
    onClose,
  } = requestedConfig;

  return {
    id: `${dialogOverrides?.maxWidth?.toString() ?? "dialog"}-${uuid()}`,
    overrides: {
      ...dialogOverrides,
      ...overrides,
    },
    ...(titleProps ? { titleProps } : {}),
    contentProps,
    ...(actionProps ? { actionProps } : {}),
    onClose,
  };
};

export { createDialogConfig };
