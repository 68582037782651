import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <rect
      x="1.16602"
      y="1"
      width="19"
      height="19"
      rx="3.5"
      xmlns="http://www.w3.org/2000/svg"
    />
    <rect
      x="1.16602"
      y="1"
      width="19"
      height="19"
      rx="3.5"
      stroke="#9FA1A8"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "UncheckedCheckbox"
);
