import { PopoverOrigin } from "@mui/material";

export const anchorOriginDefault: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

export const transformOriginDefault: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};
