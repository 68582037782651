import { v4 as uuid } from "uuid";
import { GetAudienceOverviewResponse } from "@/types/api/reportsOverview";
import { formatNumber } from "@tiny/utils/formatUtils";
import { Metric } from "../MetricCard/types";

function totalSubscribersMapper(input: number): Metric {
  return {
    title: "Total Subscribers",
    value: typeof input === "undefined" ? "N/A" : formatNumber(input),
    id: uuid(),
  };
}

function collectedSubscribersMapper(input: number): Metric {
  return {
    title: "Collected Subscribers from tinyAlbert",
    value: typeof input === "undefined" ? "N/A" : formatNumber(input),
    id: uuid(),
  };
}

function collectedCustomersMapper(input: number): Metric {
  return {
    title: "Collected Customers from tinyAlbert",
    value: typeof input === "undefined" ? "N/A" : formatNumber(input),
    id: uuid(),
  };
}

function totalCustomersMapper(input: number): Metric {
  return {
    title: "Total Customers",
    value: typeof input === "undefined" ? "N/A" : formatNumber(input),
    id: uuid(),
  };
}

export function audiencesMetricMapper(
  src: GetAudienceOverviewResponse
): Metric[] {
  const {
    totalCustomers,
    collectedCustomers,
    totalSubscribers,
    collectedSubscribers,
  } = src;
  return [
    totalCustomersMapper(totalCustomers),
    collectedCustomersMapper(collectedCustomers),
    totalSubscribersMapper(totalSubscribers),
    collectedSubscribersMapper(collectedSubscribers),
  ];
}
