import React from "react";
import { useRouteError } from "react-router-dom";
import CenteredContainer from "@/components/CenteredContainer";
import { Box, Button, Typography } from "@mui/material";
import { captureExceptionToSentry } from "@tiny/sentry";
import { authStorage } from "@tiny/utils";
import errorImage from "../../assets/images/errorImage.png";

interface ErrorBoundaryProps {
  reloadHandler?: () => void;
  buttonName?: string;
}
function ErrorBoundary({
  reloadHandler = () => {
    const url = window.location.href;
    window.location.href = `${url}${
      url.indexOf("?") > 0 ? "&" : "?"
    }access_token=${authStorage?.getAccessToken() ?? ""}&refresh_token=${
      authStorage?.getRefreshToken() ?? ""
    }`;
  },
  buttonName = "Reload Page",
}: ErrorBoundaryProps) {
  const error = useRouteError();
  console.error(error);
  captureExceptionToSentry(error);

  return (
    <CenteredContainer sx={{ display: "flex", alignItems: "center" }}>
      <img
        style={{ width: "200px", opacity: "0.4" }}
        src={errorImage}
        alt="error"
      />
      <Box
        display="flex"
        gap={2}
        flexDirection="column"
        alignItems="center"
        pb={8}
        textAlign="center"
        maxWidth={328}
      >
        <Typography variant="h2" color="primary.main">
          Oops! Looks like you have some connectivity issues.
        </Typography>
        <Typography variant="p1" color="grey.80">
          You can save the day by giving that trusty reload button a tap.
        </Typography>
      </Box>

      <Button disableElevation variant="contained" onClick={reloadHandler}>
        {buttonName}
      </Button>
    </CenteredContainer>
  );
}

export default ErrorBoundary;
