import React from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import * as locale from "date-fns/locale";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import MonthViewCard from "./MonthViewCard";
import MonthViewEvent from "./MonthViewEvent";
import { CalendarLoaderProps } from "../loader";
import "./calendar-styles.css";
import DateCellWrapper from "./DateCellWrapper";

const locales = {
  "en-US": locale.enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CustomToolbar = () => null;
const TinyCalendar = ({ viewDate }: { viewDate: Date }) => {
  const { campaigns } = useLoaderData() as CalendarLoaderProps;

  const events = campaigns?.campaigns?.content?.map((campaign) => ({
    ...campaign,
    title: campaign.name,
    start: new Date(campaign.startTime),
    end: new Date(campaign.completedTime || campaign.startTime),
  }));

  const dateHeader = ({ date }: { date: Date }) => (
    <MonthViewCard viewDate={viewDate} date={date} />
  );

  return (
    <Box
      sx={{
        backgroundColor: "common.white",
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      <Calendar
        localizer={localizer}
        events={events}
        date={viewDate}
        startAccessor="start"
        endAccessor="end"
        defaultView="month"
        style={{ height: 600 }}
        onNavigate={() => {}}
        components={{
          toolbar: CustomToolbar,
          dateCellWrapper: DateCellWrapper,
          month: {
            header: () => null,
            event: MonthViewEvent,
            dateHeader,
          },
        }}
        popup
        showAllEvents
      />
    </Box>
  );
};

export default TinyCalendar;
