import React from "react";
import { useConfig } from "./Config.hooks";

type ContextValue = ReturnType<typeof useConfig>;

const ConfigContext = React.createContext<ContextValue | null>(null);

export function useConfigView() {
  const contextValue = React.useContext(ConfigContext);
  if (!contextValue) {
    throw Error("Should be used inside the ConfigProvider");
  }
  return contextValue;
}

export const ConfigProvider = ConfigContext.Provider;
