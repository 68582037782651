import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { formatDate } from "@tiny/utils/dateUtils";
import { CalendarEvent } from "@tiny/icons";
import { getCampaign } from "./helpers";
import { CampaignFooterProps } from "./types";

function CampaignFooter(props: CampaignFooterProps) {
  const {
    startTime,
    status,
    active,
    onActivationChange,
    outdated,
    sx,
    generatedBy,
  } = props;

  const onChange = React.useCallback(
    (_: any, isActivated: boolean) => {
      onActivationChange?.(isActivated);
    },
    [onActivationChange]
  );

  const campaignDate = startTime
    ? formatDate(new Date(startTime), "MM/dd/yyyy h:mm a")
    : undefined;

  return (
    <Box
      sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}
      className="campaign-footer"
    >
      <Box display="flex" justifyContent="space-between">
        {campaignDate ? (
          <Box display="flex" alignItems="center" gap={1}>
            {getCampaign({
              status,
              active,
              onChange,
              outdated,
              activationToggleId: active
                ? "campaigns-overview-list-toggle-deactivate"
                : "campaigns-overview-list-toggle-activate",
            })}
          </Box>
        ) : (
          <div />
        )}
        {campaignDate && (
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            className="campaign-date"
          >
            <CalendarEvent
              sx={(theme) => ({
                fill: theme.palette.grey[80],
              })}
            />
            <Typography variant="p3" color="grey.80">
              {campaignDate}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default CampaignFooter;
