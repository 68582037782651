import EmptyState from "@/components/EmptyState";
import PageTitle from "@/components/PageTitle";
import PageSize from "@/components/PaginationRouted/PageSize";
import TinyPagination from "@/components/PaginationRouted/Pagination";
import TextSearch from "@/components/TextSearch";
import { SubscribersTable } from "@/routes/Audience/Subscribers/SubscribersTable";
import { GetAudienceSubscribersResponse } from "@/types/api/subscribers";
import Search from "@tiny/icons/generated/Search";
import * as React from "react";
import { Box, Button, Grid, Theme } from "@mui/material";
import {
  Await,
  useLoaderData,
  Link as RouterLink,
  useParams,
  useNavigate,
} from "react-router-dom";
import { fetchApi } from "@tiny/utils";
import { useDialogInvokers, useScreenLoaderInvokers } from "@tiny/lib";
import { useTinyImporter } from "@/components/TinyCsvImporter/useTinyImporter";
import { AudienceUpdateResponse } from "@/components/TinyCsvImporter/TinyImporter.types";
import { showUploadMessage } from "@/components/Snackbar/snackbar-helper";
import { closeSnackbar } from "notistack";
import { useRefreshButton } from "@/hooks/useRefreshButton";
import { SubscribersMetrics } from "./SubscrbersMetrics";
import { FilterCTA } from "./FilterCTA";

function Subscribers() {
  const { proceedToImporter } = useTinyImporter();
  const { subscribersData } = useLoaderData() as {
    subscribersData: GetAudienceSubscribersResponse["page"];
  };
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderInvokers();
  const { exitDialog } = useDialogInvokers();
  const { audienceId = "" } = useParams();
  const [audienceName, setAudienceName] = React.useState<string>("");
  const [type, setType] = React.useState<string>("INTEGRATION");
  const navigate = useNavigate();
  const { clickHandler } = useRefreshButton();

  const UpdateAudienceName = async (name: string) => {
    try {
      const url = `/customerservice/audience/${audienceId}`;
      const response = await fetchApi(url, {
        method: "PUT",
        body: JSON.stringify({
          name,
        }),
      });
      await fetchAudienceDetails();
      return response;
    } catch (error) {
      return new Error("Audience name already exists");
    }
  };

  const handleAudienceUpload = async (data: any) => {
    try {
      showUploadMessage({
        variant: "uploading",
        message: "Uploading file",
        description: `Your ${audienceName} audience is uploading`,
        persist: true,
      });
      const url = `/customerservice/audience/${audienceId}/customers`;
      const response = await fetchApi<AudienceUpdateResponse>(url, {
        method: "POST",
        body: JSON.stringify(data),
      });
      exitDialog();
      closeSnackbar();
      showUploadMessage({
        variant: "uploadSuccess",
        message: "Uploaded successfully!",
        description: `Your ${audienceName} audience has been uploaded successfully!`,
        ctaActionText: "View",
        ctaActionFunction: () => {
          navigate(`/main/audience/${response.id}/subscribers`);
          clickHandler(); // to refetch the audience data after posting.
          closeSnackbar();
        },
        persist: true,
      });
      return response;
    } catch (error) {
      closeSnackbar();
      showUploadMessage({
        variant: "uploadFailed",
        message: "Uploading error!",
        description:
          "It seems file size is large for browser. Please contact the support team.",
        hideOnClick: true,
      });
      throw new Error();
    }
  };

  const fetchAudienceDetails = async () => {
    showScreenLoader();
    try {
      const url = `/customerservice/audience/${audienceId}`;
      const response = await fetchApi<any>(url, {
        method: "GET",
      });
      const { name, audienceType } = response;
      setAudienceName(name);
      setType(audienceType);
    } catch (error) {
      throw new Error();
    }
    hideScreenLoader();
  };

  React.useEffect(() => {
    fetchAudienceDetails();
  }, []);

  return (
    <>
      <PageTitle
        title={audienceName}
        onEdit={UpdateAudienceName}
        canEdit={type === "UPLOAD"}
      />

      <React.Suspense>
        <Await resolve={subscribersData}>
          {(subscribers: GetAudienceSubscribersResponse["page"]) => (
            <>
              <Grid
                container
                id="statistics-section"
                spacing={{ xs: 2, md: 4 }}
                columns={{ xs: 12, md: 12 }}
              >
                <SubscribersMetrics
                  subscribersCount={subscribers.totalElements}
                />
              </Grid>
              <Box
                display="flex"
                justifyContent="space-between"
                mb={5}
                mt={6}
                sx={{ columnGap: 2 }}
              >
                <Box display="flex" alignItems="center" sx={{ columnGap: 2 }}>
                  <TextSearch
                    paramName="searchTextName"
                    id="search-for-customers"
                  />
                  <FilterCTA />
                </Box>
                <Box display="flex" alignItems="center" sx={{ columnGap: 2 }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      proceedToImporter({
                        importType: "UPDATE",
                        onSuccess: (importedData: any) => {
                          handleAudienceUpload(importedData);
                        },
                        audienceId,
                        audienceName,
                      });
                    }}
                    sx={{
                      display: {
                        md: "flex",
                        sm: "none",
                      },
                    }}
                  >
                    Import
                  </Button>
                  <Button
                    variant="outlined"
                    component={RouterLink}
                    to=".."
                    id="audience-subscribers-go-back"
                  >
                    Go back
                  </Button>
                </Box>
              </Box>
              {subscribers.empty ? (
                <EmptyState
                  Icon={
                    <Search
                      sx={(theme: Theme) => ({
                        color: "grey.80",
                        width: theme.spacing(6),
                        height: theme.spacing(6),
                      })}
                    />
                  }
                  title="There are no results that match your search."
                  message="Please try adjusting your search keywords or filters."
                />
              ) : (
                <>
                  <SubscribersTable />
                  <Box display="flex" justifyContent="space-between" mt={5}>
                    <PageSize />
                    <TinyPagination pagesCount={subscribers.totalPages} />
                  </Box>
                </>
              )}
            </>
          )}
        </Await>
      </React.Suspense>
    </>
  );
}

export default Subscribers;
export { subscribersLoader } from "./loader";
