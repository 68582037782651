import { initializeAuthStorage } from "@/hooks/authInit";
import { computeForcedRedirectURL } from "@/routes/RootRoute/forceRedirects";
import { defer, LoaderFunctionArgs, redirect } from "react-router-dom";
import type { GetAccountResponse } from "@/types/api/account";
import { fetchApi } from "@tiny/utils";
import type { UserStatusOverview } from "@/contexts/user";

const rootLoader = async ({ request }: LoaderFunctionArgs) => {
  /*
    React Router executes loaders out of the render cycle (before <App> executes),
    so must initialize authStorage here first
  */
  initializeAuthStorage(new URL(request.url));
  const userStatus = await fetchApi<UserStatusOverview>(
    "/authorization-server/user/status/overview",
    { signal: request.signal }
  );

  if (userStatus) {
    const redirectUrl = computeForcedRedirectURL(
      request.url,
      userStatus.onboardingStep
    );

    if (redirectUrl) {
      return redirect(redirectUrl);
    }
  }

  const account = fetchApi<GetAccountResponse>("/accountservice/account", {
    signal: request.signal,
  });

  return defer({
    userStatus: await userStatus,
    accountInfo: await account,
  });
};

export { rootLoader };
