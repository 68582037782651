import WelcomeBadge from "@/assets/svgs/welcomeBadge.svg";
import { Button, CircularProgress, Typography } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { NotificationDialog } from "@tiny/lib/ConfirmationDialog/DialogTypes/NotificationDialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function WelcomeDialog({ redirectPath }: { redirectPath: string }) {
  const navigate = useNavigate();
  const redirect = () => {
    navigate(redirectPath);
  };
  const [isLoading, setIsLoading] = useState(true);

  const loadHandler = () => {
    setIsLoading(false);
  };

  return (
    <NotificationDialog
      open
      onClose={redirect}
      content={
        <>
          <Typography variant="h2">Welcome!</Typography>
          <img alt="welcome badge" src={WelcomeBadge} onLoad={loadHandler} />
          {!isLoading ? (
            <>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{ textAlign: "center" }}
                variant="p2"
              >
                'You are capable of more than you know.'
                <br />
                <Typography variant="label2">Benjamin Spock</Typography>
              </DialogContentText>
              <Button variant="contained" onClick={redirect}>
                Let's Go!
              </Button>
            </>
          ) : (
            <CircularProgress size={48} />
          )}
        </>
      }
    />
  );
}

export default WelcomeDialog;
