import { usePageSort } from "@/components/PaginationRouted/usePagination.hook";
import TinyTable from "@/components/Table";
import { TableHeaderCellsTypes } from "@/components/Table/table.types";
import { ViewSubscribersCTA } from "@/routes/Audience/Sources/ViewSubscribersCTA";
import { Typography } from "@mui/material";
import { formatDate } from "@tiny/utils/dateUtils";
import React from "react";
import { useAsyncValue, useNavigate } from "react-router-dom";
import { Audience } from "@/types/api/audience";

const headerColumns: TableHeaderCellsTypes<Audience>[] = [
  {
    propName: "name",
    label: "Audience",
  },
  { propName: "createdAt", label: "Created" },
  { propName: "numberOfCustomers", label: "Customers" },
  { propName: "lastAddedAt", label: "Last added" },
];

function AudienceSourcesTable() {
  const navigate = useNavigate();
  const audiences = useAsyncValue() as Audience[];
  const [currentSort, onChangeSort] = usePageSort();

  const formattedRows = audiences.map((audience) => ({
    ...audience,
    name: (
      <Typography variant="label2R" color="grey.100">
        {audience.name}
      </Typography>
    ),
    createdAt: (
      <Typography variant="label2R" color="grey.80">
        {formatDate(new Date(audience.createdAt), "dd.MM.yyy")}
      </Typography>
    ),
    lastAddedAt: (
      <Typography variant="label2R" color="grey.80">
        {audience.lastAddedAt && audience.numberOfCustomers > 0
          ? formatDate(new Date(audience.lastAddedAt), "dd.MM.yyy")
          : "N/A"}
      </Typography>
    ),
  }));
  const navigateToSubscribers = (tableRowData: Audience) => {
    const { id } = tableRowData;
    navigate(`${id}/subscribers`);
  };

  return (
    <TinyTable
      rows={formattedRows}
      rowsData={audiences}
      headerCells={headerColumns}
      options={{
        currentSort,
        onSort: onChangeSort,
        ActionCTA: ViewSubscribersCTA,
        RowCTA: navigateToSubscribers,
      }}
      sx={{ mt: 3 }}
    />
  );
}

export default AudienceSourcesTable;
