import React from "react";

import { ChevronBigDown, ChevronBigUp } from "@tiny/icons";
import useForwardRef from "../../hooks/useForwardRef";
import useId from "../../hooks/useId";
import { Button, Menu } from "@mui/material";
import { MenuButtonProps } from "./MenuButton.types";
import {
  anchorOriginDefault,
  transformOriginDefault,
} from "./MenuButton.helpers";

const TOTAL_MENU_PADDING_X = 24;

function MenuButton(
  props: MenuButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    anchorOrigin = anchorOriginDefault,
    children,
    text,
    onClick,
    transformOrigin = transformOriginDefault,
    size,
    ...other
  } = props;

  const buttonRef = useForwardRef(ref);

  const menuId = useId();

  const [open, setOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    onClick?.(event);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button {...other} ref={buttonRef} size={size} onClick={handleClick}>
        {text}
        {open ? (
          <ChevronBigUp
            style={{
              marginLeft: 8,
              width: 20,
              height: 20,
            }}
          />
        ) : (
          <ChevronBigDown
            style={{
              marginLeft: 8,
              width: 20,
              height: 20,
            }}
          />
        )}
      </Button>
      <Menu
        // size={size}
        anchorEl={buttonRef.current}
        anchorOrigin={anchorOrigin}
        disableScrollLock
        // getContentAnchorEl={undefined}
        id={menuId}
        onClose={handleClose}
        open={open}
        transformOrigin={transformOrigin}
        onClick={handleClose}
        MenuListProps={{
          style: {
            minWidth: buttonRef.current
              ? buttonRef.current.clientWidth - TOTAL_MENU_PADDING_X
              : undefined,
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
}

export default React.forwardRef(MenuButton);
