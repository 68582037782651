import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M10.8506 6.59396C10.9568 6.36797 11.0741 6.14366 11.2026 5.92193L11.2296 5.87564L11.2449 5.84972C11.4719 5.46619 11.7228 5.10753 11.993 4.77548C13.0438 3.48438 14.3876 2.59573 15.7627 2.21255C15.7676 2.21118 15.7725 2.20981 15.7774 2.20845C17.0805 1.84885 18.4109 1.94329 19.5461 2.57941L19.5776 2.59723C22.2229 4.10766 22.7894 8.014 20.8416 11.3296C19.1206 14.2591 16.0119 15.7486 13.4808 15.0238L17 21.0109L15.2557 22L12.0008 16.4623L8.74759 22L6.99995 21.0083L11.3263 13.644C10.4324 12.6046 10.0025 11.1584 10.0801 9.6041C10.1293 8.61878 10.3824 7.59002 10.8506 6.59396ZM17.7881 4.04875V11.9647C18.2628 11.544 18.7114 11.0076 19.1021 10.3426C20.7252 7.57964 19.8923 5.07503 18.554 4.31567C18.3309 4.18907 18.0721 4.09869 17.7881 4.04875ZM16.7791 12.674V4.0358C16.1321 4.12251 15.4319 4.38656 14.7611 4.86105V13.2013C15.3835 13.2118 16.0853 13.0478 16.7791 12.674ZM12.7611 12.197C12.9106 12.4015 13.0788 12.5765 13.2602 12.7175C13.3433 12.7821 13.4291 12.8396 13.5171 12.8895C13.565 12.9167 13.6145 12.9422 13.6655 12.9659C13.6898 12.9773 13.7145 12.9882 13.7395 12.9988L13.7521 13.0041V5.77417C13.474 6.0899 13.2105 6.45172 12.9691 6.86262L12.9539 6.88858L12.9313 6.92762C12.8711 7.03247 12.8144 7.13692 12.7611 7.24091C11.7168 9.27889 11.9855 11.1357 12.7611 12.197Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M9.42244 5.00587C8.69394 4.43915 7.92018 4.13031 7.21073 4.03526V12.6425C7.71008 12.9116 8.21359 13.0718 8.68847 13.1362C8.77201 13.333 8.86422 13.5259 8.96524 13.7145L8.16642 15.0743C6.26616 14.7126 4.36697 13.3664 3.15612 11.3063C1.20954 7.99462 1.77904 4.09206 4.42813 2.58975C6.27025 1.54508 8.63287 1.93613 10.5559 3.38488C10.165 3.84514 9.81198 4.34026 9.50513 4.86258C9.47717 4.91017 9.44961 4.95793 9.42244 5.00587ZM4.89584 10.3198C5.28479 10.9815 5.73128 11.5154 6.2037 11.9343V4.04835C5.92113 4.09812 5.66359 4.18807 5.4416 4.31396C4.10829 5.07008 3.27691 7.56546 4.89584 10.3198Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "TennisMatch"
);
