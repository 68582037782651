import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#0F1526"
        d="m16.57727,4.80592l2.44082,0l-5.33248,6.09468l6.27324,8.29349l-4.9119,0l-3.84717,-5.02996l-4.40201,5.02996l-2.4423,0l5.70362,-6.51895l-6.01794,-7.86922l5.0366,0l3.47748,4.59757l4.02205,-4.59757zm-0.85665,12.92722l1.35249,0l-8.73027,-11.543l-1.45136,0l8.82914,11.543z"
      />
    </g>
    <path
      d="m16.57727,4.80592l2.44082,0l-5.33248,6.09468l6.27324,8.29349l-4.9119,0l-3.84717,-5.02996l-4.40201,5.02996l-2.4423,0l5.70362,-6.51895l-6.01794,-7.86922l5.0366,0l3.47748,4.59757l4.02205,-4.59757zm-0.85665,12.92722l1.35249,0l-8.73027,-11.543l-1.45136,0l8.82914,11.543z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "Twitter"
);
