import {
  AutocompleteFieldProps,
  OmitedTextFieldProps,
  OmitedAutocompleteProps,
} from "./AutocompleteField.types";

export function extractAutocompleteProps(
  props: AutocompleteFieldProps
): [OmitedAutocompleteProps, OmitedTextFieldProps] {
  const {
    autoComplete,
    autoHighlight,
    autoSelect,
    blurOnSelect,
    ChipProps,
    classes,
    clearOnBlur,
    clearOnEscape = true,
    clearText,
    //@ts-ignore
    closeIcon,
    closeText,
    //@ts-ignore
    debug,
    defaultValue,
    disableClearable,
    disableCloseOnSelect,
    disabled,
    disabledItemsFocusable,
    disableListWrap,
    disablePortal,
    filterOptions,
    filterSelectedOptions,
    forcePopupIcon,
    freeSolo,
    fullWidth,
    getLimitTagsText,
    getOptionDisabled,
    getOptionLabel,
    //@ts-ignore
    getOptionSelected,
    groupBy,
    handleHomeEndKeys,
    id,
    includeInputInList,
    inputValue,
    limitTags,
    ListboxComponent,
    ListboxProps,
    loading,
    loadingText,
    multiple,
    noOptionsText,
    onChange,
    onClose,
    onHighlightChange,
    onInputChange,
    onOpen,
    open,
    openOnFocus,
    openText,
    options,
    PaperComponent,
    PopperComponent,
    popupIcon,
    renderGroup,
    renderOption,
    renderTags,
    selectOnFocus,
    size,
    value,
    ...other
  } = props;

  return [
    {
      autoComplete,
      autoHighlight,
      autoSelect,
      blurOnSelect,
      ChipProps,
      classes,
      clearOnBlur,
      clearOnEscape,
      clearText,
      //@ts-ignore
      closeIcon,
      closeText,
      debug,
      defaultValue,
      disableClearable,
      disableCloseOnSelect,
      disabled,
      disabledItemsFocusable,
      disableListWrap,
      disablePortal,
      filterOptions,
      filterSelectedOptions,
      forcePopupIcon,
      freeSolo,
      fullWidth,
      getLimitTagsText,
      getOptionDisabled,
      getOptionLabel,
      getOptionSelected,
      groupBy,
      handleHomeEndKeys,
      id,
      includeInputInList,
      inputValue,
      limitTags,
      ListboxComponent,
      ListboxProps,
      loading,
      loadingText,
      multiple,
      noOptionsText,
      onChange,
      onClose,
      onHighlightChange,
      onInputChange,
      onOpen,
      open,
      openOnFocus,
      openText,
      options,
      PaperComponent,
      PopperComponent,
      popupIcon,
      renderGroup,
      renderOption,
      renderTags,
      selectOnFocus,
      size,
      value,
    },
    { ...other },
  ];
}
