import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ChevronBigDown } from "@tiny/icons";
import { fetchApi } from "@tiny/utils";
import { Language } from "@/types/api/contentDetails";
import { Campaign } from "@/types/api/campaigns";
import { types } from "../../Calendar/Table/RowHelpers";

function Details({ formData }: { formData?: Campaign }) {
  const { register, setValue, control } = useFormContext();
  const [languages, setLanguages] = useState<Language[]>([]);
  const editMode = formData;
  const isDisabled = !!editMode;
  const isSystemGenerated = formData?.generatedBy === "System";

  useEffect(() => {
    fetchSupportedLanguages();
  }, []);

  const fetchSupportedLanguages = () => {
    fetchApi<Language[]>(
      "/accountservice/account/content/supported-languages",
      {
        method: "get",
      }
    )
      .then((response) => {
        setLanguages(response);
        if (editMode) {
          setValue("name", formData?.name);
          setValue("campaignDescription", formData?.description);
          setValue("type", formData?.type);
          setValue("language", formData?.language);
        }
      })
      .catch((error) => {
        console.log("Error fetching supported languages:", error);
      });
  };

  return (
    <Box my={5}>
      <Typography variant="h4" color="grey.100">
        Details
      </Typography>
      <Box mt={5}>
        <TextField
          fullWidth
          label="Campaign Name"
          placeholder="Christmas Sale"
          {...register("name")}
          size="small"
          disabled={isDisabled}
          required
          InputLabelProps={{ shrink: true }}
          inputProps={{ style: { textTransform: "capitalize" } }}
          sx={() => ({
            "& .MuiInputLabel-root.Mui-disabled": {
              backgroundColor: "transparent",
            },
          })}
        />
      </Box>
      <Box mt={5}>
        <TextField
          multiline
          minRows={4}
          fullWidth
          label="Campaign Description"
          placeholder="Describe what is the purpose of your campaign. Our AI will generate email designs based on this description text. Ex- campaign for Christmas sale"
          {...register("campaignDescription")}
          size="small"
          disabled={isDisabled}
          required
          InputLabelProps={{ shrink: true }}
          sx={() => ({
            "& .MuiInputLabel-root.Mui-disabled": {
              backgroundColor: "transparent",
            },
          })}
        />
      </Box>

      <Box display="flex" gap={4} mt={5}>
        <FormControl fullWidth size="small">
          <InputLabel
            shrink
            id="select-type-label"
            sx={(theme: Theme) => ({ fontSize: theme.spacing(3.5) })}
          >
            Type *
          </InputLabel>
          <Controller
            name="type"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select
                variant="outlined"
                id="types"
                IconComponent={ChevronBigDown}
                fullWidth
                label="Type"
                disabled={isDisabled && isSystemGenerated}
                required
                onChange={onChange}
                value={value}
                notched
                labelId="select-type-label"
                displayEmpty
              >
                <MenuItem
                  key="empty"
                  value=""
                  disabled
                  sx={(theme) => ({ fontSize: theme.spacing(3.5) })}
                >
                  Select
                </MenuItem>
                {types.map((type) => (
                  <MenuItem
                    key={type.key}
                    value={type.key}
                    sx={(theme) => ({ fontSize: theme.spacing(3.5) })}
                  >
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <FormControl fullWidth size="small">
          <InputLabel
            shrink
            id="select-language-label"
            sx={(theme: Theme) => ({ fontSize: theme.spacing(3.5) })}
          >
            Email Language *
          </InputLabel>
          <Controller
            name="language"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select
                variant="outlined"
                id="languages"
                IconComponent={ChevronBigDown}
                fullWidth
                label="Email Language"
                required
                onChange={onChange}
                value={value}
                labelId="select-language-label"
                notched
                displayEmpty
              >
                <MenuItem
                  key="empty"
                  value=""
                  disabled
                  sx={(theme) => ({ fontSize: theme.spacing(3.5) })}
                >
                  Select
                </MenuItem>
                {languages.map((language) => (
                  <MenuItem
                    key={language.id}
                    value={language.id}
                    sx={(theme) => ({ fontSize: theme.spacing(3.5) })}
                  >
                    {language.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Box>
    </Box>
  );
}

export default Details;
