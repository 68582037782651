import React from "react";
import { Box, useTheme } from "@mui/material";
import ActionMenu from "@/components/ActionMenu";
import {
  Condition,
  useCampaignMenu,
} from "@/components/Campaign/CampaignMenu/hooks";
import { HtmlPreview } from "@tiny/lib/ContentPreview";
import { Campaign } from "@/types/api/campaigns";

function CampaignHeader(props: Campaign) {
  const { template } = props;
  const theme = useTheme();

  const { campaignMenu, handlePreview } = useCampaignMenu(props);

  return (
    <Box
      sx={{
        borderBottom: `0.5px solid ${theme.palette.grey[60]}`,
        position: "relative",
      }}
      className="campaign-preview"
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          cursor: "zoom-in",
        }}
        onClick={() => handlePreview()}
      >
        <ActionMenu menuActions={campaignMenu} withBackgroundAndBorderStyles />
      </Box>
      <HtmlPreview
        width="100%"
        height="30rem"
        contentWidth={50}
        html={template.templateHtml}
        css={template.templateCss}
      />
    </Box>
  );
}

export default CampaignHeader;
