import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { CampaignPageProvider } from "./CampaignPage.context";
import { useCampaignPage } from "./CampaignPage.hooks";
import CampaignHeader from "./CampaignHeader";
import DetailsStep from "./DetailsStep";
import DesignStep from "./DesignStep";
import TemplatePreviewDialog from "../customComponents/TemplatePreviewDialog";
import SendTestEmailDialog from "../customComponents/SendTestEmailDialog";
import ScheduleStep from "./ScheduleStep";

const CampaignPage = () => {
  const campaignPageView = useCampaignPage();
  const {
    details,
    activeStep,
    activityId,
    campaignId,
    closePreviewDialog,
    isCampaignLoading,
    closeSendEmailTest,
    emailMessage,
    previewDialogOpen,
    requireSave,
    saveTemplateDialogOpen,
    sendEmailTestOpen,
    config,
    templateHtml,
    templateCss,
  } = campaignPageView;
  const { campaignTemplate, templateData, attributes, templateType } =
    emailMessage;
  return (
    <CampaignPageProvider value={campaignPageView}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box
          sx={(theme) => ({
            position: "relative",
            boxShadow: `0 2px 4px ${theme.palette.grey[200]}`,
            zIndex: 10,
          })}
        >
          <CampaignHeader />
        </Box>
        {isCampaignLoading ? (
          <Box
            sx={(theme) => ({
              flex: "1 1 auto",
              background: "transparent",
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              flex: "1 1 auto",
              background: "transparent",
              overflow: "auto",
            })}
          >
            {activeStep === "details" && <DetailsStep />}
            {activeStep === "design" && <DesignStep />}
            {activeStep === "schedule" && <ScheduleStep />}
          </Box>
        )}
      </Box>
      {previewDialogOpen && (
        <TemplatePreviewDialog
          campaignId={campaignId}
          mainActionName="Close"
          onClose={closePreviewDialog}
          onMainActionClick={closePreviewDialog}
          open={previewDialogOpen}
          templateAttributes={attributes}
          renderWithEjs={templateType === "CUSTOM"}
          templateData={templateData}
          templateHtml={templateHtml || campaignTemplate?.templateHtml}
          templateCss={templateCss || campaignTemplate?.templateCss}
          templateName={details?.name}
        />
      )}
      {sendEmailTestOpen && (
        <SendTestEmailDialog
          activityId={activityId}
          campaignId={campaignId}
          onClose={closeSendEmailTest}
          open={sendEmailTestOpen}
        />
      )}
    </CampaignPageProvider>
  );
};

export default CampaignPage;
