import React from "react";
import { useSchedulePanelView } from "../SchedulePanel.context";
import { AutoTrackingDialogProps } from "./AutoTrackingDialog.types";

export function useAutoTrackingDialog(props: AutoTrackingDialogProps) {
  const { onClose, onSave } = props;

  const { autoTracking } = useSchedulePanelView();

  const [autoTrackingState, setAutoTrackingState] =
    React.useState(autoTracking);

  const saveAutoTrackingState = React.useCallback(() => {
    if (autoTracking !== autoTrackingState) {
      onSave(autoTrackingState);
    }
    onClose();
  }, [autoTracking, autoTrackingState, onClose, onSave]);

  return {
    autoTrackingState,
    saveAutoTrackingState,
    setAutoTrackingState,
  };
}
