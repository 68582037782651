import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M10 4V11H4V4H2V20H4V13H10V20H12V4H10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M20 20V8H18.5L16 8.67V10.74L18 10.204V20H20Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "HeadingH1"
);
