import React from "react";
import { PlanLogo } from "@tiny/icons";
import { SxProps, useTheme } from "@mui/material";

const PlanLogos = ({
  karmaLevel,
  sx = [],
  disabled,
}: {
  karmaLevel: number;
  sx?: SxProps;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const disableColors = [
    theme.palette.grey[80],
    theme.palette.grey[80],
    theme.palette.grey[80],
  ];

  const planColors = [
    theme.palette.primary.main,
    theme.palette.primary.main,
    theme.palette.primary.main,
  ];
  return (
    <PlanLogo
      sx={[
        {
          fill: theme.palette.common.white,
          stroke: disabled ? disableColors[karmaLevel] : planColors[karmaLevel],
          strokeWidth: "0.5px",
          "& path:nth-of-type(n)": {
            fill: theme.palette.common.white,
          },
          [`& path:nth-of-type(-n+${karmaLevel + 1})`]: {
            fill: disabled ? disableColors[karmaLevel] : planColors[karmaLevel],
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      viewBox="0 0 21 21"
    />
  );
};

const AiMarketingAssistantPlanLogo = () => <PlanLogos karmaLevel={0} />;

const AiMarketingSpecialistPlanLogo = () => <PlanLogos karmaLevel={1} />;

const AiGrowthManagerPlanLogo = () => <PlanLogos karmaLevel={2} />;

export {
  PlanLogos,
  AiMarketingAssistantPlanLogo,
  AiMarketingSpecialistPlanLogo,
  AiGrowthManagerPlanLogo,
};
