import { Pagination } from "@/types/api/pagination";
import React, { createContext, useMemo, useState, ReactNode } from "react";
import { HeroImage } from "../types";

export interface HeroImageContext {
  heroImages: HeroImage[];
  updateHeroImages: (
    id: string,
    updatedProperties: { [key: string]: any }
  ) => void;
  setHeroImagesData: (images: HeroImage[]) => void;
  heroImagePagination: Pagination<HeroImage> | null;
  setHeroImagePaginationData: (paginationData: Pagination<HeroImage>) => void;
}

export const ImageContext = createContext<HeroImageContext | null>(null);

export function ImageProvider({ children }: { children: ReactNode }) {
  const [heroImages, setHeroImages] = useState<HeroImage[]>([]);
  const [heroImagePagination, setHeroImagePagination] =
    useState<Pagination<HeroImage> | null>(null);

  function updateHeroImages(
    id: string,
    updatedProperties: { [key: string]: any }
  ) {
    setHeroImages((prevImages) =>
      prevImages?.map((heroImage) => {
        if (heroImage?.id === id) {
          return { ...heroImage, ...updatedProperties };
        }
        return heroImage;
      })
    );
  }

  function setHeroImagesData(images: HeroImage[]): void {
    setHeroImages(images);
  }

  function setHeroImagePaginationData(paginationData: Pagination<HeroImage>) {
    setHeroImagePagination(paginationData);
  }

  const value: HeroImageContext = useMemo(
    () => ({
      heroImages,
      updateHeroImages,
      setHeroImagesData,
      heroImagePagination,
      setHeroImagePaginationData,
    }),
    [heroImages, heroImagePagination]
  );

  return (
    <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
  );
}
