import React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useLoaderData } from "react-router-dom";
import { useConfirmationDialog } from "@/routes/Campaigns/useConfirmationDialog.hook";
import SocialSection from "./SocialSection";
import { FormFields } from "./brandkit.types";
import LogoSection from "./LogoSection";
import ColorSection from "./ColorSection";
import EmailPreview from "./EmailPreview";
import { BrandkitLoaderProps } from "./loader";
import { useBrandKit } from "./Hooks/useBrandKit";

function BrandKit() {
  const sm = useMediaQuery("(max-width:600px)");
  const dataLoaderResponse = useLoaderData() as BrandkitLoaderProps;
  const { primaryColor, secondaryColor, logoUrl, socialLinks, id } =
    dataLoaderResponse.brandkit;
  const { updateBrandKit } = useBrandKit();
  const { showConfirmationDialog } = useConfirmationDialog();

  const methods = useForm<FormFields>({
    defaultValues: {
      id,
      logoUrl,
      primaryColor,
      secondaryColor,
      socialLinks,
    },
  });

  const onSubmit = (data: FormFields) => {
    showConfirmationDialog(
      "You are changing brand details of your store, the changes will be applied to all your templates. Are you sure you want to continue?",
      "Are you sure",
      "Continue",
      () => {
        updateBrandKit(data);
      },
      true
    );
  };

  return (
    <Box mt={3}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "flex-end", md: "space-between" },
          alignItems: { xs: "flex-start", md: "flex-end" },
          mb: 4,
        }}
        height={58}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="h3"
            color="grey.100"
            fontSize={18}
            mb={1}
            sx={{ width: "100%" }}
          >
            Brand kit
          </Typography>
          <Typography variant="label1R">Logo and Brand identity</Typography>
        </Box>
        <Box id="toast-container">{}</Box>
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={6}>
            <Grid item flex="1">
              <LogoSection />
              <ColorSection />
              <SocialSection />
            </Grid>
            {!sm && (
              <Grid item flex="0 0 32%">
                <EmailPreview />
              </Grid>
            )}
          </Grid>
          <Box m={{ sx: 4, md: 6 }} textAlign="right">
            {!sm && (
              <Divider
                sx={{
                  mb: 6,
                }}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              id="brandkit-cta-submit"
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}

export default BrandKit;
