import PageTitle from "@/components/PageTitle";
import AudienceSourcesTable from "@/routes/Audience/Sources/AudienceSourcesTable";
import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { GetAudienceOverviewResponse } from "@/types/api/reportsOverview";
import AudiencesMetrics from "@/components/AudiencesMetrics";
import { TinyImporterDialog } from "@/components/TinyCsvImporter";
import { useDialogInvokers } from "@tiny/lib/DialogManager";
import { fetchApi } from "@tiny/utils";
import {
  AudienceSegmentViewRequest,
  AudienceCreateResponse,
} from "@/components/TinyCsvImporter/TinyImporter.types";
import { Audience } from "@/types/api/audience";
import { useRefreshButton } from "@/hooks/useRefreshButton";
import { closeSnackbar } from "notistack";
import { showUploadMessage } from "@/components/Snackbar/snackbar-helper";

function AudienceSources() {
  const { audiences } = useLoaderData() as {
    audiences: Audience[];
  };
  const { audiencesGeneralMetrics } = useLoaderData() as {
    audiencesGeneralMetrics: GetAudienceOverviewResponse;
  };
  const { requestMediumDialog } = useDialogInvokers();
  const navigate = useNavigate();
  const { clickHandler } = useRefreshButton();

  const handleAudienceImport = () => {
    const createNewAudience = async (data: AudienceSegmentViewRequest) => {
      try {
        const url = `/customerservice/audience/import`;
        const response = await fetchApi<AudienceCreateResponse>(url, {
          method: "POST",
          body: JSON.stringify(data),
        });

        return response;
      } catch (error) {
        throw new Error();
      }
    };
    requestMediumDialog({
      titleProps: { children: "Audience Name", variant: "h3" },
      contentProps: {
        children: (
          <TinyImporterDialog
            importType="CREATE"
            audienceName=""
            onSuccess={async (data: AudienceSegmentViewRequest) => {
              const audienceName = data.name;
              try {
                showUploadMessage({
                  variant: "uploading",
                  message: "Uploading file",
                  description: `Your ${audienceName} audience is uploading`,
                  persist: true,
                });
                const response = await createNewAudience(data);
                closeSnackbar();
                showUploadMessage({
                  variant: "uploadSuccess",
                  message: "Uploaded successfully!",
                  description: `Your ${audienceName} audience has been uploaded successfully!`,
                  ctaActionText: "View",
                  ctaActionFunction: () => {
                    navigate(`/main/audience/${response.id}/subscribers`);
                    clickHandler(); // to refetch the audience data after posting.
                    closeSnackbar();
                  },
                  persist: true,
                });
              } catch (e) {
                closeSnackbar();
                showUploadMessage({
                  variant: "uploadFailed",
                  message: "Uploading error!",
                  description:
                    "It seems file size is large for browser. Please contact the support team.",
                  hideOnClick: true,
                });
                throw new Error();
              }
            }}
          />
        ),
        dividers: true,
        sx: {
          border: "none",
        },
      },
    });
  };

  return (
    <>
      <PageTitle title="Audience" canRefresh />
      <Grid
        container
        id="statistics-section"
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 12, md: 12 }}
        pb={6}
      >
        <React.Suspense>
          <Await resolve={audiencesGeneralMetrics}>
            <AudiencesMetrics />
          </Await>
        </React.Suspense>
      </Grid>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        mt={6}
        sx={{ columnGap: 2 }}
      >
        <Typography variant="h4">Available audiences</Typography>
        <Button
          variant="contained"
          sx={{
            height: 40,
            display: {
              md: "flex",
              sm: "none",
            },
          }}
          onClick={handleAudienceImport}
        >
          Import Audience
        </Button>
      </Box>

      <React.Suspense>
        <Await resolve={audiences}>
          <AudienceSourcesTable />
        </Await>
      </React.Suspense>
    </>
  );
}

export default AudienceSources;
export { audiencesLoader } from "./loader";
