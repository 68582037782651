import React from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { NavLink, useAsyncValue } from "react-router-dom";

import { ChevronBigRight } from "@tiny/icons";
import { useIsLargerThan } from "@tiny/theme/utils";
import { GetAccountResponse } from "@/types/api/account";

function ProfileMenu(props: { avatarSrc?: string; collapsed: boolean }) {
  const { avatarSrc, collapsed } = props;
  const {
    firstName,
    lastName,
    company: userCompany,
  } = useAsyncValue() as GetAccountResponse;
  const userName = `${firstName ?? ""} ${lastName ?? ""}`;
  const isMdUp = useIsLargerThan("sm");
  const theme = useTheme();

  return !collapsed && isMdUp ? (
    <NavLink
      to="account-settings"
      style={{
        padding: 0,
        justifyContent: "space-between",
        color: theme.palette.grey[80],
        display: "flex",
        width: "100%",
        textDecoration: "none",
      }}
    >
      <Box display="flex" flexDirection="row" gap={2}>
        <Avatar src={avatarSrc} variant="rounded" />
        <Box
          display="flex"
          flexDirection="column"
          textAlign="left"
          justifyContent="center"
        >
          <Typography variant="label1" color="grey.100">
            {userName}
          </Typography>
          <Typography variant="p3" color="grey.80">
            {userCompany}
          </Typography>
        </Box>
      </Box>
      <ChevronBigRight />
    </NavLink>
  ) : (
    <NavLink
      to="account-settings/ai-tuning"
      style={({ isActive }) => ({
        p: 0,
        border: `4px solid ${
          isActive ? theme.palette.primary.lighter : theme.palette.common.white
        }`,
        transition: "border 0.6s ease",
        borderRadius: 4,
        display: isMdUp ? "block" : "none",
        textDecoration: "none",
      })}
    >
      {({ isActive }) => (
        <Avatar src={avatarSrc} variant={isActive ? "square" : "rounded"} />
      )}
    </NavLink>
  );
}

export default ProfileMenu;
