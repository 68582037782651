import { Box, Button } from "@mui/material";
import PageFilter, { FilterOptions } from "@/components/PageFilter";
import TextSearch from "@/components/TextSearch";
import React from "react";
import { usePageFilter } from "@/hooks/usePageFilter";

export const sourcesFilterOptions: FilterOptions[] = [
  { key: "ALL_TYPES", label: "All" },
  { key: "AUTOMATION", label: "Automations" },
  { key: "CAMPAIGN", label: "Campaigns" },
];

function SourcesTableHeader({
  seeResultsHandler,
  isEmpty,
}: {
  seeResultsHandler: () => void;
  isEmpty: boolean;
}) {
  const [period, onChangeFilter] = usePageFilter("ALL_TYPES");

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={5}
      flexDirection={{ xs: "column", sm: "row" }}
      gap={{ xs: 2, sm: 0 }}
    >
      <Box
        display="flex"
        gap={4}
        flexDirection="row"
        justifyContent={{ xs: "space-between", sm: "flex-start" }}
      >
        <PageFilter
          filterOptions={sourcesFilterOptions}
          onChangeFilter={onChangeFilter}
          value={period}
        />
        <TextSearch id="search-orders" paramName="searchTextName" />
      </Box>

      {!isEmpty && (
        <Button variant="outlined" onClick={seeResultsHandler}>
          See results
        </Button>
      )}
    </Box>
  );
}

export default SourcesTableHeader;
