import { Alert, Divider, IconButton, Stack, useTheme } from "@mui/material";
import * as React from "react";
import { CloseSmall, InfoError, InfoInfo, InfoWarning } from "@tiny/icons";
import { fetchApi } from "@tiny/utils";
import { NotificationListProps } from "@/components/Notifications/types";
import NotificationMessage from "./NotificationMessage";

function NotificationsList(props: NotificationListProps) {
  const { entries, isCollapsed, silenceNotificationHandler } = props;
  const theme = useTheme();

  const visibleNotifications = isCollapsed ? entries.slice(0, 1) : entries;

  const removeListItem = React.useCallback(
    (id: string, isInfo: boolean) => {
      if (id && isInfo) {
        fetchApi(`/accountservice/account/issue/${id}/resolved`, {
          method: "put",
        });
      }

      silenceNotificationHandler(id);
    },
    [silenceNotificationHandler]
  );

  return (
    <Stack
      id="notifications-list"
      divider={<Divider />}
      sx={[
        {
          flex: "1 1 100%",
          backgroundColor: "common.white",
          borderTopRightRadius: { xs: 0, sm: theme.spacing(2) },
          borderBottomRightRadius: { xs: 0, sm: theme.spacing(2) },
          height: "100%",
          width: { xs: "100%", sm: "auto" },
        },
        entries.length === 1 && {
          borderRadius: theme.spacing(2),
        },
      ]}
    >
      {visibleNotifications.map((notification, index) => (
        <Alert
          id={`notification-${notification.id}`}
          key={`${notification.id}-${index}`}
          severity={
            // eslint-disable-next-line no-nested-ternary
            notification.severity === "ERROR"
              ? "error"
              : notification.severity === "WARNING"
              ? "warning"
              : "info"
          }
          variant="standard"
          iconMapping={{
            error: <InfoError sx={{ color: theme.palette.red.dark }} />,
            warning: <InfoWarning sx={{ color: theme.palette.yellow.dark }} />,
            info: <InfoInfo sx={{ color: theme.palette.blue.dark }} />,
          }}
          action={
            <IconButton
              onClick={() =>
                removeListItem(
                  notification.id,
                  notification.severity === "INFO"
                )
              }
              sx={{ p: 0, width: 20, height: 20 }}
            >
              <CloseSmall />
            </IconButton>
          }
        >
          <NotificationMessage notification={notification} />
        </Alert>
      ))}
    </Stack>
  );
}
export default NotificationsList;
