import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Button,
  Typography,
  Box,
} from "@mui/material";
import TagItem from "./TagItem/TagItem";
import { TagsDialogProps } from "./TagsDialog.types";
import { useTagsDialog } from "./TagsDialog.hooks";
import useBreakpoints from "../../hooks/useBreakpoints";
import FilterItemCheckbox from "../FilterItemCheckbox/FilterItemCheckbox";

function TagsDialog(props: TagsDialogProps) {
  const { tags = [], open, onClose, subtitle = "Tags" } = props;

  const { mdUp } = useBreakpoints();

  const {
    save,
    selected,
    toggleAll,
    allChecked,
    selectTag,
    isFavoriteSelected,
    isChecked,
    toggleSelectedFavorites,
  } = useTagsDialog(props);

  return (
    <Dialog open={open} onClose={onClose} disableEscapeKeyDown fullWidth>
      <DialogTitle>Filters</DialogTitle>
      <DialogContent sx={{ pt: 5 }}>
        <Grid container my={3}>
          <FilterItemCheckbox
            checked={isFavoriteSelected ?? false}
            onChange={toggleSelectedFavorites}
            label="Favorite"
          />
        </Grid>
        <Box mb={3}>
          <Divider />
        </Box>
        <Typography
          variant="h4"
          fontWeight="semiBold"
          color="grey"
          mt={0}
          mb={2}
        >
          {`${subtitle} (${selected?.length})`}
        </Typography>
        <Grid container>
          <TagItem onChange={toggleAll} checked={allChecked} label="All tags" />
          {tags?.map((tag) => (
            <TagItem
              key={tag.id}
              tag={tag}
              onChange={selectTag}
              checked={isChecked(tag.id)}
              label={tag.name!}
            />
          ))}
        </Grid>
        <Box mt={5}>
          <Divider />
        </Box>
      </DialogContent>
      <DialogActions>
        {mdUp && (
          <Button onClick={onClose} variant="text">
            Cancel
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={save}
          type="button"
          fullWidth={!mdUp}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TagsDialog;
