import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { OverFlowTip } from "../../../../components";
import { ErrorOutline, CircleCheckOutline } from "@tiny/icons";
import { LaunchItemProps } from "./LaunchItem.types";

function LaunchItem(props: LaunchItemProps) {
  const {
    actionDisabled,
    actionText = "Edit",
    completed,
    description,
    name,
    onActionClick,
    actionIcon,
    nonCompliance,
  } = props;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        listStyle: "none",
        position: "relative",
        marginBottom: 10,
        "&::after": {
          content: "''",
          position: "absolute",
          width: "100%",
          bottom: -20,
          borderTop: `1px solid ${theme.palette.grey[200]}`,
        },
        // "&:last-child": {
        //   marginBottom: 0,
        //   "&::after": {
        //     content: "none",
        //   },
        // },
      })}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box
          display="flex"
          minWidth={1}
          maxHeight={nonCompliance ? 5 : undefined}
        >
          <Box
            sx={{
              flex: "0 0 34px",
            }}
          >
            {completed ? (
              <CircleCheckOutline
                sx={(theme) => ({
                  boxSizing: "border-box",
                  width: 20,
                  height: 20,
                  color: theme.palette.primary.main,
                })}
              />
            ) : (
              <ErrorOutline
                sx={(theme) => ({
                  boxSizing: "border-box",
                  width: 20,
                  height: 20,
                  color: theme.palette.red.dark,
                })}
              />
            )}
          </Box>
          <Box
            sx={{
              flex: "1",
              paddingRight: 7,
            }}
          >
            <Typography
              variant="h4"
              component="span"
              color="grey"
              fontWeight="semiBold"
              m={0}
            >
              {name}
            </Typography>

            {nonCompliance ?? (
              <Typography
                component="span"
                variant="p2"
                color="grey"
                fontWeight="regular"
                m={0}
              >
                {description && <OverFlowTip text={description} />}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              flex: "0 0 80px",
              textAlign: "right",
            }}
          >
            <Button
              variant="text"
              onClick={(e) => onActionClick && onActionClick(e)}
              disabled={actionDisabled}
              endIcon={actionIcon}
              sx={{ p: 0, cursor: "pointer" }}
            >
              {actionText}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LaunchItem;
