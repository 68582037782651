import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { FeaturesList } from "../../../../../components";
import { getCampaignSubjectdRequirements } from "./BestPractices.helpers";
import { Bulb } from "@tiny/icons";

interface BestPracticesProps {
  text: string;
}

function BestPractices(props: BestPracticesProps): JSX.Element {
  const { text } = props;

  const list = React.useMemo(() => {
    return getCampaignSubjectdRequirements(text);
  }, [text]);

  return (
    <Grid container>
      <Grid item>
        <Typography
          variant="h4"
          fontWeight="semiBold"
          color="grey"
          component="span"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Bulb
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontSize: 16,
                marginRight: 1,
              })}
            />
            Subject best practices
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box mt={-14 / 4}>
          <FeaturesList list={list} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default BestPractices;
