import React from "react";
import { ActionMenu } from "@/components/ActionMenu/types";
import { Theme } from "@mui/material";
import { useDialogInvokers } from "@tiny/lib";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem";

function useActionMenuMobile(
  actionMenu: ActionMenu,
  theme: Theme,
  handleCloseMenuDialog: () => void
) {
  const { requestSmallDialog } = useDialogInvokers();

  const onOpenActionMenuMobileClick = React.useCallback(
    () =>
      requestSmallDialog({
        titleProps: {},
        contentProps: {
          dividers: false,
          sx: {
            border: "none",
          },
          children: actionMenu?.map((actionMenuItem) => (
            <ActionMenuItem
              theme={theme}
              name={actionMenuItem.name}
              onClickAction={actionMenuItem.onClickAction}
              handleCloseMenu={() => handleCloseMenuDialog}
              key={actionMenuItem.name}
            />
          )),
        },
      }),
    [actionMenu, handleCloseMenuDialog, requestSmallDialog, theme]
  );

  return { onOpenActionMenuMobileClick };
}

export default useActionMenuMobile;
