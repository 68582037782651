import React from "react";
import { Link } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import { CircleChevronLeft, CircleChevronRight } from "@tiny/icons";
import tinyAlbertSymbol from "../../assets/logos/tiny-albert-symbol-color.svg";
import tinyAlbertLogo from "../../assets/logos/tinyalbert-trademark-normal.svg";

function SidebarHeader({
  collapsed,
  toggleSidebar,
}: {
  collapsed: boolean;
  toggleSidebar: () => void;
}) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      {collapsed ? (
        <Link to="dashboard" style={{ display: "inline-flex" }}>
          <img
            src={tinyAlbertSymbol}
            alt="Tiny Albert Logo"
            style={{ width: 32, height: 32 }}
          />
        </Link>
      ) : (
        <Link to="dashboard" style={{ display: "inline-flex" }}>
          <img src={tinyAlbertLogo} alt="Tiny Albert Logo" />
        </Link>
      )}
      <>
        <IconButton
          onClick={toggleSidebar}
          size="small"
          sx={(theme) => ({
            display: collapsed ? "none" : "flex",
            width: 24,
            height: 24,
            color: theme.palette.grey[60],
          })}
        >
          <CircleChevronLeft />
        </IconButton>

        <IconButton
          onClick={toggleSidebar}
          sx={(theme) => ({
            display: !collapsed ? "none" : "flex",
            width: 24,
            height: 24,
            color: theme.palette.grey[100],
            position: "absolute",
            right: "-12px",
            background: "white",
          })}
        >
          <CircleChevronRight />
        </IconButton>
      </>
    </Box>
  );
}

export default SidebarHeader;
