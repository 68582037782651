import React from "react";
import { useDialogInvokers } from "@tiny/lib";
import ColorPickerDialogContent from "@/components/ColorPicker/ColorPickerDialogContent";
import { ColorPreferences } from "@tiny/mui/ColorInput/types";

function useColorPickerDialog(
  colors: { primaryColor: string; secondaryColor: string },
  onSaveColorPreferences: (updatedColors: ColorPreferences) => void,
  selectedPrimaryColorRef: React.MutableRefObject<string>,
  selectedSecondaryColorRef: React.MutableRefObject<string>
) {
  const { requestMediumDialog } = useDialogInvokers();

  const onChangeColorClick = React.useCallback(
    () =>
      requestMediumDialog({
        titleProps: { children: "Change colors", variant: "h3" },
        contentProps: {
          dividers: false,
          sx: {
            border: "none",
          },
          children: (
            <ColorPickerDialogContent
              colors={colors}
              onSaveChange={onSaveColorPreferences}
              selectedPrimaryColorRef={selectedPrimaryColorRef}
              selectedSecondaryColorRef={selectedSecondaryColorRef}
            />
          ),
        },
      }),
    [
      colors,
      onSaveColorPreferences,
      requestMediumDialog,
      selectedPrimaryColorRef,
      selectedSecondaryColorRef,
    ]
  );

  return { onChangeColorClick };
}

export { useColorPickerDialog };
