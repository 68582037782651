import { useDialogInvokers } from "@tiny/lib";
import FilterSubscriberDialogContent from "./FilterSubscriberDialogContent";

function useFilterSubscriberDialog() {
  const { requestMediumDialog } = useDialogInvokers();

  const showFilterDialog = () => {
    requestMediumDialog({
      titleProps: { children: "Filters", variant: "h3" },
      contentProps: {
        children: <FilterSubscriberDialogContent />,
        dividers: false,
        sx: {
          border: "none",
        },
      },
    });
  };

  return { showFilterDialog };
}

export default useFilterSubscriberDialog;
