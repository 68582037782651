import React from "react";

import { SimpleListProps } from "./SimpleList.types";
import { List } from "@mui/material";

function SimpleList(props: SimpleListProps) {
  const { children } = props;
  return (
    <List
      sx={{
        listStyle: "none",
        margin: 0,
        padding: 0,
      }}
    >
      {children}
    </List>
  );
}

export default SimpleList;
