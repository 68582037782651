import { fetchApi } from "@tiny/utils";
import {
  GetContentDetailsResponse,
  GetSupportedLanguagesResponse,
} from "@/types/api/contentDetails";
import { GetTemplateLayoutOptionsResponse } from "@/types/api/accountTemplateLayouts";
import { defer, LoaderFunctionArgs } from "react-router-dom";

const aiTuningRootLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const { primaryColor, secondaryColor } = Object.fromEntries(url.searchParams);

  const accountDetails = await fetchApi<GetContentDetailsResponse>(
    "/accountservice/account/content/details",
    { signal: request.signal }
  );

  const languages = await fetchApi<GetSupportedLanguagesResponse>(
    "/accountservice/account/content/supported-languages",
    { signal: request.signal }
  );

  const layoutsDict =
    primaryColor && secondaryColor
      ? await fetchApi<GetTemplateLayoutOptionsResponse>(
          "/accountservice/layout",
          {
            signal: request.signal,
            body: JSON.stringify({
              primaryColor,
              secondaryColor,
            }),
            method: "POST",
          }
        )
      : await fetchApi<GetTemplateLayoutOptionsResponse>(
          "/accountservice/layout",
          {
            signal: request.signal,
            method: "GET",
          }
        );

  const mergedAccountDetails = {
    ...accountDetails,
    languages,
  };

  return defer({ accountDetails: mergedAccountDetails, layoutsDict });
};
export { aiTuningRootLoader };
