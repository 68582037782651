import React from "react";
import { format } from "date-fns";
import { DateCellWrapperProps } from "react-big-calendar";
import { Box } from "@mui/material";

interface CustomDateCellWrapperProps extends DateCellWrapperProps {
  value: Date;
}

const DateCellWrapper: React.FC<CustomDateCellWrapperProps> = ({
  value,
  children,
}) => {
  const isFirstDate = value.getDate() === 1;
  const monthName = format(value, "MMMM");
  const day = format(value, "d");

  return (
    <div
      className={children.props.className}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      {isFirstDate && (
        <Box
          sx={(theme) => ({
            mr: 3,
            my: 1.5,
            backgroundColor: "white",
            fontSize: "0.7rem",
            fontWeight: 700,
            zIndex: 10,
            color: theme.palette.grey[80],
          })}
        >
          {monthName} {day}
        </Box>
      )}
      {children}
    </div>
  );
};
export default DateCellWrapper;
