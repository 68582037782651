import { fetchApi } from "@tiny/utils";
import { ApiError } from "@tiny/utils/errors";
import { useToast } from "@/components/ToastService/useToast";
import { useScreenLoaderInvokers } from "@tiny/lib/ScreenLoaderManager";
import { FormFields } from "../brandkit.types";

export function useBrandKit() {
  const { showToast } = useToast();
  const { hideScreenLoader, showScreenLoader } = useScreenLoaderInvokers();

  const updateBrandKit = async (formFields: FormFields) => {
    showScreenLoader();
    const formData = new FormData();
    if (formFields?.logoUrl instanceof File) {
      formData.append("logoFile", formFields.logoUrl);
    } else if (!formFields?.logoUrl) {
      const isLogoPresent = await getLogo(formFields?.id);
      if (isLogoPresent) {
        await deleteLogo(formFields?.id);
      }
    }

    const params = {
      primaryColor: formFields.primaryColor,
      secondaryColor: formFields.secondaryColor,
      socialLinks: formFields.socialLinks,
      secondaryFont: null,
      primaryFont: null,
      links: [],
    };
    formData.append(
      "identity",
      new Blob([JSON.stringify(params)], { type: "application/json" })
    );

    try {
      await fetchApi(
        `/accountservice/identity`,
        {
          body: formData,
          method: "PUT",
        },
        undefined,
        true
      );
      hideScreenLoader();
      showToast({
        message: "Brand details details successfully updated",
        type: "success",
        duration: 3000,
        portalId: "toast-container",
      });
    } catch (err) {
      hideScreenLoader();
      if (err instanceof ApiError) {
        showToast({
          message: err.message,
          type: "error",
          duration: 3000,
          portalId: "toast-container",
        });
      }
      throw err;
    }
  };

  const deleteLogo = async (id: string) => {
    showScreenLoader();
    try {
      await fetchApi(`/accountservice/identity/logo/${id}`, {
        method: "DELETE",
      });
    } catch (err) {
      hideScreenLoader();
      if (err instanceof ApiError) {
        showToast({
          message: err.message,
          type: "error",
          duration: 3000,
          portalId: "toast-container",
        });
      }
      throw err;
    }
  };

  const getLogo = async (id: string) => {
    showScreenLoader();
    try {
      const response = await fetchApi<{
        logoUrl: string;
        identityId: string;
      }>(`/accountservice/identity/logo/${id}`, {
        method: "GET",
      });
      return !!response?.logoUrl;
    } catch (err) {
      hideScreenLoader();
      if (err instanceof ApiError) {
        showToast({
          message: err.message,
          type: "error",
          duration: 3000,
          portalId: "toast-container",
        });
      }
      throw err;
    }
  };

  return { updateBrandKit };
}
