import React from "react";
import { Box, CircularProgress } from "@mui/material";

function CircularProgressWithContent({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        size={180}
        thickness={2}
        disableShrink
        sx={{ color: "primary.lighter", strokeLinecap: "round" }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
    </Box>
  );
}

export default CircularProgressWithContent;
