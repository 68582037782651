import React from "react";
import { Box, Typography } from "@mui/material";
import { PlanLogos } from "./PlanLogo";

function PlanTitle({
  planTitle,
  disabled,
  planIndex,
}: {
  planTitle: string;
  disabled: boolean;
  planIndex: number;
}) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <PlanLogos karmaLevel={planIndex} disabled={disabled} />
      <Typography variant="h4" color={disabled ? "grey.80" : "grey.100"}>
        {planTitle}
      </Typography>
    </Box>
  );
}

export default PlanTitle;
