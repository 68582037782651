import { GetAudienceSubscribersResponse } from "@/types/api/subscribers";
import { fetchApi } from "@tiny/utils";
import { CustomError } from "@tiny/utils/errors";
import { defer, LoaderFunctionArgs } from "react-router-dom";

export const defaultApiQueryParams = {
  page: "0",
  size: "6",
  sort: "email,asc",
};

export const subscribersLoader = ({ request, params }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const { audienceId } = params;
  const { searchTextName, statuses, tags, ...paginationParams } =
    Object.fromEntries(url.searchParams);

  if (!audienceId)
    throw new CustomError("`audienceId` should be present in route path");

  const apiPage = (parseInt(paginationParams.page ?? 1, 10) - 1).toString();
  const apiParams = {
    ...defaultApiQueryParams,
    ...paginationParams,
    page: apiPage,
  };
  const apiQueryString = new URLSearchParams(apiParams).toString();

  const subscribersData = fetchApi<GetAudienceSubscribersResponse>(
    `/customerservice/customer/by-filter?${apiQueryString}`,
    {
      signal: request.signal,
      method: "put",
      body: JSON.stringify({
        searchText: searchTextName,
        audienceIds: [audienceId],
        statuses: statuses ? statuses.split(",") : [],
        tags: tags ? tags.split(",") : [],
      }),
    }
  ).then((response) => response.page);

  return defer({ subscribersData });
};
