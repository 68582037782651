import React from "react";
import { useDragAndDropTab } from "./DragAndDropTab.hooks";

type ContextValue = ReturnType<typeof useDragAndDropTab>;

const DragAndDropTabContext = React.createContext<ContextValue | null>(null);

export function useDragAndDropTabView() {
  const contextValue = React.useContext(DragAndDropTabContext);
  if (!contextValue) {
    throw Error("Should be used inside the DragAndDropTabProvider");
  }
  return contextValue;
}

export const DragAndDropTabProvider = DragAndDropTabContext.Provider;
