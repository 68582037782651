import * as React from "react";
import { TinyScreenLoaderProvider } from "@tiny/lib";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useOAuthInit } from "./hooks/oauth.hooks";
import AppAuthenticatedRoutes from "./AppAuthentictedRoutes";
import AppUnauthenticatedRoutes from "./AppUnauthenticatedRoutes";
import { ToastProvider } from "./components/ToastService/ToastProvider";

function App() {
  const url = new URL(window.location.href);
  const { isAuthenticated, setIsAuthenticated } = useOAuthInit(url);

  return isAuthenticated ? (
    <ToastProvider>
      <TinyScreenLoaderProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppAuthenticatedRoutes />
        </LocalizationProvider>
      </TinyScreenLoaderProvider>
    </ToastProvider>
  ) : (
    <AppUnauthenticatedRoutes
      onAuthenticated={() => setIsAuthenticated(true)}
    />
  );
}

export default App;
