import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <g clipPath="url(#clip0_14319_129445)" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9999 20H5.99992C2.87429 20.0018 0.270809 17.6038 0.0162787 14.4886C-0.238251 11.3733 1.94144 8.58452 5.02592 8.07898C6.44563 5.56187 9.11003 4.0035 11.9999 3.99993C13.8016 3.99334 15.5516 4.6015 16.9609 5.72398C18.3448 6.82158 19.3288 8.34432 19.7609 10.057C22.3477 10.4509 24.1932 12.7726 23.9936 15.3816C23.7939 17.9905 21.6165 20.0043 18.9999 20ZM11.9999 5.99997C9.83163 6.00254 7.83259 7.17208 6.76792 9.06098L6.29992 9.89998L5.35091 10.055C3.3012 10.3984 1.85592 12.2543 2.02513 14.3257C2.19433 16.397 3.92164 17.9938 5.99992 18H18.9999C20.5685 18.0016 21.8735 16.7946 21.9941 15.2307C22.1147 13.6667 21.0102 12.2739 19.4599 12.035L18.1439 11.835L17.8219 10.543C17.1572 7.86979 14.7545 5.99494 11.9999 5.99997ZM9.41292 16.414L7.99992 15L10.2929 12.707L7.99992 10.414L9.41392 8.99998L11.7069 11.292L13.9999 8.99998L15.4139 10.414L13.1209 12.707L15.4139 15L13.9999 16.413L11.7059 14.121L9.41392 16.414H9.41292Z"
        fill="#0F1526"
      />
    </g>
    <path
      d="M18.9999 20H5.99992C2.87429 20.0018 0.270809 17.6038 0.0162787 14.4886C-0.238251 11.3733 1.94144 8.58452 5.02592 8.07898C6.44563 5.56187 9.11003 4.0035 11.9999 3.99993C13.8016 3.99334 15.5516 4.6015 16.9609 5.72398C18.3448 6.82158 19.3288 8.34432 19.7609 10.057C22.3477 10.4509 24.1932 12.7726 23.9936 15.3816C23.7939 17.9905 21.6165 20.0043 18.9999 20ZM11.9999 5.99997C9.83163 6.00254 7.83259 7.17208 6.76792 9.06098L6.29992 9.89998L5.35091 10.055C3.3012 10.3984 1.85592 12.2543 2.02513 14.3257C2.19433 16.397 3.92164 17.9938 5.99992 18H18.9999C20.5685 18.0016 21.8735 16.7946 21.9941 15.2307C22.1147 13.6667 21.0102 12.2739 19.4599 12.035L18.1439 11.835L17.8219 10.543C17.1572 7.86979 14.7545 5.99494 11.9999 5.99997ZM9.41292 16.414L7.99992 15L10.2929 12.707L7.99992 10.414L9.41392 8.99998L11.7069 11.292L13.9999 8.99998L15.4139 10.414L13.1209 12.707L15.4139 15L13.9999 16.413L11.7059 14.121L9.41392 16.414H9.41292Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <defs xmlns="http://www.w3.org/2000/svg">
      <clipPath id="clip0_14319_129445">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
    <clipPath id="clip0_14319_129445" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="white" />
    </clipPath>
    <rect width="24" height="24" xmlns="http://www.w3.org/2000/svg" />
  </g>,
  "CloudClose"
);
