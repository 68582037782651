import * as React from "react";
import { useEventListener } from "@tiny/utils";

function useNotificationsManager() {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  React.useEffect(() => {
    setIsCollapsed(false);
    document.body.classList.add("overflow-content");
    const timer = setTimeout(() => {
      document.body.classList.remove("overflow-content");
      setIsCollapsed(true);
    }, 3000);
    return () => {
      clearTimeout(timer);
      document.body.classList.remove("overflow-content");
    };
  }, []);

  useEventListener(
    "toggle-notifications",
    (event: CustomEvent<{ collapsed: boolean | null }>) => {
      const { collapsed } = event.detail;
      if (collapsed === null) {
        return handleToggleCollapse();
      }
      setIsCollapsed(collapsed);
    },
    React.useRef<Document>(document)
  );

  const handleToggleCollapse = React.useCallback(() => {
    setIsCollapsed((isCurrentlyCollapsed) => {
      if (isCurrentlyCollapsed) {
        document.body.classList.add("overflow-content");
      } else {
        document.body.classList.remove("overflow-content");
      }

      return !isCurrentlyCollapsed;
    });
  }, []);

  return { isCollapsed, handleToggleCollapse };
}

export { useNotificationsManager };
