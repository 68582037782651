import * as React from "react";
import { DialogProps } from "./Dialog";
import {
  CommonDialogConfig,
  InstantiatedDialogConfig,
} from "./DialogManager.types";
import { getForSize } from "./DialogOverrides";
import { createDialogConfig } from "./utils";

const useDialogManagerState = () => {
  const [requestedDialogConfig, setDialogConfig] =
    React.useState<InstantiatedDialogConfig>();

  const requestDialogBySize = React.useCallback(
    (size: DialogProps["maxWidth"], configOverrides: CommonDialogConfig) => {
      const dialogOverrides = getForSize(size);
      const config = createDialogConfig(dialogOverrides, configOverrides);
      setDialogConfig(config);
    },
    []
  );

  const clearDialogConfig = React.useCallback(
    () => setDialogConfig(undefined),
    [setDialogConfig]
  );

  return {
    requestDialogBySize,
    requestedDialogConfig,
    clearDialogConfig,
  };
};

export { useDialogManagerState };
