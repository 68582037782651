import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M9 14H10.3333C10.3333 14.72 11.2467 15.3333 12.3333 15.3333C13.42 15.3333 14.3333 14.72 14.3333 14C14.3333 13.2667 13.64 13 12.1733 12.6467C10.76 12.2933 9 11.8533 9 10C9 8.80667 9.98 7.79333 11.3333 7.45333V6H13.3333V7.45333C14.6867 7.79333 15.6667 8.80667 15.6667 10H14.3333C14.3333 9.28 13.42 8.66667 12.3333 8.66667C11.2467 8.66667 10.3333 9.28 10.3333 10C10.3333 10.7333 11.0267 11 12.4933 11.3533C13.9067 11.7067 15.6667 12.1467 15.6667 14C15.6667 15.1933 14.6867 16.2067 13.3333 16.5467V18H11.3333V16.5467C9.98 16.2067 9 15.1933 9 14Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2.00606 17.5204 6.47967 21.994 12 22C13.1262 21.9998 14.2091 21.8133 15.2195 21.4697C15.0768 21.005 15 20.5115 15 20C15 19.7972 15.0121 19.5972 15.0355 19.4007C14.0997 19.786 13.0747 19.9989 12 20H11.984C7.56729 19.9956 3.98979 16.4127 3.992 11.996C3.99421 7.57931 7.57528 4.00002 11.992 4.00002C16.4087 4.00002 19.9898 7.57931 19.992 11.996C19.9925 13.0721 19.7806 14.0988 19.3957 15.0362C19.5938 15.0123 19.7955 15 20 15C20.511 15 21.0041 15.0767 21.4685 15.2191C21.805 14.2287 21.9906 13.1684 21.9978 12.0654C22.0337 6.5689 17.6268 2.07382 12.1307 2.0009C6.63459 1.92797 2.10993 6.30455 2 11.8V12Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M20.5628 23H19.4373C19.292 23 19.1663 22.9019 19.1352 22.7642L19.0094 22.199C18.8415 22.1276 18.6823 22.0384 18.5347 21.9329L17.9667 22.1084C17.8282 22.1513 17.6776 22.0947 17.605 21.9725L17.041 21.0272C16.9691 20.905 16.9939 20.7507 17.1007 20.6555L17.5413 20.2655C17.5212 20.0888 17.5212 19.9106 17.5413 19.7339L17.1007 19.3448C16.9937 19.2495 16.969 19.0951 17.041 18.9728L17.6037 18.0269C17.6764 17.9047 17.827 17.8481 17.9655 17.891L18.5335 18.0665C18.609 18.0122 18.6875 17.9622 18.7688 17.9165C18.8468 17.8738 18.9271 17.8352 19.0094 17.8007L19.1355 17.2361C19.1665 17.0984 19.292 17.0001 19.4373 17H20.5628C20.7081 17.0001 20.8336 17.0984 20.8646 17.2361L20.992 17.801C21.0788 17.8381 21.1634 17.8799 21.2452 17.9264C21.3217 17.9692 21.3956 18.0162 21.4666 18.0671L22.0349 17.8916C22.1734 17.8489 22.3238 17.9054 22.3964 18.0275L22.9591 18.9734C23.031 19.0956 23.0062 19.2499 22.8995 19.3451L22.4588 19.7351C22.4789 19.9118 22.4789 20.09 22.4588 20.2667L22.8995 20.6567C23.0062 20.7519 23.031 20.9062 22.9591 21.0284L22.3964 21.9743C22.3238 22.0964 22.1734 22.1529 22.0349 22.1102L21.4666 21.9347C21.3947 21.9861 21.32 22.0337 21.2427 22.0772C21.1617 22.1228 21.0781 22.1639 20.9923 22.2005L20.8646 22.7642C20.8335 22.9018 20.708 22.9999 20.5628 23ZM19.9988 18.8C19.3157 18.8 18.762 19.3373 18.762 20C18.762 20.6627 19.3157 21.2 19.9988 21.2C20.6819 21.2 21.2356 20.6627 21.2356 20C21.2356 19.3373 20.6819 18.8 19.9988 18.8Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "Generatedrevenue"
);
