import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Form, FormWatch, TextControl } from "../../../../components";
import { validateCampaignName } from "../../../../utils/validateUtils";
import { useCampaignPageView } from "../../../CampaignPage.context";
import BestPractices from "./BestPractices";
import { useConfigView } from "../../../../Config.context";
import { CampaignCreateRequestView } from "../../../CampaignPage.types";
import InboxPreview from "./InboxPreview";

interface DetailsFormProps {
  form: UseFormReturn<CampaignCreateRequestView, object>;
  onSubmit: (values: CampaignCreateRequestView) => Promise<void>;
}

function DetailsForm(props: DetailsFormProps) {
  const { form, onSubmit } = props;

  const { title, details } = useCampaignPageView();

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={10}>
        <Grid item xs={6}>
          <Grid container spacing={6} mt={1}>
            <Grid item xs={12}>
              <TextControl
                name="name"
                defaultValue={details?.name || title}
                id="campaign-name"
                label="Campaign name"
                required
                maxLength={256}
                validate={validateCampaignName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormWatch name="subject">
                {(subject = details?.subject) => {
                  const maxLength = 150;
                  return (
                    <TextControl
                      name="subject"
                      defaultValue={details?.subject || ""}
                      id="campaign-subject"
                      label="Subject"
                      placeholder="Example here"
                      required
                      maxLength={maxLength}
                    />
                  );
                }}
              </FormWatch>
            </Grid>
            <Grid item xs={12}>
              <FormWatch name="preview">
                {(previewText = details?.preview) => {
                  const maxLength = 150;
                  return (
                    <TextControl
                      name="preview"
                      id="campaign-preview"
                      defaultValue={details?.preview || ""}
                      label="Preview text (Optional)"
                      placeholder="Example here"
                      maxLength={maxLength}
                    />
                  );
                }}
              </FormWatch>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="label1R" fontWeight="regular" color="grey.90">
            Inbox preview
          </Typography>
          <Box mt={3}>
            <InboxPreview enableFormWatcher />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={10} mt={1}>
        <Grid item xs={12}>
          <FormWatch name="subject">
            {(subject) => <BestPractices text={subject || ""} />}
          </FormWatch>
        </Grid>
      </Grid>
      <button hidden type="submit">
        submit
      </button>
    </Form>
  );
}

export default DetailsForm;
