import React, { SyntheticEvent, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CloseSmall } from "@tiny/icons";
import { useDialogInvokers } from "@tiny/lib";
import { useSearchParams } from "react-router-dom";

const checkboxes = ["Subscribed", "Unsubscribed", "Spam", "Bounced"];

function FilterSubscriberDialogContent() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tags, setTags] = useState<string[]>(
    (searchParams.get("tags") && searchParams.get("tags")?.split(",")) || []
  );
  const [status, setStatus] = useState<string[]>(
    (searchParams.get("statuses") &&
      searchParams.get("statuses")?.split(",")) ||
      []
  );

  const handleSave = () => {
    if (tags.length > 0) {
      searchParams.set("tags", tags.join(","));
    } else {
      searchParams.delete("tags");
    }

    if (status.length > 0) {
      searchParams.set("statuses", status.join(","));
    } else {
      searchParams.delete("statuses");
    }

    setSearchParams(searchParams);
    exitDialog();
  };

  const { exitDialog } = useDialogInvokers();

  const handleAddOption = (event: SyntheticEvent, newValue: any) => {
    if (newValue && newValue.inputValue) {
      setTags([...tags, newValue.inputValue]);
    } else if (newValue) {
      setTags(newValue);
    }
  };

  return (
    <>
      <Typography variant="h4" color="grey.100" my={4}>
        Status ({status?.length})
      </Typography>
      <Grid container spacing={4}>
        {checkboxes.map((label, index) => (
          <Grid item xs={6} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    mr: 2,
                  }}
                />
              }
              checked={status?.includes(label)}
              label={label}
              sx={{
                ml: 0,
                fontSize: 14,
              }}
              onChange={(e, checked: boolean) => {
                if (checked) {
                  setStatus([...status, label]);
                } else {
                  setStatus(status?.filter((value) => value !== label));
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h4" color="grey.100" my={4}>
        Tags ({tags?.length})
      </Typography>

      <Autocomplete
        multiple
        options={tags}
        value={tags}
        onChange={handleAddOption}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Add Tags" variant="outlined" />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={option}
              sx={(theme) => ({
                background: theme.palette.grey[30],
                color: theme.palette.grey[90],
                fontSize: 10,
                border: 0,
                fontWeight: 400,
              })}
              variant="outlined"
              deleteIcon={<CloseSmall />}
            />
          ))
        }
      />
      <Box justifyContent="end" display="flex" mt={4} gap={4}>
        <Button onClick={exitDialog} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          id="audience-subscribers-filter"
        >
          Save
        </Button>
      </Box>
    </>
  );
}

export default FilterSubscriberDialogContent;
