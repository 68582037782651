import { FormStatusSwitcher } from "@/components/Form/FormStatusSwitcher";
import { Box, Button } from "@mui/material";
import { ExternalLink } from "@tiny/icons";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { FormFooterProps } from "./types";

function FormFooter(props: FormFooterProps) {
  const { active = false, onChangeActivation, formStatus, id } = props;
  const navigate = useNavigate();

  const previewColor = React.useMemo(
    () => (active ? "primary.100" : "grey.80"),
    [active]
  );

  const onChange = React.useCallback(
    (_: any, isActivated: boolean) => {
      onChangeActivation?.(isActivated);
    },
    [onChangeActivation]
  );

  const previewClickHandler = () => navigate(`${id}/preview`);

  return (
    <Box display="flex" width="100%" justifyContent="space-between">
      <Button
        variant="text"
        endIcon={<ExternalLink />}
        sx={{ fontWeight: 400, m: 0, p: 0, lineHeight: 1, color: previewColor }}
        onClick={previewClickHandler}
        className="preview-cta"
        id="forms-overview-list-preview"
      >
        Preview
      </Button>
      <Box display="flex" alignItems="center" gap={1}>
        <FormStatusSwitcher
          status={formStatus}
          activeSwitch={active}
          onChangeSwitch={onChange}
          id="forms-overview-list-toggle-activate"
        />
      </Box>
    </Box>
  );
}

export default FormFooter;
