import React from "react";
import { Box, Skeleton } from "@mui/material";
import { CampaignResponseView } from "../../../../../app/GeneratedApi";
import { FormWatch } from "../../../../../components";
import { Label, StarOutline } from "@tiny/icons";
import { useConfigView } from "../../../../../Config.context";

type InboxPreviewDetails = Pick<
  CampaignResponseView,
  "senderName" | "subject" | "preview"
>;

function InboxPreview({
  details,
  enableFormWatcher,
}: {
  details?: InboxPreviewDetails | undefined;
  enableFormWatcher: boolean;
}) {
  const { config } = useConfigView();

  const TextContent = ({
    name,
    columnSx,
  }: {
    name: "senderName" | "subject" | "preview";
    columnSx: object;
  }) => {
    if (enableFormWatcher) {
      return (
        <Box sx={columnSx}>
          <FormWatch name={name}>
            {(content) =>
              content ? (
                <span
                  style={{
                    display: "block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontSize: 10,
                  }}
                >
                  {content}
                </span>
              ) : (
                <Skeleton
                  variant="text"
                  width="100%"
                  height={3}
                  animation={false}
                />
              )
            }
          </FormWatch>
        </Box>
      );
    }

    return (
      <Box sx={columnSx}>
        {details && details[name] ? (
          <span
            style={{
              display: "block",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontSize: 10,
            }}
          >
            {details[name] || ""}
          </span>
        ) : (
          <Skeleton
            sx={{}}
            variant="text"
            width="100%"
            height={3}
            animation={false}
          />
        )}
      </Box>
    );
  };

  const RowContent = ({ isPlaceholder }: { isPlaceholder: boolean }) => (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StarOutline sx={{ color: "grey.60", fontSize: 16, marginRight: 1 }} />
        <Label sx={{ color: "grey.60", fontSize: 16, marginRight: 2 }} />
      </Box>
      <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
        {isPlaceholder ? (
          <>
            <Skeleton
              sx={{ marginRight: "2.5%" }}
              variant="text"
              width="20%"
              height={3}
              animation={false}
            />
            <Skeleton
              sx={{ marginRight: "3%" }}
              variant="text"
              width="25%"
              height={3}
              animation={false}
            />
            <Skeleton
              variant="text"
              width="49.5%"
              height={3}
              animation={false}
            />
          </>
        ) : (
          <>
            <Box
              sx={{
                width: "20%",
                marginRight: "3%",
                overflow: "hidden",
                color: "grey.800",
                display: "flex",
                alignItems: "center",
              }}
            >
              {config?.senderDetails?.name ? (
                <span
                  style={{
                    display: "block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontSize: 10,
                  }}
                >
                  {config?.senderDetails?.name || ""}
                </span>
              ) : (
                <Skeleton
                  sx={{ marginRight: "2.5%" }}
                  variant="text"
                  width="20%"
                  height={3}
                  animation={false}
                />
              )}
            </Box>
            <TextContent
              name="subject"
              columnSx={{
                width: "25%",
                marginRight: "3%",
                overflow: "hidden",
                color: "grey.800",
                display: "flex",
                alignItems: "center",
              }}
            />
            <TextContent
              name="preview"
              columnSx={{
                flex: 1,
                overflow: "hidden",
                color: "grey.700",
                display: "flex",
                alignItems: "center",
              }}
            />
          </>
        )}
      </Box>
    </>
  );

  const Row = ({ isPlaceholder }: { isPlaceholder: boolean }) => (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        padding: 2,
        backgroundColor: isPlaceholder
          ? theme.palette.background.default
          : "transparent",
        borderTop: isPlaceholder
          ? `0.4px solid ${theme.palette.grey[30]}`
          : "none",
        borderBottom: isPlaceholder
          ? `0.4px solid ${theme.palette.grey[30]}`
          : "none",
        gap: 2,
        width: "100%",
        justifyContent: isPlaceholder ? "flex-start" : "space-between",
      })}
    >
      <RowContent isPlaceholder={isPlaceholder} />
    </Box>
  );

  return (
    <Box sx={{ fontSize: 16, marginRight: 1 }}>
      <Row isPlaceholder={true} />
      <Row isPlaceholder={false} />
      <Row isPlaceholder={true} />
      <Row isPlaceholder={true} />
      <Row isPlaceholder={true} />
    </Box>
  );
}

export default InboxPreview;
