import { NetworkFetchError, UnauthenticatedRequestError } from "../../errors";
import { createApiError } from "../../errors/apiErrorFactory";
import { authStorage } from "../authStorage";
import { requestNewToken } from "../authTokens";
import { fetchApi } from "./fetchApi";

type InitialFetchParams = [string, RequestInit | undefined, number];

/**
 * The signature of this handler is dictated by current implementation of
 * fetch-retry mechanism, when access tokens must be refreshed.
 * This mechanism should be replaced and this signature refactored
 * @param initialFetchParams
 */
function rootResponseHandler(initialFetchParams: InitialFetchParams) {
  return async (response: Response) => {
    if (response.ok) {
      return successHandler(response);
    }

    if (isAuthError(response)) {
      return failedAuthenticationHandler(response, initialFetchParams);
    }

    throw await createApiError(response);
  };
}

function isAuthError(response: Response) {
  return response.status === 401;
}

async function successHandler(response: Response) {
  const isJson = response.headers
    .get("content-type")
    ?.includes("application/json");
  return isJson ? response.json() : response;
}

async function failedAuthenticationHandler(
  response: Response,
  initialFetchParams: InitialFetchParams
) {
  const [url, requestConfig, retries] = initialFetchParams;
  const refreshTokenValue = authStorage.getRefreshToken();
  if (retries > 0 && refreshTokenValue) {
    const tokens = await requestNewToken(refreshTokenValue);
    if (tokens) {
      authStorage.updateTokens(tokens);
      return fetchApi(url, requestConfig, retries - 1);
    }
  }
  throw new UnauthenticatedRequestError(url);
}

function networkErrorHandler(url: string | URL) {
  return (error: Error) => {
    if (error.name === "AbortError" || error.name === "TypeError") {
      console.info(`Aborted request: ${url.toString()}`);
      return;
    }
    throw new NetworkFetchError(
      `Unknown NetworkFetchError for ${url.toString()}: ${
        error.name ?? "NoName"
      }:${error.message}`
    );
  };
}

export { rootResponseHandler, networkErrorHandler };
