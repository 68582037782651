import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M7 14C4.23858 14 2 11.7614 2 9C2 6.23858 4.23858 4 7 4C9.76142 4 12 6.23858 12 9C12 11.7614 9.76142 14 7 14ZM4 10H6.00001V12H8.00001V10H10V8L8.00001 8V6H6.00001V8L4 8V10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M20 14H11.899C12.4849 13.4259 12.9703 12.7496 13.3264 12L20 12C21.1046 12 22 12.8954 22 14V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V14C2 13.9669 2.0008 13.9341 2.00239 13.9014C2.57599 14.4862 3.25143 14.9708 4 15.3264V18L20 18V14Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "AddRow"
);
