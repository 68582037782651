import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 10H10V14H14V10ZM10 8C8.89543 8 8 8.89543 8 10V14C8 15.1046 8.89543 16 10 16H14C15.1046 16 16 15.1046 16 14V10C16 8.89543 15.1046 8 14 8H10Z"
    xmlns="http://www.w3.org/2000/svg"
  />,
  "DummyIconSmLine"
);
