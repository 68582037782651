import * as React from "react";
import { useIsLargerThan } from "@tiny/theme/utils";

import Sidebar from "../../components/Sidebar";
import MobileNavigation from "../../components/MobileMenu";

function Navigation({
  openMenu,
  toggleNavigation,
}: {
  openMenu: boolean;
  toggleNavigation: () => void;
}) {
  const isMobile = useIsLargerThan("sm");

  return isMobile ? (
    <Sidebar isCollapsed={!openMenu} toggleSidebar={toggleNavigation} />
  ) : (
    <MobileNavigation openMenu={openMenu} toggleMenu={toggleNavigation} />
  );
}

export default Navigation;
