import { Automation } from "@/types/api/automations";
import { useRevalidator } from "react-router-dom";
import { useInterval } from "@tiny/utils/react/useInterval";

function useAutomationsRefetch(automations: Automation[]) {
  const revalidator = useRevalidator();
  const shouldRefetch = automations.find(
    (automation) => automation.status === "GENERATE"
  );

  useInterval(
    () => {
      revalidator.revalidate();
    },
    shouldRefetch ? 3000 : null
  );
}

export { useAutomationsRefetch };
