import React, { ChangeEvent, useEffect, useState } from "react";
import { Campaign } from "@/types/api/campaigns";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ChevronBigDown } from "@tiny/icons";
import { fetchApi } from "@tiny/utils";
import { Coupon, GetCouponsResponse } from "../types";

function Discount({ formData }: { formData?: Campaign }) {
  const editMode = formData;
  const [isExisting, setIsExisting] = useState("existing");
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const { register, setValue, control } = useFormContext();
  const discountId = formData?.discountId;
  const [showDiscountContent, setShowDiscountContent] = useState<boolean>(
    !!discountId
  );

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (value === "existing" && discountId) {
      setValue("discountId", discountId);
      setValue(
        "discountValue",
        coupons?.find((coupon) => coupon.id === discountId)?.percentage
      );
    } else {
      setValue("discountId", "");
      setValue("discountValue", "");
    }
    setIsExisting(value);
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = () => {
    fetchApi<GetCouponsResponse>("/campaignservice/campaign/discount-series", {
      method: "get",
    })
      .then((response) => {
        setCoupons(response?.discounts);
        setValue("coupons", response?.discounts);
        if (editMode) {
          setValue("discountId", formData?.discountId);
          setValue(
            "discountValue",
            response?.discounts?.find(
              (coupon) => coupon?.id === formData?.discountId
            )?.percentage
          );
        }
      })
      .catch((error) => {
        console.log("Error fetching coupons:", error);
      });
  };

  return (
    <Box my={5}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" color="grey.100">
          Discount
        </Typography>
        <Switch
          checked={showDiscountContent}
          onChange={(event) => {
            if (event?.target?.checked) {
              if (discountId) {
                setValue("discountId", discountId);
                setValue(
                  "discountValue",
                  coupons.find((coupon) => coupon.id === discountId)?.percentage
                );
              }
            } else {
              setValue("discountId", "");
              setValue("discountValue", "");
            }
            setShowDiscountContent(event.target.checked);
          }}
        />
      </Box>
      {showDiscountContent && (
        <Box mx={2}>
          <RadioGroup
            aria-labelledby="discount-radio-buttons-group"
            name="discount-radio-buttons-group"
            row
            value={isExisting}
            onChange={handleChange}
          >
            <FormControlLabel
              value="existing"
              sx={(theme: Theme) => ({ fontSize: theme.spacing(3.5) })}
              control={<Radio />}
              label="Use an existing coupon"
            />
            <FormControlLabel
              value="new"
              sx={(theme: Theme) => ({ fontSize: theme.spacing(3.5) })}
              control={<Radio />}
              label="Create a new coupon"
            />
          </RadioGroup>
          {isExisting === "existing" ? (
            <FormControl fullWidth size="small" sx={{ mt: 4 }}>
              <InputLabel
                shrink
                id="select-coupons-label"
                sx={(theme: Theme) => ({ fontSize: theme.spacing(3.5) })}
              >
                Existing coupons *
              </InputLabel>
              <Controller
                name="discountId"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    variant="outlined"
                    id="coupons"
                    IconComponent={ChevronBigDown}
                    fullWidth
                    label="Existing coupons"
                    required
                    onChange={(event) => {
                      onChange(
                        event?.target?.value,
                        coupons?.find(
                          (coupon) => coupon?.id === event?.target?.value
                        )?.percentage
                      );
                      setValue(
                        "discountValue",
                        coupons?.find(
                          (coupon) => coupon?.id === event?.target?.value
                        )?.percentage
                      );
                    }}
                    value={value}
                    labelId="select-coupons-label"
                    notched
                    displayEmpty
                  >
                    <MenuItem
                      key="empty"
                      value=""
                      disabled
                      sx={(theme) => ({ fontSize: theme.spacing(3.5) })}
                    >
                      Select
                    </MenuItem>
                    {coupons.map((coupon) => (
                      <MenuItem
                        key={coupon.id}
                        value={coupon.id}
                        sx={(theme) => ({ fontSize: theme.spacing(3.5) })}
                      >
                        {`${coupon.shortSummary} ( ${coupon?.codes[0]} )`}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          ) : (
            <Box display="flex" gap={4} mt={4}>
              <FormControl fullWidth size="small">
                <InputLabel
                  shrink
                  id="discount-type-label"
                  sx={(theme: Theme) => ({ fontSize: theme.spacing(3.5) })}
                >
                  Discount Type *
                </InputLabel>
                <Controller
                  name="discount type"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Select
                      variant="outlined"
                      id="discount type"
                      IconComponent={ChevronBigDown}
                      fullWidth
                      label="Discount Type"
                      onChange={onChange}
                      value={value}
                      labelId="discount-type-label"
                      notched
                      displayEmpty
                    >
                      <MenuItem
                        key="empty"
                        value=""
                        sx={(theme) => ({ fontSize: theme.spacing(3.5) })}
                        selected
                      >
                        Percentage
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <TextField
                fullWidth
                label="value"
                {...register("discountValue")}
                size="small"
                required
                type="number"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: { textTransform: "capitalize" },
                  min: 1,
                  max: 100,
                }}
                sx={() => ({
                  "& .MuiInputLabel-root.Mui-disabled": {
                    backgroundColor: "transparent",
                  },
                })}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Discount;
