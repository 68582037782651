import * as React from "react";
import { Box, List } from "@mui/material";
import { Await, useRouteLoaderData } from "react-router-dom";
import type { RootLoader } from "@/types/RootLoader";
import { mainMenu } from "./helpers";
import NavigationItem from "../NavigationItem";
import SidebarHeader from "./SidebarHeader";
import PlanInfo from "../PlanInfo";
import ProfileMenu from "../ProfileMenu";
import SidebarWrapper from "./SidebarWrapper";

interface SidebarProps {
  isCollapsed: boolean;
  toggleSidebar: () => void;
}

function SideBar({ isCollapsed, toggleSidebar }: SidebarProps) {
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;

  return (
    <SidebarWrapper collapsed={isCollapsed}>
      <SidebarHeader collapsed={isCollapsed} toggleSidebar={toggleSidebar} />

      <List
        sx={{
          display: "flex",
          flex: 1,
          alignSelf: isCollapsed ? "center" : "flex-start",
          gap: 7,
          flexDirection: "column",
          width: "100%",
          mt: { xs: 0, sm: 16 },
        }}
      >
        {mainMenu.map((menuItem) => (
          <NavigationItem
            key={menuItem.name}
            to={menuItem.to}
            Icon={menuItem.Icon}
            IconActive={menuItem.IconActive}
            collapsed={isCollapsed}
            name={menuItem.name}
            tooltip={menuItem.tooltip}
            SecondaryLabel={menuItem.SecondaryLabel}
          />
        ))}
      </List>
      <Box
        display={{ xs: "inline-flex", sm: "flex" }}
        gap={{ xs: 5, sm: 7 }}
        flexDirection={{ xs: "row", sm: "column" }}
        alignItems="center"
      >
        <PlanInfo collapsed={isCollapsed} />
        <React.Suspense>
          <Await resolve={accountInfo}>
            <ProfileMenu collapsed={isCollapsed} />
          </Await>
        </React.Suspense>
      </Box>
    </SidebarWrapper>
  );
}

export default SideBar;
