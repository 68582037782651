import {
  IssueCta,
  IssueLinkCta,
  NotificationMessageProps,
} from "@/types/api/notifications";

interface NotificationManagerProps {
  isMenuOpened: boolean;
  onVisibleNotificationsChange: (isPresent: boolean) => void;
}

interface NotificationsControlProps {
  collapsed: boolean;
  counter: number;
  toggleHandler: () => void;
}

interface NotificationListProps {
  entries: NotificationMessageProps[];
  isCollapsed: boolean;
  silenceNotificationHandler: (id: string) => void;
}

function isLinkCta(issueCta: IssueCta): issueCta is IssueLinkCta {
  return issueCta.ctaType === "UI_PAGE";
}

export { isLinkCta };
export type {
  NotificationManagerProps,
  NotificationsControlProps,
  NotificationListProps,
};
