import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M19 10H17V7H14V5H17V2H19V5H22V7H19V10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M21 12H19V15H8.334C7.90107 14.9988 7.47964 15.1393 7.134 15.4L5 17V5H12V3H5C3.89543 3 3 3.89543 3 5V21L7.8 17.4C8.14582 17.1396 8.56713 16.9992 9 17H19C20.1046 17 21 16.1046 21 15V12Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "MessagePlusAlt"
);
