function lowercaseNoSpaces(title: string): string {
  return title.toLowerCase().replaceAll(" ", "");
}

function toTitleCase(title: string): string {
  return title.replace(
    /\w\S*/g,
    (txt: string) =>
      txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );
}

export { lowercaseNoSpaces, toTitleCase };
