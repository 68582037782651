import { Theme } from "@mui/material";

const MuiCssBaseline = {
  styleOverrides: (themeParam: Theme) => `
        body {
          font-family: ${
            themeParam.typography.fontFamily?.toString() ?? "serif"
          };
        },
        body.overflow-content {
          overflow: hidden
        },
      `,
};

export { MuiCssBaseline };
