import React from "react";
import { useTheme } from "@mui/material";
import { useDialogInvokers } from "@tiny/lib";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Importer from "@tinyemail/tiny-csv-importer";
import { getUtcMilliSecondsByDate } from "@tiny/utils/dateUtils";
import { TinyImporterProps, CustomerView } from "./TinyImporter.types";
import useTinyImporter from "./TinyImporter.hooks";

export default function TinyImporter({
  onSuccess,
  audienceName,
  importType,
}: TinyImporterProps) {
  const { exitDialog } = useDialogInvokers();

  const { fields } = useTinyImporter();
  const {
    palette: {
      primary: { dark: primaryColor },
    },
  } = useTheme();
  const PRIMARY_THEME = {
    colors: {
      primary: primaryColor,
      success: primaryColor,
    },
  };
  const postBulkAdd = async (customers: CustomerView[]) => {
    if (customers) {
      const newCustomers = customers?.map((customer: any) => {
        if (customer) {
          if (customer.tags?.length > 0) {
            customer.tags = customer.tags?.split(",");
          } else {
            customer.tags = null;
          }
          if (customer.birthday) {
            customer.birthday = getUtcMilliSecondsByDate(
              new Date(customer.birthday)
            );
          }
          if (customer.lastOrderDate) {
            customer.lastOrderDate = getUtcMilliSecondsByDate(
              new Date(customer.lastOrderDate)
            );
          }
        }
        return customer;
      });
      switch (importType) {
        case "CREATE":
          await onSuccess({
            name: audienceName,
            customers: newCustomers,
            audienceType: "UPLOAD",
            skipGenerateEvents: true,
          });
          break;
        case "UPDATE":
          await onSuccess({ customers: newCustomers });
          break;
        default:
          break;
      }
      exitDialog();
    }
  };

  return (
    <Importer
      theme={PRIMARY_THEME}
      fields={fields}
      onComplete={(data: any) => {
        postBulkAdd(data);
      }}
    />
  );
}
