import React from "react";
import { LoadingButton } from "@mui/lab";
import { EditPanelProps } from "./EditPanel.types";
import Panel from "../Panel";

function EditPanel(props: EditPanelProps): JSX.Element {
  const {
    buttonDisabled,
    buttonLoading,
    children,
    closedButtonLabel = "Edit",
    empty,
    onClose,
    onOpen,
    open,
    openedButtonLabel = "Save",
    title,
    buttonVariant,
    ...other
  } = props;

  return (
    <Panel
      {...other}
      title={title}
      button={
        <LoadingButton
          loading={buttonLoading}
          variant={buttonVariant || "contained"}
          onClick={open ? onClose : onOpen}
          disabled={buttonDisabled}
        >
          {open ? openedButtonLabel : closedButtonLabel}
        </LoadingButton>
      }
    >
      {children}
    </Panel>
  );
}

export default EditPanel;
