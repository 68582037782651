import { Box, Typography } from "@mui/material";
import { formatDate, isInFirstWeekOfMonth } from "@tiny/utils/dateUtils";
import React from "react";

interface DateHeaderProps {
  date?: Date;
  viewDate?: Date;
}

const MonthViewCard = ({ viewDate, date }: DateHeaderProps) => (
  <Box display="flex" justifyContent="space-between" p={2}>
    <Typography variant="label3R" color="grey.80">
      {date &&
        viewDate &&
        (isInFirstWeekOfMonth(viewDate, date) ? formatDate(date, "EE") : "")}
    </Typography>
    <Typography variant="label3R" color="grey.80">
      {date && date.getDate()}
    </Typography>
  </Box>
);

export default MonthViewCard;
