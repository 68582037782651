import React from "react";

import useForwardRef from "../../hooks/useForwardRef";
import useId from "../../hooks/useId";
import {
  anchorOriginDefault,
  transformOriginDefault,
} from "./MenuIconButton.helpers";
import { IconButton, Menu } from "@mui/material";
import { MenuIconButtonProps } from "./MenuIconButton.types";

const TOTAL_MENU_PADDING_X = 24;

function MenuIconButton(
  props: MenuIconButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    icon,
    anchorOrigin = anchorOriginDefault,
    children,
    onClick,
    transformOrigin = transformOriginDefault,
    menuSize,
    ...other
  } = props;

  const buttonRef = useForwardRef(ref);

  const menuId = useId();

  const [open, setOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    onClick?.(event);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton ref={buttonRef} onClick={handleClick}>
        {icon}
      </IconButton>
      <Menu
        anchorEl={buttonRef.current}
        anchorOrigin={anchorOrigin}
        disableScrollLock
        id={menuId}
        onClose={handleClose}
        open={open}
        transformOrigin={transformOrigin}
        onClick={handleClose}
        MenuListProps={{
          style: {
            minWidth: buttonRef.current
              ? buttonRef.current.clientWidth - TOTAL_MENU_PADDING_X
              : undefined,
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
}

export default React.forwardRef(MenuIconButton);
