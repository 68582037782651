import faker from "@faker-js/faker";
import { add, format } from "date-fns";

const FAKER_DATE_PATERN = "yyyy-MM-dd";

export function getRandomDate(pattern: string) {
  const to = new Date();
  const from = add(to, { years: -100 });
  return format(
    faker.date.between(
      format(from, FAKER_DATE_PATERN),
      format(to, FAKER_DATE_PATERN)
    ),
    pattern
  );
}
faker.locale = "en";

export function getRandomEmail() {
  return faker.internet.email();
}

export function getRandomFirstName() {
  return faker.name.firstName();
}

export function getRandomLastName() {
  return faker.name.lastName();
}

export function getRandomSource(
  elements = ["online_shop", "sales_force", "email_marketing"]
) {
  return faker.random.arrayElement(elements);
}

export function getRandomWord() {
  return faker.random.word();
}

export function getRandomTags() {
  return [getRandomWord(), getRandomWord()].join(",");
}

export function getRandomCurrency() {
  return faker.finance.currencyCode();
}

export function getRandomCountry() {
  return faker.address.country();
}

export function getRandomState() {
  return faker.address.state();
}

export function getRandomCity() {
  return faker.address.cityName();
}

export function getRandomAddress1() {
  return faker.address.streetAddress();
}

export function getRandomAddress2() {
  return faker.address.secondaryAddress();
}

export function getRandomZipCode() {
  return faker.address.zipCode();
}

export function getRandomPhone() {
  return faker.phone.phoneNumber();
}

export function getRandomCompany() {
  return faker.company.companyName();
}

export function getRandomOrders() {
  return faker.datatype.number({
    min: 1,
    max: 10,
  });
}

export function getRandomLifetimeValue() {
  return faker.datatype.number({
    min: 1,
    max: 1000,
  });
}

export function getRandomId() {
  return faker.datatype.uuid();
}

export function getRandomProductName() {
  return faker.commerce.productName();
}

export function getRandomOrderPrice() {
  return faker.datatype.number({
    min: 1,
    max: 1000,
  });
}
