import React from "react";
import useResizeObserver from "use-resize-observer";
import { Box } from "@mui/material";

import { HtmlPreviewProps } from "./HtmlPreview.types";
import { getIframeHead, makeNotInteractive } from "./HtmlPreviewHelpers";

export default function HtmlPreview(props: HtmlPreviewProps): JSX.Element {
  const {
    contentWidth,
    css,
    height: heightProp,
    html,
    interactive,
    scrollable,
    width: widthProp,
    borderRadius,
    initWindow,
    scriptsIncluded,
    formType,
    mobileMargin,
  } = props;

  const { ref, width, height } = useResizeObserver<HTMLDivElement>();

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const writeContentWithScripts = React.useCallback(() => {
    if (iframeRef.current && !iframeRef.current.srcdoc) {
      initWindow?.(iframeRef.current?.contentWindow);
      const dom = document.implementation.createDocument(
        "http://www.w3.org/1999/xhtml",
        "html",
        null
      );
      const head = dom.createElement("head");
      head.innerHTML = getIframeHead({
        interactive,
        scrollable,
        css,
      });
      dom.firstChild?.appendChild(head);

      if (html) {
        const body = dom.createElement("body");
        body.innerHTML = !interactive ? makeNotInteractive(html) : html;
        dom.firstChild?.appendChild(body);
        formType === "EMBEDDED" &&
          body?.setAttribute(
            "style",
            "width: 100%; position: absolute; bottom: 0"
          );
        if (formType === "EMBEDDED") {
          body.firstElementChild?.setAttribute(
            "style",
            `justify-content: center; ${
              mobileMargin && `margin-bottom: ${mobileMargin}`
            }`
          );
        }
      }
      iframeRef.current.srcdoc = new XMLSerializer().serializeToString(dom);
    }
  }, [css, formType, html, initWindow, interactive, mobileMargin, scrollable]);

  const writeContentWithNoScripts = React.useCallback(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current?.contentWindow?.document;
      if (iframeDoc?.body && html) {
        const convertedHtml = !interactive ? makeNotInteractive(html) : html;
        iframeDoc.body.innerHTML = convertedHtml;
        iframeDoc
          .querySelector(".es-wrapper")
          ?.setAttribute("style", "display: block");
      }
      if (iframeDoc?.head) {
        iframeDoc.head.innerHTML = getIframeHead({
          interactive,
          scrollable,
          css,
        });
      }
    }
  }, [css, html, interactive, scrollable]);

  const writeOnIframe = React.useCallback(async () => {
    scriptsIncluded ? writeContentWithScripts() : writeContentWithNoScripts();
  }, [scriptsIncluded, writeContentWithNoScripts, writeContentWithScripts]);

  const handleLoad = React.useCallback(() => {
    writeOnIframe();
  }, [writeOnIframe]);

  React.useEffect(() => {
    writeOnIframe();
  }, [writeOnIframe]);

  const delta = width ? contentWidth / width : 0;
  let deltaScale = width ? width / contentWidth : 0;
  deltaScale = deltaScale > 1 ? 1 : deltaScale;
  const iframeWidth = contentWidth < (width || 0) ? width : contentWidth;
  let iframeHeight = height ? delta * height : 0;
  if (height && iframeHeight < height) {
    iframeHeight = height;
  }

  const rootStyle = {
    width: widthProp,
    height: heightProp,
    borderRadius,
  };

  const iframeStyle = {
    width: iframeWidth,
    height: iframeHeight,
    opacity: width && height ? 1 : 0,
    transform: `scale(${deltaScale})`,
  };

  return (
    <Box
      sx={{
        transformOrigin: "top left",
        border: 0,
      }}
      style={rootStyle}
      ref={ref}
    >
      <iframe
        ref={iframeRef}
        src="about:blank"
        title="Some title"
        style={{ ...iframeStyle, transformOrigin: "top left", border: 0 }}
        onLoad={handleLoad}
      />
    </Box>
  );
}
