import { MuiPaginationItem } from "./MuiPaginationItem";
import { MuiTypography } from "./MuiTypography";
import { MuiButton } from "./MuiButton";
import { MuiTabs } from "./MuiTabs";
import { MuiTab } from "./MuiTab";
import { MuiTooltip } from "./MuiTooltip";
import { MuiChip } from "./MuiChip";
import { MuiAvatar } from "./MuiAvatar";
import { MuiDialog } from "./MuiDialog";
import { MuiDialogTitle } from "./MuiDialogTitle";
import { MuiDialogContent } from "./MuiDialogContent";
import { MuiDialogActions } from "./MuiDialogActions";
import { MuiSelect } from "./MuiSelect";
import { MuiTabPanel } from "./MuiTabPanel";
import { MuiCssBaseline } from "./MuiCssBaseline";
import { MuiInputBase } from "./MuiInputBase";
import { MuiSwitch } from "./MuiSwitch";
import { MuiTextField } from "./MuiTextField";
import { MuiAlert } from "./MuiAlert";
import { MuiTableCell } from "./MuiTableCell";
import { MuiTableRow } from "./MuiTableRow";
import { MuiCheckbox } from "./MuiCheckbox";
import { MuiTableHead } from "./MuiTableHead";
import { MuiRadio } from "./MuiRadio";

const components = {
  MuiTypography,
  MuiButton,
  MuiCssBaseline,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiInputBase,
  MuiPaginationItem,
  MuiSelect,
  MuiTabs,
  MuiTab,
  MuiTabPanel,
  MuiTooltip,
  MuiChip,
  MuiAvatar,
  MuiSwitch,
  MuiTextField,
  MuiAlert,
  MuiTableCell,
  MuiTableRow,
  MuiCheckbox,
  MuiTableHead,
  MuiRadio,
};

export { components };
