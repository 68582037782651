import { EndpointConfig } from "../types";
import { authStorage } from "../authStorage";

export function prepareRequestHeaders(
  endpointConfig: EndpointConfig
): HeadersInit | undefined {
  const { requireAuthorization = false } = endpointConfig;
  let headers: HeadersInit | undefined = { "Content-Type": "application/json" };
  if (requireAuthorization) {
    headers = {
      ...headers,
      Authorization: `Bearer ${authStorage.getAccessToken() ?? ""}`,
    };
  }
  return headers;
}
