import React from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useDialogInvokers } from "@tiny/lib";
import { Form, useActionData, useNavigation } from "react-router-dom";
import { BackEndError, isBackendError } from "@tiny/utils/api/types";
import { PlanSelectionActionResponse } from "@/routes/PlansPage/types";

function CouponCodeForm({ action }: { action: string | undefined }) {
  const actionData = useActionData() as
    | PlanSelectionActionResponse
    | BackEndError;
  const navigation = useNavigation();
  const [error, setError] = React.useState<string | undefined>("");
  const { exitDialog } = useDialogInvokers();
  const couponCodeRef = React.useRef(
    !isBackendError(actionData) ? actionData?.couponCode : ""
  );

  React.useEffect(() => {
    if (actionData && navigation.state === "loading") {
      if (isBackendError(actionData)) {
        setError(actionData.message);
        couponCodeRef.current = "";
      } else {
        exitDialog();
      }
    }
  }, [actionData, exitDialog, navigation.state]);

  return (
    <Form method="post" action={action}>
      <TextField
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          couponCodeRef.current = e.target.value;
          if (e.target.value.length === 0) {
            setError("");
          }
        }}
        name="couponCode"
        inputRef={couponCodeRef}
        placeholder="ex.albert20"
        size="small"
        fullWidth
        sx={{ mt: 4, mb: 2 }}
        error={!!error}
      />
      {error && (
        <Typography variant="p3" color="error" mb={4}>
          {error}
        </Typography>
      )}
      <input type="hidden" name="_action" value="applyCoupon" />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disableElevation
        id="myaccount-plans-submit-code"
      >
        Submit code
      </Button>
    </Form>
  );
}

export default CouponCodeForm;
