import { Box, Theme } from "@mui/material";
import React from "react";
import { ColorPreferences } from "@tiny/mui/ColorInput/types";

function ColorCircles({ primaryColor, secondaryColor }: ColorPreferences) {
  return (
    <Box display="flex">
      <Box
        sx={(theme: Theme) => ({
          backgroundColor: primaryColor,
          width: theme.spacing(4),
          height: theme.spacing(4),
          borderRadius: "50%",
          position: "relative",
        })}
      />
      <Box
        sx={{
          backgroundColor: secondaryColor,
          width: 16,
          height: 16,
          borderRadius: "50%",
          position: "absolute",
          marginLeft: 3,
        }}
      />
    </Box>
  );
}

export default ColorCircles;
