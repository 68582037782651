import useSearchParam from "@/hooks/router/useSearchParam";
import { useSearchParams } from "react-router-dom";

const activityTypeFilter = (
  filterValue: string
): { activityType: Array<string> } => {
  switch (filterValue) {
    case "ALL_TYPES": {
      return {
        activityType: ["AUTOMATION", "CAMPAIGN"],
      };
    }
    case "AUTOMATION": {
      return { activityType: ["AUTOMATION"] };
    }
    case "CAMPAIGN": {
      return { activityType: ["CAMPAIGN"] };
    }
    default: {
      return {
        activityType: ["AUTOMATION", "CAMPAIGN"],
      };
    }
  }
};

function usePageFilter(defaultValue: string) {
  const paramName = "filter";

  const [period] = useSearchParam(paramName, defaultValue);
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeFilter = (newValue: string) => {
    searchParams.set(paramName, newValue);
    setSearchParams(searchParams);
  };

  return [period, onChangeFilter] as const;
}

export { usePageFilter, activityTypeFilter };
