import React from "react";
import { extractTemplateData } from "../../models/template/templateUtils";
import {
  Template,
  TemplateListProps,
  TemplateType,
} from "./TemplateList.types";
import { useConfigView } from "../../Config.context";
import { EmailActivityTemplateIdentityView } from "../../pages/CampaignPage.types";

export function useTemplateList(props: TemplateListProps) {
  const { config } = useConfigView();
  const {
    activeTab,
    campaignId,
    campaignName,
    emptyStateMessage,
    getPreviewMainActionName,
    mainActionText,
    onCreateCustomTemplate,
    onMainActionClick,
    onPreviewMainActionClick,
    refetchSections,
    selectable,
    selectedActionText,
    selectedTemplate,
    ...boxProps
  } = props;

  const [previewOpen, setPreviewOpen] = React.useState(false);

  const [activeTemplate, setActiveTemplate] =
    React.useState<EmailActivityTemplateIdentityView>();

  const [activeSaved, setActiveSaved] = React.useState<boolean>();

  const [activeTemplateType, setActiveTemplateType] =
    React.useState<TemplateType>();

  const [activeSectionTitle, setActiveSectionTitle] = React.useState<string>();

  const openTemplatePreview = React.useCallback(
    (
      sectionTitle: string,
      template?: Template,
      saved?: boolean,
      type?: TemplateType
    ) => {
      setActiveSectionTitle(sectionTitle);
      setActiveTemplate(template);
      setActiveSaved(saved);
      setActiveTemplateType(type);
      setPreviewOpen(true);
    },
    []
  );

  const closePreview = React.useCallback(() => {
    setPreviewOpen(false);
  }, []);

  const mainActionPreview = React.useCallback(() => {
    onPreviewMainActionClick?.(
      activeTemplate!,
      activeSaved,
      activeTemplateType
    );
    setPreviewOpen(false);
  }, [
    activeSaved,
    activeTemplate,
    activeTemplateType,
    onPreviewMainActionClick,
  ]);

  const attributes = React.useMemo(() => {
    return config?.defaultTemplateAttributes?.attributes || [];
  }, []);

  const templateData = React.useMemo(() => {
    if (attributes) {
      return extractTemplateData(attributes);
    }
  }, [attributes]);

  const previewMainActionName = React.useMemo(() => {
    return getPreviewMainActionName?.(activeSectionTitle!) || "Close";
  }, [activeSectionTitle, getPreviewMainActionName]);

  const renderWithEjs = activeTemplateType === "CUSTOM";
  const showSelected = !!selectedTemplate;

  return {
    renderWithEjs,
    activeTab,
    activeTemplate,
    attributes,
    boxProps,
    campaignId,
    campaignName,
    closePreview,
    emptyStateMessage,
    getPreviewMainActionName,
    mainActionPreview,
    mainActionText,
    onCreateCustomTemplate,
    onMainActionClick,
    onPreviewMainActionClick,
    openTemplatePreview,
    previewMainActionName,
    previewOpen,
    refetchSections,
    selectable,
    selectedActionText,
    selectedTemplate,
    templateData,
    activeSaved,
    showSelected,
  };
}
