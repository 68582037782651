import React from "react";
import { useActionData, useLoaderData } from "react-router-dom";
import { GetSubscriptionsResponse } from "@/types/api/subscriptions";
import { subscriptionMapper } from "@/routes/AccountSettings/Plans/mapper";
import { PlanSelectionActionResponse } from "@/routes/PlansPage/types";
import OnboardingPageTitle from "@/components/OnboardingPageTitle";
import CouponCode from "@/components/CouponCode";
import { Subscription } from "@/routes/AccountSettings/Plans/types";
import CenteredContainer from "../../components/CenteredContainer";
import PlanSelection from "../../components/PlanSelection";

// TODO cleanup duplicated code with apps/einstein/src/routes/Plans/index.tsx
function PlansPage() {
  const dataLoaderResponse = useLoaderData() as GetSubscriptionsResponse;
  const actionData = useActionData() as PlanSelectionActionResponse;

  const appliedCouponPrices: { [key: string]: Subscription } =
    actionData?.subscriptions?.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: currentValue,
      }),
      {}
    );

  const data = dataLoaderResponse?.subscriptions?.map((s) =>
    subscriptionMapper(s, appliedCouponPrices?.[s.id])
  );

  return (
    <CenteredContainer>
      <OnboardingPageTitle title="Select your plan" />
      <CouponCode couponFormAction="/onboarding/plan-selection" />
      <PlanSelection plans={data} />
    </CenteredContainer>
  );
}

export default PlansPage;
export * from "./loader";
export * from "./action";
