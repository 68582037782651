const flexContainer = {
  columnGap: "1rem",
};

const root = {
  minHeight: "1.875rem",
  "& .MuiTabs-flexContainer": flexContainer,
};

const styleOverrides = {
  root,
};

export { styleOverrides };
