import { createRoutesFromElements, Navigate, Route } from "react-router-dom";
import ErrorBoundary from "@/components/ErrorBoundary";
import WelcomePage from "./Welcome";
import LoginPage from "./Login";
import LayoutUnauthenticated from "./LayoutSimple";
import SenderEmailConfirmation from "./SenderEmailConfirmation";
import { createBrowserRouterWithSentry } from "./routesUtils";

const ENVS = import.meta.env;
const isDevEnv = ENVS.DEV;

const createRoutes: any = ({
  onAuthenticated,
}: {
  onAuthenticated: () => void;
}) =>
  createBrowserRouterWithSentry(
    createRoutesFromElements(
      <Route
        path="/"
        element={<LayoutUnauthenticated />}
        errorElement={<ErrorBoundary />}
      >
        <Route index element={<WelcomePage />} />
        {isDevEnv && (
          <Route
            path="login"
            element={<LoginPage onSuccess={onAuthenticated} />}
          />
        )}
        <Route
          index
          path="senderDetails/emailConfirmation/:token"
          element={<SenderEmailConfirmation />}
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    )
  );

export default createRoutes;
