import { Outlet, useLoaderData } from "react-router-dom";
import PageTitle from "@/components/PageTitle";
import { GetFormsResponse } from "@/types/api/forms";
import Grid from "@mui/material/Grid";
import Form from "@/components/Form";
import { useFormsRefetch } from "@/routes/Forms/useFormsRefetch";
import { Box } from "@mui/material";
import React from "react";
import RegenerateAll from "@/components/RegenerateAll";

function Forms() {
  const { forms } = useLoaderData() as GetFormsResponse;

  useFormsRefetch(forms);

  const activeForms = forms.filter((form) => form.formStatus === "ACTIVE");
  const formName = activeForms.map((form) => form.name);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PageTitle title="Forms" />
        <RegenerateAll
          method="post"
          action="/main/forms"
          confirmationMessage="Do you want to retrain tinyAlbert with updated Forms? Doing this may recreate some or all of your
        templates"
          successMessage='You have successfully "Albertized" your forms.'
          id="forms-overview-regenerate-all"
        />
      </Box>
      <Grid
        container
        spacing={{ xs: 3, md: 5, lg: 5 }}
        columns={{ xs: 12, md: 12, lg: 12 }}
        id="form-cards-section"
      >
        {forms.map(({ enabled, ...formProps }) => (
          <Grid item xs={12} md={6} lg={4} key={formProps.id}>
            <Form {...formProps} active={enabled} activeForms={formName} />
          </Grid>
        ))}
      </Grid>
      <Outlet />
    </>
  );
}

export default Forms;
export * from "./loader";
export * from "./action";
