import React from "react";
import { Image } from "@tiny/icons";
import { Divider, Box, Skeleton } from "@mui/material";
import { useWatch } from "react-hook-form";
import { mapSocialIconsByType, getContrastColor } from "../brandkit.utils";

function EmailPreview() {
  const values = useWatch();

  const [imageSrc, setImageSrc] = React.useState<string | undefined>("");

  React.useEffect(() => {
    const logo = values?.logoUrl;
    if (!logo) {
      setImageSrc(undefined);
      return;
    }
    if (typeof logo === "string") {
      setImageSrc(logo);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(logo);
    reader.onload = () => {
      if (typeof reader.result === "string") {
        setImageSrc(reader.result);
      }
    };
  }, [values?.logoUrl]);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        px: { xs: 4, md: 6 },
        borderRadius: 2,
        border: `0.5px solid ${theme.palette.grey[60]}`,
      })}
    >
      <Box display="flex" justifyContent="center">
        <Box
          sx={(theme) => ({
            height: theme.typography.pxToRem(60),
            width: theme.typography.pxToRem(60),
            my: 5,
          })}
        >
          {imageSrc ? (
            <img
              src={imageSrc}
              height="100%"
              width="100%"
              style={{
                objectFit: "cover",
              }}
              alt="logo"
            />
          ) : (
            <Box
              sx={(theme) => ({
                width: "100%",
                height: "100%",
                margin: "auto",
                display: "flex",
                background: theme.palette.grey[60],
                alignItems: "center",
                borderRadius: theme.typography.pxToRem(12),
                justifyContent: "center",
              })}
            >
              <Image />
            </Box>
          )}
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 5 }}>
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation={false} />
        <Skeleton
          variant="rectangular"
          sx={{ borderRadius: 1 }}
          height={60}
          animation={false}
        />
      </Box>
      <Box
        my={5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          p={4}
          sx={{
            backgroundColor: values?.primaryColor,
            width: "100%",
            textAlign: "center",
            fontWeight: "semibold",
            color: getContrastColor(values?.primaryColor),
          }}
        >
          Button
        </Box>
      </Box>
      <Divider />
      <Box my={5} display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" alignItems="center" gap={{ sm: 2, md: 3 }}>
          {values.socialLinks?.map((social: any, index: number) => {
            if (!social.type) {
              return null;
            }
            return <Box key={index}>{mapSocialIconsByType[social?.type]}</Box>;
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default EmailPreview;
