import { CircularProgress, Container } from "@mui/material";
import React from "react";
import zIndex from "@mui/material/styles/zIndex";
import { ScreenLoaderProps } from "./ScreenLoader.types";

const ScreenLoader = ({ visible }: ScreenLoaderProps) => {
  if (!visible) {
    return null;
  }
  return (
    <Container
      sx={(theme) => ({
        position: "fixed",
        m: 0,
        p: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        minWidth: "100vw",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: zIndex.tooltip,
      })}
      disableGutters
    >
      <CircularProgress />
    </Container>
  );
};

export default ScreenLoader;
