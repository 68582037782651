import { GetFormsResponse } from "@/types/api/forms";
import { Button } from "@mui/material";
import { PreviewDialog } from "@tiny/lib/ConfirmationDialog/DialogTypes/PreviewDialog";
import { CustomError } from "@tiny/utils/errors";
import * as React from "react";
import {
  Form,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";

function FormPreviewDialog() {
  const { id } = useParams();
  if (!id) throw new CustomError("Could not find id when loading Preview");
  const navigate = useNavigate();

  const redirect = () => {
    navigate(-1);
  };

  const { forms } = useRouteLoaderData("forms") as GetFormsResponse;
  const [form] = forms.filter((candidate) => candidate.id === id);
  const { payLoad, formStatus = "GENERATE", enabled } = form;
  const html = `<div><script src="/assets/TinyFormRenderer.js" data-ia1="preview" data-if2="preview" data-preview-payload='${payLoad}'></script></div>`;
  const isReady = formStatus !== "GENERATE";
  const showRegenerate = !enabled;

  return (
    <PreviewDialog
      isReady={isReady}
      open
      onClose={redirect}
      template={{ html, css: undefined }}
      actions={
        <>
          <Button onClick={redirect} className="preview-cta" variant="text">
            Cancel
          </Button>
          {showRegenerate ? (
            <Form method="put" replace>
              <input type="hidden" name="_action" value="regenerate" />
              <input type="hidden" name="_id" value={id} />
              <Button
                variant="contained"
                className="regenerate-cta"
                disabled={!isReady}
                type="submit"
                id="forms-overview-preview-regenerate"
              >
                Regenerate
              </Button>
            </Form>
          ) : null}
        </>
      }
    />
  );
}

export { FormPreviewDialog };
