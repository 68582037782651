import { Box } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";

export const showUploadMessage = ({
  variant,
  message,
  description,
  persist = false,
  hideOnClick = false,
  ctaActionText,
  ctaActionFunction,
}: {
  variant: "uploading" | "uploadSuccess" | "uploadFailed";
  message: string;
  description: string;
  persist?: boolean;
  hideOnClick?: boolean;
  ctaActionFunction?: VoidFunction;
  ctaActionText?: string;
}) => {
  const key = enqueueSnackbar(
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 1.25,
      }}
    >
      <div
        style={{
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "18px",
          color: "#0F1526",
        }}
      >
        {message}
      </div>
      <div
        style={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "16px",
          color: "#6F737D",
          maxWidth: "250px",
        }}
      >
        {description}
      </div>

      {ctaActionText && ctaActionFunction && (
        <Box
          sx={(theme) => ({
            alignSelf: "flex-end",
            cursor: "pointer",
            color: theme.palette.primary[100],
            fontSize: "14px",
            lineHeight: "16px",
          })}
          onClick={ctaActionFunction}
        >
          {ctaActionText}
        </Box>
      )}
    </Box>,
    {
      variant,
      persist,
      onClick: () => {
        if (hideOnClick) {
          closeSnackbar(key);
        }
      },
      description,
    }
  );
};
