import * as React from "react";

const useScreenLoaderManagerState = () => {
  const [screenLoaderVisible, setScreenLoaderVisible] =
    React.useState<boolean>(false);

  const showScreenLoader = () => {
    setScreenLoaderVisible(true);
  };
  const hideScreenLoader = () => {
    setScreenLoaderVisible(false);
  };

  return {
    showScreenLoader,
    hideScreenLoader,
    screenLoaderVisible,
  };
};

export { useScreenLoaderManagerState };
