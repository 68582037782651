import React from "react";
import {
  Button,
  IconButton,
  MenuItem,
  Typography,
  Tooltip,
  Box,
  List,
  SxProps,
  Theme,
} from "@mui/material";
import { MenuButton, TruncateTooltip } from "../../components";
import { CloseBig, Clock, BarTop, Show } from "@tiny/icons";
import { useCampaignPageView } from "../CampaignPage.context";
import { StepItem } from "./StepItem/StepItem";
import QuitCampaign from "../QuitCampaign";
import CampaignStatus from "../../customComponents/CampaignStatus/CampaignStatus";
import { HeaderAction } from "../CampaignPage.types";
import MenuIconButton from "../../components/MenuIconButton/MenuIconButton";

const CampaignHeader = (): JSX.Element => {
  const {
    activeStep,
    setActiveStep,
    headerActions,
    headerControlAction,
    quitCampaign,
    steps,
    templateEditing,
    templateLoaded,
    title,
    toggleTemplateHistory,
    campaignStatus,
  } = useCampaignPageView();

  const historyButtonVisible = activeStep === "design" && templateEditing;

  const pausedTooltip =
    "The campaign is under review. This process may take up to 15 minutes.";

  const ICONS: { [type: string]: React.ReactNode } = {
    BarTop: <BarTop />,
    Show: <Show />,
  };

  const ActionButton: React.FunctionComponent<{
    action: HeaderAction;
    sx?: SxProps<Theme>;
    children: React.ReactNode;
  }> = (props) => {
    const { sx, action, children } = props;
    const { type, ...other } = action;
    if (type === "icon") {
      const icon = ICONS[action.text];
      return (
        <IconButton
          sx={(theme) => ({
            width: 24,
            color: theme.palette.primary.main,
          })}
          color={action.disabled ? undefined : "primary"}
          {...other}
        >
          {icon}
        </IconButton>
      );
    } else {
      return (
        <Button sx={sx} {...other} variant="text">
          {children}
        </Button>
      );
    }
  };

  const ActionMenuButton: React.FunctionComponent<{
    action: HeaderAction;
    sx?: SxProps<Theme>;
    children: React.ReactNode;
  }> = (props) => {
    const { sx, action, children } = props;
    const { type, ...other } = action;
    if (type === "icon") {
      const icon = ICONS[action.text];
      return (
        <MenuIconButton
          sx={sx}
          color={action.disabled ? undefined : "primary"}
          icon={icon}
          {...other}
        >
          {children}
        </MenuIconButton>
      );
    } else {
      return (
        <Button sx={sx} {...other}>
          {children}
        </Button>
      );
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={(theme) => {
          return {
            display: "flex",
            alignItems: "center",
            height: 72,
            backgroundColor: "white",
          };
        }}
      >
        <Box
          sx={(theme) => ({
            boxSizing: "border-box",
            flex: "1",
            display: "flex",
            alignItems: "center",
            paddingRight: 40,
            [theme.breakpoints.up("lg")]: {
              paddingRight: 80,
            },
          })}
        >
          <Box
            sx={() => ({
              flex: "0 0 54px",
              boxSizing: "border-box",
              padding: "0 12px 0 20px",
            })}
          >
            <IconButton size="small" onClick={quitCampaign}>
              <CloseBig />
            </IconButton>
            <QuitCampaign />
          </Box>
          <Box
            sx={{
              minWidth: "10px",
            }}
          >
            <TruncateTooltip arrow>
              <Typography
                variant="h4"
                component="h1"
                fontWeight="bold"
                color="grey"
                noWrap
                m={0}
              >
                {title}
              </Typography>
            </TruncateTooltip>
          </Box>
          {campaignStatus === "PAUSED" && (
            <Box
              sx={{
                paddingLeft: 20,
              }}
            >
              <Tooltip title={pausedTooltip} arrow>
                <CampaignStatus status={campaignStatus} />
              </Tooltip>
            </Box>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            flex: "0 0 360px",
            [theme.breakpoints.up("lg")]: {
              flex: "0 0 420px",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "center",
              listStyle: "none",
              margin: 0,
              padding: 0,
              [theme.breakpoints.up("lg")]: {
                gap: theme.spacing(2),
              },
            })}
          >
            {steps.map((step) => (
              <StepItem
                key={step.step}
                step={step}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                disabled={step.disabled}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            boxSizing: "border-box",
            flex: "1",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 5,
            padding: "0 20px",
            [theme.breakpoints.up("lg")]: {
              padding: "0 20px 0 60px",
            },
          })}
        >
          {headerActions?.map((action) =>
            action.children ? (
              <Box
                key={action.text}
                sx={{
                  p: "0 !important",
                  fontWeight: 400,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ActionMenuButton
                  action={action}
                  sx={(theme) => ({
                    width: 24,
                    color: theme.palette.primary.main,
                  })}
                >
                  {action.children.map((item) => (
                    <MenuItem
                      key={item.text}
                      onClick={item.onClick}
                      disabled={item.disabled}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </ActionMenuButton>
              </Box>
            ) : (
              <Box
                key={action.text}
                sx={{
                  padding: "0 !important",
                  fontWeight: 400,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ActionButton action={action}>{action.text}</ActionButton>
              </Box>
            )
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {headerControlAction.children ? (
              <MenuButton
                disabled={false}
                color="primary"
                text="Send"
                variant="contained"
              >
                {headerControlAction.children
                  ?.filter((a) => !a.hidden)
                  ?.map((action) => (
                    <MenuItem
                      key={action.text}
                      onClick={action.onClick}
                      disabled={action.disabled}
                    >
                      {action.text}
                    </MenuItem>
                  ))}
              </MenuButton>
            ) : (
              <Button
                variant="contained"
                disabled={headerControlAction.disabled}
                onClick={headerControlAction.onClick}
              >
                {headerControlAction.text}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignHeader;
