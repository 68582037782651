import { StylesOverridesProps } from "../../../types/stylesOverrides";

const root = ({ theme }: StylesOverridesProps) => ({
  backgroundColor: theme.palette.grey["0"],
});

const styleOverrides = {
  root,
};

export { styleOverrides };
