import React, { useRef, useEffect, useState } from "react";
import { Tooltip, Typography, useTheme } from "@mui/material";
import { hex2rgb } from "@tiny/utils/colors";
import { OverflowTypographyWithTooltipProps } from "./types";

const OverflowTypographyWithTooltip: React.FC<
  OverflowTypographyWithTooltipProps
  // eslint-disable-next-line react/prop-types
> = ({ text, value, variant, color }) => {
  const typographyRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    const typographyNode = typographyRef.current;
    if (typographyNode) {
      setIsOverflowing(
        typographyNode.offsetWidth < typographyNode.scrollWidth ||
          typographyNode.offsetHeight < typographyNode.scrollHeight
      );
    }
  }, [text]);

  return (
    <Tooltip
      title={isOverflowing ? text : ""}
      componentsProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              padding: 2,
              fontSize: theme.spacing(3),
              backgroundColor: hex2rgb(theme.palette.grey[100], 0.9),
            },
          },
        },
      }}
    >
      <Typography noWrap variant={variant} color={color} ref={typographyRef}>
        {value}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTypographyWithTooltip;
