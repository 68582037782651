import React from "react";
import { MenuList, Box } from "@mui/material";
import { mainMenu } from "../Sidebar/helpers";
import NavigationItem from "../NavigationItem";
import PlanInfo from "../PlanInfo";

// TODO: REFACTOR, make one component with the sidebar component
function MobileMenu({
  openMenu,
  closeMenu,
}: {
  openMenu: boolean;
  closeMenu: () => void;
}) {
  return (
    <Box
      sx={{
        height: "90vh",
        display: openMenu ? "flex" : "none",
        flexDirection: "column",
        background: "white",
        justifyContent: "space-between",
        textDecoration: "none",
      }}
    >
      <MenuList>
        {mainMenu.map((menuItem) => (
          <NavigationItem
            key={menuItem.name}
            to={menuItem.to}
            Icon={menuItem.Icon}
            IconActive={menuItem.IconActive}
            name={menuItem.name}
            mobileCloseMenu={closeMenu}
            SecondaryLabel={menuItem.SecondaryLabel}
          />
        ))}
      </MenuList>
      <PlanInfo />
    </Box>
  );
}

export default MobileMenu;
