import React from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import { lowercaseNoSpaces } from "@tiny/utils";
import { Info2Outline } from "@tiny/icons";
import InfoTooltip from "@/components/InfoTooltip";
import { MetricsProps } from "./types";
import MetricInfo from "./MetricInfo";

function MetricCard({ metric, isLast, isFirst, isMiddle, sx }: MetricsProps) {
  return (
    <Card
      sx={[
        {
          borderRadius: 2,
          boxShadow: "none",
          position: "relative",
          p: 5,
        },
        !!isFirst && {
          borderRadius: "8px 8px 0 0 ",
        },
        !!isLast && {
          borderRadius: "0 0 8px 8px",
        },
        !!isMiddle && {
          borderRadius: 0,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={`metric-card ${lowercaseNoSpaces(metric.title)}`}
    >
      <CardContent
        className="metric-card__content"
        sx={{
          p: 0,
          "&:last-child": {
            pb: 5,
          },
        }}
      >
        <MetricInfo data={metric} />
      </CardContent>
      <InfoTooltip message={metric.infoMessage ?? ""} variant="white">
        <IconButton
          size="small"
          sx={(theme) => ({
            p: 0,
            position: "absolute",
            top: theme.spacing(2),
            right: theme.spacing(2),
            color: theme.palette.grey[60],
          })}
        >
          <Info2Outline sx={{ fontSize: 16 }} />
        </IconButton>
      </InfoTooltip>
    </Card>
  );
}

export default MetricCard;
