import { Button, Typography } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import WelcomeBadge from "../../../assets/svgs/welcomeBadge.svg";

function SuccessMessageContent({
  closeDialogHandler,
  successMessage,
}: {
  closeDialogHandler: () => void;
  successMessage: string;
}) {
  return (
    <>
      <Typography variant="h2">New content generated</Typography>
      <img alt="successful content generation badge" src={WelcomeBadge} />
      <DialogContentText
        id="contentGenerationSuccess"
        sx={{ textAlign: "center" }}
        variant="p2"
        color="grey.100"
      >
        {successMessage}
      </DialogContentText>
      <Button variant="contained" onClick={closeDialogHandler}>
        Ok
      </Button>
    </>
  );
}

export default SuccessMessageContent;
