import React, { useEffect, useState } from "react";
import { ChevronBigDown, InfoOutline } from "@tiny/icons";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { useLoaderData } from "react-router-dom";
import {
  LayoutCategory,
  TemplateLayout,
} from "@/types/api/accountTemplateLayouts";
import EmailTemplateLayout from "@/components/EmailTemplateLayout";
import { SubmissionErrorProps } from "@/routes/BrandVoice/types";
import ColorPicker from "@/components/ColorPicker";
import { ColorPreferences } from "@tiny/mui/ColorInput/types";
import { computeErrorText } from "@/components/ContentSetupStrategy/computeErrorText";
import CharactersCounter from "@/components/ContentSetupStrategy/CharactersCounter";
import InfoTooltip from "../InfoTooltip";
import type { AiTuningRootLoader } from "./types";

interface EditableFormContentProps {
  accountDetailsRef: React.MutableRefObject<string>;
  selectedToneRef: React.MutableRefObject<string>;
  selectedLanguageRef: React.MutableRefObject<string>;
  selectedLayoutRef: React.MutableRefObject<LayoutCategory>;
  selectedPrimaryColorRef: React.MutableRefObject<string>;
  selectedSecondaryColorRef: React.MutableRefObject<string>;
  errors: SubmissionErrorProps | undefined;
}

function EditableFormContent({
  accountDetailsRef,
  selectedToneRef,
  selectedLanguageRef,
  selectedLayoutRef,
  selectedPrimaryColorRef,
  selectedSecondaryColorRef,
  errors,
}: EditableFormContentProps) {
  const {
    accountDetails: {
      tones,
      accountDetails: savedAccountDetails = "",
      selectedToneId,
      layoutCategory,
      colors,
      languages,
      language,
    },
    layoutsDict,
  } = useLoaderData() as AiTuningRootLoader;

  const [selectedTone, setSelectedTone] = useState(
    selectedToneId ?? tones[0].id
  );

  const [selectedLanguage, setSelectedLanguage] = useState(
    language?.id ?? languages[0].id
  );

  const [selectedLayout, setSelectedLayout] = useState(
    layoutCategory ?? layoutsDict.layouts[0].category
  );

  selectedLayoutRef.current = selectedLayout;

  const [isEmptyDetails, setIsEmptyDetails] = useState(
    savedAccountDetails.length === 0
  );

  const [accountDetailsInputValue, setAccountDetailsInputValue] = useState("");

  const handleToneChange = (event: SelectChangeEvent<string>) => {
    setSelectedTone(event.target.value);
  };
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setSelectedLanguage(event.target.value);
  };

  const [selectedColors, setSelectedColors] = React.useState<ColorPreferences>({
    primaryColor: colors.primaryColor,
    secondaryColor: colors.secondaryColor,
  });

  const [textCount, setText] = useState(savedAccountDetails);

  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(textCount.length);
  }, [textCount]);

  selectedPrimaryColorRef.current = selectedColors.primaryColor;
  selectedSecondaryColorRef.current = selectedColors.secondaryColor;

  return (
    <Box
      id="fields"
      display="flex"
      flexDirection="column"
      sx={{
        bgcolor: "grey.0",
        borderRadius: 2,
        p: {
          xs: 4,
          sm: 6,
        },
        mx: {
          xs: -4,
          sm: 0,
        },
      }}
    >
      <Box mb={6} display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginBottom: 3,
            marginRight: 3,
          }}
        >
          <InputLabel htmlFor="campaign-layout" sx={{ mb: 1.5 }}>
            <Typography
              variant="label2"
              sx={(theme) => ({
                color: theme.palette.primary.dark,
              })}
            >
              1&nbsp;
            </Typography>
            <Typography
              variant="label2"
              sx={(theme) => ({
                color: theme.palette.grey[100],
              })}
            >
              Choose your layout
            </Typography>
            <InfoTooltip
              message="The selected layout will be applied to all your email templates (campaigns/automations) and forms."
              variant="black"
              noInfoIcon
            >
              <IconButton sx={{ p: 0, ml: 1 }}>
                <InfoOutline
                  sx={(theme) => ({
                    fontSize: theme.typography.p1.fontSize,
                  })}
                />
              </IconButton>
            </InfoTooltip>
          </InputLabel>

          <ColorPicker
            colors={selectedColors}
            onColorSave={setSelectedColors}
            selectedPrimaryColorRef={selectedPrimaryColorRef}
            selectedSecondaryColorRef={selectedSecondaryColorRef}
          />
        </Box>

        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Grid
          container
          spacing={{ xs: 3, sm: 5, md: 5 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
          flexWrap={{ xs: "nowrap", sm: "wrap", md: "wrap" }}
          sx={{
            overflow: "auto",
            width: 343,
            scrollSnapType: "x mandatory",
            "& > *": {
              scrollSnapAlign: "center",
            },
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          {layoutsDict.layouts.map((layoutProps: TemplateLayout) => (
            <Grid
              item
              id={`layout-${layoutProps.id}`}
              xs={9}
              sm={4}
              md={4}
              key={layoutProps.id}
              onClick={() => {
                selectedLayoutRef.current = layoutProps.category;
                setSelectedLayout(layoutProps.category);
              }}
            >
              <EmailTemplateLayout
                {...layoutProps}
                isSelected={selectedLayout === layoutProps.category}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <InputLabel htmlFor="tone-of-voice" sx={{ mb: 1.5 }}>
        <Typography
          variant="label2"
          sx={(theme) => ({
            color: theme.palette.primary.dark,
          })}
        >
          2&nbsp;
        </Typography>
        <Typography
          variant="label2"
          sx={(theme) => ({
            color: theme.palette.grey[100],
          })}
        >
          Setup your Tone of voice&nbsp;
        </Typography>
        <Typography variant="label2R">(required)</Typography>
        <InfoTooltip
          message="Tone of voice tells tinyAlbert how your brand should sound to your audience."
          variant="black"
          noInfoIcon
        >
          <IconButton sx={{ p: 0, ml: 1 }}>
            <InfoOutline
              sx={(theme) => ({
                fontSize: theme.typography.p1.fontSize,
              })}
            />
          </IconButton>
        </InfoTooltip>
      </InputLabel>
      <Select
        value={selectedTone}
        name="toneId"
        IconComponent={ChevronBigDown}
        size="small"
        variant="outlined"
        id="tone-of-voice"
        onChange={handleToneChange}
        inputRef={selectedToneRef}
        sx={(theme: Theme) => ({
          mb: 1.5,
          "& .MuiInputBase-input": {
            fontSize: theme.spacing(4),
          },
        })}
      >
        {tones.map((tone) => (
          <MenuItem key={tone.id} value={tone.id}>
            {tone.name}
          </MenuItem>
        ))}
      </Select>
      <InputLabel htmlFor="tone-of-voice" sx={{ mb: 1.5, mt: 4 }}>
        <Typography
          variant="label2"
          sx={(theme) => ({
            color: theme.palette.primary.dark,
          })}
        >
          3&nbsp;
        </Typography>
        <Typography
          variant="label2"
          sx={(theme) => ({
            color: theme.palette.grey[100],
          })}
        >
          Setup your Content Language&nbsp;
        </Typography>
        <Typography variant="label2R">(required)</Typography>
        <InfoTooltip
          message="Set the language in which tinyAlbert generates content to effectively engage and connect with your audience"
          variant="black"
          noInfoIcon
        >
          <IconButton sx={{ p: 0, ml: 1 }}>
            <InfoOutline
              sx={(theme) => ({
                fontSize: theme.typography.p1.fontSize,
              })}
            />
          </IconButton>
        </InfoTooltip>
      </InputLabel>
      <Select
        value={selectedLanguage}
        name="language"
        IconComponent={ChevronBigDown}
        size="small"
        variant="outlined"
        id="content-language"
        onChange={handleLanguageChange}
        sx={(theme) => ({
          mb: 1.5,
          "& .MuiInputBase-input": {
            fontSize: theme.spacing(4),
          },
        })}
        inputRef={selectedLanguageRef}
      >
        {languages.map((lang) => (
          <MenuItem key={lang.id} value={lang.id}>
            {lang.name}
          </MenuItem>
        ))}
      </Select>
      <Box mt={4} display="flex" flexDirection="column">
        <InputLabel htmlFor="account-details" sx={{ mb: 1.5 }}>
          <Typography
            variant="label2"
            sx={(theme) => ({
              color: theme.palette.primary.dark,
            })}
          >
            4&nbsp;
          </Typography>
          <Typography
            variant="label2"
            sx={(theme) => ({
              color: theme.palette.grey[100],
            })}
          >
            Tell us about your Company/Product details&nbsp;
          </Typography>
          <Typography variant="label2R">(required)</Typography>
          <InfoTooltip
            message="Tell us what your product or company does. Example: Barkbox is a monthly subscription of the best treats, toys, and goodies for your pet delivered to your doorstep."
            variant="black"
            noInfoIcon
          >
            <IconButton sx={{ p: 0, ml: 1 }}>
              <InfoOutline
                sx={(theme) => ({
                  fontSize: theme.typography.p1.fontSize,
                })}
              />
            </IconButton>
          </InfoTooltip>
        </InputLabel>
        <TextField
          id="account-details"
          placeholder="tinyAlbert AI is on a mission to make email marketing an exciting and efficient part of running a small business. With powerful, easy-to-use artificial intelligence tools, Shopify store owners can automate their email marketing processes--saving time that would otherwise be spent on tedious tasks. Our unique value proposition helps businesses make sense of their data and nurture meaningful customer relationships. By combining cutting-edge technology with the knowledge of our team scattered across the globe, tinyAlbert AI is transforming how people engage with customers over email. We're redefining success for small businesses everywhere, one automated task at a time!"
          defaultValue={savedAccountDetails}
          inputRef={accountDetailsRef}
          multiline
          onChange={(e) => {
            const text = e.target.value.trim();
            setText(e.target.value);
            setAccountDetailsInputValue(text);
            if (text.length === 0 && !isEmptyDetails) {
              return setIsEmptyDetails(true);
            }
            if (text.length > 0 && isEmptyDetails) {
              return setIsEmptyDetails(false);
            }
          }}
          minRows={2}
          maxRows={10}
          name="accountDetails"
          error={
            (!!errors?.accountDetails && !accountDetailsInputValue.length) ||
            accountDetailsInputValue.length >= 1000 ||
            isEmptyDetails
          }
          helperText={
            computeErrorText(
              accountDetailsInputValue.length,
              !!errors?.accountDetails,
              isEmptyDetails
            ) || CharactersCounter(charCount)
          }
          sx={(theme) => ({
            "& .MuiOutlinedInput-input": {
              fontSize: theme.spacing(4),
            },
          })}
        />
      </Box>
    </Box>
  );
}

export default EditableFormContent;
