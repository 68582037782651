import React from "react";
import { Breakpoint, Container, SxProps, Theme } from "@mui/material";

function CenteredContainer({
  children,
  fixed,
  maxWidth,
  sx = [],
}: {
  fixed?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}) {
  return (
    <Container
      className="centered-container"
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          paddingX: {
            xs: 4,
            sm: 10,
            md: 20,
          },
          paddingY: {
            xs: 4,
            sm: 20,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      disableGutters
      fixed={fixed}
      maxWidth={maxWidth}
    >
      {children}
    </Container>
  );
}

export default CenteredContainer;
