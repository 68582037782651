import * as React from "react";

function useDialog() {
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const showDialog = React.useCallback(() => setDialogVisible(true), []);
  const hideDialog = React.useCallback(() => setDialogVisible(false), []);

  return {
    dialogVisible,
    showDialog,
    hideDialog,
  };
}

export default useDialog;
