import { EndpointConfig } from "../types";

export function getEinsteinEndpointConfig(path: string): EndpointConfig {
  const segments = path.substring(1).split("/");
  const microservice = segments?.[0] ?? "";
  let endpointConfig: EndpointConfig = { requireAuthorization: true, path };
  switch (microservice) {
    case "authorization-server":
      switch (segments?.[1] ?? "") {
        case "dev":
        case "oauth":
          endpointConfig = { ...endpointConfig, requireAuthorization: false };
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return endpointConfig;
}
