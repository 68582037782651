import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <g xmlns="http://www.w3.org/2000/svg">
      <title>Layer 1</title>
      <path
        stroke="null"
        id="svg_1"
        fill="#6F737D"
        d="m12.65032,20.24051c-0.44979,0 -0.84335,-0.16867 -1.1807,-0.50601c-0.33734,-0.33734 -0.50601,-0.73085 -0.50601,-1.1807l0,-16.86709c0,-0.44979 0.16867,-0.84335 0.50601,-1.1807c0.33734,-0.33734 0.7309,-0.50601 1.1807,-0.50601l5.90348,0c0.44985,0 0.84335,0.16867 1.1807,0.50601c0.33734,0.33734 0.50601,0.7309 0.50601,1.1807l0,16.86709c0,0.44985 -0.16867,0.84335 -0.50601,1.1807c-0.33734,0.33734 -0.73085,0.50601 -1.1807,0.50601l-5.90348,0zm-10.96361,0c-0.44979,0 -0.84335,-0.16867 -1.1807,-0.50601c-0.33734,-0.33734 -0.50601,-0.73085 -0.50601,-1.1807l0,-16.86709c0,-0.44979 0.16867,-0.84335 0.50601,-1.1807c0.33734,-0.33734 0.7309,-0.50601 1.1807,-0.50601l5.90348,0c0.44979,0 0.84335,0.16867 1.1807,0.50601c0.33734,0.33734 0.50601,0.7309 0.50601,1.1807l0,16.86709c0,0.44985 -0.16867,0.84335 -0.50601,1.1807c-0.33734,0.33734 -0.7309,0.50601 -1.1807,0.50601l-5.90348,0zm0,-18.5538l0,16.86709l5.90348,0l0,-16.86709l-5.90348,0z"
      />
    </g>
    <title>Layer 1</title>
    <path
      stroke="null"
      id="svg_1"
      d="m12.65032,20.24051c-0.44979,0 -0.84335,-0.16867 -1.1807,-0.50601c-0.33734,-0.33734 -0.50601,-0.73085 -0.50601,-1.1807l0,-16.86709c0,-0.44979 0.16867,-0.84335 0.50601,-1.1807c0.33734,-0.33734 0.7309,-0.50601 1.1807,-0.50601l5.90348,0c0.44985,0 0.84335,0.16867 1.1807,0.50601c0.33734,0.33734 0.50601,0.7309 0.50601,1.1807l0,16.86709c0,0.44985 -0.16867,0.84335 -0.50601,1.1807c-0.33734,0.33734 -0.73085,0.50601 -1.1807,0.50601l-5.90348,0zm-10.96361,0c-0.44979,0 -0.84335,-0.16867 -1.1807,-0.50601c-0.33734,-0.33734 -0.50601,-0.73085 -0.50601,-1.1807l0,-16.86709c0,-0.44979 0.16867,-0.84335 0.50601,-1.1807c0.33734,-0.33734 0.7309,-0.50601 1.1807,-0.50601l5.90348,0c0.44979,0 0.84335,0.16867 1.1807,0.50601c0.33734,0.33734 0.50601,0.7309 0.50601,1.1807l0,16.86709c0,0.44985 -0.16867,0.84335 -0.50601,1.1807c-0.33734,0.33734 -0.7309,0.50601 -1.1807,0.50601l-5.90348,0zm0,-18.5538l0,16.86709l5.90348,0l0,-16.86709l-5.90348,0z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "TableHeader"
);
