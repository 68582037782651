import React from "react";
import { Button } from "@mui/material";
import { useDialogInvokers } from "@tiny/lib";
import { RegenerateAllConfirmationDialog } from "@/dialogs/RegenerateAllConfirmationDialog";
import { FormMethod } from "react-router-dom";
import { GenerativeAi } from "@tiny/icons";

export interface RegenerateAllProps {
  action: string;
  confirmationMessage: string;
  successMessage: string;
  method: FormMethod;
  id?: string;
}

function RegenerateAll(props: RegenerateAllProps) {
  const { action, confirmationMessage, successMessage, method, id } = props;
  const { requestInfoDialog } = useDialogInvokers();

  const regenerateAll = React.useCallback(
    () =>
      requestInfoDialog(
        <RegenerateAllConfirmationDialog
          method={method}
          action={action}
          description={confirmationMessage}
          successMessage={successMessage}
        />
      ),
    [action, confirmationMessage, method, requestInfoDialog, successMessage]
  );

  return (
    <Button
      variant="outlined"
      onClick={regenerateAll}
      id={id}
      startIcon={<GenerativeAi sx={{ marginRight: -1 }} />}
      sx={{ py: { xl: 2, sm: 1.5 } }}
    >
      Regenerate
    </Button>
  );
}

export default RegenerateAll;
