import React from "react";

export type AccountAction = "EXPORT" | "SCHEDULE_CAMPAIGN";

export function useAccountPermissions(action: AccountAction = "EXPORT") {
  const statusesToAnalyze = React.useMemo(() => {
    if (action === "SCHEDULE_CAMPAIGN") {
      return ["ACTIVE", "LATE"];
    } else {
      return ["ACTIVE", "LATE", "FROZEN"];
    }
  }, [action]);

  const actionDisabled = React.useMemo(
    () => !statusesToAnalyze.includes(""),
    [statusesToAnalyze]
  );

  return {
    actionDisabled,
  };
}
