import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M19 10H13V21H4.99916C3.89459 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V13H19V10ZM11 10H5V14H11V10ZM11 19V16H5V19H11ZM13 5V8H19V5H13ZM11 5H5V8H11V5Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path d="M16 18V16H24V18H16Z" xmlns="http://www.w3.org/2000/svg" />
  </g>,
  "TableDelete"
);
