import React from "react";
import { useSchedulePanel } from "./SchedulePanel.hooks";

const SchedulePanelContext = React.createContext<ReturnType<
  typeof useSchedulePanel
> | null>(null);

export function useSchedulePanelView() {
  const contextValue = React.useContext(SchedulePanelContext);
  if (!contextValue) {
    throw Error("Should be used inside the SchedulePanelProvider");
  }
  return contextValue;
}

export const SchedulePanelProvider = SchedulePanelContext.Provider;
