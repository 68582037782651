import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M8.41421 0.414185L7 1.8284L8.1716 3L4 3C3.447 3 3 3.44215 3 3.98915V6.54611C3 7.07135 3.211 7.57483 3.586 7.94576L9.70711 14.0005C9.89464 14.186 10 14.4376 10 14.6999L10 21.0096C10 21.7449 10.7823 22.2231 11.4472 21.8943L13.4472 20.9051C13.786 20.7376 14 20.3951 14 20.0204V14.6999C14 14.4376 14.1054 14.186 14.2929 14.0005L16.7458 11.5742L19.7279 14.5563L21.1421 13.1421L8.41421 0.414185Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M20.414 7.94576L19.5896 8.7612L13.8284 3L20 3C20.553 3 21 3.44215 21 3.98916V6.54611C21 7.07135 20.789 7.57483 20.414 7.94576Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "FilterOff"
);
