import React from "react";
import {
  useGetActivityEmailFooterQuery,
  useGetCampaignEmailFooterByCampaignIdQuery,
} from "../../app/useGeneratedApi";
import { useMergeAttributesTemplate } from "../../models/template/template.hooks";
import {
  appendFooter,
  mergeAttributes,
} from "../../models/template/templateUtils";
import { TemplatePreviewDialogProps } from "./TemplatePreviewDialog.types";

export function useTemplatePreviewDialog(props: TemplatePreviewDialogProps) {
  const {
    campaignId,
    renderWithEjs,
    templateAttributes,
    templateData,
    templateHtml: templateHtmlProp,
    templateCss: templateCssProp,
  } = props;

  const [activeTab, setActiveTab] = React.useState(0);

  const activityEmailFooterQuery = useGetActivityEmailFooterQuery({
    enabled: !campaignId,
  });

  const campaignEmailFooterQuery = useGetCampaignEmailFooterByCampaignIdQuery(
    campaignId!,
    { enabled: !!campaignId }
  );

  const { templateHtml, templateCss: css } = useMergeAttributesTemplate(
    templateHtmlProp,
    templateCssProp,
    templateData,
    renderWithEjs
  );

  const handleExited = () => {
    setActiveTab(0);
  };

  const html = React.useMemo(() => {
    const footer = campaignId
      ? campaignEmailFooterQuery.data?.html
      : activityEmailFooterQuery.data?.html;

    const htmlWithPlaceholders = appendFooter(templateHtml, footer);
    return mergeAttributes(htmlWithPlaceholders, templateAttributes);
  }, [
    activityEmailFooterQuery.data?.html,
    campaignEmailFooterQuery.data?.html,
    campaignId,
    templateAttributes,
    templateHtml,
  ]);

  return {
    activeTab,
    setActiveTab,
    handleExited,
    html,
    css,
  };
}
