import React from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Toolbar,
  useTheme,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { CloseBig, Hamburger } from "@tiny/icons";
import tinyAlbertLogo from "../../assets/logos/tinyalbert-trademark-normal.svg";
import MobileMenu from "./MobileMenu";

function MobileNavigation({
  openMenu,
  toggleMenu,
}: {
  openMenu: boolean;
  toggleMenu: () => void;
}) {
  const theme = useTheme();
  return (
    <Box sx={{ zIndex: 3, position: "sticky", right: 0, top: 0, left: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          p: 5,
          display: "flex",
          justifyContent: "space-between",
          background: "white",
        }}
      >
        <Link to="dashboard" style={{ display: "inline-flex" }}>
          <img src={tinyAlbertLogo} alt="Tiny Albert Logo" />
        </Link>
        <Box display="flex" gap={5}>
          <NavLink
            to="account-settings"
            style={({ isActive }) => ({
              p: 0,
              border: `4px solid ${
                isActive
                  ? theme.palette.primary.lighter
                  : theme.palette.common.white
              }`,
              transition: "border 0.6s ease",
              borderRadius: 4,
            })}
          >
            {({ isActive }) => (
              <Avatar variant={isActive ? "square" : "rounded"} />
            )}
          </NavLink>
          {openMenu ? (
            <IconButton sx={{ p: 0 }} onClick={toggleMenu}>
              <CloseBig />
            </IconButton>
          ) : (
            <IconButton sx={{ p: 0 }} onClick={toggleMenu}>
              <Hamburger />
            </IconButton>
          )}
        </Box>
      </Toolbar>
      <Divider />
      <MobileMenu openMenu={openMenu} closeMenu={toggleMenu} />
    </Box>
  );
}

export default MobileNavigation;
