import { PaletteColor } from "@mui/material/styles";
import {
  TinyColorShadeCodes,
  TinyColorShadeNames,
  TinyColorSpec,
  TinyStyleGuideColors,
} from "../types/palette.types";

const asMuiColor = (spec: TinyColorSpec): PaletteColor => {
  const shadeMap: Record<keyof TinyColorShadeNames, keyof TinyColorShadeCodes> =
    {
      lighter: 50,
      light: 75,
      main: 100,
      dark: 125,
    };

  const result = Object.keys(shadeMap).reduce((acc, shadeName) => {
    const shadeCode = shadeMap[shadeName as keyof TinyColorShadeNames];
    const shadeEntry = {
      [shadeName as keyof TinyColorShadeNames]: spec[shadeCode],
    };
    return { ...acc, ...shadeEntry };
  }, spec);
  return result as unknown as PaletteColor;
};

const createPaletteColor = (colorsSpec: TinyStyleGuideColors) =>
  Object.entries(colorsSpec).reduce((acc, nextEntry) => {
    const [color, spec] = nextEntry;
    const muiColor = asMuiColor(spec);
    return { ...acc, [color]: muiColor };
  }, {});

export { asMuiColor, createPaletteColor };
