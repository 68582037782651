import * as React from "react";
import { Box, Typography } from "@mui/material";
import ActionMenu from "@/components/ActionMenu";
import useAutomationActionMenu from "@/components/Automation/useAutomationActionMenu";
import { AutomationHeaderProps } from "./types";

function AutomationHeader(props: AutomationHeaderProps) {
  const {
    active = false,
    name,
    id,
    infoDetails,
    comingSoon,
    editable,
    image,
  } = props;
  const { automationMenu } = useAutomationActionMenu(
    id,
    infoDetails,
    name,
    active,
    editable,
    comingSoon
  );

  return (
    <Box display="flex" width="100%" className="automation-name">
      <Box display="flex" flexDirection="column" gap={4}>
        {image && (
          <img
            src={image}
            className="metric-image"
            style={{
              mixBlendMode: active ? "unset" : "luminosity",
            }}
            alt={name}
            height={80}
            width={80}
          />
        )}
        <Typography
          variant="h4"
          color={active ? "grey.100" : "grey.80"}
          sx={{ flex: 1 }}
        >
          {name}
        </Typography>
      </Box>
      <ActionMenu menuActions={automationMenu} />
    </Box>
  );
}

export default AutomationHeader;
