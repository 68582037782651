import React from "react";
import { Box } from "@mui/material";
import {
  Outlet,
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { GetReportsOverviewResponse } from "@/types/api/reportsOverview";
import PageTitle from "@/components/PageTitle";
import PieChartCard from "@/components/Dashboard/PieChartCard/PieChartCard";
import StatisticsCard from "@/components/Dashboard/StatisticsCard/StatisticsCard";
import CampaignsCard from "@/components/Dashboard/CampaignsCard/CampaignsCard";
import { RootLoader } from "@/types/RootLoader";
import { dashboardMetricMapper } from "./dashboardMetric.mapper";
import { CampaignsProvider } from "../Campaigns/Context";

function DashboardPage() {
  const data = useLoaderData() as GetReportsOverviewResponse;
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;
  const mapMetrics = dashboardMetricMapper(data, accountInfo);

  const navigate = useNavigate();

  return (
    <CampaignsProvider>
      <PageTitle title="Dashboard" canRefresh />
      <Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={5}
          justifyContent="space-between"
        >
          <PieChartCard
            tooltipMessage="This info shows how tinyAlbert impacts your revenue, how much revenue were driven by the platform."
            metrics={mapMetrics.totalRevenue}
          />
          <PieChartCard
            tooltipMessage="This info shows how tinyAlbert impacts your orders, how many orders were driven by the platform."
            metrics={mapMetrics.totalOrders}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        mt={{ xs: 5, sm: 6 }}
        width="100%"
        gap={5}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Box flex={1}>
          <StatisticsCard
            metrics={mapMetrics.automations}
            seeReportsHandler={() =>
              navigate(
                "/main/dashboard/generated-revenue/sources?filter=AUTOMATION"
              )
            }
          />
        </Box>
      </Box>

      <Box
        display="flex"
        mt={{ xs: 5, sm: 6 }}
        width="100%"
        gap={5}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Box flex={1}>
          <StatisticsCard
            metrics={mapMetrics.campaign}
            seeReportsHandler={() =>
              navigate(
                "/main/dashboard/generated-revenue/sources?filter=CAMPAIGN"
              )
            }
          />
        </Box>
      </Box>

      <Box mt={{ xs: 5, sm: 6 }}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={5}
          justifyContent="space-between"
        >
          <StatisticsCard metrics={mapMetrics.audience} />
        </Box>
      </Box>

      {!data?.campaigns?.campaigns?.empty && (
        <Box mt={{ xs: 5, sm: 6 }}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={5}
            justifyContent="space-between"
          >
            <CampaignsCard campaigns={data.campaigns} />
          </Box>
        </Box>
      )}
      <Outlet />
    </CampaignsProvider>
  );
}

export default DashboardPage;
export { dashboardLoader } from "./loader";
