const root = () => ({
  "&> .MuiTableRow-root:nth-of-type(2n+1)": {
    backgroundColor: "transparent",
  },
});

const styleOverrides = {
  root,
};

export { styleOverrides };
