import { TypographyOptions } from "@mui/material/styles/createTypography";
import { Theme } from "@mui/material";

const FontWeight = {
  regular: 400,
  semibold: 600,
  bold: 700,
} as const;

const typography = {
  initial: {
    fontFamily: ["Archivo", "sans-serif"].join(","),
    fontWeightBold: FontWeight.bold,
    fontWeightMedium: FontWeight.semibold,
    fontWeightRegular: FontWeight.regular,
  },
  main: ({ breakpoints, typography: { pxToRem } }: Theme) =>
    ({
      // TODO: Change this custom typography when Leo updates the foundation
      priceH0: {
        fontWeight: FontWeight.semibold,
        fontSize: pxToRem(48),
        lineHeight: 48 / 48,
      },
      priceH0R: {
        fontWeight: 100,
        fontSize: pxToRem(48),
        lineHeight: 48 / 48,
      },
      h0: {
        fontWeight: FontWeight.semibold,
        fontSize: pxToRem(40),
        lineHeight: 40 / 40,
      },
      h1: {
        fontWeight: FontWeight.semibold,
        fontSize: pxToRem(28),
        lineHeight: 32 / 28,
        [breakpoints.up("md")]: {
          fontSize: pxToRem(32),
          lineHeight: 36 / 32,
        },
      },
      h2: {
        fontSize: pxToRem(24),
        fontWeight: FontWeight.semibold,
        lineHeight: 28 / 24,
      },
      h3: {
        fontSize: pxToRem(20),
        fontWeight: FontWeight.semibold,
        lineHeight: 20 / 20,
      },
      h4: {
        fontSize: pxToRem(16),
        fontWeight: FontWeight.semibold,
        lineHeight: 20 / 16,
      },
      h4R: {
        fontSize: pxToRem(16),
        fontWeight: FontWeight.regular,
        lineHeight: 20 / 16,
      },
      p1: {
        fontSize: pxToRem(16),
        fontWeight: FontWeight.regular,
        lineHeight: 20 / 16,
      },
      p2: {
        fontSize: pxToRem(14),
        fontWeight: FontWeight.regular,
        lineHeight: 18 / 14,
      },
      p3: {
        fontSize: pxToRem(12),
        fontWeight: FontWeight.regular,
        lineHeight: 16 / 12,
      },
      label1: {
        fontSize: pxToRem(14),
        fontWeight: FontWeight.bold,
        lineHeight: 14 / 14,
      },
      label1R: {
        fontSize: pxToRem(14),
        fontWeight: FontWeight.regular,
        lineHeight: 14 / 14,
      },
      label2: {
        fontSize: pxToRem(12),
        fontWeight: FontWeight.bold,
        lineHeight: 12 / 12,
      },
      label2R: {
        fontSize: pxToRem(12),
        fontWeight: FontWeight.regular,
        lineHeight: 12 / 12,
      },
      label3: {
        fontSize: pxToRem(10),
        fontWeight: FontWeight.bold,
        lineHeight: 10 / 10,
      },
      label3R: {
        fontSize: pxToRem(10),
        fontWeight: FontWeight.regular,
        lineHeight: 10 / 10,
      },
      h5: undefined,
      h6: undefined,
      subtitle1: undefined,
      subtitle2: undefined,
      body1: undefined,
      body2: undefined,
    } as TypographyOptions),
};

export { typography, FontWeight };
