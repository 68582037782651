import * as React from "react";
// TODO change import to reduce bundle
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import "@tiny/theme/types";

export interface MessageDialogProps {
  open: boolean;
  onClose: () => void;
  message?: string;
}

function MessageDialog(props: MessageDialogProps) {
  const { open, onClose, message } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography variant="p1" color="grey.60">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog;
