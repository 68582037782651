import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import { GetCampaignResponse } from "@/types/api/campaigns";
import { GetSupportedLanguagesResponse } from "@/types/api/contentDetails";
import { getStartAndEndOfMonth, getUTCDate } from "@tiny/utils/dateUtils";
import { addDays, endOfDay, startOfDay } from "date-fns";

export const defaultApiQueryParamsList = {
  page: "0",
  size: "6",
  sort: "startTime,asc",
  "scheduled-from": getUTCDate(startOfDay(new Date())),
  "scheduled-to": getUTCDate(endOfDay(addDays(new Date(), 60))),
};

export const defaultApiQueryParamsGrid = {
  page: "0",
  size: "100",
  sort: "startTime,asc",
  "scheduled-from": getStartAndEndOfMonth(new Date(), false).from,
  "scheduled-to": getStartAndEndOfMonth(new Date(), true).to,
};

interface CalendarLoaderProps {
  campaigns: GetCampaignResponse;
  languages: GetSupportedLanguagesResponse;
}

const campaignsCalendarLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const requestParams = Object.fromEntries(url?.searchParams);
  const apiPage = (parseInt(requestParams.page ?? 1, 10) - 1).toString();

  const paths = url?.pathname.split("/");
  const path = paths[paths.length - 1];
  let defaultApiQueryParams = {};
  if (path === "list") {
    defaultApiQueryParams = defaultApiQueryParamsList;
  } else if (path === "calendar") {
    defaultApiQueryParams = defaultApiQueryParamsGrid;
  }

  const apiParams = {
    ...defaultApiQueryParams,
    ...requestParams,
    page: apiPage,
  };
  const apiQueryString = new URLSearchParams(apiParams).toString();

  const campaignsPromise = fetchApi<GetCampaignResponse>(
    `/campaignservice/campaign?${apiQueryString}`,
    { signal: request.signal }
  );

  const languages = fetchApi<GetSupportedLanguagesResponse>(
    "/accountservice/account/content/supported-languages",
    { signal: request.signal }
  );

  return defer({
    campaigns: await campaignsPromise,
    languages: { languages: await languages },
  });
};

export { campaignsCalendarLoader };
export type { CalendarLoaderProps };
