import React from "react";
import { Box, Button, Divider } from "@mui/material";
import { ShortRight } from "@tiny/icons";
import TemplateSection from "../../TemplateSection";
import { FilteredSectionProps } from "./FilteredSection.types";
import useBreakpoints from "../../../../hooks/useBreakpoints";

function FilteredSection(props: FilteredSectionProps) {
  const {
    title,
    total,
    hasMore,
    templates = [],
    saved,
    renderWithEjs,
    type,
    fetchMore,
    isSelected,
    toggleSelect,
  } = props;

  const { mdUp } = useBreakpoints();

  return (
    <Box>
      <Box ml={mdUp ? 6 : 0} mr={mdUp ? 6 : 0}>
        <Divider />
      </Box>

      <Box p={6}>
        <TemplateSection
          title={title!}
          total={total || 0}
          type={type}
          saved={saved}
          templates={templates}
          refetch={false}
          selectable={saved}
          renderWithEjs={renderWithEjs}
          favoritable
          isSelected={isSelected}
          toggleSelect={toggleSelect}
        />
        {hasMore && (
          <Button
            variant="text"
            endIcon={<ShortRight />}
            onClick={() => fetchMore?.()}
          >
            Load more
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default FilteredSection;
