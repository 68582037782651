import { useDialogInvokers } from "@tiny/lib";
import { Box, Button, Typography } from "@mui/material";

function useConfirmationDialog() {
  const { requestMediumDialog, exitDialog } = useDialogInvokers();
  const showConfirmationDialog = (
    description: string,
    title: string,
    ctaText: string,
    onConfirmHandler: () => void,
    showCancel = true
  ) => {
    requestMediumDialog({
      titleProps: { children: title, variant: "h3" },
      contentProps: {
        sx: { pb: 4 },
        children: (
          <Typography variant="p1" mt={4}>
            {description}
          </Typography>
        ),
      },
      actionProps: {
        children: (
          <Box alignItems="flex-end" sx={{ display: "flex", columnGap: 4 }}>
            {showCancel && (
              <Button
                disableElevation
                onClick={() => exitDialog()}
                variant="outlined"
              >
                Cancel
              </Button>
            )}
            <Button
              disableElevation
              onClick={() => {
                onConfirmHandler();
                exitDialog();
              }}
              variant="contained"
            >
              {ctaText}
            </Button>
          </Box>
        ),
      },
    });
  };

  return { showConfirmationDialog };
}

export { useConfirmationDialog };
