import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import { defaultApiQueryParams } from "@/routes/Campaigns/loader";
import { activityTypeFilter } from "@/hooks/usePageFilter";
import { GetGeneratedSourcesResponse } from "@/types/api/generatedRevenueSources";

export const sourcesTableRevenueLoader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const requestParams = Object.fromEntries(url.searchParams);
  const apiPage = (parseInt(requestParams.page ?? 1, 10) - 1).toString();
  const apiParams = {
    ...defaultApiQueryParams,
    ...requestParams,
    page: apiPage,
  };
  const filterValue = requestParams.filter ?? "ALL_TYPES";
  const { activityType } = activityTypeFilter(filterValue);

  const apiQueryString = new URLSearchParams(apiParams).toString();

  const generatedSources = fetchApi<GetGeneratedSourcesResponse>(
    `/reportservice/activity-order/find/filter?${apiQueryString}`,
    {
      body: JSON.stringify({
        from: null,
        to: null,
        activityTypes: activityType,
        searchTextName: requestParams.searchTextName,
      }),
      method: "PUT",
    }
  );

  return defer({ generatedSources });
};
