import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M20 6H4V10H12.101C11.5151 10.5741 11.0297 11.2504 10.6736 12H4C2.89543 12 2 11.1046 2 10V6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V10C22 10.0331 21.9992 10.0659 21.9976 10.0986C21.424 9.51379 20.7486 9.02924 20 8.67363V6Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M20.8508 11.8105C19.9337 10.7045 18.5491 10 17 10C15.3642 10 13.9118 10.7856 12.9996 12C12.3719 12.8357 12 13.8744 12 15C12 17.7614 14.2386 20 17 20C19.7614 20 22 17.7614 22 15C22 13.7877 21.5685 12.6761 20.8508 11.8105ZM20 14V16H14V14H20Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "DeleteRow"
);
