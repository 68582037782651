import { RuntimeNotificationMessage } from "@/types/api/notifications";

enum CustomEvents {
  RequestNotification = "request-notification",
  SidebarToggled = "sidebar-toggled",
  ToggleNotifications = "toggle-notifications",
}

declare global {
  interface DocumentEventMap {
    "request-notification": CustomEvent<RuntimeNotificationMessage>;
    "sidebar-toggled": CustomEvent<{ collapsed: boolean }>;
    "toggle-notifications": CustomEvent<{ collapsed: boolean | null }>;
  }
}

export { CustomEvents };
