import { PlanCodes } from "../types/api/accountSubscription";

const planCodesOrder = [
  PlanCodes.AiGrowthManager,
  PlanCodes.AiMarketingSpecialist,
  PlanCodes.AiMarketingAssistant,
] as const;

const plansCount = planCodesOrder.length;

export const getKarmaLevel = (planCode: PlanCodes): number =>
  plansCount - 1 - planCodesOrder.indexOf(planCode);
