import { stripoAuth } from "../envVars";
import { fetchApi } from "./fetch/fetchApi";

const getStripoAuthToken = () =>
  fetchApi<string>("https://plugins.stripo.email/api/v1/auth", {
    method: "POST",
    body: JSON.stringify(stripoAuth),
  }).then((data: any) => data.token);

export { getStripoAuthToken };
