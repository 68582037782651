import React from "react";
import { Grid } from "@mui/material";
import { Code, FileArchive, UploadOutline } from "@tiny/icons";
import SelectCard from "../../../SelectCard";
import useBreakpoints from "../../../../hooks/useBreakpoints";

interface CustomTemplateOptionsProps {
  onPasteYourCode: () => void;
  onImportHtml: () => void;
}

function CustomTemplateOptions(props: CustomTemplateOptionsProps) {
  const { onPasteYourCode, onImportHtml } = props;
  const { mdUp } = useBreakpoints();

  return (
    <Grid container spacing={mdUp ? 5 : 4}>
      <Grid item xs={12} md={4}>
        <SelectCard
          title="Paste your code"
          description="Create a template by pasting your custom coded design."
          onSelect={onPasteYourCode}
          icon={<Code />}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectCard
          title="Import HTML"
          description="Create a template by uploading an HTML file with your template code."
          onSelect={onImportHtml}
          icon={<UploadOutline />}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectCard
          title="Import ZIP"
          description="Create a template by uploading a zip file with your template code."
          disabled
          icon={<FileArchive />}
        />
      </Grid>
    </Grid>
  );
}

export default CustomTemplateOptions;
