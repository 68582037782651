import {
  InfoMessageGroup,
  InfoMessageKeys,
} from "@/components/MetricCard/types";

export const metricsInfoMessages: {
  [key1 in InfoMessageGroup]: {
    [key2 in InfoMessageKeys]: string;
  };
} = {
  automationsInfoMessages: {
    totalRevenueInfoMessage: "Total number of active automations.",
    generatedRevenueInfoMessage:
      "Total revenue generated by triggering tinyAlbert automations.",
    generatedConversionMessage:
      "A total number of customers who placed an order as a result of tinyAlbert automations.",
    emailSentInfoMessage: "A total number of emails sent through automations.",
    emailOpensInfoMessage:
      "A total number of unique opened emails sent through automations.",
    emailClicksInfoMessage:
      "A total number of unique clicked emails sent through automations.",
  },

  specificAutomationInfoMessages: {
    totalRevenueInfoMessage: "Revenue generated so far by your store.",
    generatedRevenueInfoMessage:
      "Total revenue generated by triggering this automation.",
    generatedConversionMessage:
      "A total number of customers who placed an order as a result this automation.",
    emailSentInfoMessage:
      "A total number of emails sent through this automation.",
    emailOpensInfoMessage:
      "A total number of unique opened emails sent through this automation.",
    emailClicksInfoMessage:
      "A total number of unique clicked emails sent through this automation.",
  },

  campaignsInfoMessages: {
    totalRevenueInfoMessage: "Total number of sent campaigns.",
    generatedRevenueInfoMessage:
      "Total revenue generated by sending tinyAlbert campaigns.",
    generatedConversionMessage:
      "A total number of customers who placed an order as a result of tinyAlbert sent campaigns.",
    emailSentInfoMessage: "A total number of emails sent through campaigns.",
    emailOpensInfoMessage:
      "A total number of unique opened emails sent through campaigns.",
    emailClicksInfoMessage:
      "A total number of unique clicked emails sent through campaigns.",
  },

  specificCampaignMessages: {
    totalRevenueInfoMessage: "Revenue generated so far by your store.",
    generatedRevenueInfoMessage:
      "Total revenue generated by sending this campaign.",
    generatedConversionMessage:
      "A total number of customers who placed an order as a result of this campaign.",
    emailSentInfoMessage:
      "A total number of emails sent through this campaign.",
    emailOpensInfoMessage:
      "A total number of unique opened emails sent through this campaign.",
    emailClicksInfoMessage:
      "A total number of unique clicked emails sent through this campaign.",
  },
};
