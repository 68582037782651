import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path d="M4.5 2H8.5L4.5 18H0.5L4.5 2Z" xmlns="http://www.w3.org/2000/svg" />
    <path
      d="M10.5 2H14.5L10.5 18H6.5L10.5 2Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M16.5 2H20.5L16.5 18H12.5L16.5 2Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "PlanLogo"
);
