import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Campaign from "@/assets/illustrations/campaign.svg";
import { Metric } from "@/components/MetricCard/types";
import { ChevronRight } from "@tiny/icons";
import CardHeader from "../PieChartCard/CardHeader";
import MetricInfoCard from "../MetricInfoCard/MetricInfoCard";

export interface StatisticsCardProps {
  metrics: Metric[];
  seeReportsHandler?: () => void;
}

const StatisticsCard: React.FC<StatisticsCardProps> = ({
  metrics,
  seeReportsHandler,
}) => {
  const sm = useMediaQuery("(max-width:600px)");

  const twoItemsGrid = metrics.length === 2;

  const lg = useMediaQuery("(max-width:1365px)");

  const shouldRenderDivider = (index: number) => {
    if (lg && !sm) {
      return (index + 1) % 2 !== 0 && index < metrics.length - 1;
    }
    return index < metrics.length - 1;
  };

  const boxStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  };

  const dividerStyles = {
    width: sm ? "100%" : "2px",
    height: sm ? "2px" : "80%",
    mx: sm ? 0 : 1,
    my: sm ? 2 : 0,
  };

  return (
    <Card
      sx={{
        p: 6,
        borderRadius: 4,
        boxShadow: "none",
        width: "100%",
      }}
    >
      <CardContent sx={{ p: "0 !important" }}>
        <Grid container spacing={2} p={0}>
          {metrics.map((metric, i) => (
            <Grid
              key={metric.id}
              item
              xs={12}
              sm={6}
              md={6}
              lg={twoItemsGrid ? 6 : 3}
              sx={{
                display: "flex",
                flexDirection: sm ? "column" : "row",
                width: "100%",
                justifyContent: "space-between",
                p: "0 !important",
              }}
            >
              <Box sx={{ p: { xs: 0, sm: 2, md: 4, lg: 6 }, flex: 1 }}>
                <Box sx={{ pb: { xs: 2, sm: 2, md: 4, lg: 6 } }}>
                  <CardHeader
                    text={metric.title}
                    tooltipMessage={metric.infoMessage || ""}
                  />
                </Box>
                <MetricInfoCard
                  image={metric?.image || Campaign}
                  label={metric?.shortTitle || ""}
                  value={metric.value}
                />

                {metric?.subMetric ? (
                  <Typography variant="label1R" color="grey.100">
                    {metric.subMetric?.value}&nbsp;
                    <Typography variant="label1R" color="grey.80">
                      {metric.subMetric?.label}
                    </Typography>
                  </Typography>
                ) : (
                  seeReportsHandler && (
                    <Button
                      sx={(theme) => ({
                        p: 0,
                        fontWeight: theme.typography.fontWeightRegular,
                      })}
                      onClick={seeReportsHandler}
                      variant="text"
                      endIcon={<ChevronRight />}
                    >
                      See reports
                    </Button>
                  )
                )}
              </Box>

              {shouldRenderDivider(i) && (
                <Box sx={boxStyles}>
                  <Divider
                    orientation={sm ? "horizontal" : "vertical"}
                    flexItem
                    sx={dividerStyles}
                  />
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;
