import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M7.62745 11.3787C6.05882 11.3787 5 10.2868 5 8.66912C5 7.21324 6.21569 6 7.86274 6C9.66667 6 11 7.45588 11 9.68015C11 14.7353 7.31373 16.7574 5 17V14.7757C6.56863 14.4926 8.33333 12.9154 8.41176 11.1765C8.33333 11.2169 8.01961 11.3787 7.62745 11.3787Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M15.6274 11.3787C14.0588 11.3787 13 10.2868 13 8.66912C13 7.21324 14.2157 6 15.8627 6C17.6667 6 19 7.45588 19 9.68015C19 14.7353 15.3137 16.7574 13 17V14.7757C14.5686 14.4926 16.3333 12.9154 16.4118 11.1765C16.3333 11.2169 16.0196 11.3787 15.6274 11.3787Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "DoubleQuotesR"
);
