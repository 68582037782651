import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M14.8277 11.3221C12.9633 14.4553 9.48976 15.9399 6.84661 14.827L3.76866 19.9999L2 19.0091L5.19791 13.6346C3.58071 11.7779 3.46409 8.62194 5.10003 5.87253C7.07389 2.5552 10.8516 1.0859 13.5378 2.59075C14.2847 3.00917 14.8686 3.61348 15.2799 4.33927C15.28 4.33927 15.2799 4.33927 15.2799 4.33927C15.5977 4.89995 15.8125 5.53311 15.9199 6.20908C15.9199 6.20908 15.9199 6.20907 15.9199 6.20908C16.1735 7.80508 15.8287 9.63967 14.8277 11.3221ZM13.9865 7.31915C13.9867 7.21414 13.9833 7.1108 13.9767 7.00927C13.8936 5.73451 13.2913 4.74432 12.5151 4.3095C12.2876 4.18201 12.0248 4.09095 11.7374 4.04023L11.7374 11.9778C11.8062 11.9181 11.8744 11.8561 11.942 11.7917C11.942 11.7916 11.942 11.7917 11.942 11.7917C12.3529 11.4 12.7402 10.9203 13.0828 10.3446C13.7222 9.26997 13.9853 8.23158 13.9865 7.31915ZM10.0078 12.9855C10.0078 12.9855 10.0078 12.9854 10.0078 12.9855C10.2287 12.9114 10.4517 12.8175 10.674 12.703L10.7163 12.6809L10.7163 4.02406C10.0605 4.10868 9.35223 4.3705 8.67401 4.84041V13.2006C9.09254 13.2089 9.54563 13.1404 10.0078 12.9855ZM7.41256 12.8851C7.48884 12.9278 7.56908 12.9665 7.65287 13.0009V5.74567C7.36538 6.0653 7.09337 6.43241 6.84489 6.85001C5.20803 9.60097 6.0374 12.1147 7.41256 12.8851Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M16.7163 14.681C15.8052 15.1615 14.8794 15.2917 14.1304 15.1431C13.0083 16.0555 11.7184 16.713 10.3758 17.0162L8 21.0091L9.76866 21.9999L12.8466 16.827C15.4898 17.9399 18.9633 16.4553 20.8277 13.322C22.8015 10.0047 22.224 6.09555 19.5378 4.59071C18.8657 4.21418 18.1253 4.02385 17.3618 4.00208C17.6445 4.65631 17.8292 5.35623 17.9214 6.07899C18.1375 6.13226 18.3371 6.20971 18.5151 6.30946C19.8903 7.07984 20.7197 9.59356 19.0828 12.3445C18.6839 13.015 18.2243 13.5553 17.7374 13.9779V9.37719C17.5246 10.2995 17.1808 11.2045 16.7163 12.048V14.681Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "TennisMatchAlt"
);
