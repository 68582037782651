import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { inIframe } from "@tiny/utils/dom";
import { SentryConfigProps } from "./types";

/*
 Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
 */
function initializeSentry(config: SentryConfigProps) {
  const { dsn, release, environment, instrumentationFns } = config;
  Sentry.init({
    dsn,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    release,
    environment,
    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          ...instrumentationFns
        ),
      }),
    ],
  });
  if (inIframe()) {
    Sentry.setTag("iframe", true);
  }
}

const decorateWithSentry: typeof wrapCreateBrowserRouter = (
  createBrowserRouter
) => Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export { initializeSentry, decorateWithSentry };
export { captureException as captureExceptionToSentry } from "@sentry/react";
