import { prepareRequest } from "./requestConfig";
import {
  networkErrorHandler,
  rootResponseHandler,
} from "./rootResponseHandler";

export async function fetchApi<T>(
  url: string,
  requestConfig?: RequestInit,
  retries = 1,
  isMultipart?: boolean
): Promise<T> {
  const { url: requestUrl, requestConfig: defaultRequestConfig } =
    prepareRequest(url);

  const finalRequestConfig = defaultRequestConfig
    ? { ...defaultRequestConfig, ...requestConfig }
    : requestConfig;

  if (
    isMultipart &&
    finalRequestConfig?.headers?.hasOwnProperty("Content-Type")
  ) {
    const headers = new Headers(finalRequestConfig.headers);
    headers.delete("Content-Type");
    finalRequestConfig.headers = headers;
  }

  return window.fetch(requestUrl, finalRequestConfig).then<T>(
    rootResponseHandler([url, requestConfig, retries]),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    networkErrorHandler(requestUrl)
  );
}
