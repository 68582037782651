import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M13 10V21C12.0034 21 7.62034 21 4.99915 21C3.89458 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3L19 3C20.1046 3 21 3.89543 21 5V10H13ZM11 10H5V14H11V10ZM11 19V16H5V19H11ZM13 5.00001V8.00001H19V5.00001H13ZM11 5.00001H5V8.00001H11V5.00001Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M19 21V18H16V16H19V13H21V16H24V18H21V21H19Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "TableAdd"
);
