import React from "react";
import { useQueryClient } from "react-query";
import {
  usePutActivityFavoriteByIdAndIsFavoriteMutation,
  usePutActivityTemplateEmailFavoriteByIdAndIsFavoriteMutation,
} from "../../../../app/useGeneratedApi";
import { useTemplateListView } from "../../TemplateList.context";
import { TemplateItemProps } from "./TemplateItem.types";
import { changeTemplateFavoriteCache } from "./TemplateItem.helpers";
import {
  mergeAttributes,
  prepareTemplate,
} from "../../../../models/template/templateUtils";
import { getBaseUrl } from "../../../../utils/urlUtils";
import { useMergeAttributesTemplate } from "../../../../models/template/template.hooks";

export function useTemplateItem(props: TemplateItemProps) {
  const {
    css: cssProp,
    favoritable,
    favorited,
    html: htmlProp,
    id,
    name,
    renderWithEjs,
    sectionTitle,
    selectable,
    saved,
    template,
    type,
    toggleSelect,
    isSelected,
    selectDisabled,
    isCurrentSelected,
  } = props;

  const queryClient = useQueryClient();

  const {
    attributes,
    templateData,
    mainActionText,
    openTemplatePreview,
    onMainActionClick,
    selectedActionText,
  } = useTemplateListView();

  const { templateHtml, templateCss } = useMergeAttributesTemplate(
    htmlProp,
    cssProp,
    templateData,
    renderWithEjs
  );

  const [moreOpen, setMoreOpen] = React.useState(false);

  const { mutateAsync: favoriteSavedTemplate } =
    usePutActivityFavoriteByIdAndIsFavoriteMutation();

  const { mutateAsync: favoriteOtherTemplate } =
    usePutActivityTemplateEmailFavoriteByIdAndIsFavoriteMutation();

  const toggleFavorite = React.useCallback(async () => {
    if (id) {
      if (saved) {
        await favoriteSavedTemplate({
          id,
          isFavorite: !favorited,
        });
      } else {
        await favoriteOtherTemplate({
          id,
          isFavorite: !favorited,
        });
      }
      changeTemplateFavoriteCache(queryClient, id, !favorited);
    }
  }, [
    favoriteOtherTemplate,
    favoriteSavedTemplate,
    favorited,
    id,
    queryClient,
    saved,
  ]);

  const showMore = React.useCallback(() => {
    setMoreOpen(true);
  }, []);

  const hideMore = React.useCallback(() => {
    setMoreOpen(false);
  }, []);

  const executeMainAction = React.useCallback(() => {
    onMainActionClick(template, saved, type);
  }, [onMainActionClick, saved, template, type]);

  const openPreview = React.useCallback(() => {
    openTemplatePreview(sectionTitle, template, saved, type);
  }, [openTemplatePreview, saved, sectionTitle, template, type]);

  const selectTemplate = React.useCallback(() => {
    toggleSelect(id);
  }, [toggleSelect, id]);

  const selected = React.useMemo(() => {
    return isSelected(id);
  }, [id, isSelected]);

  const html = React.useMemo(() => {
    const baseUrl = getBaseUrl();
    const htmlWithPlaceholders = prepareTemplate(templateHtml, baseUrl);
    return mergeAttributes(htmlWithPlaceholders, attributes);
  }, [attributes, templateHtml]);

  const css = templateCss;

  return {
    css,
    executeMainAction,
    favoritable,
    favorited,
    hideMore,
    html,
    mainActionText,
    moreOpen,
    name,
    openPreview,
    selectTemplate,
    selectable,
    selected,
    showMore,
    toggleFavorite,
    selectDisabled,
    isCurrentSelected,
    selectedActionText,
  };
}
