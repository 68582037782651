import { Theme } from "@mui/material";

const selected = ({ theme }: { theme: Theme }) => ({
  color: theme.palette.grey[100],
  fontWeight: 700,
});

const root = ({ theme }: { theme: Theme }) => ({
  textTransform: "unset",
  color: theme.palette.grey[80],
  fontWeight: 400,
  lineHeight: "0.875rem",
  padding: 0,
  minHeight: "1.875rem",
  minWidth: 0,
  alignItems: "flex-start",
  justifyContent: "flex-start",
  "&.Mui-selected": selected({ theme }),
});

const styleOverrides = {
  root,
};

export { styleOverrides };
