import React from "react";
import { Block, CircleCheck, GlassHour, Rocket } from "@tiny/icons";
import { FormControlLabel, Switch, Theme, Typography } from "@mui/material";
import TooltipWrapper from "../TooltipWrapper";

const switcherLabel = (active: boolean) => (active ? "Deactivate" : "Activate");

const switcherLabelColor = (active: boolean, theme: Theme) =>
  active ? theme.palette.grey[100] : theme.palette.primary.main;

let statusIcon;
let statusLabel;

interface GetCampaignProps {
  status: string;
  active: boolean;
  onChange: (_: any, isActivated: boolean) => void;
  outdated: boolean | undefined;
  activationToggleId?: string;
}

// duplicate of apps/einstein/src/hooks/useSwitchWithStatus.tsx
export const getCampaign = ({
  status,
  active,
  onChange,
  outdated,
  activationToggleId,
}: GetCampaignProps) => {
  switch (status) {
    case "COMPLETED":
      statusIcon = (
        <CircleCheck sx={(theme) => ({ fill: theme.palette.green.main })} />
      );
      statusLabel = "Completed";
      break;
    case "FAILED":
      statusIcon = <Block sx={(theme) => ({ fill: theme.palette.red[100] })} />;
      statusLabel = "Failed";
      break;
    case "GENERATE":
      statusIcon = (
        <GlassHour sx={(theme) => ({ fill: theme.palette.fuchsia.main })} />
      );
      statusLabel = "Generating...";
      break;
    case "LAUNCHED":
      statusIcon = (
        <Rocket sx={(theme) => ({ fill: theme.palette.yellow.main })} />
      );
      statusLabel = "Launched";
      break;
    default:
      statusIcon = (
        <TooltipWrapper
          text="Campaign is outdated"
          show={outdated}
          placement="top"
        >
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={onChange}
                name="active"
                color="primary"
                disabled={outdated}
                size="small"
                id={activationToggleId}
              />
            }
            label={switcherLabel(active)}
            sx={(theme) => ({
              color: switcherLabelColor(active, theme),
              ml: 0,
            })}
            className="activation-toggle"
          />
        </TooltipWrapper>
      );
      statusLabel = "";
      break;
  }

  return (
    <>
      {statusIcon}
      <Typography variant="p2">{statusLabel}</Typography>
    </>
  );
};
