import React from "react";
import { Box, Typography } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { HeroImageGrid } from "./HeroImageGrid";
import { GetHeroImageResponse } from "./types";
import { ImageProvider } from "./Context/imageContext";

function HeroImage() {
  const dataLoaderResponse = useLoaderData() as GetHeroImageResponse;
  const images = dataLoaderResponse?.heroImages?.images;

  return (
    <Box mt={6}>
      <Typography variant="h3">Hero image</Typography>
      <Typography variant="label1R" lineHeight={2} color="grey.80">
        You can select up to 5 images to be Hero images in your campaigns.
      </Typography>
      <ImageProvider>
        <HeroImageGrid imagesObject={images} />
      </ImageProvider>
    </Box>
  );
}

export default HeroImage;
