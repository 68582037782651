import React from "react";
import { Box, Typography } from "@mui/material";
import CenteredContainer from "@/components/CenteredContainer";

function WaitCampaignsGenerating({
  title = "Thanks for choosing tinyAlbert!",
  description = `We are in the process of generating campaigns for you. Kindly hit the
  "regenerate" button after a few seconds.`,
}: {
  title?: string;
  description?: string;
}) {
  return (
    <CenteredContainer
      maxWidth="sm"
      sx={{
        height: ["80%", "70%"],
      }}
    >
      <Box mb={12}>
        <Typography variant="h2" mb={2}>
          {title}
        </Typography>
        <Typography variant="p1" color="grey.80">
          {description}
        </Typography>
      </Box>
    </CenteredContainer>
  );
}

export default WaitCampaignsGenerating;
