import { createTheme, Theme } from "@mui/material";
import {
  palette,
  typography,
  spacing,
  components,
  breakpoints,
} from "./configs";
import "./types";

const createTinyTheme = (): Theme => {
  const partialTheme = createTheme({
    palette,
    breakpoints,
    typography: typography.initial,
  });
  return createTheme(partialTheme, {
    typography: typography.main(partialTheme),
    spacing,
    components,
  });
};

const einsteinTheme = createTinyTheme();

export default createTinyTheme;
export { einsteinTheme };
