import React from "react";
import { RegisterOptions } from "react-hook-form";
import { FormItem, TextField } from "../../components";
import { TextControlProps } from "./TextControl.types";
import { GetChildrePropsParams } from "../FormItem/FormItem.types";

function TextControl(props: TextControlProps) {
  const {
    defaultValue,
    getTextFieldProps,
    hideHelperText,
    label,
    maxLength,
    name,
    required,
    validate,
    valuePropName,
    watchValue,
    inputProps,
    ...other
  } = props;

  const rules = React.useMemo<RegisterOptions>(() => {
    const result: RegisterOptions = {
      validate,
    };
    if (required) {
      result.required = {
        value: true,
        message: label ? `${label} is required` : "Required field",
      };
    }
    if (maxLength !== undefined) {
      result.maxLength = {
        value: maxLength,
        message: `The maximum number of characters is ${maxLength}`,
      };
    }
    return result;
  }, [label, required, validate, maxLength]);

  const getChildrenProps = React.useCallback(
    (params: GetChildrePropsParams) => {
      return getTextFieldProps?.(params);
    },
    [getTextFieldProps]
  );

  return (
    <FormItem
      defaultValue={defaultValue}
      getChildrenProps={getChildrenProps}
      hideHelperText={hideHelperText}
      name={name}
      rules={rules}
      valuePropName={valuePropName}
      watchValue={watchValue}
    >
      <TextField
        {...other}
        label={label}
        inputProps={{ ...inputProps, maxLength: maxLength }}
      />
    </FormItem>
  );
}

export default React.memo(TextControl);
