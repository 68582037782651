import * as React from "react";
import { Outlet, useNavigate, useRevalidator } from "react-router-dom";
import PageTitle from "@/components/PageTitle";
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@mui/material";
import { CloseBig } from "@tiny/icons";
import { useScreenLoaderInvokers } from "@tiny/lib";

function Calendar() {
  const [tab, setTab] = React.useState("future");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    if (view === "Calendar") {
      navigate(`${newValue}/calendar`);
    } else {
      navigate(`${newValue}/list`);
    }
  };

  const [showAlert, setShowAlert] = React.useState(true);
  const [view, setView] = React.useState("Calendar");
  const navigate = useNavigate();
  const handleRadio = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "Calendar") {
      navigate(`${tab}/calendar`);
    } else {
      navigate(`${tab}/list`);
    }
    setView(newValue);
  };

  const revalidator = useRevalidator();
  const { hideScreenLoader, showScreenLoader } = useScreenLoaderInvokers();

  React.useEffect(() => {
    if (revalidator.state === "loading") {
      showScreenLoader();
    } else {
      hideScreenLoader();
    }
  }, [revalidator.state]);

  return (
    <>
      <PageTitle title="Calendar" canRefresh />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <Tabs value={tab} onChange={handleTabChange} textColor="secondary">
          <Tab value="future" label="Future Campaigns" />
          <Tab value="recurring" label="Recurring Campaigns" />
        </Tabs>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={view}
            onChange={handleRadio}
          >
            <Box display="flex">
              <FormControlLabel
                value="Calendar"
                control={<Radio />}
                label="Calendar"
                id="campaign-calendar-calendar-view"
              />
              <FormControlLabel
                value="List"
                control={<Radio />}
                label="List"
                id="campaign-calendar-list-view"
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
      <Box mt={6}>
        {showAlert && (
          <Alert
            variant="outlined"
            severity="info"
            sx={(theme: Theme) => ({
              mb: 5,
              borderColor: `${theme.palette.primary[60] as string} !important`,
              "& .MuiAlert-icon": {
                ml: 2,
              },
            })}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                sx={{ mr: 4 }}
                onClick={() => {
                  setShowAlert(!showAlert);
                }}
                id="campaign-calendar-close-info-alert-msg"
              >
                <CloseBig
                  sx={(theme) => ({
                    fontSize: theme.spacing(5),
                    color: "grey.70",
                  })}
                />
              </IconButton>
            }
          >
            <Typography variant="p3">
              We have developed a <b>tailor-made event calendar</b> designed
              exclusively for your store. Activate these campaigns to put your
              marketing on autopilot.
            </Typography>
          </Alert>
        )}
        <Outlet />
      </Box>
    </>
  );
}

export default Calendar;
