import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M2 19H4C4 16.7909 5.79086 15 8 15C10.2091 15 12 16.7909 12 19H14C14 15.6863 11.3137 13 8 13C4.68629 13 2 15.6863 2 19Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C11.9972 10.208 10.208 11.9972 8 12C5.79086 12 4 10.2091 4 8ZM6.00223 7.97134C5.98658 9.06383 6.85057 9.9667 7.94269 9.99912C9.03481 10.0315 9.95083 9.1815 10 8.09V8C10 6.89543 9.10457 6 8 6C6.9074 6.00111 6.01789 6.87885 6.00223 7.97134Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M18.339 14.9994C18.131 14.8144 17.896 14.6214 17.647 14.4174L17.6 14.3794L17.5944 14.3749C16.4384 13.4317 15 12.2581 15 10.7994C15.0002 10.3154 15.1953 9.85178 15.5413 9.51329C15.8873 9.1748 16.3551 8.98994 16.839 9.00042C17.4112 8.99888 17.9569 9.24153 18.339 9.66742C18.7212 9.24163 19.2668 8.99901 19.839 9.00042C20.3224 8.99102 20.7893 9.17651 21.1345 9.5151C21.4797 9.85369 21.6741 10.3169 21.674 10.8004C21.674 12.2652 20.2245 13.4474 19.059 14.398L19.056 14.4004L18.999 14.4474L18.9808 14.4623C18.7505 14.6508 18.5329 14.829 18.338 15.0014L18.339 14.9994Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "UserHeart"
);
