export const iframeDimensins = (
  width: number | undefined,
  height: number | undefined,
  contentWidth: number
) => {
  const delta = width ? contentWidth / width : 0;
  let deltaScale = width ? width / contentWidth : 0;
  deltaScale = deltaScale > 1 ? 1 : deltaScale;
  const iframeWidth = contentWidth < (width || 0) ? width : contentWidth;
  let iframeHeight = height ? delta * height : 0;
  if (height && iframeHeight < height) {
    iframeHeight = height;
  }
  return { iframeWidth, iframeHeight, deltaScale };
};

export function makeNotInteractive(html: string): string {
  return addTabIndexToInteractiveElements(html);
}

function addTabIndexToInteractiveElements(html: string): string {
  let result = html;
  result = result.replaceAll("<button", "<button tabindex='-1'");
  result = result.replaceAll("<a", "<a tabindex='-1'");
  result = result.replaceAll("<input", "<input tabindex='-1'");
  result = result.replaceAll("<select", "<select tabindex='-1'");
  result = result.replaceAll("<textarea", "<textarea tabindex='-1'");
  return result;
}

export function getIframeHead(options: {
  interactive?: boolean;
  scrollable?: boolean;
  css?: string;
}): string {
  const result = [];
  if (options.css) {
    result.push("<style>");
    result.push(options.css);
    result.push("</style>");
  }
  result.push("<style>");
  result.push(`
  body { 
    margin: 0;
    padding: 0;
  }
`);
  if (!options.interactive) {
    result.push(`
      * { 
        user-select: none;
      }
    `);
    result.push(`
      [tabindex="-1"] {
        pointer-events: none;
      }
    `);
  }
  if (!options.scrollable) {
    result.push(`
      html {
        overflow: hidden;
      }
    `);
  } else {
    result.push(`
      html {
        overflow: auto;
        scrollbar-gutter: stable;
        scrollbar-color: #aaa transparent;
        scrollbar-width: thin;
      }
      html::-webkit-scrollbar {
        width: 0.5rem;
      }
      html::-webkit-scrollbar-track {
      background: transparent;
      }
      html::-webkit-scrollbar-thumb {
        background: #c9cace;
        border-radius: 0.25rem;
      }
    `);
  }
  result.push("</style>");
  return result.join("");
}
