import React from "react";

import { Form, TruncateTooltip, TextControl } from "../../../../components";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import { useCampaignPageView } from "../../../CampaignPage.context";
import EditPanel from "../../../EditPanel";
import { useRssPanel } from "./RssPanel.hooks";
import { validateUrl } from "../../../../utils/validateUtils";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ExternalLink } from "@tiny/icons";

function RssPanel(): JSX.Element {
  const { lgUp } = useBreakpoints();

  const { details } = useCampaignPageView();

  const {
    editing,
    save,
    edit,
    editDisabled,
    form,
    closeButtonLabel,
    submitForm,
  } = useRssPanel();

  const buttonVariant = React.useMemo(
    () => (editing || !details?.contentSourceUrl ? "contained" : "outlined"),
    [details?.contentSourceUrl, editing]
  );

  return (
    <>
      <EditPanel
        buttonDisabled={editDisabled}
        buttonLoading={false}
        closedButtonLabel={closeButtonLabel}
        buttonVariant={buttonVariant}
        empty={
          !details?.contentSourceUrl &&
          !details?.id &&
          !details?.senderDetailsId
        }
        mt={lgUp ? 8 : 4}
        onClose={save}
        onOpen={edit}
        open={editing}
        openedButtonLabel="Save"
        title="RSS URL (optional)"
        subtitle={
          <Typography variant="label2" mt={-0.5} mb={0} color="grey.90">
            Email Content Source url
            <InfoTooltip
              title={
                <Typography variant="label2" lineHeight={1.2}>
                  RSS URL for content retrieval, only JSON RSS feed is
                  supported.
                  <a
                    href="https://support.tinyemail.com/portal/en/kb/articles/add-a-json-web-feed-to-a-campaign-21-5-2024"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Box display="flex" alignItems="center">
                      Learn More <ExternalLink />
                    </Box>
                  </a>
                </Typography>
              }
              size="small"
              buttonText="Got it"
              placement="top"
            />
          </Typography>
        }
      >
        {editing ? (
          <Box mt={6}>
            <Form form={form} onSubmit={submitForm}>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <TextControl
                    label="Url"
                    maxLength={64}
                    name="url"
                    validate={validateUrl}
                  />
                </Grid>
              </Grid>
              <button type="submit" hidden />
            </Form>
          </Box>
        ) : (
          details?.contentSourceUrl && (
            <>
              <Divider
                sx={{
                  my: 6,
                }}
              />
              <Box display="flex">
                <Box minWidth={0}>
                  <TruncateTooltip>
                    <Typography variant="label1" color="grey" fontWeight="bold">
                      {details?.contentSourceUrl}
                    </Typography>
                  </TruncateTooltip>
                </Box>
              </Box>
            </>
          )
        )}
      </EditPanel>
    </>
  );
}

export default RssPanel;
