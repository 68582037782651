import { useNavigate, useRouteLoaderData } from "react-router-dom";
import React from "react";
import type { RootLoader } from "@/types/RootLoader";
import { Box, Typography } from "@mui/material";
import tinyAlbertSymbol from "@/assets/logos/tiny-albert-symbol-color.svg";
import CenteredContainer from "@/components/CenteredContainer";
import CircularProgressWithContent from "@/components/CircularProgressWithContent";

function InstallingPage() {
  const { userStatus } = useRouteLoaderData("root") as RootLoader;
  const navigate = useNavigate();
  const intervalRef = React.useRef<NodeJS.Timer>();

  React.useEffect(() => {
    intervalRef.current = setInterval(() => {
      navigate("/onboarding");
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [navigate, userStatus?.onboardingStep]);

  return (
    <CenteredContainer
      maxWidth="sm"
      sx={{
        height: ["80%", "70%"],
      }}
    >
      <Box mb={12}>
        <Typography variant="h2" mb={2}>
          Thanks for choosing tinyAlbert!
        </Typography>
        <Typography variant="p1" color="grey.80">
          Please wait a few seconds until the synchronization is complete and
          you can choose a plan for you business.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <CircularProgressWithContent>
          <img
            style={{
              width: 64,
              height: 64,
            }}
            src={tinyAlbertSymbol}
            alt="tinyAlbert logo"
          />
        </CircularProgressWithContent>
      </Box>
    </CenteredContainer>
  );
}

export default InstallingPage;
