import React from "react";
import StepContainer from "../StepContainer";
import SchedulePanel from "./SchedulePanel";

function ScheduleStep(): JSX.Element {
  return (
    <StepContainer>
      <SchedulePanel />
    </StepContainer>
  );
}

export default ScheduleStep;
