import React from "react";
import { Box } from "@mui/material";
import {
  useActionData,
  useLoaderData,
  useRouteLoaderData,
} from "react-router-dom";
import CouponCode from "@/components/CouponCode";
import { GetSubscriptionsResponse } from "@/types/api/subscriptions";
import { GetAccountSubscriptionResponse } from "@/types/api/accountSubscription";
import { PlanSelectionActionResponse } from "@/routes/PlansPage/types";
import { Subscription } from "@/routes/AccountSettings/Plans/types";
import PlanSelection from "../../../components/PlanSelection";
import { subscriptionMapper } from "./mapper";

// TODO cleanup duplicated code with apps/einstein/src/routes/PlansPage/index.tsx
function Plans() {
  const dataLoaderResponse = useLoaderData() as GetSubscriptionsResponse;
  const actionData = useActionData() as PlanSelectionActionResponse;

  const accountSubscriptionData = useRouteLoaderData("main") as {
    planInfo: GetAccountSubscriptionResponse;
  };

  const appliedCouponPrices: { [key: string]: Subscription } =
    actionData?.subscriptions?.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: currentValue,
      }),
      {}
    );

  const data = dataLoaderResponse?.subscriptions?.map((s) =>
    subscriptionMapper(
      s,
      appliedCouponPrices?.[s.id],
      accountSubscriptionData.planInfo
    )
  );

  return (
    <Box mt={6}>
      <CouponCode couponFormAction="/main/account-settings/plans" />
      <PlanSelection plans={data} sx={{ maxWidth: "100%" }} />
    </Box>
  );
}

export { Plans };
