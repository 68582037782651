import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import { RootLoader } from "@/types/RootLoader";
import { Box, Divider, Typography } from "@mui/material";
import BasicInfoForm from "@/components/ProfileForm/BasicInfoForm";
import ContactInfoForm from "@/components/ProfileForm/ContactInfoForm";

function Profile() {
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;

  return (
    <Box mt={6}>
      <Typography
        variant="h4"
        color="grey.100"
        m={0}
        mb={4}
        sx={{ width: "100%" }}
      >
        Basic Information
      </Typography>
      <BasicInfoForm
        firstName={accountInfo.firstName}
        lastName={accountInfo.lastName}
        userEmail={accountInfo.userEmail}
        phoneNumber={accountInfo.phoneNumber ?? ""}
      />
      <Divider sx={{ my: { xs: 5, md: 6 } }} />
      <Typography
        variant="h4"
        color="grey.100"
        m={0}
        mb={4}
        sx={{ width: "100%" }}
      >
        Contact information
      </Typography>
      <ContactInfoForm
        companyName={accountInfo.company}
        state={accountInfo.state ?? ""}
        city={accountInfo.city ?? ""}
        country={accountInfo.country ?? ""}
        zipCode={accountInfo.postalCode ?? ""}
        address={accountInfo.address ?? ""}
        websiteUrl={accountInfo.site}
      />
    </Box>
  );
}

export { Profile };
