import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path d="M15 16H17V9H15V16Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M7 16H9V11H7V16Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M11 16H13V6H11V16Z" xmlns="http://www.w3.org/2000/svg" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "BarChartCircle"
);
