import type { ForwardedRef } from "react";
import * as React from "react";
import {
  CreateVersionHistoryPointAsyncCallback,
  GetTemplateCallback,
  StripoInstance,
  StripoProps,
} from "./Stripo.types";

function StripoMock(
  props: StripoProps,
  ref: ForwardedRef<StripoInstance>
): JSX.Element {
  const { html, css, onTemplateLoaded } = props;

  React.useImperativeHandle(ref, () => ({
    getTemplate: (callback: GetTemplateCallback) => {
      callback(html, css);
    },
    createVersionHistoryPointAsync: (
      callback?: CreateVersionHistoryPointAsyncCallback
    ) => {
      callback?.();
    },
    reload: () => {
      console.log("reload");
    },
    showAmpErrorsModal: (ampErrors: string[]) => {
      console.log(`ampErrors: ${ampErrors}`);
    },
  }));

  React.useEffect(() => {
    onTemplateLoaded?.();
  }, [onTemplateLoaded]);

  return <div>Stripo Mock</div>;
}

export default React.forwardRef(StripoMock);
