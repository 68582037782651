import React from "react";
import { Tooltip, useTheme } from "@mui/material";
import { hex2rgb } from "@tiny/utils/colors";
import { TooltipWrapperProps } from "./types";

function TooltipWrapper({
  children,
  show = false,
  text = "",
  placement,
}: TooltipWrapperProps) {
  const theme = useTheme();

  return show ? (
    <Tooltip
      title={text}
      placement={placement}
      componentsProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              padding: 2,
              fontSize: theme.spacing(3),
              backgroundColor: hex2rgb(theme.palette.grey[100], 0.9),
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
}

export default TooltipWrapper;
