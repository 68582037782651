import React from "react";
import { Campaign } from "@/types/api/campaigns";
import { Box, Chip, Typography } from "@mui/material";
import { CloseSmall } from "@tiny/icons";

function FormHeader({
  editMode,
  formData,
  hideDrawer,
}: {
  editMode: boolean | undefined;
  formData?: Campaign;
  hideDrawer: () => void;
}) {
  return (
    <Box
      py={6}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h3" color="grey.100">
        {editMode ? "Edit campaign" : "Create a new campaign"}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        gap={4}
      >
        {editMode && (
          <Chip
            label={formData?.name}
            sx={(theme) => ({
              background: theme.palette.grey[30],
              color: theme.palette.grey[90],
              fontWeight: 400,
              fontSize: theme.spacing(3),
            })}
          />
        )}
        <Box
          sx={(theme) => ({
            border: `0.5px solid ${theme.palette.grey[40]}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: 24,
            height: 24,
          })}
          onClick={hideDrawer}
        >
          <CloseSmall
            sx={() => ({
              cursor: "pointer",
            })}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default FormHeader;
