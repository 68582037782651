export const capitalizeWords = (str: string) => {
  if (!str) {
    return "";
  }
  const words: string[] = str.split(" ");

  words.forEach((word, index, array) => {
    array[index] = word.charAt(0).toUpperCase() + word.slice(1);
  });

  const capitalizedString: string = words.join(" ");
  return capitalizedString;
};
