import { Form } from "@/types/api/forms";
import { useRevalidator } from "react-router-dom";
import { useInterval } from "@tiny/utils/react/useInterval";

function useFormsRefetch(forms: Form[]) {
  const revalidator = useRevalidator();
  const shouldRefetch = forms.find((form) => form.formStatus === "GENERATE");

  useInterval(
    () => {
      revalidator.revalidate();
    },
    shouldRefetch ? 3000 : null
  );
}

export { useFormsRefetch };
