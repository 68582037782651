import type { SentryEnvProps } from "./env";

const ENVS = import.meta.env;

/*
  TODO implement getter and concept of mandatory and optional env vars
  should throw if mandatory var is not set in current env
  Also should treat default prod values set during docker build and
  which are then replaced with env-specific values during docker run
 */

export const API_BASE_URL = ENVS.VITE_APP_API_URL as string;
export const FORM_API_BASE_URL = ENVS.VITE_APP_FORM_API_URL as string;

export const APP_ID = ENVS.VITE_APP_ID as string;

export const APP_REFRESH_TOKEN_USER =
  ENVS.VITE_APP_REFRESH_TOKEN_USER as string;
export const APP_REFRESH_TOKEN_PASS =
  ENVS.VITE_APP_REFRESH_TOKEN_PASS as string;
export const INTERCOM_APP_ID = ENVS.VITE_INTERCOM_APP_ID as string;

const stripoAuth = {
  pluginId: (ENVS.VITE_APP_STRIPO_PLUGIN_ID ?? "") as string,
  secretKey: (ENVS.VITE_APP_STRIPO_SECRET_KEY ?? "") as string,
};

function getSentryConfig(): SentryEnvProps {
  return {
    dsn: ENVS.VITE_SENTRY_DSN as string,
    release: ENVS.VITE_APP_RELEASE as string,
    environment: ENVS.VITE_ENVIRONMENT as string,
  };
}

function isSentryEnabled() {
  const config = getSentryConfig();
  return Object.values(config).every((value) => !!value);
}

export { isSentryEnabled, getSentryConfig, stripoAuth };
