import { Box, Chip, Theme } from "@mui/material";
import React from "react";
import {
  GetGeneratedOrdersResponse,
  Orders,
} from "@/types/api/generatedRevenueOrders";
import { useAsyncValue, useSearchParams } from "react-router-dom";

function OrdersChips() {
  const orders = useAsyncValue() as GetGeneratedOrdersResponse;

  const [searchParams] = useSearchParams();
  const urlParam = new URLSearchParams(searchParams);

  const chipsDictionary = orders.content.reduce(
    (acc: { [key: string]: string }, next: Orders) => ({
      ...acc,
      [next.activityId]: next.activityName,
    }),
    {} as { [key: string]: string }
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={1}
      sx={{ overflowX: "scroll" }}
    >
      {urlParam.get("activities")?.length === 0 ? (
        <Chip
          size="small"
          label={`All ${orders.content.length} selected`}
          sx={(theme: Theme) => ({
            marginBottom: 5,
            backgroundColor: "grey.30",
            color: "grey.90",
            padding: 1,
            height: "inherit",
            "&> .MuiChip-labelSmall": {
              fontWeight: theme.typography.fontWeightRegular,
              fontSize: theme.spacing(2),
              paddingX: 0,
            },
          })}
        />
      ) : (
        Object.values<string>(chipsDictionary).map(
          (name: string, index: number) => (
            <Chip
              size="small"
              key={index}
              label={name}
              sx={(theme: Theme) => ({
                marginBottom: 5,
                backgroundColor: "grey.30",
                color: "grey.90",
                padding: 1,
                height: "inherit",
                "&> .MuiChip-labelSmall": {
                  fontWeight: theme.typography.fontWeightRegular,
                  fontSize: theme.spacing(2),
                  paddingX: 0,
                },
              })}
            />
          )
        )
      )}
    </Box>
  );
}

export default OrdersChips;
