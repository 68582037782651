import { StylesOverridesProps } from "../../../types/stylesOverrides";

const icon = ({ theme }: StylesOverridesProps) => ({
  fontSize: theme.typography.pxToRem(20),
});

const root = ({ theme }: StylesOverridesProps) => ({
  "& .MuiOutlinedInput-notchedOutline": { borderRadius: theme.spacing(2) },
  "& .MuiInputBase-input": {
    fontSize: theme.spacing(3.5),
  },
});

const styleOverrides = {
  root,
  icon,
};

export { styleOverrides };
