import { FormMethod, useFetcher } from "react-router-dom";
import * as React from "react";

function useRegenerateAction(method: FormMethod, action: string) {
  const fetcher = useFetcher();
  const { ok } = (fetcher.data as { ok: boolean }) ?? {};
  const isSubmitting = fetcher.state === "submitting";
  const submitCompleted = ok;

  const submitHandler = React.useCallback(() => {
    const formData = new FormData();

    formData.append("_action", "regenerateAll");
    fetcher.submit(formData, {
      method,
      action,
    });
  }, [action, fetcher, method]);

  return {
    submitHandler,
    isSubmitting,
    submitCompleted,
  };
}

export { useRegenerateAction };
