import { Dialog, DialogContent } from "@mui/material";
import { DialogTitle } from "@tiny/mui";
import React from "react";

function NotificationDialog({
  open,
  onClose,
  content,
}: {
  open: boolean;
  onClose: () => void;
  content: React.ReactNode;
}) {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose} />
      <DialogContent
        dividers={false}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 3,
          mb: 6,
        }}
      >
        {content}
      </DialogContent>
    </Dialog>
  );
}

export { NotificationDialog };
