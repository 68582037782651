import { Theme } from "@mui/material";
import { hex2rgb } from "@tiny/utils/colors";

const root = ({ theme }: { theme: Theme }) => ({
  "&.MuiTableRow-hover:hover": {
    backgroundColor: hex2rgb(theme.palette.primary.lighter, 0.2),
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[10],
  },
});

const styleOverrides = {
  root,
};

export { styleOverrides };
