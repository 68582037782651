import * as React from "react";
import { usePageSort } from "@/components/PaginationRouted/usePagination.hook";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ChevronBigDown } from "@tiny/icons";

const sortOptions = [
  { key: "name,asc", label: "Sort by Name Asc" },
  { key: "name,desc", label: "Sort by Name Desc" },
  { key: "startTime,asc", label: "Sort by Send Date Asc" },
  {
    key: "startTime,desc",
    label: "Sort by Send Date Desc",
  },
];

function PageSort({ defaultSortOption }: { defaultSortOption?: string }) {
  const [sort, onChangeSort] = usePageSort(defaultSortOption);

  return (
    <Select
      labelId="results"
      value={sort}
      name="limit"
      IconComponent={ChevronBigDown}
      size="small"
      variant="outlined"
      className="page-sort"
      onChange={(event: SelectChangeEvent<string>) => {
        onChangeSort(event.target.value);
      }}
    >
      {sortOptions.map((option) => (
        <MenuItem key={option.key} value={option.key}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default PageSort;
