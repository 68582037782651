import React from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { CheckBold } from "@tiny/icons";
import { isEqual } from "./SelectList.helpers";

import { SelectListProps } from "./SelectList.types";

function SelectList(props: SelectListProps) {
  const { options, value, onChangeValue } = props;

  return (
    <List
      role="listbox"
      sx={{
        listStyle: "none",
        margin: 0,
        padding: 0,
      }}
    >
      {options.map((option) => (
        <ListItem
          key={option.value}
          sx={(theme) => ({
            display: "flex",
            marginTop: 4,
            padding: 8,
            borderRadius: 4,
            "&[aria-selected=true]": {
              background: theme.palette.grey[100],
            },
            "&:first-of-type": {
              marginTop: 0,
            },
          })}
          role="option"
          aria-selected={isEqual(option.value, value)}
          onClick={() => onChangeValue?.(option.value)}
        >
          <Typography
            sx={{
              flex: 1,
              lineHeight: "16px",
            }}
            variant="p2"
            color="grey"
          >
            {option.label}
          </Typography>
          {isEqual(option.value, value) && (
            <CheckBold
              sx={(theme) => ({
                width: 16,
                height: 16,
                fill: theme.palette.primary.main,
                marginRight: 4,
              })}
            />
          )}
        </ListItem>
      ))}
    </List>
  );
}

export default React.memo(SelectList);
