import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M16.0067 5.40841C15.9971 5.3205 15.9188 5.28133 15.8605 5.28133C15.8021 5.28133 14.523 5.18383 14.523 5.18383C14.523 5.18383 13.6346 4.2955 13.5271 4.20758C13.4296 4.11008 13.2438 4.13925 13.1659 4.15883C13.1563 4.15883 12.9705 4.21758 12.668 4.31508C12.3655 3.44591 11.848 2.65508 10.9205 2.65508H10.8325C10.578 2.323 10.2463 2.16675 9.97296 2.16675C7.82504 2.16675 6.79004 4.85175 6.46796 6.21883C5.62838 6.48258 5.03254 6.658 4.96421 6.68758C4.49546 6.83383 4.48588 6.84383 4.42713 7.283C4.37838 7.61508 3.15796 17.0563 3.15796 17.0563L12.658 18.8334L17.8134 17.7205C17.823 17.7009 16.0167 5.49633 16.0067 5.40841ZM12.1405 4.45133C11.9063 4.51966 11.623 4.60758 11.34 4.70508V4.52966C11.34 4.00258 11.2717 3.573 11.1446 3.23091C11.6325 3.28966 11.9355 3.82675 12.1405 4.45133ZM10.5488 3.33841C10.6759 3.6705 10.7638 4.13925 10.7638 4.78341V4.88091C10.2367 5.04675 9.68004 5.213 9.10379 5.39841C9.42588 4.16841 10.0413 3.563 10.5488 3.33841ZM9.92379 2.733C10.0213 2.733 10.1192 2.77216 10.1971 2.8305C9.50379 3.15258 8.77171 3.963 8.46879 5.60342C8.01004 5.74967 7.57046 5.87675 7.15088 6.01342C7.50254 4.76383 8.38129 2.733 9.92379 2.733Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M15.8605 5.26165C15.8017 5.26165 14.523 5.16415 14.523 5.16415C14.523 5.16415 13.6346 4.27582 13.5271 4.1879C13.4876 4.14873 13.4388 4.12915 13.4 4.12915L12.6871 18.8333L17.8426 17.7204C17.8426 17.7204 16.0363 5.49623 16.0263 5.40832C15.9871 5.3204 15.9188 5.28123 15.8605 5.26165Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M10.83 8.2472L10.2154 10.101C10.2154 10.101 9.65834 9.80303 8.99584 9.80303C8.00667 9.80303 7.95876 10.4272 7.95876 10.581C7.95876 11.426 10.1675 11.7526 10.1675 13.7405C10.1675 15.306 9.17834 16.3143 7.84334 16.3143C6.23001 16.3143 5.42334 15.3155 5.42334 15.3155L5.85542 13.8943C5.85542 13.8943 6.70042 14.6243 7.41126 14.6243C7.88167 14.6243 8.07376 14.2593 8.07376 13.9905C8.07376 12.886 6.26834 12.838 6.26834 11.0135C6.26834 9.48637 7.36334 8.00762 9.58167 8.00762C10.4071 7.99762 10.83 8.2472 10.83 8.2472Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "Shopify"
);
