import { RouterProvider } from "react-router-dom";
import createRoutes from "./routes/routesUnauthenticated";

function AppUnauthenticatedRoutes({
  onAuthenticated,
}: {
  onAuthenticated: () => void;
}) {
  const routes = createRoutes({ onAuthenticated });
  return <RouterProvider router={routes} />;
}

export default AppUnauthenticatedRoutes;
