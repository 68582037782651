import { NotificationMessageProps } from "@/types/api/notifications";

function computeNotificationsUpdates({
  loaded,
  displayed,
  silenced,
}: {
  loaded: NotificationMessageProps[];
  displayed: NotificationMessageProps[];
  silenced: string[];
}) {
  const displayedIds = displayed.map((not) => not.id);
  const knownIds = [...displayedIds, ...silenced];
  const idsToHide: string[] = [];

  const fresh = loaded.filter(
    (notification) => !knownIds.includes(notification.id)
  );
  const newEmailConfirmationNotice = fresh.find(
    (notification) => notification.accountIssueType === "EMAIL_NOT_CONFIRMED"
  );

  if (newEmailConfirmationNotice) {
    const currentNotice = displayed.find(
      (notification) => notification.accountIssueType === "EMAIL_NOT_CONFIRMED"
    );
    if (currentNotice && currentNotice.id !== newEmailConfirmationNotice.id) {
      idsToHide.push(currentNotice.id);
    }
  }
  return {
    hasChanged: fresh.length > 0 || idsToHide.length > 0,
    news: fresh,
    staleIds: idsToHide,
  };
}

export { computeNotificationsUpdates };
