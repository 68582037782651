import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M9 4H7V11C7 13.7614 9.23858 16 12 16C14.7614 16 17 13.7614 17 11V4H15V11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11V4Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path d="M19 20V18H5V20H19Z" xmlns="http://www.w3.org/2000/svg" />
  </g>,
  "Underline"
);
