import PageTitle from "@/components/PageTitle";
import React from "react";
import { Outlet } from "react-router-dom";

function GeneratedRevenue() {
  return (
    <>
      <PageTitle title="Generated revenue" />
      <Outlet />
    </>
  );
}

export default GeneratedRevenue;
