import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M3 5L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 5C21 3.89543 20.1046 3 19 3L15 3L15 5L19 5L19 19L5 19L5 5L9 5L9 3L5 3C3.89543 3 3 3.89543 3 5Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M8 8L11 8L11 16L13 16L13 8L16 8L12 3L8 8Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "Share2"
);
