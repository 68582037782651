import { ClickAwayListener, Tooltip } from "@mui/material";
import * as React from "react";
import { variantBasedBackgroundColor } from "./helpers";
import TooltipContent from "./TooltipContent";
import { InfoTooltipProps } from "./types";

function InfoTooltip(props: InfoTooltipProps) {
  const { message, children, variant, noInfoIcon = false } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen((old) => !old);
  }, []);

  const contentRef = React.createRef<any>();

  const handleClickAway = React.useCallback(
    (event: any) => {
      if (!contentRef?.current?.contains(event.target)) setOpen(false);
    },
    [contentRef]
  );

  const enrichedChildren = React.cloneElement(children, {
    onClick: handleOpen,
  });
  return (
    <ClickAwayListener onClickAway={handleClickAway} touchEvent={false}>
      <Tooltip
        title={
          <TooltipContent
            message={message}
            ref={contentRef}
            onClose={handleOpen}
            variant={variant}
            noInfoIcon={noInfoIcon}
          />
        }
        componentsProps={{
          popper: {
            sx: {
              "& .MuiTooltip-arrow": {
                color: variantBasedBackgroundColor(variant),
              },
            },
          },
        }}
        open={open}
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        sx={{ padding: 4 }}
      >
        {enrichedChildren}
      </Tooltip>
    </ClickAwayListener>
  );
}

export default InfoTooltip;
