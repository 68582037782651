import { GetAccountSubscriptionResponse } from "@/types/api/accountSubscription";
import { Subscription } from "./types";

export function subscriptionMapper(
  initialSubscription: Subscription,
  updatedSubscription?: Subscription,
  currentSubscription?: GetAccountSubscriptionResponse
): Subscription {
  const { price, name, discountDescription, couponCode, ...other } =
    initialSubscription;

  const {
    price: updatedPrice,
    discountDescription: updatedDiscountDescription,
    couponCode: updatedCouponCode,
  } = updatedSubscription ?? {
    price,
    discountDescription,
    couponCode,
  };

  let currentPlan = false;
  if (currentSubscription) {
    const { couponCode: currentCouponCode } = currentSubscription;

    currentPlan =
      couponCode === currentCouponCode &&
      currentCouponCode === updatedCouponCode;
  }

  return {
    ...other,
    currentPlan,
    isChanged: updatedCouponCode !== couponCode,
    name,
    price: updatedPrice,
    discountDescription: updatedDiscountDescription || discountDescription,
    couponCode: updatedCouponCode,
  };
}
