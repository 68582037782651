import { Box, Button } from "@mui/material";
import React from "react";
import { useColorPickerDialog } from "@/dialogs/ColorPickerDialog";
import { ColorPreferences } from "@tiny/mui/ColorInput/types";
import ColorCircles from "@/components/ColorCircles";

export interface ColorPickerProps {
  colors: ColorPreferences;
  onColorSave: (updatedColors: ColorPreferences) => void;
  selectedPrimaryColorRef: React.MutableRefObject<string>;
  selectedSecondaryColorRef: React.MutableRefObject<string>;
}

function ColorPicker(props: ColorPickerProps) {
  const {
    colors,
    onColorSave,
    selectedPrimaryColorRef,
    selectedSecondaryColorRef,
  } = props;

  const { onChangeColorClick } = useColorPickerDialog(
    colors,
    onColorSave,
    selectedPrimaryColorRef,
    selectedSecondaryColorRef
  );

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Button variant="text" size="small" onClick={onChangeColorClick}>
        Change colors
      </Button>
      <ColorCircles
        primaryColor={colors.primaryColor}
        secondaryColor={colors.secondaryColor}
      />
    </Box>
  );
}

export default ColorPicker;
