import React from "react";
import { useCampaignPage } from "./CampaignPage.hooks";

type ContextValue = ReturnType<typeof useCampaignPage>;

const CampaignContext = React.createContext<ContextValue | null>(null);

export function useCampaignPageView(): ContextValue {
  const contextValue = React.useContext(CampaignContext);
  if (!contextValue) {
    throw Error("Should be used inside the CampaignPageProvider");
  }
  return contextValue;
}

export const CampaignPageProvider = CampaignContext.Provider;
