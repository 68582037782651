import React, { ReactElement } from "react";
import { createTinyTheme } from "@tiny/theme";
import { ThemeProvider } from "@mui/material";
import { AppProvider } from "./App.context";
import { useApp } from "./App.hooks";
import { TinyCampaignBuilderProps } from "./App.types";
import CampaignPage from "./pages/CampaignPage";
import { ConfigProvider } from "./Config.context";
import { useConfig } from "./Config.hooks";
import { QueryClient, QueryClientProvider } from "react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function AppQueryClientProvider({ children }: { children: React.ReactNode }) {
  const queryClient = React.useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: Infinity,
          cacheTime: 0,
          retry: false,
          refetchOnWindowFocus: false,
        },
        mutations: {},
      },
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}

export const TinyCampaignBuilder = ({
  config: importedConfig,
}: TinyCampaignBuilderProps): ReactElement => {
  const appView = useApp();
  const einsteinTheme = createTinyTheme();
  const { config, setConfig } = useConfig();

  return (
    <AppQueryClientProvider>
      <ThemeProvider theme={einsteinTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ConfigProvider
            value={{
              config: {
                ...config,
                ...importedConfig,
              },
              setConfig,
            }}
          >
            <AppProvider value={appView}>
              <CampaignPage />
            </AppProvider>
          </ConfigProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </AppQueryClientProvider>
  );
};
