import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { authStorage } from "@tiny/utils";
import { applyAxiosUnauthorizedHandler } from "./axiosAddons";
import authApi from "./authApi";
import GeneratedApi, { ContentType, FullRequestParams } from "./GeneratedApi";
import qs from "qs";

class Api extends GeneratedApi {
  instance;
  accountStatusChangedHandler?: () => void;
  constructor(baseURL: string) {
    super();
    this.instance = axios.create({
      baseURL,
    });
    applyAxiosUnauthorizedHandler(this);
    this.instance.interceptors.request.use((config: any) => {
      const accessToken = authStorage.getAccessToken();
      const token = localStorage.getItem("accessToken") || accessToken;
      if (config.headers) {
        config.headers.Authorization = token ? `Bearer ${token}` : "";
      }
      return config;
    });
    this.instance.interceptors.response.use(undefined, (error: any) => {
      return Promise.reject(error);
    });
  }

  public setAccountStatusChangedHandler(handler: () => void) {
    this.accountStatusChangedHandler = handler;
  }

  public getUserIp() {
    return this.request<{ ipv4: string; ipv6: string }>({
      path: "ip",
      method: "GET",
      baseURL: new URL("/", baseUrl).toString(),
      type: ContentType.Json,
      timeout: 2000,
    })
      .then(({ data }) => data.ipv6 ?? data.ipv4)
      .catch(() => "");
  }

  private static preparePath(path: string): string {
    if (path.startsWith("/")) {
      const controller = path.substring(1).split("/")[0];
      switch (controller) {
        case "segment":
        case "customer":
        case "customer-event":
        case "customer-action":
          return `/customerservice${path}`;
        case "tone":
        case "merge-tag":
        case "content":
          return `/einsteinservice${path}`;
        case "form":
        case "form-template":
          return `/formservice${path}`;
        case "automation":
          return `/automationservice${path}`;
        case "html-template":
        case "campaign":
        case "account-html-template":
        case "campaign-layout":
        case "template-tag":
          return `/campaignservice${path}`;
        default:
          return `/clientservice${path}`;
      }
    } else {
      return path;
    }
  }

  async request<T = any>({
    path,
    method,
    body,
    type,
    query,
    format,
    headers,
    baseURL,
  }: FullRequestParams): Promise<AxiosResponse<T>> {
    const options: AxiosRequestConfig = {
      url: Api.preparePath(path),
      method,
      responseType: format,
      headers,
      paramsSerializer: function (params: any) {
        return qs.stringify(params, { indices: false, encode: false });
      },
    };

    if (type === ContentType.FormData && body && typeof body === "object") {
      const formData = new FormData();
      for (const key in body) {
        const item: any = body[key as keyof typeof body];
        if (item instanceof File) {
          formData.append(key, item);
        } else {
          const blob = new Blob([JSON.stringify(item)], {
            type: "application/json",
          });
          formData.append(key, blob);
        }
      }
      options.data = formData;
    } else {
      if (body) {
        options.data = body;
      }
      if (query) {
        options.params = query;
      }
    }

    if (baseURL) {
      options.baseURL = baseURL;
    }

    return this.instance.request(options);
  }

  silentReAuth() {
    return authApi.silentReAuth();
  }
}

let baseUrl = import.meta.env.VITE_APP_API_URL;

const api = new Api(baseUrl);

export default api;
