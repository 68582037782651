import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M11.875 9.33333C11.2537 9.33333 10.75 8.811 10.75 8.16667C10.75 7.52233 11.2537 7 11.875 7C12.4963 7 13 7.52233 13 8.16667C13 8.811 12.4963 9.33333 11.875 9.33333Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M19 14H10L12.25 10.8889L13 11.6667L15.25 8.55556L19 14Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 18H8C6.89543 18 6 17.1046 6 16V4C6 2.89543 6.89543 2 8 2H20C21.1046 2 22 2.89543 22 4V16C22 17.1046 21.1046 18 20 18ZM8 4V16H20V4H8Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M4 8H2V20C2 21.1046 2.89543 22 4 22H16V20H4V8Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "ImageAlt"
);
