import React from "react";
import {
  Button,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";

function SuccessDialogContent({
  onClickHandler,
  title,
  description,
}: {
  onClickHandler: () => void;
  title: string;
  description: string;
}) {
  return (
    <DialogContent
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1.5rem",
        padding: "0.5rem",
      }}
    >
      <Typography variant="h2">{title}</Typography>
      <DialogContentText
        id="contentGenerationSuccess"
        sx={{ textAlign: "center" }}
        variant="p2"
        color="grey.100"
      >
        {description}
      </DialogContentText>
      <Button variant="contained" onClick={onClickHandler}>
        Ok
      </Button>
    </DialogContent>
  );
}

export default SuccessDialogContent;
