import React from "react";
import { useAsyncValue } from "react-router-dom";
import { Grid } from "@mui/material";
import { GetAudienceOverviewResponse } from "@/types/api/reportsOverview";
import UniqueUser from "@/assets/illustrations/unique-user.svg";
import NewUser from "@/assets/illustrations/new-user.svg";
import StatisticsCard from "@/components/MetricCard/StatisticsCard";
import { audiencesMetricMapper } from "./mapper";

function AudiencesMetrics() {
  const data = useAsyncValue() as GetAudienceOverviewResponse;
  const mappedAudienceMetrics = audiencesMetricMapper(data);

  const firstTwoAudienceMetrics = mappedAudienceMetrics.slice(0, 2);
  const lastTwoAudienceMetrics = mappedAudienceMetrics.slice(2);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <StatisticsCard
          metrics={firstTwoAudienceMetrics}
          image={UniqueUser}
          infoMessage="Total customers in the application and collected customers created since the application was installed."
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StatisticsCard
          metrics={lastTwoAudienceMetrics}
          image={NewUser}
          infoMessage="Total unique subscribers in the application and collected unique subscribers created since the application was installed."
        />
      </Grid>
    </>
  );
}

export default AudiencesMetrics;
