export const accountTabs = [
  {
    label: "AI Tuning",
    route: `ai-tuning`,
    id: "account-settings-tab-ai-tuning",
  },
  {
    label: "Hero Image",
    route: `hero-image`,
    id: "account-settings-tab-hero-image",
  },
  {
    label: "Brand kit",
    route: `brand-kit`,
    id: "account-settings-tab-brand-kit",
  },
  {
    label: "Profile",
    route: `profile`,
    id: "account-settings-tab-profile",
  },
  {
    label: "Plans",
    route: `plans`,
    id: "account-settings-tab-plans",
  },
  {
    label: "Sender details",
    route: `sender-details`,
    id: "account-settings-tab-sender-details",
  },
  {
    label: "DKIM",
    route: `dkim`,
    id: "account-settings-tab-dkim",
  },
];
