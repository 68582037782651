import * as React from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ChevronBigDown } from "@tiny/icons";
import { useSearchParams } from "react-router-dom";

const defaultOptions = [
  { key: "", value: "", label: "Show All" },
  { key: "imageType", value: "HERO", label: "Selected" },
];

export interface FilterOption {
  key: string;
  value: string;
  label: string;
}

function PageFilter({ sortOptions }: { sortOptions?: FilterOption[] }) {
  const options = sortOptions?.length ? sortOptions : defaultOptions;
  const [filter, setFilter] = React.useState(defaultOptions[0]);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Select
      labelId="hero-image-filter"
      id="hero-image-filter"
      value={JSON.stringify(filter)}
      name="limit"
      IconComponent={ChevronBigDown}
      size="small"
      variant="outlined"
      className="page-filter"
      onChange={(event: SelectChangeEvent<string>) => {
        const option = JSON.parse(event?.target?.value);
        if (option) {
          searchParams.delete(filter?.key);
          if (option?.key) searchParams.set(option?.key, option?.value);
          searchParams.set("page", "1");
          setSearchParams(searchParams);
          setFilter(option);
        }
      }}
    >
      {options?.map((option: FilterOption) => (
        <MenuItem key={option?.key} value={JSON.stringify(option)}>
          {option?.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default PageFilter;
