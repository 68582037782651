import type { TemplateProps } from "@/components/TemplateEditor/types";
import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";

const automationTemplateLoader = async ({
  params,
  request,
}: LoaderFunctionArgs) => {
  const templateDataPromise = fetchApi<TemplateProps>(
    "/automationservice/automation-layout",
    {
      signal: request.signal,
      method: "PUT",
      body: JSON.stringify({ automationId: params.id }),
    }
  );

  return defer({ templateData: await templateDataPromise });
};

export default automationTemplateLoader;
