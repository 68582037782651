import * as React from "react";
import { useLoaderData } from "react-router-dom";
import { Grid } from "@mui/material";
import CampaignMetrics from "@/components/Campaign/CampaignMetrics";
import { CampaignsList } from "@/components/CampaignsList";
import { StatisticsLoaderProps } from "./loader";
import { CampaignsContext, ViewType } from "../Context";

function Statistics() {
  const { campaignsReports, campaignsSent, campaigns } =
    useLoaderData() as StatisticsLoaderProps;

  const [previousView, setPreviousView] = React.useState<ViewType>(
    ViewType.CARD
  );

  const { view, setView } = React.useContext(CampaignsContext);

  React.useEffect(() => {
    setPreviousView(view);
    setView(ViewType.TABLE);
    return () => {
      setView(previousView);
    };
  }, []);

  return (
    <CampaignsList
      campaigns={campaigns}
      component={
        <Grid
          container
          sx={{
            pb: 6,
            pt: 2,
          }}
          id="statistics-section"
          spacing={{ xs: 3, md: 5 }}
          columns={{ xs: 12, md: 12 }}
        >
          <CampaignMetrics
            campaignsReports={campaignsReports}
            campaignsSent={campaignsSent}
          />
        </Grid>
      }
    />
  );
}

export default Statistics;
export { StatisticsLoader } from "./loader";
