import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M11.1725 3.7029C11.347 3.43102 11.2737 3.06344 11.0096 2.88594L9.83148 2.09407C9.57246 1.91996 9.22685 1.99434 9.05572 2.26098L7.59692 4.53404L9.87852 5.71908L11.1725 3.7029Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M13.1771 7.43234L14.2451 5.76815C14.4196 5.49627 14.3463 5.12869 14.0822 4.95119L12.9041 4.15931C12.6451 3.98521 12.2995 4.05958 12.1283 4.32623L10.8955 6.24725L13.1771 7.43234Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M14.4661 10.8578L13.4331 10.3808C13.1136 10.2333 12.9989 9.82256 13.1928 9.52033L15.2008 6.39141C15.3719 6.12477 15.7175 6.0504 15.9766 6.2245L16.9121 6.85337C17.1762 7.03087 17.2495 7.39845 17.075 7.67032L15.1645 10.6473C15.0121 10.8848 14.7171 10.9737 14.4661 10.8578Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M19.7425 8.75561L18.8069 8.12678C18.5479 7.95268 18.2023 8.02705 18.0311 8.29369L16.318 10.9631C16.1241 11.2653 16.2388 11.6761 16.5583 11.8236L17.5913 12.3005C17.8424 12.4164 18.1373 12.3275 18.2897 12.09L19.9053 9.57253C20.0798 9.30073 20.0065 8.93315 19.7425 8.75561Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M17.1328 13.3714C17.3506 13.472 17.5818 13.523 17.8199 13.523C18.2937 13.523 18.7387 13.3181 19.0559 12.965V14.68C19.0559 15.8385 18.5018 16.9139 17.5739 17.5565C17.3645 17.7015 17.223 17.7998 17.223 17.7998C17.0666 17.9086 16.9726 18.0912 16.9726 18.2868V21.4142C16.9726 21.7378 16.7201 22.0001 16.4086 22.0001H7.87785C7.51092 22.0001 7.24164 21.6419 7.33065 21.2721L8.36211 16.9862C8.40303 16.8162 8.36854 16.6361 8.26814 16.4953L4.1108 10.6652C3.96919 10.4666 3.96276 10.197 4.09475 9.99142L6.69018 5.94714C6.84797 5.70128 7.15733 5.61593 7.41176 5.74808L11.975 8.1182C12.2784 8.27582 12.3802 8.67407 12.192 8.96731L11.8469 9.50505C11.7423 9.66806 11.5662 9.76598 11.3776 9.76598H7.81491C7.49434 9.76598 7.23622 10.0438 7.25149 10.3802C7.26578 10.6954 7.52713 10.9379 7.83085 10.9379H10.2439L12.8586 16.37C12.9944 16.6522 13.3201 16.7866 13.5978 16.6589C13.8943 16.5226 14.0181 16.1586 13.8747 15.8607L11.5052 10.9379H11.8617L17.1328 13.3714Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "BlackLivesMatter"
);
