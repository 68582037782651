import React from "react";
import {
  usePutActivityFindFilterQuery,
  usePutActivityTemplateEmailFindFilterQuery,
} from "../../../app/useGeneratedApi";
import useBreakpoints from "../../../hooks/useBreakpoints";

interface UseTemplateSectionQueryOptions {
  searchKeyword?: string;
  favorited?: boolean;
  enabled?: boolean;
  tagIds?: string[];
  refetchSections?: string[];
}

export function useTemplateSectionQuery(
  options: UseTemplateSectionQueryOptions
) {
  const { searchKeyword, favorited, tagIds, enabled, refetchSections } =
    options;

  const { mdUp } = useBreakpoints();

  const {
    data: {
      content: savedTemplates = [],
      totalElements: totalSaved = 0,
      last: isLastSaved,
    } = {},
    isSuccess: isSavedSuccess,
    refetch: refetchSaved,
    remove: removeSaved,
    isLoading: isLoadingSaved,
  } = usePutActivityFindFilterQuery(
    {
      editorType: "DRAG_AND_DROP",
      isFavorite: favorited,
      searchNameText: searchKeyword,
      tagIds,
    },
    {
      page: 0,
      size: 3,
      sort: ["updatedAt,DESC"],
    },
    {
      enabled,
    }
  );

  const {
    data: {
      content: staffPicksTemplates = [],
      totalElements: totalStaffPicks = 0,
      last: isLastStaffPicks,
    } = {},
    isSuccess: isStaffPicksSuccess,
    refetch: refetchStaffPicks,
    remove: removeStaffPicks,
    isLoading: isLoadingStaffPicks,
  } = usePutActivityTemplateEmailFindFilterQuery(
    {
      isFavorite: favorited,
      searchNameText: searchKeyword,
      types: ["STAFF_PICKED"],
      tagIds,
    },
    {
      page: 0,
      size: mdUp ? (savedTemplates.length > 0 ? 3 : 6) : 3,
      sort: ["updatedAt,DESC"],
    },
    {
      enabled: enabled && (!mdUp || isSavedSuccess),
    }
  );

  const {
    data: {
      content: brandedTemplates = [],
      totalElements: totalBranded = 0,
      last: isLastBranded,
    } = {},
    isSuccess: isBrandedSuccess,
    refetch: refetchBranded,
    remove: removeBranded,
    isLoading: isLoadingBranded,
  } = usePutActivityTemplateEmailFindFilterQuery(
    {
      isFavorite: favorited,
      searchNameText: searchKeyword,
      types: ["CUSTOM"],
      tagIds,
    },
    {
      page: 0,
      size: mdUp ? 6 : 3,
      sort: ["updatedAt,DESC"],
    },
    {
      enabled,
    }
  );

  const {
    data: {
      content: basicTemplates = [],
      totalElements: totalBasic = 0,
      last: isLastBasic,
    } = {},
    isSuccess: isBasicSuccess,
    refetch: refetchBasic,
    remove: removeBasic,
    isLoading: isLoadingBasic,
  } = usePutActivityTemplateEmailFindFilterQuery(
    {
      isFavorite: favorited,
      searchNameText: searchKeyword,
      types: ["BASIC"],
      tagIds,
    },
    {
      page: 0,
      size: mdUp ? 6 : 3,
      sort: ["updatedAt,DESC"],
    },
    {
      enabled,
    }
  );

  const {
    data: {
      content: libraryTemplates = [],
      totalElements: totalLibrary = 0,
      last: isLastLibrary,
    } = {},
    isSuccess: isLibrarySuccess,
    refetch: refetchLibrary,
    remove: removeLibrary,
    isLoading: isLoadingLibrary,
  } = usePutActivityTemplateEmailFindFilterQuery(
    {
      isFavorite: favorited,
      searchNameText: searchKeyword,
      types: ["LIBRARY"],
      tagIds,
    },
    {
      page: 0,
      size: mdUp ? 6 : 3,
      sort: ["updatedAt,DESC"],
    },
    {
      enabled,
    }
  );

  const refetchTemplates = React.useCallback(async () => {
    return Promise.all([
      refetchSaved(),
      refetchStaffPicks(),
      refetchBranded(),
      refetchBasic(),
      refetchLibrary(),
    ]);
  }, [
    refetchBasic,
    refetchBranded,
    refetchLibrary,
    refetchSaved,
    refetchStaffPicks,
  ]);

  const removeTemplates = React.useCallback(async () => {
    removeBasic();
    removeBranded();
    removeLibrary();
    removeSaved();
    removeStaffPicks();
  }, [
    removeBasic,
    removeBranded,
    removeLibrary,
    removeSaved,
    removeStaffPicks,
  ]);

  React.useEffect(() => {
    refetchSections?.forEach((section) => {
      if (section === "Saved") {
        refetchSaved();
      } else if (section === "Staff picks") {
        refetchStaffPicks();
      } else if (section === "Branded") {
        refetchBranded();
      } else if (section === "Basic") {
        refetchBasic();
      } else if (section === "Library") {
        refetchLibrary();
      }
    });
  }, [
    refetchBasic,
    refetchBranded,
    refetchLibrary,
    refetchSaved,
    refetchSections,
    refetchStaffPicks,
  ]);

  const isEmpty =
    isSavedSuccess &&
    isStaffPicksSuccess &&
    isBrandedSuccess &&
    isBasicSuccess &&
    isLibrarySuccess &&
    savedTemplates.length === 0 &&
    staffPicksTemplates.length === 0 &&
    brandedTemplates.length === 0 &&
    basicTemplates.length === 0 &&
    libraryTemplates.length === 0;

  const shouldShowSavedAndStaffPicks =
    isSavedSuccess &&
    isStaffPicksSuccess &&
    (savedTemplates.length > 0 || staffPicksTemplates.length > 0);
  const shouldShowSaved = isSavedSuccess && savedTemplates.length > 0;
  const shouldShowBranded = isBrandedSuccess && brandedTemplates.length > 0;
  const shouldShowBasic = isBasicSuccess && basicTemplates.length > 0;
  const shouldShowLibrary = isLibrarySuccess && libraryTemplates.length > 0;

  return {
    savedTemplates,
    staffPicksTemplates,
    brandedTemplates,
    basicTemplates,
    libraryTemplates,
    shouldShowSavedAndStaffPicks,
    shouldShowSaved,
    shouldShowBranded,
    shouldShowBasic,
    shouldShowLibrary,
    totalSaved,
    totalStaffPicks,
    totalBranded,
    totalBasic,
    totalLibrary,
    isEmpty,
    isLastSaved,
    isLastStaffPicks,
    isLastBranded,
    isLastBasic,
    isLastLibrary,
    isLoadingBasic,
    isLoadingBranded,
    isLoadingLibrary,
    isLoadingSaved,
    isLoadingStaffPicks,
    refetchTemplates,
    removeTemplates,
  };
}
