import React from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TinyCampaignBuilder } from "@tiny/campaign-builder";
import { useIsLargerThan } from "@tiny/theme/utils";
import ComponentLoader from "@tiny/campaign-builder/src/customComponents/ComponentLoader/ComponentLoader";
import { useConfirmationDialog } from "../useConfirmationDialog.hook";
import useTinyCampaignBuilder from "./useTinyCampaignBuilder";

const TinyCampaignBuilderPage = (): JSX.Element => {
  const { config } = useTinyCampaignBuilder();
  const navigate = useNavigate();
  const { showConfirmationDialog } = useConfirmationDialog();
  const md = useIsLargerThan("md");
  React.useEffect(() => {
    if (!md) {
      showConfirmationDialog(
        "Campaign Builder is not supported in the mobile view.",
        "Cannot Open",
        "Ok",
        () => {
          navigate(-1);
        },
        false
      );
    }
  }, [md]);
  return (
    <Container
      sx={{
        position: "fixed",
        m: 0,
        p: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "grey.20",
        minWidth: "100vw",
        minHeight: "100vh",
      }}
      disableGutters
      maxWidth={false}
    >
      {config.audiences.length ? (
        <TinyCampaignBuilder config={config} />
      ) : (
        <ComponentLoader loading />
      )}
    </Container>
  );
};

export default TinyCampaignBuilderPage;
