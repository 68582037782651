import React from "react";
import { Theme, Tooltip, TooltipProps } from "@mui/material";
import { Box } from "@mui/material";

export interface OverFlowTipProps {
  text?: string;
  bgColor?: "white" | "grey";
  onClick?: () => void;
}

function OverFlowTip(props: OverFlowTipProps): JSX.Element {
  const { text, bgColor = "white", onClick } = props;
  const [hoverStatus, setHover] = React.useState(false);

  const textElementRef = React.useRef<HTMLDivElement>(null);
  const compareSize = () => {
    if (textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  React.useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);
  return (
    <Tooltip
      sx={(theme) => ({
        padding: 16,
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
        fontSize: 14,
        lineHeight: "18px",
      })}
      title={text || ""}
      arrow
      placement="bottom-start"
      disableHoverListener={!hoverStatus}
    >
      <Box
        ref={textElementRef}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: props.onClick ? "pointer" : "initial",
        }}
        onClick={onClick}
      >
        {text}
      </Box>
    </Tooltip>
  );
}

export default OverFlowTip;
