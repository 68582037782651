import { ActionMenu } from "@/components/ActionMenu/types";
import React from "react";
import { useDialogInvokers } from "@tiny/lib";
import { Button, Typography } from "@mui/material";

function useEmailTemplateLayoutActionMenu(info: string) {
  const { requestInfoDialog, exitDialog } = useDialogInvokers();

  const clickInformationHandler = React.useCallback(() => {
    requestInfoDialog(
      <>
        <Typography>{info}</Typography>{" "}
        <Button
          variant="contained"
          fullWidth
          disableElevation
          onClick={exitDialog}
        >
          Okay
        </Button>
      </>
    );
  }, [requestInfoDialog, info, exitDialog]);

  const emailLayoutTemplateMenu: ActionMenu = [
    { name: "Information", onClickAction: clickInformationHandler },
  ];

  return { emailLayoutTemplateMenu };
}

export default useEmailTemplateLayoutActionMenu;
