import { OnBoardingStep } from "@/types/api/statusOverview";
import { matchPath } from "react-router-dom";

const isChargeIdCallback = (pathname: string, searchParams: URLSearchParams) =>
  matchPath("/", pathname) && searchParams.get("charge_id");

/*
  Spot early illegal combinations of current URL and onboardingStep
  Root loader must make use of this to force the redirect directly to the proper
  target, before any other rendering/loader/actions occurs
 */
const computeForcedRedirectURL = (
  currentUrl: string,
  onboardingStep: OnBoardingStep
) => {
  const { pathname, searchParams } = new URL(currentUrl);
  let redirectUrl: string | undefined;
  switch (onboardingStep) {
    case "USER_CREATED":
      if (!matchPath("/onboarding", pathname)) {
        redirectUrl = "/onboarding";
      }
      break;
    case "ACCOUNT_CREATED":
      if (!matchPath("/onboarding/brand-voice", pathname)) {
        redirectUrl = "/onboarding/brand-voice";
      }
      break;
    case "EINSTEINIZED":
      if (
        !matchPath("/onboarding/plan-selection", pathname) &&
        !isChargeIdCallback(pathname, searchParams)
      ) {
        redirectUrl = "/onboarding/plan-selection";
      }
      break;
    case "FINISHED":
      if (
        !matchPath("/main/*", pathname) &&
        !isChargeIdCallback(pathname, searchParams)
      ) {
        redirectUrl = "/main/dashboard/overview";
      }
      break;
    default:
      break;
  }
  return redirectUrl;
};

export { computeForcedRedirectURL };
