import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import { defaultApiQueryParams } from "@/routes/Campaigns/loader";
import { convertDateRangeToFilter } from "@tiny/utils/dateUtils";
import { GetGeneratedOrdersResponse } from "@/types/api/generatedRevenueOrders";

export const ordersTableRevenueLoader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const {
    activities = "",
    searchTextName,
    activityType,
    ...restParams
  } = Object.fromEntries(url.searchParams);
  const apiPage = (parseInt(restParams.page ?? 1, 10) - 1).toString();
  const apiParams = {
    ...defaultApiQueryParams,
    ...restParams,
    page: apiPage,
  };

  const filterValue = restParams.filter ?? "ALL_TIME";
  const activityTypes = (activityType || "CAMPAIGN,AUTOMATION").split(",");

  const { from, to } = convertDateRangeToFilter(filterValue);

  const apiQueryString = new URLSearchParams(apiParams).toString();

  const generatedOrders = fetchApi<GetGeneratedOrdersResponse>(
    `/reportservice/order/find/filter?${apiQueryString}`,
    {
      body: JSON.stringify({
        from,
        to,
        activityTypes,
        activityIds: activities ? activities.split(",") : [],
        searchTextName,
      }),
      method: "PUT",
    }
  );

  return defer({ generatedOrders });
};
