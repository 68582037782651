import React from "react";

export function useApp() {
  const [appInited, setAppInited] = React.useState(true);

  return {
    appInited,
    setAppInited,
  };
}
