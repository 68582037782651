import React, { useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CloseBig, Redo, Upload } from "@tiny/icons";
import { ImageUploadProps } from "./imageUpload.types";

function ImageUpload(props: ImageUploadProps) {
  const { value, onChangeValue, maxSize, acceptedTypes, onError } = props;

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [imageSrc, setImageSrc] = React.useState("data:,");

  const handleDelete = () => {
    if (onChangeValue) {
      onChangeValue("");
    }
  };

  React.useEffect(() => {
    if (!value) {
      return;
    }
    if (typeof value === "string") {
      setImageSrc(value);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(value);
    reader.onload = () => {
      if (typeof reader?.result === "string") {
        setImageSrc(reader?.result);
      }
    };
  }, [value]);

  return (
    <Box>
      <Box>
        <input
          style={{ display: "none" }}
          ref={hiddenFileInput}
          type="file"
          accept={acceptedTypes.join(",")}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
            if (file) {
              if (file.size > maxSize * 1024 * 1024) {
                onError(`File size exceeds the ${maxSize} MB limit.`);
                return;
              }
              if (!acceptedTypes.includes(file.type)) {
                onError(
                  `Unsupported file format. Allowed types: ${acceptedTypes
                    ?.map((at) => at.split("/")[1])
                    ?.join(", ")}.`
                );
                return;
              }
              onChangeValue(file);
            }
          }}
        />
        {!value && (
          <Box
            sx={(theme) => ({
              border: "1px dashed",
              borderColor: theme.palette.grey[60],
              borderRadius: 1,
              height: theme.typography.pxToRem(90),
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            })}
            onClick={() => {
              if (hiddenFileInput?.current) {
                hiddenFileInput?.current?.click();
              }
            }}
            id="brandkit-browse-file-cta"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Upload
                sx={(theme) => ({
                  color: theme.palette.grey[60],
                  height: 30,
                  width: 30,
                })}
              />
              <Typography variant="label2R" lineHeight={2} color="grey.80">
                Browse your computer for your logo.
              </Typography>
            </Box>
          </Box>
        )}
        {value && (
          <Box display="flex">
            <Box
              sx={(theme) => ({
                height: theme.typography.pxToRem(90),
                width: theme.typography.pxToRem(90),
                borderRadius: 3,
                border: ".5px dashed",
                borderColor: theme.palette.grey[60],
                backgroundColor: theme.palette.grey[20],
                marginRight: 2,
              })}
            >
              <img
                height="100%"
                width="100%"
                style={{ objectFit: "cover" }}
                src={imageSrc}
                alt=""
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Button
                variant="text"
                onClick={handleDelete}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  justifyContent: "flex-start",
                }}
                id="brandkit-delete-file-cta"
                startIcon={<CloseBig />}
              >
                Delete
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  if (hiddenFileInput.current) {
                    hiddenFileInput.current.click();
                  }
                }}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  justifyContent: "flex-start",
                }}
                startIcon={<Redo />}
                id="brandkit-replace-file-cta"
              >
                Replace
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ImageUpload;
