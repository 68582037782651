import { usePageSize } from "@/components/PaginationRouted/usePagination.hook";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ChevronBigDown } from "@tiny/icons";
import * as React from "react";

const pageSizeOptions = ["6", "12", "24"];

function PageSize() {
  const [pageSize, onChangeSize] = usePageSize();

  return (
    <Box display="flex" alignItems="center" gap={4}>
      <Select
        labelId="results"
        value={pageSize}
        name="limit"
        IconComponent={ChevronBigDown}
        size="small"
        variant="outlined"
        className="page-size"
        onChange={(event: SelectChangeEvent<string>) => {
          onChangeSize(event.target.value);
        }}
      >
        {pageSizeOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <InputLabel id="results">
        <Typography variant="p2" color="grey.80">
          results
        </Typography>
      </InputLabel>
    </Box>
  );
}

export default PageSize;
