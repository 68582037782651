import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ColorInput from "@tiny/mui/ColorInput";
import { Controller, useFormContext } from "react-hook-form";
import InfoTooltip from "@/components/InfoTooltip";
import { InfoOutline } from "@tiny/icons";

function ColorSection() {
  const { control } = useFormContext();

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        px: { xs: 4, md: 6 },
        py: { xs: 4, md: 4 },
        borderRadius: 2,
        mb: 5,
        border: `0.5px solid ${theme.palette.grey[60]}`,
      })}
    >
      <Typography variant="h4" color="grey.100" sx={{ width: "100%", mb: 4 }}>
        Colors
        <InfoTooltip
          message="Please note that modifying the color in the Brand kit section will automatically reflect the changes in the AI Tuning section, and vice versa."
          variant="black"
          noInfoIcon
        >
          <IconButton sx={{ p: 0, ml: 1 }}>
            <InfoOutline
              sx={(theme) => ({
                fontSize: theme.typography.p1.fontSize,
              })}
            />
          </IconButton>
        </InfoTooltip>
      </Typography>
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={4}>
        <Box flex={1}>
          <Controller
            name="primaryColor"
            control={control}
            defaultValue
            render={({ field: { onChange, value } }) => (
              <ColorInput
                size="medium"
                value={value}
                label="Primary color"
                onChange={onChange}
                format="hex8"
                fullWidth
                id="brandkit-primary-color-selector"
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="secondaryColor"
            control={control}
            defaultValue
            render={({ field: { onChange, value } }) => (
              <ColorInput
                size="medium"
                value={value}
                label="Secondary color"
                onChange={onChange}
                format="hex8"
                fullWidth
                id="brandkit-secondary-color-selector"
              />
            )}
          />
        </Box>
      </Box>
      <Typography variant="p3" color="grey.80" lineHeight={1.5} mt={2}>
        Primary colors are for important design elements, while secondary colors
        complement and add visual interest to the design in email marketing
        templates generated by AI.
      </Typography>
    </Box>
  );
}

export default ColorSection;
