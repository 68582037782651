import { TableHeaderCellsTypes } from "@/components/Table/table.types";
import { EditDetailsCTA } from "@/routes/AccountSettings/SenderDetails/EditDetailsCTA";
import type { SenderDetailsRow } from "@/routes/AccountSettings/SenderDetails/SenderDetails.types";
import { Alert, Box, Button, Theme, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import TinyTable from "@/components/Table";
import SenderDetailsFormDialog from "./SenderDetailsFormDialog";
import { prepareForEdit } from "./utils";

const headerCells: TableHeaderCellsTypes<SenderDetailsRow>[] = [
  { propName: "name", label: "Sender" },
  { propName: "dkimSigned", label: "DKIM status" },
  { propName: "emailConfirmed", label: "Email Status" },
  { propName: "email", label: "Email address" },
  { propName: "address", label: "Address" },
  { propName: "city", label: "City" },
  { propName: "region", label: "State" },
  { propName: "country", label: "Country" },
  { propName: "cta", label: "", width: "4rem" },
];

function SenderDetails() {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const { senderDetails: detailsData } = useRouteLoaderData("main") as {
    senderDetails: SenderDetailsRow;
  };
  const rowsData = useMemo(() => [detailsData], [detailsData]);
  const formattedRows = rowsData.map((row) => ({
    ...row,
    emailConfirmed: row.emailConfirmed ? (
      <Typography variant="label2R" color="blue.125" fontWeight="bold">
        Verified
      </Typography>
    ) : (
      <Typography variant="label2R" color="red.100" fontWeight="bold">
        Not verified
      </Typography>
    ),
    dkimSigned: row.dkimSigned ? (
      <Typography variant="label2R" color="blue.125" fontWeight="bold">
        Verified
      </Typography>
    ) : (
      <Typography variant="label2R" color="red.100" fontWeight="bold">
        Not verified
      </Typography>
    ),
  }));
  return (
    <Box mt={6}>
      {!detailsData.dkimSigned && (
        <Alert
          variant="outlined"
          severity="info"
          sx={(theme: Theme) => ({
            mb: 5,
            borderColor: `${theme.palette.primary[60] as string} !important`,
            "& .MuiAlert-icon": {
              ml: 2,
            },
          })}
          action={
            <Button
              aria-label="Learn More"
              size="small"
              sx={{ mr: 4 }}
              href="https://support.tinyalbert.ai/portal/en/kb/articles/new-email-rules-to-stop-spam"
              target="_blank"
              rel="noreferrer"
              id="campaign-calendar-close-info-alert-msg"
              variant="text"
            >
              Learn More
            </Button>
          }
        >
          <Typography variant="p3">
            Sign DKIM to comply with gmail sending guidelines and get better
            delivery rate.
          </Typography>
        </Alert>
      )}
      <TinyTable
        headerCells={headerCells}
        rowsData={rowsData}
        rows={formattedRows}
        options={{
          currentSort: "name.asc",
          onSort: () => {},
          ActionCTA: EditDetailsCTA,
          showActionAlways: true,
          RowCTA: () => {
            setIsEditOpen(true);
          },
        }}
      />
      <SenderDetailsFormDialog
        open={isEditOpen}
        details={prepareForEdit(rowsData[0])}
        dialogCloseHandler={() => {
          setIsEditOpen(false);
        }}
        ctaId="myaccount-senderdetails-update"
      />
    </Box>
  );
}

export { SenderDetails };
