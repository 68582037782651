import React from "react";
import { TemplateTab } from "./TemplatePanel.types";

export function useTemplatePanel() {
  const [activeTab, setActiveTab] = React.useState<TemplateTab>("dragAndDrop");

  const changeTab = React.useCallback((value: TemplateTab) => {
    setActiveTab(value);
  }, []);

  return {
    changeTab,
    activeTab,
  };
}
