import React, { useContext } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import PageTitle from "@/components/PageTitle";
import PageTabs from "@/components/PageTabs";
import { campaignTabs } from "./helper";
import { CampaignsContext, ViewType } from "./Context";

function TabsHeader() {
  const { view, setView } = useContext(CampaignsContext);

  const handleRadio = (event: React.SyntheticEvent, newValue: string) => {
    setView(newValue as ViewType);
  };

  const location = useLocation();
  const isStatisticsTab =
    location.pathname === "/main/campaigns/overview/statistics";

  return (
    <>
      <PageTitle title="Campaigns" canRefresh />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <PageTabs tabs={campaignTabs} />
        {!isStatisticsTab && (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={view}
              onChange={handleRadio}
            >
              <Box display="flex">
                <FormControlLabel
                  value="CARD"
                  control={<Radio size="small" />}
                  label="Card"
                  id="campaign-calendar-calendar-view"
                />
                <FormControlLabel
                  value="TABLE"
                  control={<Radio size="small" />}
                  label="Table"
                  id="campaign-calendar-list-view"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        )}
      </Box>
    </>
  );
}

export default TabsHeader;
