import React from "react";

let nextId = 1;

function useId(id?: string): string {
  const [result] = React.useState(() => (id ? id : `id${nextId++}`));
  return result;
}

export default useId;
