import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M10 4V11H4V4H2V20H4V13H10V20H12V4H10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M21.7287 10L21.7309 8H14.5V10H18.8784L15.7018 13.2829L17.1087 14.7984C17.3649 14.6798 17.6555 14.6122 17.9634 14.6122C19.0032 14.6122 19.8471 15.3802 19.8471 16.3265C19.8471 17.2728 19.0032 18.0408 17.9634 18.0408C17.0463 18.0408 16.282 17.4442 16.1141 16.6547L14 17.0289C14.3606 18.7216 15.9978 20 17.9634 20C20.1927 20 22 18.3553 22 16.3265C22 14.6348 20.7438 13.2095 19.0323 12.7833L21.7287 10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "HeadingH3"
);
