import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M12.2495 19.26C10.9028 19.26 9.75612 18.9533 8.80946 18.34C7.87612 17.7267 7.22279 16.8867 6.84946 15.82L8.82946 14.66C9.38946 16.2333 10.5495 17.02 12.3095 17.02C13.1761 17.02 13.8228 16.8533 14.2495 16.52C14.6895 16.1733 14.9095 15.7267 14.9095 15.18C14.9095 14.6803 14.7322 14.287 14.3778 14H4V12H20V14H17.0721C17.1637 14.3399 17.2095 14.7199 17.2095 15.14C17.2095 16.4067 16.7495 17.4133 15.8295 18.16C14.9228 18.8933 13.7295 19.26 12.2495 19.26Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M7.76946 10.42C7.87043 10.6279 7.99389 10.8212 8.13984 11H12.8543C12.7586 10.9683 12.657 10.9349 12.5495 10.9C11.4828 10.5667 10.7428 10.2533 10.3295 9.95999C9.92946 9.65332 9.72946 9.23999 9.72946 8.71999C9.72946 8.18666 9.92946 7.76666 10.3295 7.45999C10.7295 7.13999 11.2628 6.97999 11.9295 6.97999C13.3028 6.97999 14.2828 7.63332 14.8695 8.93999L16.8095 7.81999C16.3561 6.85999 15.7095 6.10666 14.8695 5.55999C14.0295 5.01332 13.0495 4.73999 11.9295 4.73999C10.6895 4.73999 9.62946 5.10666 8.74946 5.83999C7.86946 6.55999 7.42946 7.53999 7.42946 8.77999C7.42946 9.40666 7.54279 9.95332 7.76946 10.42Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "Strikethrough"
);
