import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M15 8H9C8.44772 8 8 8.44772 8 9V15C8 15.5523 8.44772 16 9 16H15C15.5523 16 16 15.5523 16 15V9C16 8.44772 15.5523 8 15 8ZM9 6C7.34315 6 6 7.34315 6 9V15C6 16.6569 7.34315 18 9 18H15C16.6569 18 18 16.6569 18 15V9C18 7.34315 16.6569 6 15 6H9Z"
    xmlns="http://www.w3.org/2000/svg"
  />,
  "DummyIconMdLine"
);
