import {
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import { CheckBig, TableHeader, UncheckedCheckbox } from "@tiny/icons";
import React, { useState } from "react";
import { TableHeaderCellsTypes } from "@/components/Table/table.types";
import { Campaign } from "@/types/api/campaigns";

function ActionColCTA({
  tableColsData,
  emitSelection,
  selected,
}: {
  tableColsData: TableHeaderCellsTypes<Campaign>[];
  emitSelection: (selected: TableHeaderCellsTypes<Campaign>) => void;
  selected: TableHeaderCellsTypes<Campaign>[];
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColumnSelect = (column: TableHeaderCellsTypes<Campaign>) => {
    emitSelection(column);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleFilter}
        size="small"
        id="campaign-calendar-column-selector"
      >
        <TableHeader
          sx={(theme: Theme) => ({
            color: theme.palette.primary.main,
            fontSize: theme.typography.h3,
          })}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {tableColsData.map((column: any) => (
          <MenuItem
            key={column.label}
            onClick={() => handleColumnSelect(column)}
            sx={{
              py: 1.5,
            }}
          >
            <Checkbox
              checked={selected.map((s) => s.label).includes(column.label)}
              icon={
                <UncheckedCheckbox
                  sx={(theme: Theme) => ({
                    width: theme.spacing(6),
                    height: theme.spacing(6),
                    color: "common.white",
                  })}
                />
              }
              checkedIcon={
                <CheckBig
                  sx={(theme: Theme) => ({
                    width: theme.spacing(5),
                    height: theme.spacing(5),
                    backgroundColor: "primary.main",
                    borderRadius: 1,
                    color: "common.white",
                  })}
                />
              }
              sx={(theme: Theme) => ({ mr: theme.typography.pxToRem(8) })}
            />
            <Typography variant="p2">{column.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ActionColCTA;
