import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M20.1409 13.358C20.1426 13.603 20.0752 13.8426 19.9479 14.0433C19.8207 14.244 19.64 14.396 19.431 14.4781L15.1415 16.2007L13.5824 20.9371C13.5055 21.1657 13.3672 21.3629 13.1859 21.5021C13.0045 21.6414 12.789 21.716 12.5682 21.716C12.3473 21.716 12.1318 21.6414 11.9505 21.5021C11.7692 21.3629 11.6308 21.1657 11.554 20.9371L9.99411 16.1955L5.70537 14.4781C5.49833 14.3932 5.31977 14.2404 5.19368 14.0402C5.06759 13.8399 5 13.6019 5 13.358C5 13.1141 5.06759 12.8761 5.19368 12.6759C5.31977 12.4756 5.49833 12.3228 5.70537 12.238L9.99884 10.5153L11.554 5.77898C11.6308 5.55034 11.7692 5.35314 11.9505 5.21389C12.1318 5.07464 12.3473 5 12.5682 5C12.789 5 13.0045 5.07464 13.1859 5.21389C13.3672 5.35314 13.5055 5.55034 13.5824 5.77898L15.1422 10.5205L19.431 12.238C19.64 12.32 19.8207 12.472 19.9479 12.6727C20.0752 12.8735 20.1426 13.113 20.1409 13.358Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M4.77703 1.04189C4.83002 0.874716 5.10173 0.876052 5.13532 1.04369C5.30053 1.83159 5.62517 3.01126 6.18257 3.65995C6.739 4.30672 7.79555 4.73636 8.50449 4.96569C8.6555 5.01506 8.64099 5.30993 8.48479 5.35656C7.7522 5.57645 6.65146 5.98998 6.02749 6.62906C5.40492 7.26853 4.95631 8.44404 4.70976 9.22881C4.65677 9.39598 4.38548 9.39311 4.35189 9.22547C4.18669 8.43757 3.86163 7.25944 3.30562 6.61113C2.74961 5.96282 1.69125 5.53434 0.982301 5.305C0.831299 5.25564 0.847612 4.95961 1.0034 4.91452C1.73599 4.69463 2.83533 4.28072 3.45972 3.6401C4.08369 3.00101 4.5309 1.82512 4.77703 1.04189Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "GenerativeAi"
);
