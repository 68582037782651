import * as React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ChevronUp } from "@tiny/icons";
import ChevronDown from "@tiny/icons/generated/ChevronDown";
import { NotificationsControlProps } from "@/components/Notifications/types";

function NotificationsControl({
  collapsed,
  counter,
  toggleHandler,
}: NotificationsControlProps) {
  return (
    <Box
      sx={{
        backgroundColor: "common.white",
        borderRight: { xs: "0px", sm: "1px solid" },
        borderTop: { xs: "0.5px solid", sm: "0px" },
        borderBottom: { xs: "0.5px solid", sm: "0px" },
        borderColor: { xs: "grey.60", sm: "grey.60" },
        borderTopLeftRadius: { xs: 0, sm: 8 },
        borderBottomLeftRadius: { xs: 0, sm: 8 },
        paddingX: { xs: 1, sm: 2 },
        paddingTop: { xs: 1, sm: 2 },
        paddingBottom: { xs: 1, sm: 0 },
        width: { xs: "100%", sm: "auto" },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <IconButton
          onClick={toggleHandler}
          sx={{
            width: 20,
            height: 20,
            color: "grey.100",
          }}
        >
          {collapsed ? <ChevronDown /> : <ChevronUp />}
        </IconButton>
        <Typography variant="label1" color="grey.80">
          {counter}
        </Typography>
      </Box>
    </Box>
  );
}
export default NotificationsControl;
