import React from "react";
import { useTheme, useMediaQuery, Theme } from "@mui/material";
import { TableHeaderCellsTypes } from "@/components/Table/table.types";
import { Campaign } from "@/types/api/campaigns";
import {
  CircleCheck,
  ErrorOutline,
  GlassHour,
  Rocket,
  Block,
  PauseCircleFilled,
  Repeat,
} from "@tiny/icons";
import { CampaignStatusProps } from "./Table/RowHelpers";

export const formatRevenue = (revenue: number) => {
  if (revenue < 100) {
    return "<100$";
  }

  if (revenue < 1000) {
    const lowerBound = Math.floor(revenue / 100) * 100;
    const upperBound = lowerBound + 100;
    return `${lowerBound}$-${upperBound}$`;
  }

  const base = 10 ** Math.floor(Math.log10(revenue));
  const lowerBound = Math.floor(revenue / base) * base;
  const upperBound = lowerBound + base;
  return `${lowerBound}$-${upperBound}$`;
};

export const useResponsiveColCount = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));
  let cols;
  if (isSmall) {
    cols = 7;
  } else if (isMedium) {
    cols = 4;
  } else if (isLarge) {
    cols = 5;
  } else {
    cols = 6;
  }
  return cols;
};

export const getCampaignStatus = ({
  status,
  outdated,
  enabled,
}: CampaignStatusProps) => {
  const statusConfig: {
    [key: string]: {
      bgColor: string;
      icon: JSX.Element;
      label: string;
      status: string;
    };
  } = {
    COMPLETED: {
      bgColor: "green.50",
      icon: (
        <CircleCheck
          sx={(theme: Theme) => ({
            fill: theme.palette.green.main,
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Sent",
      status: "COMPLETED",
    },
    FAILED: {
      bgColor: "green.50",
      icon: (
        <Block
          sx={(theme) => ({
            fill: theme.palette.red[100],
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Failed",
      status: "FAILED",
    },
    GENERATE: {
      bgColor: "fuchsia.50",
      icon: (
        <GlassHour
          sx={(theme: Theme) => ({
            fill: theme.palette.fuchsia.main,
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Generating...",
      status: "GENERATE",
    },
    LAUNCHED: {
      bgColor: "yellow.50",
      icon: (
        <Rocket
          sx={(theme: Theme) => ({
            fill: theme.palette.yellow.main,
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Launched",
      status: "LAUNCHED",
    },
    DRAFT: {
      bgColor: "red.50",
      icon: (
        <ErrorOutline
          sx={(theme: Theme) => ({
            fill: theme.palette.red[100],
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Not Activated",
      status: "INACTIVE",
    },
    ACTIVE: {
      bgColor: "green.50",
      icon: (
        <CircleCheck
          sx={(theme: Theme) => ({
            fill: theme.palette.green.main,
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Activated",
      status: "ACTIVE",
    },
    EXPIRED: {
      bgColor: "grey.20",
      icon: (
        <Block
          sx={(theme: Theme) => ({
            fill: theme.palette.grey[80],
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Expired",
      status: "EXPIRED",
    },
    PAUSED: {
      bgColor: "grey.20",
      icon: (
        <PauseCircleFilled
          sx={(theme: Theme) => ({
            fill: theme.palette.grey[80],
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Paused",
      status: "PAUSED",
    },
    STOPPED: {
      bgColor: "yellow.50",
      icon: (
        <PauseCircleFilled
          sx={(theme: Theme) => ({
            fill: theme.palette.grey[80],
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Stopped",
      status: "STOPPED",
    },
    RECURRING: {
      bgColor: "green.50",
      icon: (
        <Repeat
          sx={(theme: Theme) => ({
            fill: theme.palette.green.main,
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "Recurring",
      status: "RECURRING",
    },
  };

  let statusObj;
  if (outdated) {
    statusObj = statusConfig.EXPIRED;
  } else if (enabled && status !== "LAUNCHED") {
    statusObj = statusConfig.ACTIVE;
  } else {
    statusObj = statusConfig[status];
  }

  return {
    status: statusObj?.status,
    bgColor: statusObj?.bgColor,
    label: statusObj?.label,
    icon: statusObj?.icon,
  };
};

export const headerCellData = [
  { propName: "name", label: "Campaign Name", isSortable: true },
  { propName: "startTime", label: "Scheduled Date", isSortable: true },
  { propName: "status", label: "Status", isSortable: false },
  { propName: "generatedBy", label: "Generated By", isSortable: false },
  { propName: "discount", label: "Has Discount", isSortable: false },
  { propName: "audience", label: "Audience", isSortable: false },
  { propName: "type", label: "Type", isSortable: false },
  {
    propName: "templateLanguage",
    label: "Email Language",
    isSortable: false,
  },
  { propName: "expectedRevenue", label: "Expected Revenue", isSortable: false },
] as TableHeaderCellsTypes<Campaign>[];
