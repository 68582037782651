import React from "react";
import { NavLink } from "react-router-dom";
import { useIsSmallerThen } from "@tiny/theme/utils";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import TooltipWrapper from "../TooltipWrapper";
import { NavigationItemProps } from "./types";

function NavigationItem(props: NavigationItemProps) {
  const {
    to,
    IconActive,
    Icon,
    collapsed,
    name,
    tooltip,
    mobileCloseMenu,
    SecondaryLabel,
  } = props;
  const isMdDown = useIsSmallerThen("sm");
  const theme = useTheme();

  return (
    <>
      <NavLink
        to={to}
        style={({ isActive }) => ({
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          gap: 8,
          color: isActive ? theme.palette.primary.main : theme.palette.grey[80],
          padding: isMdDown ? "1rem 0" : 0,
        })}
        onClick={mobileCloseMenu}
      >
        {({ isActive }) => (
          <Box
            sx={{ px: isMdDown ? 4 : 0, width: "100%" }}
            display="flex"
            alignItems="center"
            gap={2}
          >
            <TooltipWrapper
              key={`${name}-${isActive.toString()}`}
              text={tooltip}
              show={collapsed}
              placement="right"
            >
              {isActive ? <IconActive /> : <Icon />}
            </TooltipWrapper>
            {(isMdDown || !collapsed) && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  variant={isActive ? "label1" : "label1R"}
                  color={isActive ? "grey.100" : "grey.80"}
                >
                  {name}
                </Typography>
                {SecondaryLabel}
              </Box>
            )}
          </Box>
        )}
      </NavLink>
      {isMdDown && <Divider />}
    </>
  );
}

export default NavigationItem;
