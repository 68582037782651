import React from "react";
import { useForm } from "react-hook-form";
import { getTimezone } from "../../../utils/dateUtils";
import { useCampaignPageView } from "../../CampaignPage.context";
import { ScheduleForm } from "./ScheduleDialog.types";
import { isBefore } from "date-fns";

export function useScheduleDialog() {
  const {
    cancelScheduledCampaign,
    details,
    closeScheduleDialog,
    startCampaign,
  } = useCampaignPageView();

  const form = useForm<ScheduleForm>({
    mode: "onChange",
  });

  const { clearErrors, reset, setError, trigger, setValue } = form;

  const [sending, setSending] = React.useState(false);

  const [isRecurringEnabled, setIsRecurringEnabled] = React.useState(false);

  const [scheduling, setScheduling] = React.useState(false);

  const timezone = React.useMemo(() => {
    return getTimezone(new Date().getTimezoneOffset());
  }, []);

  const onChange = React.useCallback(
    (field?: keyof ScheduleForm) => {
      const fields: (keyof ScheduleForm)[] = field ? ["date", field] : ["date"];
      clearErrors(fields);
    },
    [clearErrors]
  );

  const resetDialog = React.useCallback(() => {
    reset();
  }, [reset]);

  const handleSend = React.useCallback(async () => {
    setSending(true);
    try {
      if (details?.id) {
        await startCampaign(details?.id, undefined);
      }
    } finally {
      setSending(false);
      closeScheduleDialog();
    }
  }, [closeScheduleDialog, details?.id, startCampaign]);

  const handleSchedule = React.useCallback(async () => {
    const valid = await trigger();
    if (!valid) {
      return;
    }
    const start = form.getValues().date;
    const days = form.getValues().days;
    const time = form.getValues().time;

    const localTime = form.getValues().time;

    const formattedUtcTime = formatUTCDate(localTime);
    if (days?.length) {
      setScheduling(true);
      try {
        if (details?.id) {
          await startCampaign(details?.id, undefined, days, formattedUtcTime);
        }
      } finally {
        setScheduling(false);
        closeScheduleDialog();
      }
    } else if (
      isBefore(
        new Date(),
        new Date(
          `${start.toISOString().split("T")[0]}T${
            time.toISOString().split("T")[1]
          }`
        )
      )
    ) {
      setScheduling(true);
      try {
        if (details?.id) {
          await startCampaign(
            details?.id,
            new Date(
              `${start.toISOString().split("T")[0]}T${
                time.toISOString().split("T")[1]
              }`
            ).getTime()
          );
        }
      } finally {
        setScheduling(false);
        closeScheduleDialog();
      }
    } else {
      setError("date", {
        type: "manual",
        message: "Scheduled date must be in future!",
      });
    }
  }, [
    closeScheduleDialog,
    details?.id,
    form,
    setError,
    startCampaign,
    trigger,
  ]);

  const handleCancelScheduledCampaign = React.useCallback(async () => {
    setScheduling(true);
    try {
      if (details?.id) {
        await cancelScheduledCampaign(details?.id);
      }
    } finally {
      setScheduling(false);
      closeScheduleDialog();
    }
  }, [cancelScheduledCampaign, closeScheduleDialog, details?.id]);

  React.useLayoutEffect(() => {
    if (details?.days?.length && details?.time) {
      setIsRecurringEnabled(true);
      const timeHours = +details?.time.substring(0, 2);
      const timeMinutes = +details?.time.substring(3, 5);
      const date = new Date();
      date.setUTCHours(timeHours);
      date.setUTCMinutes(timeMinutes);
      setValue("days", details?.days);
      setValue("date", date);
      setValue("time", date);
    }
  }, [details?.time, details?.days, setValue]);

  function formatUTCDate(date: Date) {
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  return {
    campaignStatus: details?.campaignStatus,
    handleCancelScheduledCampaign,
    handleSchedule,
    handleSend,
    form,
    onChange,
    timezone,
    resetDialog,
    sending,
    scheduling,
    isRecurringEnabled,
    setIsRecurringEnabled,
  };
}
