import { applyLocalStorageMock } from "@tiny/utils";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import createTinyTheme from "@tiny/theme/createTheme";
import "@tiny/theme/types";
import { getSentryConfig, isSentryEnabled } from "@tiny/utils/envVars";
import { initializeSentry } from "@tiny/sentry";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import NotificationSnackbarProvider from "./components/Snackbar/snackbar-provider";
import App from "./App";

applyLocalStorageMock();

const einsteinTheme = createTinyTheme();

if (isSentryEnabled()) {
  const config = getSentryConfig();
  initializeSentry({
    ...config,
    instrumentationFns: [
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    ],
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <NotificationSnackbarProvider>
      <ThemeProvider theme={einsteinTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </NotificationSnackbarProvider>
  </React.StrictMode>
);
