import React, { useState } from "react";
import { Campaign } from "@/types/api/campaigns";
import { Box, Menu, Typography, useTheme } from "@mui/material";
import { User } from "@tiny/icons";
import {
  Condition,
  useCampaignMenu,
} from "@/components/Campaign/CampaignMenu/hooks";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem";
import {
  convertUTCTimeStringToLocalTime,
  formatDate,
} from "@tiny/utils/dateUtils";
import { hex2rgb } from "@tiny/utils/colors";
import { getCampaignStatus } from "../helpers";

function MonthViewEvent({ title, event }: { title: string; event: Campaign }) {
  const { status, outdated, enabled, generatedBy, time, startTime } = event;
  const [showMenu, setShowMenu] = useState(false);
  const menuConditionsOverride: Condition[] = [
    {
      conditionName: "Information",
      condition: event.status === "COMPLETED",
    },
    {
      conditionName: "Regenerate",
      condition: false,
    },
  ];
  const { campaignMenu } = useCampaignMenu(event, menuConditionsOverride);
  const { icon, bgColor } = getCampaignStatus({
    status,
    outdated,
    enabled,
  });
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isRecurring = status === "RECURRING" || status === "STOPPED";

  const timeString = isRecurring
    ? convertUTCTimeStringToLocalTime(time || "")
    : formatDate(new Date(startTime), "hh:mm a");

  return (
    <Box
      sx={{ backgroundColor: bgColor, height: 30, p: 2, pr: 0 }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      onClick={(e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setShowMenu(true);
      }}
      id="calendar-view-show-preview"
    >
      <Typography
        variant="label3"
        color="grey.100"
        fontWeight="semibold"
        sx={{ overflow: "hidden", textOverflow: "hidden", width: "70%" }}
      >
        {timeString}&nbsp;
        {title}
      </Typography>
      <Box sx={{ pt: showMenu ? 0 : 1 }}>
        <Menu
          open={showMenu}
          sx={{ mt: 3 }}
          id="action-menu"
          anchorEl={anchorEl}
          onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
            e?.stopPropagation();
            setAnchorEl(null);
            setShowMenu(false);
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            sx: {
              boxShadow: `0px 30px 84px ${hex2rgb(
                theme.palette.grey[80],
                0.08
              )}, 0px 8px 32px ${hex2rgb(
                theme.palette.grey[80],
                0.07
              )}, 0px 3px 14px ${hex2rgb(
                theme.palette.grey[80],
                0.03
              )}, 0px 1px 3px ${hex2rgb(theme.palette.grey[80], 0.13)}`,
              width: theme.spacing(41),
            },
          }}
        >
          {campaignMenu.map((menuAction) => (
            <ActionMenuItem
              key={menuAction.name}
              theme={theme}
              name={menuAction.name}
              onClickAction={menuAction.onClickAction}
              handleCloseMenu={() => {
                setAnchorEl(null);
                setShowMenu(false);
              }}
              id={menuAction.id}
            />
          ))}
        </Menu>
        <Typography variant="label2" sx={{ pr: 2, pt: 1 }}>
          {generatedBy === "User" && (
            <User
              sx={{
                fill: theme.palette.primary[100],
                fontSize: theme.spacing(5),
              }}
            />
          )}
          {icon}
        </Typography>
      </Box>
    </Box>
  );
}

export default MonthViewEvent;
