import * as React from "react";
import { useDrawerManagerState } from "./DrawerManagerState.hooks";
import { DrawerManagerProvider } from "./DrawerManager.context";
import { DrawerManager } from "./DrawerManager";

function TinyDrawerProvider({ children }: { children: React.ReactNode }) {
  const DrawerManagerContextValue = useDrawerManagerState();
  return (
    <DrawerManagerProvider value={DrawerManagerContextValue}>
      <DrawerManager />
      {children}
    </DrawerManagerProvider>
  );
}

export { TinyDrawerProvider };
