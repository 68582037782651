import { TableProps } from "@/components/Table/table.types";
import { Table, TableContainer } from "@mui/material";
import React from "react";
import TableHeader from "@/components/Table/TableHeader";
import TableBody from "@/components/Table/TableBody";

function TinyTable<T extends { [key: string]: any }>({
  headerCells,
  rows,
  rowsData,
  options,
  sx,
}: TableProps<T>) {
  const {
    currentSort,
    onSort,
    isSelectable,
    selectAllHandler,
    isAllSelected,
    isSelected,
    freezeEdgeColumns,
    selectHandler,
    showBorders,
    showActionAlways = false,
    ActionCTA,
    RowCTA,
    ActionColCTA,
  } = options;

  return (
    <TableContainer className="table-wrapper">
      <Table
        sx={[
          { backgroundColor: "common.white", borderRadius: 2 },
          ...(Array.isArray(sx) ? sx : [sx]),
          showBorders && { borderCollapse: "separate" },
        ]}
      >
        <TableHeader
          currentSort={currentSort}
          headerCells={headerCells}
          isSelectable={isSelectable}
          selectAllHandler={selectAllHandler}
          isAllSelected={isAllSelected}
          onSort={onSort}
          ActionCTA={ActionCTA}
          ActionColCTA={ActionColCTA}
          showBorders={showBorders}
          freezeEdgeColumns={freezeEdgeColumns}
        />
        <TableBody
          headerData={headerCells}
          rows={rows}
          rowsData={rowsData}
          isSelectable={isSelectable}
          isAllSelected={isAllSelected}
          isSelected={isSelected}
          selectHandler={selectHandler}
          showActionAlways={showActionAlways}
          ActionCTA={ActionCTA}
          RowCTA={RowCTA}
          showBorders={showBorders}
          freezeEdgeColumns={freezeEdgeColumns}
        />
      </Table>
    </TableContainer>
  );
}

export default TinyTable;
