import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Info2Outline } from "@tiny/icons";
import InfoTooltip from "../../InfoTooltip";

function CardHeader({
  text,
  tooltipMessage,
}: {
  text: string;
  tooltipMessage: string;
}) {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h4" color="grey.100">
        {text}
      </Typography>
      <InfoTooltip message={tooltipMessage} variant="white">
        <IconButton
          size="small"
          sx={(theme) => ({
            p: 0,
            color: theme.palette.grey[60],
          })}
        >
          <Info2Outline sx={{ fontSize: 16 }} />
        </IconButton>
      </InfoTooltip>
    </Box>
  );
}

export default CardHeader;
