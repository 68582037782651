import { Box, SxProps, Typography } from "@mui/material";
import ChevronRight from "@tiny/icons/generated/ChevronRight";
import * as React from "react";
import { useMatches, Link as RouterLink } from "react-router-dom";

interface CrumbMeta {
  to: string;
  name: string;
}

interface RouteHandle {
  crumb: (data: unknown) => CrumbMeta;
}

interface BreadcrumbsProps {
  sx?: SxProps;
}

function Breadcrumbs({ sx }: BreadcrumbsProps) {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => Boolean((match.handle as RouteHandle)?.crumb))
    .map((match) =>
      (match.handle as RouteHandle).crumb({
        data: match.data,
        params: match.params,
      })
    );

  if (crumbs.length === 1) {
    return null;
  }

  return (
    <Box
      sx={[
        { display: "flex", alignItems: "center", fontSize: "12px" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {crumbs.map((crumb, index) => {
        const isLast = index === crumbs.length - 1;
        return (
          <Box key={crumb.name} display="flex" alignItems="center">
            <Typography
              component={RouterLink}
              to={crumb.to}
              color={isLast ? "primary.125" : "grey.80"}
              sx={{ textDecoration: "none" }}
            >
              {crumb.name}
            </Typography>
            {!isLast ? <ChevronRight sx={{ fontSize: "12px" }} /> : null}
          </Box>
        );
      })}
    </Box>
  );
}

export { Breadcrumbs };
