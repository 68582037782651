import { ActionFunctionArgs } from "react-router-dom";
import { fetchApi } from "@tiny/utils";
import { PostChargeSubscriptionResponse } from "@/types/api/chargeSubscription";

async function submitSetupStrategyAction({
  request,
}: ActionFunctionArgs): Promise<{ ok: boolean }> {
  const formData = await request.formData();
  const toneId = formData.get("toneId");
  const languageId = formData.get("languageId");
  const layoutCategory = formData.get("layoutCategory");
  const accountDetails = formData.get("accountDetails");
  const primaryColor = formData.get("primaryColor");
  const secondaryColor = formData.get("secondaryColor");

  return new Promise((resolve) => {
    fetchApi<PostChargeSubscriptionResponse>(
      "/accountservice/account/content/einsteinize",
      {
        method: request.method,
        body: JSON.stringify({
          toneId,
          languageId,
          accountDetails,
          layoutCategory,
          colors: {
            primaryColor,
            secondaryColor,
          },
        }),
        signal: request.signal,
      }
    );
    setTimeout(() => resolve({ ok: true }), 2000);
  });
}

export { submitSetupStrategyAction };
