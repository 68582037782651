import React from "react";
import { Stripo, StripoProvider } from "../../components";
import StepContainer from "../StepContainer";
import TemplatePanel from "./TemplatePanel";
import { useCampaignPageView } from "../CampaignPage.context";
import ComponentLoader from "../../customComponents/ComponentLoader/ComponentLoader";
import { Box, Container } from "@mui/material";

function DesignStep() {
  const {
    handleTemplateChange,
    handleTemplateLoaded,
    handleTemplateUnLoaded,
    hideTemplateHistory,
    selectTemplate,
    stripoRef,
    templateCss,
    templateEditing,
    templateHtml,
    templateEditorType,
    templateHistoryVisible,
    screenLoading,
    setScreenLoading,
  } = useCampaignPageView();
  if (templateEditing) {
    const codeEditorVisible = templateEditorType === "HTML";
    const settingsVisible = templateEditorType === "DRAG_AND_DROP";
    React.useEffect(() => {
      setScreenLoading(true);
    }, []);
    return (
      <StripoProvider accountId="000000" userFullName="Sahil">
        <Container
          sx={(theme) => ({
            minWidth: "100vw",
            height: "100vh",
            position: "relative",
          })}
          disableGutters
        >
          {screenLoading && (
            <Box
              sx={{
                position: "absolute",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                zIndex: 1000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0,0,0,0.5)",
              }}
            >
              <ComponentLoader loading={screenLoading} />
            </Box>
          )}
          <Stripo
            codeEditorVisible={codeEditorVisible}
            css={templateCss ?? ""}
            emailId={"1231323"}
            historyVisible={templateHistoryVisible}
            html={templateHtml ?? ""}
            onTemplateChange={handleTemplateChange}
            onTemplateLoaded={() => {
              handleTemplateLoaded();
            }}
            onTemplateUnloaded={handleTemplateUnLoaded}
            onHistoryHide={hideTemplateHistory}
            ref={stripoRef}
            settingsVisible={settingsVisible}
          />
        </Container>
      </StripoProvider>
    );
  }

  return (
    <StepContainer>
      <TemplatePanel selectTemplate={selectTemplate} />
    </StepContainer>
  );
}

export default DesignStep;
