import { Box } from "@mui/material";
import * as React from "react";
import useResizeObserver from "use-resize-observer";
import { iframeDimensins, getIframeHead, makeNotInteractive } from "./helpers";
import { HtmlPreviewProps } from "./types";

function HtmlPreview(props: HtmlPreviewProps) {
  const {
    html,
    css,
    width,
    height,
    contentWidth,
    borderRadius,
    src = "about:blank",
    title = "Preview",
    interactive = false,
    scrollable = false,
    onLoadHandler,
  } = props;
  const observer = useResizeObserver<HTMLDivElement>();
  const { iframeWidth, iframeHeight, deltaScale } = iframeDimensins(
    observer.width,
    observer.height,
    contentWidth * 16
  );

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    if (iframeRef.current) {
      const dom = document.implementation.createDocument(
        "http://www.w3.org/1999/xhtml",
        "html",
        null
      );

      const customHead = dom.createElement("head");
      customHead.innerHTML = getIframeHead({
        interactive,
        scrollable,
        css,
      });

      if (html) {
        const { body, head } = new DOMParser().parseFromString(
          !interactive ? makeNotInteractive(html) : html,
          "text/html"
        );
        if (head) {
          [...customHead.childNodes].forEach((v) => head.appendChild(v));
          dom.firstChild?.appendChild(head);
        } else {
          dom.firstChild?.appendChild(customHead);
        }
        dom.firstChild?.appendChild(body);
      }

      iframeRef.current.srcdoc = new XMLSerializer().serializeToString(dom);
    }
  }, [css, html, interactive, scrollable]);

  return (
    <Box
      className="html-preview"
      ref={observer.ref}
      position="relative"
      overflow="hidden"
      width={width}
      height={height}
      borderRadius={borderRadius}
    >
      <iframe
        ref={iframeRef}
        src={src}
        title={title}
        onLoad={onLoadHandler}
        style={{
          transformOrigin: "top left",
          border: 0,
          width: iframeWidth,
          height: iframeHeight,
          opacity: width && height ? 1 : 0,
          transform: `scale(${deltaScale})`,
        }}
      />
    </Box>
  );
}

export { HtmlPreview };
