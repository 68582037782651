import React from "react";
import { Link } from "@mui/material";
import { useTinyImporterSample } from "./TinyImporterSample.hooks";

function TinyImporterSample() {
  const { download } = useTinyImporterSample();
  return (
    <Link
      style={{ cursor: "pointer", textDecoration: "none" }}
      onClick={download}
    >
      Download a sample csv file here
    </Link>
  );
}

export default TinyImporterSample;
