import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import { mapGetAutomations } from "@/components/Automation/mapper";
import { GetAutomationsResponse } from "@/types/api/automations";
import { GetReportOverviewResponse } from "@/types/api/reportsOverview";

async function automationsLoader({ request }: LoaderFunctionArgs) {
  const automations = await fetchApi<GetAutomationsResponse>(
    "/automationservice/automation",
    { signal: request.signal }
  ).then((r) => mapGetAutomations(r));

  const automationsEnabled = {
    count: automations?.filter((a) => a?.active)?.length,
  };

  const automationsReports = await fetchApi<GetReportOverviewResponse>(
    "/reportservice/automation/overview",
    { signal: request.signal }
  );

  return defer({ automations, automationsReports, automationsEnabled });
}

export { automationsLoader };
