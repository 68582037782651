import { NotificationMessageProps } from "@/types/api/notifications";
import { Typography } from "@mui/material";
import NotificationMessageCta from "@/components/Notifications/NotificationMessageCta";

function NotificationMessage({
  notification,
}: {
  notification: NotificationMessageProps;
}) {
  const { issueCta, message } = notification;

  if (!issueCta) {
    return (
      <Typography variant="label2R" color="grey.100">
        {message}
      </Typography>
    );
  }

  const { ctaNamePlaceHolder } = issueCta;
  const [before, after] = message.split(ctaNamePlaceHolder);
  return (
    <Typography variant="label2R" color="grey.100">
      {before}
      <NotificationMessageCta meta={issueCta} />
      {after}
    </Typography>
  );
}

export default NotificationMessage;
