import * as React from "react";
import { Box } from "@mui/material";

function MissingColorsMessage() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={4}
      justifyContent="flex-start"
      sx={{
        color: "red.125",
        mt: 3,
        fontWeight: 400,
        fontSize: 12,
      }}
    >
      Colors are required to create template designs
    </Box>
  );
}

export default MissingColorsMessage;
