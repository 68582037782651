import { useScreenLoaderManager } from "./ScreenLoaderManager.context";

const useScreenLoaderInvokers = () => {
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderManager();
  return {
    showScreenLoader,
    hideScreenLoader,
  };
};

export { useScreenLoaderInvokers };
