import * as React from "react";
import type { DrawerProps } from "@mui/material";

const useDrawerManagerState = () => {
  const [drawerVisible, setDrawerVisible] = React.useState<boolean>(false);
  const [drawerProps, setDrawerProps] = React.useState<DrawerProps>({});

  const showDrawer = (props: DrawerProps) => {
    setDrawerProps(props);
    setDrawerVisible(true);
  };

  const hideDrawer = () => {
    setDrawerVisible(false);
    setDrawerProps({});
  };

  return {
    showDrawer,
    hideDrawer,
    drawerVisible,
    drawerProps,
  };
};

export { useDrawerManagerState };
