import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import { GetBrandKitResponse } from "./brandkit.types";

interface BrandkitLoaderProps {
  brandkit: GetBrandKitResponse;
}

const BrandKitLoader = async ({ request }: LoaderFunctionArgs) => {
  const brandkit = await fetchApi<GetBrandKitResponse>(
    `/accountservice/identity`,
    { signal: request?.signal }
  );

  return defer({
    brandkit: await brandkit,
  });
};

export { BrandKitLoader };
export type { BrandkitLoaderProps };
