import React from "react";
import { fetchApi } from "@tiny/utils";
import { Box, Button, Typography } from "@mui/material";
import emailSent from "@/assets/svgs/emailSent.svg";
import { useLoaderData } from "react-router-dom";
import { GetSenderDetailsResponse } from "@/types/api/senderDetails";
import { useDialogInvokers } from "@tiny/lib";

function useSendSenderDetailsNotification() {
  const { senderDetails } = useLoaderData() as {
    senderDetails: GetSenderDetailsResponse;
  };

  const { id, email } = senderDetails;

  const { requestInfoDialog, exitDialog } = useDialogInvokers();

  const handleSendSenderDetailsRequest = React.useCallback(() => {
    fetchApi(`/accountservice/sender-details/${id}/email-confirm-token`, {
      method: "post",
    }).then(() =>
      requestInfoDialog(
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h2" mb={5.5}>
            Email sent
          </Typography>
          <img
            src={emailSent}
            alt="EmailSent"
            style={{ width: "10rem", height: "10rem" }}
          />
          <Typography textAlign="center" variant="p2" my={5.5}>
            Please check your '{email}' inbox and click on the link provided in
            the confirmation email.
          </Typography>
          <Button variant="contained" onClick={exitDialog}>
            Ok
          </Button>
        </Box>
      )
    );
  }, [email, exitDialog, id, requestInfoDialog]);

  return { handleSendSenderDetailsRequest };
}

export { useSendSenderDetailsNotification };
