import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogTitle } from "@tiny/mui";
import React from "react";
import { TemplatePreview } from "../../ContentPreview";

function PreviewDialog({
  open,
  onClose,
  template,
  actions,
  isReady,
}: {
  open: boolean;
  onClose: () => void;
  template: { css: string | undefined; html: string };
  actions: React.ReactNode;
  isReady: boolean;
}) {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Preview</DialogTitle>
      <DialogContent>
        <TemplatePreview
          html={template.html}
          css={template.css}
          isLoading={!isReady}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        {actions}
      </DialogActions>
    </Dialog>
  );
}

export { PreviewDialog };
