import React from "react";
import { useForm } from "react-hook-form";
import { useRefCallBack } from "../../../../hooks/useRefCallBack";
import { useCampaignPageView } from "../../../CampaignPage.context";
import { usePutCampaignContentSourceByIdMutation } from "../../../../app/useGeneratedApi";

export function useRssPanel() {
  const { details, notifyRequireSave, refetchCampaign } = useCampaignPageView();

  const [editing, setEditing] = React.useState(false);

  const {
    mutateAsync: putCampaignContentSourceMutateAsync,
    isLoading: isPutCampaignContentSourceLoading,
  } = usePutCampaignContentSourceByIdMutation({
    onError(error) {
      form.setError("url", {
        type: "manual",
        message: error?.response?.data?.message || "An error occurred",
      });
    },
  });

  const form = useForm<any>({
    defaultValues: { url: details?.contentSourceUrl || "" },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const assignRss = useRefCallBack(
    React.useCallback(
      async (url: string, options?: any) => {
        if (details?.id) {
          await putCampaignContentSourceMutateAsync(
            {
              id: details?.id,
              data: { contentSourceUrl: url },
            },
            options
          );
          await refetchCampaign().then((r) => null);
        }
      },
      [details?.id, putCampaignContentSourceMutateAsync]
    )
  );

  const submitForm = React.useCallback(
    async (values: any) => {
      await assignRss(values?.url || " ");
      setEditing(false);
      notifyRequireSave({ name: "assignUrl", fn: undefined });
    },
    [assignRss, setEditing, notifyRequireSave]
  );

  const save = useRefCallBack(
    React.useCallback(async () => {
      form.handleSubmit(submitForm)();
      setEditing(false);
    }, [form, submitForm])
  );

  const edit = () => {
    setEditing(true);
  };

  const editDisabled = !details?.id;

  React.useLayoutEffect(() => {
    const hasDefaultRss = details?.contentSourceUrl;

    if (!editDisabled && !editing && !hasDefaultRss) {
      edit();
    }
  }, [details?.contentSourceUrl, edit, editDisabled, editing]);

  const closeButtonLabel = details?.contentSourceUrl ? "Edit" : "Add";

  return {
    editing,
    edit,
    editDisabled,
    save,
    form,
    closeButtonLabel,
    submitForm,
  };
}
