import { IconButton, Typography } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@tiny/icons/generated/CloseBig";
import { LoadingButton } from "@mui/lab";
import { useIsSmallerThen } from "@tiny/theme/utils";

interface ConfirmationRequestContentProps {
  confirmClickHandler: () => void;
  closeDialogHandler: () => void;
  isGenerationInProgress: boolean;
  description: string;
}
function ConfirmationRequestContent({
  confirmClickHandler,
  closeDialogHandler,
  isGenerationInProgress,
  description,
}: ConfirmationRequestContentProps) {
  const isMdDown = useIsSmallerThen("sm");
  return (
    <>
      <IconButton
        aria-label="close"
        onClick={closeDialogHandler}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h2">Generate content</Typography>
      <DialogContentText
        id="confirmation-dialog"
        sx={{ textAlign: "center" }}
        variant="p2"
        color="grey.100"
      >
        {description}
      </DialogContentText>
      <LoadingButton
        loading={isGenerationInProgress}
        loadingIndicator="Generating"
        variant="contained"
        fullWidth={isMdDown}
        onClick={confirmClickHandler}
      >
        Yes, generate
      </LoadingButton>
    </>
  );
}

export { ConfirmationRequestContent };
