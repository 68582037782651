import { CustomEvents } from "@/types/Document";
import { RuntimeNotificationMessage } from "@/types/api/notifications";

function emitEvent<T>({ name, data }: { name: string; data: T }) {
  const event = new CustomEvent(name, { detail: data });
  document.dispatchEvent(event);
}

function requestErrorNotification(message: string) {
  requestNotification({ severity: "ERROR", message });
}

function requestNotification(notification: RuntimeNotificationMessage) {
  emitEvent({ name: CustomEvents.RequestNotification, data: notification });
}

function collapseAllNotifications() {
  emitEvent({
    name: CustomEvents.ToggleNotifications,
    data: { collapsed: true },
  });
}
export {
  requestNotification,
  requestErrorNotification,
  collapseAllNotifications,
};
