import { downloadFileData, ResponseHeaders } from "@tiny/utils/fileUtils";
import { getSampleCsvData } from "./TinyImporter.helpers";

export function useTinyImporterSample() {
  const exportHeaders = {
    "content-disposition": "fileName=ImportSample.csv",
  };
  const download = () => {
    downloadFileData(
      new Blob([getSampleCsvData()]),
      exportHeaders as ResponseHeaders
    );
  };

  return {
    download,
  };
}
