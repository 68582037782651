import { Sources } from "@/types/api/generatedRevenueSources";
import { Button, Theme } from "@mui/material";
import { ChevronRight } from "@tiny/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

function ViewOrderActivitiesCTA({ tableRowData }: { tableRowData: Sources }) {
  const navigate = useNavigate();
  const exploreOrdersForActivity = () => {
    navigate(`../orders?activities=${tableRowData.activityId}`);
  };

  return (
    <Button
      onClick={exploreOrdersForActivity}
      sx={(theme: Theme) => ({
        p: 0,
        fontWeight: theme.typography.fontWeightRegular,
        position: "absolute",
        right: theme.spacing(4),
        top: "50%",
        transform: "translateY(-50%)",
        whiteSpace: "nowrap",
      })}
      endIcon={<ChevronRight />}
      variant="text"
    >
      See results
    </Button>
  );
}

export { ViewOrderActivitiesCTA };
