import React from "react";

import { Box } from "@mui/material";
import { TemplateListProps } from "./TemplateList.types";
import { useTemplateList } from "./TemplateList.hooks";
import { TemplateListProvider } from "./TemplateList.context";
import TemplatePreviewDialog from "../TemplatePreviewDialog";
import DragAndDropTab from "./DragAndDropTab";
import CustomCodeTab from "./CustomCodeTab/CustomCodeTab";

function TemplateList(props: TemplateListProps) {
  const templateListView = useTemplateList(props);

  const {
    activeTab,
    activeTemplate,
    attributes,
    boxProps,
    campaignId,
    campaignName,
    closePreview,
    emptyStateMessage,
    mainActionPreview,
    previewMainActionName,
    previewOpen,
    refetchSections,
    selectedTemplate,
    templateData,
    renderWithEjs,
    showSelected,
  } = templateListView;

  return (
    <TemplateListProvider value={templateListView}>
      <Box {...boxProps}>
        {activeTab === "dragAndDrop" && (
          <DragAndDropTab
            emptyStateMessage={emptyStateMessage}
            refetchSections={refetchSections}
            showSelected={showSelected}
          />
        )}
        {activeTab === "customCode" && (
          <CustomCodeTab
            emptyStateMessage={emptyStateMessage}
            refetchSections={refetchSections}
          />
        )}
      </Box>
      <TemplatePreviewDialog
        campaignId={campaignId}
        mainActionName={previewMainActionName}
        onClose={closePreview}
        onMainActionClick={mainActionPreview}
        open={previewOpen}
        renderWithEjs={renderWithEjs}
        templateAttributes={attributes}
        templateData={templateData}
        templateHtml={activeTemplate?.templateHtml}
        templateCss={activeTemplate?.templateCss}
        templateName={
          activeTemplate?.id === selectedTemplate?.id
            ? campaignName
            : activeTemplate?.name
        }
      />
    </TemplateListProvider>
  );
}

export default TemplateList;
