import { Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDialogInvokers } from "@tiny/lib";
import {
  blockOriginalListenersOnNodes,
  Stripo,
  StripoContextProvider,
} from "@tiny/stripo-editor";
import { StripoInstance } from "@tiny/stripo-editor/Stripo.types";
import { fetchApi } from "@tiny/utils";
import * as React from "react";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getStripoAuthToken } from "@tiny/utils/api/stripo";
import { ApiError, SenderNotConfirmedError } from "@tiny/utils/errors";
import SenderDetailsConfirmationDialogContent from "@/components/SenderDetailsConfirmationDialogContent";
import { useConfirmationDialog } from "@/routes/Campaigns/useConfirmationDialog.hook";
import { FormContainer } from "react-hook-form-mui";
import ScreenLoader from "@/components/ScreenLoader/ScreenLoader";
import { TemplateProps, NavigationChipsItemType } from "./types";
import { TemplateEditorTopBar } from "./TemplateEditorTopBar";

// TODO parameterize properly and pass context data
const TemplateEditorDesignPage = () => {
  const [loaderVisible, setLoaderVisible] = React.useState<boolean>(false);
  const showScreenLoader = () => setLoaderVisible(true);
  const hideScreenLoader = () => setLoaderVisible(false);
  const { templateData } = useLoaderData() as {
    templateData: TemplateProps;
  };
  const sm = useMediaQuery("(max-width:600px)");
  const navigation = useNavigation();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { requestMediumDialog } = useDialogInvokers();
  const cancelActionHandler = (): void => {
    showScreenLoader();
    navigate(
      `${
        state?.startPath
          ? (state?.startPath as string)
          : "/main/campaigns/overview"
      }${searchParams ? `?${searchParams.toString()}` : ""}`
    );
  };
  const { showConfirmationDialog } = useConfirmationDialog();
  const [searchParams] = useSearchParams();
  const stripoRef = React.useRef<StripoInstance>(null);

  function removePatternsAtStart(inputString: string) {
    const match = inputString.match(/<\s?html/);
    return match?.index ? inputString.substring(match.index) : inputString;
  }

  const saveTemplateHandler = (): void => {
    showConfirmationDialog(
      "Would you like to save this template ?",
      "Save Template",
      "Save & Continue",
      () => {
        showScreenLoader();
        stripoRef?.current?.getTemplate((html, css) => {
          // to remove anything before the first <html> tag
          const updatedHTML = removePatternsAtStart(html);

          fetchApi("/campaignservice/campaign-layout", {
            method: "POST",
            body: JSON.stringify({ campaignId: id, html: updatedHTML, css }),
          }).then(() =>
            navigate(
              `${
                state?.startPath
                  ? (state?.startPath as string)
                  : "/main/campaigns/overview"
              }${searchParams ? `?${searchParams.toString()}` : ""}`
            )
          );
        });
      },
      true
    );
  };
  const saveAndActivateTemplateHandler = (): void => {
    showConfirmationDialog(
      "Would you like to save and activate this template ?",
      "Save & Activate Template",
      "Save & Activate",
      () => {
        showScreenLoader();
        stripoRef?.current?.getTemplate((html, css) => {
          // to remove anything before the first <html> tag

          const updatedHTML = removePatternsAtStart(html);
          fetchApi("/campaignservice/campaign-layout", {
            method: "POST",
            body: JSON.stringify({ campaignId: id, html: updatedHTML, css }),
          })
            .then(() =>
              fetchApi(`/campaignservice/campaign/${id || ""}/enabled/true`, {
                method: "put",
              })
            )
            .then(() =>
              navigate(
                `${
                  state?.startPath
                    ? (state?.startPath as string)
                    : "/main/campaigns/overview"
                }${searchParams ? `?${searchParams.toString()}` : ""}`
              )
            )
            .catch((error: ApiError) => {
              if (error instanceof SenderNotConfirmedError) {
                requestMediumDialog({
                  titleProps: {},
                  contentProps: {
                    dividers: false,
                    sx: {
                      border: "none",
                    },
                    children: <SenderDetailsConfirmationDialogContent />,
                  },
                });
              }
              hideScreenLoader();
            });
        });
      },
      true
    );
  };

  React.useEffect(() => {
    if (sm) {
      showConfirmationDialog(
        "Editing is not supported in the mobile view.",
        "Cannot Edit",
        "Ok",
        () => {
          navigate(
            `${
              state?.startPath
                ? (state?.startPath as string)
                : "/main/campaigns/overview"
            }${searchParams ? `?${searchParams.toString()}` : ""}`
          );
        },
        false
      );
    }
  }, [sm]);
  React.useEffect(() => {
    if (navigation.state === "idle") {
      showScreenLoader();
    }
  }, [navigation.state]);

  return !sm ? (
    <Container
      sx={(theme) => ({
        position: "fixed",
        m: 0,
        p: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.grey[20],
        minWidth: "100vw",
        minHeight: "100vh",
      })}
      disableGutters
    >
      <ScreenLoader visible={loaderVisible} />
      <FormContainer onSuccess={saveTemplateHandler}>
        <TemplateEditorTopBar
          subject={templateData.subjectLine}
          ctaAction={saveTemplateHandler}
          cancelAction={cancelActionHandler}
          ctaText="Save"
          ctaActivateAction={saveAndActivateTemplateHandler}
          navigationChips={[
            {
              title: "Details",
              type: NavigationChipsItemType.complete,
              navigate: "details",
            },
            {
              title: "Design",
              type: NavigationChipsItemType.active,
              navigate: "design",
            },
          ]}
          type="submit"
          ctaId="campaigns-overview-edit-save-design"
          startPath={`${
            state?.startPath
              ? (state?.startPath as string)
              : "/main/campaigns/overview"
          }`}
        />
      </FormContainer>
      <StripoContextProvider
        value={{
          accountId: "000000",
          emailId: "12344",
          userFullName: "Elvis",
          getAuthToken: getStripoAuthToken,
        }}
      >
        <Stripo
          html={templateData?.html}
          css={templateData?.css ?? ""}
          emailId="1231323"
          settingsVisible
          onTemplateLoaded={() => {
            blockOriginalListenersOnNodes();
            hideScreenLoader();
          }}
          ref={stripoRef}
        />
      </StripoContextProvider>
    </Container>
  ) : (
    <div />
  );
};

export default TemplateEditorDesignPage;
