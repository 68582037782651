import React from "react";
import {
  defaultTemplateAttributes,
  showAudienceDetails,
  showSenderDetails,
  showTinyAlbertBanner,
} from "./defaultConfig";
import { AppConfig } from "./config.types";

export function useConfig() {
  const [config, setConfig] = React.useState<AppConfig>({
    defaultTemplateAttributes,
    showAudienceDetails,
    showSenderDetails,
    showTinyAlbertBanner,
  });
  return {
    config,
    setConfig,
  };
}
