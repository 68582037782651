import React from "react";
import { useIsLargerThan } from "@tiny/theme/utils";
import { Box, Button } from "@mui/material";
import TextSearch from "@/components/TextSearch";
import {
  useDialogInvokers,
  useDrawerInvokers,
  useScreenLoaderInvokers,
} from "@tiny/lib";
import { getUTCDate } from "@tiny/utils/dateUtils";
import { useRevalidator, useSearchParams } from "react-router-dom";
import { fetchApi } from "@tiny/utils";
import { ApiError, SenderNotConfirmedError } from "@tiny/utils/errors";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { Campaign } from "@/types/api/campaigns";
import SenderDetailsConfirmationDialogContent from "@/components/SenderDetailsConfirmationDialogContent";
import TinyDateRangePicker from "@/components/TinyDateRangePicker";
import AddNewCampaign from "@/components/AddNewCampaign";
import { useConfirmationDialog } from "../../useConfirmationDialog.hook";
import AddCampaignForm from "../../CampaignForm";
import SuccessDialogContent from "./SuccessDialogContent";

function CampaignTableHeader({
  selectedCampaigns,
  setSelectedCampaigns,
  setIsAllSelected,
  showActions,
  showDateRange,
}: {
  selectedCampaigns: Campaign[];
  setIsAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>;
  showActions?: boolean;
  showDateRange?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const revalidator = useRevalidator();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { showDrawer } = useDrawerInvokers();
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderInvokers();
  const { requestMediumDialog, requestSmallDialog, exitDialog } =
    useDialogInvokers();

  const handleDelete = () => {
    if (!futureCampaigns?.length) {
      showConfirmationDialog(
        "Expired or Completed campaigns cannot be Deleted",
        "Delete Campaigns",
        "okay",
        () => {},
        false
      );
      return;
    }
    const templateIds = futureCampaigns.map((campaign) => campaign.templateId);
    showConfirmationDialog(
      `Are you sure you want to delete ${
        selectedCampaigns?.length > 1 ? "these campaigns" : "this campaign"
      } from your event calendar?`,
      "Delete campaign",
      "Yes, delete",
      () => {
        showScreenLoader();
        fetchApi(`/campaignservice/campaign`, {
          method: "delete",
          body: JSON.stringify({
            campaignIds: futureCampaigns.map((camp) => camp.id),
            templateIds,
          }),
        })
          .then(() => {
            searchParams.delete("page");
            setSearchParams(searchParams);
            revalidator.revalidate();
            hideScreenLoader();
            if (revalidator.state === "idle") {
              setSelectedCampaigns([]);
              setIsAllSelected(false);
              requestSmallDialog({
                contentProps: {
                  dividers: false,
                  sx: {
                    border: "none",
                  },
                  children: (
                    <SuccessDialogContent
                      title="Successfully Deleted"
                      description={`${futureCampaigns?.length} ${
                        futureCampaigns?.length > 1 ? "campaigns" : "campaign"
                      } got Deleted.`}
                      onClickHandler={() => {
                        exitDialog();
                      }}
                    />
                  ),
                },
              });
            }
          })
          .catch(() => {
            hideScreenLoader();
          });
      },
      true
    );
  };

  const activatedCampaigns = selectedCampaigns.filter(
    (camp) => camp.enabled && camp.status !== "FAILED"
  );
  const deactivatedCampaigns = selectedCampaigns.filter(
    (camp) =>
      !camp.enabled &&
      +new Date(camp.startTime) > +new Date() &&
      camp.status !== "FAILED"
  );
  const futureCampaigns = selectedCampaigns.filter(
    (camp) => +new Date(camp.startTime) > +new Date()
  );

  const toggleActivation = (status: boolean, campaigns: Campaign[]) => {
    showScreenLoader();
    fetchApi(`/campaignservice/campaign/change-enabled`, {
      method: "put",
      body: JSON.stringify({
        enabled: status,
        campaignIds: campaigns.map((s) => s.id),
      }),
    })
      .then(() => {
        revalidator.revalidate();
        if (revalidator.state === "idle") {
          hideScreenLoader();
          setSelectedCampaigns([]);
          setIsAllSelected(false);
          requestSmallDialog({
            contentProps: {
              dividers: false,
              sx: {
                border: "none",
              },
              children: (
                <SuccessDialogContent
                  title={`Successfully ${status ? "activated" : "deactivated"}`}
                  description={`${campaigns?.length} ${
                    campaigns?.length > 1 ? "campaigns" : "campaign"
                  } got ${status ? "activated" : "deactivated"}.`}
                  onClickHandler={() => {
                    exitDialog();
                  }}
                />
              ),
            },
          });
        }
      })
      .catch((error: ApiError) => {
        if (error instanceof SenderNotConfirmedError) {
          requestMediumDialog({
            titleProps: {},
            contentProps: {
              dividers: false,
              sx: {
                border: "none",
              },
              children: <SenderDetailsConfirmationDialogContent />,
            },
          });
        }
        hideScreenLoader();
      });
  };

  const handleBulkActivate = () => {
    const alreadyActivated =
      selectedCampaigns?.length === activatedCampaigns?.length;
    const allExpired =
      (activatedCampaigns?.length || 0) +
        (deactivatedCampaigns?.length || 0) ===
      0;
    const mainCta =
      alreadyActivated || allExpired || deactivatedCampaigns?.length === 0
        ? "Okay"
        : "Activate";

    let message = "";

    if (allExpired) {
      message = "Expired or Completed campaigns cannot be activated";
    } else if (alreadyActivated) {
      message = `Selected ${
        selectedCampaigns?.length > 1 ? "campaigns are" : "campaign is"
      } already active.`;
    } else if (selectedCampaigns?.length === deactivatedCampaigns?.length) {
      message = `${deactivatedCampaigns?.length} ${
        deactivatedCampaigns?.length > 1 ? "Campaigns" : "Campaign"
      } will be activated.`;
    } else {
      const pastCampaignsCount =
        (selectedCampaigns?.length || 0) -
        ((activatedCampaigns?.length || 0) +
          (deactivatedCampaigns?.length || 0));
      const pastMessage =
        pastCampaignsCount > 0
          ? `and ${pastCampaignsCount} ${
              pastCampaignsCount > 1 ? "Campaigns are" : "Campaign is"
            } expired or completed or failed.`
          : "";
      const activateMessage =
        deactivatedCampaigns?.length > 0
          ? `Therefore, ${deactivatedCampaigns?.length} ${
              deactivatedCampaigns?.length > 1 ? "Campaigns" : "Campaign"
            } will be activated.`
          : "Therefore, there is no campaign to activate";
      message = `You have selected ${selectedCampaigns?.length} ${
        selectedCampaigns?.length > 1 ? "Campaigns" : "Campaign"
      }, out of which ${activatedCampaigns?.length} ${
        activatedCampaigns?.length > 1 ? "are" : "is"
      } already active ${pastMessage} ${activateMessage}`;
    }

    showConfirmationDialog(
      message,
      "Activate Campaigns",
      mainCta,
      () => {
        if (
          !(
            alreadyActivated ||
            allExpired ||
            deactivatedCampaigns?.length === 0
          )
        ) {
          toggleActivation(true, deactivatedCampaigns);
        }
      },
      !(alreadyActivated || allExpired || deactivatedCampaigns?.length === 0)
    );
  };

  const handleBulkDeactivate = () => {
    const alreadyDeactivated =
      selectedCampaigns?.length === deactivatedCampaigns?.length;
    const allExpired =
      (activatedCampaigns?.length || 0) +
        (deactivatedCampaigns?.length || 0) ===
      0;
    const mainCta =
      alreadyDeactivated || allExpired || activatedCampaigns?.length === 0
        ? "Okay"
        : "Deactivate";

    let message = "";

    if (allExpired) {
      message = "Expired or Completed campaigns cannot be deactivated";
    } else if (alreadyDeactivated) {
      message = `Selected ${
        selectedCampaigns?.length > 1 ? "campaigns are" : "campaign is"
      } already deactivated.`;
    } else if (selectedCampaigns?.length === activatedCampaigns?.length) {
      message = `${activatedCampaigns?.length} ${
        activatedCampaigns?.length > 1 ? "Campaigns" : "Campaign"
      } will be deactivated.`;
    } else {
      const pastCampaignsCount =
        (selectedCampaigns?.length || 0) -
        ((activatedCampaigns?.length || 0) +
          (deactivatedCampaigns?.length || 0));
      const pastMessage =
        pastCampaignsCount > 0
          ? `and ${pastCampaignsCount} ${
              pastCampaignsCount > 1 ? "Campaigns are" : "Campaign is"
            } expired or completed or failed.`
          : "";
      const activateMessage =
        activatedCampaigns?.length > 0
          ? `Therefore, ${activatedCampaigns?.length} ${
              activatedCampaigns?.length > 1 ? "Campaigns" : "Campaign"
            } will be deactivated.`
          : "Therefore, there is no campaign to Deactivate";
      message = `You have selected ${selectedCampaigns?.length} ${
        selectedCampaigns?.length > 1 ? "Campaigns" : "Campaign"
      }, out of which ${deactivatedCampaigns?.length} ${
        deactivatedCampaigns?.length > 1 ? "are" : "is"
      } already deactivated ${pastMessage} ${activateMessage}`;
    }
    showConfirmationDialog(
      message,
      "Deactivate Campaigns",
      mainCta,
      () => {
        if (
          !(
            alreadyDeactivated ||
            allExpired ||
            activatedCampaigns?.length === 0
          )
        ) {
          toggleActivation(false, activatedCampaigns);
        }
      },
      !(alreadyDeactivated || allExpired || activatedCampaigns?.length === 0)
    );
  };

  const handleAddNew = () => {
    showDrawer({
      children: <AddCampaignForm />,
    });
  };

  const handleDateChange = (scheduledFrom: string, scheduledTo: string) => {
    searchParams.set("scheduled-from", scheduledFrom);
    searchParams.set("scheduled-to", scheduledTo);
    searchParams.delete("page");
    setSearchParams(searchParams);
    setSelectedCampaigns([]);
  };

  const renderSearchAndActions = () => {
    if (selectedCampaigns?.length > 0) {
      return (
        <>
          <Box gap={2} display="flex">
            <Button
              onClick={handleBulkActivate}
              variant="contained"
              size="small"
              sx={{ px: 5 }}
              id="campaign-calendar-activate-campaign"
            >
              Activate
            </Button>
            <Button
              onClick={handleBulkDeactivate}
              variant="outlined"
              size="small"
              sx={{ px: 5 }}
              id="campaign-calendar-deactivate-campaign"
            >
              Deactivate
            </Button>
          </Box>
          <Button
            onClick={handleDelete}
            variant="outlined"
            size="small"
            sx={{ px: 5 }}
            id="campaign-calendar-delete-campaign"
          >
            Delete
          </Button>
        </>
      );
    }
    return (
      <TextSearch
        id="calendar-search-campaigns"
        paramName="searchTextName"
        sx={{
          width: { sm: 320, sx: "100%" },
        }}
      />
    );
  };

  const isMdUp = useIsLargerThan("md");

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={5}
      flexDirection={{ xs: "column", sm: "row" }}
      gap={{ xs: 2, sm: 0 }}
    >
      <Box
        display="flex"
        gap={6}
        flexDirection="row"
        justifyContent={{ xs: "space-between", sm: "flex-start" }}
      >
        {showActions && renderSearchAndActions()}
      </Box>

      <Box display="flex" gap={4} justifyContent="space-between">
        {showDateRange && (
          <TinyDateRangePicker
            selectedRange={{
              startDate: startOfDay(new Date()),
              endDate: endOfDay(addDays(new Date(), 60)),
            }}
            buttonLabel="Next 60 days"
            onChange={({
              startDate,
              endDate,
            }: {
              startDate: Date;
              endDate: Date;
            }) => {
              handleDateChange(
                getUTCDate(startOfDay(startDate)),
                getUTCDate(endOfDay(endDate))
              );
              setIsAllSelected(false);
              setSelectedCampaigns([]);
            }}
          />
        )}
        {isMdUp && <AddNewCampaign />}
      </Box>
    </Box>
  );
}

export default CampaignTableHeader;
