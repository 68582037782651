import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import tinyAlbertLogo from "../../assets/logos/tinyalbert-trademark-normal.svg";
import PageFooter from "../../components/PageFooter";

/**
 * Used for wrapping / and /login pages, when No Auth tokens found
 */
function LayoutUnauthenticated() {
  return (
    <Box
      p={{ xs: 4, md: 6 }}
      component="main"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
    >
      <Box>
        <img src={tinyAlbertLogo} alt="TinyAlbert logo" />
      </Box>

      <Outlet />
      <PageFooter />
    </Box>
  );
}

export default LayoutUnauthenticated;
