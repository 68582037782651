import React from "react";
import { Chip, ChipProps, Tooltip } from "@mui/material";
import {
  prepareChipLabel,
  prepareTooltipTitle,
} from "./ChipWithTooltip.helpers";
interface ChipWithTooltipProps extends ChipProps {
  maxVisibleChars?: number;
  label?: string;
}

function ChipWithTooltip(props: ChipWithTooltipProps) {
  const { maxVisibleChars, label, ...other } = props;
  const title = prepareTooltipTitle(label, maxVisibleChars);
  const chipLabel = prepareChipLabel(label, maxVisibleChars);
  if (title === "") {
    return <Chip {...other} label={chipLabel} sx={{ mx: 0.5 }} />;
  }
  return (
    <Tooltip title={title}>
      <Chip {...other} label={chipLabel} />
    </Tooltip>
  );
}

export default ChipWithTooltip;
