import { GetAutomationsResponse } from "@/types/api/automations";
import AbandonedCart from "@/assets/illustrations/abandoned-cart.svg";
import ThankYouForPurchase from "@/assets/illustrations/thank-you-for-purchase.svg";
import WelcomeSeries10 from "@/assets/illustrations/welcome-series-10.svg";
import WelcomeSeries from "@/assets/illustrations/welcome-series.svg";
import WinbackFlow from "@/assets/illustrations/winback-flow.svg";
import BrowseAbandonment from "@/assets/illustrations/browse-abandonment.svg";
import ProductReview from "@/assets/illustrations/product-review.svg";
import { AutomationInfo } from "./types";

const imageMap: { [key: string]: string } = {
  "Product Review": ProductReview,
  "Welcome Series": WelcomeSeries,
  "Welcome Series 10% Discount": WelcomeSeries10,
  "Win Back Flow": WinbackFlow,
  "Abandoned Cart": AbandonedCart,
  "Thank You For Purchase": ThankYouForPurchase,
  "Browse Abandonment": BrowseAbandonment,
};

export function mapGetAutomations(
  src: GetAutomationsResponse
): AutomationInfo[] {
  const { automations } = src;
  return (
    automations?.map((a) => ({
      id: a.id,
      active: a.enabled,
      name: a.name,
      editable: a.editable,
      description: a.description,
      infoDetails: a.infoDetails,
      enabled: a.enabled,
      comingSoon: a.comingSoon,
      emailTemplate: {
        templateCss: a.emailTemplate?.templateCss ?? "",
        templateHtml: a.emailTemplate?.templateHtml ?? "",
      },
      status: a.status,
      image: imageMap[a.name],
    })) ?? []
  );
}
