import React from "react";
import { Tooltip } from "@mui/material";
import { TruncateTooltipProps } from "./TruncateTooltip.types";

function TruncateTooltip(props: TruncateTooltipProps) {
  const { children, title: titleProp } = props;

  const [open, setOpen] = React.useState(false);

  const handleOpen = (event: React.ChangeEvent<{}>) => {
    const target = event.target as any;
    const hasEllipsis = target.offsetWidth < target.scrollWidth;
    setOpen(hasEllipsis);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const title = titleProp || children?.props.children || "";

  return (
    <Tooltip
      {...props}
      open={open}
      title={title}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      {children}
    </Tooltip>
  );
}

export default TruncateTooltip;
