import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path d="M2 4H5V20H2V4Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M6 4H7V20H6V4Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M11 4H9V20H11V4Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M12 4H14V20H12V4Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M15 4H16V20H15V4Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M20 4H17V20H20V4Z" xmlns="http://www.w3.org/2000/svg" />
    <path d="M21 4H22V20H21V4Z" xmlns="http://www.w3.org/2000/svg" />
  </g>,
  "Barcode"
);
