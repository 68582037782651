import { useContext } from "react";
import { ToastContext } from "./ToastContext";
import { ToastContextType, ToastMessage } from "./ToastService.types";

export const useToast = () => {
  const context = useContext(ToastContext) as ToastContextType;
  if (!context) {
    throw new Error("useToast must be used within a ToastContextProvider");
  }
  const {
    setMessage,
    setAutoHideDuration,
    setOpen,
    setType,
    setHeading,
    setPortalId,
  } = context;

  const showToast = ({
    duration,
    message,
    type,
    heading,
    portalId,
  }: ToastMessage) => {
    setMessage(message);
    setType(type);
    setAutoHideDuration(duration || 3000);
    setOpen(true);
    setHeading(heading || type);
    setPortalId(portalId || "");
  };
  return { showToast };
};
