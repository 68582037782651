import React from "react";
import { MenuItem } from "@mui/material";
import { ActionMenuItemProps } from "@/components/ActionMenu/types";

function ActionMenuItem(props: ActionMenuItemProps) {
  const { theme, name, onClickAction, handleCloseMenu, id } = props;
  return (
    <MenuItem
      sx={{
        mx: theme.spacing(3),
        p: theme.spacing(1),
        borderRadius: 1,
        color: "grey.80",
        "&:hover": {
          backgroundColor: "grey.20",
          color: "grey.100",
        },
        "&:not(:last-child)": {
          mb: 2,
        },
      }}
      disableGutters
      key={name}
      onClick={(e) => {
        e.stopPropagation();
        onClickAction();
        handleCloseMenu();
      }}
      id={id}
    >
      {name}
    </MenuItem>
  );
}

export default ActionMenuItem;
