import { fetchApi } from "@tiny/utils";
import { ActionFunctionArgs, redirect } from "react-router-dom";

type Actions = "regenerateAll" | "enableForm";

async function formsActions(request: ActionFunctionArgs) {
  const formData = await request.request.formData();
  const action = formData.get("_action") as Actions;

  switch (action) {
    case "enableForm":
      return enableForm(formData, request);
    case "regenerateAll":
      return regenerateForms();
    default:
      console.error("no action provided");
  }
}

async function regenerateForms() {
  return fetchApi<any>("/formservice/form/einsteinize", {
    method: "POST",
  }).then(() => ({ ok: true }));
}

async function enableForm(formData: FormData, { request }: ActionFunctionArgs) {
  const id = formData.get("id")?.toString();
  const isActivated = formData.get("isActivated")?.toString();
  if (id && isActivated) {
    return fetchApi(`/formservice/form/${id}/enabled/${isActivated}`, {
      method: "put",
      signal: request.signal,
    }).then(() => true);
  }
  return redirect("/main/forms");
}

export { formsActions };
