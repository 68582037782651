import { authStorage } from "@tiny/utils";

function initializeAuthStorage(url: URL) {
  if (authStorage.isInitialized()) return;

  const accessToken =
    url.searchParams.get("access_token") || authStorage.getAccessToken();
  const refreshToken =
    url.searchParams.get("refresh_token") || authStorage.getRefreshToken();
  const foundTokens = !!accessToken && !!refreshToken;
  if (foundTokens) {
    authStorage.updateTokens({
      access_token: accessToken,
      refresh_token: refreshToken,
    });
  }
}

export { initializeAuthStorage };
