import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import { GetAudienceResponse } from "@/types/api/audience";
import { GetAudienceOverviewResponse } from "@/types/api/reportsOverview";

export const audiencesLoader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const requestParams = Object.fromEntries(url.searchParams);

  const audiences = fetchApi<GetAudienceResponse>(
    `/customerservice/audience?sort=${requestParams.sort ?? "name,asc"}`,
    { signal: request.signal }
  ).then((response) => response.audiences);

  const audiencesGeneralMetrics = fetchApi<GetAudienceOverviewResponse>(
    "/reportservice/audience/overview",
    { signal: request.signal }
  );

  return defer({ audiences, audiencesGeneralMetrics });
};
