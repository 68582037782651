import React from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { ShortRight, ExternalLink, CircleCheck, HelpCircle } from "@tiny/icons";
import useDkim from "./dkim.hooks";

const DKIM = () => {
  const [selectedDomain, setSelectedDomain] = React.useState<string>();
  const [regenerateFlag, setRegenerateFlag] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { domains, fetchDKIMStatus, dkimError, dkimSigned } = useDkim();
  const checkDKIMStatus = async (domain: string) => {
    setLoading(true);
    await fetchDKIMStatus(domain);
    setLoading(false);
  };
  const handleChangeSelectedDomain = async (domain: string) => {
    setSelectedDomain(domain);
    await checkDKIMStatus(domain);
  };
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        display: "flex",
        flexDirection: "column",
        mt: 4,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        borderWidth: 1,
        outlineColor: theme.palette.divider,
      })}
    >
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          py: 5,
          px: 6,
          gap: 1,
        })}
      >
        <Typography variant="h3">DKIM</Typography>
        <Typography
          variant="p2"
          sx={(theme) => ({
            color: theme.palette.grey[80],
          })}
        >
          Improve your deliverability, open rate by signing DKIM. Without DKIM,
          messages sent from your organization or domain are more likely to be
          marked as spam.
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            py: 5,
            px: 6,
            gap: 1,
            maxWidth: "40%",
            [theme.breakpoints.down("md")]: {
              maxWidth: "100%",
            },
          })}
        >
          <Typography variant="h3">Sender domain</Typography>
          <Typography
            variant="p2"
            sx={(theme) => ({
              color: theme.palette.grey[80],
            })}
          >
            DKIM signing occurs on a per email domain basis rather than on
            individual email addresses
          </Typography>
          <Select
            sx={{
              mt: 4,
            }}
            onChange={(e) => handleChangeSelectedDomain(e.target.value)}
            value={selectedDomain}
          >
            {domains?.map((option, index) => (
              <MenuItem
                selected={index === 0}
                value={option.domain}
                key={`option-${index}`}
              >
                {option.domain}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {selectedDomain && (
          <Box
            sx={() => ({
              display: "flex",
              flexDirection: "column",
              width: "100%",
              py: 5,
              px: 6,
              gap: 1,
            })}
          >
            <Typography variant="h3">Checking progress</Typography>
            <Divider sx={{ my: 5 }} />
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 5,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <Box
                  sx={() => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    py: 2,
                  })}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    {dkimSigned ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 2,
                        }}
                      >
                        <CircleCheck color="primary" />
                        <Typography>
                          {selectedDomain} is DKIM verified
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 2,
                        }}
                      >
                        <HelpCircle color="primary" />
                        <Typography>Turn on DKIM for your domain</Typography>
                        <Typography>
                          <a
                            href="https://support.tinyalbert.ai/portal/en/kb/articles/authenticate-your-sending-domain-using-dkim"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Turn on DKIM for your domain"
                          >
                            <ExternalLink
                              sx={(theme) => ({
                                color: theme.palette.grey[80],
                                cursor: "pointer",
                              })}
                            />
                          </a>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Button
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                    onClick={async () => {
                      await checkDKIMStatus(selectedDomain);
                      setRegenerateFlag(true);
                    }}
                  >
                    {regenerateFlag ? (
                      <>
                        <Typography variant="p2">Recheck</Typography>
                        <ShortRight />
                      </>
                    ) : (
                      <>
                        <Typography variant="p2">Check</Typography>
                        <ShortRight />
                      </>
                    )}
                  </Button>
                </Box>
                {dkimError && dkimError?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="label1"
                      fontWeight="semiBold"
                      display="block"
                      sx={(theme) => ({
                        color: theme.palette.red[100],
                      })}
                    >
                      {dkimError}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        window.open(
                          "https://support.tinyalbert.ai/portal/en/kb/articles/authenticate-your-sending-domain-using-dkim",
                          "_blank",
                          "noreferrer"
                        );
                      }}
                    >
                      <ExternalLink />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
export { DKIM };
