import React from "react";
import { Await, useLoaderData } from "react-router-dom";
import TinyCalendar from "./TinyCalendar";
import { CalendarLoaderProps } from "../loader";
import GridHeader from "./GridHeader";

function GridView() {
  const { campaigns } = useLoaderData() as CalendarLoaderProps;
  const [viewDate, setViewDate] = React.useState(new Date());

  return (
    <React.Suspense>
      <Await resolve={campaigns}>
        {() => (
          <>
            <GridHeader
              selectedDate={viewDate}
              updateSelectedDate={(date) => {
                setViewDate(date);
              }}
            />
            <TinyCalendar viewDate={viewDate} />
          </>
        )}
      </Await>
    </React.Suspense>
  );
}

export default GridView;
