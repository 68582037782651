import React from "react";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DayOfWeekProps } from "./DayOfWeekSelector.types";

const DayOfWeekSelector: React.FC<DayOfWeekProps> = ({
  value = [],
  onChange,
  disabled,
}) => {
  const days = [
    { label: "Sun", value: "SUNDAY" },
    { label: "Mon", value: "MONDAY" },
    { label: "Tue", value: "TUESDAY" },
    { label: "Wed", value: "WEDNESDAY" },
    { label: "Thu", value: "THURSDAY" },
    { label: "Fri", value: "FRIDAY" },
    { label: "Sat", value: "SATURDAY" },
  ];

  const handleChange = (day: string, checked: boolean) => {
    let newValue: string[] = [...value];
    if (checked) {
      newValue.push(day);
    } else {
      newValue = newValue.filter((d) => d !== day);
    }
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Grid container spacing={16}>
      {days.map((day) => (
        <Grid
          item
          xs={1}
          key={day.value}
          sx={{
            mt: 3,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={day.value}
                checked={value.includes(day.value)}
                onChange={(e) => handleChange(day.value, e.target.checked)}
                disabled={disabled}
              />
            }
            label={day.label}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DayOfWeekSelector;
