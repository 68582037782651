import React from "react";
import { useDeleteActivityMutation } from "../../../app/useGeneratedApi";
import { TemplateFilter, TemplateTag } from "../TemplateList.types";
import { useTemplateListView } from "../TemplateList.context";
import useInfiniteTemplatesQuery from "../useInfiniteTemplatesQuery";
import { CustomCodeTabProps } from "./CustomCodeTab.types";

export function useCustomCodeTab(props: CustomCodeTabProps) {
  const { refetchSections, emptyStateMessage } = props;

  const { onCreateCustomTemplate } = useTemplateListView();

  const [searchKeyword, setSearchKeyword] = React.useState("");

  const [existTemplatesForAccount, setExistTemplatesForAccount] =
    React.useState(false);

  const [activeFilter, setActiveFilter] =
    React.useState<TemplateFilter>("Saved");

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const [selectedTags, setSelectedTags] = React.useState<TemplateTag[]>([]);

  const [importHtmlVisible, setImportHtmlVisible] = React.useState(false);

  const favorited = activeFilter === "Favorites" ? true : undefined;

  const {
    templates = [],
    fetchMore,
    hasMore,
    total = 0,
    refetch,
    isEmpty,
    loading,
  } = useInfiniteTemplatesQuery(
    true,
    undefined,
    searchKeyword,
    selectedTags,
    favorited,
    "HTML",
    true
  );

  const { mutateAsync: deleteActivityMutateAsync } =
    useDeleteActivityMutation();

  const search = React.useCallback((value: string) => {
    setSearchKeyword(value);
  }, []);

  const deleteSelected = React.useCallback(async () => {
    await deleteActivityMutateAsync({ data: { ids: selectedIds } });
    setSelectedIds([]);
    refetch();
  }, [deleteActivityMutateAsync, refetch, selectedIds]);

  const deselectAll = React.useCallback(() => {
    setSelectedIds([]);
  }, []);

  const updateSelectedTags = React.useCallback((tags?: TemplateTag[]) => {
    setSelectedTags(tags || []);
  }, []);

  const deselectTag = React.useCallback((tagId: string) => {
    setSelectedTags((prev) => {
      return prev.filter((tag) => tag.id !== tagId);
    });
  }, []);

  const toggleSelect = React.useCallback((id?: string) => {
    if (!id) {
      return;
    }
    setSelectedIds((prev) => {
      const index = prev.findIndex((i) => i === id);
      if (index === -1) {
        return [...prev, id];
      } else {
        const next = [...prev];
        next.splice(index, 1);
        return next;
      }
    });
  }, []);

  const isSelected = React.useCallback(
    (id?: string) => {
      if (!id) {
        return false;
      }
      return selectedIds.includes(id);
    },
    [selectedIds]
  );

  const changeActiveFilter = React.useCallback((value: TemplateFilter) => {
    setActiveFilter(value);
  }, []);

  const toggleAll = React.useCallback(
    (value: boolean) => {
      if (value) {
        const ids = templates.map((template) => template.id!);
        setSelectedIds(ids);
      } else {
        setSelectedIds([]);
      }
    },
    [templates]
  );

  const showPasteYourCode = React.useCallback(() => {
    onCreateCustomTemplate("Paste your code", "/* Custom CSS */");
  }, [onCreateCustomTemplate]);

  const uploadImportHtml = React.useCallback(
    (files: File[]) => {
      const file = files?.[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        const html = reader.result as string;
        onCreateCustomTemplate(html, "/* Custom CSS */");
        setImportHtmlVisible(false);
      };
    },
    [onCreateCustomTemplate]
  );

  const openImportHtml = React.useCallback(() => {
    setImportHtmlVisible(true);
  }, []);

  const closeImportHtml = React.useCallback(() => {
    setImportHtmlVisible(false);
  }, []);

  const allSelected = React.useMemo(() => {
    return templates.length === selectedIds.length && templates.length > 0;
  }, [selectedIds.length, templates.length]);

  const allSelectedDisabled = React.useMemo(() => {
    return templates.length === 0;
  }, [templates.length]);

  React.useEffect(() => {
    if (!searchKeyword && total > 0) {
      setExistTemplatesForAccount(true);
    }
  }, [searchKeyword, total]);

  return {
    allSelectedDisabled,
    toggleAll,
    selectedIds,
    allSelected,
    activeFilter,
    changeActiveFilter,
    deleteSelected,
    deselectAll,
    deselectTag,
    existTemplatesForAccount,
    isSelected,
    refetchSections,
    search,
    selectedTags,
    toggleSelect,
    updateSelectedTags,
    isEmpty,
    isLoading: loading,
    emptyStateMessage,
    searchKeyword,
    fetchMore,
    hasMore,
    total,
    templates,
    showPasteYourCode,
    openImportHtml,
    closeImportHtml,
    importHtmlVisible,
    uploadImportHtml,
  };
}
