import { Validators, Fields } from "./TinyImporter.types";

export default function () {
  const emailValidators: Validators = [
    { validate: "required" },
    { validate: "unique" },
    {
      validate: "regex_matches",
      /* eslint quotes: [2, "double", "avoid-escape"] */
      regex:
        '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
      error: "Invalid email.",
    },
  ];
  const nameValidators: Validators = [
    // Note: We have for now removed any restrictions on the validators
    // {
    //   validate: "regex_matches",
    //   /* eslint-disable-next-line */
    //   regex: "^[a-zA-Z0-9 -]*$",
    //   error: "Special characters are not allowed.",
    // },
    // {
    //   validate: "regex_matches",
    //   regex: "^([^0-9]*)$",
    //   error: "Numbers are not allowed.",
    // },
    {
      validate: "regex_matches",
      regex: "^.{0,64}$",
      error: "The maximum number of characters is 64",
    },
  ];
  const dateValidators: Validators = [
    {
      validate: "regex_matches",
      regex:
        "^((0?[1-9]|1[012])[-/.](0?[1-9]|[12][0-9]|3[01])[-/.](19|20)?[0-9]{2})*$",
      error: "The expect format is MM/dd/yyyy",
    },
  ];
  const decimalNumberValidator: Validators = [
    {
      validate: "regex_matches",
      regex: "^([1-9]\\d*|0)(\\.\\d+)?$",
      error: "The value must be a decimal number",
    },
  ];
  const numberValidators: Validators = [
    {
      validate: "regex_matches",
      regex: "^$|^([1-9]\\d*|0)(\\.\\d+)?$|^[0-9]+$",
      error: "The value must be a number",
    },
    {
      validate: "regex_matches",
      regex:
        // eslint-disable-next-line
        `^$|^(?:[0-9]|[1-9][0-9]{0,8}|1[0-9]{9}|20[0-9]{8}|21[0-3][0-9]{7}|214[0-6][0-9]{6}|2147[0-3][0-9]{5}|21474[0-7][0-9]{4}|214748[0-2][0-9]{3}|2147483[0-5][0-9]{2}|21474836[0-3][0-9]|214748364[0-7])(\.[0-9]+)?$`,
      error: "The value must be between 0 and 2147483647",
    },
  ];
  const addressValidators: Validators = [
    {
      validate: "regex_matches",
      regex: "^.{0,128}$",
      error: "The maximum number of characters is 128",
    },
  ];
  const otherValidators: Validators = [
    {
      validate: "regex_matches",
      regex: "^.{0,64}$",
      error: "The maximum number of characters is 64",
    },
  ];
  const fields: Fields = [
    { label: "Email", key: "email", validators: emailValidators },
    { label: "First Name", key: "firstName", validators: nameValidators },
    { label: "Last Name", key: "lastName", validators: nameValidators },
    { label: "Source", key: "source", validators: otherValidators },
    {
      label: "Birthday",
      key: "birthday",
      validators: dateValidators,
      description: "Add date in the format MM/dd/yyyy",
    },
    { label: "Tags", key: "tags", description: "Add tags separated by commas" },
    { label: "Currency", key: "currency", validators: otherValidators },
    { label: "Country", key: "country", validators: otherValidators },
    { label: "State/Province", key: "province", validators: otherValidators },
    { label: "City", key: "city", validators: otherValidators },
    { label: "Address 1", key: "address1", validators: addressValidators },
    { label: "Address 2", key: "address2", validators: addressValidators },
    { label: "Phone number", key: "phone", validators: otherValidators },
    {
      label: "Zip/Postal Code",
      key: "postalCode",
      validators: otherValidators,
    },
    { label: "Company", key: "company", validators: otherValidators },
    {
      label: "Number of orders",
      key: "ordersCount",
      validators: numberValidators,
    },
    {
      label: "Lifetime Value",
      key: "totalSpent",
      validators: decimalNumberValidator,
    },
    {
      label: "Latest order ID",
      key: "lastOrderId",
      validators: otherValidators,
    },
    {
      label: "Last purchased order",
      key: "lastOrderName",
      validators: otherValidators,
    },
    {
      label: "Last purchased date",
      key: "lastOrderDate",
      validators: dateValidators,
      description: "Add date in the format MM/dd/yyyy",
    },
    {
      label: "Last purchased price",
      key: "lastOrderPrice",
      validators: numberValidators,
    },
  ];
  return {
    fields,
  };
}
