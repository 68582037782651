import React from "react";
import { Campaign } from "@/types/api/campaigns";
import { Box, useTheme } from "@mui/material";
import {
  Condition,
  useCampaignMenu,
} from "@/components/Campaign/CampaignMenu/hooks";
import ActionMenu from "@/components/ActionMenu";

function CampaignTableActions({ tableRowData }: { tableRowData: Campaign }) {
  const canThisBeActivated = !!tableRowData?.startTime;
  const outdated = !!(new Date() > new Date(tableRowData?.startTime));
  const failed = tableRowData?.status === "FAILED";
  const menuConditionsOverride: Condition[] = [
    {
      conditionName: "Information",
      condition: tableRowData.status === "COMPLETED",
    },
    {
      conditionName: "Regenerate",
      condition: false,
    },
    {
      conditionName: "Activate",
      condition:
        canThisBeActivated &&
        !outdated &&
        !failed &&
        tableRowData.status === "DRAFT",
    },
    {
      conditionName: "Deactivate",
      condition:
        canThisBeActivated &&
        !outdated &&
        !failed &&
        tableRowData.status === "SCHEDULED",
    },
  ];
  const { campaignMenu } = useCampaignMenu(
    tableRowData || {},
    menuConditionsOverride
  );

  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        maxHeight: theme.spacing(10),
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "flex-start", sm: "center" },
        marginRight: 2,
        p: { xs: 2, sm: 0 },
      }}
      id="campaign-calendar-campaign-action-menu"
    >
      <ActionMenu
        menuActions={campaignMenu}
        sx={{
          marginRight: theme.spacing(4),
          width: theme.spacing(6),
          height: theme.spacing(6),
          position: "absolute",
          zIndex: 1,
          backgroundColor: "common.white",
          border: "0.5px solid",
          borderRadius: 1,
          borderColor: "grey.60",
          "&:hover": {
            backgroundColor: "common.white",
          },
        }}
      />
    </Box>
  );
}

export { CampaignTableActions };
