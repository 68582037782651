import { TableBodyProps } from "@/components/Table/table.types";
import {
  Checkbox,
  TableBody as MuiTableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { CheckBig, UncheckedCheckbox } from "@tiny/icons";
import { useIsLargerThan } from "@tiny/theme/utils";

function TableBody<T extends { [key: string]: any }>(props: TableBodyProps<T>) {
  const {
    rows,
    rowsData,
    headerData,
    isSelectable,
    isAllSelected,
    freezeEdgeColumns,
    showBorders,
    selectHandler,
    isSelected,
    showActionAlways,
    ActionCTA,
    RowCTA,
  } = props;
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState<
    number | undefined
  >();
  const isMdUp = useIsLargerThan("sm");
  const themeObject = useTheme();

  const showRowAction = (index: number) =>
    !isMdUp || showActionAlways || hoveredRowIndex === index;

  const showCheckBox = (index: number) => index === 0 && isSelectable;

  return (
    <MuiTableBody>
      {rows.map((row, rowIndex) => (
        <TableRow
          key={rowIndex}
          hover
          onClick={() => RowCTA?.(rowsData[rowIndex])}
          onMouseEnter={() => {
            setHoveredRowIndex(rowIndex);
          }}
          onMouseLeave={() => setHoveredRowIndex(undefined)}
          role={isSelectable ? "checkbox" : "row"}
          sx={[
            showBorders
              ? { borderBottom: "1px solid", borderColor: "grey.40" }
              : {},
            !isMdUp && {
              display: "flex",
              flexDirection: "column",
            },
            RowCTA
              ? {
                  "&:hover": {
                    cursor: "pointer",
                  },
                }
              : {},
            freezeEdgeColumns
              ? {
                  "&:hover": {
                    backgroundColor: `${themeObject.palette.common.white} !important`,
                  },
                }
              : {},
          ]}
        >
          {headerData.map((cell, index) => (
            <TableCell
              key={cell.propName as string}
              sx={[
                showCheckBox(index)
                  ? {
                      paddingLeft: { sm: 7.5, xs: 4 },
                      whiteSpace: "nowrap",
                    }
                  : {},
                { position: "relative" },
                !isMdUp && {
                  paddingY: 2,
                },
                isMdUp && index === 0 && freezeEdgeColumns
                  ? {
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "common.white",
                      borderBottom: `1px solid ${themeObject.palette.grey[40]}`,
                      borderRight: `1px solid ${themeObject.palette.grey[40]}`,
                    }
                  : {},
                freezeEdgeColumns
                  ? {
                      borderBottom: `1px solid ${themeObject.palette.grey[40]}`,
                    }
                  : {},
              ]}
            >
              {showCheckBox(index) && (
                <Checkbox
                  checked={isAllSelected || isSelected?.(rowsData[rowIndex])}
                  onChange={() => selectHandler?.(rowsData[rowIndex])}
                  icon={<UncheckedCheckbox sx={{ color: "common.white" }} />}
                  sx={(theme) => ({ marginRight: theme.spacing(2) })}
                  checkedIcon={
                    <CheckBig
                      sx={(theme: Theme) => ({
                        width: theme.spacing(5),
                        height: theme.spacing(5),
                        backgroundColor: "primary.main",
                        borderRadius: 1,
                        color: "common.white",
                      })}
                    />
                  }
                />
              )}
              <Typography
                variant="label2R"
                color="grey.80"
                sx={{ display: "inline-block", verticalAlign: "inherit" }}
              >
                {row[cell.propName]}
              </Typography>
            </TableCell>
          ))}
          {ActionCTA && (
            <TableCell
              sx={[
                { position: "relative", p: 2 },
                !isMdUp && {
                  paddingY: 2,
                },
                isMdUp && freezeEdgeColumns
                  ? {
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      backgroundColor: "common.white",
                      borderBottom: `1px solid ${themeObject.palette.grey[40]}`,
                      borderLeft: `1px solid ${themeObject.palette.grey[40]}`,
                    }
                  : {},
              ]}
            >
              {showRowAction(rowIndex) && rowsData[rowIndex] ? (
                <ActionCTA tableRowData={rowsData[rowIndex]} />
              ) : null}
            </TableCell>
          )}
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default TableBody;
