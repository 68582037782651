import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import {
  Campaign,
  GetCampaignResponse,
  DayOfWeek,
} from "@/types/api/campaigns";
import { GetSupportedLanguagesResponse } from "@/types/api/contentDetails";
import { getStartAndEndOfMonth, getUTCDate } from "@tiny/utils/dateUtils";
import { addDays, endOfDay, isBefore, startOfDay } from "date-fns";
import { Pagination } from "@/types/api/pagination";
import { RRule } from "rrule";

type ApiQueryParams = {
  page: string;
  size: string;
  sort: string;
  "scheduled-from"?: string;
  "scheduled-to"?: string;
};

export const defaultApiQueryParamsList: ApiQueryParams = {
  page: "0",
  size: "6",
  sort: "startTime,asc",
  "scheduled-from": getUTCDate(startOfDay(new Date())),
  "scheduled-to": getUTCDate(endOfDay(addDays(new Date(), 60))),
};

export const defaultApiQueryParamsGrid: ApiQueryParams = {
  page: "0",
  size: "100",
  sort: "startTime,asc",
  "scheduled-from": getStartAndEndOfMonth(new Date(), false).from,
  "scheduled-to": getStartAndEndOfMonth(new Date(), true).to,
};

interface CalendarLoaderProps {
  campaigns: GetCampaignResponse;
  languages: GetSupportedLanguagesResponse;
}

const recurringCalendarLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const requestParams = Object.fromEntries(url?.searchParams);
  const apiPage = (parseInt(requestParams.page ?? 1, 10) - 1).toString();

  const paths = url?.pathname.split("/");
  const path = paths[paths.length - 1];
  let defaultApiQueryParams: ApiQueryParams = {} as ApiQueryParams;
  if (path === "list") {
    defaultApiQueryParams = defaultApiQueryParamsList;
  } else if (path === "calendar") {
    defaultApiQueryParams = defaultApiQueryParamsGrid;
  }

  const apiParams = {
    ...defaultApiQueryParams,
    ...requestParams,
    page: apiPage,
  };
  const apiQueryString = new URLSearchParams(apiParams).toString();

  const recurringCampaigns = await fetchApi<Pagination<Campaign>>(
    `/campaignservice/campaign/find/filter?${apiQueryString}`,
    {
      signal: request.signal,
      method: "put",
      body: JSON.stringify({
        campaignStatuses: ["RECURRING", "STOPPED"],
      }),
    }
  );

  const languages = fetchApi<GetSupportedLanguagesResponse>(
    "/accountservice/account/content/supported-languages",
    { signal: request.signal }
  );

  const scheduledFrom = endOfDay(new Date(apiParams["scheduled-from"] || ""));
  const today = endOfDay(new Date());

  const adjustedScheduledFrom = isBefore(scheduledFrom, today)
    ? today
    : scheduledFrom;

  const generateChildEvents = path === "calendar";

  const campaignsData = updateCampaignData(
    { campaigns: recurringCampaigns },
    generateChildEvents,
    adjustedScheduledFrom,
    typeof apiParams["scheduled-to"] === "string"
      ? new Date(apiParams["scheduled-to"])
      : new Date()
  );

  return defer({
    campaigns: campaignsData,
    languages: { languages: await languages },
  });
};

export { recurringCalendarLoader };
export type { CalendarLoaderProps };

const updateCampaignData = (
  campaignsData: GetCampaignResponse,
  generateChildEventsFlag: boolean,
  startDate: Date,
  endDate: Date
) => {
  let recurringContent = [...campaignsData.campaigns.content];
  if (generateChildEventsFlag) {
    recurringContent = recurringContent
      .map((campaign) => generateChildEvents(campaign, startDate, endDate))
      .flatMap((campaigns) => campaigns);
  }
  campaignsData.campaigns.content = recurringContent;
  return campaignsData;
};

const generateChildEvents = (
  campaign: Campaign,
  startDate: Date,
  endDate: Date
) => {
  const daysOfWeekMap: Record<DayOfWeek, any> = {
    SUNDAY: RRule.SU,
    MONDAY: RRule.MO,
    TUESDAY: RRule.TU,
    WEDNESDAY: RRule.WE,
    THURSDAY: RRule.TH,
    FRIDAY: RRule.FR,
    SATURDAY: RRule.SA,
  };

  const selectedDays =
    campaign.days?.map(
      (day) => daysOfWeekMap[day.toUpperCase() as DayOfWeek]
    ) || [];

  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: selectedDays,
    dtstart: new Date(
      Date.UTC(
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate()
      )
    ),
    until: new Date(
      Date.UTC(
        endDate.getUTCFullYear(),
        endDate.getUTCMonth(),
        endDate.getUTCDate()
      )
    ),
  });

  const dates = rule.all();

  const childEvents = dates.map((date) => ({
    ...campaign,
    startTime: date.getTime(),
  }));

  return childEvents;
};
