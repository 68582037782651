import { Box, Button, Typography } from "@mui/material";
import { captureExceptionToSentry } from "@tiny/sentry";
import { fetchApi } from "@tiny/utils";
import * as React from "react";
import { useParams } from "react-router-dom";
import { PostSenderDetailsResponse } from "@/types/api/senderDetails";

function SenderEmailConfirmation() {
  const { token } = useParams();
  const execConfirmationRef = React.useRef(false);
  const [confirmed, setConfirmed] = React.useState<string>();

  React.useEffect(() => {
    if (!execConfirmationRef.current) {
      execConfirmationRef.current = true;
      fetchApi<PostSenderDetailsResponse>(
        "/accountservice/sender-details/confirm-email",
        {
          method: "post",
          body: JSON.stringify({ token }),
        }
      )
        .then((r) => setConfirmed(r.redirectUrl))
        .catch((error) => {
          captureExceptionToSentry(error);
          setConfirmed("");
        });
    }
  }, [token]);

  const title = React.useMemo(
    () => (confirmed ? "Email confirmed" : "Email confirmation failed!"),
    [confirmed]
  );

  const message = React.useMemo(
    () =>
      confirmed
        ? "You are now ready to grow your Shopify store 10x faster through AI-driven messaging with little to no time investment from your end."
        : "Your email has been already confirmed or your confirmation link was expired.",
    [confirmed]
  );

  const onClickHandler = React.useCallback(() => {
    if (confirmed) window.location.href = confirmed;
  }, [confirmed]);

  if (confirmed === undefined) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h2">{title}</Typography>
      <Typography
        maxWidth="26.75rem"
        textAlign="center"
        variant="p1"
        color="grey.80"
        mt={3}
        mb={6}
      >
        {message}
      </Typography>
      {confirmed && (
        <Button variant="contained" onClick={onClickHandler}>
          Go to Dashboard
        </Button>
      )}
    </Box>
  );
}

export default SenderEmailConfirmation;
