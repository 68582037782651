import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 15.416V6C22 4.89543 21.1046 4 20 4H4C2.92853 3.99899 2.04661 4.84255 2 5.913V18C2 19.1046 2.89543 20 4 20H15C15 19.2889 15.1484 18.6125 15.416 18H4V7.868L12 13.2L20 7.868V15C20.7111 15 21.3875 15.1484 22 15.416ZM12 10.8L4.8 6H19.2L12 10.8Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M20.5628 23H19.4373C19.292 23 19.1663 22.9019 19.1352 22.7642L19.0094 22.199C18.8415 22.1276 18.6823 22.0384 18.5347 21.9329L17.9667 22.1084C17.8282 22.1513 17.6776 22.0947 17.605 21.9725L17.041 21.0272C16.9691 20.905 16.9939 20.7507 17.1007 20.6555L17.5413 20.2655C17.5212 20.0888 17.5212 19.9106 17.5413 19.7339L17.1007 19.3448C16.9937 19.2495 16.969 19.0951 17.041 18.9728L17.6037 18.0269C17.6764 17.9047 17.827 17.8481 17.9655 17.891L18.5335 18.0665C18.609 18.0122 18.6875 17.9622 18.7688 17.9165C18.8468 17.8738 18.9271 17.8352 19.0094 17.8007L19.1355 17.2361C19.1665 17.0984 19.292 17.0001 19.4373 17H20.5628C20.7081 17.0001 20.8336 17.0984 20.8646 17.2361L20.992 17.801C21.0788 17.8381 21.1634 17.8799 21.2452 17.9264C21.3217 17.9692 21.3956 18.0162 21.4666 18.0671L22.0349 17.8916C22.1734 17.8489 22.3238 17.9054 22.3964 18.0275L22.9591 18.9734C23.031 19.0956 23.0062 19.2499 22.8995 19.3451L22.4588 19.7351C22.4789 19.9118 22.4789 20.09 22.4588 20.2667L22.8995 20.6567C23.0062 20.7519 23.031 20.9062 22.9591 21.0284L22.3964 21.9743C22.3238 22.0964 22.1734 22.1529 22.0349 22.1102L21.4666 21.9347C21.3947 21.9861 21.32 22.0337 21.2427 22.0772C21.1617 22.1228 21.0781 22.1639 20.9923 22.2005L20.8646 22.7642C20.8335 22.9018 20.708 22.9999 20.5628 23ZM19.9988 18.8C19.3157 18.8 18.762 19.3373 18.762 20C18.762 20.6627 19.3157 21.2 19.9988 21.2C20.6819 21.2 21.2356 20.6627 21.2356 20C21.2356 19.3373 20.6819 18.8 19.9988 18.8Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "Generatedemail"
);
