import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import {
  GetCampaignCountResponse,
  GetCampaignResponse,
} from "@/types/api/campaigns";
import { GetReportOverviewResponse } from "@/types/api/reportsOverview";

interface StatisticsLoaderProps {
  campaignsReports: GetReportOverviewResponse;
  campaignsSent: GetCampaignCountResponse;
  campaigns: GetCampaignResponse;
}

export const defaultApiQueryParams = {
  page: "0",
  size: "6",
  sortParam: "startTime,desc",
};

const StatisticsLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const requestParams = Object.fromEntries(url.searchParams);
  const apiPage = (parseInt(requestParams.page ?? 1, 10) - 1).toString();

  const campaignsSentPromise = fetchApi<GetCampaignCountResponse>(
    `/campaignservice/campaign/completed/true/count`,
    { signal: request.signal }
  );

  const campaignsReportsPromise = fetchApi<GetReportOverviewResponse>(
    "/reportservice/campaign/overview",
    { signal: request.signal }
  );

  const apiParams = {
    ...defaultApiQueryParams,
    ...requestParams,
    page: apiPage,
    sort: requestParams.sort || "",
  };

  const apiQueryString = new URLSearchParams(apiParams).toString();

  const campaignsPromise = fetchApi<GetCampaignResponse>(
    `/campaignservice/campaign?${apiQueryString}`,
    { signal: request.signal }
  );

  return defer({
    campaignsReports: await campaignsReportsPromise,
    campaignsSent: await campaignsSentPromise,
    campaigns: await campaignsPromise,
  });
};

export { StatisticsLoader };
export type { StatisticsLoaderProps };
