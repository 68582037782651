import React from "react";
import { Theme } from "@mui/material";
import { Search } from "@tiny/icons";
import EmptyState from "@/components/EmptyState";

function NoCampaignsFoundMessage() {
  return (
    <EmptyState
      Icon={
        <Search
          sx={(theme: Theme) => ({
            color: "grey.80",
            width: theme.spacing(6),
            height: theme.spacing(6),
          })}
        />
      }
      title="There are no results that match your search."
      message="Please try adjusting your search keywords or filters."
    />
  );
}

export default NoCampaignsFoundMessage;
