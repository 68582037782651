import { BackEndError } from "../api";
import {
  CampaignIsOutdatedError,
  CampaignNotFoundError,
  HandledApiError,
  InvalidCampaignStatusError,
  InvalidCouponCodeError,
  SenderNotConfirmedError,
  UnhandledApiError,
} from "./ApiError";

const ErrorCode2ClassMapper: Record<string, typeof HandledApiError> = {
  "4.1.001": CampaignNotFoundError,
  "4.1.002": CampaignIsOutdatedError,
  "4.1.003": InvalidCampaignStatusError,
  "4.1.004": SenderNotConfirmedError,
  "4.2.001": SenderNotConfirmedError,
  "4.3.001": InvalidCouponCodeError,
};

async function createApiError(response: Response) {
  const { headers, url, status } = response;
  const isJson = headers.get("content-type")?.includes("application/json");

  const responseContent = isJson
    ? ((await response.json()) as BackEndError)
    : await response.text();

  if (!isJson) {
    return new UnhandledApiError("unhandled", status, responseContent, url);
  }

  const errorBody = responseContent as BackEndError;
  const { message, errorCodes } = errorBody;
  let errorCode;
  if (Array.isArray(errorCodes) && errorCodes.length > 0) {
    // TODO: clarify why it's an array
    [{ errorCode }] = errorCodes;
  }
  if (errorCode) {
    const ErrorClass = ErrorCode2ClassMapper[errorCode];
    if (!ErrorClass) {
      return new UnhandledApiError(
        `Unknown errorCode ${errorCode} -> ${message ?? ""}`,
        status,
        errorBody,
        url
      );
    }
    return new ErrorClass(message ?? "", status, errorBody, url);
  }
  return new UnhandledApiError(
    `${message ?? "Unknown api error format"}`,
    status,
    errorBody,
    url
  );
}

export { createApiError };
