import React from "react";
import { MobilePreviewProps } from "./MobilePreview.types";
import HtmlPreview from "../HtmlPreview";
import { Box } from "@mui/material";

function MobilePreview(props: MobilePreviewProps): JSX.Element {
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        width: props.width,
        height: props.height,
        border: `8px solid ${theme.palette.grey[200]}`,
        borderRadius: 6,
        overflow: "hidden",
        padding: 2,
        "& > *": {
          borderRadius: 4,
        },
      })}
    >
      <HtmlPreview {...props} width="100%" height="100%" />
    </Box>
  );
}

export default MobilePreview;
