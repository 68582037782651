import ErrorBoundary from "@/components/ErrorBoundary/index";
import { useRevalidator } from "react-router-dom";

/**
 * Similar to page reload, just without browser refresh
 * it triggers all the loaders for each routes in active path
 * Useful for handling non-permanent api errors
 */
function ErrorBoundaryRevalidation() {
  const revalidator = useRevalidator();
  return <ErrorBoundary reloadHandler={() => revalidator.revalidate()} />;
}

export { ErrorBoundaryRevalidation };
