import { Box, Button, Stack, Theme, Typography } from "@mui/material";
import { ErrorOutline } from "@tiny/icons";
import * as React from "react";
import { useDialogInvokers } from "@tiny/lib";
import { useLoaderData } from "react-router-dom";
import { GetSenderDetailsResponse } from "@/types/api/senderDetails";
import { useSendSenderDetailsNotification } from "@/hooks/useSendSenderDetailsNotification";

function SenderDetailsConfirmationDialogContent() {
  const { exitDialog } = useDialogInvokers();
  const { senderDetails } = useLoaderData() as {
    senderDetails: GetSenderDetailsResponse;
  };
  const { email } = senderDetails;

  const { handleSendSenderDetailsRequest } = useSendSenderDetailsNotification();
  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={3}>
      <ErrorOutline
        sx={(theme: Theme) => ({
          width: theme.spacing(12),
          height: theme.spacing(12),
          color: "red.125",
        })}
      />
      <Stack spacing={2} direction="column" alignItems="center">
        <Typography variant="h2">Email verification required !</Typography>
        <Typography
          textAlign="center"
          my={5.5}
          variant="p2"
          color="grey.80"
          sx={{ display: "flex", alignItems: "center" }}
        >
          You can activate only after confirming email address. Please check
          your inbox associated with {email} for verification email.
        </Typography>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          columnGap: 5,
          mt: 6,
          variant: "outlined",
        }}
      >
        <Button variant="outlined" onClick={exitDialog}>
          Ok
        </Button>
        <Button variant="contained" onClick={handleSendSenderDetailsRequest}>
          Resend Confirmation Email
        </Button>
      </Box>
    </Box>
  );
}

export default SenderDetailsConfirmationDialogContent;
