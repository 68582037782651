import React from "react";
import { useCustomCodeTab } from "./CustomCodeTab.hooks";

type ContextValue = ReturnType<typeof useCustomCodeTab>;

const CustomCodeTabContext = React.createContext<ContextValue | null>(null);

export function useCustomCodeTabView() {
  const contextValue = React.useContext(CustomCodeTabContext);
  if (!contextValue) {
    throw Error("Should be used inside the CustomCodeTabProvider");
  }
  return contextValue;
}

export const CustomCodeTabProvider = CustomCodeTabContext.Provider;
