export const showSenderDetails = false;
export const showTinyAlbertBanner = false;
export const showAudienceDetails = false;
export const defaultTemplateAttributes = {
  attributes: [
    {
      name: "[identity_background_color]",
      value: "identity_background_color",
    },
    {
      name: "[sender_details_email]",
      value: "sender_details_email",
    },
    {
      name: "[identity_body_text_color]",
      value: "#232227",
    },
    {
      name: "[identity_links_color]",
      value: "#22c07e",
    },
    {
      name: "[sender_details_region]",
      value: "sender_details_region",
    },
    {
      name: "[campaign_id]",
      value: "campaign_id",
    },
    {
      name: "[identity_title_font]",
      value: "identity_title_font",
    },
    {
      name: "[account_powered_by_logo]",
      value:
        "https://tinyemail-stage.s3.ca-central-1.amazonaws.com/recources/ui/poweredBy/poweredByTinyEmail.png",
    },
    {
      name: "[identity_paragraph_font]",
      value: "identity_paragraph_font",
    },
    {
      name: "[sender_details_postal_code]",
      value: "sender_details_postal_code",
    },
    {
      name: "[sender_details_country]",
      value: "sender_details_country",
    },
    {
      name: "[account_company_name]",
      value: "account_company_name",
    },
    {
      name: "[sender_details_name]",
      value: "test",
    },
    {
      name: "[account_powered_by_text]",
      value: "account_powered_by_text by",
    },
    {
      name: "[identity_logo]",
      value:
        "https://tinyemail-prod.s3.ca-central-1.amazonaws.com/users/3b9c4a68-23cc-4aeb-a7cb-c3fbe75b4f6f/logos/f55a00e0c1ce438.png",
    },
    {
      name: "[sender_details_city]",
      value: "sender_details_city",
    },
    {
      name: "[identity_buttons_color]",
      value: "#22c07e",
    },
    {
      name: "[ui_application_url]",
      value: "ui_application_url",
    },
    {
      name: "[sender_details_address]",
      value: "sender_details_address",
    },
    {
      name: "[identity_top_menu_color]",
      value: "#22c07e",
    },
    {
      name: "[account_company_site]",
      value: "account_company_site",
    },
  ],
};
