import React from "react";
import { CloseSmall } from "@tiny/icons";
import { Typography, Box, Button, Paper, Divider } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchApi } from "@tiny/utils";
import { SendTestEmailContentProps } from "./types";

const SendTestEmailContent = ({
  setOpen,
  html,
  css,
  subject,
  preview,
}: SendTestEmailContentProps) => {
  const [email, setEmail] = React.useState<string>("");
  const [sentMessage, setSentMessage] = React.useState<string>("");
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const sm = useMediaQuery("(max-width:600px)");
  const formValues = { email };
  const formSubmitHandler = async () => {
    setButtonLoading(true);
    try {
      await fetchApi(`/automationservice/automation-layout/send-test-email`, {
        method: "put",
        body: JSON.stringify({
          html,
          css,
          subjectLine: `[TEST] ${subject}`,
          preview,
          receiver: email,
        }),
      });
      setButtonLoading(false);
    } catch (error: any) {
      if (error.status === 412) {
        setSentMessage(
          "Your daily test email limit has exceeded for today! Please try again tomorrow!"
        );
      } else {
        setSentMessage("Something went wrong. Please try again!");
      }
      return;
    }
    setEmail("");
    setSentMessage("Email sent successfully!");
  };

  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        width: sm ? "100%" : "510px",
        p: 6,
        gap: 5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h2">Send test</Typography>
        <Box
          sx={(theme) => ({
            border: `0.5px solid ${theme.palette.grey[40]}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: 24,
            height: 24,
          })}
          onClick={() => setOpen(false)}
        >
          <CloseSmall
            sx={{
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
      {sentMessage?.length > 0 ? (
        <Typography>{sentMessage}</Typography>
      ) : (
        <FormContainer
          onSuccess={formSubmitHandler}
          values={formValues}
          FormProps={{
            style: {
              display: "flex",
              flexDirection: "column",
              gap: 20,
            },
          }}
        >
          <TextFieldElement
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            sx={{
              flexBasis: "100%",
              ".MuiOutlinedInput-input": {
                padding: "16px 14px",
              },
            }}
            label="Email address"
            InputLabelProps={{
              required: false,
              style: {
                fontSize: "14px",
                paddingRight: "5px",
                backgroundColor: "white",
              },
            }}
            inputProps={{
              style: {
                fontSize: "14px",
              },
            }}
          />
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                cursor: "pointer",
              }}
              variant="p1"
              color="primary.100"
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              type="submit"
              disabled={buttonLoading}
              id="automations-overview-preview-send-test"
            >
              Send test
            </Button>
          </Box>
        </FormContainer>
      )}
    </Paper>
  );
};

export default SendTestEmailContent;
