import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M9.87868 13.5355L8.46447 12.1213L10.5858 9.99999L8.46447 7.87867L9.87868 6.46446L12 8.58578L14.1213 6.46446L15.5355 7.87867L13.4142 9.99999L15.5355 12.1213L14.1213 13.5355L12 11.4142L9.87868 13.5355Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M3 5V21L7.8 17.4C8.14582 17.1396 8.56713 16.9992 9 17H19C20.1046 17 21 16.1046 21 15V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5ZM5 17V5H19V15H8.334C7.90107 14.9988 7.47964 15.1393 7.134 15.4L5 17Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "MessageClose"
);
