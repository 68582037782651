import React from "react";
import { Box, Typography } from "@mui/material";
import OverflowTypographyWithTooltip from "@/components/OverflowTooltip";
import { CampaignContentProps } from "./types";

interface CampaignContentComponentProps extends CampaignContentProps {
  showDetails: boolean;
}
function CampaignContent(props: CampaignContentComponentProps) {
  const { name, description, showDetails } = props;

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <OverflowTypographyWithTooltip
        text={name}
        value={name}
        variant="h4"
        color="grey.100"
      />

      {showDetails && (
        <Typography
          variant="p2"
          color="grey.80"
          className="campaign-description"
        >
          {description}
        </Typography>
      )}
    </Box>
  );
}

export default CampaignContent;
