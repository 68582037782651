import React from "react";
import { Tab, Tabs } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useRouteMatch } from "./hooks";
import { PageTabsProps } from "./types";

function PageTabs(props: PageTabsProps) {
  const { tabs } = props;

  const routeMatch = useRouteMatch();
  const currentTab = routeMatch?.params?.name;

  return currentTab ? (
    <Tabs sx={{ borderBottom: 1, color: "grey.60" }} value={currentTab}>
      {tabs.map((tabItem, index) => (
        <Tab
          id={tabItem?.id}
          key={index}
          label={tabItem.label}
          value={tabItem.route}
          component={RouterLink}
          to={tabItem.route}
        />
      ))}
    </Tabs>
  ) : null;
}

export default PageTabs;
