const root = {
  padding: 0,
};

const dividers = {
  borderTop: "none",
};
const styleOverrides = {
  dividers,
  root,
};

export { styleOverrides };
