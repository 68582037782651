import TinyTable from "@/components/Table";
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useAsyncValue, useLoaderData } from "react-router-dom";
import { usePageSort } from "@/components/PaginationRouted/usePagination.hook";
import {
  TableFormatted,
  TableHeaderCellsTypes,
} from "@/components/Table/table.types";
import { Campaign, GetCampaignResponse } from "@/types/api/campaigns";
import { fetchApi } from "@tiny/utils";
import { useScreenLoaderInvokers } from "@tiny/lib/ScreenLoaderManager";
import CampaignPreview from "@/components/Campaign/CampaignPreview";
import { useDrawerInvokers } from "@tiny/lib";
import { CampaignActionCTA } from "./CampaignActionCta";
import ActionColCTA from "./ActionColCTA";
import { CalendarLoaderProps } from "../loader";
import { useConfirmationDialog } from "../../useConfirmationDialog.hook";
import {
  getCampaignAudience,
  getCampaignStartTime,
  getCampaignStatusRow,
  getCampaignType,
  getDiscountStatus,
  getGeneratedBy,
  getTemplateLanguage,
} from "./RowHelpers";
import {
  formatRevenue,
  useResponsiveColCount,
  headerCellData,
  getCampaignStatus,
} from "../helpers";

function CampaignTable({
  selected,
  setSelected,
  isAllSelected,
  setIsAllSelected,
}: {
  selected: Campaign[];
  setSelected: React.Dispatch<React.SetStateAction<Campaign[]>>;
  isAllSelected: boolean;
  setIsAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { showConfirmationDialog } = useConfirmationDialog();
  const { showDrawer } = useDrawerInvokers();
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderInvokers();
  const { campaigns } = useAsyncValue() as GetCampaignResponse;
  const { languages } = useLoaderData() as CalendarLoaderProps;
  const [currentSort, onChangeSort] = usePageSort("startTime,asc");
  const numOfCol = useResponsiveColCount();
  const [tableHeaderData, setTableHeaderData] = useState(
    headerCellData.slice(0, numOfCol)
  );

  const formatCampaignRows = (campaignArray: Campaign[]) =>
    campaignArray.map((campaign: Campaign) => {
      const {
        name,
        startTime,
        status,
        outdated,
        expectedRevenue,
        generatedBy,
        language,
        type,
        enabled,
        audiences,
        id,
        discountId,
      } = campaign;

      return {
        ...campaign,
        startTime: getCampaignStartTime(startTime),
        status: getCampaignStatusRow({ status, outdated, enabled }),
        generatedBy: getGeneratedBy(generatedBy),
        templateLanguage: getTemplateLanguage(languages, language),
        type: getCampaignType(type),
        audience: getCampaignAudience(audiences),
        discount: getDiscountStatus(discountId),
        name: (
          <Typography
            variant="p2"
            color="grey.100"
            fontWeight={600}
            sx={(theme) => ({
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: theme.spacing(70),
              cursor: "pointer",
            })}
            onClick={() => {
              handlePreview(campaign);
            }}
            title={name}
            id="campaign-calendar-table-row-preview"
          >
            {name}
          </Typography>
        ),
        expectedRevenue: expectedRevenue ? (
          <Typography
            variant="p2"
            sx={(theme) => ({ color: theme.palette.grey[100] })}
          >
            {formatRevenue(expectedRevenue)}
          </Typography>
        ) : (
          <Button
            sx={{ px: 1, fontWeight: 400, py: 0 }}
            onClick={() => {
              showScreenLoader();
              calculateRevenue(id);
            }}
            id="calendar-calendar-revenue-cta"
          >
            Calculate Now
          </Button>
        ),
      };
    });

  const handlePreview = (campaign: Campaign) => {
    const { id, status, outdated, enabled, name, generatedBy } = campaign;
    showDrawer({
      children: (
        <CampaignPreview
          name={name}
          campaignId={id}
          campaignStatus={
            getCampaignStatus({ status, enabled, outdated })?.status
          }
          regenerate={false}
          onClose={() => {
            setSelected([]);
            setIsAllSelected(false);
          }}
          campaignGeneratedBy={generatedBy}
        />
      ),
      onClose: () => {
        setSelected([]);
        setIsAllSelected(false);
      },
    });
  };

  const [formattedCampaignRows, setFormattedCampaignRows] = useState(
    formatCampaignRows(campaigns.content)
  );

  useEffect(() => {
    setFormattedCampaignRows(formatCampaignRows(campaigns.content));
  }, [campaigns]);

  const toggleSelectAll = () => {
    if (isAllSelected) {
      setSelected([]);
    } else {
      setSelected(campaigns.content);
    }
    setIsAllSelected((s) => !s);
  };

  const calculateRevenue = (campaignId: string) => {
    fetchApi<{ expectedRevenue: number; totalSubscribers: number }>(
      `/campaignservice/campaign/${campaignId}/calculate-revenue`,
      {
        method: "put",
      }
    )
      .then(({ totalSubscribers }) => {
        hideScreenLoader();
        if (totalSubscribers < 1000) {
          showConfirmationDialog(
            "Revenue forecast is available only when your subscriber base is more than 1000, import your subscribers from other platform or via excel to get an estimate of potential revenue.",
            "Calculate revenue",
            "Okay",
            () => {},
            false
          );
        } else {
          setFormattedCampaignRows(
            formatCampaignRows(
              campaigns.content.map((camp) => {
                if (camp.id === campaignId) {
                  camp.expectedRevenue = 1000;
                }
                return camp;
              })
            )
          );
        }
      })
      .catch(() => {
        hideScreenLoader();
      });
  };

  const isSelected = (campaign: TableFormatted<Campaign>) =>
    selected.map((camp) => camp?.id).includes(campaign?.id);

  const toggleSelection =
    ({ pageRows }: { totalRowsCount: number; pageRows: Campaign[] }) =>
    (campaign: TableFormatted<Campaign>) => {
      setSelected((arr) => {
        const newArr = isSelected(campaign)
          ? arr.filter((camp) => camp.id !== campaign.id)
          : [...arr, campaign as Campaign];

        if (newArr.length === pageRows.length) {
          setIsAllSelected(true);
        } else {
          setIsAllSelected(false);
        }
        return newArr;
      });
    };

  const handleColumnUpdate = (column: TableHeaderCellsTypes<Campaign>) => {
    setTableHeaderData((prev) => {
      let result;
      if (prev.map((p) => p.label).includes(column.label)) {
        result = prev.filter((col) => col.label !== column.label);
      } else {
        result = [...prev, column];
      }
      return result;
    });
  };

  const MemoActionColCta = React.useMemo(
    () => (
      <ActionColCTA
        tableColsData={headerCellData.slice(numOfCol)}
        emitSelection={handleColumnUpdate}
        selected={tableHeaderData}
      />
    ),
    [headerCellData, numOfCol, handleColumnUpdate, tableHeaderData]
  );

  return (
    <Box
      sx={{
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "grey.50",
        overflow: "hidden",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: "100%",
      }}
    >
      <TinyTable
        headerCells={tableHeaderData}
        rows={formattedCampaignRows}
        rowsData={campaigns.content}
        options={{
          isSelectable: true,
          isSelected,
          selectHandler: toggleSelection({
            totalRowsCount: campaigns.totalElements,
            pageRows: campaigns.content,
          }),
          isAllSelected,
          selectAllHandler: toggleSelectAll,
          currentSort,
          onSort: onChangeSort,
          showActionAlways: true,
          ActionCTA: CampaignActionCTA,
          ActionColCTA: MemoActionColCta,
          showBorders: true,
          freezeEdgeColumns: true,
        }}
      />
    </Box>
  );
}

export default CampaignTable;
