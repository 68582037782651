import { SenderDetailsForm } from "@/routes/AccountSettings/SenderDetails/SenderDetailsForm";
import { prepareForUpdate } from "@/routes/AccountSettings/SenderDetails/utils";
import { GetSenderDetailsResponse } from "@/types/api/senderDetails";
import {
  Alert,
  Dialog,
  DialogContent,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogTitle } from "@tiny/mui";
import React, { useEffect, useState } from "react";
import {
  Link,
  useActionData,
  useNavigation,
  useRevalidator,
  useSubmit,
} from "react-router-dom";
import { ApiError } from "@tiny/utils/errors";
import { ExternalLink } from "@tiny/icons";
import type { DialogProps } from "./SenderDetails.types";
import { UpdateConfirmationMessage } from "./UpdateConfirmationMessage";

function SenderDetailsFormDialog({
  open,
  details,
  dialogCloseHandler,
  ctaId,
}: DialogProps) {
  const [isFormFragmentShown, setIsFormFragmentShown] = useState(false);
  const actionSubmit = useSubmit();
  const navigation = useNavigation();
  const { error } = (useActionData() as { error: ApiError }) ?? {};
  const revalidator = useRevalidator();
  const theme = useTheme();

  const onSubmit = (data: GetSenderDetailsResponse) => {
    const formData = new FormData();
    formData.append("formData", JSON.stringify(prepareForUpdate(data)));
    formData.append("id", data.id);
    actionSubmit(formData, { method: "put" });
  };

  const hasSubmitActionCompleted = navigation.state === "loading";

  if (hasSubmitActionCompleted && isFormFragmentShown && !error) {
    setIsFormFragmentShown(false);
  }

  const setIsFormFragmentShownTrue = React.useCallback(() => {
    if (!open) {
      revalidator.revalidate();
      const id = setTimeout(() => setIsFormFragmentShown(true), 1000);
      return () => clearTimeout(id);
    }
  }, [open]);

  useEffect(() => {
    (async () => {
      const clearTimeout = await setIsFormFragmentShownTrue();
      return clearTimeout;
    })();
  }, [setIsFormFragmentShownTrue]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={dialogCloseHandler}>
      <DialogTitle onClose={dialogCloseHandler}>
        {isFormFragmentShown ? "Edit Sender Details" : "Request submitted"}
      </DialogTitle>
      <DialogContent>
        {isFormFragmentShown && !details.dkimSigned && (
          <>
            <Alert
              variant="outlined"
              severity="info"
              sx={{
                mt: 5,
                mb: 2.5,
                borderColor: `${
                  theme.palette.primary[60] as string
                } !important`,
                "& .MuiAlert-icon": {
                  ml: 2,
                },
              }}
            >
              <Typography variant="p3">
                Sign in your domain using DKIM to ensure higher delivery, In
                case you dont sign-in your domain, your email will be sent from
                a system generated Email address.
                <Link
                  aria-label="Learn More"
                  to="https://support.tinyalbert.ai/portal/en/kb/articles/new-email-rules-to-stop-spam"
                  target="_blank"
                  rel="noreferrer"
                  id="campaign-calendar-close-info-alert-msg"
                  style={{
                    textDecoration: "none",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: 2,
                    color: theme.palette.primary[125],
                  }}
                >
                  Learn More <ExternalLink fontSize="small" />
                </Link>
              </Typography>
            </Alert>
            <Alert
              variant="outlined"
              severity="info"
              sx={{
                mb: 5,
                borderColor: `${
                  theme.palette.primary[60] as string
                } !important`,
                "& .MuiAlert-icon": {
                  ml: 2,
                },
              }}
            >
              <Typography variant="p3">
                Always use your brand email address ex- john@mybusiness.com as
                from sender.
                <Link
                  aria-label="Learn More"
                  to="https://support.tinyalbert.ai/portal/en/kb/articles/why-free-email-is-bad-27-5-2024"
                  target="_blank"
                  rel="noreferrer"
                  id="campaign-calendar-close-info-alert-msg"
                  style={{
                    textDecoration: "none",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: 2,
                    color: theme.palette.primary[125],
                  }}
                >
                  Learn More <ExternalLink fontSize="small" />
                </Link>
              </Typography>
            </Alert>
          </>
        )}
        {isFormFragmentShown ? (
          <SenderDetailsForm
            details={details}
            onClose={dialogCloseHandler}
            onSubmit={onSubmit}
            ctaId={ctaId}
          />
        ) : (
          <UpdateConfirmationMessage dialogCloseHandler={dialogCloseHandler} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default SenderDetailsFormDialog;
