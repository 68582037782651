import { useRevalidator } from "react-router-dom";

const useRefreshButton = () => {
  const revalidator = useRevalidator();
  const isLoading = revalidator.state === "loading";

  const clickHandler = () => {
    revalidator.revalidate();
  };

  return { clickHandler, isLoading };
};

export { useRefreshButton };
