import React from "react";

function useRandom(): string {
  const [result] = React.useState(() =>
    Math.round(Math.random() * 1e9).toString()
  );
  return result;
}

export default useRandom;
