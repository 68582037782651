import React from "react";
import { Tooltip } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SendTestEmailProps } from "./types";
import SendTestEmailContent from "./SendTestEmailContent";

const SendTestEmail = (props: SendTestEmailProps) => {
  const { open, setOpen } = props;
  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <div>
        <Tooltip
          onClose={() => {
            setOpen(false);
          }}
          open={open}
          sx={{ padding: 6 }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          componentsProps={{
            popper: {
              sx: {
                borderRadius: 2,
              },
            },
          }}
          title={<SendTestEmailContent {...props} />}
        >
          <span />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default SendTestEmail;
