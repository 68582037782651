import React from "react";
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import { DayOfWeekProps } from "./DayOfWeekSelector.types";

const DayOfWeekSelector: React.FC<DayOfWeekProps> = ({
  value = [],
  onChange,
  disabled,
}) => {
  const days = [
    { label: "Sun", value: "SUNDAY" },
    { label: "Mon", value: "MONDAY" },
    { label: "Tue", value: "TUESDAY" },
    { label: "Wed", value: "WEDNESDAY" },
    { label: "Thu", value: "THURSDAY" },
    { label: "Fri", value: "FRIDAY" },
    { label: "Sat", value: "SATURDAY" },
  ];

  const handleChange = (day: string, checked: boolean) => {
    let newValue: string[] = [...value];
    if (checked) {
      newValue.push(day);
    } else {
      newValue = newValue.filter((d) => d !== day);
    }
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      sx={{ marginLeft: "0px" }}
    >
      {days.map((day) => (
        <Box key={day.value} m={1}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={day.value}
                checked={value.includes(day.value)}
                onChange={(e) => handleChange(day.value, e.target.checked)}
                disabled={disabled}
              />
            }
            label={day.label}
            sx={{ margin: 0 }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default DayOfWeekSelector;
