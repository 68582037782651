import * as React from "react";
import {
  DialogTitleProps,
  DialogTitle as MuiDialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@tiny/icons/generated/CloseBig";

interface TinyDialogTitleProps extends DialogTitleProps {
  onClose?: () => void;
}

function DialogTitle(props: TinyDialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      id="dialog-title"
      sx={[
        {
          m: 0,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: 2,
          fontFamily: "inherit",
          fontWeight: 600,
          padding: 0,
          fontSize: "20px",
          lineHeight: 1,
        },
        !children && {
          justifyContent: "flex-end",
        },
      ]}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            p: 0,
            color: "grey.80",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

export { DialogTitle };
