import React from "react";
import TableBody from "@/components/Table/TableBody";
import { TableHeaderCellsTypes } from "@/components/Table/table.types";
import { Campaign } from "@/types/api/campaigns";
import { Box, Chip, Table, TableContainer, Typography } from "@mui/material";
import CampaignPreview from "@/components/Campaign/CampaignPreview";
import { getCampaignStatus } from "@/routes/Campaigns/Calendar/helpers";
import { useDrawerInvokers } from "@tiny/lib";
import { formatPercent } from "./CampaignsTable.utils";
import { CampaignTableActions } from "./CampaignTableActions";

const allHeaderCells: TableHeaderCellsTypes<Campaign>[] = [
  { propName: "name", label: "Name" },
  { propName: "sent", label: "Sent Emails" },
  { propName: "openProgress", label: "Open rate" },
  { propName: "clickedProgress", label: "Click rate" },
  { propName: "spamProgress", label: "Spam rate" },
  { propName: "unsubscribedProgress", label: "unsubscribed" },
];

function CampaignsTable({ rowsData }: { rowsData: Campaign[] }) {
  const { showDrawer } = useDrawerInvokers();

  const formattedRows = rowsData.map((row) => {
    const { label } = getCampaignStatus({
      status: row.status,
      enabled: row.enabled,
      outdated: row.outdated,
    });

    const isRecurringOrStopped =
      row.status === "RECURRING" || row.status === "STOPPED";

    return {
      ...row,
      name: (
        <Typography
          variant="label1R"
          color="grey.100"
          fontWeight={600}
          sx={(theme) => ({
            marginLeft: { sx: 0, sm: 3 },
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: theme.spacing(70),
            cursor: "pointer",
          })}
          onClick={() => {
            handlePreview(row);
          }}
          id="campaign-calendar-table-row-preview"
        >
          {row?.name}&nbsp;&nbsp;&nbsp;
          <Chip
            label={label}
            title={label}
            sx={(theme) => ({
              background: theme.palette.grey[30],
              color: theme.palette.grey[90],
              fontWeight: 400,
              fontSize: theme.spacing(2),
              maxWidth: theme.spacing(50),
              height: 16,
            })}
            size="small"
          />
        </Typography>
      ),
      openProgress: isRecurringOrStopped ? undefined : (
        <Box display="flex" flexDirection="column">
          <Typography variant="label1R" color="grey.100" component="div" mb={1}>
            {formatPercent(row.openProgress)}
          </Typography>
          <Typography variant="label3R" color="grey.80" component="div">
            Open rate
          </Typography>
        </Box>
      ),
      sent: isRecurringOrStopped ? undefined : (
        <Box display="flex" flexDirection="column">
          <Typography variant="label1R" color="grey.100" component="div" mb={1}>
            {row.sent || "-"}
          </Typography>
          <Typography variant="label3R" color="grey.80" component="div">
            Sent Emails
          </Typography>
        </Box>
      ),
      clickedProgress: isRecurringOrStopped ? undefined : (
        <Box display="flex" flexDirection="column">
          <Typography variant="label1R" color="grey.100" component="div" mb={1}>
            {formatPercent(row.clickedProgress)}
          </Typography>
          <Typography variant="label3R" color="grey.80" component="div">
            Click rate
          </Typography>
        </Box>
      ),
      spamProgress: isRecurringOrStopped ? undefined : (
        <Box display="flex" flexDirection="column">
          <Typography variant="label1R" color="grey.100" component="div" mb={1}>
            {formatPercent(row.spamProgress)}
          </Typography>
          <Typography variant="label3R" color="grey.80" component="div">
            Spam rate
          </Typography>
        </Box>
      ),
      unsubscribedProgress: isRecurringOrStopped ? undefined : (
        <Box display="flex" flexDirection="column">
          <Typography variant="label1R" color="grey.100" component="div" mb={1}>
            {formatPercent(row.unsubscribedProgress)}
          </Typography>
          <Typography variant="label3R" color="grey.80" component="div">
            Unsubscribe
          </Typography>
        </Box>
      ),
    };
  });

  const handlePreview = (campaign: Campaign) => {
    const { id, status, outdated, enabled, name, generatedBy } = campaign;
    showDrawer({
      children: (
        <CampaignPreview
          name={name}
          campaignId={id}
          campaignStatus={
            getCampaignStatus({ status, enabled, outdated })?.status
          }
          regenerate={false}
          onClose={() => {}}
          campaignGeneratedBy={generatedBy}
        />
      ),
      onClose: () => {},
    });
  };

  return (
    <TableContainer className="table-wrapper">
      <Table sx={{ backgroundColor: "common.white", borderRadius: 2 }}>
        <TableBody
          headerData={allHeaderCells}
          rows={formattedRows}
          rowsData={rowsData}
          showActionAlways
          ActionCTA={CampaignTableActions}
          showBorders
        />
      </Table>
    </TableContainer>
  );
}

export default CampaignsTable;
