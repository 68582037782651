import { Button, Card, CardContent, IconButton, Theme } from "@mui/material";
import CardStack from "@/components/CardStack/CardStack";
import { ChevronRight, Info2Outline } from "@tiny/icons";
import React from "react";
import MetricInfo from "@/components/MetricCard/MetricInfo";
import { Metric } from "@/components/MetricCard/types";
import InfoTooltip from "@/components/InfoTooltip";

export interface MetricsCardProps {
  metrics: Metric[];
  image: string;
  infoMessage?: string;
  seeReportsHandler?: () => void;
  seeReportsCtaId?: string;
}

// TODO: rename this and merge into MetricCard component
function StatisticsCard({
  metrics,
  image,
  infoMessage,
  seeReportsHandler,
  seeReportsCtaId,
}: MetricsCardProps) {
  return (
    <Card
      sx={{
        p: 5,
        borderRadius: 2,
        boxShadow: "none",
        display: "flex",
        position: "relative",
        flex: 1,
      }}
    >
      <CardContent sx={{ flex: 1, p: 0 }}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <CardStack>
          {metrics.map((metricData) => (
            <MetricInfo key={metricData.id} data={metricData} />
          ))}
        </CardStack>
        {seeReportsHandler && (
          <Button
            sx={(theme: Theme) => ({
              mt: 2,
              p: 0,
              fontWeight: theme.typography.fontWeightRegular,
            })}
            onClick={seeReportsHandler}
            variant="text"
            endIcon={<ChevronRight />}
            id={seeReportsCtaId}
          >
            See reports
          </Button>
        )}
      </CardContent>

      <InfoTooltip message={infoMessage ?? ""} variant="black">
        <IconButton
          size="small"
          sx={(theme) => ({
            p: 0,
            position: "absolute",
            top: theme.spacing(2),
            right: theme.spacing(2),
            color: theme.palette.grey[60],
          })}
        >
          <Info2Outline sx={{ fontSize: 16 }} />
        </IconButton>
      </InfoTooltip>
      <img src={image} alt="tinyAlbert" />
    </Card>
  );
}

export default StatisticsCard;
