import React from "react";
import { TinyDialogProvider, TinyDrawerProvider } from "@tiny/lib";
import { Navigate, useRouteLoaderData } from "react-router-dom";
import { RootLoader } from "@/types/RootLoader";
import LayoutUnauthenticated from "../LayoutSimple";

/**
 * Used for wrapping pages for /onboarding/* routes
 */
function LayoutOnboarding() {
  const { userStatus } = useRouteLoaderData("root") as RootLoader;

  if (userStatus?.onboardingStep === "FINISHED") {
    return <Navigate to="/main" />;
  }

  return (
    <TinyDialogProvider>
      <TinyDrawerProvider>
        <LayoutUnauthenticated />
      </TinyDrawerProvider>
    </TinyDialogProvider>
  );
}

export default LayoutOnboarding;
export * from "./OnboardingIndex";
