import React from "react";
import { Button } from "@mui/material";
import { useDialogInvokers } from "@tiny/lib";
import { useNavigate } from "react-router-dom";
import AIBuilderIcon from "@/assets/illustrations/ai-builder.svg";
import ClassicBuilderIcon from "@/assets/illustrations/classic-builder.svg";
import AddNewCampaignDialog from "./AddNewCampaignDialog";
import { CampaignBuilderCardProps } from "./AddNewCampaigns.types";

const AddNewCampaign = () => {
  const navigate = useNavigate();
  const { requestMediumDialog, exitDialog } = useDialogInvokers();
  const campaignBuilders: CampaignBuilderCardProps[] = [
    {
      title: "AI Builder",
      description: `Effortlessly craft tailored campaigns for your store with our AI. Ideal for the busy entrepreneur, it’s packed with essential features and constantly evolving. Discover what it can do for you!`,
      ctaText: "Select AI Builder",
      image: AIBuilderIcon,
      active: false,
    },
    {
      title: "Classic Builder",
      description:
        "Build emails effortlessly with our drag-and-drop builder, offering extensive customization for those who desire full control over their campaign's details.",
      ctaText: "Select Classic Builder",
      image: ClassicBuilderIcon,
      active: true,
      ctaAction: () => {
        exitDialog();
        navigate(`/main/campaigns/builder`);
      },
    },
  ];

  const requestNewCampaignDialog = React.useCallback(
    () =>
      requestMediumDialog({
        titleProps: { children: "Create Campaign", variant: "h3" },
        contentProps: {
          children: (
            <AddNewCampaignDialog campaignBuilders={campaignBuilders} />
          ),
        },
      }),
    [requestMediumDialog]
  );
  return (
    <Button
      variant="contained"
      onClick={requestNewCampaignDialog}
      id="add-new-campaign"
    >
      Add New
    </Button>
  );
};

export default AddNewCampaign;
