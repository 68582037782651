import { addDays, startOfDay, isSameDay } from "date-fns";

type Range = {
  startDate: Date;
  endDate: Date;
};

type RangeHandler = {
  label?: string;
  range?: () => Range;
  isSelected?: (range: Range) => boolean;
};

const staticRangeHandler: RangeHandler = {
  isSelected(range: Range) {
    const definedRange = this.range?.();

    if (definedRange?.startDate && definedRange?.endDate)
      return (
        isSameDay(range.startDate, definedRange?.startDate) &&
        isSameDay(range.endDate, definedRange?.endDate)
      );
    return false;
  },
};

export function createStaticRanges(ranges: RangeHandler[]): any[] {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: "Next 30 days",
    range: () => {
      const startDate = startOfDay(new Date());
      const endDate = startOfDay(addDays(new Date(), 30));
      return { startDate, endDate };
    },
  },
  {
    label: "Next 45 days",
    range: () => {
      const startDate = startOfDay(new Date());
      const endDate = startOfDay(addDays(new Date(), 45));
      return { startDate, endDate };
    },
  },
  {
    label: "Next 60 days",
    range: () => {
      const startDate = startOfDay(new Date());
      const endDate = startOfDay(addDays(new Date(), 60));
      return { startDate, endDate };
    },
  },
  {
    label: "Next 90 days",
    range: () => {
      const startDate = startOfDay(new Date());
      const endDate = startOfDay(addDays(new Date(), 90));
      return { startDate, endDate };
    },
  },
  {
    label: "Next 180 days",
    range: () => {
      const startDate = startOfDay(new Date());
      const endDate = startOfDay(addDays(new Date(), 180));
      return { startDate, endDate };
    },
  },
]);
