import React from "react";
import { Box } from "@mui/material";
import { useCampaignPageView } from "../CampaignPage.context";
import { StepContainerProps } from "./StepContainer.types";

function StepContainer(props: StepContainerProps): JSX.Element {
  const { children } = props;

  const {
    activeStep,
    firstIncompleteStep,
    steps,
    clearRequireSave,
    setActiveStep,
  } = useCampaignPageView();
  const campaignStep = steps.find(
    (s) =>
      (s.step === activeStep && s.completed) || s.step === firstIncompleteStep
  );
  // React.useLayoutEffect(() => {
  //   if (campaignStep && campaignStep.step !== activeStep) {
  //     setActiveStep(campaignStep.step);
  //   }
  // }, [activeStep, campaignStep, setActiveStep]);

  React.useLayoutEffect(() => {
    clearRequireSave();
  }, [clearRequireSave]);

  return (
    <Box
      sx={(theme) => ({
        margin: "auto",
        width: "100%",
        padding: 16,
        [theme.breakpoints.up("lg")]: {
          maxWidth: 1380,
          paddingRight: 40,
          paddingLeft: 40,
        },
      })}
    >
      {children}
    </Box>
  );
}

export default StepContainer;
