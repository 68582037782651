export interface ResponseHeaders {
  "content-disposition"?: string | null;
  "content-length"?: string | null;
  "content-type"?: string | null;
}

export function downloadFileData(data: Blob, headers: ResponseHeaders): void {
  const contentDisposition =
    headers?.["content-disposition"]?.split("fileName=");

  const fileName =
    (contentDisposition?.length || 0) > 1 && contentDisposition?.[1];

  const url = window.URL.createObjectURL(data);

  const a = document.createElement("a");
  a.href = url;

  if (fileName) {
    a.download = fileName;
    a.click();
  }
}
