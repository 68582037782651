import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16 6H8C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V8C18 6.89543 17.1046 6 16 6ZM8 4C5.79086 4 4 5.79086 4 8V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4H8Z"
    xmlns="http://www.w3.org/2000/svg"
  />,
  "DummyIconLgLine"
);
