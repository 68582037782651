import React, { ForwardedRef } from "react";
import { TextField } from "..";
import { Search, CloseBig } from "@tiny/icons";
import { SearchFieldProps } from "./SearchField.types";

function SearchField(
  props: SearchFieldProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { onChangeValue, onKeyDown, value, ...other } = props;

  const handleClear = React.useCallback(() => {
    onChangeValue?.("");
  }, [onChangeValue]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Escape") {
        onChangeValue?.("");
      }
      onKeyDown?.(event);
    },
    [onChangeValue, onKeyDown]
  );

  const endIcon = value ? (
    <button type="button" onClick={handleClear} title="Clear (ESC)">
      <CloseBig
        sx={{
          fontSize: 16,
        }}
      />
    </button>
  ) : (
    <Search
      sx={{
        fontSize: 16,
      }}
    />
  );

  return (
    <TextField
      {...other}
      ref={ref}
      onChangeValue={onChangeValue}
      endIcon={endIcon}
      value={value}
      onKeyDown={handleKeyDown}
    />
  );
}

export default React.forwardRef(SearchField);
