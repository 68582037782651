import React from "react";
import { Button, Typography } from "@mui/material";
import { useIsLargerThan } from "@tiny/theme/utils";
import CenteredContainer from "../../components/CenteredContainer";

function WelcomePage() {
  const isMdUp = useIsLargerThan("sm");

  return (
    <CenteredContainer
      sx={{
        alignItems: isMdUp ? "center" : "flex-start",
        justifyContent: "center",
        mb: "33vh",
      }}
      fixed
      maxWidth="xs"
    >
      <Typography variant={isMdUp ? "h2" : "h1"} pb={3} color="grey.100">
        Welcome to tinyAlbert
      </Typography>
      <Typography
        variant={isMdUp ? "p1" : "p2"}
        mb={6}
        textAlign={isMdUp ? "center" : "left"}
        color="grey.80"
      >
        tinyAlbert is an AI Marketing manager that helps you grow your Shopify
        store 10x faster with almost zero time investment from you. Connect your
        Shopify store to get started.
      </Typography>
      <Button
        fullWidth={!isMdUp}
        variant="contained"
        href="https://apps.shopify.com/tinyalbert"
        target="_blank"
      >
        Connect With Shopify
      </Button>
    </CenteredContainer>
  );
}

export default WelcomePage;
