import React from "react";
import { useController, useWatch } from "react-hook-form";
import { validateNoBlank } from "../../utils/validateUtils";

import { FormItemProps } from "./FormItem.types";

function FormItem(props: FormItemProps) {
  const {
    children,
    defaultValue,
    hideHelperText,
    name,
    rules,
    valuePropName = "value",
    onChangeValue,
    getChildrenProps,
    watchValue,
  } = props;

  const required = Boolean(rules?.required);
  const child = React.Children.only(children) as React.ReactElement;
  const fieldName = child.props["label"] || child.props["placeholder"];

  let customValidate = rules?.validate;
  if (required && fieldName) {
    const nonBlankValidation = (v: any) => validateNoBlank(v, fieldName);
    customValidate =
      customValidate instanceof Function
        ? { customValidate, nonBlankValidation }
        : { ...customValidate, nonBlankValidation };
  }

  const watchedValue = useWatch({
    name,
  });

  const { field, fieldState, formState } = useController({
    name,
    rules: { ...rules, validate: customValidate },
    defaultValue,
  });

  const { onChange, onBlur, value, ref } = field;
  const { error } = fieldState;

  const handleChangeValue = (value: any) => {
    onChange(value);
    if (onChangeValue) {
      onChangeValue(value);
    }
  };

  const extraProps = React.useMemo(() => {
    return getChildrenProps?.({ field, fieldState, formState });
  }, [field, fieldState, formState, getChildrenProps]);

  const helperText = hideHelperText
    ? undefined
    : (error && error?.message) ||
      formState.errors[name] ||
      error?.type ||
      child.props["helperText"];

  const childProps = {
    helperText,
    error: Boolean(error),
    onChangeValue: handleChangeValue,
    [valuePropName]: watchValue ? watchedValue : value,
    onBlur,
    required,
    inputRef: ref,
    ...extraProps,
  };

  return React.cloneElement(child, childProps);
}

export default FormItem;
