import React, { ForwardedRef } from "react";
import {
  TextFieldProps,
  TextField as MuiTextField,
  Tooltip,
} from "@mui/material";
import { TextFieldBaseProps } from "./TextFieldBase.types";
import useId from "../../hooks/useId";
import useRandom from "../../hooks/useRandom";

function TextFieldBase(
  props: TextFieldBaseProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    autoComplete: autoCompleteProp,
    className: classNameProp,
    hiddenLabel,
    fullWidth = true,
    id: idProp,
    InputLabelProps: InputLabelPropsProp,
    InputProps: InputPropsProp,
    FormHelperTextProps: FormHelperTextPropsProp,
    helperText,
    showHelperTextMargin,
    disabled,
    disabledTooltip,
    tooltipAction,
    ...other
  } = props;

  const id = useId(idProp);

  const autoCompleteRandom = useRandom();

  const autoComplete = autoCompleteProp || autoCompleteRandom;

  const textField = (
    <InternalTextField
      {...other}
      autoComplete={autoComplete}
      fullWidth={fullWidth}
      id={id}
      ref={ref}
      variant="outlined"
      helperText={helperText}
      disabled={disabled}
    />
  );

  if (disabled && disabledTooltip) {
    return (
      <Tooltip title={disabledTooltip}>
        <>
          {textField}
          {tooltipAction}
        </>
      </Tooltip>
    );
  }

  return textField;
}

const InternalTextField = React.forwardRef(function (
  props: TextFieldProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    inputProps: inputPropsProp,
    onKeyDownCapture: onKeyDownCaptureProp,
    onFocusCapture: onFocusCaptureProp,
    onFocus,
    disabled,
  } = props;

  const onKeyDownCapture = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (disabled) {
      if (event.key !== "Enter" && event.key !== "Tab") {
        event.preventDefault();
      }
      event.stopPropagation();
      return;
    }
    onKeyDownCaptureProp?.(event);
  };

  const handleFocusCapture = (event: React.FocusEvent<HTMLInputElement>) => {
    if (disabled) {
      onFocus?.(event);
    } else {
      onFocusCaptureProp?.(event);
    }
  };

  return (
    <MuiTextField
      {...props}
      InputProps={{
        sx: (theme) => ({
          root: {
            boxShadow: `0 0 0 100px ${theme.palette.background.default} inset`,
            "& > fieldset": {
              border: `0.5px solid ${theme.palette.grey[600]} !important`,
              transition: "border-color  250ms",
              borderRadius: 4,
              "& > legend": {
                fontSize: "10.5px !important",
              },
            },
            "&not($disabled):hover > fieldset": {
              border: `0.5px solid ${theme.palette.grey[600]} !important`,
            },
            "&.Mui-disabled": {
              background: theme.palette.grey[100],
              boxShadow: "none",
              borderRadius: 4,
              "&:focus-within": {
                "& > fieldset": {
                  border: `0.5px solid ${theme.palette.grey[600]} !important`,
                },
              },
            },
          },
          disabled: {},
          input: {
            boxSizing: "border-box",
            height: 40,
            fontSize: 14,
            "&:-webkit-autofill": {
              boxShadow: `0 0 0 100px ${theme.palette.background.default} inset`,
            },
            color: theme.palette.grey[900],
          },
          marginDense: {
            boxSizing: "border-box",
            height: 36,
            "& > $input": {
              fontSize: 12,
            },
            "& > fieldset > legend": {
              fontSize: "10px !important",
            },
          },
        }),
      }}
      InputLabelProps={{
        shrink: true,
        sx: {
          root: {
            fontSize: 14,
            transform: "translate(13px, 13px) scale(1)",
            color: "grey",
          },
          marginDense: {
            fontSize: 10,
            transform: "translate(15px, 10px) scale(1)",
          },
          shrink: {
            margin: "0 -6px",
            padding: "0 6px",
            fontSize: 14,
            fontWeight: "bold",
            background: "white",
            borderRadius: 2,
            "&.MuiFormLabel-root": {
              transform: "translate(15px, -4px) scale(0.75)",
            },
            color: "grey",
          },
        },
      }}
      ref={ref}
      disabled={disabled}
      onKeyDownCapture={onKeyDownCapture}
      inputProps={{
        display: "inline",
        alignSelf: "baseline",
        minHeight: 10,
        lineHeight: "10px",
        marginBottom: 7,
        marginTop: 4,
        marginLeft: 10,
        fontSize: 10,
        background: "transparent",
        borderRadius: 2,
        disabled: false,
        "aria-disabled": disabled,
      }}
    />
  );
});

export default React.forwardRef(TextFieldBase);
