import { useState, useEffect, useContext } from "react";
import { fetchApi } from "@tiny/utils";
import { ApiError } from "@tiny/utils/errors";
import { useToast } from "@/components/ToastService/useToast";
import { useRevalidator, useSearchParams } from "react-router-dom";
import { useScreenLoaderInvokers } from "@tiny/lib";
import { HeroImageContext, ImageContext } from "../Context/imageContext";
import { HeroImageHook } from "../types";

export function useHeroImage(): HeroImageHook {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { updateHeroImages, heroImagePagination } = useContext(
    ImageContext
  ) as HeroImageContext;

  const [searchParams, setSearchParams] = useSearchParams();
  const { hideScreenLoader, showScreenLoader } = useScreenLoaderInvokers();
  const revalidator = useRevalidator();
  const { showToast } = useToast();

  useEffect(() => {
    if (isLoading) {
      showScreenLoader();
    } else {
      hideScreenLoader();
    }
  }, [isLoading]);

  useEffect(() => {
    if (revalidator?.state === "idle" && isLoading) {
      hideScreenLoader();
    }
  }, [revalidator?.state]);

  const selectHeroImage = async (id: string, type: string) => {
    setIsLoading(true);
    setError(null);
    const imageType = type === "HERO" ? "HERO_CANDIDATE" : "HERO";
    try {
      await fetchApi(`/accountservice/account/image/${id}/type/${imageType}`, {
        method: "PUT",
      });
      updateHeroImages(id, { imageType });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err instanceof ApiError) {
        setError(err?.message);
        showToast({
          heading: "Maximum limit exceeded",
          message:
            "You can select up to 5 images to be Hero images in your campaigns.",
          type: "error",
          duration: 3000,
          portalId: "grid-container-toast",
        });
      }
      throw err;
    }
  };

  const deleteHeroImage = async (id: string) => {
    setIsLoading(true);
    setError(null);
    try {
      await fetchApi(`/accountservice/account/image/${id}`, {
        method: "DELETE",
      });
      if (
        heroImagePagination?.last &&
        heroImagePagination?.numberOfElements === 1 &&
        !heroImagePagination?.first
      ) {
        searchParams?.set("page", heroImagePagination?.number?.toString());
        setSearchParams(searchParams);
      } else {
        revalidator.revalidate();
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof ApiError) {
        setError(err?.message);
        showToast({
          message: err?.message,
          type: "error",
          duration: 3000,
          portalId: "grid-container-toast",
        });
      }
      throw err;
    }
  };

  const uploadHeroImage = async (files: File[]) => {
    showScreenLoader();
    setError(null);
    try {
      await Promise.all(
        files?.map((file) => {
          const formData = new FormData();
          formData.append("logoFile", file, file.name);
          return fetchApi(
            `/accountservice/account/image`,
            {
              method: "POST",
              body: formData,
            },
            1,
            true
          );
        })
      );
      searchParams.set("page", "1");
      setSearchParams(searchParams);
      showToast({
        duration: 5000,
        message: "file(s) has been successfully uploaded",
        type: "success",
        portalId: "grid-container-toast",
      });
    } catch (err) {
      hideScreenLoader();
      if (err instanceof ApiError) {
        setError(err?.message);
        showToast({
          message: err?.message,
          type: "error",
          duration: 3000,
          portalId: "grid-container-toast",
        });
      }
      throw err;
    }
  };

  return {
    isLoading,
    error,
    selectHeroImage,
    deleteHeroImage,
    uploadHeroImage,
  };
}
