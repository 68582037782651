import { ActionFunctionArgs } from "react-router-dom";
import { fetchApi } from "@tiny/utils";
import { ApiError } from "@tiny/utils/errors";

async function senderDetailsAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const senderDetailsData = formData.get("formData") as string;
  const senderDetailsId = formData.get("id") as string;
  if (!(senderDetailsData && senderDetailsId)) {
    return;
  }

  return fetchApi(`/accountservice/sender-details/${senderDetailsId}`, {
    method: "PUT",
    body: senderDetailsData,
    signal: request.signal,
  })
    .then(() => ({ ok: true }))
    .catch((error: ApiError) => ({ error }));
}

export { senderDetailsAction };
