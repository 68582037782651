import React from "react";
import { useTemplateList } from "./TemplateList.hooks";

type ContextValue = ReturnType<typeof useTemplateList>;

const TemplateListContext = React.createContext<ContextValue | null>(null);

export function useTemplateListView() {
  const contextValue = React.useContext(TemplateListContext);
  if (!contextValue) {
    throw Error("Should be used inside the TemplateListProvider");
  }
  return contextValue;
}

export const TemplateListProvider = TemplateListContext.Provider;
