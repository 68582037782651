import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M6 4L6 20H8.67363C9.02924 20.7486 9.51379 21.424 10.0986 21.9976C10.0659 21.9992 10.0331 22 10 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H10C11.1046 2 12 2.89543 12 4V10.6736C11.2504 11.0297 10.5741 11.5151 10 12.101L10 4L6 4Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M10 17C10 15.3642 10.7856 13.9118 12 12.9996C12.8357 12.3719 13.8744 12 15 12C17.7614 12 20 14.2386 20 17C20 19.7614 17.7614 22 15 22C13.7877 22 12.6761 21.5685 11.8105 20.8508C10.7045 19.9337 10 18.5491 10 17ZM12 18H18V16H12V18Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "DeleteColumn"
);
