export function makeNotInteractive(html: string): string {
  return addTabIndexToInteractiveElements(html);
}

function addTabIndexToInteractiveElements(html: string): string {
  let result = html;
  result = result.replaceAll("<button", "<button tabindex='-1'");
  result = result.replaceAll("<a", "<a tabindex='-1'");
  result = result.replaceAll("<input", "<input tabindex='-1'");
  result = result.replaceAll("<select", "<select tabindex='-1'");
  result = result.replaceAll("<textarea", "<textarea tabindex='-1'");
  return result;
}

export function getIframeHead(options: {
  interactive?: boolean;
  scrollable?: boolean;
  css?: string;
}): string {
  const result = [];
  if (options.css) {
    result.push("<style>");
    result.push(options.css);
    result.push("</style>");
  }
  result.push("<style>");
  result.push(`
  body { 
    margin: 0;
    padding: 0;
  }
`);
  if (!options.interactive) {
    result.push(`
      * { 
        user-select: none;
      }
    `);
    result.push(`
      [tabindex="-1"] {
        pointer-events: none;
      }
    `);
  }
  if (!options.scrollable) {
    result.push(`
      html {
        overflow: hidden;
      }
    `);
  }
  result.push("</style>");
  return result.join("");
}
