import React, { useEffect } from "react";

const { env } = import.meta;

interface VisitorInfoProps {
  visitorInfo: {
    name: string;
    email: string;
    contactnumber: string;
  };
}

const ZohoSalesIQ: React.FC<VisitorInfoProps> = ({ visitorInfo }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://salesiq.zohopublic.com/widget";
    script.async = true;
    script.id = "zsiqscript";
    script.defer = true;

    // Use type assertion to access the $zoho property
    const zohoObject = (window as any).$zoho || {};
    (window as any).$zoho = {
      salesiq: {
        widgetcode: import.meta.env?.VITE_APP_ZOHO_WIDGET_KEY,
        values: {},
        ready: () => {
          zohoObject.salesiq.visitor.name(visitorInfo.name);
          zohoObject.salesiq.visitor.email(visitorInfo.email);
          zohoObject.salesiq.visitor.contactnumber(visitorInfo.contactnumber);
        },
      },
      ...zohoObject,
    };

    document.head.appendChild(script);

    return () => {
      const scriptElement = document.getElementById("zsiqscript");
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  }, []);

  return null;
};

export default ZohoSalesIQ;
