import React from "react";
import { Tab as MuiTab, Theme } from "@mui/material";
import { TabProps } from "./Tab.types";

function Tab(props: TabProps) {
  return (
    <MuiTab
      {...props}
      sx={(theme: Theme) => ({
        minWidth: "initial",
        maxHeight: 5,
        minHeight: "initial",
        lineHeight: "16px",
        marginLeft: 4,
        padding: 0,
        textTransform: "initial",
        cursor: "pointer",
        fontSize: 4,
        color: `${theme.palette.grey[700]} !important`,
        "&:first-of-type": {
          marginLeft: 0,
        },
        fontWeight: 400,
        [theme.breakpoints.up("md")]: {
          maxHeight: 14,
          fontSize: 14,
          lineHeight: "14px",
        },
      })}
      disableRipple
      disableFocusRipple
    />
  );
}

export default Tab;
