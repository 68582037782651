import { UploadOutline } from "@tiny/icons";
import { Button } from "@mui/material";
import React from "react";
import { useAsyncValue, useSearchParams } from "react-router-dom";
import {
  GetGeneratedOrdersResponse,
  Orders,
} from "@/types/api/generatedRevenueOrders";
import { ActivityReportType, useExportReports } from "@/hooks/useExportReports";

function ExportOrdersButton() {
  const orders = useAsyncValue() as GetGeneratedOrdersResponse;
  const exportReports = useExportReports();

  const [searchParams] = useSearchParams();
  const urlParam = new URLSearchParams(searchParams);
  const activitiesIds = urlParam.get("activities")?.split(",");

  const exportActivityTypes = orders.content.map(
    (type: Orders) => type.activityType
  );

  const uniqueActivityTypes = [
    ...new Set(exportActivityTypes),
  ] as unknown as ActivityReportType[];

  return (
    <Button
      variant="text"
      onClick={() =>
        exportReports(
          uniqueActivityTypes,
          urlParam.get("activities")?.length ? activitiesIds : undefined
        )
      }
      endIcon={<UploadOutline />}
    >
      Export
    </Button>
  );
}

export default ExportOrdersButton;
