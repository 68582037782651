import * as React from "react";
import { Backdrop, Box } from "@mui/material";
import { hex2rgb } from "@tiny/utils/colors";
import useNotificationsService from "@/components/Notifications/useNotificationsService";
import { NotificationManagerProps } from "@/components/Notifications/types";
import NotificationsList from "./NotificationList";
import NotificationsControl from "./NotificationControl";
import { useNotificationsManager } from "./useNotificationsManager";

function NotificationsManager({
  isMenuOpened,
  onVisibleNotificationsChange,
}: NotificationManagerProps) {
  const { accountIssues, silenceNotification } = useNotificationsService({
    issuePresenceHandler: onVisibleNotificationsChange,
  });
  const { isCollapsed, handleToggleCollapse } = useNotificationsManager();
  const showBackdrop = !isCollapsed;
  const hasIssuesToDisplay = accountIssues.length > 0;

  return hasIssuesToDisplay ? (
    <>
      <Box
        id="notifications-container"
        sx={(theme) => ({
          position: "fixed",
          top: { xs: theme.spacing(18), sm: theme.spacing(4) },
          left: { xs: 0, sm: theme.spacing(isMenuOpened ? 64 : 20) },
          width: { xs: "100%", sm: "auto" },
          right: 0,
          zIndex: { xs: 2, md: 3 },
          mx: { xs: 0, sm: 10, md: 20 },
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
          alignItems: { xs: "center", sm: "stretch" },
          border: { xs: "0px", sm: "0.5px solid" },
          borderColor: { xs: "transparent", sm: "grey.70" },
          borderRadius: { xs: 0, sm: theme.spacing(2) },
          transition: "all 0.2s ease",
        })}
      >
        {accountIssues.length > 1 && (
          <NotificationsControl
            collapsed={isCollapsed}
            toggleHandler={handleToggleCollapse}
            counter={accountIssues.length}
          />
        )}

        <NotificationsList
          entries={accountIssues}
          isCollapsed={isCollapsed}
          silenceNotificationHandler={silenceNotification}
        />
      </Box>
      <Backdrop
        sx={(theme) => ({
          zIndex: { xs: 1, md: 2 },
          backgroundColor: hex2rgb(theme.palette.grey[100], 0.7),
        })}
        open={showBackdrop}
        onClick={handleToggleCollapse}
      />
    </>
  ) : null;
}
export default NotificationsManager;
