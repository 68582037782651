import { useSendSenderDetailsNotification } from "@/hooks/useSendSenderDetailsNotification";

function useNotificationActionMapper() {
  const { handleSendSenderDetailsRequest } = useSendSenderDetailsNotification();
  const links = {
    CAMPAIGN_PAGE: "campaigns/overview",
    PLANS_PAGE: "account-settings/plans",
    SENDER_PAGE: "account-settings/sender-details",
  };
  const actions = {
    RESEND_CONFIRMATION_SENDER_EMAIL: handleSendSenderDetailsRequest,
  };

  return { links, actions };
}

export default useNotificationActionMapper;
