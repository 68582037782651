import React, { Component } from "react";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { Box, Typography, Grid, Button, Divider } from "@mui/material";
import { ShortRight } from "@tiny/icons";
import { useTemplateListView } from "../TemplateList.context";
import { TemplateSectionProps } from "./TemplateSection.types";
import TemplateItem from "./TemplateItem";
import ComponentLoader from "../../ComponentLoader/ComponentLoader";

function TemplateSection(props: TemplateSectionProps) {
  const {
    title,
    total,
    hasMore,
    showAll,
    templates,
    columnSize = 2,
    favoritable = true,
    selectable = false,
    saved,
    renderWithEjs,
    type,
    isSelected,
    toggleSelect,
    selectDisabled,
    isCurrentSelected,
    loading,
  } = props;

  const { campaignName } = useTemplateListView();

  const { mdUp } = useBreakpoints();

  return (
    <Box>
      <Box p={mdUp ? 0 : 1} mb={mdUp ? 1 : 0}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item flex="1">
            <Typography
              variant="h4"
              component="h3"
              mt={0}
              mb={0}
              fontWeight="bold"
              color="grey"
            >
              {`${title} ${total > 0 ? ` (${total})` : ""}`}
            </Typography>
          </Grid>
          {!loading && hasMore && (
            <Grid item>
              <Box>
                <Button
                  variant="text"
                  onClick={() => showAll?.(saved, type)}
                  endIcon={<ShortRight />}
                >
                  Show all
                </Button>
              </Box>
            </Grid>
          )}
        </Box>
      </Box>
      {loading ? (
        <ComponentLoader height="200px" />
      ) : (
        <Grid container spacing={mdUp ? 4 : 0}>
          {templates.map((template, index) => (
            <Grid key={template.id} item xs={12} md={columnSize}>
              {!mdUp && index > 0 && <Divider />}
              <TemplateItem
                css={template.templateCss}
                id={template.id}
                favoritable={favoritable}
                favorited={template.isFavorite}
                html={template.templateHtml}
                name={
                  campaignName && isCurrentSelected
                    ? campaignName
                    : template?.name
                }
                selectable={selectable}
                saved={saved}
                sectionTitle={title}
                template={template}
                renderWithEjs={renderWithEjs}
                type={type}
                toggleSelect={toggleSelect}
                isSelected={isSelected}
                selectDisabled={selectDisabled}
                isCurrentSelected={isCurrentSelected}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default TemplateSection;
