import * as React from "react";
import { Navigate, useRouteLoaderData } from "react-router-dom";
import InstallingPage from "@/routes/InstallingPage";
import type { RootLoader } from "@/types/RootLoader";

function OnboardingIndex() {
  const { userStatus } = useRouteLoaderData("root") as RootLoader;

  if (userStatus?.onboardingStep === "USER_CREATED") {
    return <InstallingPage />;
  }

  if (userStatus?.onboardingStep === "ACCOUNT_CREATED") {
    return <Navigate to="brand-voice" />;
  }

  return <Navigate to="plan-selection" />;
}

export { OnboardingIndex };
