import axios from "axios";
import { applyAxiosUnauthorizedHandler } from "./axiosAddons";
import { getUtcTimestamp } from "../utils/dateUtils";

export interface NewUser {
  email: string;
  password: string;
  confirmationPassword: string;
  talon: string;
  source?: string;
}

export interface Login {
  username: string;
  password: string;
}

export interface ResetPassword {
  email: string;
}

export interface SetupNewPassword {
  token: string;
  password: string;
  confirmationPassword: string;
}

export interface ChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmationPassword: string;
}

export interface ConfirmEmail {
  token?: string;
}

export type OAuthType = "login" | "sign-up";

export interface GoogleAuthRequset {
  idToken?: string;
  accessToken?: string;
  code?: string;
  redirectUri?: string;
  action: OAuthType;
}

export interface GoogleTokenResponse {
  id_token?: string;
  access_token?: string;
}

export interface GoogleAuthResponse {
  tokenInfo?: LoginResult;
  requiredAction?: OAuthType;
  googleTokenResponse?: GoogleTokenResponse;
}

export interface LoginRequestPayload {
  access_token: string;
  expires_in: number;
  jti: string;
  refresh_token: string;
  scope: string;
  sub: string;
  token_type: string;
}

export interface RefreshToken {
  refresh_token: string;
}

export type UserStatus =
  | "CREATED"
  | "ACTIVE"
  | "EMAIL_CONFIRMED"
  | "ACCOUNT_CREATED"
  | "DELETED"
  | "BLOCKED";

export interface UserStatusOverview {
  accountStatus:
    | "ACTIVE"
    | "FROZEN"
    | "PAUSED"
    | "CANCELED"
    | "LATE"
    | "LATE_TRIAL";
  userStatus: "CREATED" | "ACTIVE" | "DELETED" | "BLOCKED";
  onboardingStep:
    | "USER_CREATED"
    | "EMAIL_CONFIRMED"
    | "ACCOUNT_CREATED"
    | "IDENTITY_CREATED"
    | "INTEGRATION_ADDED"
    | "FINISHED";
  provider: string;
}

export interface User {
  id: string;
  userEmail: string;
  userStatus: UserStatus;
}

export interface LoginResult {
  access_token: string;
  refresh_token: string;
}

type AuthToken =
  | "accessToken"
  | "refreshToken"
  | "keepLoggedIn"
  | "sessionExpire";

class AuthApi {
  instance;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });
    applyAxiosUnauthorizedHandler(this);
    this.instance.interceptors.request.use((config: any) => {
      const token = localStorage.getItem("accessToken");
      if (!!token && config.headers && !!config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  setTokens(data: LoginResult, isKeepLoggedIn = false) {
    localStorage.setItem("accessToken", data.access_token);
    localStorage.setItem("refreshToken", data.refresh_token);
    if (isKeepLoggedIn) {
      localStorage.setItem("keepLoggedIn", data.refresh_token);
    }
    localStorage.setItem(
      "sessionExpire",
      `${getUtcTimestamp(4 * 60 * 60 * 1000)}`
    );
  }

  removeTokens(
    tokens: AuthToken[] = [
      "accessToken",
      "refreshToken",
      "keepLoggedIn",
      "sessionExpire",
    ]
  ) {
    tokens.forEach((token) => localStorage.removeItem(token));
  }

  silentReAuth() {
    const refresh_token = localStorage.getItem("keepLoggedIn");
    if (refresh_token) {
      return this.refreshToken({ refresh_token }).then((response: any) => {
        this.setTokens(response.data, true);
        return response.data;
      });
    }
    return Promise.reject({});
  }

  private getAuthorization() {
    return "Bearer " + localStorage.getItem("accessToken");
  }

  createUser(data: NewUser) {
    return this.instance.post("/authorization-server/user", data);
  }

  sendConfirmEmail() {
    return this.instance.request({
      url: "/authorization-server/user/email-confirm-token",
      method: "POST",
      headers: {
        Authorization: this.getAuthorization(),
      },
    });
  }

  login(data: Login) {
    const { username, password } = data;

    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("username", username);
    params.append("password", password);

    return this.instance.post("/authorization-server/oauth/token", params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      auth: {
        username: "Web",
        password: "2222",
      },
    });
  }

  refreshToken(data: RefreshToken) {
    const { refresh_token } = data;

    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refresh_token);

    const requestConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      auth: {
        username: "Web",
        password: "2222",
      },
      skipAuthRefresh: true,
    };

    return this.instance.post(
      "/authorization-server/oauth/token",
      params,
      requestConfig
    );
  }

  resetPassword(data: ResetPassword) {
    return this.instance.post(
      "/authorization-server/user/forgot-password-token",
      data
    );
  }

  setupNewPassword(data: SetupNewPassword) {
    return this.instance.post(
      "/authorization-server/user/confirm-forgot-password",
      data
    );
  }

  changePassword(data: ChangePassword) {
    return this.instance.post(
      "/authorization-server/user/change-password",
      data,
      {
        headers: {
          Authorization: this.getAuthorization(),
        },
      }
    );
  }

  confirmEmail(data: ConfirmEmail) {
    return this.instance.post("/authorization-server/user/confirm-email", data);
  }

  getUser() {
    return this.instance.request<User>({
      url: "/authorization-server/user",
      method: "GET",
      headers: {
        Authorization: this.getAuthorization(),
      },
    });
  }

  getUserStatus() {
    return this.instance.request<UserStatus>({
      url: "/authorization-server/user/status",
      method: "GET",
      headers: {
        Authorization: this.getAuthorization(),
      },
    });
  }

  putAccountIntegrationSave() {
    return this.instance.request<UserStatusOverview>({
      url: "/authorization-server/account-integration/save",
      method: "PUT",
      headers: {
        Authorization: this.getAuthorization(),
      },
    });
  }

  googleAuth(data: GoogleAuthRequset) {
    return this.instance.post(
      "/authorization-server/third-party/oauth/google",
      data
    );
  }
}

let baseUrl = "";

const authApi = new AuthApi(baseUrl);

export default authApi;
