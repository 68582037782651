import React from "react";
import type { SenderDetailsRow } from "@/routes/AccountSettings/SenderDetails/SenderDetails.types";
import { AutomationTemplate } from "@/types/api/template";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchApi } from "@tiny/utils";
import { useConfirmationDialog } from "@/routes/Automations/useConfirmationDialog.hook";
import {
  CloseSmall,
  GenerativeAi,
  InfoInfo,
  MoreHorizontal,
} from "@tiny/icons";
import {
  Container,
  Box,
  Typography,
  Button,
  Divider,
  Alert,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import {
  useDialogInvokers,
  useDrawerInvokers,
  useScreenLoaderInvokers,
} from "@tiny/lib";
import { TemplatePreview } from "@tiny/lib/AutomationPreview";
import {
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import { ApiError, SenderNotConfirmedError } from "@tiny/utils/errors";
import SenderDetailsConfirmationDialogContent from "@/components/SenderDetailsConfirmationDialogContent";
import SuccessDialogContent from "@/routes/Campaigns/Calendar/Table/SuccessDialogContent";
import SendTestEmail from "./SendTestEmail";
import { AutomationPreviewProps } from "./types";

const AutomationInfo = () => (
  <Alert
    severity="info"
    variant="outlined"
    iconMapping={{
      info: (
        <InfoInfo
          sx={(theme) => ({
            color: theme.palette.primary[100],
          })}
        />
      ),
    }}
  >
    <Typography variant="p3">
      This preview is generated using a random product from your store. The
      actual email will show products based on the user's cart/purchase.
    </Typography>
  </Alert>
);

const AutomationPreview = ({
  automationId,
  showRegenerate,
  showInfoMessage,
  regenerate = false,
  enabled,
  name,
}: AutomationPreviewProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const { senderDetails: detailsData } = useRouteLoaderData("main") as {
    senderDetails: SenderDetailsRow;
  };
  const { hideDrawer } = useDrawerInvokers();
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderInvokers();
  const [template, setTemplate] = React.useState<AutomationTemplate>();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);

  const sm = useMediaQuery("(max-width:600px)");
  const { requestMediumDialog, requestSmallDialog, exitDialog } =
    useDialogInvokers();
  const revalidator = useRevalidator();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showSendTest, setShowSendTest] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChangeActivation = () => {
    showScreenLoader();
    const callback = () => {
      revalidator.revalidate();
      hideScreenLoader();
      hideDrawer();
      requestSmallDialog({
        contentProps: {
          dividers: false,
          sx: {
            border: "none",
          },
          children: (
            <SuccessDialogContent
              title={`Successfully ${enabled ? "Deactivated" : "Activated"}`}
              description={`Automation ${
                enabled ? "Deactivated" : "Activated"
              }`}
              onClickHandler={() => {
                exitDialog();
              }}
            />
          ),
        },
      });
    };
    handleActivation(!enabled, callback);
  };

  const handleActivation = (state: boolean, callback: () => void = () => {}) =>
    fetchApi(
      `/automationservice/automation/${automationId}/enabled/${state.toString()}`,
      {
        method: "put",
      }
    )
      .then(() => callback())
      .catch((error: ApiError) => {
        if (error instanceof SenderNotConfirmedError) {
          requestMediumDialog({
            titleProps: {},
            contentProps: {
              dividers: false,
              sx: {
                border: "none",
              },
              children: <SenderDetailsConfirmationDialogContent />,
            },
          });
        }
        hideScreenLoader();
      });

  const onClickEdit = () => {
    const handleEdit = () => {
      hideDrawer();
      showScreenLoader();
      navigate(`/main/automations/${automationId || ""} /edit/details`);
    };
    if (sm) {
      return showConfirmationDialog(
        "Editing is not supported in the mobile view.",
        "Cannot Edit",
        "Ok",
        () => {},
        false
      );
    }
    if (!loading) {
      if (enabled) {
        showConfirmationDialog(
          "You are trying to edit template of an active automation, editing template of an active automation will pause that automation, please ensure you activate the automation post edit completion.",
          "Edit Automation Template",
          "Start Editing",
          () => {
            handleActivation(false, handleEdit);
          },
          true
        );
      } else {
        handleEdit();
      }
    }
  };

  const fetchTemplate = () =>
    fetchApi<AutomationTemplate>(
      `/automationservice/automation-layout/preview`,
      {
        method: "put",
        body: JSON.stringify({
          automationId,
        }),
      }
    );
  const handleRegenerate = (e?: any) => {
    e?.preventDefault();
    const invokeRegenerate = async (activate = false) => {
      setLoading(true);
      await fetchApi(
        `/automationservice/automation/${automationId}/einsteinize`,
        {
          method: "post",
        }
      );
      await automationLoader(activate);
    };

    if (enabled) {
      showConfirmationDialog(
        "You are trying to regenerate template of an active automation, regeneration will change the current template with a new one. Are you sure you want to continue?",
        "Regenerate Automation Template",
        "Continue",
        async () => {
          setLoading(true);
          await handleActivation(false);
          await invokeRegenerate(true);
          setLoading(false);
        },
        true
      );
    } else {
      invokeRegenerate();
    }
  };

  const automationLoader = async (activate = false) => {
    setLoading(true);
    const templateResponse = await fetchTemplate();
    if (templateResponse.inGenerationState) {
      setTimeout(() => {
        automationLoader();
      }, 300);
      return;
    }
    setLoading(false);
    setTemplate(templateResponse);
    if (activate) {
      handleActivation(true);
    }
  };

  React.useEffect(() => {
    if (template) {
      setLoading(false);
    }
  }, [template]);
  React.useEffect(() => {
    if (regenerate) {
      handleRegenerate();
    } else {
      automationLoader();
    }
  }, []);
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: sm ? "100vw" : "40vw",
        borderRadius: "20px 0px 0px 0px",
        overflow: "hidden",
      }}
      disableGutters
      maxWidth={false}
    >
      <Box
        p={6}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h2" color="grey.100">
          Preview email
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          gap={4}
        >
          <Chip
            label={name}
            title={name}
            sx={(theme) => ({
              background: theme.palette.grey[30],
              color: theme.palette.grey[90],
              fontWeight: 400,
              fontSize: theme.spacing(3),
              maxWidth: theme.spacing(100),
            })}
          />

          <Box
            sx={(theme) => ({
              border: `0.5px solid ${theme.palette.grey[40]}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: 24,
              height: 24,
            })}
            onClick={hideDrawer}
          >
            <CloseSmall
              sx={() => ({
                cursor: "pointer",
              })}
            />
          </Box>
        </Box>
      </Box>
      <Box
        px={6}
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TemplatePreview
          html={template?.html}
          css={template?.css}
          isLoading={loading}
          subject={template?.subjectLine}
          from={`${detailsData.name} <${detailsData.email}>`}
          Info={showInfoMessage && <AutomationInfo />}
        />
      </Box>
      {showRegenerate && (
        <>
          <Divider sx={{ mx: 6 }} />
          <Menu
            id="secondary-menu"
            anchorEl={anchorEl}
            open={open}
            transformOrigin={{ vertical: "bottom", horizontal: "left" }}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            onClose={handleCloseMenu}
            sx={{ mb: 6 }}
            PaperProps={{
              style: {
                marginTop: -12,
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
                setShowSendTest(true);
              }}
            >
              <Typography variant="p2">Send Test</Typography>
            </MenuItem>
            <MenuItem onClick={onClickEdit}>
              <Typography variant="p2">Edit</Typography>
            </MenuItem>
          </Menu>
          <Box
            p={6}
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mr: theme.spacing(16),
            })}
          >
            <Box>
              <Button
                sx={{ px: 4, py: { xl: 2.5, sm: 1.5 }, pr: 3 }}
                variant="outlined"
                endIcon={<MoreHorizontal />}
                onClick={(e) => {
                  if (!loading) handleOpenMenu(e);
                }}
                disabled={loading}
              >
                More
              </Button>
              <SendTestEmail
                open={showSendTest}
                setOpen={setShowSendTest}
                html={template?.html || ""}
                css={template?.css || ""}
                preview={template?.preview || ""}
                subject={template?.subjectLine || ""}
              />
            </Box>

            <Box gap={3} display="flex">
              <Button
                id="campaigns-overview-preview-regenerate"
                variant="outlined"
                className="regenerate-cta"
                disabled={loading}
                type="submit"
                sx={{ py: { xl: 2, sm: 1.5 } }}
                startIcon={<GenerativeAi sx={{ marginRight: -1.5 }} />}
                onClick={handleRegenerate}
              >
                Regenerate
              </Button>
              <Button
                id={
                  enabled
                    ? "automation-preview-deactivate"
                    : "automation-preview-activate"
                }
                variant="contained"
                onClick={onChangeActivation}
                sx={{ px: 5, py: { xl: 2.5, sm: 1.5 } }}
                disabled={loading}
              >
                {enabled ? "Deactivate" : "Activate"}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default AutomationPreview;
