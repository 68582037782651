import { GetFormsResponse } from "@/types/api/forms";
import { Typography } from "@mui/material";
import { InfoDialog } from "@tiny/lib/ConfirmationDialog/DialogTypes/InfoDialog";
import * as React from "react";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

function FormInfo() {
  const { id } = useParams();
  const formsData = useRouteLoaderData("forms") as GetFormsResponse;
  const [{ infoDetails, name }] = formsData.forms.filter(
    (form) => form.id === id
  );
  const navigate = useNavigate();
  const redirect = () => {
    navigate(-1);
  };

  return (
    <InfoDialog
      title={name}
      open
      onClose={redirect}
      content={
        <>
          <Typography variant="h4" mt={4}>
            Details
          </Typography>
          <Typography variant="p2" color="grey.80" mt={1} mb={4}>
            {infoDetails}
          </Typography>
        </>
      }
    />
  );
}

export { FormInfo };
