import type { DialogPropsOverrides } from "./DialogManager.types";
import { DialogProps } from "./Dialog";

const defaultOverrides: DialogPropsOverrides = {
  fullScreen: false,
  fullWidth: true,
  maxWidth: "md",
};

function getForSize(maxWidth: DialogProps["maxWidth"]): DialogPropsOverrides {
  return {
    ...defaultOverrides,
    maxWidth,
  };
}

export { getForSize };
