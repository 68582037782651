import { useTheme } from "@mui/material";
import React from "react";
import { MuiColorInput, MuiColorInputProps } from "mui-color-input";

function ColorInput(props: MuiColorInputProps) {
  const theme = useTheme();
  return (
    <MuiColorInput
      {...props}
      InputLabelProps={{
        sx: {
          fontWeight: theme.typography.fontWeightBold,
          backgroundColor: "common.white",
          left: theme.spacing(9),
        },
      }}
      sx={{
        "& .MuiInputAdornment-root": {
          "&> .MuiButtonBase-root": {
            borderRadius: "50%",
            boxShadow: "none",
            width: theme.spacing(6),
            height: theme.spacing(6),
          },
        },
        "& .MuiInputBase-root": {
          paddingLeft: theme.spacing(2),

          "& > .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.grey[70]} !important`,
            "&> legend": {
              marginLeft: theme.spacing(9.5),
            },
          },
        },
        "& .MuiOutlinedInput-input": {
          paddingLeft: theme.spacing(3),
          borderLeft: `1px solid ${theme.palette.grey[70]}`,
        },
      }}
    />
  );
}

export default ColorInput;
