import React, { useState } from "react";
import { Box, Card, useTheme } from "@mui/material";
import useTemplateLayoutPreviewer from "@/hooks/useTemplateLayoutPreviewer";
import CampaignHeader from "@/components/Campaign/CampaignHeader";
import { Campaign } from "@/types/api/campaigns";
import CampaignContent from "./CampaignContent";
import CampaignFooter from "./CampaignFooter";
import { useCampaign } from "./hooks";

interface CampaignCardProps extends Campaign {
  onCampaignHover: (id: string) => void;
  onCampaignUnhover: () => void;
  isCampaignHovered: (id: string) => boolean;
}

function CampaignCard(props: CampaignCardProps) {
  const {
    template,
    subject,
    name,
    status,
    description,
    startTime,
    outdated,
    id,
    generatedBy,
    isCampaignHovered,
    onCampaignHover,
    onCampaignUnhover,
  } = props;
  const theme = useTheme();
  const { activeState, onChangeActivation } = useCampaign(props);
  const previewClickHandler = useTemplateLayoutPreviewer();

  const showDetails = isCampaignHovered(id);

  return (
    <Card
      id={`campaign-${id}`}
      sx={{
        bgcolor: outdated ? "transparent" : "common.white",
        display: "flex",
        flexDirection: "column",
        border: `0.5px solid ${theme.palette.grey[70]}`,
        borderRadius: "8px",
        height: "100%",
        gap: 2,
        boxShadow: "none",
        position: "relative",
      }}
      className="campaign-card"
    >
      <CampaignHeader {...props} />
      <div
        onMouseEnter={() => {
          onCampaignHover(id);
        }}
        onMouseLeave={() => {
          onCampaignUnhover();
        }}
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "auto",
          zIndex: 1,
        }}
      >
        <Box
          p={4}
          height="100%"
          display="flex"
          flexDirection="column"
          gap={0}
          sx={{
            height: "100%",
            width: "100%",
            backgroundImage:
              "linear-gradient(10deg, rgba(238, 233, 255, 0.9) 2.64%, rgba(238, 233, 255, 0.95) 97.11%)",
          }}
        >
          <CampaignContent
            name={name}
            description={
              generatedBy === "System" ? description : subject ?? description
            }
            showDetails={showDetails}
          />
          {!(status === "RECURRING" || status === "STOPPED") && (
            <CampaignFooter
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                paddingTop: 2,
              }}
              startTime={startTime}
              onActivationChange={onChangeActivation}
              onPreviewClick={() =>
                previewClickHandler({
                  html: template.templateHtml,
                  css: template.templateCss,
                })
              }
              active={activeState || false}
              status={status}
              outdated={outdated}
              generatedBy={generatedBy}
            />
          )}
        </Box>
      </div>
    </Card>
  );
}

export default CampaignCard;
