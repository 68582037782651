import { Theme } from "@mui/material";
import { FontWeight } from "../../typography";

// TODO: Refactor this component to add array of colors
const root = ({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.blue[75],
  color: theme.palette.common.white,
  fontWeight: FontWeight.bold,
});

const styleOverrides = {
  root,
};

export { styleOverrides };
