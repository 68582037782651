import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M12 22C6.47967 21.994 2.00606 17.5204 2 12V11.8C2.10993 6.30455 6.63459 1.92797 12.1307 2.0009C17.6268 2.07382 22.0337 6.5689 21.9978 12.0654C21.9619 17.5618 17.4966 21.9989 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57931 16.4087 4.00002 11.992 4.00002C7.57528 4.00002 3.99421 7.57931 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M9 14H10.3333C10.3333 14.72 11.2467 15.3333 12.3333 15.3333C13.42 15.3333 14.3333 14.72 14.3333 14C14.3333 13.2667 13.64 13 12.1733 12.6467C10.76 12.2933 9 11.8533 9 10C9 8.80667 9.98 7.79333 11.3333 7.45333V6H13.3333V7.45333C14.6867 7.79333 15.6667 8.80667 15.6667 10H14.3333C14.3333 9.28 13.42 8.66667 12.3333 8.66667C11.2467 8.66667 10.3333 9.28 10.3333 10C10.3333 10.7333 11.0267 11 12.4933 11.3533C13.9067 11.7067 15.6667 12.1467 15.6667 14C15.6667 15.1933 14.6867 16.2067 13.3333 16.5467V18H11.3333V16.5467C9.98 16.2067 9 15.1933 9 14Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "Revenue"
);
