import * as React from "react";
import { CommonDialogConfig } from "./DialogManager.types";
import { useDialogManager } from "./DialogManager.context";

const useDialogInvokers = () => {
  const { requestDialogBySize, clearDialogConfig } = useDialogManager();

  const requestSmallDialog = React.useCallback(
    (configOverrides: CommonDialogConfig) => {
      requestDialogBySize("sm", configOverrides);
    },
    [requestDialogBySize]
  );
  const requestMediumDialog = React.useCallback(
    (configOverrides: CommonDialogConfig) => {
      requestDialogBySize("md", configOverrides);
    },
    [requestDialogBySize]
  );

  const requestLargeDialog = React.useCallback(
    (configOverrides: CommonDialogConfig) => {
      requestDialogBySize("lg", configOverrides);
    },
    [requestDialogBySize]
  );

  const requestInfoDialog = React.useCallback(
    (infoJsx: React.ReactNode) =>
      requestSmallDialog({
        overrides: { disableEscapeKeyDown: true },
        contentProps: {
          dividers: false,
          sx: {
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 6,
            p: 2,
          },
          children: infoJsx,
        },
      }),
    [requestSmallDialog]
  );

  return {
    requestSmallDialog,
    requestMediumDialog,
    requestLargeDialog,
    requestInfoDialog,
    exitDialog: clearDialogConfig,
  };
};

export { useDialogInvokers };
