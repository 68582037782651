import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M10 4V11H4V4H2V20H4V13H10V20H12V4H10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M21.7451 9.92V8H15.6273L14.4853 14.0902L16.3309 14.9581C16.7051 14.5203 17.2791 14.24 17.9215 14.24C19.0482 14.24 19.9607 15.0992 19.9607 16.16C19.9607 17.2208 19.0482 18.08 17.9215 18.08C16.9906 18.08 16.2055 17.4925 15.9607 16.6899L14 17.2189C14.4884 18.825 16.0586 20 17.9215 20C20.1749 20 22 18.2816 22 16.16C22 14.0384 20.1749 12.32 17.9215 12.32C17.5554 12.32 17.2006 12.3651 16.8641 12.4506L17.3372 9.92H21.7451Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "HeadingH5"
);
