import React from "react";
import { useDialogInvokers } from "@tiny/lib";
import { ConfirmationRequestContent } from "@/components/ContentSetupStrategy/Dialogs/ConfirmationRequestContent";
import SuccessMessageContent from "@/components/ContentSetupStrategy/Dialogs/SuccessMessageContent";
import { useRegenerateAction } from "@/hooks/useRegenerateAction";
import { FormMethod } from "react-router-dom";

export interface RegenerateAllConfirmationDialogProps {
  method: FormMethod;
  action: string;
  description: string;
  successMessage: string;
}

function RegenerateAllConfirmationDialog(
  props: RegenerateAllConfirmationDialogProps
) {
  const { method, action, description, successMessage } = props;
  const { exitDialog } = useDialogInvokers();

  const { submitHandler, isSubmitting, submitCompleted } = useRegenerateAction(
    method,
    action
  );

  if (!submitCompleted) {
    return (
      <ConfirmationRequestContent
        confirmClickHandler={submitHandler}
        isGenerationInProgress={isSubmitting}
        closeDialogHandler={exitDialog}
        description={description}
      />
    );
  }

  return (
    <SuccessMessageContent
      closeDialogHandler={exitDialog}
      successMessage={successMessage}
    />
  );
}

export { RegenerateAllConfirmationDialog };
