import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path d="M6 6H8V8H6V6Z" xmlns="http://www.w3.org/2000/svg" />
    <path
      d="M3 11V3H11V11H3ZM5 5V9H9V5H5Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path d="M13 13H16V15H13V13Z" xmlns="http://www.w3.org/2000/svg" />
    <path
      d="M16 15H18V13H21V16H18V17H19V18H21V21H19V19H17V21H13V17H15V19H16V15Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path d="M18 6H16V8H18V6Z" xmlns="http://www.w3.org/2000/svg" />
    <path
      d="M13 3V11H21V3H13ZM19 5V9H15V5H19Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path d="M6 16H8V18H6V16Z" xmlns="http://www.w3.org/2000/svg" />
    <path
      d="M3 21V13H11V21H3ZM5 15V19H9V15H5Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "QrCode2"
);
