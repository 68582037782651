import React from "react";
import { Box, Grid, TextField } from "@mui/material";
import { BasicInfoFormProps } from "@/components/ProfileForm/types";
import TruncatedTextField from "@/components/TruncatedTextField";

function BasicInfoForm(props: BasicInfoFormProps) {
  const { firstName, lastName, phoneNumber, userEmail } = props;
  return (
    <Box borderRadius={2} bgcolor="common.white" p={6}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TruncatedTextField
            fullWidth
            id="first-name"
            value={firstName}
            size="small"
            name="firstName"
            disabled
            label="First Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TruncatedTextField
            fullWidth
            id="last-name"
            value={lastName}
            size="small"
            name="lastName"
            disabled
            label="Last Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TruncatedTextField
            fullWidth
            id="user-email"
            value={userEmail}
            size="small"
            name="userEmail"
            disabled
            label="Email address"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="phone-number"
            value={phoneNumber}
            name="phoneNumber"
            size="small"
            disabled
            label="Phone Number"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BasicInfoForm;
