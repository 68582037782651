import React, { useState } from "react";
import {
  Button,
  Box,
  Container,
  useMediaQuery,
  Divider,
  Alert,
  Typography,
  IconButton,
} from "@mui/material";
import { useDrawerInvokers } from "@tiny/lib";
import { fetchApi } from "@tiny/utils";
import { useForm, FormProvider } from "react-hook-form";
import { useRevalidator } from "react-router-dom";
import { ApiError } from "@tiny/utils/errors";
import { Campaign } from "@/types/api/campaigns";
import { combineDateTimeToUtcMilliseconds } from "@tiny/utils/dateUtils";
import { addHours, isBefore } from "date-fns";
import CampaignPreview from "@/components/Campaign/CampaignPreview";
import { LoadingButton } from "@mui/lab";
import { CloseBig } from "@tiny/icons";
import EmailContent from "./FormSections/EmailContent";
import Details from "./FormSections/Details";
import { FormFields } from "./types";
import Scheduled from "./FormSections/Scheduled";
import Audiences from "./FormSections/Audiences";
import FormHeader from "./FormSections/FormHeader";
import { capitalizeWords } from "./helper";
import { getCampaignStatus } from "../Calendar/helpers";
import Discount from "./FormSections/Discount";
import { useConfirmationDialog } from "../useConfirmationDialog.hook";

const AddCampaignForm = ({
  editMode,
  formData,
}: {
  editMode?: boolean;
  formData?: Campaign;
}) => {
  const sm = useMediaQuery("(max-width:600px)");
  const { showDrawer, hideDrawer } = useDrawerInvokers();
  const revalidator = useRevalidator();
  const methods = useForm<FormFields>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { showConfirmationDialog } = useConfirmationDialog();

  const toggleActivation = (status: boolean, callback: () => void = () => {}) =>
    fetchApi(
      `/campaignservice/campaign/${
        formData?.id ? formData.id : ""
      }/enabled/${status.toString()}`,
      {
        method: "put",
      }
    )
      .then(() => {
        callback();
      })
      .catch(() => {
        setIsLoading(false);
      });

  const onSubmit = (formFields: FormFields) => {
    const startTime = combineDateTimeToUtcMilliseconds(
      formFields.scheduledDate,
      formFields.time
    );
    const futureDate = addHours(new Date(), 12);
    if (isBefore(new Date(startTime), futureDate)) {
      setError("Scheduled date and time must greater than next 12 hours");
      return;
    }
    const payload = {
      name: capitalizeWords(formFields.name),
      description: formFields.campaignDescription,
      type: formFields.type,
      language: formFields.language,
      startTime,
      audiences: formFields.audiences
        ?.filter((aud) => aud.audienceName !== "All")
        .map((aud) => aud?.audienceId),
      includeNonSubscribedAsReceivers:
        formFields.includeNonSubscribedAsReceivers,
      contents: formFields.contents,
      discountId: formFields.discountId ? formFields.discountId : null,
      discountValue: formFields.discountValue
        ? +formFields.discountValue
        : null,
    };
    const createOrUpdateCampaign = () => {
      setIsLoading(true);
      const url = editMode
        ? `/campaignservice/campaign/${formData?.id as string}`
        : `/campaignservice/campaign`;
      fetchApi<Campaign>(url, {
        method: editMode ? "put" : "post",
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          const { enabled, outdated, name } = response;
          const campaignStatus = {
            status: formData?.status ? formData.status : "DRAFT",
            enabled: formData?.enabled ? formData.enabled : enabled,
            outdated: formData?.outdated ? formData.outdated : outdated,
          };

          if (formData?.enabled) {
            await pollUntilNotGenerating(formData?.id);
            await toggleActivation(true);
          }
          setIsLoading(false);
          showDrawer({
            children: (
              <CampaignPreview
                campaignId={response.id}
                name={name}
                campaignStatus={getCampaignStatus(campaignStatus)?.status}
                regenerate={false}
                onClose={() => {
                  revalidator.revalidate();
                }}
                campaignGeneratedBy={response.generatedBy}
              />
            ),
            onClose: () => {
              revalidator.revalidate();
            },
          });
        })
        .catch((err: ApiError) => {
          setIsLoading(false);
          setError(
            err?.message === "unhandled"
              ? "Something went wrong!"
              : err?.message
          );
          console.log("error", error);
        });
    };
    const discounts = formFields?.coupons || [];
    if (
      !formFields?.discountId &&
      discounts
        ?.map((discount) => discount?.percentage?.toString())
        ?.includes(formFields?.discountValue)
    ) {
      showConfirmationDialog(
        "You're attempting to create a new discount, but a similar discount already exists. You can utilize the existing one.",
        "Coupon Exists",
        "Create anyway",
        () => {
          createOrUpdateCampaign();
        },
        true
      );
      return;
    }

    if (formData?.enabled) {
      toggleActivation(false, createOrUpdateCampaign);
    } else {
      createOrUpdateCampaign();
    }
  };

  const fetchStatus = async (campaignId: string) => {
    const response = await fetchApi<Campaign>(
      `/campaignservice/campaign/${campaignId}`,
      {
        method: "GET",
      }
    );
    return response?.status;
  };

  const pollUntilNotGenerating = async (
    campaignId: string
  ): Promise<string> => {
    const status = await fetchStatus(campaignId);
    if (status === "GENERATE") {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
      return pollUntilNotGenerating(campaignId);
    }
    return status;
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: sm ? "100vw" : "40vw",
        overflow: "hidden",
      }}
      disableGutters
      maxWidth={false}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box px={6}>
                <FormHeader
                  formData={formData}
                  editMode={editMode}
                  hideDrawer={() => {
                    hideDrawer();
                  }}
                />
              </Box>
              <Divider />
            </Box>
            <Box
              sx={{ overflowY: "auto", overflowX: "hidden" }}
              flex={1}
              px={6}
            >
              <Details formData={formData} />
              <Divider />
              <EmailContent formData={formData} />
              <Divider />
              <Scheduled formData={formData} />
              <Divider />
              <Audiences formData={formData} />
              <Divider />
              <Discount formData={formData} />
            </Box>
            <Box>
              <Divider />
              {error && (
                <Alert
                  variant="outlined"
                  severity="error"
                  sx={{
                    m: 5,
                    "& .MuiAlert-icon": {
                      ml: 2,
                    },
                  }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      sx={{ mr: 4 }}
                      onClick={() => {
                        setError("");
                      }}
                    >
                      <CloseBig
                        sx={(theme) => ({
                          fontSize: theme.spacing(5),
                          color: "grey.70",
                        })}
                      />
                    </IconButton>
                  }
                >
                  <Typography variant="p3" lineHeight={2}>
                    <b>Error: </b>
                    {error}
                  </Typography>
                </Alert>
              )}
              <Box
                display="flex"
                justifyContent="space-between"
                my={6}
                sx={(theme) => ({
                  mr: theme.spacing(16),
                })}
                px={8}
              >
                <Button
                  onClick={hideDrawer}
                  variant="outlined"
                  size="small"
                  sx={{ px: 5, py: 2 }}
                  disabled={isLoading}
                  id={
                    editMode
                      ? "add-new-campaign-cancel"
                      : "campaign-setting-cancel"
                  }
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  size="small"
                  sx={{ px: 5, py: 2 }}
                  type="submit"
                  loading={isLoading}
                  id={
                    editMode ? "add-new-campaign-next" : "campaign-setting-next"
                  }
                >
                  Save and Continue
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
};

export default AddCampaignForm;
