import { ActionFunctionArgs, redirect } from "react-router-dom";
import { submitSetupStrategyAction } from "../common";

async function einsteinizeAction(actionArgs: ActionFunctionArgs) {
  return submitSetupStrategyAction(actionArgs).then(() =>
    redirect("/onboarding/plan-selection")
  );
}

export { einsteinizeAction };
