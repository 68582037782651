import { Dialog, DialogContent, Divider } from "@mui/material";
import { DialogTitle } from "@tiny/mui";
import React from "react";

function InfoDialog({
  title,
  open,
  onClose,
  content,
}: {
  title: string;
  open: boolean;
  onClose: () => void;
  content: React.ReactNode;
}) {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <Divider variant="fullWidth" sx={{ mt: 4, borderColor: "purple.50" }} />
      <DialogContent dividers={false}>{content}</DialogContent>
    </Dialog>
  );
}

export { InfoDialog };
