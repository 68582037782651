import React from "react";
import { TextField } from "../../components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Divider,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { UploadDialogProps } from "./UploadDialog.types";
import { useUploadDialog } from "./UploadDialog.hooks";
import { CloseSmall } from "@tiny/icons";

function UploadDialog(props: UploadDialogProps) {
  const { onClose, open } = props;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <UploadDialogContent {...props} />
    </Dialog>
  );
}

function UploadDialogContent(props: UploadDialogProps) {
  const { accept, onClose, onUpload, title } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  const { upload, fileChange, fileName, uploadDisabled } = useUploadDialog({
    onUpload,
  });
  return (
    <>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseSmall />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item flex="1">
            <input
              type="file"
              onChange={fileChange}
              hidden
              ref={inputRef}
              accept={accept}
            />
            <TextField value={fileName} InputProps={{ readOnly: true }} />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => inputRef.current?.click()}
            >
              Browse
            </Button>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Divider />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={upload}
          disabled={uploadDisabled}
        >
          Upload
        </Button>
      </DialogActions>
    </>
  );
}

export default UploadDialog;
