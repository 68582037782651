class AppLocalStorage {
  storage: Record<string, any> = {};

  getItem(name: string) {
    try {
      return localStorage.getItem(name);
    } catch {
      return this.storage[name];
    }
  }

  setItem(name: string, value: any) {
    try {
      return localStorage.setItem(name, value);
    } catch {
      return (this.storage[name] = value);
    }
  }

  removeItem(name: string) {
    try {
      localStorage.removeItem(name);
    } catch {
      delete this.storage[name];
    }
  }

  clear() {
    try {
      localStorage.clear();
    } catch {
      this.storage = {};
    }
  }
}
export { AppLocalStorage };
