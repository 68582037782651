import { v4 as uuid } from "uuid";
import {
  AutomationsReports,
  CampaignsReports,
  GetReportsOverviewResponse,
  ReportSubscribersOverview,
} from "@/types/api/reportsOverview";
import { Metric, MetricsOverview } from "@/components/MetricCard/types";
import {
  formatNumber,
  formatAmount,
  formatPercent,
} from "@tiny/utils/formatUtils";
import { GetAccountResponse } from "@/types/api/account";
import Automation from "@/assets/illustrations/automation.svg";
import AutomationSecondary from "@/assets/illustrations/automations_secondary.svg";
import Campaign from "@/assets/illustrations/campaign.svg";
import Sent from "@/assets/illustrations/sent.svg";
import Opens from "@/assets/illustrations/opens.svg";
import Clicks from "@/assets/illustrations/clicks.svg";
import Users from "@/assets/illustrations/users.svg";
import NewUser from "@/assets/illustrations/new-user.svg";
import AddUser from "@/assets/illustrations/add-user.svg";
import UniqueUser from "@/assets/illustrations/unique-user.svg";

function audienceMapper(
  input: ReportSubscribersOverview,
  totalConversion: number
): Metric[] {
  const { formSubscribers, totalCustomers, collectedSubscribers } = input;

  return [
    {
      title: "Audiences",
      shortTitle: "All customers",
      value:
        typeof totalCustomers === "undefined"
          ? "N/A"
          : formatNumber(totalCustomers, undefined, true),
      id: uuid(),
      image: Users,
      infoMessage: "Total customers in the application",
    },
    {
      title: "Collected subscribers",
      shortTitle: "Through forms",
      value:
        typeof formSubscribers === "undefined"
          ? "N/A"
          : formatNumber(formSubscribers, undefined, true),
      id: uuid(),
      image: NewUser,
      infoMessage:
        "Subscribers collected through forms: Subscribers who completed the tinyAlbert form from your website.",
    },
    {
      title: "Collected subscribers",
      shortTitle: "From tinyAlbert",
      value:
        typeof collectedSubscribers === "undefined"
          ? "N/A"
          : formatNumber(collectedSubscribers, undefined, true),
      id: uuid(),
      image: AddUser,
      infoMessage:
        "Collected Subscribers: Collected unique subscribers since the application was installed.",
    },
    {
      title: "Generated conversions",
      shortTitle: "From tinyAlbert",
      value:
        typeof totalConversion === "undefined"
          ? "N/A"
          : formatNumber(totalConversion, undefined, true),
      id: uuid(),
      image: UniqueUser,
      infoMessage:
        "A total number of customers who placed an order as a result of tinyAlbert sent campaigns or automations.",
    },
  ];
}

function automationMapper(
  input: AutomationsReports,
  automationConversion: number
): Metric[] {
  const { activeAutomations, sent, open, clicked } = input;

  return [
    {
      title: "Automations",
      shortTitle: "Active",
      value:
        typeof activeAutomations === "undefined"
          ? "N/A"
          : formatNumber(activeAutomations, undefined, true),
      id: uuid(),
      image: Automation,
      infoMessage: "Total number of active automations.",
    },
    {
      title: "Email sent",
      shortTitle: "Total sent",
      value:
        typeof sent === "undefined"
          ? "N/A"
          : formatNumber(sent, undefined, true),
      id: uuid(),
      image: Sent,
      infoMessage: "A total number of emails sent through automations.",
      subMetric: {
        label: "Conversion",
        value: formatNumber(automationConversion, undefined, true),
      },
    },
    {
      title: "Email opens",
      shortTitle: "Total opens",
      value:
        typeof open === "undefined"
          ? "N/A"
          : formatNumber(open, undefined, true),
      id: uuid(),
      image: Opens,
      infoMessage:
        "A total number of unique opened emails sent through automations.",
      subMetric: {
        label: "Open rate",
        value: formatPercent((open / sent) * 100, 1),
      },
    },
    {
      title: "Email clicks",
      shortTitle: "Total clicks",
      value:
        typeof clicked === "undefined"
          ? "N/A"
          : formatNumber(clicked, undefined, true),
      id: uuid(),
      image: Clicks,
      infoMessage:
        "A total number of unique clicked emails sent through automations.",
      subMetric: {
        label: "Click rate",
        value: formatPercent((clicked / sent) * 100, 1),
      },
    },
  ];
}

function campaignMapper(
  input: CampaignsReports,
  campaignConversion: number
): Metric[] {
  const { sentCampaigns, sent, open, clicked } = input;
  return [
    {
      title: "Campaigns",
      shortTitle: "Sent campaigns",
      value:
        typeof sentCampaigns === "undefined"
          ? "N/A"
          : formatNumber(sentCampaigns, undefined, true),
      id: uuid(),
      image: Campaign,
      infoMessage: "Total number of sent campaigns.",
    },
    {
      title: "Email sent",
      shortTitle: "Total sent",
      value:
        typeof sent === "undefined"
          ? "N/A"
          : formatNumber(sent, undefined, true),
      id: uuid(),
      image: Sent,
      infoMessage: "A total number of emails sent through campaigns.",
      subMetric: {
        label: "Conversion",
        value: formatNumber(campaignConversion, undefined, true),
      },
    },
    {
      title: "Email opens",
      shortTitle: "Total opened",
      value:
        typeof open === "undefined"
          ? "N/A"
          : formatNumber(open, undefined, true),
      id: uuid(),
      image: Opens,
      infoMessage:
        "A total number of unique opened emails sent through campaigns.",
      subMetric: {
        label: "Open rate",
        value: formatPercent((open / sent) * 100, 1),
      },
    },
    {
      title: "Email clicks",
      shortTitle: "Total clicks",
      value:
        typeof clicked === "undefined"
          ? "N/A"
          : formatNumber(clicked, undefined, true),
      id: uuid(),
      image: Clicks,
      infoMessage:
        "A total number of unique clicked emails sent through campaigns.",
      subMetric: {
        label: "Click rate",
        value: formatPercent((clicked / sent) * 100, 1),
      },
    },
  ];
}

function totalRevenueMapper(
  generatedRevenue: number,
  campaignRevenue: number,
  automationRevenue: number,
  currency: string
): Metric[] {
  return [
    {
      title: `Total Revenue ( ${currency} )`,
      value:
        typeof generatedRevenue === "undefined"
          ? "N/A"
          : formatAmount(generatedRevenue, currency, true),
      id: uuid(),
      image: "",
      rate: generatedRevenue || 0,
      currency: currency || "",
    },
    {
      title: `Campaigns`,
      value:
        typeof campaignRevenue === "undefined"
          ? "N/A"
          : formatAmount(campaignRevenue, currency, true),
      id: uuid(),
      image: Campaign,
      rate: campaignRevenue || 0,
    },
    {
      title: `Automations`,
      value:
        typeof automationRevenue === "undefined"
          ? "N/A"
          : formatAmount(automationRevenue, currency, true),
      id: uuid(),
      image: AutomationSecondary,
      rate: automationRevenue || 0,
    },
  ];
}

function totalOrdersMapper(
  generatedConversion: number,
  campaignConversion: number,
  automationConversion: number
): Metric[] {
  return [
    {
      title: `Total Orders`,
      value:
        typeof generatedConversion === "undefined"
          ? "N/A"
          : formatNumber(generatedConversion, undefined, true),
      id: uuid(),
      image: "",
      rate: generatedConversion || 0,
    },
    {
      title: `Campaigns`,
      value:
        typeof campaignConversion === "undefined"
          ? "N/A"
          : formatNumber(campaignConversion, undefined, true),
      id: uuid(),
      image: Campaign,
      rate: campaignConversion || 0,
    },
    {
      title: `Automations`,
      value:
        typeof automationConversion === "undefined"
          ? "N/A"
          : formatNumber(automationConversion, undefined, true),
      id: uuid(),
      image: AutomationSecondary,
      rate: automationConversion || 0,
    },
  ];
}

export function dashboardMetricMapper(
  src: GetReportsOverviewResponse,
  settings: GetAccountResponse
): MetricsOverview {
  const {
    audiences,
    campaignRevenue,
    automationRevenue,
    generatedRevenue,
    generatedConversion,
    automationsReports,
    campaignsReports,
  } = src;
  const { currency } = settings;

  return {
    totalRevenue: totalRevenueMapper(
      generatedRevenue,
      campaignRevenue?.generatedRevenue,
      automationRevenue?.generatedRevenue,
      currency
    ),
    totalOrders: totalOrdersMapper(
      generatedConversion,
      campaignRevenue?.generatedConversion,
      automationRevenue?.generatedConversion
    ),
    automations: automationMapper(
      automationsReports,
      automationRevenue?.generatedConversion
    ),
    audience: audienceMapper(audiences, generatedConversion),
    campaign: campaignMapper(
      campaignsReports,
      automationRevenue?.generatedConversion
    ),
  };
}
