import React from "react";

import { Tag, TagsDialogProps } from "./TagsDialog.types";

export function useTagsDialog(props: TagsDialogProps) {
  const {
    tags = [],
    selected: selectedProp,
    open,
    onClose,
    onSave,
    isFavoritesSelected,
    setIsFavoritesSelected,
  } = props;

  const [selected, setSelected] = React.useState<Tag[]>([]);
  const [isFavoriteSelected, setIsFavoriteSelected] = React.useState(false);

  const save = () => {
    onSave?.(selected, isFavoriteSelected);
    //@ts-ignore
    onClose && onClose();
  };

  const isChecked = React.useCallback(
    (tagId: string | undefined) => {
      return tagId
        ? selected?.filter((tag) => tag.id === tagId).length > 0
        : false;
    },
    [selected]
  );

  const selectTag = React.useCallback((checked: boolean, tag: Tag) => {
    if (checked) {
      setSelected((prev) => {
        return [...prev, tag];
      });
    } else {
      setSelected((prev) => {
        return prev.filter((item) => item.id !== tag.id);
      });
    }
  }, []);

  const toggleAll = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelected(tags || []);
      } else {
        setSelected([]);
      }
    },
    [tags]
  );

  React.useEffect(() => {
    if (open) {
      setSelected(selectedProp || []);
    }
  }, [open, selectedProp]);

  const allChecked = selected.length === tags?.length;

  const toggleSelectedFavorites = (isSelected: boolean) => {
    setIsFavoriteSelected(isSelected);
  };

  return {
    save,
    selected,
    toggleAll,
    allChecked,
    selectTag,
    isChecked,
    isFavoritesSelected,
    isFavoriteSelected,
    toggleSelectedFavorites,
    setIsFavoritesSelected,
  };
}
