import React from "react";
import { ActionMenu } from "@/components/ActionMenu/types";
import ImagePreview from "@/components/HeroImage/ImagePreview";
import { useConfirmationDialog } from "@/routes/Campaigns/useConfirmationDialog.hook";
import { useDrawerInvokers } from "@tiny/lib";
import { useHeroImage } from "../../routes/AccountSettings/HeroImage/Hooks/useHeroimage";
import { HeroImage } from "../../routes/AccountSettings/HeroImage/types";

function useHeroImageActionMenu(heroImage: HeroImage) {
  const { showConfirmationDialog } = useConfirmationDialog();
  const { showDrawer } = useDrawerInvokers();
  const heroImageHook = useHeroImage();
  const { deleteHeroImage } = heroImageHook;

  const handlePreview = () => {
    showDrawer({
      children: (
        <ImagePreview heroImage={heroImage} useHeroImage={heroImageHook} />
      ),
    });
  };

  const handleDelete = () => {
    showConfirmationDialog(
      "Are you sure you want to delete this image from you library?",
      "Delete hero image",
      "Yes, delete",
      () => {
        deleteHeroImage(heroImage.id);
      },
      true
    );
  };

  const heroImageMenu: ActionMenu = [
    {
      name: "Preview",
      onClickAction: handlePreview,
      id: "here-image-actionMenu-preview",
    },
    {
      name: "Delete",
      onClickAction: handleDelete,
      id: "hero-image-actionMenu-delete",
    },
  ];

  return { heroImageMenu };
}

export default useHeroImageActionMenu;
