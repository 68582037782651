import React from "react";
import { Box, Typography } from "@mui/material";
import { useIsInsideDialog } from "@tiny/lib/DialogContent/useIsInsideDialog.hook";

function MetricInfoTitle({
  title,
  shortTitle,
}: {
  title: string;
  shortTitle?: string;
}) {
  const isInsideDialog = useIsInsideDialog();

  return (
    <Box
      display="flex"
      sx={[
        isInsideDialog && {
          flexDirection: "row-reverse",
          justifyContent: "flex-end",
        },
      ]}
      alignItems="center"
      gap={1}
    >
      <Typography variant="label2R" color="grey.80">
        {isInsideDialog ? shortTitle ?? title : title}
      </Typography>
    </Box>
  );
}

export default MetricInfoTitle;
