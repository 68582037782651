import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18.248 16.9971C18.1656 16.9989 18.0829 16.9999 18 16.9999C11.9249 16.9999 7 12.075 7 5.99987C7 5.91697 7.00092 5.83428 7.00275 5.7518C5.17211 7.21839 4 9.47327 4 11.9998C4 16.4181 7.58172 19.9998 12 19.9998C14.5266 19.9998 16.7814 18.8277 18.248 16.9971ZM19.4661 14.881C18.989 14.9592 18.4992 14.9999 18 14.9999C13.0294 14.9999 9 10.9704 9 5.99987C9 5.50062 9.04065 5.01086 9.11882 4.53373C9.25094 3.72732 9.49024 2.95697 9.82162 2.23779C8.96026 2.42915 8.14073 2.73161 7.37882 3.12934C4.18215 4.79809 2 8.14413 2 11.9998C2 17.5227 6.47715 21.9998 12 21.9998C15.8557 21.9998 19.2017 19.8177 20.8705 16.6211C21.2682 15.8592 21.5707 15.0396 21.762 14.1783C21.0429 14.5096 20.2725 14.7489 19.4661 14.881Z"
    xmlns="http://www.w3.org/2000/svg"
  />,
  "Moon"
);
