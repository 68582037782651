import React from "react";
import { Box, Button, Popover, useMediaQuery, useTheme } from "@mui/material";
import { ChevronBigDown } from "@tiny/icons";
import { formatDate } from "@tiny/utils/dateUtils";
import { DateRangePicker, Range } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays, startOfDay } from "date-fns";
import { defaultStaticRanges } from "./defaultStaticRange";
import { TinyDateRangePickerProps } from "./types";

function TinyDateRangePicker({
  onChange,
  selectedRange,
  color,
  direction,
  minDate,
  buttonLabel,
}: TinyDateRangePickerProps) {
  const theme = useTheme();
  const sm = useMediaQuery("(max-width:800px)");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "tiny-date-picker-popover" : undefined;

  const defaultProps: {
    startDate: Date;
    endDate: Date;
    color: string | undefined;
    direction: "horizontal" | "vertical" | undefined;
    minDate: Date;
    buttonLabel: string | undefined;
  } = {
    startDate: startOfDay(new Date()),
    endDate: startOfDay(addDays(new Date(), 7)),
    color: theme.palette.primary[100],
    direction: sm ? "vertical" : "horizontal",
    minDate: new Date(),
    buttonLabel: buttonLabel || "Select Date",
  };

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (resetValue: {
    startDate: Date;
    endDate: Date;
  }) => {
    setRangeSelected([
      {
        ...defaultRange,
        ...resetValue,
      },
    ]);
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    if (
      rangeSelected &&
      rangeSelected[0]?.startDate &&
      rangeSelected[0]?.endDate
    ) {
      const val = {
        startDate: rangeSelected[0]?.startDate,
        endDate: rangeSelected[0]?.endDate,
      };
      setResetValue(val);
      onChange(val);
      handlePopoverClose(val);

      setBtnLabel(
        `${formatDate(rangeSelected[0]?.startDate, "dd MMM")} - ${formatDate(
          rangeSelected[0]?.endDate,
          "dd MMM"
        )}`
      );
    }
  };

  const defaultRange = {
    startDate: selectedRange?.startDate || defaultProps.startDate,
    endDate: selectedRange?.endDate || defaultProps.endDate,
    key: "selection",
    color: color || defaultProps.color,
  };

  const [rangeSelected, setRangeSelected] = React.useState<Range[]>([
    defaultRange,
  ]);

  const [resetValue, setResetValue] = React.useState({
    startDate: selectedRange?.startDate || defaultProps.startDate,
    endDate: selectedRange?.endDate || defaultProps.endDate,
  });

  const [btnLabel, setBtnLabel] = React.useState(defaultProps?.buttonLabel);

  return (
    <Box>
      <Button
        onClick={handlePopoverClick}
        variant="outlined"
        size="small"
        sx={{
          px: 5,
          py: 2,
          fontWeight: "normal",
          color: "grey.100",
          backgroundColor: "common.white",
          borderColor: "grey.70",
          "&:hover": {
            borderColor: "grey.70",
            boxShadow: "none",
            backgroundColor: "common.white",
          },
          "&:active": {
            boxShadow: "none",
            borderColor: "grey.70",
          },
        }}
        endIcon={<ChevronBigDown sx={{ color: "grey.70" }} />}
        id="campaign-calendar-datepicker"
      >
        {btnLabel}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handlePopoverClose(resetValue)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box>
          <DateRangePicker
            months={2}
            direction={direction || defaultProps.direction}
            minDate={minDate || defaultProps.minDate}
            showDateDisplay={false}
            ranges={rangeSelected}
            onChange={(item) => {
              setRangeSelected([item?.selection]);
            }}
            fixedHeight
            inputRanges={[]}
            staticRanges={defaultStaticRanges}
          />
          <Box
            display="flex"
            gap={4}
            justifyContent="flex-end"
            sx={{
              p: 5,
              pt: 0,
            }}
          >
            <Button
              variant="text"
              onClick={() => handlePopoverClose(resetValue)}
            >
              cancel
            </Button>
            <Button variant="outlined" onClick={handleSubmit}>
              submit
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

export default TinyDateRangePicker;
