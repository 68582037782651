import { ChipProps, Chip } from "@mui/material";
import React from "react";
import { getChipColor } from "./CampaignStatus.helpers";
import { CampaignInfoView } from "../../pages/CampaignPage.types";

interface CampaignStatusProps extends Omit<ChipProps, "label" | "color"> {
  status?: CampaignInfoView["campaignStatus"];
}

function CampaignStatus(
  props: CampaignStatusProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { status, ...other } = props;
  return (
    <Chip ref={ref} label={status} color={getChipColor(status)} {...other} />
  );
}

export default React.forwardRef(CampaignStatus);
