import { TemplateFilter } from "../TemplateList.types";
import { TemplateSectionProps } from "../TemplateSection/TemplateSection.types";

// TODO: Add Branded Templates
const sectionPropsByFilterMap: {
  [key in TemplateFilter]: Partial<
    Pick<
      TemplateSectionProps,
      "title" | "type" | "saved" | "selectable" | "renderWithEjs"
    >
  >;
} = {
  All: {},
  Saved: {
    title: "Saved",
    saved: true,
    selectable: true,
  },
  StaffPicks: {
    title: "Staff picks",
    type: "STAFF_PICKED",
  },
  // Branded: {
  //   title: "Branded",
  //   type: "CUSTOM",
  //   renderWithEjs: true,
  // },
  Basic: {
    title: "Basic",
    type: "BASIC",
  },
  Library: {
    title: "Library",
    type: "LIBRARY",
  },
  Favorites: {},
};

export function sectionPropsByFilter(filter: TemplateFilter) {
  return sectionPropsByFilterMap[filter];
}

export const filterOptions: { label: string; value: TemplateFilter }[] = [
  { label: "All templates", value: "All" },
  { label: "Saved", value: "Saved" },
  { label: "Staff picks", value: "StaffPicks" },
  // { label: "Branded", value: "Branded" },
  { label: "Basic", value: "Basic" },
  { label: "Library", value: "Library" },
];
