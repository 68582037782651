import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useAutoTrackingDialog } from "./AutoTrackingDialog.hooks";
import { AutoTrackingDialogProps } from "./AutoTrackingDialog.types";

function AutoTrackingDialog(props: AutoTrackingDialogProps): JSX.Element {
  const { open, onClose } = props;

  const { autoTrackingState, saveAutoTrackingState, setAutoTrackingState } =
    useAutoTrackingDialog(props);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Auto-tracking</DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        <Grid container alignItems="center">
          <Grid flex="1">
            <Typography
              component="span"
              variant="h4"
              color="grey"
              fontWeight="bold"
            >
              Status
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component="span"
              variant="p2"
              color="grey"
              mr={autoTrackingState ? 0.25 : 1}
            >
              {autoTrackingState ? "Enabled" : "Disabled"}
            </Typography>
          </Grid>
          <Grid>
            <Switch
              size="small"
              checked={autoTrackingState}
              onChange={(e) => setAutoTrackingState(e.target.checked)}
            />
          </Grid>
          <Box mt={2.5}>
            <Typography component="span" variant="p3" color="grey">
              tinyEmail appends default UTM codes to every hyperlink you add to
              your email. To enable this, toggle ON here. Default UTM parameters
              are as follow: utm_source=tinyemail,
              utm_campaign=[your_campaign_name], utm_medium=email
            </Typography>
          </Box>
        </Grid>
        <Box
          sx={(theme) => ({
            position: "relative",
            top: "20px",
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
          })}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={saveAutoTrackingState}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AutoTrackingDialog;
