import React from "react";
import { CampaignAudience } from "@/types/api/campaigns";
import {
  GetSupportedLanguagesResponse,
  Language,
} from "@/types/api/contentDetails";
import { Box, Typography, Chip, Theme } from "@mui/material";
import { Monitor, User } from "@tiny/icons";
import { formatDate } from "@tiny/utils/dateUtils";
import { getCampaignStatus } from "../helpers";

export interface CampaignStatusProps {
  status: string;
  outdated: boolean | undefined;
  enabled: boolean;
}
export const getCampaignStartTime = (startTime: string | number) => (
  <Typography
    variant="p2"
    title={formatDate(new Date(startTime), "MM-dd-yyyy h:mm a")}
    sx={(theme) => ({ color: theme.palette.grey[100], whiteSpace: "nowrap" })}
  >
    {formatDate(new Date(startTime), "MM-dd-yyyy")}
  </Typography>
);

export const getDiscountStatus = (discountId: string) => (
  <Typography
    variant="p2"
    sx={(theme) => ({ color: theme.palette.grey[100], whiteSpace: "nowrap" })}
  >
    {discountId ? "Yes" : "No"}
  </Typography>
);

export const getTemplateLanguage = (
  languagesResp: GetSupportedLanguagesResponse,
  languageId: string
) => (
  <Typography
    variant="p2"
    sx={(theme) => ({ color: theme.palette.grey[100], whiteSpace: "nowrap" })}
  >
    {
      languagesResp.languages.find((lang: Language) => lang.id === languageId)
        ?.name
    }
  </Typography>
);

export const getCampaignAudience = (
  audiences: CampaignAudience[] | undefined
) => (
  <Typography
    variant="p2"
    sx={(theme) => ({
      color: theme.palette.grey[100],
      textOverflow: "ellipsis",
      maxWidth: theme.spacing(50),
      overflow: "hidden",
      whiteSpace: "nowrap",
    })}
  >
    {audiences && audiences?.length
      ? audiences.map((audience) => audience.audienceName).join(", ")
      : "All"}
  </Typography>
);

export const getCampaignStatusRow = ({
  status,
  outdated,
  enabled,
}: CampaignStatusProps) => {
  const statusObj = getCampaignStatus({ status, outdated, enabled });
  return (
    <Box display="flex" alignItems="center">
      {statusObj.icon}
      <Typography
        variant="p2"
        sx={(theme) => ({
          color: theme.palette.grey[100],
          ml: 2,
          whiteSpace: "nowrap",
        })}
      >
        {statusObj.label}
      </Typography>
    </Box>
  );
};

export const types = [
  {
    key: "Promotions",
    label: "Promotions",
  },
  {
    key: "Holidays",
    label: "Holidays",
  },
  {
    key: "Newsletters",
    label: "Newsletters",
  },
  {
    key: "ProductHighlights",
    label: "Product Highlights",
  },
];
export const getCampaignType = (category: string) => {
  const getStyles = (theme: Theme) => {
    const categoryStyles: {
      [key: string]: {
        background: string | undefined;
        color: string | undefined;
      };
    } = {
      Promotions: {
        background: theme.palette.primary[20],
        color: theme.palette.primary[100],
      },
      Newsletters: {
        background: theme.palette.fuchsia[50],
        color: theme.palette.fuchsia[100],
      },
      Holidays: {
        background: theme.palette.green[50],
        color: theme.palette.green[100],
      },
      ProductHighlights: {
        background: theme.palette.yellow[50],
        color: theme.palette.yellow[100],
      },
    };
    return (
      categoryStyles[category] || {
        background: theme.palette.grey[20],
        color: theme.palette.primary[100],
      }
    );
  };
  const label = types.find((type) => type.key === category)?.label;
  return (
    <Chip
      label={label}
      sx={(theme: Theme) => ({
        fontSize: theme.spacing(3),
        fontWeight: 600,
        marginBottom: -4,
        marginTop: -4,
        py: theme.spacing(1),
        ...getStyles(theme),
      })}
    />
  );
};

export const getGeneratedBy = (generatedBy: string) => {
  const generatedByConfig: {
    [key: string]: { icon: JSX.Element; label: string };
  } = {
    System: {
      icon: (
        <Monitor
          sx={(theme) => ({
            fill: theme.palette.yellow[100],
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "System",
    },
    User: {
      icon: (
        <User
          sx={(theme) => ({
            fill: theme.palette.primary[100],
            fontSize: theme.spacing(5),
          })}
        />
      ),
      label: "User",
    },
  };

  const { icon, label } = generatedByConfig[generatedBy] || {};

  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Typography
        variant="p2"
        sx={(theme) => ({ color: theme.palette.grey[100], ml: 2 })}
      >
        {label}
      </Typography>
    </Box>
  );
};
