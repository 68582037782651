import React from "react";
import { CampaignsList } from "@/components/CampaignsList";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { CampaignsContext, ViewType } from "@/routes/Campaigns/Context";
import { GetCampaignResponse } from "@/types/api/campaigns";

function CampaignsCard({ campaigns }: { campaigns: GetCampaignResponse }) {
  const { setView } = React.useContext(CampaignsContext);

  React.useEffect(() => {
    setView(ViewType.TABLE);
  }, []);

  return (
    <Card
      sx={{
        py: 2,
        borderRadius: 4,
        boxShadow: "none",
        width: "100%",
      }}
    >
      <CardContent sx={{ p: "0 !important" }}>
        <Box p={6}>
          <Typography variant="h4" color="grey.100">
            Campaigns
          </Typography>
        </Box>
        <CampaignsList campaigns={campaigns} showHeader={false} />
      </CardContent>
    </Card>
  );
}

export default CampaignsCard;
