import React from "react";
import { LaunchItem } from ".";
import { HtmlPreview } from "../../../components";
import useDialog from "../../../hooks/useDialog";
import { ShortRight } from "@tiny/icons";
import { useCampaignPageView } from "../../CampaignPage.context";
import Panel from "../../Panel";
import ScheduleDialog from "../ScheduleDialog";
import AutoTrackingDialog from "./AutoTrackingDialog";
import { SchedulePanelProvider } from "./SchedulePanel.context";
import { useSchedulePanel } from "./SchedulePanel.hooks";
import { List, Box, Divider, Grid, Typography } from "@mui/material";

function SchedulePanel() {
  const {
    campaignTemplate,
    closeScheduleDialog,
    details,
    openScheduleDialog,
    selectedTemplate,
    scheduleDialogOpen,
    templateHtml,
    templateCss,
  } = useCampaignPageView();

  const schedulePanelView = useSchedulePanel();

  const {
    autoTracking,
    autoTrackingState,
    displayPoweredBy,
    goToDesign,
    goToDetails,
    saveAutoTracking,
    scheduleCompleted,
    scheduleDescription,
    scheduleDisabled,
  } = schedulePanelView;

  const {
    dialogVisible: autoTrackingDialogOpen,
    showDialog: showAutoTrackingDialog,
    hideDialog: hideAutoTrackingDialog,
  } = useDialog();

  const templateEditorType = React.useMemo(() => {
    return "DRAG_AND_DROP" ===
      (selectedTemplate || campaignTemplate)?.editorType
      ? "Drag And Drop"
      : "Custom Editor";
  }, [campaignTemplate, selectedTemplate]);

  return (
    <SchedulePanelProvider value={schedulePanelView}>
      <Panel title="Preflight checklist" titleVariant="h2">
        <Typography variant="p2" component="p" color="grey" m={0} mt={2} mb={6}>
          Before you launch this campaign, please review the campaign details
          and settings. If all look good, schedule or send it. Enough said!
        </Typography>
        <Box mb={6}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box minWidth="70%">
            <List
              sx={{
                listStyle: "none",
                margin: 0,
                padding: 0,
              }}
            >
              <LaunchItem
                name="Details"
                description={details?.name || ""}
                completed
                onActionClick={goToDetails}
                actionIcon={<ShortRight />}
                smartAssistant={details?.smartAssistant}
              />
              <LaunchItem
                name="Design"
                description={templateEditorType}
                completed
                onActionClick={goToDesign}
                actionIcon={<ShortRight />}
              />
              {/* <LaunchItem
                name="Auto-tracking"
                description={autoTrackingState}
                completed={autoTracking}
                onActionClick={showAutoTrackingDialog}
                actionIcon={<ShortRight />}
              /> */}
              {!!displayPoweredBy && (
                <LaunchItem
                  name="Footer message"
                  description="Powered by tinyEmail"
                  completed={!displayPoweredBy}
                  actionText="Upgrade"
                  onActionClick={() => {
                    return;
                  }}
                  actionIcon={<ShortRight />}
                />
              )}
              <LaunchItem
                name="Schedule"
                description={scheduleDescription}
                completed={scheduleCompleted}
                actionText="Setup"
                onActionClick={openScheduleDialog}
                actionDisabled={scheduleDisabled}
                actionIcon={<ShortRight />}
              />
            </List>
          </Box>
          <Box>
            <Box borderRadius={1}>
              <HtmlPreview
                contentWidth={250}
                height="300px"
                width="100%"
                html={templateHtml || campaignTemplate?.templateHtml}
                css={templateCss || campaignTemplate?.templateCss}
                scrollable
              />
            </Box>
          </Box>
        </Box>
        {scheduleDialogOpen && (
          <ScheduleDialog
            open={scheduleDialogOpen}
            onClose={closeScheduleDialog}
          />
        )}
        {autoTrackingDialogOpen && (
          <AutoTrackingDialog
            open={autoTrackingDialogOpen}
            onClose={hideAutoTrackingDialog}
            onSave={saveAutoTracking}
          />
        )}
      </Panel>
    </SchedulePanelProvider>
  );
}

export default SchedulePanel;
