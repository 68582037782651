import React from "react";

interface StripoContext {
  accountId?: string;
  emailId?: string;
  userFullName?: string;
}

const StripoContext = React.createContext<StripoContext>({
  accountId: "",
  emailId: "",
  userFullName: "",
});

export default StripoContext;
