import { PostAccountSubscriptionResponse } from "@/types/api/accountSubscription";
import { captureExceptionToSentry } from "@tiny/sentry";
import { fetchApi } from "@tiny/utils";
import * as React from "react";
import {
  useNavigate,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import type { RootLoader } from "@/types/RootLoader";

/*
  Whenever our user selects a package and Approves the payment in shopify, he
  will be redirected to our app to the root route with a `charge_id` query param.
  This should serve as an indicator to FE to trigger a POST/PUT /account-subscription request
 */
function RootRouteIndex() {
  const { userStatus } = useRouteLoaderData("root") as RootLoader;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const shopifyChargeId = searchParams.get("charge_id");

  /*
    TODO: Known bug. Refreshing the page in Shopify, on any /main route, after onboarding success, will
    re-trigger a PUT /account-subscription request and redirect to Account Settings -> Plans page
   */
  React.useEffect(() => {
    if (shopifyChargeId) {
      if (userStatus.onboardingStep === "EINSTEINIZED") {
        fetchApi<PostAccountSubscriptionResponse>(
          "/accountservice/account-subscription",
          {
            method: "POST",
            body: JSON.stringify({ referenceId: shopifyChargeId }),
          }
        )
          .then(({ onboardingStep: nextStep }) => {
            navigate(
              nextStep === "FINISHED"
                ? "/main/dashboard/overview/welcome"
                : "/onboarding/plan-selection"
            );
          })
          .catch((err) => {
            captureExceptionToSentry(err);
            navigate("/onboarding/plan-selection");
          });
      }
      if (userStatus.onboardingStep === "FINISHED") {
        fetchApi<PostAccountSubscriptionResponse>(
          "/accountservice/account-subscription",
          {
            method: "PUT",
            body: JSON.stringify({ referenceId: shopifyChargeId }),
          }
        )
          .catch((err) => {
            captureExceptionToSentry(err);
          })
          .finally(() => navigate("/main/account-settings/plans"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopifyChargeId]);
  return null;
}

export default RootRouteIndex;
