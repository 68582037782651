import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import api from "./api";

import {
  LeadIdentityView,
  SenderDetailsUpdateView,
  SenderDetailsView,
  SenderDetailsFilterView,
  PageSenderDetailsView,
  Pageable,
  Sort,
  ExportInvoicesView,
  IntegrationFilterView,
  IntegrationItemView,
  IntegrationsView,
  IdentityLogoView,
  IdentityView,
  LinkView,
  SocialLinkView,
  CampaignUpdateRequestView,
  CampaignResponseView,
  CampaignTestEmailsView,
  CampaignRemainedTestEmailsView,
  CampaignStartView,
  SenderDetailsIdentityView,
  SegmentIdentitiesView,
  SegmentIdentityView,
  EnabledView,
  ActivityIdentitiesView,
  ActivityIdentityView,
  ActivityDetailsView,
  TemplateTagView,
  IdView,
  IdsView,
  CampaignFilterView,
  CampaignInfoView,
  PageCampaignInfoView,
  ActivityFilterView,
  PageActivityIdentityView,
  EmailActivityTemplateFilterView,
  EmailActivityTemplateIdentityView,
  PageEmailActivityTemplateIdentityView,
  AccountView,
  AccountIntegrationExecutionView,
  ConfigurableNotificationUpdateView,
  ConfigurableNotificationCategoryUpdateView,
  SubscriptionCostCalculateParametersView,
  SubscriptionCostView,
  TaxRateView,
  AttachBillingInformationView,
  TicketCreateRequestDto,
  LeadView,
  LicenseView,
  LicenseCreatedView,
  SenderDetailsCreateView,
  SenderDetailsEmailConfirmationView,
  AttachPaymentMethodView,
  AddressResponse,
  BillingDetailsResponse,
  CardResponse,
  LastSetupError,
  PaymentMethodResponse,
  PaymentMethodView,
  CampaignCreateRequestView,
  CampaignCreateByTemplateRequestView,
  CampaignStatisticsTotalRequestView,
  CampaignStatisticsTotalResponseView,
  CampaignStatisticsGroupedRequestView,
  CampaignStatisticsGroupedResponseView,
  CampaignTimeGroupStatisticsView,
  CampaignCloneRequestView,
  AccountCreateView,
  AccountIntegrationView,
  AssignSubscriptionView,
  AssignSubscriptionWithPaymentMethodView,
  AssignSubscriptionWithBillingInfoView,
  TemplateTagsView,
  SubscriptionFeatureView,
  SubscriptionView,
  SubscriptionsView,
  TierView,
  SubscriptionFeatureAvailabilityView,
  SubscriptionFeaturesAvailabilityView,
  SubscriptionFeaturesCategoriesAvailabilityView,
  SenderDetailsesView,
  PaymentMethodsView,
  InvoiceView,
  InvoicesView,
  SocialLinksView,
  ColorsView,
  FontView,
  IdentityProfiledView,
  LogoView,
  EditorTokenView,
  ActivityDetailsListView,
  ActivityEmailFooterView,
  ActivityTemplateAttributeView,
  ActivityTemplateAttributesView,
  CampaignIdentitiesView,
  CampaignIdentityView,
  EmailActivityTemplateDetailsView,
  AccountIntegrationItemView,
  AccountPlatformUsageView,
  AccountTemplatesPlatformUsageView,
  AccountIntegrationsPlatformUsageView,
  AccountCampaignsPlatformUsageView,
  AccountAudiencesPlatformUsageView,
  ConfigurableNotificationCategoryView,
  ConfigurableNotificationSettingView,
  ConfigurableNotificationSettingsView,
  AccountIssueView,
  AccountIssuesView,
  AccountSubscriptionRecommendationView,
  AccountSubscriptionView,
  UsageView,
  AccountLeadSubscriptionView,
  SubscriptionFeaturesView,
  SubscriptionFeaturesCategoriesView,
  SubscriptionFeaturesCategoryView,
  AccountSubscriptionBillingInfoView,
  BillingInfoView,
  ContactInfoView,
  AccountIntegrationsView,
  AccountIntegrationsSummaryView,
  SenderDetailsDeleteView,
  CampaignMultipleDeleteView,
  ActivityMultipleDeleteView,
  BaseCondition,
  CombinedCondition,
  Condition,
  ConditionValueObject,
  SegmentUpdateView,
  SegmentIdentityView1,
  CustomersUpdateView,
  SegmentTypeStatisticRequestView,
  SegmentTypeStatisticResponseView,
  SegmentIdentitiesView1,
  SegmentFilterView,
  SegmentPageSegmentView,
  SegmentView,
  CustomSegmentSummaryRequestView,
  AudienceCriteriaView,
  AudienceView,
  CustomSegmentSummaryResponseView,
  SegmentCheckExistsFilterView,
  CustomerView,
  CustomerFilterView,
  CustomerInfoView,
  PageCustomerInfoView,
  CustomerCompositeFilterView,
  CustomerEventFilterView,
  CustomerExcludeFilterView,
  CustomerPropertyFilterView,
  CustomerSegmentFilterView,
  FilterViewWrapperCustomerEventFilterView,
  FilterViewWrapperCustomerPropertyFilterView,
  FilterViewWrapperCustomerSegmentFilterView,
  CustomerActionPlatformFindFilterView,
  CustomerActionPlatformInfoView,
  CustomerActionGeolocationFindFilterView,
  CustomerActionGeolocationInfoView,
  CustomerActionDomainFindFilterView,
  CustomerActionDomainInfoView,
  CustomerSegmentInfoView,
  PageCustomerSegmentInfoView,
  CustomerBatchView,
  UploadSegmentCreateView,
  SegmentTotalStatisticsResponseView,
  SegmentTotalCustomersStatisticsRequestView,
  SegmentCustomersStatisticView,
  SegmentTotalCustomersStatisticsResponseView,
  IntegrationSegmentStatisticsGrowthRequestView,
  IntegrationSegmentStatisticGrowthView,
  IntegrationSegmentStatisticsGrowthResponseView,
  SegmentImportView,
  CustomSegmentCreateView,
  SegmentCloneRequestView,
  CustomerStatisticsTotalRequestView,
  CustomerStatisticsTotalResponseView,
  CustomerStatisticsGroupedRequestView,
  CustomerStatisticsGroupedResponseView,
  CustomerTimeGroupStatisticsView,
  CustomerEventStatisticsGroupedRequestView,
  CustomerEventStatisticsGroupedResponseView,
  CustomerEventTimeGroupStatisticsView,
  CustomerEventStatisticsTotalRequestView,
  CustomerEventStatisticsTotalResponseView,
  CustomerEventMostOpenStatisticsFilterView,
  CustomerEventMostOpenStatisticsResponseView,
  CustomerGroupedOpenEventView,
  CustomerEventMostClickStatisticsFilterView,
  CustomerEventMostClickStatisticsResponseView,
  CustomerGroupedClickEventView,
  SegmentDetailsView,
  Attribute,
  AttributeType,
  SegmentAttributesView,
  SegmentsView,
  TagsView,
  CustomerTotalView,
  SegmentMultipleDeleteView,
  SegmentUnassignCustomersView,
  SubjectLineFilterView,
  PageSubjectLineView,
  SubjectLineView,
  SubjectGenerateRequestView,
  SubjectGenerateResponseView,
  ToneView,
  TonesView,
  MergeTagView,
  MergeTagsView,
  SubjectLineFeatureInfoView,
  FormUpdateRequestView,
  FormUpdateResponseView,
  FormFilterView,
  FormInfoView,
  PageFormInfoView,
  CategoryElementView,
  ControlElementView,
  FormAppearanceView,
  FormConfigView,
  FormContentView,
  FormPayloadView,
  FormTriggerDetailsView,
  FormTriggerEventView,
  GroupElementView,
  JsonSchemaView,
  LabelElementView,
  LayoutElementView,
  UiSchemaElementView,
  FormAllowedDomainsView,
  FormCheckAssignedAudiencesRequestView,
  FormCheckAssignedAudiencesResponseView,
  FormTemplateFilterView,
  FormTemplateInfoView,
  PageFormTemplateInfoView,
  FormView,
  FormIdentityView,
  FormCloneRequestView,
  FormCloneResponseView,
  FormWithTemplateView,
  FormPlatformUsageView,
  FormDetailsView,
  FormDeleteRequestView,
  AccountAutomationView,
  AutomationTemplateView,
  AutomationsView,
  CampaignContentSourceRequestView,
} from "./GeneratedApi";

export interface ErrorEntity {
  errorCode: string;
  errorMessage: string;
}

export interface Error {
  message?: string;
  response?: {
    status?: number;
    data?: {
      message?: string;
      messageDetails?: string;
      errorCodes?: ErrorEntity[];
    };
  };
}

export function usePutSubscriptionLeadContextEmailQuery(
  data: LeadIdentityView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", "/subscription/lead/context-email", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putSubscriptionLeadContextEmail(data);
      return result.data;
    },
    options
  );
}

export function useGetSenderDetailsByIdQuery(
  id: string,

  options?: Omit<
    UseQueryOptions<SenderDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SenderDetailsView, Error> {
  const key = ["GET", `/sender-details/${id}`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getSenderDetailsById(id);
      return result.data;
    },
    options
  );
}

export function usePutSenderDetailsByIdQuery(
  id: string,

  data: SenderDetailsUpdateView,

  options?: Omit<
    UseQueryOptions<SenderDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SenderDetailsView, Error> {
  const key = ["PUT", `/sender-details/${id}`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putSenderDetailsById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutSenderDetailsFindFilterQuery(
  data: SenderDetailsFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageSenderDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageSenderDetailsView, Error> {
  const key = ["PUT", "/sender-details/find/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putSenderDetailsFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutPaymentAccountPaymentMethodDefaultByIdQuery(
  id: string,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/payment-account/payment-method/${id}/default`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.putPaymentAccountPaymentMethodDefaultById(id);
      return result.data;
    },
    options
  );
}

export function usePutPaymentAccountInvoicePayByInvoiceIdQuery(
  invoiceId: string,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/payment-account/invoice/${invoiceId}/pay`, invoiceId];
  return useQuery(
    key,
    async () => {
      const result = await api.putPaymentAccountInvoicePayByInvoiceId(
        invoiceId
      );
      return result.data;
    },
    options
  );
}

export function usePutPaymentAccountInvoiceExportQuery(
  data: ExportInvoicesView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", "/payment-account/invoice/export", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putPaymentAccountInvoiceExport(data);
      return result.data;
    },
    options
  );
}

export function usePutIntegrationFindFilterQuery(
  data: IntegrationFilterView,

  options?: Omit<
    UseQueryOptions<IntegrationsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<IntegrationsView, Error> {
  const key = ["PUT", "/integration/find/filter", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putIntegrationFindFilter(data);
      return result.data;
    },
    options
  );
}

export function useGetIdentityLogoByIdQuery(
  id: string,

  options?: Omit<
    UseQueryOptions<IdentityLogoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<IdentityLogoView, Error> {
  const key = ["GET", `/identity/${id}/logo`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getIdentityLogoById(id);
      return result.data;
    },
    options
  );
}

export function usePutCampaignContentSourceByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      data: CampaignContentSourceRequestView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    data: CampaignContentSourceRequestView;
  },
  unknown
> {
  return useMutation(
    async ({
      id,
      data,
    }: {
      id: string;
      data: CampaignContentSourceRequestView;
    }) => {
      const result = await api.putCampaignContentSourceById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutIdentityLogoByIdQuery(
  id: string,

  data: { logoFile?: File },

  options?: Omit<
    UseQueryOptions<IdentityLogoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<IdentityLogoView, Error> {
  const key = ["PUT", `/identity/${id}/logo`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putIdentityLogoById(id, data);
      return result.data;
    },
    options
  );
}

export function useGetIdentityQuery(
  options?: Omit<UseQueryOptions<IdentityView, Error>, "queryKey" | "queryFn">
): UseQueryResult<IdentityView, Error> {
  const key = ["GET", "/identity"];
  return useQuery(
    key,
    async () => {
      const result = await api.getIdentity();
      return result.data;
    },
    options
  );
}

export function usePutIdentityQuery(
  data: { logoFile?: File; identity: IdentityView },

  options?: Omit<UseQueryOptions<IdentityView, Error>, "queryKey" | "queryFn">
): UseQueryResult<IdentityView, Error> {
  const key = ["PUT", "/identity", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putIdentity(data);
      return result.data;
    },
    options
  );
}

export function usePostIdentityQuery(
  data: { logoFile?: File; identity: IdentityView },

  options?: Omit<UseQueryOptions<IdentityView, Error>, "queryKey" | "queryFn">
): UseQueryResult<IdentityView, Error> {
  const key = ["POST", "/identity", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postIdentity(data);
      return result.data;
    },
    options
  );
}

export function useGetCampaignByIdQuery(
  id: string,

  options?: Omit<
    UseQueryOptions<CampaignResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignResponseView, Error> {
  const key = ["GET", `/campaign/${id}`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getCampaignById(id);
      return result.data;
    },
    options
  );
}

export function usePutCampaignByIdQuery(
  id: string,

  data: CampaignUpdateRequestView,

  options?: Omit<
    UseQueryOptions<CampaignResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignResponseView, Error> {
  const key = ["PUT", `/campaign/${id}`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignUnscheduleByIdQuery(
  id: string,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/campaign/${id}/unschedule`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignUnscheduleById(id);
      return result.data;
    },
    options
  );
}

export function usePutCampaignTestEmailsByIdQuery(
  data: CampaignTestEmailsView,

  options?: Omit<
    UseQueryOptions<CampaignRemainedTestEmailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignRemainedTestEmailsView, Error> {
  const key = ["PUT", `/campaign-layout/send-test-email`, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignTestEmailsById(data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignTemplateByIdQuery(
  id: string,

  options?: Omit<
    UseQueryOptions<CampaignResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignResponseView, Error> {
  const key = ["PUT", `/campaign/${id}/template`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignTemplateById(id);
      return result.data;
    },
    options
  );
}

export function usePutCampaignStartByIdQuery(
  id: string,

  data: CampaignStartView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/campaign/${id}/start`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignStartById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignSenderDetailsByIdQuery(
  id: string,

  data: SenderDetailsIdentityView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/campaign/${id}/sender-details`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignSenderDetailsById(id, data);
      return result.data;
    },
    options
  );
}

export function useGetCampaignSegmentsByIdQuery(
  id: string,

  options?: Omit<
    UseQueryOptions<SegmentIdentitiesView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentitiesView, Error> {
  const key = ["GET", `/campaign/${id}/segments`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getCampaignSegmentsById(id);
      return result.data;
    },
    options
  );
}

export function usePutCampaignSegmentsByIdQuery(
  id: string,

  data: SegmentIdentitiesView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/campaign/${id}/segments`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignSegmentsById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignCheckByIdQuery(
  id: string,

  data: CampaignStartView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/campaign/${id}/check`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignCheckById(id, data);
      return result.data;
    },
    options
  );
}

export function useGetCampaignAutoTrackingByIdQuery(
  id: string,

  options?: Omit<UseQueryOptions<EnabledView, Error>, "queryKey" | "queryFn">
): UseQueryResult<EnabledView, Error> {
  const key = ["GET", `/campaign/${id}/auto-tracking`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getCampaignAutoTrackingById(id);
      return result.data;
    },
    options
  );
}

export function usePutCampaignAutoTrackingByIdQuery(
  id: string,

  data: EnabledView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/campaign/${id}/auto-tracking`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignAutoTrackingById(id, data);
      return result.data;
    },
    options
  );
}

export function useGetCampaignActivitiesByIdQuery(
  id: string,

  options?: Omit<
    UseQueryOptions<ActivityDetailsListView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ActivityDetailsListView, Error> {
  const key = ["GET", `/campaign/${id}/activities`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getCampaignActivitiesById(id);
      return result.data;
    },
    options
  );
}

export function usePutCampaignActivitiesByIdQuery(
  id: string,

  data: ActivityIdentitiesView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/campaign/${id}/activities`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignActivitiesById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignActivitiesEmailMessageByCampaignIdQuery(
  campaignId: string,

  data: ActivityDetailsView,

  options?: Omit<UseQueryOptions<IdView, Error>, "queryKey" | "queryFn">
): UseQueryResult<IdView, Error> {
  const key = [
    "PUT",
    `/campaign/${campaignId}/activities/email-message`,
    campaignId,
    data,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignActivitiesEmailMessageByCampaignId(
        campaignId,
        data
      );
      return result.data;
    },
    options
  );
}

export function usePutCampaignSegmentsCheckAssignedQuery(
  data: IdsView,

  options?: Omit<UseQueryOptions<IdsView, Error>, "queryKey" | "queryFn">
): UseQueryResult<IdsView, Error> {
  const key = ["PUT", "/campaign/segments/check-assigned", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignSegmentsCheckAssigned(data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignFindFilterQuery(
  data: CampaignFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageCampaignInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageCampaignInfoView, Error> {
  const key = ["PUT", "/campaign/find/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutCampaignFindFilterExportQuery(
  data: CampaignFilterView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", "/campaign/find/filter/export", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCampaignFindFilterExport(data);
      return result.data;
    },
    options
  );
}

export function useGetActivityByIdQuery(
  id: string,

  options?: Omit<
    UseQueryOptions<ActivityDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ActivityDetailsView, Error> {
  const key = ["GET", `/activity/${id}`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getActivityById(id);
      return result.data;
    },
    options
  );
}

export function usePutActivityByIdQuery(
  id: string,

  data: ActivityDetailsView,

  options?: Omit<
    UseQueryOptions<ActivityIdentityView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ActivityIdentityView, Error> {
  const key = ["PUT", `/activity/${id}`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putActivityById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutActivityFavoriteByIdAndIsFavoriteQuery(
  id: string,

  isFavorite: boolean,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/activity/${id}/favorite/${isFavorite}`, id, isFavorite];
  return useQuery(
    key,
    async () => {
      const result = await api.putActivityFavoriteByIdAndIsFavorite(
        id,
        isFavorite
      );
      return result.data;
    },
    options
  );
}

export function usePutActivityFindFilterQuery(
  data: ActivityFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageActivityIdentityView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageActivityIdentityView, Error> {
  const key = ["PUT", "/activity/find/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putActivityFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutActivityTemplateEmailFavoriteByIdAndIsFavoriteQuery(
  id: string,

  isFavorite: boolean,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "PUT",
    `/activity-template/email/${id}/favorite/${isFavorite}`,
    id,
    isFavorite,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.putActivityTemplateEmailFavoriteByIdAndIsFavorite(
          id,
          isFavorite
        );
      return result.data;
    },
    options
  );
}

export function usePutActivityTemplateEmailFindFilterQuery(
  data: EmailActivityTemplateFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageEmailActivityTemplateIdentityView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageEmailActivityTemplateIdentityView, Error> {
  const key = ["PUT", "/activity-template/email/find/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putActivityTemplateEmailFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function useGetAccountQuery(
  options?: Omit<UseQueryOptions<AccountView, Error>, "queryKey" | "queryFn">
): UseQueryResult<AccountView, Error> {
  const key = ["GET", "/account"];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccount();
      return result.data;
    },
    options
  );
}

export function usePutAccountQuery(
  data: AccountView,

  options?: Omit<UseQueryOptions<AccountView, Error>, "queryKey" | "queryFn">
): UseQueryResult<AccountView, Error> {
  const key = ["PUT", "/account", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putAccount(data);
      return result.data;
    },
    options
  );
}

export function usePostAccountQuery(
  data: AccountCreateView,

  options?: Omit<UseQueryOptions<AccountView, Error>, "queryKey" | "queryFn">
): UseQueryResult<AccountView, Error> {
  const key = ["POST", "/account", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postAccount(data);
      return result.data;
    },
    options
  );
}

export function usePutAccountCanceledByAccountIdQuery(
  accountId: string,

  query: {
    reason: string;
    issueType:
      | "SUBSCRIPTION_PAYMENT"
      | "ACCOUNT_SETUP"
      | "SUBSCRIPTION_LIMIT"
      | "HIGH_BOUNCE_RATE"
      | "HIGH_SPAM_RATE"
      | "HIGH_UNSUBSCRIBE_RATE"
      | "ACCOUNT_ISSUE";
  },

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/account/${accountId}/canceled`, accountId, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putAccountCanceledByAccountId(accountId, query);
      return result.data;
    },
    options
  );
}

export function usePutAccountAccountIntegrationExecutionByAccountIdQuery(
  accountId: string,

  data: AccountIntegrationExecutionView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "PUT",
    `/account/${accountId}/account-integration/execution`,
    accountId,
    data,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.putAccountAccountIntegrationExecutionByAccountId(
        accountId,
        data
      );
      return result.data;
    },
    options
  );
}

export function usePutAccountNotificationSettingNotificationQuery(
  data: ConfigurableNotificationUpdateView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", "/account/notification-setting/notification", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putAccountNotificationSettingNotification(data);
      return result.data;
    },
    options
  );
}

export function usePutAccountNotificationSettingCategoryQuery(
  data: ConfigurableNotificationCategoryUpdateView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", "/account/notification-setting/category", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putAccountNotificationSettingCategory(data);
      return result.data;
    },
    options
  );
}

export function usePutAccountIntegrationTriggerByIntegrationSystemNameQuery(
  integrationSystemName: string,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "PUT",
    `/account-integration/${integrationSystemName}/trigger`,
    integrationSystemName,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.putAccountIntegrationTriggerByIntegrationSystemName(
          integrationSystemName
        );
      return result.data;
    },
    options
  );
}

export function usePutAccountIntegrationSaveQuery(
  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", "/account-integration/save"];
  return useQuery(
    key,
    async () => {
      const result = await api.putAccountIntegrationSave();
      return result.data;
    },
    options
  );
}

export function usePostTicketQuery(
  data: TicketCreateRequestDto,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["POST", "/ticket", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postTicket(data);
      return result.data;
    },
    options
  );
}

export function useGetSubscriptionLeadQuery(
  options?: Omit<UseQueryOptions<LeadView, Error>, "queryKey" | "queryFn">
): UseQueryResult<LeadView, Error> {
  const key = ["GET", "/subscription/lead"];
  return useQuery(
    key,
    async () => {
      const result = await api.getSubscriptionLead();
      return result.data;
    },
    options
  );
}

export function usePostSubscriptionLeadQuery(
  data: LeadView,

  options?: Omit<
    UseQueryOptions<LeadIdentityView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<LeadIdentityView, Error> {
  const key = ["POST", "/subscription/lead", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSubscriptionLead(data);
      return result.data;
    },
    options
  );
}

export function usePostSubscriptionAppsumoLicenseQuery(
  options?: Omit<
    UseQueryOptions<LicenseCreatedView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<LicenseCreatedView, Error> {
  const key = ["POST", "/subscription/appsumo/license"];
  return useQuery(
    key,
    async () => {
      const result = await api.postSubscriptionAppsumoLicense();
      return result.data;
    },
    options
  );
}

export function useGetSenderDetailsQuery(
  options?: Omit<
    UseQueryOptions<SenderDetailsesView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SenderDetailsesView, Error> {
  const key = ["GET", "/sender-details"];
  return useQuery(
    key,
    async () => {
      const result = await api.getSenderDetails();
      return result.data;
    },
    options
  );
}

export function usePostSenderDetailsQuery(
  data: SenderDetailsCreateView,

  options?: Omit<
    UseQueryOptions<SenderDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SenderDetailsView, Error> {
  const key = ["POST", "/sender-details", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSenderDetails(data);
      return result.data;
    },
    options
  );
}

export function usePostSenderDetailsEmailConfirmTokenBySenderDetailsIdQuery(
  senderDetailsId: string,

  options?: Omit<UseQueryOptions<string, Error>, "queryKey" | "queryFn">
): UseQueryResult<string, Error> {
  const key = [
    "POST",
    `/sender-details/${senderDetailsId}/email-confirm-token`,
    senderDetailsId,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postSenderDetailsEmailConfirmTokenBySenderDetailsId(
          senderDetailsId
        );
      return result.data;
    },
    options
  );
}

export function usePostSenderDetailsConfirmEmailQuery(
  data: SenderDetailsEmailConfirmationView,

  options?: Omit<
    UseQueryOptions<SenderDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SenderDetailsView, Error> {
  const key = ["POST", "/sender-details/confirm-email", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSenderDetailsConfirmEmail(data);
      return result.data;
    },
    options
  );
}

export function usePostPaymentAccountWebhookQuery(
  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["POST", "/payment-account/webhook"];
  return useQuery(
    key,
    async () => {
      const result = await api.postPaymentAccountWebhook();
      return result.data;
    },
    options
  );
}

export function useGetPaymentAccountPaymentMethodQuery(
  options?: Omit<
    UseQueryOptions<PaymentMethodsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PaymentMethodsView, Error> {
  const key = ["GET", "/payment-account/payment-method"];
  return useQuery(
    key,
    async () => {
      const result = await api.getPaymentAccountPaymentMethod();
      return result.data;
    },
    options
  );
}

export function usePostPaymentAccountPaymentMethodQuery(
  data: AttachPaymentMethodView,

  options?: Omit<
    UseQueryOptions<PaymentMethodView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PaymentMethodView, Error> {
  const key = ["POST", "/payment-account/payment-method", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postPaymentAccountPaymentMethod(data);
      return result.data;
    },
    options
  );
}

export function usePostCampaignQuery(
  data: CampaignCreateRequestView,

  options?: Omit<
    UseQueryOptions<CampaignResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignResponseView, Error> {
  const key = ["POST", "/campaign", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postCampaign(data);
      return result.data;
    },
    options
  );
}

export function usePostCampaignSenderDetailsEmailConfirmTokenByCampaignIdAndSenderDetailsIdQuery(
  campaignId: string,

  senderDetailsId: string,

  options?: Omit<UseQueryOptions<string, Error>, "queryKey" | "queryFn">
): UseQueryResult<string, Error> {
  const key = [
    "POST",
    `/campaign/${campaignId}/sender-details/${senderDetailsId}/email-confirm-token`,
    campaignId,
    senderDetailsId,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postCampaignSenderDetailsEmailConfirmTokenByCampaignIdAndSenderDetailsId(
          campaignId,
          senderDetailsId
        );
      return result.data;
    },
    options
  );
}

export function usePostCampaignTemplateQuery(
  data: CampaignCreateByTemplateRequestView,

  options?: Omit<
    UseQueryOptions<CampaignResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignResponseView, Error> {
  const key = ["POST", "/campaign/template", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postCampaignTemplate(data);
      return result.data;
    },
    options
  );
}

export function usePostCampaignStatisticsTotalQuery(
  data: CampaignStatisticsTotalRequestView,

  options?: Omit<
    UseQueryOptions<CampaignStatisticsTotalResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignStatisticsTotalResponseView, Error> {
  const key = ["POST", "/campaign/statistics/total", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postCampaignStatisticsTotal(data);
      return result.data;
    },
    options
  );
}

export function usePostCampaignStatisticsGroupedQuery(
  data: CampaignStatisticsGroupedRequestView,

  options?: Omit<
    UseQueryOptions<CampaignStatisticsGroupedResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignStatisticsGroupedResponseView, Error> {
  const key = ["POST", "/campaign/statistics/grouped", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postCampaignStatisticsGrouped(data);
      return result.data;
    },
    options
  );
}

export function usePostCampaignCloneQuery(
  data: CampaignCloneRequestView,

  options?: Omit<
    UseQueryOptions<CampaignResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignResponseView, Error> {
  const key = ["POST", "/campaign/clone", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postCampaignClone(data);
      return result.data;
    },
    options
  );
}

export function usePostActivityQuery(
  data: ActivityDetailsView,

  options?: Omit<
    UseQueryOptions<ActivityIdentityView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ActivityIdentityView, Error> {
  const key = ["POST", "/activity", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postActivity(data);
      return result.data;
    },
    options
  );
}

export function usePostAccountAccountIntegrationByAccountIdQuery(
  accountId: string,

  data: AccountIntegrationView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "POST",
    `/account/${accountId}/account-integration`,
    accountId,
    data,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.postAccountAccountIntegrationByAccountId(
        accountId,
        data
      );
      return result.data;
    },
    options
  );
}

export function useGetTemplateTagQuery(
  options?: Omit<
    UseQueryOptions<TemplateTagsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<TemplateTagsView, Error> {
  const key = ["GET", "/template-tag"];
  return useQuery(
    key,
    async () => {
      const result = await api.getTemplateTag();
      return result.data;
    },
    options
  );
}

export function useGetSubscriptionQuery(
  options?: Omit<
    UseQueryOptions<SubscriptionsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SubscriptionsView, Error> {
  const key = ["GET", "/subscription"];
  return useQuery(
    key,
    async () => {
      const result = await api.getSubscription();
      return result.data;
    },
    options
  );
}

export function useGetSubscriptionByTierByTierIdQuery(
  tierId: string,

  options?: Omit<
    UseQueryOptions<SubscriptionView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SubscriptionView, Error> {
  const key = ["GET", `/subscription/by-tier/${tierId}`, tierId];
  return useQuery(
    key,
    async () => {
      const result = await api.getSubscriptionByTierByTierId(tierId);
      return result.data;
    },
    options
  );
}

export function useGetSubscriptionFeatureAvailableQuery(
  options?: Omit<
    UseQueryOptions<SubscriptionFeaturesAvailabilityView[], Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SubscriptionFeaturesAvailabilityView[], Error> {
  const key = ["GET", "/subscription-feature/available"];
  return useQuery(
    key,
    async () => {
      const result = await api.getSubscriptionFeatureAvailable();
      return result.data;
    },
    options
  );
}

export function useGetSubscriptionFeatureAvailableByFeatureSystemNameQuery(
  featureSystemName: string,

  options?: Omit<
    UseQueryOptions<SubscriptionFeaturesAvailabilityView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SubscriptionFeaturesAvailabilityView, Error> {
  const key = [
    "GET",
    `/subscription-feature/available/${featureSystemName}`,
    featureSystemName,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.getSubscriptionFeatureAvailableByFeatureSystemName(
          featureSystemName
        );
      return result.data;
    },
    options
  );
}

export function useGetSubscriptionFeatureAvailableByCategoriesQuery(
  options?: Omit<
    UseQueryOptions<SubscriptionFeaturesCategoriesAvailabilityView[], Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SubscriptionFeaturesCategoriesAvailabilityView[], Error> {
  const key = ["GET", "/subscription-feature/available/by-categories"];
  return useQuery(
    key,
    async () => {
      const result = await api.getSubscriptionFeatureAvailableByCategories();
      return result.data;
    },
    options
  );
}

export function useGetPaymentAccountInvoiceQuery(
  query: { limit?: number; startAfter?: string },

  options?: Omit<UseQueryOptions<InvoicesView, Error>, "queryKey" | "queryFn">
): UseQueryResult<InvoicesView, Error> {
  const key = ["GET", "/payment-account/invoice", query];
  return useQuery(
    key,
    async () => {
      const result = await api.getPaymentAccountInvoice(query);
      return result.data;
    },
    options
  );
}

export function useGetIntegrationQuery(
  options?: Omit<
    UseQueryOptions<IntegrationsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<IntegrationsView, Error> {
  const key = ["GET", "/integration"];
  return useQuery(
    key,
    async () => {
      const result = await api.getIntegration();
      return result.data;
    },
    options
  );
}

export function useGetIntegrationProfiledQuery(
  options?: Omit<
    UseQueryOptions<IntegrationsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<IntegrationsView, Error> {
  const key = ["GET", "/integration/profiled"];
  return useQuery(
    key,
    async () => {
      const result = await api.getIntegrationProfiled();
      return result.data;
    },
    options
  );
}

export function useGetIdentitySocialLinksProfiledQuery(
  options?: Omit<
    UseQueryOptions<SocialLinksView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SocialLinksView, Error> {
  const key = ["GET", "/identity/social-links/profiled"];
  return useQuery(
    key,
    async () => {
      const result = await api.getIdentitySocialLinksProfiled();
      return result.data;
    },
    options
  );
}

export function useGetIdentityProfiledQuery(
  options?: Omit<
    UseQueryOptions<IdentityProfiledView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<IdentityProfiledView, Error> {
  const key = ["GET", "/identity/profiled"];
  return useQuery(
    key,
    async () => {
      const result = await api.getIdentityProfiled();
      return result.data;
    },
    options
  );
}

export function useGetCampaignEmailFooterByCampaignIdQuery(
  campaignId: string,

  options?: Omit<
    UseQueryOptions<ActivityEmailFooterView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ActivityEmailFooterView, Error> {
  const key = ["GET", `/campaign/${campaignId}/email/footer`, campaignId];
  return useQuery(
    key,
    async () => {
      const result = await api.getCampaignEmailFooterByCampaignId(campaignId);
      return result.data;
    },
    options
  );
}

export function useGetCampaignActivitiesByCampaignIdAndActivityIdQuery(
  campaignId: string,

  activityId: string,

  options?: Omit<
    UseQueryOptions<ActivityDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ActivityDetailsView, Error> {
  const key = [
    "GET",
    `/campaign/${campaignId}/activities/${activityId}`,
    campaignId,
    activityId,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.getCampaignActivitiesByCampaignIdAndActivityId(
        campaignId,
        activityId
      );
      return result.data;
    },
    options
  );
}

export function useGetCampaignActivitiesTemplateAttributesByCampaignIdQuery(
  campaignId: string,

  options?: Omit<
    UseQueryOptions<ActivityTemplateAttributesView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ActivityTemplateAttributesView, Error> {
  const key = [
    "GET",
    `/campaign/${campaignId}/activities/template-attributes`,
    campaignId,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.getCampaignActivitiesTemplateAttributesByCampaignId(
          campaignId
        );
      return result.data;
    },
    options
  );
}

export function useGetCampaignTemplatesQuery(
  query: { searchTextName: string },

  options?: Omit<
    UseQueryOptions<CampaignIdentitiesView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CampaignIdentitiesView, Error> {
  const key = ["GET", "/campaign/templates", query];
  return useQuery(
    key,
    async () => {
      const result = await api.getCampaignTemplates(query);
      return result.data;
    },
    options
  );
}

export function useGetActivityTemplateAttributesQuery(
  options?: Omit<
    UseQueryOptions<ActivityTemplateAttributesView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ActivityTemplateAttributesView, Error> {
  const key = ["GET", "/activity/template-attributes"];
  return useQuery(
    key,
    async () => {
      const result = await api.getActivityTemplateAttributes();
      return result.data;
    },
    options
  );
}

export function useGetActivityEmailFooterQuery(
  options?: Omit<
    UseQueryOptions<ActivityEmailFooterView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ActivityEmailFooterView, Error> {
  const key = ["GET", "/activity/email/footer"];
  return useQuery(
    key,
    async () => {
      const result = await api.getActivityEmailFooter();
      return result.data;
    },
    options
  );
}

export function useGetActivityTemplateEmailByIdQuery(
  id: string,

  options?: Omit<
    UseQueryOptions<EmailActivityTemplateDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<EmailActivityTemplateDetailsView, Error> {
  const key = ["GET", `/activity-template/email/${id}`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getActivityTemplateEmailById(id);
      return result.data;
    },
    options
  );
}

export function useGetAccountAccountIntegrationByAccountIdAndIntegrationSystemNameQuery(
  accountId: string,

  integrationSystemName: string,

  options?: Omit<
    UseQueryOptions<AccountIntegrationItemView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<AccountIntegrationItemView, Error> {
  const key = [
    "GET",
    `/account/${accountId}/account-integration/${integrationSystemName}`,
    accountId,
    integrationSystemName,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.getAccountAccountIntegrationByAccountIdAndIntegrationSystemName(
          accountId,
          integrationSystemName
        );
      return result.data;
    },
    options
  );
}

export function useGetAccountProfiledQuery(
  options?: Omit<UseQueryOptions<AccountView, Error>, "queryKey" | "queryFn">
): UseQueryResult<AccountView, Error> {
  const key = ["GET", "/account/profiled"];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccountProfiled();
      return result.data;
    },
    options
  );
}

export function useGetAccountNotificationSettingQuery(
  options?: Omit<
    UseQueryOptions<ConfigurableNotificationSettingsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ConfigurableNotificationSettingsView, Error> {
  const key = ["GET", "/account/notification-setting"];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccountNotificationSetting();
      return result.data;
    },
    options
  );
}

export function useGetAccountIssuesQuery(
  options?: Omit<
    UseQueryOptions<AccountIssuesView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<AccountIssuesView, Error> {
  const key = ["GET", "/account/issues"];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccountIssues();
      return result.data;
    },
    options
  );
}

export function useGetAccountIntegrationQuery(
  options?: Omit<
    UseQueryOptions<AccountIntegrationsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<AccountIntegrationsView, Error> {
  const key = ["GET", "/account-integration"];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccountIntegration();
      return result.data;
    },
    options
  );
}

export function useGetAccountIntegrationByIntegrationSystemNameQuery(
  integrationSystemName: string,

  options?: Omit<
    UseQueryOptions<AccountIntegrationItemView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<AccountIntegrationItemView, Error> {
  const key = [
    "GET",
    `/account-integration/${integrationSystemName}`,
    integrationSystemName,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccountIntegrationByIntegrationSystemName(
        integrationSystemName
      );
      return result.data;
    },
    options
  );
}

export function useGetAccountIntegrationSummaryQuery(
  options?: Omit<
    UseQueryOptions<AccountIntegrationsSummaryView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<AccountIntegrationsSummaryView, Error> {
  const key = ["GET", "/account-integration/summary"];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccountIntegrationSummary();
      return result.data;
    },
    options
  );
}

export function useGetSegmentBySegmentIdQuery(
  segmentId: string,

  options?: Omit<
    UseQueryOptions<SegmentDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentDetailsView, Error> {
  const key = ["GET", `/segment/${segmentId}`, segmentId];
  return useQuery(
    key,
    async () => {
      const result = await api.getSegmentBySegmentId(segmentId);
      return result.data;
    },
    options
  );
}

export function usePutSegmentBySegmentIdQuery(
  segmentId: string,

  data: SegmentUpdateView,

  options?: Omit<
    UseQueryOptions<SegmentIdentityView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentityView1, Error> {
  const key = ["PUT", `/segment/${segmentId}`, segmentId, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putSegmentBySegmentId(segmentId, data);
      return result.data;
    },
    options
  );
}

export function usePutSegmentCustomersBySegmentIdQuery(
  segmentId: string,

  data: CustomersUpdateView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/segment/${segmentId}/customers`, segmentId, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putSegmentCustomersBySegmentId(segmentId, data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentCustomersBySegmentIdQuery(
  segmentId: string,

  data: CustomerBatchView,

  options?: Omit<
    UseQueryOptions<SegmentIdentityView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentityView1, Error> {
  const key = ["POST", `/segment/${segmentId}/customers`, segmentId, data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentCustomersBySegmentId(segmentId, data);
      return result.data;
    },
    options
  );
}

export function usePutSegmentStatisticByTypeQuery(
  data: SegmentTypeStatisticRequestView,

  options?: Omit<
    UseQueryOptions<SegmentTypeStatisticResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentTypeStatisticResponseView, Error> {
  const key = ["PUT", "/segment/statistic/by-type", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putSegmentStatisticByType(data);
      return result.data;
    },
    options
  );
}

export function usePutSegmentFindIdsQuery(
  data: IdsView,

  options?: Omit<
    UseQueryOptions<SegmentIdentitiesView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentitiesView1, Error> {
  const key = ["PUT", "/segment/find/ids", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putSegmentFindIds(data);
      return result.data;
    },
    options
  );
}

export function usePutSegmentFindFilterQuery(
  data: SegmentFilterView,

  query: {
    page?: number;
    size?: number;
    sort?: string[];
    countAllCustomers?: boolean;
  },

  options?: Omit<
    UseQueryOptions<SegmentPageSegmentView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentPageSegmentView, Error> {
  const key = ["PUT", "/segment/find/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putSegmentFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutSegmentCustomSummaryQuery(
  data: CustomSegmentSummaryRequestView,

  options?: Omit<
    UseQueryOptions<CustomSegmentSummaryResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomSegmentSummaryResponseView, Error> {
  const key = ["PUT", "/segment/custom/summary", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putSegmentCustomSummary(data);
      return result.data;
    },
    options
  );
}

export function usePutCustomerByCustomerIdQuery(
  customerId: string,

  data: CustomerView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/customer/${customerId}`, customerId, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCustomerByCustomerId(customerId, data);
      return result.data;
    },
    options
  );
}

export function usePutCustomerStatusByCustomerIdAndCustomerStatusQuery(
  customerId: string,

  customerStatus: string,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "PUT",
    `/customer/${customerId}/status/${customerStatus}`,
    customerId,
    customerStatus,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.putCustomerStatusByCustomerIdAndCustomerStatus(
        customerId,
        customerStatus
      );
      return result.data;
    },
    options
  );
}

export function usePutCustomerFilterQuery(
  data: CustomerFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageCustomerInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageCustomerInfoView, Error> {
  const key = ["PUT", "/customer/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putCustomerFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutCustomerFilterExportQuery(
  data: CustomerFilterView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", "/customer/filter/export", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCustomerFilterExport(data);
      return result.data;
    },
    options
  );
}

export function usePutCustomerCompositeFilterQuery(
  data: CustomerCompositeFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageCustomerInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageCustomerInfoView, Error> {
  const key = ["PUT", "/customer/composite-filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putCustomerCompositeFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutCustomerActionPlatformInfoFindFilterQuery(
  data: CustomerActionPlatformFindFilterView,

  options?: Omit<
    UseQueryOptions<CustomerActionPlatformInfoView[], Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerActionPlatformInfoView[], Error> {
  const key = ["PUT", "/customer-action/platform-info/find/filter", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCustomerActionPlatformInfoFindFilter(data);
      return result.data;
    },
    options
  );
}

export function usePutCustomerActionGeolocationInfoFindFilterQuery(
  data: CustomerActionGeolocationFindFilterView,

  options?: Omit<
    UseQueryOptions<CustomerActionGeolocationInfoView[], Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerActionGeolocationInfoView[], Error> {
  const key = ["PUT", "/customer-action/geolocation-info/find/filter", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCustomerActionGeolocationInfoFindFilter(data);
      return result.data;
    },
    options
  );
}

export function usePutCustomerActionDomainInfoFindFilterQuery(
  data: CustomerActionDomainFindFilterView,

  options?: Omit<
    UseQueryOptions<CustomerActionDomainInfoView[], Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerActionDomainInfoView[], Error> {
  const key = ["PUT", "/customer-action/domain-info/find/filter", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putCustomerActionDomainInfoFindFilter(data);
      return result.data;
    },
    options
  );
}

export function usePutAccountSegmentCustomerFilterByAccountIdQuery(
  accountId: string,

  data: CustomerSegmentFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageCustomerSegmentInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageCustomerSegmentInfoView, Error> {
  const key = [
    "PUT",
    `/account/${accountId}/segment-customer/filter`,
    accountId,
    data,
    query,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.putAccountSegmentCustomerFilterByAccountId(
        accountId,
        data,
        query
      );
      return result.data;
    },
    options
  );
}

export function usePutAccountCustomerFilterByAccountIdQuery(
  accountId: string,

  data: CustomerFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageCustomerInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageCustomerInfoView, Error> {
  const key = [
    "PUT",
    `/account/${accountId}/customer/filter`,
    accountId,
    data,
    query,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.putAccountCustomerFilterByAccountId(
        accountId,
        data,
        query
      );
      return result.data;
    },
    options
  );
}

export function usePostSegmentCustomerBySegmentIdQuery(
  segmentId: string,

  data: CustomerView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["POST", `/segment/${segmentId}/customer`, segmentId, data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentCustomerBySegmentId(segmentId, data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentUploadQuery(
  data: UploadSegmentCreateView,

  options?: Omit<
    UseQueryOptions<SegmentIdentityView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentityView1, Error> {
  const key = ["POST", "/segment/upload", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentUpload(data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentUploadCustomerQuery(
  data: CustomerView,

  query: { segmentName: string },

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["POST", "/segment/upload/customer", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentUploadCustomer(data, query);
      return result.data;
    },
    options
  );
}

export function usePostSegmentStatisticsTotalQuery(
  options?: Omit<
    UseQueryOptions<SegmentTotalStatisticsResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentTotalStatisticsResponseView, Error> {
  const key = ["POST", "/segment/statistics/total"];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentStatisticsTotal();
      return result.data;
    },
    options
  );
}

export function usePostSegmentStatisticsTotalCustomersQuery(
  data: SegmentTotalCustomersStatisticsRequestView,

  options?: Omit<
    UseQueryOptions<SegmentTotalCustomersStatisticsResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentTotalCustomersStatisticsResponseView, Error> {
  const key = ["POST", "/segment/statistics/total-customers", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentStatisticsTotalCustomers(data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentIntegrationStatisticsGrowthQuery(
  data: IntegrationSegmentStatisticsGrowthRequestView,

  options?: Omit<
    UseQueryOptions<IntegrationSegmentStatisticsGrowthResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<IntegrationSegmentStatisticsGrowthResponseView, Error> {
  const key = ["POST", "/segment/integration/statistics/growth", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentIntegrationStatisticsGrowth(data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentImportQuery(
  data: SegmentImportView,

  options?: Omit<
    UseQueryOptions<SegmentIdentityView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentityView1, Error> {
  const key = ["POST", "/segment/import", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentImport(data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentCustomQuery(
  data: CustomSegmentCreateView,

  options?: Omit<
    UseQueryOptions<SegmentIdentityView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentityView1, Error> {
  const key = ["POST", "/segment/custom", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentCustom(data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentCloneQuery(
  data: SegmentCloneRequestView,

  options?: Omit<
    UseQueryOptions<SegmentIdentityView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentityView1, Error> {
  const key = ["POST", "/segment/clone", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postSegmentClone(data);
      return result.data;
    },
    options
  );
}

export function usePostCustomerStatisticsTotalQuery(
  data: CustomerStatisticsTotalRequestView,

  options?: Omit<
    UseQueryOptions<CustomerStatisticsTotalResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerStatisticsTotalResponseView, Error> {
  const key = ["POST", "/customer/statistics/total", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postCustomerStatisticsTotal(data);
      return result.data;
    },
    options
  );
}

export function usePostCustomerStatisticsGroupedQuery(
  data: CustomerStatisticsGroupedRequestView,

  options?: Omit<
    UseQueryOptions<CustomerStatisticsGroupedResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerStatisticsGroupedResponseView, Error> {
  const key = ["POST", "/customer/statistics/grouped", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postCustomerStatisticsGrouped(data);
      return result.data;
    },
    options
  );
}

export function usePostCustomerImportQuery(
  data: CustomerBatchView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["POST", "/customer/import", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postCustomerImport(data);
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventStatisticsGroupedQuery(
  data: CustomerEventStatisticsGroupedRequestView,

  options?: Omit<
    UseQueryOptions<CustomerEventStatisticsGroupedResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerEventStatisticsGroupedResponseView, Error> {
  const key = ["POST", "/customer-event/statistics/grouped", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postCustomerEventStatisticsGrouped(data);
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsTotalByCampaignIdQuery(
  campaignId: string,

  data: CustomerEventStatisticsTotalRequestView,

  options?: Omit<
    UseQueryOptions<CustomerEventStatisticsTotalResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerEventStatisticsTotalResponseView, Error> {
  const key = [
    "POST",
    `/customer-event/campaign/${campaignId}/statistics/total`,
    campaignId,
    data,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postCustomerEventCampaignStatisticsTotalByCampaignId(
          campaignId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsMostOpenByCampaignIdQuery(
  campaignId: string,

  data: CustomerEventMostOpenStatisticsFilterView,

  query: { offset?: number; limit?: number },

  options?: Omit<
    UseQueryOptions<CustomerEventMostOpenStatisticsResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerEventMostOpenStatisticsResponseView, Error> {
  const key = [
    "POST",
    `/customer-event/campaign/${campaignId}/statistics/most-open`,
    campaignId,
    data,
    query,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postCustomerEventCampaignStatisticsMostOpenByCampaignId(
          campaignId,
          data,
          query
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsMostOpenExportByCampaignIdQuery(
  campaignId: string,

  data: CustomerEventMostOpenStatisticsFilterView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "POST",
    `/customer-event/campaign/${campaignId}/statistics/most-open/export`,
    campaignId,
    data,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postCustomerEventCampaignStatisticsMostOpenExportByCampaignId(
          campaignId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsMostClickByCampaignIdQuery(
  campaignId: string,

  data: CustomerEventMostClickStatisticsFilterView,

  query: { offset?: number; limit?: number },

  options?: Omit<
    UseQueryOptions<CustomerEventMostClickStatisticsResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerEventMostClickStatisticsResponseView, Error> {
  const key = [
    "POST",
    `/customer-event/campaign/${campaignId}/statistics/most-click`,
    campaignId,
    data,
    query,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postCustomerEventCampaignStatisticsMostClickByCampaignId(
          campaignId,
          data,
          query
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsMostClickExportByCampaignIdQuery(
  campaignId: string,

  data: CustomerEventMostClickStatisticsFilterView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "POST",
    `/customer-event/campaign/${campaignId}/statistics/most-click/export`,
    campaignId,
    data,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postCustomerEventCampaignStatisticsMostClickExportByCampaignId(
          campaignId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsGroupedByCampaignIdQuery(
  campaignId: string,

  data: CustomerEventStatisticsGroupedRequestView,

  options?: Omit<
    UseQueryOptions<CustomerEventStatisticsGroupedResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerEventStatisticsGroupedResponseView, Error> {
  const key = [
    "POST",
    `/customer-event/campaign/${campaignId}/statistics/grouped`,
    campaignId,
    data,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postCustomerEventCampaignStatisticsGroupedByCampaignId(
          campaignId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsExportByCampaignIdQuery(
  campaignId: string,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "POST",
    `/customer-event/campaign/${campaignId}/statistics/export`,
    campaignId,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postCustomerEventCampaignStatisticsExportByCampaignId(
          campaignId
        );
      return result.data;
    },
    options
  );
}

export function usePostAccountSegmentCustomersByAccountIdAndSegmentIdQuery(
  accountId: string,

  segmentId: string,

  data: CustomerBatchView,

  options?: Omit<
    UseQueryOptions<SegmentIdentityView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentityView1, Error> {
  const key = [
    "POST",
    `/account/${accountId}/segment/${segmentId}/customers`,
    accountId,
    segmentId,
    data,
  ];
  return useQuery(
    key,
    async () => {
      const result =
        await api.postAccountSegmentCustomersByAccountIdAndSegmentId(
          accountId,
          segmentId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostAccountSegmentImportByAccountIdQuery(
  accountId: string,

  data: SegmentImportView,

  options?: Omit<
    UseQueryOptions<SegmentIdentityView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentityView1, Error> {
  const key = ["POST", `/account/${accountId}/segment/import`, accountId, data];
  return useQuery(
    key,
    async () => {
      const result = await api.postAccountSegmentImportByAccountId(
        accountId,
        data
      );
      return result.data;
    },
    options
  );
}

export function useGetSegmentCustomCustomersQuery(
  query: { segmentId: string; page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageCustomerInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageCustomerInfoView, Error> {
  const key = ["GET", "/segment/custom/customers", query];
  return useQuery(
    key,
    async () => {
      const result = await api.getSegmentCustomCustomers(query);
      return result.data;
    },
    options
  );
}

export function useGetSegmentConditionsInfoQuery(
  options?: Omit<
    UseQueryOptions<SegmentAttributesView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentAttributesView, Error> {
  const key = ["GET", "/segment/conditions/info"];
  return useQuery(
    key,
    async () => {
      const result = await api.getSegmentConditionsInfo();
      return result.data;
    },
    options
  );
}

export function useGetSegmentBySegmentTypeQuery(
  query: {
    searchSegmentType:
      | "UPLOAD"
      | "INTEGRATION"
      | "CUSTOM"
      | "PREDEFINED_RECOMMENDED"
      | "PREDEFINED_LIBRARY";
  },

  options?: Omit<UseQueryOptions<SegmentsView, Error>, "queryKey" | "queryFn">
): UseQueryResult<SegmentsView, Error> {
  const key = ["GET", "/segment/by-segment-type", query];
  return useQuery(
    key,
    async () => {
      const result = await api.getSegmentBySegmentType(query);
      return result.data;
    },
    options
  );
}

export function useGetSegmentByNameQuery(
  query: { searchTextName: string },

  options?: Omit<
    UseQueryOptions<SegmentIdentitiesView1, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SegmentIdentitiesView1, Error> {
  const key = ["GET", "/segment/by-name", query];
  return useQuery(
    key,
    async () => {
      const result = await api.getSegmentByName(query);
      return result.data;
    },
    options
  );
}

export function useGetCustomerTagsQuery(
  options?: Omit<UseQueryOptions<TagsView, Error>, "queryKey" | "queryFn">
): UseQueryResult<TagsView, Error> {
  const key = ["GET", "/customer/tags"];
  return useQuery(
    key,
    async () => {
      const result = await api.getCustomerTags();
      return result.data;
    },
    options
  );
}

export function useGetAccountSegmentByAccountIdQuery(
  accountId: string,

  query: { name: string },

  options?: Omit<UseQueryOptions<SegmentView, Error>, "queryKey" | "queryFn">
): UseQueryResult<SegmentView, Error> {
  const key = ["GET", `/account/${accountId}/segment`, accountId, query];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccountSegmentByAccountId(accountId, query);
      return result.data;
    },
    options
  );
}

export function useGetAccountCustomerByAccountIdQuery(
  accountId: string,

  query: { email: string },

  options?: Omit<
    UseQueryOptions<CustomerInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerInfoView, Error> {
  const key = ["GET", `/account/${accountId}/customer`, accountId, query];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccountCustomerByAccountId(accountId, query);
      return result.data;
    },
    options
  );
}

export function useGetAccountCustomerTotalByAccountIdQuery(
  accountId: string,

  query: { segmentIds: string[] },

  options?: Omit<
    UseQueryOptions<CustomerTotalView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<CustomerTotalView, Error> {
  const key = ["GET", `/account/${accountId}/customer/total`, accountId, query];
  return useQuery(
    key,
    async () => {
      const result = await api.getAccountCustomerTotalByAccountId(
        accountId,
        query
      );
      return result.data;
    },
    options
  );
}

export function usePutContentSubjectFilterQuery(
  data: SubjectLineFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageSubjectLineView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageSubjectLineView, Error> {
  const key = ["PUT", "/content/subject/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putContentSubjectFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutContentSubjectFavoriteByIdAndIsFavoriteQuery(
  id: string,

  isFavorite: boolean,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "PUT",
    `/content/subject/${id}/favorite/${isFavorite}`,
    id,
    isFavorite,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.putContentSubjectFavoriteByIdAndIsFavorite(
        id,
        isFavorite
      );
      return result.data;
    },
    options
  );
}

export function usePostContentSubjectGenerateQuery(
  data: SubjectGenerateRequestView,

  options?: Omit<
    UseQueryOptions<SubjectGenerateResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SubjectGenerateResponseView, Error> {
  const key = ["POST", "/content/subject/generate", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postContentSubjectGenerate(data);
      return result.data;
    },
    options
  );
}

export function useGetToneQuery(
  options?: Omit<UseQueryOptions<TonesView, Error>, "queryKey" | "queryFn">
): UseQueryResult<TonesView, Error> {
  const key = ["GET", "/tone"];
  return useQuery(
    key,
    async () => {
      const result = await api.getTone();
      return result.data;
    },
    options
  );
}

export function useGetMergeTagQuery(
  options?: Omit<UseQueryOptions<MergeTagsView, Error>, "queryKey" | "queryFn">
): UseQueryResult<MergeTagsView, Error> {
  const key = ["GET", "/merge-tag"];
  return useQuery(
    key,
    async () => {
      const result = await api.getMergeTag();
      return result.data;
    },
    options
  );
}

export function useGetContentSubjectFeatureQuery(
  options?: Omit<
    UseQueryOptions<SubjectLineFeatureInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<SubjectLineFeatureInfoView, Error> {
  const key = ["GET", "/content/subject/feature"];
  return useQuery(
    key,
    async () => {
      const result = await api.getContentSubjectFeature();
      return result.data;
    },
    options
  );
}

export function usePutFormByIdQuery(
  id: string,

  data: FormUpdateRequestView,

  options?: Omit<
    UseQueryOptions<FormUpdateResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<FormUpdateResponseView, Error> {
  const key = ["PUT", `/form/${id}`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutFormStatusByIdAndFormStatusQuery(
  id: string,

  formStatus: string,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/form/${id}/status/${formStatus}`, id, formStatus];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormStatusByIdAndFormStatus(id, formStatus);
      return result.data;
    },
    options
  );
}

export function usePutFormFilterQuery(
  data: FormFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageFormInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageFormInfoView, Error> {
  const key = ["PUT", "/form/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutFormConfigQuery(
  data: FormConfigView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", "/form/config/", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormConfig(data);
      return result.data;
    },
    options
  );
}

export function usePutFormConfigContentByIdQuery(
  id: string,

  data: FormContentView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/form/config/${id}/content`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormConfigContentById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutFormConfigAppearanceByIdQuery(
  id: string,

  data: FormAppearanceView,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = ["PUT", `/form/config/${id}/appearance`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormConfigAppearanceById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutFormConfigAllowedDomainsByIdQuery(
  id: string,

  data: FormAllowedDomainsView,

  options?: Omit<
    UseQueryOptions<FormAllowedDomainsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<FormAllowedDomainsView, Error> {
  const key = ["PUT", `/form/config/${id}/allowed-domains`, id, data];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormConfigAllowedDomainsById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutFormCheckAssignedAudiencesQuery(
  data: FormCheckAssignedAudiencesRequestView,

  options?: Omit<
    UseQueryOptions<FormCheckAssignedAudiencesResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<FormCheckAssignedAudiencesResponseView, Error> {
  const key = ["PUT", "/form/check/assigned-audiences", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormCheckAssignedAudiences(data);
      return result.data;
    },
    options
  );
}

export function usePutFormTemplateFavoriteByIdAndIsFavoriteQuery(
  id: string,

  isFavorite: boolean,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "PUT",
    `/form-template/${id}/favorite/${isFavorite}`,
    id,
    isFavorite,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormTemplateFavoriteByIdAndIsFavorite(
        id,
        isFavorite
      );
      return result.data;
    },
    options
  );
}

export function usePutFormTemplateFilterQuery(
  data: FormTemplateFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: Omit<
    UseQueryOptions<PageFormTemplateInfoView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<PageFormTemplateInfoView, Error> {
  const key = ["PUT", "/form-template/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putFormTemplateFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePostFormQuery(
  data: FormView,

  options?: Omit<
    UseQueryOptions<FormIdentityView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<FormIdentityView, Error> {
  const key = ["POST", "/form", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postForm(data);
      return result.data;
    },
    options
  );
}

export function usePostFormCloneQuery(
  data: FormCloneRequestView,

  options?: Omit<
    UseQueryOptions<FormCloneResponseView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<FormCloneResponseView, Error> {
  const key = ["POST", "/form/clone", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postFormClone(data);
      return result.data;
    },
    options
  );
}

export function usePostFormByTemplateQuery(
  data: FormWithTemplateView,

  options?: Omit<
    UseQueryOptions<FormIdentityView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<FormIdentityView, Error> {
  const key = ["POST", "/form/by-template", data];
  return useQuery(
    key,
    async () => {
      const result = await api.postFormByTemplate(data);
      return result.data;
    },
    options
  );
}

export function useGetFormProviderByAccountIdAndFormIdQuery(
  accountId: string,

  formId: string,

  options?: Omit<
    UseQueryOptions<FormPayloadView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<FormPayloadView, Error> {
  const key = [
    "GET",
    `/form-provider/${accountId}/${formId}`,
    accountId,
    formId,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.getFormProviderByAccountIdAndFormId(
        accountId,
        formId
      );
      return result.data;
    },
    options
  );
}

export function usePostFormProviderByAccountIdAndFormIdQuery(
  accountId: string,

  formId: string,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "POST",
    `/form-provider/${accountId}/${formId}`,
    accountId,
    formId,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.postFormProviderByAccountIdAndFormId(
        accountId,
        formId
      );
      return result.data;
    },
    options
  );
}

export function useGetFormConfigByIdQuery(
  id: string,

  options?: Omit<
    UseQueryOptions<FormDetailsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<FormDetailsView, Error> {
  const key = ["GET", `/form/config/${id}`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getFormConfigById(id);
      return result.data;
    },
    options
  );
}

export function useGetFormTemplateTagsQuery(
  options?: Omit<
    UseQueryOptions<TemplateTagsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<TemplateTagsView, Error> {
  const key = ["GET", "/form-template/tags"];
  return useQuery(
    key,
    async () => {
      const result = await api.getFormTemplateTags();
      return result.data;
    },
    options
  );
}

export function usePutAutomationEnabledByAutomationIdAndIsEnabledQuery(
  automationId: string,

  isEnabled: boolean,

  options?: Omit<UseQueryOptions<void, Error>, "queryKey" | "queryFn">
): UseQueryResult<void, Error> {
  const key = [
    "PUT",
    `/automation/${automationId}/enabled/${isEnabled}`,
    automationId,
    isEnabled,
  ];
  return useQuery(
    key,
    async () => {
      const result = await api.putAutomationEnabledByAutomationIdAndIsEnabled(
        automationId,
        isEnabled
      );
      return result.data;
    },
    options
  );
}

export function useGetAutomationQuery(
  options?: Omit<
    UseQueryOptions<AutomationsView, Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<AutomationsView, Error> {
  const key = ["GET", "/automation"];
  return useQuery(
    key,
    async () => {
      const result = await api.getAutomation();
      return result.data;
    },
    options
  );
}

export function usePutSubscriptionLeadContextEmailMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: LeadIdentityView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: LeadIdentityView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: LeadIdentityView }) => {
    const result = await api.putSubscriptionLeadContextEmail(data);
    return result.data;
  }, options);
}

export function usePutSenderDetailsByIdMutation(
  options?: UseMutationOptions<
    SenderDetailsView,
    Error,
    {
      id: string;

      data: SenderDetailsUpdateView;
    },
    unknown
  >
): UseMutationResult<
  SenderDetailsView,
  Error,
  {
    id: string;

    data: SenderDetailsUpdateView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: SenderDetailsUpdateView }) => {
      const result = await api.putSenderDetailsById(id, data);
      return result.data;
    },
    options
  );
}

export function useDeleteSenderDetailsByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.deleteSenderDetailsById(id);
    return result.data;
  }, options);
}

export function usePutSenderDetailsFindFilterMutation(
  options?: UseMutationOptions<
    PageSenderDetailsView,
    Error,
    {
      data: SenderDetailsFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageSenderDetailsView,
  Error,
  {
    data: SenderDetailsFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: SenderDetailsFilterView;
      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putSenderDetailsFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutPaymentAccountPaymentMethodDefaultByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.putPaymentAccountPaymentMethodDefaultById(id);
    return result.data;
  }, options);
}

export function usePutPaymentAccountInvoicePayByInvoiceIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      invoiceId: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    invoiceId: string;
  },
  unknown
> {
  return useMutation(async ({ invoiceId }: { invoiceId: string }) => {
    const result = await api.putPaymentAccountInvoicePayByInvoiceId(invoiceId);
    return result.data;
  }, options);
}

export function usePutPaymentAccountInvoiceExportMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: ExportInvoicesView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: ExportInvoicesView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: ExportInvoicesView }) => {
    const result = await api.putPaymentAccountInvoiceExport(data);
    return result.data;
  }, options);
}

export function usePutIntegrationFindFilterMutation(
  options?: UseMutationOptions<
    IntegrationsView,
    Error,
    {
      data: IntegrationFilterView;
    },
    unknown
  >
): UseMutationResult<
  IntegrationsView,
  Error,
  {
    data: IntegrationFilterView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: IntegrationFilterView }) => {
    const result = await api.putIntegrationFindFilter(data);
    return result.data;
  }, options);
}

export function usePutIdentityLogoByIdMutation(
  options?: UseMutationOptions<
    IdentityLogoView,
    Error,
    {
      id: string;

      data: { logoFile?: File };
    },
    unknown
  >
): UseMutationResult<
  IdentityLogoView,
  Error,
  {
    id: string;

    data: { logoFile?: File };
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: { logoFile?: File } }) => {
      const result = await api.putIdentityLogoById(id, data);
      return result.data;
    },
    options
  );
}

export function useDeleteIdentityLogoByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.deleteIdentityLogoById(id);
    return result.data;
  }, options);
}

export function usePutIdentityMutation(
  options?: UseMutationOptions<
    IdentityView,
    Error,
    {
      data: { logoFile?: File; identity: IdentityView };
    },
    unknown
  >
): UseMutationResult<
  IdentityView,
  Error,
  {
    data: { logoFile?: File; identity: IdentityView };
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: { logoFile?: File; identity: IdentityView } }) => {
      const result = await api.putIdentity(data);
      return result.data;
    },
    options
  );
}

export function usePostIdentityMutation(
  options?: UseMutationOptions<
    IdentityView,
    Error,
    {
      data: { logoFile?: File; identity: IdentityView };
    },
    unknown
  >
): UseMutationResult<
  IdentityView,
  Error,
  {
    data: { logoFile?: File; identity: IdentityView };
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: { logoFile?: File; identity: IdentityView } }) => {
      const result = await api.postIdentity(data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignByIdMutation(
  options?: UseMutationOptions<
    CampaignResponseView,
    Error,
    {
      id: string;

      data: CampaignUpdateRequestView;
    },
    unknown
  >
): UseMutationResult<
  CampaignResponseView,
  Error,
  {
    id: string;

    data: CampaignUpdateRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: CampaignUpdateRequestView }) => {
      const result = await api.putCampaignById(id, data);
      return result.data;
    },
    options
  );
}

export function useDeleteCampaignByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.deleteCampaignById(id);
    return result.data;
  }, options);
}

export function usePutCampaignUnscheduleByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.putCampaignUnscheduleById(id);
    return result.data;
  }, options);
}

export function usePutCampaignTestEmailsByIdMutation(
  options?: UseMutationOptions<
    CampaignRemainedTestEmailsView,
    Error,
    {
      data: CampaignTestEmailsView;
    },
    unknown
  >
): UseMutationResult<
  CampaignRemainedTestEmailsView,
  Error,
  {
    data: CampaignTestEmailsView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: CampaignTestEmailsView }) => {
    const result = await api.putCampaignTestEmailsById(data);
    return result.data;
  }, options);
}

export function usePutCampaignTemplateByIdMutation(
  options?: UseMutationOptions<
    CampaignResponseView,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  CampaignResponseView,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.putCampaignTemplateById(id);
    return result.data;
  }, options);
}

export function usePutCampaignStartByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      data: CampaignStartView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    data: CampaignStartView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: CampaignStartView }) => {
      const result = await api.putCampaignStartById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignSenderDetailsByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      data: SenderDetailsIdentityView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    data: SenderDetailsIdentityView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: SenderDetailsIdentityView }) => {
      const result = await api.putCampaignSenderDetailsById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignSegmentsByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      data: SegmentIdentitiesView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    data: SegmentIdentitiesView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: SegmentIdentitiesView }) => {
      const result = await api.putCampaignSegmentsById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignCheckByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      data: CampaignStartView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    data: CampaignStartView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: CampaignStartView }) => {
      const result = await api.putCampaignCheckById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignAutoTrackingByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      data: EnabledView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    data: EnabledView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: EnabledView }) => {
      const result = await api.putCampaignAutoTrackingById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignActivitiesByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      data: ActivityIdentitiesView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    data: ActivityIdentitiesView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: ActivityIdentitiesView }) => {
      const result = await api.putCampaignActivitiesById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutCampaignActivitiesEmailMessageByCampaignIdMutation(
  options?: UseMutationOptions<
    IdView,
    Error,
    {
      campaignId: string;

      data: ActivityDetailsView;
    },
    unknown
  >
): UseMutationResult<
  IdView,
  Error,
  {
    campaignId: string;

    data: ActivityDetailsView;
  },
  unknown
> {
  return useMutation(
    async ({
      campaignId,
      data,
    }: {
      campaignId: string;
      data: ActivityDetailsView;
    }) => {
      const result = await api.putCampaignActivitiesEmailMessageByCampaignId(
        campaignId,
        data
      );
      return result.data;
    },
    options
  );
}

export function usePutCampaignSegmentsCheckAssignedMutation(
  options?: UseMutationOptions<
    IdsView,
    Error,
    {
      data: IdsView;
    },
    unknown
  >
): UseMutationResult<
  IdsView,
  Error,
  {
    data: IdsView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: IdsView }) => {
    const result = await api.putCampaignSegmentsCheckAssigned(data);
    return result.data;
  }, options);
}

export function usePutCampaignFindFilterMutation(
  options?: UseMutationOptions<
    PageCampaignInfoView,
    Error,
    {
      data: CampaignFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageCampaignInfoView,
  Error,
  {
    data: CampaignFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: CampaignFilterView;
      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putCampaignFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutCampaignFindFilterExportMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: CampaignFilterView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: CampaignFilterView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: CampaignFilterView }) => {
    const result = await api.putCampaignFindFilterExport(data);
    return result.data;
  }, options);
}

export function usePutActivityByIdMutation(
  options?: UseMutationOptions<
    ActivityIdentityView,
    Error,
    {
      id: string;

      data: ActivityDetailsView;
    },
    unknown
  >
): UseMutationResult<
  ActivityIdentityView,
  Error,
  {
    id: string;

    data: ActivityDetailsView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: ActivityDetailsView }) => {
      const result = await api.putActivityById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutActivityFavoriteByIdAndIsFavoriteMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      isFavorite: boolean;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    isFavorite: boolean;
  },
  unknown
> {
  return useMutation(
    async ({ id, isFavorite }: { id: string; isFavorite: boolean }) => {
      const result = await api.putActivityFavoriteByIdAndIsFavorite(
        id,
        isFavorite
      );
      return result.data;
    },
    options
  );
}

export function usePutActivityFindFilterMutation(
  options?: UseMutationOptions<
    PageActivityIdentityView,
    Error,
    {
      data: ActivityFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageActivityIdentityView,
  Error,
  {
    data: ActivityFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: ActivityFilterView;
      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putActivityFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutActivityTemplateEmailFavoriteByIdAndIsFavoriteMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      isFavorite: boolean;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    isFavorite: boolean;
  },
  unknown
> {
  return useMutation(
    async ({ id, isFavorite }: { id: string; isFavorite: boolean }) => {
      const result =
        await api.putActivityTemplateEmailFavoriteByIdAndIsFavorite(
          id,
          isFavorite
        );
      return result.data;
    },
    options
  );
}

export function usePutActivityTemplateEmailFindFilterMutation(
  options?: UseMutationOptions<
    PageEmailActivityTemplateIdentityView,
    Error,
    {
      data: EmailActivityTemplateFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageEmailActivityTemplateIdentityView,
  Error,
  {
    data: EmailActivityTemplateFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: EmailActivityTemplateFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putActivityTemplateEmailFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutAccountMutation(
  options?: UseMutationOptions<
    AccountView,
    Error,
    {
      data: AccountView;
    },
    unknown
  >
): UseMutationResult<
  AccountView,
  Error,
  {
    data: AccountView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: AccountView }) => {
    const result = await api.putAccount(data);
    return result.data;
  }, options);
}

export function usePostAccountMutation(
  options?: UseMutationOptions<
    AccountView,
    Error,
    {
      data: AccountCreateView;
    },
    unknown
  >
): UseMutationResult<
  AccountView,
  Error,
  {
    data: AccountCreateView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: AccountCreateView }) => {
    const result = await api.postAccount(data);
    return result.data;
  }, options);
}

export function useDeleteAccountMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      query: { email: string };
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    query: { email: string };
  },
  unknown
> {
  return useMutation(async ({ query }: { query: { email: string } }) => {
    const result = await api.deleteAccount(query);
    return result.data;
  }, options);
}

export function usePutAccountCanceledByAccountIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      accountId: string;

      query: {
        reason: string;
        issueType:
          | "SUBSCRIPTION_PAYMENT"
          | "ACCOUNT_SETUP"
          | "SUBSCRIPTION_LIMIT"
          | "HIGH_BOUNCE_RATE"
          | "HIGH_SPAM_RATE"
          | "HIGH_UNSUBSCRIBE_RATE"
          | "ACCOUNT_ISSUE";
      };
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    accountId: string;

    query: {
      reason: string;
      issueType:
        | "SUBSCRIPTION_PAYMENT"
        | "ACCOUNT_SETUP"
        | "SUBSCRIPTION_LIMIT"
        | "HIGH_BOUNCE_RATE"
        | "HIGH_SPAM_RATE"
        | "HIGH_UNSUBSCRIBE_RATE"
        | "ACCOUNT_ISSUE";
    };
  },
  unknown
> {
  return useMutation(
    async ({
      accountId,
      query,
    }: {
      accountId: string;
      query: {
        reason: string;
        issueType:
          | "SUBSCRIPTION_PAYMENT"
          | "ACCOUNT_SETUP"
          | "SUBSCRIPTION_LIMIT"
          | "HIGH_BOUNCE_RATE"
          | "HIGH_SPAM_RATE"
          | "HIGH_UNSUBSCRIBE_RATE"
          | "ACCOUNT_ISSUE";
      };
    }) => {
      const result = await api.putAccountCanceledByAccountId(accountId, query);
      return result.data;
    },
    options
  );
}

export function usePutAccountAccountIntegrationExecutionByAccountIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      accountId: string;

      data: AccountIntegrationExecutionView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    accountId: string;

    data: AccountIntegrationExecutionView;
  },
  unknown
> {
  return useMutation(
    async ({
      accountId,
      data,
    }: {
      accountId: string;
      data: AccountIntegrationExecutionView;
    }) => {
      const result = await api.putAccountAccountIntegrationExecutionByAccountId(
        accountId,
        data
      );
      return result.data;
    },
    options
  );
}

export function usePutAccountNotificationSettingNotificationMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: ConfigurableNotificationUpdateView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: ConfigurableNotificationUpdateView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: ConfigurableNotificationUpdateView }) => {
      const result = await api.putAccountNotificationSettingNotification(data);
      return result.data;
    },
    options
  );
}

export function usePutAccountNotificationSettingCategoryMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: ConfigurableNotificationCategoryUpdateView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: ConfigurableNotificationCategoryUpdateView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: ConfigurableNotificationCategoryUpdateView }) => {
      const result = await api.putAccountNotificationSettingCategory(data);
      return result.data;
    },
    options
  );
}

export function usePutAccountIntegrationTriggerByIntegrationSystemNameMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      integrationSystemName: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    integrationSystemName: string;
  },
  unknown
> {
  return useMutation(
    async ({ integrationSystemName }: { integrationSystemName: string }) => {
      const result =
        await api.putAccountIntegrationTriggerByIntegrationSystemName(
          integrationSystemName
        );
      return result.data;
    },
    options
  );
}

export function usePutAccountIntegrationSaveMutation(
  options?: UseMutationOptions<void, Error, void, unknown>
): UseMutationResult<void, Error, void, unknown> {
  return useMutation(async () => {
    const result = await api.putAccountIntegrationSave();
    return result.data;
  }, options);
}

export function usePostTicketMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: TicketCreateRequestDto;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: TicketCreateRequestDto;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: TicketCreateRequestDto }) => {
    const result = await api.postTicket(data);
    return result.data;
  }, options);
}

export function usePostSubscriptionLeadMutation(
  options?: UseMutationOptions<
    LeadIdentityView,
    Error,
    {
      data: LeadView;
    },
    unknown
  >
): UseMutationResult<
  LeadIdentityView,
  Error,
  {
    data: LeadView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: LeadView }) => {
    const result = await api.postSubscriptionLead(data);
    return result.data;
  }, options);
}

export function usePostSubscriptionAppsumoLicenseMutation(
  options?: UseMutationOptions<LicenseCreatedView, Error, void, unknown>
): UseMutationResult<LicenseCreatedView, Error, void, unknown> {
  return useMutation(async () => {
    const result = await api.postSubscriptionAppsumoLicense();
    return result.data;
  }, options);
}

export function usePostSenderDetailsMutation(
  options?: UseMutationOptions<
    SenderDetailsView,
    Error,
    {
      data: SenderDetailsCreateView;
    },
    unknown
  >
): UseMutationResult<
  SenderDetailsView,
  Error,
  {
    data: SenderDetailsCreateView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: SenderDetailsCreateView }) => {
    const result = await api.postSenderDetails(data);
    return result.data;
  }, options);
}

export function useDeleteSenderDetailsMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: SenderDetailsDeleteView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: SenderDetailsDeleteView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: SenderDetailsDeleteView }) => {
    const result = await api.deleteSenderDetails(data);
    return result.data;
  }, options);
}

export function usePostSenderDetailsEmailConfirmTokenBySenderDetailsIdMutation(
  options?: UseMutationOptions<
    string,
    Error,
    {
      senderDetailsId: string;
    },
    unknown
  >
): UseMutationResult<
  string,
  Error,
  {
    senderDetailsId: string;
  },
  unknown
> {
  return useMutation(
    async ({ senderDetailsId }: { senderDetailsId: string }) => {
      const result =
        await api.postSenderDetailsEmailConfirmTokenBySenderDetailsId(
          senderDetailsId
        );
      return result.data;
    },
    options
  );
}

export function usePostSenderDetailsConfirmEmailMutation(
  options?: UseMutationOptions<
    SenderDetailsView,
    Error,
    {
      data: SenderDetailsEmailConfirmationView;
    },
    unknown
  >
): UseMutationResult<
  SenderDetailsView,
  Error,
  {
    data: SenderDetailsEmailConfirmationView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: SenderDetailsEmailConfirmationView }) => {
      const result = await api.postSenderDetailsConfirmEmail(data);
      return result.data;
    },
    options
  );
}

export function usePostPaymentAccountWebhookMutation(
  options?: UseMutationOptions<void, Error, void, unknown>
): UseMutationResult<void, Error, void, unknown> {
  return useMutation(async () => {
    const result = await api.postPaymentAccountWebhook();
    return result.data;
  }, options);
}

export function usePostPaymentAccountPaymentMethodMutation(
  options?: UseMutationOptions<
    PaymentMethodView,
    Error,
    {
      data: AttachPaymentMethodView;
    },
    unknown
  >
): UseMutationResult<
  PaymentMethodView,
  Error,
  {
    data: AttachPaymentMethodView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: AttachPaymentMethodView }) => {
    const result = await api.postPaymentAccountPaymentMethod(data);
    return result.data;
  }, options);
}

export function usePostCampaignMutation(
  options?: UseMutationOptions<
    CampaignResponseView,
    Error,
    {
      data: CampaignCreateRequestView;
    },
    unknown
  >
): UseMutationResult<
  CampaignResponseView,
  Error,
  {
    data: CampaignCreateRequestView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: CampaignCreateRequestView }) => {
    const result = await api.postCampaign(data);
    return result.data;
  }, options);
}

export function useDeleteCampaignMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: CampaignMultipleDeleteView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: CampaignMultipleDeleteView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: CampaignMultipleDeleteView }) => {
    const result = await api.deleteCampaign(data);
    return result.data;
  }, options);
}

export function usePostCampaignSenderDetailsEmailConfirmTokenByCampaignIdAndSenderDetailsIdMutation(
  options?: UseMutationOptions<
    string,
    Error,
    {
      campaignId: string;

      senderDetailsId: string;
    },
    unknown
  >
): UseMutationResult<
  string,
  Error,
  {
    campaignId: string;

    senderDetailsId: string;
  },
  unknown
> {
  return useMutation(
    async ({
      campaignId,
      senderDetailsId,
    }: {
      campaignId: string;

      senderDetailsId: string;
    }) => {
      const result =
        await api.postCampaignSenderDetailsEmailConfirmTokenByCampaignIdAndSenderDetailsId(
          campaignId,
          senderDetailsId
        );
      return result.data;
    },
    options
  );
}

export function usePostCampaignTemplateMutation(
  options?: UseMutationOptions<
    CampaignResponseView,
    Error,
    {
      data: CampaignCreateByTemplateRequestView;
    },
    unknown
  >
): UseMutationResult<
  CampaignResponseView,
  Error,
  {
    data: CampaignCreateByTemplateRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CampaignCreateByTemplateRequestView }) => {
      const result = await api.postCampaignTemplate(data);
      return result.data;
    },
    options
  );
}

export function usePostCampaignStatisticsTotalMutation(
  options?: UseMutationOptions<
    CampaignStatisticsTotalResponseView,
    Error,
    {
      data: CampaignStatisticsTotalRequestView;
    },
    unknown
  >
): UseMutationResult<
  CampaignStatisticsTotalResponseView,
  Error,
  {
    data: CampaignStatisticsTotalRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CampaignStatisticsTotalRequestView }) => {
      const result = await api.postCampaignStatisticsTotal(data);
      return result.data;
    },
    options
  );
}

export function usePostCampaignStatisticsGroupedMutation(
  options?: UseMutationOptions<
    CampaignStatisticsGroupedResponseView,
    Error,
    {
      data: CampaignStatisticsGroupedRequestView;
    },
    unknown
  >
): UseMutationResult<
  CampaignStatisticsGroupedResponseView,
  Error,
  {
    data: CampaignStatisticsGroupedRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CampaignStatisticsGroupedRequestView }) => {
      const result = await api.postCampaignStatisticsGrouped(data);
      return result.data;
    },
    options
  );
}

export function usePostCampaignCloneMutation(
  options?: UseMutationOptions<
    CampaignResponseView,
    Error,
    {
      data: CampaignCloneRequestView;
    },
    unknown
  >
): UseMutationResult<
  CampaignResponseView,
  Error,
  {
    data: CampaignCloneRequestView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: CampaignCloneRequestView }) => {
    const result = await api.postCampaignClone(data);
    return result.data;
  }, options);
}

export function usePostActivityMutation(
  options?: UseMutationOptions<
    ActivityIdentityView,
    Error,
    {
      data: ActivityDetailsView;
    },
    unknown
  >
): UseMutationResult<
  ActivityIdentityView,
  Error,
  {
    data: ActivityDetailsView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: ActivityDetailsView }) => {
    const result = await api.postActivity(data);
    return result.data;
  }, options);
}

export function useDeleteActivityMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: ActivityMultipleDeleteView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: ActivityMultipleDeleteView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: ActivityMultipleDeleteView }) => {
    const result = await api.deleteActivity(data);
    return result.data;
  }, options);
}

export function usePostAccountAccountIntegrationByAccountIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      accountId: string;

      data: AccountIntegrationView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    accountId: string;

    data: AccountIntegrationView;
  },
  unknown
> {
  return useMutation(
    async ({
      accountId,
      data,
    }: {
      accountId: string;
      data: AccountIntegrationView;
    }) => {
      const result = await api.postAccountAccountIntegrationByAccountId(
        accountId,
        data
      );
      return result.data;
    },
    options
  );
}

export function useDeletePaymentAccountPaymentMethodByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.deletePaymentAccountPaymentMethodById(id);
    return result.data;
  }, options);
}

export function useDeleteCampaignActivitiesEmailMessageByCampaignIdAndEmailMessageIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      campaignId: string;

      emailMessageId: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    campaignId: string;

    emailMessageId: string;
  },
  unknown
> {
  return useMutation(
    async ({
      campaignId,
      emailMessageId,
    }: {
      campaignId: string;

      emailMessageId: string;
    }) => {
      const result =
        await api.deleteCampaignActivitiesEmailMessageByCampaignIdAndEmailMessageId(
          campaignId,
          emailMessageId
        );
      return result.data;
    },
    options
  );
}

export function useDeleteCampaignSegmentMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: SegmentIdentityView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: SegmentIdentityView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: SegmentIdentityView }) => {
    const result = await api.deleteCampaignSegment(data);
    return result.data;
  }, options);
}

export function useDeleteCampaignSegmentsMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: IdsView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: IdsView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: IdsView }) => {
    const result = await api.deleteCampaignSegments(data);
    return result.data;
  }, options);
}

export function useDeleteAccountIntegrationByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.deleteAccountIntegrationById(id);
    return result.data;
  }, options);
}

export function usePutSegmentBySegmentIdMutation(
  options?: UseMutationOptions<
    SegmentIdentityView1,
    Error,
    {
      segmentId: string;

      data: SegmentUpdateView;
    },
    unknown
  >
): UseMutationResult<
  SegmentIdentityView1,
  Error,
  {
    segmentId: string;

    data: SegmentUpdateView;
  },
  unknown
> {
  return useMutation(
    async ({
      segmentId,
      data,
    }: {
      segmentId: string;
      data: SegmentUpdateView;
    }) => {
      const result = await api.putSegmentBySegmentId(segmentId, data);
      return result.data;
    },
    options
  );
}

export function useDeleteSegmentBySegmentIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      segmentId: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    segmentId: string;
  },
  unknown
> {
  return useMutation(async ({ segmentId }: { segmentId: string }) => {
    const result = await api.deleteSegmentBySegmentId(segmentId);
    return result.data;
  }, options);
}

export function usePutSegmentCustomersBySegmentIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      segmentId: string;

      data: CustomersUpdateView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    segmentId: string;

    data: CustomersUpdateView;
  },
  unknown
> {
  return useMutation(
    async ({
      segmentId,
      data,
    }: {
      segmentId: string;
      data: CustomersUpdateView;
    }) => {
      const result = await api.putSegmentCustomersBySegmentId(segmentId, data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentCustomersBySegmentIdMutation(
  options?: UseMutationOptions<
    SegmentIdentityView1,
    Error,
    {
      segmentId: string;

      data: CustomerBatchView;
    },
    unknown
  >
): UseMutationResult<
  SegmentIdentityView1,
  Error,
  {
    segmentId: string;

    data: CustomerBatchView;
  },
  unknown
> {
  return useMutation(
    async ({
      segmentId,
      data,
    }: {
      segmentId: string;
      data: CustomerBatchView;
    }) => {
      const result = await api.postSegmentCustomersBySegmentId(segmentId, data);
      return result.data;
    },
    options
  );
}

export function usePutSegmentStatisticByTypeMutation(
  options?: UseMutationOptions<
    SegmentTypeStatisticResponseView,
    Error,
    {
      data: SegmentTypeStatisticRequestView;
    },
    unknown
  >
): UseMutationResult<
  SegmentTypeStatisticResponseView,
  Error,
  {
    data: SegmentTypeStatisticRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: SegmentTypeStatisticRequestView }) => {
      const result = await api.putSegmentStatisticByType(data);
      return result.data;
    },
    options
  );
}

export function usePutSegmentFindIdsMutation(
  options?: UseMutationOptions<
    SegmentIdentitiesView1,
    Error,
    {
      data: IdsView;
    },
    unknown
  >
): UseMutationResult<
  SegmentIdentitiesView1,
  Error,
  {
    data: IdsView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: IdsView }) => {
    const result = await api.putSegmentFindIds(data);
    return result.data;
  }, options);
}

export function usePutSegmentFindFilterMutation(
  options?: UseMutationOptions<
    SegmentPageSegmentView,
    Error,
    {
      data: SegmentFilterView;

      query: {
        page?: number;
        size?: number;
        sort?: string[];
        countAllCustomers?: boolean;
      };
    },
    unknown
  >
): UseMutationResult<
  SegmentPageSegmentView,
  Error,
  {
    data: SegmentFilterView;

    query: {
      page?: number;
      size?: number;
      sort?: string[];
      countAllCustomers?: boolean;
    };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: SegmentFilterView;
      query: {
        page?: number;
        size?: number;
        sort?: string[];
        countAllCustomers?: boolean;
      };
    }) => {
      const result = await api.putSegmentFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutSegmentCustomSummaryMutation(
  options?: UseMutationOptions<
    CustomSegmentSummaryResponseView,
    Error,
    {
      data: CustomSegmentSummaryRequestView;
    },
    unknown
  >
): UseMutationResult<
  CustomSegmentSummaryResponseView,
  Error,
  {
    data: CustomSegmentSummaryRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CustomSegmentSummaryRequestView }) => {
      const result = await api.putSegmentCustomSummary(data);
      return result.data;
    },
    options
  );
}

export function usePutCustomerByCustomerIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      customerId: string;

      data: CustomerView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    customerId: string;

    data: CustomerView;
  },
  unknown
> {
  return useMutation(
    async ({
      customerId,
      data,
    }: {
      customerId: string;
      data: CustomerView;
    }) => {
      const result = await api.putCustomerByCustomerId(customerId, data);
      return result.data;
    },
    options
  );
}

export function usePutCustomerStatusByCustomerIdAndCustomerStatusMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      customerId: string;

      customerStatus: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    customerId: string;

    customerStatus: string;
  },
  unknown
> {
  return useMutation(
    async ({
      customerId,
      customerStatus,
    }: {
      customerId: string;

      customerStatus: string;
    }) => {
      const result = await api.putCustomerStatusByCustomerIdAndCustomerStatus(
        customerId,
        customerStatus
      );
      return result.data;
    },
    options
  );
}

export function usePutCustomerFilterMutation(
  options?: UseMutationOptions<
    PageCustomerInfoView,
    Error,
    {
      data: CustomerFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageCustomerInfoView,
  Error,
  {
    data: CustomerFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: CustomerFilterView;
      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putCustomerFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutCustomerFilterExportMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: CustomerFilterView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: CustomerFilterView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: CustomerFilterView }) => {
    const result = await api.putCustomerFilterExport(data);
    return result.data;
  }, options);
}

export function usePutCustomerCompositeFilterMutation(
  options?: UseMutationOptions<
    PageCustomerInfoView,
    Error,
    {
      data: CustomerCompositeFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageCustomerInfoView,
  Error,
  {
    data: CustomerCompositeFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: CustomerCompositeFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putCustomerCompositeFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutCustomerActionPlatformInfoFindFilterMutation(
  options?: UseMutationOptions<
    CustomerActionPlatformInfoView[],
    Error,
    {
      data: CustomerActionPlatformFindFilterView;
    },
    unknown
  >
): UseMutationResult<
  CustomerActionPlatformInfoView[],
  Error,
  {
    data: CustomerActionPlatformFindFilterView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CustomerActionPlatformFindFilterView }) => {
      const result = await api.putCustomerActionPlatformInfoFindFilter(data);
      return result.data;
    },
    options
  );
}

export function usePutCustomerActionGeolocationInfoFindFilterMutation(
  options?: UseMutationOptions<
    CustomerActionGeolocationInfoView[],
    Error,
    {
      data: CustomerActionGeolocationFindFilterView;
    },
    unknown
  >
): UseMutationResult<
  CustomerActionGeolocationInfoView[],
  Error,
  {
    data: CustomerActionGeolocationFindFilterView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CustomerActionGeolocationFindFilterView }) => {
      const result = await api.putCustomerActionGeolocationInfoFindFilter(data);
      return result.data;
    },
    options
  );
}

export function usePutCustomerActionDomainInfoFindFilterMutation(
  options?: UseMutationOptions<
    CustomerActionDomainInfoView[],
    Error,
    {
      data: CustomerActionDomainFindFilterView;
    },
    unknown
  >
): UseMutationResult<
  CustomerActionDomainInfoView[],
  Error,
  {
    data: CustomerActionDomainFindFilterView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CustomerActionDomainFindFilterView }) => {
      const result = await api.putCustomerActionDomainInfoFindFilter(data);
      return result.data;
    },
    options
  );
}

export function usePutAccountSegmentCustomerFilterByAccountIdMutation(
  options?: UseMutationOptions<
    PageCustomerSegmentInfoView,
    Error,
    {
      accountId: string;

      data: CustomerSegmentFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageCustomerSegmentInfoView,
  Error,
  {
    accountId: string;

    data: CustomerSegmentFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      accountId,
      data,
      query,
    }: {
      accountId: string;
      data: CustomerSegmentFilterView;
      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putAccountSegmentCustomerFilterByAccountId(
        accountId,
        data,
        query
      );
      return result.data;
    },
    options
  );
}

export function usePutAccountCustomerFilterByAccountIdMutation(
  options?: UseMutationOptions<
    PageCustomerInfoView,
    Error,
    {
      accountId: string;

      data: CustomerFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageCustomerInfoView,
  Error,
  {
    accountId: string;

    data: CustomerFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      accountId,
      data,
      query,
    }: {
      accountId: string;
      data: CustomerFilterView;
      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putAccountCustomerFilterByAccountId(
        accountId,
        data,
        query
      );
      return result.data;
    },
    options
  );
}

export function usePostSegmentCustomerBySegmentIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      segmentId: string;

      data: CustomerView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    segmentId: string;

    data: CustomerView;
  },
  unknown
> {
  return useMutation(
    async ({ segmentId, data }: { segmentId: string; data: CustomerView }) => {
      const result = await api.postSegmentCustomerBySegmentId(segmentId, data);
      return result.data;
    },
    options
  );
}

export function useDeleteSegmentCustomerBySegmentIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      segmentId: string;

      data: SegmentUnassignCustomersView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    segmentId: string;

    data: SegmentUnassignCustomersView;
  },
  unknown
> {
  return useMutation(
    async ({
      segmentId,
      data,
    }: {
      segmentId: string;
      data: SegmentUnassignCustomersView;
    }) => {
      const result = await api.deleteSegmentCustomerBySegmentId(
        segmentId,
        data
      );
      return result.data;
    },
    options
  );
}

export function usePostSegmentUploadMutation(
  options?: UseMutationOptions<
    SegmentIdentityView1,
    Error,
    {
      data: UploadSegmentCreateView;
    },
    unknown
  >
): UseMutationResult<
  SegmentIdentityView1,
  Error,
  {
    data: UploadSegmentCreateView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: UploadSegmentCreateView }) => {
    const result = await api.postSegmentUpload(data);
    return result.data;
  }, options);
}

export function usePostSegmentUploadCustomerMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: CustomerView;

      query: { segmentName: string };
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: CustomerView;

    query: { segmentName: string };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: CustomerView;
      query: { segmentName: string };
    }) => {
      const result = await api.postSegmentUploadCustomer(data, query);
      return result.data;
    },
    options
  );
}

export function usePostSegmentStatisticsTotalMutation(
  options?: UseMutationOptions<
    SegmentTotalStatisticsResponseView,
    Error,
    void,
    unknown
  >
): UseMutationResult<SegmentTotalStatisticsResponseView, Error, void, unknown> {
  return useMutation(async () => {
    const result = await api.postSegmentStatisticsTotal();
    return result.data;
  }, options);
}

export function usePostSegmentStatisticsTotalCustomersMutation(
  options?: UseMutationOptions<
    SegmentTotalCustomersStatisticsResponseView,
    Error,
    {
      data: SegmentTotalCustomersStatisticsRequestView;
    },
    unknown
  >
): UseMutationResult<
  SegmentTotalCustomersStatisticsResponseView,
  Error,
  {
    data: SegmentTotalCustomersStatisticsRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: SegmentTotalCustomersStatisticsRequestView }) => {
      const result = await api.postSegmentStatisticsTotalCustomers(data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentIntegrationStatisticsGrowthMutation(
  options?: UseMutationOptions<
    IntegrationSegmentStatisticsGrowthResponseView,
    Error,
    {
      data: IntegrationSegmentStatisticsGrowthRequestView;
    },
    unknown
  >
): UseMutationResult<
  IntegrationSegmentStatisticsGrowthResponseView,
  Error,
  {
    data: IntegrationSegmentStatisticsGrowthRequestView;
  },
  unknown
> {
  return useMutation(
    async ({
      data,
    }: {
      data: IntegrationSegmentStatisticsGrowthRequestView;
    }) => {
      const result = await api.postSegmentIntegrationStatisticsGrowth(data);
      return result.data;
    },
    options
  );
}

export function usePostSegmentImportMutation(
  options?: UseMutationOptions<
    SegmentIdentityView1,
    Error,
    {
      data: SegmentImportView;
    },
    unknown
  >
): UseMutationResult<
  SegmentIdentityView1,
  Error,
  {
    data: SegmentImportView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: SegmentImportView }) => {
    const result = await api.postSegmentImport(data);
    return result.data;
  }, options);
}

export function usePostSegmentCustomMutation(
  options?: UseMutationOptions<
    SegmentIdentityView1,
    Error,
    {
      data: CustomSegmentCreateView;
    },
    unknown
  >
): UseMutationResult<
  SegmentIdentityView1,
  Error,
  {
    data: CustomSegmentCreateView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: CustomSegmentCreateView }) => {
    const result = await api.postSegmentCustom(data);
    return result.data;
  }, options);
}

export function usePostSegmentCloneMutation(
  options?: UseMutationOptions<
    SegmentIdentityView1,
    Error,
    {
      data: SegmentCloneRequestView;
    },
    unknown
  >
): UseMutationResult<
  SegmentIdentityView1,
  Error,
  {
    data: SegmentCloneRequestView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: SegmentCloneRequestView }) => {
    const result = await api.postSegmentClone(data);
    return result.data;
  }, options);
}

export function usePostCustomerStatisticsTotalMutation(
  options?: UseMutationOptions<
    CustomerStatisticsTotalResponseView,
    Error,
    {
      data: CustomerStatisticsTotalRequestView;
    },
    unknown
  >
): UseMutationResult<
  CustomerStatisticsTotalResponseView,
  Error,
  {
    data: CustomerStatisticsTotalRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CustomerStatisticsTotalRequestView }) => {
      const result = await api.postCustomerStatisticsTotal(data);
      return result.data;
    },
    options
  );
}

export function usePostCustomerStatisticsGroupedMutation(
  options?: UseMutationOptions<
    CustomerStatisticsGroupedResponseView,
    Error,
    {
      data: CustomerStatisticsGroupedRequestView;
    },
    unknown
  >
): UseMutationResult<
  CustomerStatisticsGroupedResponseView,
  Error,
  {
    data: CustomerStatisticsGroupedRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CustomerStatisticsGroupedRequestView }) => {
      const result = await api.postCustomerStatisticsGrouped(data);
      return result.data;
    },
    options
  );
}

export function usePostCustomerImportMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: CustomerBatchView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: CustomerBatchView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: CustomerBatchView }) => {
    const result = await api.postCustomerImport(data);
    return result.data;
  }, options);
}

export function usePostCustomerEventStatisticsGroupedMutation(
  options?: UseMutationOptions<
    CustomerEventStatisticsGroupedResponseView,
    Error,
    {
      data: CustomerEventStatisticsGroupedRequestView;
    },
    unknown
  >
): UseMutationResult<
  CustomerEventStatisticsGroupedResponseView,
  Error,
  {
    data: CustomerEventStatisticsGroupedRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: CustomerEventStatisticsGroupedRequestView }) => {
      const result = await api.postCustomerEventStatisticsGrouped(data);
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsTotalByCampaignIdMutation(
  options?: UseMutationOptions<
    CustomerEventStatisticsTotalResponseView,
    Error,
    {
      campaignId: string;

      data: CustomerEventStatisticsTotalRequestView;
    },
    unknown
  >
): UseMutationResult<
  CustomerEventStatisticsTotalResponseView,
  Error,
  {
    campaignId: string;

    data: CustomerEventStatisticsTotalRequestView;
  },
  unknown
> {
  return useMutation(
    async ({
      campaignId,
      data,
    }: {
      campaignId: string;
      data: CustomerEventStatisticsTotalRequestView;
    }) => {
      const result =
        await api.postCustomerEventCampaignStatisticsTotalByCampaignId(
          campaignId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsMostOpenByCampaignIdMutation(
  options?: UseMutationOptions<
    CustomerEventMostOpenStatisticsResponseView,
    Error,
    {
      campaignId: string;

      data: CustomerEventMostOpenStatisticsFilterView;

      query: { offset?: number; limit?: number };
    },
    unknown
  >
): UseMutationResult<
  CustomerEventMostOpenStatisticsResponseView,
  Error,
  {
    campaignId: string;

    data: CustomerEventMostOpenStatisticsFilterView;

    query: { offset?: number; limit?: number };
  },
  unknown
> {
  return useMutation(
    async ({
      campaignId,
      data,
      query,
    }: {
      campaignId: string;
      data: CustomerEventMostOpenStatisticsFilterView;
      query: { offset?: number; limit?: number };
    }) => {
      const result =
        await api.postCustomerEventCampaignStatisticsMostOpenByCampaignId(
          campaignId,
          data,
          query
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsMostOpenExportByCampaignIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      campaignId: string;

      data: CustomerEventMostOpenStatisticsFilterView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    campaignId: string;

    data: CustomerEventMostOpenStatisticsFilterView;
  },
  unknown
> {
  return useMutation(
    async ({
      campaignId,
      data,
    }: {
      campaignId: string;
      data: CustomerEventMostOpenStatisticsFilterView;
    }) => {
      const result =
        await api.postCustomerEventCampaignStatisticsMostOpenExportByCampaignId(
          campaignId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsMostClickByCampaignIdMutation(
  options?: UseMutationOptions<
    CustomerEventMostClickStatisticsResponseView,
    Error,
    {
      campaignId: string;

      data: CustomerEventMostClickStatisticsFilterView;

      query: { offset?: number; limit?: number };
    },
    unknown
  >
): UseMutationResult<
  CustomerEventMostClickStatisticsResponseView,
  Error,
  {
    campaignId: string;

    data: CustomerEventMostClickStatisticsFilterView;

    query: { offset?: number; limit?: number };
  },
  unknown
> {
  return useMutation(
    async ({
      campaignId,
      data,
      query,
    }: {
      campaignId: string;
      data: CustomerEventMostClickStatisticsFilterView;
      query: { offset?: number; limit?: number };
    }) => {
      const result =
        await api.postCustomerEventCampaignStatisticsMostClickByCampaignId(
          campaignId,
          data,
          query
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsMostClickExportByCampaignIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      campaignId: string;

      data: CustomerEventMostClickStatisticsFilterView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    campaignId: string;

    data: CustomerEventMostClickStatisticsFilterView;
  },
  unknown
> {
  return useMutation(
    async ({
      campaignId,
      data,
    }: {
      campaignId: string;
      data: CustomerEventMostClickStatisticsFilterView;
    }) => {
      const result =
        await api.postCustomerEventCampaignStatisticsMostClickExportByCampaignId(
          campaignId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsGroupedByCampaignIdMutation(
  options?: UseMutationOptions<
    CustomerEventStatisticsGroupedResponseView,
    Error,
    {
      campaignId: string;

      data: CustomerEventStatisticsGroupedRequestView;
    },
    unknown
  >
): UseMutationResult<
  CustomerEventStatisticsGroupedResponseView,
  Error,
  {
    campaignId: string;

    data: CustomerEventStatisticsGroupedRequestView;
  },
  unknown
> {
  return useMutation(
    async ({
      campaignId,
      data,
    }: {
      campaignId: string;
      data: CustomerEventStatisticsGroupedRequestView;
    }) => {
      const result =
        await api.postCustomerEventCampaignStatisticsGroupedByCampaignId(
          campaignId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostCustomerEventCampaignStatisticsExportByCampaignIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      campaignId: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    campaignId: string;
  },
  unknown
> {
  return useMutation(async ({ campaignId }: { campaignId: string }) => {
    const result =
      await api.postCustomerEventCampaignStatisticsExportByCampaignId(
        campaignId
      );
    return result.data;
  }, options);
}

export function usePostAccountSegmentCustomersByAccountIdAndSegmentIdMutation(
  options?: UseMutationOptions<
    SegmentIdentityView1,
    Error,
    {
      accountId: string;

      segmentId: string;

      data: CustomerBatchView;
    },
    unknown
  >
): UseMutationResult<
  SegmentIdentityView1,
  Error,
  {
    accountId: string;

    segmentId: string;

    data: CustomerBatchView;
  },
  unknown
> {
  return useMutation(
    async ({
      accountId,
      segmentId,
      data,
    }: {
      accountId: string;

      segmentId: string;

      data: CustomerBatchView;
    }) => {
      const result =
        await api.postAccountSegmentCustomersByAccountIdAndSegmentId(
          accountId,
          segmentId,
          data
        );
      return result.data;
    },
    options
  );
}

export function usePostAccountSegmentImportByAccountIdMutation(
  options?: UseMutationOptions<
    SegmentIdentityView1,
    Error,
    {
      accountId: string;

      data: SegmentImportView;
    },
    unknown
  >
): UseMutationResult<
  SegmentIdentityView1,
  Error,
  {
    accountId: string;

    data: SegmentImportView;
  },
  unknown
> {
  return useMutation(
    async ({
      accountId,
      data,
    }: {
      accountId: string;
      data: SegmentImportView;
    }) => {
      const result = await api.postAccountSegmentImportByAccountId(
        accountId,
        data
      );
      return result.data;
    },
    options
  );
}

export function useDeleteSegmentMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: SegmentMultipleDeleteView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: SegmentMultipleDeleteView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: SegmentMultipleDeleteView }) => {
    const result = await api.deleteSegment(data);
    return result.data;
  }, options);
}

export function useDeleteSegmentCustomerBySegmentIdAndCustomerIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      segmentId: string;

      customerId: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    segmentId: string;

    customerId: string;
  },
  unknown
> {
  return useMutation(
    async ({
      segmentId,
      customerId,
    }: {
      segmentId: string;
      customerId: string;
    }) => {
      const result = await api.deleteSegmentCustomerBySegmentIdAndCustomerId(
        segmentId,
        customerId
      );
      return result.data;
    },
    options
  );
}

export function useDeleteCustomerMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: IdsView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: IdsView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: IdsView }) => {
    const result = await api.deleteCustomer(data);
    return result.data;
  }, options);
}

export function useDeleteCustomerByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.deleteCustomerById(id);
    return result.data;
  }, options);
}

export function usePutContentSubjectFilterMutation(
  options?: UseMutationOptions<
    PageSubjectLineView,
    Error,
    {
      data: SubjectLineFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageSubjectLineView,
  Error,
  {
    data: SubjectLineFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: SubjectLineFilterView;
      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putContentSubjectFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutContentSubjectFavoriteByIdAndIsFavoriteMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      isFavorite: boolean;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    isFavorite: boolean;
  },
  unknown
> {
  return useMutation(
    async ({ id, isFavorite }: { id: string; isFavorite: boolean }) => {
      const result = await api.putContentSubjectFavoriteByIdAndIsFavorite(
        id,
        isFavorite
      );
      return result.data;
    },
    options
  );
}

export function usePostContentSubjectGenerateMutation(
  options?: UseMutationOptions<
    SubjectGenerateResponseView,
    Error,
    {
      data: SubjectGenerateRequestView;
    },
    unknown
  >
): UseMutationResult<
  SubjectGenerateResponseView,
  Error,
  {
    data: SubjectGenerateRequestView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: SubjectGenerateRequestView }) => {
    const result = await api.postContentSubjectGenerate(data);
    return result.data;
  }, options);
}

export function usePutFormByIdMutation(
  options?: UseMutationOptions<
    FormUpdateResponseView,
    Error,
    {
      id: string;

      data: FormUpdateRequestView;
    },
    unknown
  >
): UseMutationResult<
  FormUpdateResponseView,
  Error,
  {
    id: string;

    data: FormUpdateRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: FormUpdateRequestView }) => {
      const result = await api.putFormById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutFormStatusByIdAndFormStatusMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      formStatus: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    formStatus: string;
  },
  unknown
> {
  return useMutation(
    async ({ id, formStatus }: { id: string; formStatus: string }) => {
      const result = await api.putFormStatusByIdAndFormStatus(id, formStatus);
      return result.data;
    },
    options
  );
}

export function usePutFormFilterMutation(
  options?: UseMutationOptions<
    PageFormInfoView,
    Error,
    {
      data: FormFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageFormInfoView,
  Error,
  {
    data: FormFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: FormFilterView;
      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putFormFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutFormConfigMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: FormConfigView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: FormConfigView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: FormConfigView }) => {
    const result = await api.putFormConfig(data);
    return result.data;
  }, options);
}

export function usePutFormConfigContentByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      data: FormContentView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    data: FormContentView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: FormContentView }) => {
      const result = await api.putFormConfigContentById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutFormConfigAppearanceByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      data: FormAppearanceView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    data: FormAppearanceView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: FormAppearanceView }) => {
      const result = await api.putFormConfigAppearanceById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutFormConfigAllowedDomainsByIdMutation(
  options?: UseMutationOptions<
    FormAllowedDomainsView,
    Error,
    {
      id: string;

      data: FormAllowedDomainsView;
    },
    unknown
  >
): UseMutationResult<
  FormAllowedDomainsView,
  Error,
  {
    id: string;

    data: FormAllowedDomainsView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: FormAllowedDomainsView }) => {
      const result = await api.putFormConfigAllowedDomainsById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutFormCheckAssignedAudiencesMutation(
  options?: UseMutationOptions<
    FormCheckAssignedAudiencesResponseView,
    Error,
    {
      data: FormCheckAssignedAudiencesRequestView;
    },
    unknown
  >
): UseMutationResult<
  FormCheckAssignedAudiencesResponseView,
  Error,
  {
    data: FormCheckAssignedAudiencesRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: FormCheckAssignedAudiencesRequestView }) => {
      const result = await api.putFormCheckAssignedAudiences(data);
      return result.data;
    },
    options
  );
}

export function usePutFormTemplateFavoriteByIdAndIsFavoriteMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      isFavorite: boolean;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    isFavorite: boolean;
  },
  unknown
> {
  return useMutation(
    async ({ id, isFavorite }: { id: string; isFavorite: boolean }) => {
      const result = await api.putFormTemplateFavoriteByIdAndIsFavorite(
        id,
        isFavorite
      );
      return result.data;
    },
    options
  );
}

export function usePutFormTemplateFilterMutation(
  options?: UseMutationOptions<
    PageFormTemplateInfoView,
    Error,
    {
      data: FormTemplateFilterView;

      query: { page?: number; size?: number; sort?: string[] };
    },
    unknown
  >
): UseMutationResult<
  PageFormTemplateInfoView,
  Error,
  {
    data: FormTemplateFilterView;

    query: { page?: number; size?: number; sort?: string[] };
  },
  unknown
> {
  return useMutation(
    async ({
      data,
      query,
    }: {
      data: FormTemplateFilterView;
      query: { page?: number; size?: number; sort?: string[] };
    }) => {
      const result = await api.putFormTemplateFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePostFormMutation(
  options?: UseMutationOptions<
    FormIdentityView,
    Error,
    {
      data: FormView;
    },
    unknown
  >
): UseMutationResult<
  FormIdentityView,
  Error,
  {
    data: FormView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: FormView }) => {
    const result = await api.postForm(data);
    return result.data;
  }, options);
}

export function useDeleteFormMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: FormDeleteRequestView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: FormDeleteRequestView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: FormDeleteRequestView }) => {
    const result = await api.deleteForm(data);
    return result.data;
  }, options);
}

export function usePostFormCloneMutation(
  options?: UseMutationOptions<
    FormCloneResponseView,
    Error,
    {
      data: FormCloneRequestView;
    },
    unknown
  >
): UseMutationResult<
  FormCloneResponseView,
  Error,
  {
    data: FormCloneRequestView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: FormCloneRequestView }) => {
    const result = await api.postFormClone(data);
    return result.data;
  }, options);
}

export function usePostFormByTemplateMutation(
  options?: UseMutationOptions<
    FormIdentityView,
    Error,
    {
      data: FormWithTemplateView;
    },
    unknown
  >
): UseMutationResult<
  FormIdentityView,
  Error,
  {
    data: FormWithTemplateView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: FormWithTemplateView }) => {
    const result = await api.postFormByTemplate(data);
    return result.data;
  }, options);
}

export function usePostFormProviderByAccountIdAndFormIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      accountId: string;

      formId: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    accountId: string;

    formId: string;
  },
  unknown
> {
  return useMutation(
    async ({ accountId, formId }: { accountId: string; formId: string }) => {
      const result = await api.postFormProviderByAccountIdAndFormId(
        accountId,
        formId
      );
      return result.data;
    },
    options
  );
}

export function usePutAutomationEnabledByAutomationIdAndIsEnabledMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      automationId: string;

      isEnabled: boolean;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    automationId: string;

    isEnabled: boolean;
  },
  unknown
> {
  return useMutation(
    async ({
      automationId,
      isEnabled,
    }: {
      automationId: string;

      isEnabled: boolean;
    }) => {
      const result = await api.putAutomationEnabledByAutomationIdAndIsEnabled(
        automationId,
        isEnabled
      );
      return result.data;
    },
    options
  );
}
