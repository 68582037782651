import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M14 4L14 12.101C13.4259 11.5151 12.7496 11.0297 12 10.6736V4C12 2.89543 12.8954 2 14 2H18C19.1046 2 20 2.89543 20 4V20C20 21.1046 19.1046 22 18 22H14C13.9669 22 13.9341 21.9992 13.9014 21.9976C14.4862 21.424 14.9708 20.7486 15.3264 20H18L18 4L14 4Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M14 17C14 19.7614 11.7614 22 9 22C6.23858 22 4 19.7614 4 17C4 14.2386 6.23858 12 9 12C11.7614 12 14 14.2386 14 17ZM10 20V18H12V16H10V14H8L8 16H6V18H8L8 20H10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "AddColumn"
);
