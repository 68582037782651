import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import type { GetSenderDetailsResponse } from "@/types/api/senderDetails";
import type { GetAccountSubscriptionResponse } from "@/types/api/accountSubscription";
import { GetNotificationResponse } from "@/types/api/notifications";
import { DomainOption } from "../AccountSettings/DKIM/types";

export async function mainLayoutLoader({ request }: LoaderFunctionArgs) {
  const { signal } = request;

  const [planInfo, senderDetails, notifications] = await Promise.all([
    fetchApi<GetAccountSubscriptionResponse>(
      "/accountservice/account-subscription",
      { signal }
    ),
    fetchApi<GetSenderDetailsResponse>(
      "/accountservice/sender-details/default",
      { signal }
    ),
    fetchApi<GetNotificationResponse>(
      "/accountservice/account/issue/unresolved",
      { signal }
    ),
  ]);

  const domain = senderDetails?.email?.split("@")[1] || "";
  const dkimStatus = await fetchApi<DomainOption>(
    `/notificationservice/dkim/check?domain=${domain}`,
    { method: "GET", signal }
  );

  return defer({
    planInfo,
    notifications,
    senderDetails: {
      ...senderDetails,
      dkimSigned: dkimStatus.enabled ?? false,
    },
  });
}
