import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import {
  Outlet,
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { AutomationInfo } from "@/components/Automation/types";
import {
  metricCountMapper,
  metricMapper,
} from "@/components/MetricCard/metric.mapper";
import { GetReportOverviewResponse } from "@/types/api/reportsOverview";
import { useAutomationsRefetch } from "@/routes/Automations/useAutomationsRefetch";
import { useExportReports } from "@/hooks/useExportReports";
import RegenerateAll from "@/components/RegenerateAll";
import { GetAutomationsCountResponse } from "@/types/api/automations";
import { RootLoader } from "@/types/RootLoader";
import Automation from "../../components/Automation";
import PageTitle from "../../components/PageTitle";
import MetricCard from "../../components/MetricCard";

function Automations() {
  const { automations } = useLoaderData() as { automations: AutomationInfo[] };
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;
  const { automationsReports } = useLoaderData() as {
    automationsReports: GetReportOverviewResponse;
  };
  const { automationsEnabled } = useLoaderData() as {
    automationsEnabled: GetAutomationsCountResponse;
  };

  const exportReports = useExportReports();
  const navigate = useNavigate();

  const mappedAutomations = metricMapper(
    automationsReports,
    "automationsInfoMessages",
    accountInfo,
    () =>
      navigate("/main/dashboard/generated-revenue/sources?filter=AUTOMATION"),
    "automations-overview-see-reports"
  ).filter((name) => name.title !== "Total Revenue");

  const mappedActiveAutomations = metricCountMapper(
    automationsEnabled.count,
    "automationsInfoMessages"
  );

  useAutomationsRefetch(automations);

  return (
    <>
      <PageTitle title="Automations" sx={{ width: "100%" }} canRefresh />
      <Typography
        variant="h4"
        color="grey.100"
        m={0}
        mb={3}
        sx={{ width: "100%" }}
      >
        General statistics
      </Typography>
      <Grid
        container
        id="statistics-section"
        spacing={{ xs: 3, md: 5 }}
        columns={{ xs: 12, md: 12 }}
      >
        <Grid item xs={12} md={4} className="automations-metric">
          <MetricCard metric={mappedActiveAutomations} />
        </Grid>
        {mappedAutomations.map((metric) => (
          <Grid
            item
            xs={12}
            md={4}
            key={metric.id}
            className="automations-metric"
          >
            <MetricCard
              metric={metric}
              seeReportsHandler={() =>
                exportReports(["AUTOMATION"], [metric.id ?? ""])
              }
            />
          </Grid>
        ))}
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="inherit"
        sx={{
          m: 0,
          mt: 4,
          mb: 3,
        }}
      >
        <Typography variant="h4" color="grey.100">
          Available automations
        </Typography>
        <RegenerateAll
          method="post"
          action="/main/automations/overview"
          confirmationMessage="Do you want to retrain tinyAlbert with updated Automations? Doing this may recreate some or all of your
        templates"
          successMessage='You have successfully "Albertized" your automations.'
          id="automations-overview-regenerate-all"
        />
      </Box>

      <Grid
        container
        spacing={{ xs: 3, md: 5 }}
        columns={{ xs: 12, md: 12 }}
        id="automation-cards-section"
      >
        {automations.map((automation) => (
          <Grid item xs={12} md={4} key={automation.id}>
            <Automation {...automation} />
          </Grid>
        ))}
      </Grid>
      <Outlet />
    </>
  );
}
export default Automations;
export { automationsLoader } from "./loader";
