import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  getHours,
  getMinutes,
  getSeconds,
  getYear,
  isPast,
  isSameWeek,
  set,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";

export function convertDateRangeToFilter(filterValue: string): {
  from?: number;
  to?: number;
} {
  const todayTimeZero = createUtcTodayFirstTime();
  const to = createUtcTodayFirstTime().getTime();
  const lastWeek = subDays(todayTimeZero, 7).getTime();

  switch (filterValue) {
    case "LAST_7_DAYS": {
      return {
        from: lastWeek,
        to,
      };
    }
    case "LAST_30_DAYS": {
      return {
        from: subDays(todayTimeZero, 30).getTime(),
        to,
      };
    }
    case "YTD": {
      const year = getYear(todayTimeZero);
      return {
        from: new Date(year, 0, 1).getTime(),
        to,
      };
    }
    case "LAST_1_WEEK": {
      return {
        from: subWeeks(lastWeek, 1).getTime(),
        to: lastWeek,
      };
    }
    case "LAST_MONTH": {
      const lastMonthStart = subMonths(todayTimeZero, 1);
      const from = getUtcFirstTime(lastMonthStart).getTime();
      return {
        from,
        to: getUtcLastTime(endOfMonth(lastMonthStart)).getTime(),
      };
    }
    case "ALL_TIME": {
      return {
        from: undefined,
        to: undefined,
      };
    }
    default: {
      return {
        from: undefined,
        to: undefined,
      };
    }
  }
}

function getUtcFirstTime(date: Date): Date {
  const isoString = date.toISOString();
  return new Date(`${isoString.split("T")[0]}T00:00:00.000Z`);
}

function getUtcLastTime(date: Date): Date {
  const isoString = date.toISOString();
  return new Date(`${isoString.split("T")[0]}T23:59:59.000Z`);
}

function createUtcTodayFirstTime(): Date {
  return getUtcFirstTime(new Date());
}

export function formatDate(date: Date, pattern: string) {
  return format(date, pattern);
}

export function getUTCDate(date: Date): string {
  const iso = date.toISOString();
  const utc = iso.substring(0, iso.length);
  return utc;
}

export function getStartAndEndOfMonth(
  date: Date,
  futureOnly?: boolean
): {
  from: string;
  to: string;
} {
  const startOfMonthDate = startOfMonth(date);
  const endOfMonthDate = endOfMonth(date);

  let start = startOfWeek(startOfMonthDate);

  if (futureOnly && isPast(start)) {
    start = startOfDay(new Date());
  }

  const end = endOfWeek(endOfMonthDate);

  const isoStart = start.toISOString();
  const utcStart = isoStart.substring(0, isoStart.length);
  const isoEnd = end.toISOString();
  const utcEnd = isoEnd.substring(0, isoEnd.length);
  return {
    from: utcStart,
    to: utcEnd,
  };
}

export function combineDateTimeToUtcMilliseconds(
  dateObj: Date,
  timeObj: Date
): number {
  const combinedDateTime = set(dateObj, {
    hours: getHours(timeObj),
    minutes: getMinutes(timeObj),
    seconds: getSeconds(timeObj),
  });
  const utcMilliseconds = combinedDateTime.getTime();
  return utcMilliseconds;
}

export function isInFirstWeekOfMonth(monthDate: Date, date: Date): boolean {
  const firstWeekStart = startOfWeek(startOfMonth(monthDate));
  return isSameWeek(date, firstWeekStart);
}

export const getUtcMilliSecondsByDate = (date: Date) =>
  Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );

export function convertUTCTimeStringToLocalTime(time: String) {
  const utcDate = new Date(`1970-01-01T${time}Z`);
  let hours = utcDate.getHours();
  const minutes = utcDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const localTimeStr = `${hours}:${minutesStr} ${ampm}`;
  return localTimeStr;
}
