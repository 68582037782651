import React, { ForwardedRef } from "react";

import { FieldGroupProps } from "./FieldGroup.types";
import { FormHelperText } from "..";
import { Box } from "@mui/material";

const FieldGroup = React.forwardRef(function FieldGroup(
  props: FieldGroupProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    children,
    contentRef,
    error,
    helperText,
    prefixWidth = 48,
    prefixWidthUnit = "px",
    size,
    showHelperTextMargin,
    ...other
  } = props;

  const renderChildren = () => {
    const arr = React.Children.toArray(children);

    return arr.map((child: any, index: number) => {
      const flex =
        index === 0 ? `0 0 ${prefixWidth}${prefixWidthUnit}` : undefined;
      const field = React.cloneElement(child, { error, size });
      return (
        <Box
          key={index}
          sx={
            props.gap
              ? {}
              : {
                  flex: "1",
                  "&:not(:first-child) fieldset": {
                    left: -1,
                  },
                  "&:first-child fieldset": {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  "&:last-child fieldset": {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                  "& > div > div.Mui-disabled": {
                    borderLeft: 0,
                  },
                  "&:first-child > div > .Mui-disabled": {
                    borderLeft: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  "&:last-child > div > .Mui-disabled": {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                  "& > div > div > div.Mui-disabled": {
                    borderLeft: 0,
                  },
                  "&:first-child > div > div > .Mui-disabled": {
                    borderLeft: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  "&:last-child > div > div > .Mui-disabled": {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                }
          }
          style={{ flex }}
        >
          {field}
        </Box>
      );
    });
  };

  return (
    <Box
      {...other}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      ref={ref}
    >
      <Box
        sx={{
          display: "flex",
          gap: props.gap,
        }}
        ref={contentRef}
      >
        {renderChildren()}
      </Box>
      {helperText && (
        <FormHelperText error={error} variant="outlined">
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
});

export default FieldGroup;
