import { TokenModel } from "./types";

const ENVS = import.meta.env;
const isDevEnv = ENVS.DEV;

const authStorage = (() => {
  let tokens: TokenModel | undefined;
  let isInitialized = false;
  const updateTokens = (updatedTokens: TokenModel) => {
    if (isDevEnv) {
      localStorage.setItem("accessToken", updatedTokens.access_token);
      localStorage.setItem("refreshToken", updatedTokens.refresh_token);
    } else {
      tokens = { ...updatedTokens };
    }
    isInitialized = true;
  };

  const getAccessToken = () =>
    isDevEnv ? localStorage.getItem("accessToken") : tokens?.access_token;
  const getRefreshToken = () =>
    isDevEnv ? localStorage.getItem("refreshToken") : tokens?.refresh_token;
  return {
    isInitialized: () => isInitialized,
    getAccessToken,
    getRefreshToken,
    updateTokens,
  };
})();

export { authStorage };
