import React, { ReactElement } from "react";
import {
  Alert,
  AlertColor,
  Box,
  IconButton,
  Portal,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CircleCheck,
  CloseBig,
  InfoOutline,
  OffOutlineClose,
  Warning,
} from "@tiny/icons";
import { ToastContainerProps } from "./ToastService.types";

export const Toast = ({
  setOpen,
  type,
  open,
  heading,
  message,
  autoHideDuration,
  portalId,
}: ToastContainerProps) => {
  const theme = useTheme();
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const renderAlert = (alertType: string) => {
    const config: {
      [key: string]: {
        borderColor: string | undefined;
        backgroundColor: string | undefined;
        icon: ReactElement | null;
      };
    } = {
      success: {
        borderColor: theme.palette.green[75],
        backgroundColor: theme.palette.green[50],
        icon: <CircleCheck />,
      },
      warning: {
        borderColor: theme.palette.yellow[75],
        backgroundColor: theme.palette.yellow[50],
        icon: <Warning />,
      },
      error: {
        borderColor: theme.palette.red[75],
        backgroundColor: theme.palette.red[50],
        icon: <OffOutlineClose />,
      },
      info: {
        borderColor: "",
        backgroundColor: theme.palette.red[50],
        icon: <InfoOutline />,
      },
    };

    return (
      <Alert
        variant="outlined"
        severity={type as AlertColor}
        sx={{
          p: 0,
          borderColor: `${config[alertType]?.borderColor as string} !important`,
          backgroundColor: config[alertType]?.backgroundColor,
          "& .MuiAlert-icon": {
            p: 2,
            m: 2,
            background: config[alertType]?.borderColor,
            borderRadius: 8,
          },
          alignItems: "center",
          borderRadius: 2,
        }}
        icon={config[alertType]?.icon}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            sx={{ mr: 3 }}
            onClick={() => {
              setOpen(!open);
            }}
            id="alert-close-info-alert-msg"
          >
            <CloseBig
              sx={{
                fontSize: theme.spacing(5),
                color: "grey.70",
              }}
            />
          </IconButton>
        }
      >
        <Box display="flex" flexDirection="column">
          <Typography
            variant="label2"
            color="grey.80"
            sx={{ textTransform: "capitalize", lineHeight: 1.2 }}
          >
            {heading}
          </Typography>
          <Typography variant="label2R" color="gray.100" lineHeight={1.2}>
            {message}
          </Typography>
        </Box>
      </Alert>
    );
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      {portalId ? (
        <Box>
          <Portal container={document?.getElementById(portalId)}>
            {renderAlert(type)}
          </Portal>
        </Box>
      ) : (
        renderAlert(type)
      )}
    </Snackbar>
  );
};
