import React from "react";

import { Box, Divider, Grid } from "@mui/material";
import { EmptyState } from "../../../components";
import { Search } from "@tiny/icons";
import TemplateSection from "../TemplateSection";
import { DragAndDropTabProps } from "./DragAndDropTab.types";
import { useDragAndDropTab } from "./DragAndDropTab.hooks";
import { DragAndDropTabProvider } from "./DragAndDropTab.context";
import DragAndDropHeader from "./DragAndDropHeader";
import FilteredSection from "./FilteredSection";
import ComponentLoader from "../../ComponentLoader/ComponentLoader";
import useBreakpoints from "../../../hooks/useBreakpoints";

function DragAndDropTab(props: DragAndDropTabProps) {
  const dragAndDropView = useDragAndDropTab(props);

  const { mdUp } = useBreakpoints();

  const {
    basicTemplates,
    brandedTemplates,
    libraryTemplates,
    refetchSections,
    savedTemplates,
    showSelected,
    staffPicksTemplates,
    shouldShowSavedAndStaffPicks,
    shouldShowSaved,
    shouldShowBranded,
    shouldShowBasic,
    shouldShowLibrary,
    totalSaved,
    totalStaffPicks,
    totalBranded,
    totalBasic,
    totalLibrary,
    isEmpty,
    emptyStateMessage,
    isLastSaved,
    isLastStaffPicks,
    isLastBranded,
    isLastBasic,
    isLastLibrary,
    isLoading,
    isLoadingBasic,
    isLoadingBranded,
    isLoadingLibrary,
    isLoadingSaved,
    isLoadingStaffPicks,
    shouldShowFilteredSection,
    activeTitle,
    filteredTemplates,
    activeSaved,
    activeType,
    fetchMore,
    hasMore,
    totalFilteredTemplates,
    activeRenderWithEjs,
    showAll,
    isSelected,
    toggleSelect,
    selectedTemplate,
    selectable,
  } = dragAndDropView;

  const renderContent = () => {
    if (isEmpty) {
      return (
        <Box bgcolor="grey.50" pt={10} pb={10}>
          <EmptyState
            title="No templates found"
            message={emptyStateMessage}
            icon={<Search />}
            iconColor="primary"
            circleColor="white"
            align="center"
          />
        </Box>
      );
    }
    if (shouldShowFilteredSection) {
      return (
        <FilteredSection
          title={activeTitle}
          templates={filteredTemplates}
          saved={activeSaved}
          type={activeType}
          fetchMore={fetchMore}
          hasMore={hasMore}
          total={totalFilteredTemplates}
          renderWithEjs={activeRenderWithEjs}
          isSelected={isSelected}
          toggleSelect={toggleSelect}
        />
      );
    } else {
      return (
        <>
          {(isLoading || showSelected) && (
            <>
              <SectionDivider />
              <Box px={mdUp ? 6 : 0} pt={mdUp ? 1 : 0} pb={mdUp ? 6 : 0}>
                <TemplateSection
                  title="Currently selected"
                  showAll={showAll}
                  total={0}
                  favoritable={false}
                  hasMore={false}
                  templates={[selectedTemplate!]}
                  refetch={
                    !!refetchSections?.find((s) => s === "Currently selected")
                  }
                  selectable={false}
                  isSelected={isSelected}
                  toggleSelect={toggleSelect}
                  isCurrentSelected
                  loading={isLoading}
                />
              </Box>
            </>
          )}
          {(isLoadingSaved ||
            isLoadingStaffPicks ||
            shouldShowSavedAndStaffPicks) && (
            <>
              <SectionDivider />
              <Box p={mdUp ? 6 : 0}>
                <Grid container spacing={mdUp ? 4 : 0}>
                  {isLoadingSaved || shouldShowSaved ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <TemplateSection
                          title="Saved"
                          showAll={showAll}
                          total={totalSaved}
                          hasMore={!isLastSaved}
                          saved
                          templates={savedTemplates}
                          columnSize={4}
                          refetch={
                            !!refetchSections?.find((s) => s === "Saved")
                          }
                          selectable={selectable}
                          isSelected={isSelected}
                          toggleSelect={toggleSelect}
                          loading={isLoadingSaved}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {!mdUp && <SectionDivider />}
                        <TemplateSection
                          title={"Staff picks"}
                          showAll={showAll}
                          total={totalStaffPicks}
                          hasMore={!isLastStaffPicks}
                          templates={staffPicksTemplates}
                          columnSize={4}
                          type="STAFF_PICKED"
                          refetch={
                            !!refetchSections?.find((s) => s === "Staff picks")
                          }
                          isSelected={isSelected}
                          toggleSelect={toggleSelect}
                          selectable={selectable}
                          selectDisabled
                          loading={isLoadingStaffPicks}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} md={12}>
                      <TemplateSection
                        title={"Staff picks"}
                        showAll={showAll}
                        total={totalStaffPicks}
                        hasMore={!isLastStaffPicks}
                        templates={staffPicksTemplates}
                        columnSize={2}
                        type="STAFF_PICKED"
                        refetch={
                          !!refetchSections?.find((s) => s === "Staff picks")
                        }
                        isSelected={isSelected}
                        toggleSelect={toggleSelect}
                        selectable={selectable}
                        selectDisabled
                        loading={isLoadingStaffPicks}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          )}
          {/* {(isLoadingBranded || shouldShowBranded) && (
          // TODO: Update this
            <>
              <SectionDivider />
              <Box p={mdUp ? 6 : 0}>
                <TemplateSection
                  title="Branded"
                  showAll={showAll}
                  total={totalBranded}
                  hasMore={!isLastBranded}
                  templates={brandedTemplates}
                  type="CUSTOM"
                  renderWithEjs
                  refetch={!!refetchSections?.find((s) => s === "Saved")}
                  isSelected={isSelected}
                  toggleSelect={toggleSelect}
                  selectable={selectable}
                  selectDisabled
                  loading={isLoadingBranded}
                />
              </Box>
            </>
          )} */}
          {(isLoadingBasic || shouldShowBasic) && (
            <>
              <SectionDivider />
              <Box p={mdUp ? 6 : 0}>
                <TemplateSection
                  title="Basic"
                  showAll={showAll}
                  total={totalBasic}
                  hasMore={!isLastBasic}
                  type="BASIC"
                  templates={basicTemplates}
                  refetch={!!refetchSections?.find((s) => s === "Saved")}
                  isSelected={isSelected}
                  toggleSelect={toggleSelect}
                  selectable={selectable}
                  selectDisabled
                  loading={isLoadingBasic}
                />
              </Box>
            </>
          )}
          {(isLoadingLibrary || shouldShowLibrary) && (
            <>
              <SectionDivider />
              <Box p={mdUp ? 6 : 0}>
                <TemplateSection
                  title="Library"
                  showAll={showAll}
                  total={totalLibrary}
                  hasMore={!isLastLibrary}
                  templates={libraryTemplates}
                  type="LIBRARY"
                  refetch={!!refetchSections?.find((s) => s === "Saved")}
                  isSelected={isSelected}
                  toggleSelect={toggleSelect}
                  selectable={selectable}
                  selectDisabled
                  loading={isLoadingLibrary}
                />
              </Box>
            </>
          )}
        </>
      );
    }
  };

  return (
    <DragAndDropTabProvider value={dragAndDropView}>
      <Box>
        <DragAndDropHeader />
        {isLoading ? <ComponentLoader height="400px" /> : renderContent()}
      </Box>
    </DragAndDropTabProvider>
  );
}

function SectionDivider() {
  const { mdUp } = useBreakpoints();
  return (
    <Box ml={mdUp ? 6 : 0} mr={mdUp ? 6 : 0}>
      <Divider />
    </Box>
  );
}

export default DragAndDropTab;
