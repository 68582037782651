import React from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { Form, FormItem, TextField } from "../../components";
import { useSendTestEmailDialog } from "./SendTestEmailDialog.hooks";
import { SendTestEmailDialogProps } from "./SendTestEmailDialog.types";
import { validateEmailList } from "../../utils/validateUtils";
import { CircleCheckOutline } from "@tiny/icons";
import { LoadingButton } from "@mui/lab";

function SendTestEmailDialog(props: SendTestEmailDialogProps): JSX.Element {
  const { open } = props;

  const {
    clearErrorMessage,
    close,
    emailsSent,
    errorMessage,
    form,
    handleExited,
    remainedTestEmails,
    sendTestEmail,
    sending,
  } = useSendTestEmailDialog(props);
  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      TransitionProps={{ onExited: handleExited }}
    >
      {emailsSent ? (
        <>
          <DialogTitle />
          <DialogContent sx={{ pt: 0 }}>
            <Box textAlign="center">
              <Box fontSize={40} height={40}>
                <CircleCheckOutline fontSize="inherit" color="primary" />
              </Box>
              <Typography
                variant="h2"
                component="h3"
                align="center"
                fontWeight="semiBold"
                color="grey"
                m={0}
                mt={4}
                mb={2}
              >
                Test email sent
              </Typography>
              <Typography
                variant="p2"
                component="p"
                align="center"
                color="grey"
                m={0}
              >
                In case you do not see it in your inbox, please check your spam
                folder.
              </Typography>
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>
            <Typography variant="h3" component="p" color="black" my={2}>
              Send test email
            </Typography>
          </DialogTitle>
          <DialogContent>
            {errorMessage && (
              <Box mb={6}>
                <Alert
                  variant="filled"
                  severity="warning"
                  onClose={clearErrorMessage}
                >
                  {errorMessage}
                </Alert>
              </Box>
            )}
            <Box pt={2}>
              <Form form={form} onSubmit={sendTestEmail}>
                <FormItem
                  name="emails"
                  rules={{
                    required: {
                      value: true,
                      message: "Emails are required.",
                    },
                    validate: validateEmailList,
                  }}
                >
                  <TextField
                    placeholder="eg.. johnatan.doe@gmail.com"
                    label="Email"
                    hiddenLabel
                  />
                </FormItem>
              </Form>
            </Box>
            <Box mt={5}>
              <Divider />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="primary" onClick={close}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={sendTestEmail}
              loading={sending}
              disabled={sending}
            >
              Send test
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default SendTestEmailDialog;
