import React from "react";
import { useDialogInvokers } from "@tiny/lib";
import { Button } from "@mui/material";
import { Metric } from "@/components/MetricCard/types";
import StatisticsDialogContent from "../components/StatisticsDialogContent";

function useStatisticsDialog() {
  const { requestMediumDialog, exitDialog } = useDialogInvokers();
  const showStatisticsDialog = (
    description: string,
    metricData: Metric[],
    title: string,
    exportReportHandler: () => void,
    seeReportsCtaId?: string
  ) => {
    requestMediumDialog({
      titleProps: { children: title, variant: "h3" },
      contentProps: {
        children: (
          <StatisticsDialogContent
            description={description}
            metricData={metricData}
          />
        ),
      },
      actionProps: {
        children: (
          <Button
            disableElevation
            onClick={() => {
              exportReportHandler();
              exitDialog();
            }}
            variant="contained"
            id={seeReportsCtaId}
          >
            See reports
          </Button>
        ),
      },
    });
  };

  return { showStatisticsDialog };
}

export { useStatisticsDialog };
