import React from "react";
import { Tabs as MuiTabs } from "@mui/material";
import { OnChange, TabsProps } from "./Tabs.types";

function Tabs(props: TabsProps) {
  const { info, infoBgColor, onChange, children, sidePadding, ...other } =
    props;

  const handleChange: OnChange = (...args: any[]) => {
    if (onChange) {
      onChange(args[1]);
    }
  };

  return (
    <MuiTabs
      {...other}
      sx={(theme) => ({
        position: "relative",
        paddingLeft: theme.spacing(props.sidePadding || 0),
        paddingRight: theme.spacing(props.sidePadding || 0),
        minHeight: 36,
        "&::before": {
          content: "''",
          position: "absolute",
          right: 0,
          bottom: 0,
          left: 0,
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        [theme.breakpoints.up("md")]: {
          minHeight: 30,
        },
      })}
      TabIndicatorProps={{
        sx: (theme) => ({
          height: 1,
          backgroundColor: "transparent",
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          color: `${theme.palette.grey[900]} !important`,
          fontWeight: 600,
          [theme.breakpoints.up("md")]: {
            fontWeight: 700,
          },
        }),
      }}
      indicatorColor="primary"
      onChange={handleChange}
    >
      {children}
    </MuiTabs>
  );
}

export default Tabs;
