import { TableHeaderCellsTypes } from "@/components/Table/table.types";
import { Subscriber } from "@/types/api/subscribers";

const headerColumns: TableHeaderCellsTypes<Subscriber>[] = [
  { propName: "email", label: "Email address" },
  { propName: "status", label: "Status" },
  { propName: "firstName", label: "First name" },
  { propName: "lastName", label: "Last name" },
  { propName: "birthday", label: "Birthday" },
  { propName: "country", label: "Country" },
  { propName: "phone", label: "Phone" },
  { propName: "postalCode", label: "Zip" },
];

export { headerColumns };
