import { TinyStyleGuideColors } from "../types/palette.types";
import { asMuiColor, createPaletteColor } from "../utils";

const greyScale = {
  0: "#FFFFFF",
  10: "#FBFCFD",
  20: "#F4F6FB",
  30: "#E7E9EE",
  40: "#DFE0E5",
  50: "#CFD0D4",
  60: "#C9CACE",
  70: "#9FA1A8",
  80: "#6F737D",
  90: "#3F4451",
  100: "#0F1526",
};

const tinyColors: TinyStyleGuideColors = {
  red: {
    50: "#FFE9E7",
    75: "#FDC1BB",
    100: "#DA4536",
    125: "#B21D0E",
    contrastText: "#ffffff",
  },
  fuchsia: {
    50: "#FFE2FE",
    75: "#FFB5DE",
    100: "#EF9ACC",
    125: "#DC89B9",
    contrastText: "#ffffff",
  },
  yellow: {
    50: "#FFF7EA",
    75: "#FFDBAA",
    100: "#FFA62B",
    125: "#E78600",
    contrastText: "#ffffff",
  },
  blue: {
    50: "#BDF4FF",
    75: "#6EDEF4",
    100: "#48CAE4",
    125: "#41B8D0",
    contrastText: "#ffffff",
  },
  green: {
    50: "#EBF6EE",
    75: "#AFDABB",
    100: "#36A455",
    125: "#0D8A30",
    contrastText: "#ffffff",
  },
  purple: {
    50: "#F0EBFF",
    75: "#DBCFFE",
    100: "#C4AFFF",
    125: "#734FF7",
    contrastText: "#ffffff",
  },
};

const primaryColor = asMuiColor({
  10: "#F1EEFF",
  20: "#E3DCFD",
  40: "#C7B9FC",
  50: "#B8A6FA",
  60: "#AB95FA",
  75: "#967BF9",
  80: "#8F72F8",
  100: "#734FF7",
  120: "#5532D3",
  125: "#5532D3",
  contrastText: "#ffffff",
});
const muiColors = createPaletteColor(tinyColors);

const palette = {
  primary: primaryColor,
  grey: greyScale,
  ...muiColors,
  background: {
    paper: greyScale["0"],
    default: greyScale["20"],
  },
  error: {
    lighter: tinyColors.red["50"],
    main: tinyColors.red["125"],
  },
};

export { palette };
