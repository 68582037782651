import { StylesOverridesProps } from "../../../types/stylesOverrides";

const container = ({ theme }: StylesOverridesProps) => ({
  maxWidth: "100vw",
  alignItems: "flex-end",
  [theme.breakpoints.up("md")]: {
    alignItems: "center",
  },
});
const paper = ({ theme }: StylesOverridesProps) => ({
  color: theme.palette.grey[100],
  width: "100%",
  maxHeight: "100%",
  margin: 0,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6),
  },
});
const paperWidthSm = ({ theme }: StylesOverridesProps) => ({
  [theme.breakpoints.up("md")]: {
    maxWidth: theme.typography.pxToRem(400),
  },
});

const paperWidthMd = ({ theme }: StylesOverridesProps) => ({
  [theme.breakpoints.up("md")]: {
    maxWidth: theme.typography.pxToRem(550),
  },
});

const styleOverrides = {
  container,
  paper,
  paperWidthSm,
  paperWidthMd,
};

export { styleOverrides };
