import { format } from "date-fns";
import React from "react";
import {
  useGetCampaignAutoTrackingByIdQuery,
  usePutCampaignAutoTrackingByIdMutation,
} from "../../../app/useGeneratedApi";
import { useAccountPermissions } from "../../../hooks/useAccountPermissions";
import { useCampaignPageView } from "../../CampaignPage.context";
import { Step } from "../../CampaignPage.types";

export function useSchedulePanel() {
  const { campaignId, details, campaignStatus, setActiveStep } =
    useCampaignPageView();

  const { actionDisabled } = useAccountPermissions("SCHEDULE_CAMPAIGN");

  const autoTrackingQuery = useGetCampaignAutoTrackingByIdQuery(campaignId!, {
    enabled: !!campaignId,
  });

  const autoTrackingMutation = usePutCampaignAutoTrackingByIdMutation({
    onSuccess: () => autoTrackingQuery.refetch(),
  });

  const autoTracking = React.useMemo(
    () => !!autoTrackingQuery.data?.enabled,
    [autoTrackingQuery.data?.enabled]
  );

  const autoTrackingState = React.useMemo(() => {
    if (autoTrackingQuery.data?.enabled == null) {
      return "";
    } else {
      return autoTrackingQuery.data?.enabled ? "Enabled" : "Disabled";
    }
  }, [autoTrackingQuery.data?.enabled]);

  const saveAutoTracking = React.useCallback(
    (enabled: boolean) => {
      autoTrackingMutation.mutateAsync({
        id: campaignId!,
        data: { enabled },
      });
    },
    [autoTrackingMutation, campaignId]
  );

  const goToStep = React.useCallback(
    (step: Step, params = "") => {
      setActiveStep(step);
    },
    [setActiveStep]
  );

  const goToDetails = React.useCallback(
    () => setActiveStep("details"),
    [setActiveStep]
  );

  const goToSender = React.useCallback(
    () => setActiveStep("details"),
    [setActiveStep]
  );

  const goToDesign = React.useCallback(
    () => setActiveStep("design"),
    [setActiveStep]
  );

  const scheduleCompleted = React.useMemo(
    () => campaignStatus !== "DRAFT" && campaignStatus !== "PAUSED",
    [campaignStatus]
  );

  const scheduleDisabled = React.useMemo(
    () => false,
    // (nonCompliance?.length || 0) > 0 ||
    // actionDisabled ||
    // campaignStatus === "QUEUED" ||
    // campaignStatus === "LAUNCHED" ||
    // campaignStatus === "PAUSED",
    []
  );

  const scheduleDescription = React.useMemo(
    () =>
      details?.start && campaignStatus !== "PAUSED"
        ? format(new Date(details.start), "yyyy-MM-dd (zzzz), hh:mm a")
        : "or send it now",
    [campaignStatus, details?.start]
  );

  return {
    autoTracking,
    autoTrackingState,
    goToDetails,
    goToSender,
    goToDesign,
    displayPoweredBy: false,
    saveAutoTracking,
    scheduleCompleted,
    scheduleDisabled,
    scheduleDescription,
  };
}
