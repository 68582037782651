import * as React from "react";
import ChevronBigLeft from "@tiny/icons/generated/ChevronBigLeft";
import ChevronBigRight from "@tiny/icons/generated/ChevronBigRight";

const slots = {
  previous: ChevronBigLeft as React.ElementType,
  next: ChevronBigRight as React.ElementType,
};

const defaultProps = {
  slots,
};

export { defaultProps };
