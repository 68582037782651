import React from "react";
import { Grid } from "@mui/material";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { GetReportOverviewResponse } from "@/types/api/reportsOverview";
import { GetCampaignCountResponse } from "@/types/api/campaigns";
import { RootLoader } from "@/types/RootLoader";
import {
  metricCampaignCountMapper,
  metricMapper,
} from "../MetricCard/metric.mapper";
import MetricCard from "../MetricCard";

interface CampaignMetricsProps {
  campaignsReports: GetReportOverviewResponse;
  campaignsSent: GetCampaignCountResponse;
}

function CampaignMetrics({
  campaignsReports,
  campaignsSent,
}: CampaignMetricsProps) {
  const navigate = useNavigate();

  const mappedSendCampaigns = metricCampaignCountMapper(
    campaignsSent.count,
    "campaignsInfoMessages"
  );
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;

  const mappedCampaignReports = metricMapper(
    campaignsReports,
    "campaignsInfoMessages",
    accountInfo,
    () => navigate("/main/dashboard/generated-revenue/sources?filter=CAMPAIGN"),
    "campaigns-overview-see-reports"
  ).filter((name) => name.title !== "Total Revenue");

  return (
    <>
      <Grid item xs={12} md={4} className="campaigns-metric">
        <MetricCard metric={mappedSendCampaigns} />
      </Grid>

      {mappedCampaignReports.map((metric) => (
        <Grid item xs={12} md={4} key={metric.id} className="campaign-metric">
          <MetricCard metric={metric} />
        </Grid>
      ))}
    </>
  );
}

export default CampaignMetrics;
