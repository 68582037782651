import { StylesOverridesProps } from "../../../types/stylesOverrides";

const root = ({ theme }: StylesOverridesProps) => ({
  "&.MuiAlert-standard": {
    backgroundColor: "transparent",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    borderRadius: 0,

    "& .MuiAlert-icon": {
      marginRight: theme.spacing(1),
      padding: 0,
      width: theme.spacing(5),
      height: theme.spacing(5),
    },

    "& .MuiAlert-message": {
      margin: 0,
      padding: 0,
      fontSize: theme.spacing(3),
      color: theme.palette.grey[100],
    },

    "& .MuiAlert-action": {
      margin: 0,
      padding: 0,
      flex: 1,
      justifyContent: "flex-end",
    },
  },

  "&.MuiAlert-outlinedInfo": {
    backgroundColor: theme.palette.purple[50],
    borderColor: theme.palette.primary[100],
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    borderRadius: theme.spacing(2),

    "& .MuiAlert-icon": {
      marginRight: theme.spacing(1),
      padding: 0,
      width: theme.spacing(6),
      height: theme.spacing(6),
      color: theme.palette.primary[125],
    },
    "& .MuiAlert-message": {
      margin: 0,
      padding: 0,
      fontSize: theme.spacing(3),
      color: theme.palette.grey[100],
    },
  },
});

const styleOverrides = { root };

export { styleOverrides };
