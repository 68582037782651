import React from "react";
import { Box, Typography } from "@mui/material";

function PlanPrice({
  price,
  disabled,
  discountDescription,
  couponCode,
}: {
  price: string | undefined;
  disabled: boolean;
  discountDescription?: string;
  couponCode?: string;
}) {
  const [wholePart, decimalPart] = price ? price.split(/[.,]/) : [];
  return (
    <Box my={4} display="flex" flexDirection="column" gap={1}>
      {price ? (
        <Box display="flex" alignItems="flex-start" gap={1}>
          <Box display="flex">
            <Typography variant="priceH0R" color="grey.60">
              $
            </Typography>
            <Typography
              variant="priceH0"
              color={disabled ? "grey.80" : "grey.100"}
            >
              {wholePart}
            </Typography>
            <Typography variant="p2" color={disabled ? "grey.80" : "grey.100"}>
              .{decimalPart}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="p3" color="grey.80">
              per month
            </Typography>
            {couponCode && discountDescription && (
              <Typography variant="label1" color="primary.125">
                {`[${couponCode}] ${discountDescription}`}
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Typography variant="priceH0" color={disabled ? "grey.80" : "grey.100"}>
          TBD
        </Typography>
      )}
    </Box>
  );
}

export default PlanPrice;
