import { OnBoardingStep } from "./statusOverview";

export interface PostAccountSubscriptionResponse {
  onboardingStep: OnBoardingStep;
}

export interface GetAccountSubscriptionResponse {
  planName: string;
  planCode: string;
  subscriptionsCount: number;
  price: number;
  trial: boolean;
  couponCode?: string;
}

export enum PlanCodes {
  AiGrowthManager = "Manager",
  AiMarketingSpecialist = "Specialist",
  AiMarketingAssistant = "Assistant",
}
