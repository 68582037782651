import React, { createContext, useState, FC } from "react";

export enum ViewType {
  TABLE = "TABLE",
  CARD = "CARD",
}

interface CampaignContextType {
  view: ViewType;
  setView: (view: ViewType) => void;
}

export const CampaignsContext = createContext<CampaignContextType>({
  view: ViewType.CARD,
  setView: () => {},
});

export const CampaignsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [view, setView] = useState<ViewType>(ViewType.CARD);

  return (
    <CampaignsContext.Provider value={{ view, setView }}>
      {children}
    </CampaignsContext.Provider>
  );
};
