import { GetSenderDetailsResponse } from "@/types/api/senderDetails";
import { Box, Button } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useActionData, useNavigation } from "react-router-dom";
import { ApiError } from "@tiny/utils/errors";
import * as React from "react";
import { useEffect, useState } from "react";
import { isBackendError } from "@tiny/utils";

function SenderDetailsForm({
  details,
  onClose,
  onSubmit,
  ctaId,
}: {
  details: GetSenderDetailsResponse;
  onClose: () => void;
  onSubmit: (details: GetSenderDetailsResponse) => void;
  ctaId?: string;
}) {
  const defaultValues: GetSenderDetailsResponse = details;
  const navigation = useNavigation();
  const { error } = (useActionData() as { error: ApiError }) ?? {};
  const [beErrorMessage, setBeErrorMessage] = useState("");

  useEffect(() => {
    const errorBody = error?.body;
    if (isBackendError(errorBody) && errorBody.message) {
      setBeErrorMessage(errorBody.message);
    } else {
      setBeErrorMessage("");
    }
  }, [error]);

  const isSubmitting = navigation.state === "submitting";
  const ctaName = isSubmitting ? "Updating..." : "Update sender";
  return (
    <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
      <Box
        gap={4}
        id="sender-details-form-container"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justContent: "space-between",
          mt: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justContent: "space-between",
            flexGrow: 1,
            gap: 4,
          }}
        >
          <TextFieldElement
            size="small"
            name="name"
            label="Sender name"
            sx={{ flexGrow: 0, color: "grey.100", flexBasis: "50%" }}
            required
          />
          <TextFieldElement
            size="small"
            name="email"
            type="email"
            label="Email address"
            required
            sx={{ flexGrow: 0, flexBasis: "50%" }}
            FormHelperTextProps={{ error: true }}
            helperText={beErrorMessage}
          />
        </Box>
        <TextFieldElement
          size="small"
          name="address"
          label="Address"
          sx={{ flexBasis: "100%" }}
        />
        <TextFieldElement
          size="small"
          name="country"
          label="Country"
          sx={{ flexGrow: 1 }}
        />
        <TextFieldElement
          size="small"
          name="region"
          label="State"
          sx={{ flexGrow: 1 }}
        />
        <TextFieldElement
          size="small"
          name="city"
          label="City"
          sx={{ flexGrow: 1 }}
        />
        <TextFieldElement
          size="small"
          name="postalCode"
          label="Zip code"
          sx={{ flexGrow: 1 }}
        />
      </Box>
      <Box
        id="sender-details-form-actions"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          columnGap: 5,
          mt: 6,
        }}
      >
        <Button onClick={onClose} className="cancel-cta" variant="text">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          id={ctaId}
        >
          {ctaName}
        </Button>
      </Box>
    </FormContainer>
  );
}

export { SenderDetailsForm };
