import { Box, Typography } from "@mui/material";
import React from "react";

function MetricInfoCard({
  image,
  label,
  value,
}: {
  image: string;
  label: string;
  value: string | number;
}) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="flex-start"
    >
      <Box
        overflow="hidden"
        display="flex"
        gap={2}
        flexDirection="column"
        pt={2}
      >
        <Typography variant="label1R">{label}</Typography>
        <Typography variant="h2">{value}</Typography>
      </Box>
      <Box sx={{ maxWidth: "50%", flexShrink: 1 }}>
        {image && (
          <img
            src={image}
            alt="tinyAlbert"
            className="metric-image-"
            style={{ width: "100%", height: "auto" }}
          />
        )}
      </Box>
    </Box>
  );
}

export default MetricInfoCard;
