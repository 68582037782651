import { FetchTemplatesType, TemplateData } from "./template.types";

export const templateTabs: { name: FetchTemplatesType }[] = [
  { name: "Saved" },
  { name: "Branded" },
  { name: "Basic" },
  { name: "Library" },
];

export function mergeAttributes(
  html: string,
  attributes?: { name?: string; value?: string }[]
): string {
  let result = html;
  attributes?.forEach((attr) => {
    if (attr.name && attr.value != null) {
      result = result.replaceAll(attr.name, attr.value);
    }
  });
  return result;
}

export function prepareTemplateToEdit(html: string | undefined): string {
  if (!html) {
    return "";
  }
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, "text/html");
  removeSenderDetails(htmlDoc);
  removeUnsubscribeLink(htmlDoc);
  return htmlDoc.documentElement.innerHTML;
}

export function appendFooter(
  html: string | undefined,
  footer?: string
): string {
  if (!html) {
    return "";
  }
  if (!footer) {
    return html;
  }
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, "text/html");
  if (hasEsWrapper(htmlDoc)) {
    addAtEndOfWrapper(htmlDoc, footer);
  } else {
    addAtEndOfHtml(htmlDoc, footer);
  }
  return htmlDoc.documentElement.innerHTML;
}

export function prepareTemplate(
  html: string | undefined,
  baseUrl: string
): string {
  if (!html) {
    return "";
  }
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, "text/html");
  addSenderDetails(htmlDoc);
  addUnsubscribeLink(htmlDoc, baseUrl);
  addPoweredBy(htmlDoc);
  return htmlDoc.documentElement.innerHTML;
}

function addSenderDetails(htmlDoc: Document): void {
  const hasSenderDetails = !!htmlDoc.getElementById("senderDetails");
  if (hasSenderDetails) {
    return;
  }
  const code = `
    <tbody>
      <tr id="senderDetails" style="border-collapse:collapse">
        <td style="padding:20px 20px 0px;margin:0" align="center">
          <p style="margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:Inter;font-style: normal;
          font-weight: normal;font-size: 12px;line-height: 16px;text-align: center;color: #6F737D;">
          [account_company_name], [sender_details_address] · [sender_details_region] · [sender_details_city] · [sender_details_postal_code] · [sender_details_country]
          </p>
        </td>
      </tr>
    </tbody>
  `;
  addAtEndOfWrapper(htmlDoc, code);
}

function addUnsubscribeLink(htmlDoc: Document, baseUrl: string): void {
  const hasUnsubscribe = !!htmlDoc.getElementById("senderDetailsUnsubscribe");
  if (hasUnsubscribe) {
    return;
  }
  const code = `
    <tbody>
      <tr id="senderDetailsUnsubscribe">
        <td style="padding:5px 20px 0px;margin:0;font-family:Inter;font-style: normal;
        font-weight: normal;font-size: 12px;line-height: 16px;text-align: center;color: #9FA1A8;" align="center">
        No longer want to receive these emails. <a id="unsubscribe_tinyemail" style="text-decoration: underline; color:#9FA1A8;Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:17px;font-size:11px" target="_blank" href="${baseUrl}/unsubscribe?logo=[identity_logo]&email=[customer_email]">
            Unsubscribe
          </a>
        </td>
      </tr>
    </tbody>
  `;
  addAtEndOfWrapper(htmlDoc, code);
}

function addPoweredBy(htmlDoc: Document): void {
  const hasPoweredBy = !!htmlDoc.getElementById("poweredBy");
  if (hasPoweredBy) {
    return;
  }
  const code = `
    <tbody>
      <tr id="poweredBy">
        <td style="padding:15px 20px 20px;margin:0;" align="center">
        <span style="margin-right: 10px;font-family: Inter;font-style: normal;font-weight: normal;font-size: 12px;color: #9FA1A8;line-height: 16px; display: inline-block;vertical-align: middle;">
        [account_powered_by_text]
        </span>
        <span style="display: inline-block;vertical-align: middle; height:22px">
        [account_powered_by_logo]
        </span>
        </td>
      </tr>
    </tbody>
  `;
  addAtEndOfWrapper(htmlDoc, code);
}

function addAtEndOfWrapper(htmlDoc: Document, code: string): void {
  const wrapper = htmlDoc.querySelector(".es-wrapper");
  wrapper?.insertAdjacentHTML("beforeend", code);
}

function addAtEndOfHtml(htmlDoc: Document, code: string): void {
  const footerTable = htmlDoc.createElement("table");
  footerTable.style.width = "100%";
  footerTable.innerHTML = code;
  htmlDoc.body.appendChild(footerTable);
}

function hasEsWrapper(htmlDoc: Document): boolean {
  return !!htmlDoc.querySelector(".es-wrapper");
}

function removeSenderDetails(htmlDoc: Document): void {
  const senderDetails = htmlDoc.getElementById("senderDetails");
  const tbody = senderDetails?.parentNode;
  tbody?.parentNode?.removeChild(tbody);
}

function removeUnsubscribeLink(htmlDoc: Document): void {
  const senderDetails = htmlDoc.getElementById("senderDetailsUnsubscribe");
  const tbody = senderDetails?.parentNode;
  tbody?.parentNode?.removeChild(tbody);
}

export function extractTemplateData(
  attributes?: { name?: string; value?: string }[]
): TemplateData | undefined {
  if (!attributes) {
    return undefined;
  }

  const mapAttr: {
    [key: string]: string | undefined;
  } = {};

  attributes.forEach((attr) => {
    const name = attr.name?.slice(1, -1);
    if (name) {
      mapAttr[name] = attr.value;
    }
  });

  const result: TemplateData = {
    menu: [],
    social: [],
    socialMap: {},
    buttonBgColor: mapAttr["identity_buttons_color"] || "",
    contentBgColor: mapAttr["identity_background_color"] || "",
    contentFontColor: mapAttr["identity_body_text_color"] || "",
    contentLinkColor: mapAttr["identity_links_color"] || "",
    logo: mapAttr["identity_logo"] || "",
    generalFont: mapAttr["identity_primary_font"] || "",
  };

  attributes.forEach((attr) => {
    const { name, value } = attr;
    if (name && value) {
      const cleanName = name.slice(1, -1);
      if (cleanName.startsWith("identity_link_")) {
        const text = cleanName.replace("identity_link_", "");
        result.menu.push({
          name: text,
          href: value,
        });
      } else if (cleanName.startsWith("identity_social_link_")) {
        const text = cleanName.replace("identity_social_link_", "");
        result.socialMap[text] = {
          name: text,
          href: value,
        };
        result.social.push({
          name: text,
          href: value,
        });
      }
    }
  });

  return result;
}
