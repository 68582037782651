import React from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { Refresh } from "@tiny/icons";
import { useRefreshButton } from "@/hooks/useRefreshButton";

function RefreshButton() {
  const { clickHandler, isLoading } = useRefreshButton();

  return !isLoading ? (
    <IconButton
      onClick={clickHandler}
      sx={{
        p: 0,
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Refresh />
    </IconButton>
  ) : (
    <CircularProgress size={24} />
  );
}

export default RefreshButton;
