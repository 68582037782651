import { useEffect } from "react";
import * as Sentry from "@sentry/react";

interface SentryUserProps {
  site: string;
  email: string;
  username: string;
}

function useSentryUser(user: SentryUserProps) {
  useEffect(() => {
    Sentry.setUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export { useSentryUser };
