import { Theme } from "@mui/material";

const root = ({ theme }: { theme: Theme }) => [
  {
    width: theme.spacing(6),
    height: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
];

const styleOverrides = {
  root,
};

export { styleOverrides };
