import { fetchApi } from "@tiny/utils";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import { GetCampaignResponse } from "@/types/api/campaigns";

export const defaultApiQueryParams = {
  page: "0",
  size: "6",
  searchTextName: "",
};

interface DraftCampaignsLoaderProps {
  campaigns: GetCampaignResponse;
}

const draftCampaignsLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const requestParams = Object.fromEntries(url.searchParams);
  const apiPage = (parseInt(requestParams.page ?? 1, 10) - 1).toString();

  const apiParams = {
    ...defaultApiQueryParams,
    ...requestParams,
    page: apiPage,
  };
  const apiQueryString = new URLSearchParams(apiParams).toString();
  const campaignsPromise = fetchApi<GetCampaignResponse>(
    `/campaignservice/campaign/find/filter?${apiQueryString}`,
    {
      signal: request.signal,
      method: "PUT",
      body: JSON.stringify({
        campaignStatuses: ["DRAFT"],
        generatedBy: ["User"],
        searchTextName: apiParams?.searchTextName,
      }),
    }
  );

  return defer({
    campaigns: {
      campaigns: await campaignsPromise,
    },
  });
};

export { draftCampaignsLoader };
export type { DraftCampaignsLoaderProps };
