import React from "react";
import { Button, Typography, Checkbox, IconButton, Box } from "@mui/material";
import { SearchField } from "../../components";
import useDebounce from "../../hooks/useDebounce";
import { MoreHorizontal, TrashFull } from "@tiny/icons";
import ChipWithTooltip from "../ChipWithTooltip/ChipWithTooltip";
import { ListHeaderProps } from "./ListHeader.types";

function ListHeader(props: ListHeaderProps) {
  const {
    actions,
    allSelected,
    allSelectedDisabled,
    bulkDeleteEnabled,
    extra,
    onDeleteClick,
    onDeselectAll,
    onMoreMobile,
    onSearchChange,
    onTagDelete,
    onToggleAll,
    totalSelected,
    tags = [],
    selectable = true,
  } = props;

  const [search, setSearch] = React.useState("");

  const searchDebounced = useDebounce(search);

  React.useEffect(() => {
    onSearchChange(searchDebounced);
  }, [onSearchChange, searchDebounced]);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minHeight: 16,
        padding: "12px 16px",
        [theme.breakpoints.up("md")]: {
          minHeight: 28,
          padding: "0 24px",
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        {selectable && (
          <Box
            sx={{
              flex: "0 0 32px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              color="primary"
              checked={allSelected}
              onChange={(checked, e) => onToggleAll(e)}
              disabled={allSelectedDisabled}
              title="Select all"
            />
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={(theme) => ({
              flex: 1,
              display: "flex",
              [theme.breakpoints.up("md")]: {
                flex: "initial",
              },
            })}
          >
            {totalSelected > 0 ? (
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {bulkDeleteEnabled && (
                  <Button
                    variant="outlined"
                    color="red"
                    endIcon={<TrashFull />}
                    onClick={onDeleteClick}
                    sx={{
                      marginRight: 4,
                    }}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  sx={(theme) => ({
                    display: "none",
                    [theme.breakpoints.up("md")]: {
                      marginRight: 4,
                      display: "flex",
                    },
                  })}
                  onClick={onDeselectAll}
                  variant="text"
                >
                  Deselect all
                </Button>
                <Typography color="grey" variant="p2">
                  {totalSelected} selected
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <SearchField
                  value={search}
                  placeholder="Search"
                  onChangeValue={setSearch}
                  fullWidth
                  sx={(theme) => ({
                    width: "100%",
                    [theme.breakpoints.up("md")]: {
                      width: 300,
                    },
                  })}
                />
              </Box>
            )}
          </Box>
          {extra && totalSelected === 0 && (
            <Box
              sx={(theme) => ({
                flex: 1,
                display: "none",
                [theme.breakpoints.up("md")]: {
                  display: "flex",
                  paddingLeft: 2,
                },
              })}
            >
              {extra}
            </Box>
          )}
        </Box>
        {actions && (
          <Box
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.up("md")]: {
                display: "flex",
              },
            })}
          >
            {actions}
          </Box>
        )}
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            paddingLeft: 2,
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          })}
        >
          <IconButton onClick={onMoreMobile} size="small" aria-label="More">
            <MoreHorizontal />
          </IconButton>
        </Box>
      </Box>
      {tags.length > 0 && (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            marginTop: 1,
            "& > div": {
              marginTop: 1,
            },
            [theme.breakpoints.up("md")]: {
              marginBottom: 3,
            },
          })}
        >
          {tags.map((tag) => (
            <ChipWithTooltip
              key={tag.id}
              label={tag.name}
              onDelete={() => onTagDelete?.(tag.id!)}
              maxVisibleChars={16}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default ListHeader;
