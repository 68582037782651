import { StylesOverridesProps } from "../../../types/stylesOverrides";

const root = ({ theme }: StylesOverridesProps) => ({
  "&.Mui-checked": {
    color: theme.palette.primary[125],
    strokeWidth: 3.5,
    stroke: theme.palette.primary[125],
  },
  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
    color: "transparent",
    stroke: "none",
  },
});

const styleOverrides = {
  root,
};

export { styleOverrides };
