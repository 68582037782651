import DialogContentText from "@mui/material/DialogContentText";
import { LoadingButton } from "@mui/lab";
import {
  List,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import Dot02S from "@tiny/icons/generated/Dot02S";

interface ConfirmationFormActivationContentProps {
  confirmClickHandler: () => void;
  activeForms: string[];
}
function ConfirmationFormActivationContent({
  confirmClickHandler,
  activeForms,
}: ConfirmationFormActivationContentProps) {
  return (
    <>
      <DialogContentText
        component="div"
        id="confirmation-dialog"
        sx={{ textAlign: "left", mt: 3 }}
        variant="p2"
        color="grey.100"
      >
        <Typography>The following active forms will be disabled:</Typography>
        <List sx={{ listStyleType: "circle" }}>
          {activeForms.map((name) => (
            <ListItem key={name} disablePadding>
              <ListItemIcon sx={{ display: "block", minWidth: 6 }}>
                <Dot02S />
              </ListItemIcon>
              <ListItemText>{name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContentText>
      <DialogContentText
        id="confirmation-dialog"
        sx={{ textAlign: "center" }}
        variant="p2"
        color="grey.100"
      >
        <LoadingButton
          loadingIndicator="Generating"
          variant="contained"
          fullWidth
          onClick={confirmClickHandler}
        >
          OK
        </LoadingButton>
      </DialogContentText>
    </>
  );
}

export { ConfirmationFormActivationContent };
