import React from "react";
import { UseUploadDialogProps } from "./UploadDialog.types";

export function useUploadDialog(props: UseUploadDialogProps) {
  const { onUpload } = props;

  const [files, setFiles] = React.useState<File[]>([]);

  const fileChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files;
      if (!fileList) {
        return;
      }
      const result: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
        result.push(fileList.item(i)!);
      }
      setFiles(result);
    },
    []
  );

  const upload = React.useCallback(() => {
    onUpload(files);
  }, [files, onUpload]);

  const fileName = files?.[0]?.name || "";

  const uploadDisabled = !fileName;

  return {
    fileChange,
    fileName,
    upload,
    uploadDisabled,
  };
}
