import React from "react";
import { Box } from "@mui/material";
import ColorInput from "@tiny/mui/ColorInput";
import { ColorPreferences } from "@tiny/mui/ColorInput/types";

export interface ColorPickerFormProps {
  colors: ColorPreferences;
  onColorChange: React.Dispatch<React.SetStateAction<ColorPreferences>>;
  selectedPrimaryColorRef: React.MutableRefObject<string>;
  selectedSecondaryColorRef: React.MutableRefObject<string>;
}

function ColorPickerForm(props: ColorPickerFormProps) {
  const {
    colors,
    onColorChange,
    selectedPrimaryColorRef,
    selectedSecondaryColorRef,
  } = props;

  const handlePrimaryColorChange = (newColor: string) => {
    onColorChange((currentColors: ColorPreferences) => ({
      ...currentColors,
      primaryColor: newColor,
    }));
  };

  const handleSecondaryColorChange = (newColor: string) => {
    onColorChange((currentColors: ColorPreferences) => ({
      ...currentColors,
      secondaryColor: newColor,
    }));
  };

  return (
    <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={4}>
      <ColorInput
        size="small"
        value={colors.primaryColor}
        label="Primary"
        inputRef={selectedPrimaryColorRef}
        onChange={handlePrimaryColorChange}
        format="hex8"
      />
      <ColorInput
        size="small"
        value={colors.secondaryColor}
        label="Secondary"
        inputRef={selectedSecondaryColorRef}
        onChange={handleSecondaryColorChange}
        format="hex8"
      />
    </Box>
  );
}

export default ColorPickerForm;
