import { Box } from "@mui/material";
import { HtmlPreview } from "../HtmlPreview";
import { HtmlPreviewProps } from "../HtmlPreview/types";

function MobilePreview(props: HtmlPreviewProps) {
  const { width, height } = props;
  return (
    <Box
      id="mobile-viewport"
      position="relative"
      overflow="hidden"
      width={width}
      height={height}
      padding={1}
      borderRadius={6}
      sx={{
        borderWidth: 8,
        borderStyle: "solid",
        borderColor: "grey.30",
        "& > *": {
          borderRadius: 3,
        },
      }}
    >
      <HtmlPreview {...props} width="100%" height="100%" />
    </Box>
  );
}

export { MobilePreview };
