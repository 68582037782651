import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export interface EmptyStateProps {
  title: string;
  Icon: React.ReactNode;
  message?: string;
}

function EmptyState(props: EmptyStateProps) {
  const { message, Icon, title } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: theme.spacing(100),
        flexDirection: "column",
        gap: 6,
        border: "0.5px solid",
        borderColor: theme.palette.grey[40],
        borderRadius: 2,
      }}
    >
      {Icon && (
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            padding: 4,
            borderRadius: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {Icon}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h4" color="grey.100">
          {title}
        </Typography>
        <Typography variant="p2" color="grey.80">
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

export default EmptyState;
