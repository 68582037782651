import { fetchApi } from "@tiny/utils";
import { ActionFunctionArgs } from "react-router-dom";

type Actions = "regenerateAll";

async function automationsActions({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const action = formData.get("_action") as Actions;

  switch (action) {
    case "regenerateAll":
      return regenerateAutomations();
    default:
      console.log("no action provided");
  }
}

async function regenerateAutomations() {
  return fetchApi<any>("/automationservice/automation/einsteinize", {
    method: "POST",
  }).then(() => ({ ok: true }));
}

export { automationsActions };
