import React, { useEffect, useState } from "react";
import ColorPickerForm from "@/components/ColorPickerForm";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useDialogInvokers } from "@tiny/lib";
import { ColorPreferences } from "@tiny/mui/ColorInput/types";
import { useIsLargerThan } from "@tiny/theme/utils";
import { useSearchParams } from "react-router-dom";
import MissingColorsMessage from "@/components/ColorPicker/MissingColorsMessage";

function ColorPickerDialogContent({
  colors,
  onSaveChange,
  selectedPrimaryColorRef,
  selectedSecondaryColorRef,
}: {
  colors: ColorPreferences;
  onSaveChange: (updatedColors: ColorPreferences) => void;
  selectedPrimaryColorRef: React.MutableRefObject<string>;
  selectedSecondaryColorRef: React.MutableRefObject<string>;
}) {
  const { exitDialog } = useDialogInvokers();
  const isMdUp = useIsLargerThan("sm");

  const [selectedColors, setSelectedColors] = React.useState<ColorPreferences>({
    primaryColor: colors.primaryColor,
    secondaryColor: colors.secondaryColor,
  });

  const primarySelectedColorParamName = "primaryColor";
  const secondarySelectedColorParamName = "secondaryColor";

  const [searchParams, setSearchParams] = useSearchParams();

  const [showError, setShowError] = useState<boolean | null>(null);

  useEffect(() => {
    if (showError !== null) {
      if (
        selectedColors.primaryColor.length > 0 &&
        selectedColors.secondaryColor.length > 0
      ) {
        setShowError(false);
      } else {
        setShowError(true);
      }
    }
  }, [selectedColors, showError]);

  const onApplyColors = () => {
    if (
      selectedColors.primaryColor.length === 0 ||
      selectedColors.secondaryColor.length === 0
    ) {
      setShowError(true);
    } else {
      saveColors();
    }
  };

  const saveColors = React.useCallback(() => {
    onSaveChange({
      primaryColor: selectedColors.primaryColor,
      secondaryColor: selectedColors.secondaryColor,
    });

    searchParams.set(
      primarySelectedColorParamName,
      selectedColors.primaryColor
    );
    searchParams.set(
      secondarySelectedColorParamName,
      selectedColors.secondaryColor
    );

    setSearchParams(searchParams);
    exitDialog();
  }, [
    exitDialog,
    onSaveChange,
    searchParams,
    selectedColors.primaryColor,
    selectedColors.secondaryColor,
    setSearchParams,
  ]);

  return (
    <>
      <Box display="flex" gap={4} flexDirection="column" sx={{ marginY: 4 }}>
        <Typography variant="p3" color="grey.80">
          Primary colors are for important design elements, while secondary
          colors complement and add visual interest to the design in email
          marketing templates generated by AI.
        </Typography>
        <ColorPickerForm
          colors={selectedColors}
          onColorChange={setSelectedColors}
          selectedPrimaryColorRef={selectedPrimaryColorRef}
          selectedSecondaryColorRef={selectedSecondaryColorRef}
        />
      </Box>
      {showError ? <MissingColorsMessage /> : null}

      <Divider sx={{ my: 4 }} />
      <Box
        display="flex"
        flexDirection="row"
        gap={4}
        justifyContent="flex-end"
        sx={{ mt: 4 }}
      >
        <Button
          fullWidth={!isMdUp}
          disableElevation
          variant="text"
          onClick={exitDialog}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          fullWidth={!isMdUp}
          variant="contained"
          onClick={onApplyColors}
          id="myaccount-aituning-apply-change-colors"
        >
          Apply colors
        </Button>
      </Box>
    </>
  );
}

export default ColorPickerDialogContent;
