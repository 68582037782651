import { StylesOverridesProps } from "../../../types/stylesOverrides";

const text = ({ theme }: StylesOverridesProps) => ({
  "&.Mui-disabled": {
    color: theme.palette.grey[60],
  },
});

const root = ({ theme }: StylesOverridesProps) => ({
  borderRadius: theme.spacing(2),
  textTransform: "unset",
  "&.Mui-disabled": {
    color: theme.palette.common.white,
  },
  "&.MuiButton-outlined.Mui-disabled": {
    color: theme.palette.grey[60],
  },
});

const styleOverrides = {
  root,
  text,
};

export { styleOverrides };
