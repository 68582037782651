import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import type { DialogProps } from "./SenderDetails.types";

function UpdateConfirmationMessage({
  dialogCloseHandler,
}: Pick<DialogProps, "dialogCloseHandler">) {
  return (
    <Box
      sx={{ display: "flex", flexFlow: "column", justifyContent: "flex-start" }}
    >
      <Divider sx={{ my: 4, borderColor: "grey.60" }} />
      <Box>
        <Typography color="grey.80" variant="p1" paragraph>
          Sender details updated successfully. Any new campaign or automation
          activated from now on will use the new sender details. If you need to
          update the sender details for an active campaign or automation, please
          confirm the email address (if changed), temporarily pause the current
          campaign or automation, regenerate the template, and then enable the
          updated campaign or automation.
        </Typography>
      </Box>
      <Divider sx={{ borderColor: "grey.60" }} />
      <Box
        id="sender-details-form-actions"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 4,
          columnGap: 5,
        }}
      >
        <Button
          className="learn-more-cta"
          variant="text"
          href="https://support.tinyalbert.ai/portal/en/kb/articles/update-sender-details"
          target="_blank"
          id="myaccount-senderdetails-update-learn-more"
        >
          <Typography component="span" variant="p2" color="primary.125">
            Learn more
          </Typography>
        </Button>
        <Button
          onClick={dialogCloseHandler}
          className="close-cta"
          variant="contained"
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
}

export { UpdateConfirmationMessage };
