import { RootLoader } from "@/types/RootLoader";
import React from "react";
import { Button, Card } from "@mui/material";
import {
  useActionData,
  useFetcher,
  useRouteLoaderData,
} from "react-router-dom";
import { BackEndError, isBackendError } from "@tiny/utils/api/types";
import type { PlanSelectionActionResponse } from "@/routes/PlansPage/types";
import PlanTitle from "./PlanTitle";
import PlanPrice from "./PlanPrice";
import PlanFeatures from "./PlanFeatures";
import { PlanProps } from "./types";

function Plan(props: PlanProps) {
  const {
    id,
    name,
    price,
    trialPeriod,
    features,
    comingSoon,
    index,
    currentPlan,
    isChanged,
    discountDescription,
    couponCode,
  } = props;

  const {
    userStatus: { onboardingStep },
  } = useRouteLoaderData("root") as RootLoader;

  const fetcher = useFetcher();
  const actionData = useActionData() as
    | PlanSelectionActionResponse
    | BackEndError;
  const newCouponCode = !isBackendError(actionData)
    ? actionData?.couponCode
    : "";

  const isSelecting = !!fetcher.formData;

  const getButtonLabel = () => {
    switch (true) {
      case isSelecting:
        return "Selecting";
      case comingSoon:
        return "Coming soon";
      case isChanged && onboardingStep === "FINISHED" && !currentPlan:
        return "Upgrade";
      case currentPlan:
        return "Current plan";
      default:
        return !trialPeriod ? `Start Now` : `Start ${trialPeriod}-day trial`;
    }
  };

  return (
    <Card
      key={id}
      sx={(theme) => ({
        p: 5,
        borderRadius: 2,
        background: comingSoon ? "transparent" : "white",
        boxShadow: "none",
        outline: comingSoon ? `1px solid ${theme.palette.grey[40]}` : "none",
      })}
    >
      <PlanTitle planIndex={index} planTitle={name} disabled={comingSoon} />
      <PlanPrice
        discountDescription={discountDescription}
        disabled={comingSoon}
        price={price?.toFixed(2)}
        couponCode={couponCode ?? newCouponCode}
      />

      <fetcher.Form method="post">
        <input type="hidden" name="couponCode" value={newCouponCode ?? ""} />
        <input type="hidden" name="_action" value="selectPlan" />
        <Button
          variant="contained"
          disabled={comingSoon || isSelecting || (currentPlan && !isChanged)}
          fullWidth
          type="submit"
          name="planId"
          value={id}
          disableElevation
        >
          {getButtonLabel()}
        </Button>
      </fetcher.Form>

      <PlanFeatures features={features} comingSoon={comingSoon} />
    </Card>
  );
}

export default Plan;
