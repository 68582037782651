import React from "react";
import { Tabs, HtmlPreview, MobilePreview } from "../../components";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseBig, CloseSmall } from "@tiny/icons";
import { useTemplatePreviewDialog } from "./TemplatePreviewDialog.hooks";
import { TemplatePreviewDialogProps } from "./TemplatePreviewDialog.types";

function TemplatePreviewDialog(props: TemplatePreviewDialogProps) {
  const { mainActionName, onMainActionClick, open, onClose, templateName } =
    props;

  const { activeTab, setActiveTab, handleExited, html, css } =
    useTemplatePreviewDialog(props);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      TransitionProps={{ onExited: handleExited }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">{templateName}</Typography>
          <IconButton onClick={onClose}>
            <CloseSmall />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tab label="Desktop" />
          <Tab label="Mobile" />
        </Tabs>
        <Box mt={6} display="flex" justifyContent="center">
          {activeTab === 0 ? (
            <HtmlPreview
              html={html}
              css={css}
              width="100%"
              height="500px"
              contentWidth={800}
              scrollable
            />
          ) : (
            <MobilePreview
              width="260px"
              height="500px"
              html={html}
              css={css}
              contentWidth={360}
              scrollable
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={onMainActionClick}>
          {mainActionName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplatePreviewDialog;
