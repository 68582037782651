import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useIsLargerThan } from "@tiny/theme/utils";

interface FooterCtaProps {
  children: React.ReactNode;
  clickHandler: () => void;
  id?: string;
}
function FormFooterCta({ children, clickHandler, id }: FooterCtaProps) {
  const isSmUp = useIsLargerThan("sm");

  return (
    <Box
      id="controls"
      mt={5}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="flex-end"
      alignItems="flex-start"
      gap={[4, 2]}
      position={["relative", "absolute"]}
      right={0}
    >
      <Typography
        variant="p3"
        color="grey.80"
        flex={{
          xs: "1 1 0",
          sm: "0 1 75%",
        }}
      >
        You can change the Tone of voice and Company/Product details again to
        modify/re-train the AI marketing manager. Update the information here
        and click on Generate.
      </Typography>
      <Button
        variant="contained"
        fullWidth={!isSmUp}
        onClick={clickHandler}
        id={id}
      >
        {children}
      </Button>
    </Box>
  );
}

export default FormFooterCta;
