import { Theme } from "@mui/material";

const popper = ({ theme }: { theme: Theme }) => ({
  "& .MuiTooltip-tooltip": {
    padding: 0,
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
  },
  "& .MuiTooltip-arrow": {
    color: theme.palette.common.white,
  },
});

const styleOverrides = {
  popper,
};

export { styleOverrides };
