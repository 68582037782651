import { StylesOverridesProps } from "../../../types/stylesOverrides";

const root = ({ theme }: StylesOverridesProps) => ({
  "& fieldset": {
    borderRadius: theme.spacing(2),
  },
  "& .MuiOutlinedInput-input": {
    fontSize: theme.spacing(3.5),
    padding: "10px 16px",
  },
  "& .MuiInputBase-multiline": {
    padding: 0,
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: theme.palette.grey["100"],
    borderRadius: theme.spacing(1),
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  "& .Mui-disabled": {
    backgroundColor: theme.palette.grey["20"],

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});

const styleOverrides = {
  root,
};

export { styleOverrides };
