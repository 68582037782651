function formatNumber(value?: number, digits = 0, compact = false): string {
  if (value === undefined) {
    return "";
  }
  return Number(value).toLocaleString("en-US", {
    minimumFractionDigits: digits,
    maximumFractionDigits: compact ? 1 : digits,
    notation: compact ? "compact" : "standard",
    compactDisplay: "short",
  });
}

function formatPercent(value?: number, digits = 2): string {
  if (value === undefined || isNaN(value) || !isFinite(value)) {
    return "0.0%";
  }
  return value.toFixed(digits) + "%";
}

function formatAmount(
  value?: number,
  currency?: string,
  compact = false
): string {
  if (value === undefined) {
    return "";
  }

  if (currency === "") {
    return "N/A";
  }

  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency: currency ?? "USD",
    minimumFractionDigits: compact ? 0 : 2,
    maximumFractionDigits: compact ? 1 : 2,
    notation: compact ? "compact" : "standard",
    compactDisplay: "short",
  });
}

export { formatNumber, formatAmount, formatPercent };
