import { fetchApi } from "@tiny/utils";
import React, { useState, useEffect } from "react";
import { GetSenderDetailsResponse } from "@/types/api/senderDetails";
import { useRouteLoaderData } from "react-router-dom";
import { RootLoader } from "@/types/RootLoader";
import { DomainOption } from "./types";

const useDkim = () => {
  const [domains, setDomains] = useState<DomainOption[]>([]);
  const [dkimSigned, setDkimSigned] = React.useState<boolean>(false);
  const [dkimError, setDkimError] = React.useState<string>();
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;

  const fetchDKIMDomains = async () => {
    const data = await fetchApi<GetSenderDetailsResponse[]>(
      `/accountservice/account/${accountInfo?.id}/sender-details`
    );
    const transformedData = data.reduce((acc, item) => {
      const domain = item.email.split("@")[1] || "";
      const existingDomain = acc.find((d) => d.domain === domain);
      if (!existingDomain) {
        acc.push({
          domain,
          enabled: item.dkimSigned,
        });
      }
      return acc;
    }, [] as DomainOption[]);
    setDomains(transformedData);
  };

  const fetchDKIMStatus = async (domain: string) => {
    setDkimSigned(false);
    setDkimError("");
    try {
      const data = await fetchApi<DomainOption>(
        `/notificationservice/dkim/check?domain=${domain}`,
        {
          method: "GET",
        }
      );
      if (data.enabled) {
        setDkimSigned(true);
      } else {
        try {
          await fetchApi(`/notificationservice/dkim`, {
            method: "POST",
            body: JSON.stringify({
              domain,
              enabled: true,
            }),
          });
        } catch (error: any) {
          setDkimSigned(false);
        }
      }
    } catch (error: any) {
      setDkimError(
        "Your DKIM is not set up with your domain registrar. To learn how to set up, Visit here."
      );
    }
  };
  useEffect(() => {
    fetchDKIMDomains();
  }, []);
  return {
    domains,
    fetchDKIMStatus,
    dkimSigned,
    dkimError,
  };
};

export default useDkim;
