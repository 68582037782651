import React from "react";
import type { SenderDetailsRow } from "@/routes/AccountSettings/SenderDetails/SenderDetails.types";
import { CampaignTemplate } from "@/types/api/template";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchApi } from "@tiny/utils";
import { useConfirmationDialog } from "@/routes/Campaigns/useConfirmationDialog.hook";
import {
  Container,
  Box,
  Typography,
  Button,
  Divider,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import {
  CloseSmall,
  GenerativeAi,
  MoreHorizontal,
} from "@tiny/icons/generated";
import {
  useDialogInvokers,
  useDrawerInvokers,
  useScreenLoaderInvokers,
} from "@tiny/lib";
import { TemplatePreview } from "@tiny/lib/CampaignPreview";
import { ApiError, SenderNotConfirmedError } from "@tiny/utils/errors";
import {
  useLocation,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import SenderDetailsConfirmationDialogContent from "@/components/SenderDetailsConfirmationDialogContent";
import SuccessDialogContent from "@/routes/Campaigns/Calendar/Table/SuccessDialogContent";
import AddCampaignForm from "@/routes/Campaigns/CampaignForm";
import { Campaign } from "@/types/api/campaigns";
import SendTestEmail from "./SendTestEmail";
import { CampaignPreviewProps } from "./types";

const CampaignPreview = ({
  campaignId,
  name,
  regenerate = false,
  campaignStatus,
  onRegenerate,
  onClose,
  html,
  css,
  variant = "default",
  campaignGeneratedBy = "System",
}: CampaignPreviewProps) => {
  const showActionButtons =
    campaignStatus === "ACTIVE" || campaignStatus === "INACTIVE";
  const { showConfirmationDialog } = useConfirmationDialog();
  const { senderDetails: detailsData } = (useRouteLoaderData("main") as {
    senderDetails: SenderDetailsRow;
  }) || {
    senderDetails: {},
  };
  const { hideDrawer, showDrawer } = useDrawerInvokers();
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderInvokers();
  const [template, setTemplate] = React.useState<CampaignTemplate>();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { requestMediumDialog, requestSmallDialog, exitDialog } =
    useDialogInvokers();
  const revalidator = useRevalidator();

  const sm = useMediaQuery("(max-width:600px)");
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [enableSendTest, setEnableSendTest] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRegenerate = (e?: any) => {
    e?.preventDefault();
    const invokeRegenerate = async (activate = false) => {
      if (onRegenerate) onRegenerate();
      setLoading(true);
      await fetchApi(`/campaignservice/campaign/generate/${campaignId}`, {
        method: "put",
      });
      await campaignLoader(activate);
    };

    if (campaignStatus !== "ACTIVE") {
      invokeRegenerate();
    } else {
      showConfirmationDialog(
        "You are trying to regenerate template of an active campaign, regeneration will change the current template with a new one. Are you sure you want to continue?",
        "Regenerate Campaign Template",
        "Continue",
        async () => {
          setLoading(true);
          await toggleActivation(false);
          await invokeRegenerate(true);
        },
        true
      );
    }
  };

  const fetchTemplate = () =>
    fetchApi<CampaignTemplate>(`/campaignservice/campaign-layout/preview`, {
      method: "put",
      body: JSON.stringify({
        campaignId,
      }),
    });

  const onClickEdit = () => {
    const handleEdit = () => {
      handleClose();
      showScreenLoader();
      if (campaignGeneratedBy === "System") {
        navigate(`/main/campaigns/${campaignId || ""} /edit/details`, {
          state: {
            startPath: location.pathname,
          },
        });
      } else {
        navigate(`/main/campaigns/builder?id=${campaignId || ""}`);
      }
    };
    if (sm) {
      return showConfirmationDialog(
        "Editing is not supported in the mobile view.",
        "Cannot Edit",
        "Ok",
        () => {},
        false
      );
    }
    if (!loading) {
      if (campaignStatus !== "ACTIVE") {
        handleEdit();
      } else {
        showConfirmationDialog(
          "You are trying to edit template of an active campaign, editing template of an active campaign will pause that campaign, please ensure you activate the campaign post edit completion.",
          "Edit Campaign Template",
          "Start Editing",
          () => {
            toggleActivation(false, handleEdit);
          },
          true
        );
      }
    }
  };

  const toggleActivation = (status: boolean, callback: () => void = () => {}) =>
    fetchApi(
      `/campaignservice/campaign/${campaignId}/enabled/${status.toString()}`,
      {
        method: "put",
      }
    )
      .then(() => {
        callback();
      })
      .catch((err: ApiError) => {
        if (err instanceof SenderNotConfirmedError) {
          requestMediumDialog({
            titleProps: {},
            contentProps: {
              dividers: false,
              sx: {
                border: "none",
              },
              children: <SenderDetailsConfirmationDialogContent />,
            },
          });
        }
        hideScreenLoader();
      });

  const onClickToggleActivation = () => {
    showScreenLoader();
    const state = campaignStatus !== "ACTIVE";
    const callback = () => {
      revalidator.revalidate();
      hideScreenLoader();
      handleClose();
      requestSmallDialog({
        contentProps: {
          dividers: false,
          sx: {
            border: "none",
          },
          children: (
            <SuccessDialogContent
              title={`Successfully ${state ? "Activated" : "Deactivated"}`}
              description={`Campaign ${state ? "Activated" : "Deactivated"}`}
              onClickHandler={() => {
                exitDialog();
              }}
            />
          ),
        },
      });
    };
    if (campaignStatus === "ACTIVE") toggleActivation(state, callback);
    else toggleActivation(state, callback);
  };

  const handleClose = () => {
    hideDrawer();
    if (onClose) onClose();
  };

  const campaignLoader = async (activate = false) => {
    setLoading(true);
    const templateResponse = await fetchTemplate();
    if (templateResponse.inGenerationState) {
      setTimeout(async () => {
        await campaignLoader(activate);
      }, 300);
      return;
    }
    setLoading(false);
    setTemplate(templateResponse);
    if (activate) {
      await toggleActivation(true);
    }
  };

  React.useEffect(() => {
    if (!html && !css) {
      if (regenerate) {
        handleRegenerate();
      } else {
        campaignLoader();
      }
    } else {
      setLoading(false);
    }
  }, []);

  const onClickSettings = () => {
    showScreenLoader();
    fetchApi<Campaign>(`/campaignservice/campaign/${campaignId}`, {
      method: "get",
    })
      .then((response: any) => {
        if (response.start) response.startTime = response.start;
        hideScreenLoader();
        hideDrawer();
        showDrawer({
          children: <AddCampaignForm editMode formData={response} />,
        });
      })
      .catch(() => {
        hideScreenLoader();
      });
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: sm ? "100vw" : "40vw",
        borderRadius: "20px 0px 0px 0px",
        overflow: "hidden",
      }}
      disableGutters
      maxWidth={false}
    >
      <Box
        p={6}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h2" color="grey.100">
          Preview email
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          gap={4}
        >
          <Chip
            label={name}
            title={name}
            sx={(theme) => ({
              background: theme.palette.grey[30],
              color: theme.palette.grey[90],
              fontWeight: 400,
              fontSize: theme.spacing(3),
              maxWidth: theme.spacing(100),
            })}
          />
          <Box
            sx={(theme) => ({
              border: `0.5px solid ${theme.palette.grey[40]}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: 24,
              height: 24,
            })}
            onClick={handleClose}
          >
            <CloseSmall
              sx={() => ({
                cursor: "pointer",
              })}
            />
          </Box>
        </Box>
      </Box>
      <Box
        px={6}
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TemplatePreview
          html={html || template?.html}
          css={css || template?.css}
          isLoading={loading}
          subject={template?.subjectLine}
          from={`${detailsData.name} <${detailsData.email}>`}
          variant={variant}
        />
      </Box>
      {showActionButtons && (
        <>
          <Divider sx={{ mx: 6 }} />
          <Menu
            id="secondary-menu"
            anchorEl={anchorEl}
            open={open}
            transformOrigin={{ vertical: "bottom", horizontal: "left" }}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            onClose={handleCloseMenu}
            sx={{ mb: 6 }}
            PaperProps={{
              style: {
                marginTop: -12,
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleCloseMenu();
                setEnableSendTest(true);
              }}
            >
              <Typography variant="p2">Send Test</Typography>
            </MenuItem>
            <MenuItem onClick={onClickEdit}>
              <Typography variant="p2">Edit</Typography>
            </MenuItem>
            {campaignGeneratedBy === "System" && (
              <MenuItem onClick={onClickSettings}>
                <Typography variant="p2">Settings</Typography>
              </MenuItem>
            )}
          </Menu>

          <Box
            p={6}
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mr: theme.spacing(16),
            })}
          >
            <Box>
              <Button
                sx={{ px: 4, py: { xl: 2.5, sm: 1.5 }, pr: 3 }}
                variant="outlined"
                endIcon={<MoreHorizontal />}
                onClick={(e) => {
                  if (!loading) handleOpenMenu(e);
                }}
                disabled={loading}
              >
                More
              </Button>
              <SendTestEmail
                open={enableSendTest}
                setOpen={setEnableSendTest}
                campaignId={campaignId}
              />
            </Box>

            <Box gap={3} display="flex">
              {campaignGeneratedBy === "System" && (
                <Button
                  id="campaigns-overview-preview-regenerate"
                  variant="outlined"
                  className="regenerate-cta"
                  disabled={loading}
                  type="submit"
                  sx={{ py: { xl: 2, sm: 1.5 } }}
                  onClick={handleRegenerate}
                  startIcon={<GenerativeAi sx={{ marginRight: -1.5 }} />}
                >
                  Regenerate
                </Button>
              )}
              <Button
                id={
                  campaignStatus === "ACTIVE"
                    ? "campaigns-preview-deactivate-campaign"
                    : "campaigns-preview-activate-campaign"
                }
                variant="contained"
                className="activate-close"
                onClick={onClickToggleActivation}
                sx={{ px: 5, py: { xl: 2.5, sm: 1.5 } }}
                disabled={loading}
              >
                {campaignStatus !== "ACTIVE" ? "Activate" : "Deactivate"}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default CampaignPreview;
