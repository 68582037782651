import React from "react";
import { Outlet } from "react-router-dom";
import PageTabs from "@/components/PageTabs";
import PageTitle from "@/components/PageTitle";
import { accountTabs } from "./helpers";

function AccountSettings() {
  return (
    <>
      <PageTitle title="My account" />
      <PageTabs tabs={accountTabs} />
      <Outlet />
    </>
  );
}

export { AccountSettings };
export * from "./Profile";
export * from "./AiTuning";
export * from "./Plans";
export * from "./SenderDetails";
export * from "./DKIM";
