import React from "react";

import {
  DialogProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TimePicker } from "@mui/x-date-pickers";
import DayOfWeekSelector from "@/components/DayOfWeekSelector";
import { Campaign } from "@/types/api/campaigns";
import { add } from "date-fns";
import { Controller, FormProvider } from "react-hook-form";
import { useRecurringDialog } from "./RecurringDialog.hooks";

interface RecurringScheduleDialogProps extends DialogProps {
  campaign: Campaign;
  onClose: () => void;
}

function RecurringDialog(props: RecurringScheduleDialogProps) {
  const { open, onClose, campaign } = props;

  const { handleSchedule, form, resetDialog, scheduling } = useRecurringDialog({
    campaign,
    onClose,
  });

  const { control } = form;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      TransitionProps={{ onExit: resetDialog }}
    >
      <DialogTitle>Recurring Campaign</DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <FormProvider {...form}>
          <form>
            <Box sx={{ display: "flex", gap: 1, mt: 5 }}>
              <Controller
                name="time"
                control={control}
                defaultValue={add(new Date(), { days: 0 })}
                render={({ field: { onChange, value } }) => (
                  <TimePicker
                    label="Time"
                    onChange={onChange}
                    value={value}
                    renderInput={(prop) => (
                      <TextField fullWidth size="small" {...prop} />
                    )}
                  />
                )}
              />
            </Box>
            <Box my={4}>
              <Controller
                name="days"
                control={control}
                render={({ field }) => (
                  <DayOfWeekSelector
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </Box>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          disabled={scheduling}
          loading={scheduling}
          onClick={handleSchedule}
        >
          Schedule
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default RecurringDialog;
