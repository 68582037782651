import { Box, Typography, Backdrop, CircularProgress } from "@mui/material";
import { HtmlPreview } from "../HtmlPreview";
import { HtmlPreviewProps } from "../HtmlPreview/types";

function MobilePreview(props: HtmlPreviewProps) {
  const { width, height, from, subject, isLoading } = props;
  return (
    <Box
      id="mobile-viewport"
      position="relative"
      overflow="hidden"
      width={width}
      height={height}
      padding={1}
      borderRadius={6}
      sx={{
        borderWidth: 8,
        borderStyle: "solid",
        borderColor: "grey.30",
        "& > *": {
          borderRadius: 3,
        },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        p={3}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderBottom: "0.5px",
        }}
      >
        <Typography variant="p3">
          <Typography
            component="span"
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightBold,
              overflowWrap: "break-word",
            })}
          >
            From:
          </Typography>{" "}
          {from}
        </Typography>
        <Typography variant="p3">
          <Typography
            component="span"
            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          >
            Subject:
          </Typography>{" "}
          {subject}
        </Typography>
      </Box>
      <HtmlPreview {...props} width="100%" height="100%" />
      <Backdrop
        sx={{
          color: "#fff",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 0.1,
        }}
        open={isLoading || false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export { MobilePreview };
