import React from "react";

import StepContainer from "../StepContainer";
import DetailsPanel from "./DetailsPanel";
import { useConfigView } from "../../Config.context";

function DetailsStep(): JSX.Element | null {
  const { config } = useConfigView();
  return (
    <StepContainer>
      <DetailsPanel />
    </StepContainer>
  );
}

export default DetailsStep;
