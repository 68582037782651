import { ActionFunctionArgs } from "react-router-dom";
import { fetchApi } from "@tiny/utils";

type Actions = "regenerate";

async function formAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const action = formData.get("_action") as Actions;
  const id = formData.get("_id") as string;

  switch (action) {
    case "regenerate":
      return regenerate({ id }, request.signal);
    default:
      console.error("no action provided");
  }
}

async function regenerate({ id }: { id: string }, signal: AbortSignal) {
  if (id) {
    return fetchApi(`/formservice/form/${id}/einsteinize`, {
      method: "post",
      signal,
    });
  }
  return "no value";
}

export { formAction };
