import React from "react";

import { Grid, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { TagItemProps } from "./TagItem.types";

function TagItem(props: TagItemProps) {
  const { checked, onChange, label, tag } = props;

  return (
    <Grid item xs={12} md={6}>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.checked, tag!)
            }
            color="primary"
            checked={checked}
            sx={{
              height: 34,
            }}
          />
        }
        label={
          <Typography variant="p2" color="grey">
            {label}
          </Typography>
        }
        sx={{
          mx: 2,
        }}
      />
    </Grid>
  );
}

export default React.memo(TagItem);
