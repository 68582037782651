import React from "react";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";

function PageFooter() {
  return (
    <Box
      component="footer"
      display="flex"
      flexDirection="column"
      gap={1}
      alignItems="center"
      pb={{ xs: 5, md: 0 }}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        {/* <Link */}
        {/*  color="inherit" */}
        {/*  href="https://www.tinyalbert.ai/" */}
        {/*  rel="noreferrer" */}
        {/*  target="_blank" */}
        {/*  underline="hover" */}
        {/* > */}
        {/*  <Typography variant="p3" color="grey.70" textAlign="center"> */}
        {/*    Terms of Use */}
        {/*  </Typography> */}
        {/* </Link> */}

        <Link
          color="inherit"
          href="https://www.tinyalbert.ai/privacy-policy/"
          rel="noreferrer"
          target="_blank"
          underline="hover"
        >
          <Typography variant="p3" color="grey.70">
            Privacy Policy
          </Typography>
        </Link>
      </Stack>
      <Typography variant="p3" color="grey.70" textAlign="center">
        ©{new Date().getFullYear()} All Rights Reserved. tinyAlbert
      </Typography>
    </Box>
  );
}

export default PageFooter;
