const disableAction = (event: Event) => {
  event.stopPropagation();
  event.preventDefault();
  return false;
};

const getInnerDocument = () => {
  const iframe = window?.document
    ?.getElementById("stripoPreviewContainer")
    ?.querySelector("iframe");

  return iframe?.contentDocument || iframe?.contentWindow?.document;
};

const getDisabledElements = (document: Document) =>
  document.querySelectorAll("[data-tiny-disabled]");

const addCaptureListenerToElements = (elements: NodeListOf<Element>) => {
  elements.forEach((element) => {
    element.addEventListener("click", disableAction, true);
    element.addEventListener("dblclick", disableAction, true);
    element.addEventListener("mousedown", disableAction, true);
    element.addEventListener("mouseup", disableAction, true);
    element.addEventListener("mouseover", disableAction, true);
    element.addEventListener("mouseout", disableAction, true);
    element.addEventListener("drop", disableAction, true);
  });
};

const isElementInsideDisabledArea = (event: Event) => {
  const targetElement = event.target as HTMLElement;
  const tinyDisabledParent = targetElement.closest("[data-tiny-disabled]");
  const isDisabled = !!tinyDisabledParent;
  return isDisabled;
};

const blockOriginalListenersOnNodes = () => {
  const innerDoc = getInnerDocument();
  if (innerDoc) {
    const disabledElements = getDisabledElements(innerDoc);
    addCaptureListenerToElements(disabledElements);

    // TODO attempt to disallow dropping blocks from toolbar
    innerDoc.addEventListener("mouseover", isElementInsideDisabledArea, true);
    innerDoc.addEventListener("click", isElementInsideDisabledArea, true);

    // experimenting disable drop - no luck
    disabledElements.forEach((element) => {
      element.querySelectorAll(".esd-structure").forEach((container) => {
        container.classList.remove("esd-structure");
      });
      element.querySelectorAll(".esd-container-frame").forEach((container) => {
        container.classList.remove("esd-container-frame");
      });
      element.querySelectorAll(".es-table-not-adapt").forEach((container) => {
        container.classList.remove("es-table-not-adapt");
      });
      element.querySelectorAll(".esd-block").forEach((container) => {
        container.classList.remove("esd-block");
      });
      element.querySelectorAll("[esd-handler-name]").forEach((container) => {
        container.removeAttribute("esd-handler-name");
      });
      element.querySelectorAll(".ui-droppable").forEach((container) => {
        container.classList.remove("ui-droppable");
      });
    });
  }
};

const blockEventForDisabledArea = (event: Event) => {
  const innerDoc = getInnerDocument();
  if (innerDoc) {
    innerDoc.addEventListener("mouseover", isElementInsideDisabledArea, true);
  }
};
export {
  disableAction,
  blockOriginalListenersOnNodes,
  blockEventForDisabledArea,
};
