import React from "react";

import {
  Tooltip,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { TruncateTooltip, HtmlPreview } from "../../../../components";
import { MoreHorizontal, ShortRight, Star } from "@tiny/icons";
import { TemplateItemProps } from "./TemplateItem.types";
import { useTemplateItem } from "./TemplateItem.hooks";
import useBreakpoints from "../../../../hooks/useBreakpoints";

export default function TemplateItem(props: TemplateItemProps) {
  const {
    css,
    executeMainAction,
    favoritable,
    toggleFavorite,
    favorited,
    hideMore,
    html,
    mainActionText,
    moreOpen,
    name,
    openPreview,
    selectTemplate,
    selectable,
    selected,
    selectDisabled,
    showMore,
    isCurrentSelected,
    selectedActionText,
  } = useTemplateItem(props);

  const { mdUp } = useBreakpoints();

  const favoriteTooltip = favorited
    ? "Remove from favorites"
    : "Mark as Favorite";

  const [hover, setHover] = React.useState(false);

  return React.useMemo(
    () => (
      <>
        <Box
          key={name}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={(theme) => ({
            display: "flex",
            position: "relative",
            padding: "12px 16px",
            [theme.breakpoints.up("md")]: {
              flexDirection: "column",
              padding: 0,
            },
            selected: {
              "&::before": {
                content: "''",
                position: "absolute",
                inset: "-12px -16px 0 -16px",
                background: theme.palette.grey[100],
                pointerEvents: "none",
              },
              "& $children": {
                [theme.breakpoints.up("md")]: {
                  "&::after": {
                    content: "''",
                    position: "absolute",
                    inset: -1,
                    border: `2px solid ${theme.palette.common.black}`,
                    pointerEvents: "none",
                    borderRadius: 4,
                  },
                },
              },
              "& $check": {
                opacity: 1,
              },
              "& $name": {
                fontWeight: "bold",
                [theme.breakpoints.up("md")]: {
                  fontWeight: "normal",
                },
              },
              [theme.breakpoints.up("md")]: {
                "&::before": {
                  content: "none",
                },
              },
            },
          })}
          data-testid="template-preview"
        >
          {selectable && (
            <Box
              sx={(theme) => ({
                display: "flex",
                width: 32,
                paddingRight: 12,
                [theme.breakpoints.up("md")]: {
                  opacity: selected || hover ? 1 : 0,
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: 8,
                  left: 8,
                  width: 32,
                  height: 32,
                  paddingRight: 0,
                  zIndex: 1,
                  overflow: "hidden",
                  borderRadius: 4,
                  background: "transparent",
                  borderWidth: 0,
                },
              })}
            >
              <Checkbox
                color="primary"
                onChange={selectTemplate}
                checked={selected}
                disabled={selectDisabled}
                // disabledTooltip="You can only delete saved templates"
              />
            </Box>
          )}
          <Box
            sx={(theme) => ({
              position: "relative",
              width: 64,
              height: 80,
              border: `1px solid ${
                selected
                  ? theme.palette.common.black
                  : hover
                  ? theme.palette.primary.main
                  : theme.palette.grey[300]
              }`,
              overflow: "hidden",
              borderRadius: 1,
              "& > div": {
                borderRadius: 1,
              },
              [theme.breakpoints.up("md")]: {
                overflow: "initial",
                width: "100%",
                height: 200,
              },
            })}
          >
            <HtmlPreview
              html={html}
              css={css}
              width="100%"
              height="180px"
              contentWidth={800}
            />
          </Box>
          <Box
            sx={(theme) => ({
              flex: 1,
              display: "flex",
              flexDirection: "column",
              paddingLeft: 8,
              paddingRight: 8,
              [theme.breakpoints.up("md")]: {
                minHeight: 40,
                paddingLeft: 0,
                paddingRight: 0,
              },
            })}
          >
            {hover ? (
              <Box
                display="flex"
                sx={(theme) => ({
                  marginTop: 1,
                  "& button": {
                    height: 16,
                  },
                  "& button:first-of-type": {
                    marginRight: 3,
                  },
                  [theme.breakpoints.up("md")]: {
                    marginTop: 2,
                  },
                })}
              >
                <Button
                  variant="text"
                  endIcon={<ShortRight />}
                  onClick={executeMainAction}
                  sx={{
                    fontWeight: "lighter",
                    p: 0,
                  }}
                >
                  {isCurrentSelected ? selectedActionText : mainActionText}
                </Button>
                <Button
                  variant="text"
                  endIcon={<ShortRight />}
                  onClick={openPreview}
                  sx={{
                    fontWeight: "lighter",
                    p: 0,
                  }}
                >
                  Preview
                </Button>
              </Box>
            ) : (
              <TruncateTooltip>
                <Typography
                  sx={(theme) => ({
                    display: "box",
                    overflow: "hidden",
                    position: "relative",
                    textOverflow: "ellipsis",
                    lineClamp: 2,
                    boxOrient: "vertical",
                    fontSize: 16,
                    lineHeight: "20px",
                    fontWeight: "normal",
                    [theme.breakpoints.up("md")]: {
                      marginTop: 2,
                      fontSize: 12,
                      lineHeight: "16px",
                    },
                  })}
                >
                  {name}
                </Typography>
              </TruncateTooltip>
            )}
          </Box>
          <Box
            sx={(theme) => ({
              display: "none",
              marginTop: 4,
              "& button": {
                height: 16,
              },
              "& button:first-of-type": {
                marginRight: 12,
              },
              [theme.breakpoints.up("md")]: {
                marginTop: 8,
              },
            })}
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                  display: "none",
                },
              })}
            >
              <IconButton size="small" onClick={showMore}>
                <MoreHorizontal />
              </IconButton>
            </Box>
            {favoritable && (
              <Box
                sx={(theme) => ({
                  alignSelf: "flex-end",
                  [theme.breakpoints.up("md")]: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: 8,
                    right: 8,
                    width: 32,
                    height: 32,
                    zIndex: 9999,
                    overflow: "hidden",
                    borderRadius: 4,
                    background: "transparent",
                  },
                })}
              >
                <IconButton
                  size="small"
                  sx={(theme) => ({
                    "& svg": {
                      width: 24,
                      height: 24,
                      "& path": {
                        fill: props.favorited
                          ? theme.palette.yellow.dark
                          : theme.palette.common.white,
                        stroke: props.favorited
                          ? theme.palette.yellow.dark
                          : theme.palette.grey[700],
                      },
                    },
                  })}
                  onClick={toggleFavorite}
                >
                  <Star />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>

        {!mdUp && (
          <Dialog open={moreOpen} onClose={hideMore} disableScrollLock>
            <DialogContent sx={{ pt: 4, pb: 8 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  openPreview();
                  hideMore();
                }}
              >
                Preview
              </Button>
            </DialogContent>
          </Dialog>
        )}
      </>
    ),
    [
      selectable,
      selectTemplate,
      selected,
      selectDisabled,
      html,
      css,
      name,
      hover,
      executeMainAction,
      isCurrentSelected,
      selectedActionText,
      mainActionText,
      openPreview,
      showMore,
      favoritable,
      favoriteTooltip,
      toggleFavorite,
      moreOpen,
      hideMore,
    ]
  );
}
