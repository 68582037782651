import React from "react";
import { MenuItem, Select, SelectChangeEvent, SxProps } from "@mui/material";
import { ChevronBigDown } from "@tiny/icons";

export interface FilterOptions {
  key: string;
  label: string;
}

export interface PageFilterProps {
  filterOptions: FilterOptions[];
  value: string;
  onChangeFilter: (newValue: string) => void;
  sx?: SxProps;
}

function PageFilter(props: PageFilterProps) {
  const { filterOptions, onChangeFilter, value, sx } = props;

  return (
    <Select
      labelId="results"
      value={value}
      name="limit"
      IconComponent={ChevronBigDown}
      size="small"
      variant="outlined"
      className="page-filter"
      onChange={(event: SelectChangeEvent<string>) => {
        onChangeFilter(event.target.value);
      }}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      {filterOptions.map((option) => (
        <MenuItem key={option.key} value={option.key}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default PageFilter;
