import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Alert,
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronDown, CloseSmall, ExternalLink } from "@tiny/icons";
import { Link } from "react-router-dom";
import { Campaign } from "@/types/api/campaigns";
import { fetchApi } from "@tiny/utils";
import {
  Content,
  CustomCollection,
  GetCustomCollectionsResponse,
} from "../types";

interface DropDownItem {
  name?: string;
  section: string;
  collectionId: string;
}

function EmailContent({ formData }: { formData?: Campaign }) {
  const hasContent = formData?.contents
    ? formData?.contents?.length > 0
    : false;

  const [dropdownList, setDropdownList] = useState<DropDownItem[]>([]);
  const [content, setContent] = useState<DropDownItem[]>([]);
  const [showEmailContent, setShowEmailContent] = useState<boolean>(hasContent);
  const { setValue } = useFormContext();
  const einsteinTheme = useTheme();

  useEffect(() => {
    fetchCampaignCollections();
  }, []);

  const fetchCampaignCollections = () => {
    fetchApi<GetCustomCollectionsResponse>(
      "/accountservice/custom-collections",
      {
        method: "get",
      }
    )
      .then((response) => {
        const dropDownItems = setDropdownItems(response?.collections || []);
        if (formData?.contents) {
          patchForm(formData?.contents, dropDownItems);
        }
      })
      .catch((error) => {
        console.log("Error fetching collections:", error);
      });
  };

  const setDropdownItems = (customCollections: CustomCollection[]) => {
    const dropdownItems = [
      {
        section: "BEST_SELLERS",
        collectionId: "BEST_SELLING_PRODUCTS",
        name: "Best Sellers",
      },
      {
        section: "NEW_ARRIVALS",
        collectionId: "NEW_ARRIVAL_PRODUCTS",
        name: "New Arrivals",
      },
      ...customCollections.map((ct) => ({
        section: "CUSTOM_COLLECTION",
        collectionId: ct.id.toString(),
        name: ct.title,
      })),
    ];
    setDropdownList(dropdownItems);
    return dropdownItems;
  };

  const patchForm = (contents: Content[], collections: DropDownItem[]) => {
    const idLabelMap = new Map<string, any>(
      collections?.map((page) => [page.collectionId, page.name])
    );
    const result = contents?.map((contentItem) => ({
      section: contentItem.section,
      collectionId: contentItem.collectionId,
      name: idLabelMap.get(contentItem.collectionId) || "",
    }));
    setContent(result);
  };

  return (
    <Box my={5}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" color="grey.100">
          Products in Email
        </Typography>
        <Switch
          id={
            showEmailContent
              ? "campaigns-settings-disable-custom-collection"
              : "campaigns-settings-enable-custom-collection"
          }
          checked={showEmailContent}
          onChange={(event) => {
            if (event?.target?.checked) {
              if (content) {
                setValue("contents", content);
              }
            } else {
              setValue("contents", []);
            }
            setShowEmailContent(event.target.checked);
          }}
        />
      </Box>
      {showEmailContent && (
        <Box my={2}>
          <Alert
            variant="outlined"
            severity="info"
            sx={(theme) => ({
              mb: 5,
              borderColor: `${theme.palette.primary[60] as string} !important`,
              "& .MuiAlert-icon": {
                ml: 2,
              },
            })}
          >
            <Typography variant="p3">
              Choosing product collection will instruct AI to select products
              only from these collections. Select up-to 2 collections.
              <Link
                aria-label="Learn More"
                to="https://support.tinyalbert.ai/portal/en/kb/articles/add-collections-to-campaigns"
                target="_blank"
                rel="noreferrer"
                id="campaign-calendar-close-info-alert-msg"
                style={{
                  textDecoration: "none",
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 2,
                  color: einsteinTheme.palette.primary[125],
                }}
              >
                Learn More <ExternalLink fontSize="small" />
              </Link>
            </Typography>
          </Alert>
          <FormControl fullWidth>
            <Autocomplete
              id="campaigns-settings-select-custom-collections"
              multiple
              options={dropdownList}
              getOptionLabel={(option) => option.name || ""}
              value={content}
              onChange={(event, newValue) => {
                setValue("contents", newValue);
                setContent(newValue);
              }}
              size="small"
              popupIcon={<ChevronDown />}
              limitTags={3}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) =>
                option?.collectionId === value?.collectionId
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Product Collections"
                  placeholder="Select Product Collections"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  sx={(theme) => ({
                    "& .MuiOutlinedInput-input": {
                      paddingTop: `${theme.typography.pxToRem(6)} !important`,
                      paddingBottom: `${theme.typography.pxToRem(
                        6
                      )} !important`,
                    },
                  })}
                  inputProps={{
                    ...params.inputProps,
                    required: content.length === 0,
                  }}
                />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    fontSize: 14,
                  },
                },
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={option.name}
                    sx={(theme) => ({
                      background: theme.palette.grey[30],
                      color: theme.palette.grey[90],
                      fontSize: 12,
                      border: 0,
                      fontWeight: 400,
                    })}
                    variant="outlined"
                    deleteIcon={<CloseSmall />}
                  />
                ))
              }
            />
          </FormControl>
        </Box>
      )}
    </Box>
  );
}

export default EmailContent;
