import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";
import { Campaign } from "@/types/api/campaigns";
import { add } from "date-fns";

function Scheduled({ formData }: { formData?: Campaign }) {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (formData?.startTime) {
      setValue("scheduledDate", new Date(formData?.startTime));
      setValue("time", new Date(formData?.startTime));
    }
  }, [formData?.startTime]);

  return (
    <Box my={5}>
      <Typography variant="h4" color="grey.100">
        Schedule
      </Typography>
      <Box display="flex" gap={4} mt={5}>
        <Controller
          name="scheduledDate"
          control={control}
          defaultValue={add(new Date(), { days: 1 })}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              label="Scheduled Date"
              renderInput={(props) => (
                <TextField fullWidth size="small" {...props} />
              )}
              minDate={add(new Date(), { days: 1 })}
              onChange={onChange}
              value={value}
              disablePast
            />
          )}
        />

        <Controller
          name="time"
          control={control}
          defaultValue={add(new Date(), { days: 1 })}
          render={({ field: { onChange, value } }) => (
            <TimePicker
              label="Time"
              onChange={onChange}
              value={value}
              renderInput={(props) => (
                <TextField fullWidth size="small" {...props} />
              )}
            />
          )}
        />
      </Box>
    </Box>
  );
}

export default Scheduled;
