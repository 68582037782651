import { v4 as uuid } from "uuid";
import { metricsInfoMessages } from "@/components/MetricCard/helpers";
import { GetReportOverviewResponse } from "@/types/api/reportsOverview";
import Revenue from "@/assets/illustrations/revenue.svg";
import GeneratedRevenue from "@/assets/illustrations/generated-rev.svg";
import GeneratedConversion from "@/assets/illustrations/generated-con.svg";
import Sent from "@/assets/illustrations/sent.svg";
import Opens from "@/assets/illustrations/opens.svg";
import Clicks from "@/assets/illustrations/clicks.svg";
import { formatNumber, formatAmount } from "@tiny/utils/formatUtils";
import { GetAccountResponse } from "@/types/api/account";
import { InfoMessageGroup, Metric } from "./types";

function totalMapper(
  input: number,
  messageGroup: InfoMessageGroup,
  currency: string,
  exportHandler: () => void,
  ctaID?: string
): Metric {
  return {
    title: "Total Revenue",
    value: typeof input === "undefined" ? "N/A" : formatAmount(input, currency),
    image: Revenue,
    id: uuid(),
    infoMessage: metricsInfoMessages[messageGroup].totalRevenueInfoMessage,
    seeReportsHandler: exportHandler,
    ctaID,
  };
}

function generatedRevenueMapper(
  input: number,
  rate: number,
  messageGroup: InfoMessageGroup,
  currency: string,
  exportHandler: () => void,
  ctaID?: string
): Metric {
  return {
    title: "Generated Revenue",
    shortTitle: "Gen.Revenue",
    value: typeof input === "undefined" ? "N/A" : formatAmount(input, currency),
    image: GeneratedRevenue,
    id: uuid(),
    rate,
    infoMessage: metricsInfoMessages[messageGroup].generatedRevenueInfoMessage,
    seeReportsHandler: exportHandler,
    ctaID,
  };
}

function generatedConversionMapper(
  input: number,
  messageGroup: InfoMessageGroup,
  exportHandler: () => void,
  ctaID?: string
): Metric {
  return {
    title: "Generated Conversion",
    shortTitle: "Gen.Conversion",
    value: typeof input === "undefined" ? "N/A" : formatNumber(input),
    image: GeneratedConversion,
    id: uuid(),
    infoMessage: metricsInfoMessages[messageGroup].generatedConversionMessage,
    seeReportsHandler: exportHandler,
    ctaID,
  };
}

function sentMapper(input: number, messageGroup: InfoMessageGroup): Metric {
  return {
    title: "Email sent",
    value: typeof input === "undefined" ? "N/A" : formatNumber(input),
    image: Sent,
    id: uuid(),
    infoMessage: metricsInfoMessages[messageGroup].emailSentInfoMessage,
  };
}

function openMapper(input: number, messageGroup: InfoMessageGroup): Metric {
  return {
    title: "Email Opens",
    value: typeof input === "undefined" ? "N/A" : formatNumber(input),
    image: Opens,
    id: uuid(),
    infoMessage: metricsInfoMessages[messageGroup].emailOpensInfoMessage,
  };
}

function clickedMapper(input: number, messageGroup: InfoMessageGroup): Metric {
  return {
    title: "Email clicks",
    value: typeof input === "undefined" ? "N/A" : formatNumber(input),
    image: Clicks,
    id: uuid(),
    infoMessage: metricsInfoMessages[messageGroup].emailClicksInfoMessage,
  };
}

// TODO: Fix the forbidden non-null assertion
export function metricMapper(
  src: GetReportOverviewResponse,
  messageGroup: InfoMessageGroup,
  settings: GetAccountResponse,
  exportHandler?: () => void,
  ctaID?: string
): Metric[] {
  const {
    totalRevenue,
    generatedRevenueRate,
    generatedRevenue,
    generatedConversion,
    sent,
    open,
    clicked,
  } = src;
  const { currency } = settings;

  return [
    totalMapper(totalRevenue, messageGroup, currency, exportHandler!),
    generatedRevenueMapper(
      generatedRevenue,
      generatedRevenueRate!,
      messageGroup,
      currency,
      exportHandler!,
      ctaID
    ),
    generatedConversionMapper(
      generatedConversion,
      messageGroup,
      exportHandler!,
      ctaID
    ),
    sentMapper(sent, messageGroup),
    openMapper(open, messageGroup),
    clickedMapper(clicked, messageGroup),
  ];
}

function automationEnabledMapper(
  input: number,
  messageGroup: InfoMessageGroup
): Metric {
  return {
    title: "Active automations",
    value: `${input}`,
    image: Revenue,
    id: uuid(),
    infoMessage: metricsInfoMessages[messageGroup].totalRevenueInfoMessage,
  };
}

export function metricCountMapper(
  count: number,
  messageGroup: InfoMessageGroup
): Metric {
  return automationEnabledMapper(count, messageGroup);
}

function campaignSentMapper(
  input: number,
  messageGroup: InfoMessageGroup,
  ctaID?: string
): Metric {
  return {
    title: "Campaign sent",
    value: `${input}`,
    image: Revenue,
    id: uuid(),
    infoMessage: metricsInfoMessages[messageGroup].totalRevenueInfoMessage,
    ctaID,
  };
}

export function metricCampaignCountMapper(
  count: number,
  messageGroup: InfoMessageGroup,
  ctaID?: string
): Metric {
  return campaignSentMapper(count, messageGroup, ctaID);
}
