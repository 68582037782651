import { Audience } from "@/types/api/audience";
import { Button, Theme } from "@mui/material";
import { ChevronRight } from "@tiny/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

function ViewSubscribersCTA({ tableRowData }: { tableRowData: Audience }) {
  const navigate = useNavigate();
  const navigateToSubscribers = () => {
    const { id, name } = tableRowData;
    navigate(`${name}/subscribers?audienceId=${id}`);
  };

  return (
    <Button
      onClick={navigateToSubscribers}
      sx={(theme: Theme) => ({
        p: 0,
        fontWeight: theme.typography.fontWeightRegular,
        position: "absolute",
        right: theme.spacing(4),
        top: "50%",
        transform: "translateY(-50%)",
        whiteSpace: "nowrap",
      })}
      endIcon={<ChevronRight />}
      variant="text"
      id="audience-list-see-results"
    >
      See results
    </Button>
  );
}

export { ViewSubscribersCTA };
