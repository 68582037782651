import MetricCard from "@/components/MetricCard";
import { Metric } from "@/components/MetricCard/types";
import React from "react";
import { Grid } from "@mui/material";
import Users from "@/assets/illustrations/users.svg";

function SubscribersMetrics({
  subscribersCount,
}: {
  subscribersCount: number;
}) {
  const metric: Metric = {
    title: "Subscribers",
    value: subscribersCount?.toString() ?? "N/A",
    image: Users,
    infoMessage: "Total number of customers for this audience.",
  };

  return (
    <Grid item xs={12} md={12} key={metric.id} className="subscribers-metric">
      <MetricCard metric={metric} />
    </Grid>
  );
}

export { SubscribersMetrics };
