import React from "react";
import { useForm } from "react-hook-form";
import {
  useGetCampaignByIdQuery,
  usePutCampaignTestEmailsByIdMutation,
} from "../../app/useGeneratedApi";
import { extractBackendError, getErrorMessage } from "../../utils/errorUtils";
import { SendTestEmailDialogProps } from "./SendTestEmailDialog.types";
import { useCampaignPageView } from "../../pages/CampaignPage.context";

const CLOSE_AFTER_SEND_TIMEOUT = 4000;

export function useSendTestEmailDialog(props: SendTestEmailDialogProps) {
  const { activityId, campaignId, onClose } = props;
  const { templateCss, templateHtml, details } = useCampaignPageView();

  const form = useForm<{ emails: string }>({
    defaultValues: {
      emails: "",
    },
  });

  const [emailsSent, setEmailsSent] = React.useState(false);

  const [sending, setSending] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState<string>();

  const timeoutRef = React.useRef<number>();

  const { data: campaign, refetch: refetchCampaign } = useGetCampaignByIdQuery(
    campaignId!
  );

  const { mutateAsync: putCampaignTestEmailsMutateAsync } =
    usePutCampaignTestEmailsByIdMutation({
      onError(e: any) {
        extractBackendError(e.response).then((be) =>
          setErrorMessage(getErrorMessage(be))
        );
      },
    });
  // TODO: update logic below
  const remainedTestEmails = campaign?.remainedTestEmails || 5;

  const clearErrorMessage = React.useCallback(() => {
    setErrorMessage(undefined);
  }, []);

  const handleExited = React.useCallback(() => {
    form.reset();
    setEmailsSent(false);
    setSending(false);
    setErrorMessage(undefined);
  }, [form]);

  const close = React.useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    onClose();
  }, [onClose]);

  const sendTestEmail = React.useCallback(async () => {
    const valid = await form.trigger();
    if (!valid) {
      return;
    }
    const emails = form.getValues()?.emails?.split(",");
    setSending(true);
    try {
      await putCampaignTestEmailsMutateAsync({
        data: {
          campaignId: campaignId || "",
          testEmails: emails,
        },
      });
      await refetchCampaign();
      setEmailsSent(true);
      timeoutRef.current = window.setTimeout(() => {
        onClose();
      }, CLOSE_AFTER_SEND_TIMEOUT);
    } finally {
      setSending(false);
    }
  }, [
    campaignId,
    activityId,
    form,
    putCampaignTestEmailsMutateAsync,
    refetchCampaign,
    onClose,
  ]);

  return {
    clearErrorMessage,
    close,
    form,
    emailsSent,
    remainedTestEmails,
    sendTestEmail,
    handleExited,
    sending,
    errorMessage,
  };
}
