import React from "react";

import { TemplateData } from "./template.types";

interface UseMergeAttributesTemplateResult {
  templateHtml?: string;
  templateCss?: string;
}

export function useMergeAttributesTemplate(
  html?: string,
  css?: string,
  templateData?: TemplateData,
  renderWithEjs?: boolean
): UseMergeAttributesTemplateResult {
  const [result, setResult] = React.useState<UseMergeAttributesTemplateResult>(
    {}
  );

  const render = React.useCallback(async () => {
    if (html) {
      setResult((prev) => ({ ...prev, templateHtml: html }));
    } else {
      setResult((prev) => ({ ...prev, templateHtml: undefined }));
    }
    if (css) {
      setResult((prev) => ({ ...prev, templateCss: css }));
    } else {
      setResult((prev) => ({ ...prev, templateCss: undefined }));
    }
  }, [html, css, templateData, renderWithEjs]);

  React.useEffect(() => {
    render();
  }, [render]);

  return result;
}
