import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <g clipPath="url(#clip0_14319_129873)" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 6.5L13 0L6.5 6.5L7.207 7.207L12.5 1.914L12.5 24H13.5L13.5 1.914L18.793 7.207L19.5 6.5Z"
        fill="#0F1526"
      />
    </g>
    <path
      d="M19.5 6.5L13 0L6.5 6.5L7.207 7.207L12.5 1.914L12.5 24H13.5L13.5 1.914L18.793 7.207L19.5 6.5Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <defs xmlns="http://www.w3.org/2000/svg">
      <clipPath id="clip0_14319_129873">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
    <clipPath id="clip0_14319_129873" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="white" />
    </clipPath>
    <rect width="24" height="24" xmlns="http://www.w3.org/2000/svg" />
  </g>,
  "ThinBigUp"
);
