import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useNavigation, useSubmit } from "react-router-dom";
import { useDialogInvokers } from "@tiny/lib";
import { ActivateFormConfirmationDialog } from "@/components/ContentSetupStrategy/Dialogs/ActivateFormConfirmationDialog";
import { FormProps } from "./types";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";

function Form(props: FormProps) {
  const {
    id,
    active = false,
    name,
    description,
    infoDetails,
    payLoad,
    formStatus,
    activeForms,
  } = props;
  const { requestSmallDialog } = useDialogInvokers();

  const [state, setState] = React.useState(active);
  const submit = useSubmit();
  const navigation = useNavigation();

  const onChangeActivation = React.useCallback(
    (isActivated: boolean) => {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("isActivated", isActivated.toString());
      formData.append("_action", "enableForm");
      if (navigation.state === "idle") {
        if (!isActivated || activeForms.length === 0) {
          setState(isActivated);
          submit(formData, {
            method: "put",
            action: "/main/forms",
          });
          return;
        }
        requestSmallDialog({
          titleProps: { children: "Please confirm", variant: "h3" },
          contentProps: {
            children: (
              <ActivateFormConfirmationDialog
                confirmClickHandler={() => {
                  setState(isActivated);
                }}
                formData={formData}
                activeForms={activeForms}
              />
            ),
          },
        });
      }
    },
    [id, navigation.state, activeForms, requestSmallDialog, submit]
  );

  React.useEffect(() => {
    setState(active);
  }, [active]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        bgcolor: state ? "common.white" : "grey.20",
        boxSizing: "border-box",
        p: 5,
        borderWidth: "0.5px",
        borderStyle: "solid",
        borderColor: "grey.70",
        borderRadius: 2,
        height: "100%",
        position: "relative",
      }}
      className="form-card"
    >
      <FormHeader
        active={state}
        name={name}
        infoDetails={infoDetails}
        id={id}
      />
      <Typography
        variant="p2"
        color="grey.80"
        mt={{ xs: 2, md: 3 }}
        mb={2}
        flex="1"
        className="form-description"
      >
        {description}
      </Typography>
      <FormFooter
        id={id}
        formStatus={formStatus}
        onChangeActivation={onChangeActivation}
        active={state}
        payLoad={payLoad}
      />
    </Box>
  );
}

export default Form;
