import React from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ShortRight } from "@tiny/icons";
import { useIsSmallerThen } from "@tiny/theme/utils";
import {
  GetAccountSubscriptionResponse,
  PlanCodes,
} from "@/types/api/accountSubscription";
import { getKarmaLevel } from "@/hooks/plans.hooks";
import { PlanLogos } from "../PlanSelection/PlanLogo";

function PlanInfo(props: { collapsed?: boolean }) {
  const { collapsed } = props;
  const theme = useTheme();
  const isMdDown = useIsSmallerThen("sm");

  const data = useLoaderData() as { planInfo: GetAccountSubscriptionResponse };
  const { planName, planCode, trial, subscriptionsCount, price } =
    data.planInfo;

  const navigate = useNavigate();
  const navigateToContacts = () => {
    navigate(`account-settings/plans`);
  };

  return isMdDown || !collapsed ? (
    <Box bgcolor={theme.palette.grey[20]} borderRadius={2}>
      <Box display="flex" gap={1} pt={3} px={4} justifyContent="space-between">
        <Typography variant="label1">{planName}</Typography>
        <PlanLogos karmaLevel={getKarmaLevel(planCode as PlanCodes)} />
      </Box>
      {/* TODO: Add a method that will dynamically add currency */}
      <Typography variant="p3" color="grey.80" pb={2} px={4}>
        ${price} / {subscriptionsCount} customers
      </Typography>
      <Divider sx={{ border: `4px solid ${theme.palette.grey[30]}` }} />
      <Box
        pb={3}
        px={4}
        pt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {trial && <Chip label="Trial" size="small" />}
        <Button
          endIcon={<ShortRight />}
          sx={{ p: 0 }}
          onClick={navigateToContacts}
          id="sidebar-plan-upgrade"
        >
          Upgrade
        </Button>
      </Box>
    </Box>
  ) : (
    <PlanLogos karmaLevel={getKarmaLevel(planCode as PlanCodes)} />
  );
}

export default PlanInfo;
