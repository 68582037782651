import createAuthRefreshInterceptor from "axios-auth-refresh";
import { AxiosInstance } from "axios";

interface ApiClient {
  instance: AxiosInstance;
  silentReAuth: () => Promise<any>;
}

function applyAxiosUnauthorizedHandler(apiClient: ApiClient) {
  createAuthRefreshInterceptor(apiClient.instance, (failedRequest) => {
    return apiClient
      .silentReAuth()
      .then((authData: any) => {
        failedRequest.response.config.headers[
          "Authorization"
        ] = `Bearer ${authData.access_token}`;
        return Promise.resolve();
      })
      .catch(() => Promise.resolve());
  });
}

export { applyAxiosUnauthorizedHandler };
