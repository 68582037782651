import React from "react";
import { Campaign } from "@/types/api/campaigns";
import { Box } from "@mui/material";
import {
  Condition,
  useCampaignMenu,
} from "@/components/Campaign/CampaignMenu/hooks";
import ActionMenu from "@/components/ActionMenu";

function CampaignActionCTA({ tableRowData }: { tableRowData: Campaign }) {
  const menuConditionsOverride: Condition[] = [
    {
      conditionName: "Information",
      condition: tableRowData.status === "COMPLETED",
    },
    {
      conditionName: "Regenerate",
      condition: false,
    },
  ];
  const { campaignMenu } = useCampaignMenu(
    tableRowData || {},
    menuConditionsOverride
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={(theme) => ({ maxHeight: theme.spacing(10) })}
      id="campaign-calendar-campaign-action-menu"
    >
      <ActionMenu menuActions={campaignMenu} sx={{ position: "relative" }} />
    </Box>
  );
}

export { CampaignActionCTA };
