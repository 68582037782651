import React from "react";
import { Box, Button, Card, Typography, useTheme } from "@mui/material";
import { ExternalLink } from "@tiny/icons";
import useEmailTemplateLayoutActionMenu from "@/components/EmailTemplateLayout/useEmailTemplateLayoutActionMenu";
import ActionMenu from "@/components/ActionMenu";
import { HtmlPreview } from "@tiny/lib/ContentPreview";
import { useDrawerInvokers } from "@tiny/lib";
import CampaignPreview from "../Campaign/CampaignPreview";

interface EmailTemplateLayoutProps {
  name: string;
  html: string;
  css?: string | undefined;
  isSelected?: boolean;
  info: string;
}

function EmailTemplateLayout(props: EmailTemplateLayoutProps) {
  const { name, html, css, isSelected = false, info } = props;
  const theme = useTheme();
  const { emailLayoutTemplateMenu } = useEmailTemplateLayoutActionMenu(info);
  const { showDrawer } = useDrawerInvokers();
  const handlePreview = () => {
    showDrawer({
      children: (
        <CampaignPreview
          name={name}
          campaignId="main"
          campaignStatus="COMPLETED"
          regenerate={false}
          onClose={() => {}}
          html={html}
          css={css}
          variant="long"
        />
      ),
      onClose: () => {},
    });
  };
  return (
    <Card
      sx={[
        {
          bgcolor: "common.white",
          display: "flex",
          flexDirection: "column",
          border: `1px solid ${theme.palette.grey[60]}`,
          borderRadius: 2,
          height: "100%",
          boxShadow: "none",
          cursor: "pointer",
        },
        isSelected && {
          border: `2px solid ${theme.palette.primary.dark}`,
          boxShadow: "0 2px 10px rgb(0 0 0 / 0.1);",
        },
      ]}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <ActionMenu
          menuActions={emailLayoutTemplateMenu}
          withBackgroundAndBorderStyles
        />
        <HtmlPreview
          width="100%"
          height="40.5rem"
          contentWidth={50}
          html={html}
          css={css}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
          }}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        p={4}
        gap={2}
        sx={{
          backgroundColor: "grey.10",
          borderTop: `1px solid ${theme.palette.grey[60]}`,
        }}
      >
        <Typography variant="h4" color="grey.100">
          {name}
        </Typography>
        <Button
          variant="text"
          endIcon={<ExternalLink />}
          sx={{ alignSelf: "flex-start", p: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            handlePreview();
          }}
          id="myaccount-aituning-preview-layout"
        >
          Preview
        </Button>
      </Box>
    </Card>
  );
}

export default EmailTemplateLayout;
