import React from "react";
import { useForm } from "react-hook-form";
import { fetchApi } from "@tiny/utils";
import { useToast } from "@/components/ToastService/useToast";
import { Campaign } from "@/types/api/campaigns";
import { ScheduleForm } from "./RecurringDialog.types";

export function useRecurringDialog({
  campaign,
  onClose,
}: {
  campaign: Campaign;
  onClose: () => void;
}) {
  const { days, time, id } = campaign;
  const form = useForm<ScheduleForm>({
    defaultValues: {
      days: days || [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ],
      time: (time && new Date(`1970-01-01T${time}Z`)) || new Date(),
    },
  });
  const { showToast } = useToast();

  const { clearErrors, reset, setError, trigger } = form;

  const [scheduling, setScheduling] = React.useState(false);

  const onChange = React.useCallback(
    (field?: keyof ScheduleForm) => {
      clearErrors(field);
    },
    [clearErrors]
  );

  const resetDialog = React.useCallback(() => {
    reset();
  }, [reset]);

  const handleSchedule = React.useCallback(async () => {
    const valid = await trigger();
    if (!valid) {
      return;
    }

    const localTime = form.getValues().time;

    const formattedUtcTime = formatUTCDate(localTime);

    setScheduling(true);
    try {
      await fetchApi(`/campaignservice/campaign/${id}/start`, {
        method: "PUT",
        body: JSON.stringify({
          isScheduled: true,
          days: form.getValues().days,
          time: formattedUtcTime,
        }),
      });
      setScheduling(false);
      onClose();
    } catch (error: any) {
      setScheduling(false);
      showToast({
        message: error.message,
        type: "error",
        duration: 3000,
      });
    }
  }, [form, setError, trigger]);

  function formatUTCDate(date: Date) {
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  return {
    handleSchedule,
    form,
    onChange,
    resetDialog,
    scheduling,
  };
}
