import React from "react";
import { useForm } from "react-hook-form";
import { useCampaignPageView } from "../../CampaignPage.context";
import { CampaignCreateRequestView } from "../../../pages/CampaignPage.types";
import { useConfigView } from "../../../Config.context";

export function useDetailsPanel() {
  const {
    saveDetails,
    setDetailsCompleted,
    details,
    isCampaignCreated,
    notifyRequireSave,
    requireSave,
  } = useCampaignPageView();

  const { config } = useConfigView();

  const { detailsOpen } = useDetailsPanelParams();

  const [editing, setEditing] = React.useState(false);

  const form = useForm<CampaignCreateRequestView>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const requiredFields = form.watch(["name", "subject"]);

  const saveDisabled = React.useMemo(() => {
    return (
      editing &&
      requiredFields.filter((v) => !!v).length != requiredFields.length
    );
  }, [editing, requiredFields]);

  const submitForm = React.useCallback(
    async (values: CampaignCreateRequestView) => {
      await saveDetails(values);
      setEditing(false);
    },
    [saveDetails, setDetailsCompleted]
  );

  const save = React.useCallback(() => {
    form.handleSubmit(submitForm)();
  }, [form, submitForm]);

  const mustSave = React.useCallback(() => {
    notifyRequireSave({ name: "saveDetails", fn: save });
  }, [notifyRequireSave, save]);

  const edit = React.useCallback(() => {
    form.reset(details);
    setEditing(true);
  }, [details, form]);

  React.useLayoutEffect(() => {
    if (!isCampaignCreated) {
      form.reset(details);
      setEditing(true);
    }
  }, [details, form, isCampaignCreated]);

  React.useLayoutEffect(() => {
    if (detailsOpen) {
      form.reset(details);
      setEditing(true);
    }
  }, [details, form, detailsOpen]);

  React.useLayoutEffect(() => {
    if (
      editing &&
      (form.formState.isDirty ||
        !form.formState.isValid ||
        form.formState.touchedFields) &&
      !requireSave["saveDetails"]
    ) {
      mustSave();
    }
  }, [
    editing,
    form.formState.isDirty,
    form.formState.isValid,
    form.formState.touchedFields,
    mustSave,
    requireSave,
    saveDetails,
  ]);

  return { details, editing, edit, save, form, submitForm, saveDisabled };
}

interface DetailsPanelParams {
  detailsOpen?: boolean;
}

function useDetailsPanelParams(): DetailsPanelParams {
  const searchParams = new URLSearchParams("");
  const detailsOpenString = searchParams.get("detailsOpen");
  const detailsOpen = detailsOpenString
    ? Boolean(detailsOpenString)
    : undefined;
  return {
    detailsOpen,
  };
}
