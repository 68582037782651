import type {
  SortDirection,
  TableHeaderProps,
} from "@/components/Table/table.types";
import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { UncheckAll, UncheckedCheckbox } from "@tiny/icons";
import SortableHeaderCell from "@/components/Table/TableHeader/SortableHeaderCell";
import { useIsLargerThan } from "@tiny/theme/utils";

const defaultSortDirection: SortDirection = "asc";

function TableHeader<T extends { [key: string]: any }>(
  props: TableHeaderProps<T>
) {
  const {
    headerCells,
    currentSort,
    onSort,
    isAllSelected,
    selectAllHandler,
    freezeEdgeColumns,
    ActionColCTA,
    ActionCTA,
  } = props;
  const [sortColumn, sortDirection] = currentSort.split(",");
  const isMdUp = useIsLargerThan("sm");
  const theme = useTheme();

  const renderSelectAll = () => (
    <>
      <Checkbox
        icon={<UncheckedCheckbox sx={{ color: "common.white" }} />}
        checked={isAllSelected}
        onChange={selectAllHandler}
        checkedIcon={
          <UncheckAll
            sx={{
              color: "common.white",
            }}
          />
        }
      />
      {!isMdUp && <Typography>Select all</Typography>}
    </>
  );

  return (
    <TableHead>
      <TableRow>
        {!isMdUp && selectAllHandler && (
          <TableCell
            sx={{
              display: { xs: "flex", sm: "table-cell" },
              alignItems: "center",
              pl: { sm: 8, sx: 2 },
            }}
            key="select all"
          >
            {renderSelectAll()}
          </TableCell>
        )}
        {isMdUp &&
          headerCells.map((cell, index) => (
            <TableCell
              key={cell.propName as string}
              sx={[
                {
                  width: cell.width ?? "inherit",
                },
                selectAllHandler && index === 0
                  ? {
                      display: { xs: "flex", sm: "table-cell" },
                      alignItems: "center",
                      pl: 8,
                    }
                  : {},
                index === 0 && freezeEdgeColumns && isMdUp
                  ? {
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "common.white",
                      borderBottom: `1px solid ${theme.palette.grey[40]}`,
                      borderRight: `1px solid ${theme.palette.grey[40]}`,
                    }
                  : {},
              ]}
            >
              <Box display="flex" gap={2}>
                {selectAllHandler && index === 0 && renderSelectAll()}
                <SortableHeaderCell
                  propName={cell.propName as string}
                  isSortable={
                    cell?.isSortable === undefined ? true : cell.isSortable
                  } // remove default true once sortable is present all tables
                  label={cell.label}
                  sortDirection={
                    cell.propName === sortColumn
                      ? (sortDirection as SortDirection)
                      : defaultSortDirection
                  }
                  isActive={cell.propName === sortColumn}
                  sortHandlerCallback={onSort}
                />
              </Box>
            </TableCell>
          ))}
        {ActionCTA && isMdUp && (
          <TableCell
            sx={[
              {
                maxWidth: "60px",
                p: 3,
              },
              freezeEdgeColumns && isMdUp
                ? {
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    backgroundColor: "common.white",
                    borderBottom: `1px solid ${theme.palette.grey[40]}`,
                    borderLeft: `1px solid ${theme.palette.grey[40]}`,
                  }
                : {},
            ]}
            align="center"
            key="action"
          >
            {ActionColCTA && ActionColCTA}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
