import React from "react";
import { Box, Typography } from "@mui/material";
import OverflowTypographyWithTooltip from "@/components/OverflowTooltip";

function MetricInfoValue({ value, rate }: { value: string; rate?: number }) {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <OverflowTypographyWithTooltip
        variant="h2"
        color="grey.100"
        text={value}
        value={value}
      />

      {rate! >= 0 && (
        <Typography variant="h4" color="grey.80">
          ({rate}%)
        </Typography>
      )}
    </Box>
  );
}

export default MetricInfoValue;
