import { InfoTooltipVariants } from "./types";

export function variantBasedBackgroundColor(variant: InfoTooltipVariants) {
  switch (variant) {
    case "black":
      return "common.black";
    case "purple":
      return "primary.100";
    default:
      return "common.wihte";
  }
}

export function variantBasedIconColor(variant: InfoTooltipVariants) {
  switch (variant) {
    case "white":
      return "common.black";
    default:
      return "grey.0";
  }
}

export function variantBasedTextColor(variant: InfoTooltipVariants) {
  switch (variant) {
    case "white":
      return "grey.100";
    default:
      return "grey.0";
  }
}

export function variantBasedButtonColor(variant: InfoTooltipVariants) {
  switch (variant) {
    case "white":
      return "primary.100";
    default:
      return "primary.50";
  }
}
