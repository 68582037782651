import { computeNotificationsUpdates } from "@/components/Notifications/utils";
import { usePrevious } from "@tiny/utils/react/usePrevious";
import { useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { CustomEvents } from "@/types/Document";
import {
  GetNotificationResponse,
  RuntimeNotificationMessage,
} from "@/types/api/notifications";
import * as SilencedNotifications from "./silencedNotifications";

function useNotificationsService({
  issuePresenceHandler,
}: {
  issuePresenceHandler: (isPresent: boolean) => void;
}) {
  const {
    notifications: { accountIssues },
  } = useLoaderData() as { notifications: GetNotificationResponse };

  useEffect(() => {
    const newNotificationListener = (
      event: CustomEvent<RuntimeNotificationMessage>
    ) => {
      const notificationDetails = event.detail;
      showNewNotification(notificationDetails);
    };
    document.addEventListener(
      CustomEvents.RequestNotification,
      newNotificationListener
    );
    return () =>
      document.removeEventListener(
        CustomEvents.RequestNotification,
        newNotificationListener
      );
  }, []);

  const [displayedNotifications, setDisplayedNotifications] =
    useState(accountIssues);

  const newLoadedNotifications = computeNotificationsUpdates({
    loaded: accountIssues,
    displayed: displayedNotifications,
    silenced: SilencedNotifications.list(),
  });

  if (newLoadedNotifications.hasChanged) {
    const { news, staleIds } = newLoadedNotifications;
    setDisplayedNotifications((previousNotifications) => [
      ...news,
      ...previousNotifications.filter(
        (notification) => !staleIds.includes(notification.id)
      ),
    ]);
  }

  const previouslyDisplayedCount = usePrevious(displayedNotifications.length);

  useEffect(() => {
    if (displayedNotifications.length === 0 && previouslyDisplayedCount > 0) {
      issuePresenceHandler(false);
    }
    if (displayedNotifications.length > 0 && previouslyDisplayedCount === 0) {
      issuePresenceHandler(true);
    }
  }, [displayedNotifications, issuePresenceHandler, previouslyDisplayedCount]);

  function silenceNotification(id: string) {
    SilencedNotifications.add(id);
    setDisplayedNotifications((currentNotifications) =>
      currentNotifications.filter((notification) => notification.id !== id)
    );
  }

  function showNewNotification(notification: RuntimeNotificationMessage) {
    const id = uuid();
    setDisplayedNotifications((currentNotifications) => [
      { id, ...notification },
      ...currentNotifications,
    ]);
  }

  return {
    accountIssues: displayedNotifications,
    silenceNotification,
  };
}

export default useNotificationsService;
