import * as React from "react";
import { useScreenLoaderManagerState } from "./ScreenLoaderManagerState.hooks";
import { ScreenLoaderManagerProvider } from "./ScreenLoaderManager.context";
import { ScreenLoaderManager } from "./ScreenLoaderManager";

function TinyScreenLoaderProvider({ children }: { children: React.ReactNode }) {
  const screenLoaderManagerContextValue = useScreenLoaderManagerState();
  return (
    <ScreenLoaderManagerProvider value={screenLoaderManagerContextValue}>
      <ScreenLoaderManager />
      {children}
    </ScreenLoaderManagerProvider>
  );
}

export { TinyScreenLoaderProvider };
