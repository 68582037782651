export const campaignTabs = [
  {
    label: "Statistics",
    route: `statistics`,
    id: "campaigns-tab-statistics",
  },
  {
    label: "Past",
    route: `past-campaigns`,
    id: "campaigns-tab-future-campaigns",
  },
  {
    label: "Draft",
    route: `draft-campaigns`,
    id: "campaigns-tab-draft-campaigns",
  },
  {
    label: "Future",
    route: `future-campaigns`,
    id: "campaigns-tab-past-campaigns",
  },
  {
    label: "Recurring",
    route: `recurring-campaigns`,
    id: "campaigns-tab-recurring-campaigns",
  },
];
