import React, { forwardRef, useRef } from "react";
import {
  SnackbarProvider as NotistackProvider,
  CustomContentProps,
  closeSnackbar,
  MaterialDesignContent,
} from "notistack";
import { Upload, Check, Error, CloseSmall } from "@tiny/icons";
import { Box, LinearProgress, ThemeProvider } from "@mui/material";
import "./snackbar.css";
import { createTinyTheme } from "@tiny/theme";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

declare module "notistack" {
  interface VariantOverrides {
    uploading: true;
    uploadSuccess: true;
    uploadFailed: true;
    loading: true;
  }
  interface OptionsObject {
    description?: string;
    onClick?: VoidFunction;
    onClose?: VoidFunction;
  }
}

interface CustomSnackBarProps extends CustomContentProps {
  description: string;
  onClick: VoidFunction;
}
const SnackbarComponent = forwardRef<HTMLDivElement, CustomSnackBarProps>(
  (props, ref) => {
    const { onClick, variant } = props;
    const [progress, setProgress] = React.useState(0);
    const einsteinTheme = createTinyTheme();

    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 90);
        });
      }, 1000);

      return () => {
        setProgress(100);
        clearInterval(timer);
      };
    }, []);

    return (
      <ThemeProvider theme={einsteinTheme}>
        <Box
          style={{
            paddingBottom: "20px",
          }}
          ref={ref}
          onClick={() => onClick?.()}
        >
          <MaterialDesignContent {...props} />
          {variant === "uploading" && (
            <div
              style={{
                marginTop: -15,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <LinearProgress
                variant="buffer"
                valueBuffer={100}
                value={progress}
                sx={(theme) => ({
                  root: {
                    ".MuiLinearProgress-barColorPrimary": {
                      backgroundColor: theme.palette.primary[100],
                      color: theme.palette.primary[100],
                    },
                  },
                })}
                color="primary"
                style={{
                  height: 3,
                  borderRadius: 4,
                }}
              />
            </div>
          )}
        </Box>
      </ThemeProvider>
    );
  }
);
SnackbarComponent.displayName = "SnackbarComponent";

interface LoadingSnackBarProps extends CustomContentProps {
  onClose: VoidFunction;
}
const LoadingSnackbarComponent = forwardRef<
  HTMLDivElement,
  LoadingSnackBarProps
>((props, ref) => (
  <Box ref={ref}>
    <MaterialDesignContent
      {...props}
      action={
        <Box
          sx={{
            paddingRight: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            closeSnackbar(props.id);
          }}
        >
          <CloseSmall />
        </Box>
      }
    />
  </Box>
));
LoadingSnackbarComponent.displayName = "LoadingSnackbarComponent";

const NotificationSnackbarProvider = ({ children }: Props) => {
  const notistackRef = useRef<any>(null);

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={5}
      preventDuplicate
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      iconVariant={{
        uploading: (
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 50,
              height: 50,
              borderRadius: "50%",
              background: theme.palette.background.paper,
              borderColor: theme.palette.primary[75],
              borderWidth: 2,
              borderStyle: "solid",
              marginRight: 3,
              "& svg": {
                color: theme.palette.primary[100],
              },
            })}
          >
            <Upload />
          </Box>
        ),
        uploadFailed: (
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              borderRadius: "50%",
              background: theme.palette.error.main,
              marginRight: 3,
              "& svg": {
                color: theme.palette.background.default,
              },
            })}
          >
            <Error />
          </Box>
        ),
        uploadSuccess: (
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              borderRadius: "50%",
              background: theme.palette.primary[100],
              marginRight: 3,
              "& svg": {
                color: theme.palette.background.default,
              },
            })}
          >
            <Check />
          </Box>
        ),
      }}
      Components={{
        default: SnackbarComponent,
        uploading: SnackbarComponent,
        uploadSuccess: SnackbarComponent,
        uploadFailed: SnackbarComponent,
        loading: LoadingSnackbarComponent,
      }}
    >
      {children}
    </NotistackProvider>
  );
};

export default NotificationSnackbarProvider;
