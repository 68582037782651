import React from "react";

interface DialogAction {
  [key: string]: (...args: any) => any;
}

interface UseDialogReturn {
  dialogVisible: boolean;
  showDialog: () => void;
  hideDialog: () => void;
  setActions: (action: DialogAction) => void;
  triggerAction: (actionName: string) => void;
  getAction: (actionName: string) => ((...args: any) => any) | undefined;
}

function useDialog(): UseDialogReturn {
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [action, setAction] = React.useState<DialogAction>();

  const showDialog = React.useCallback(() => setDialogVisible(true), []);
  const hideDialog = React.useCallback(() => setDialogVisible(false), []);
  const setActions = React.useCallback(
    (action: DialogAction) => setAction(action),
    []
  );
  const triggerAction = React.useCallback(
    (actionName: string) => {
      action?.[actionName]?.();
    },
    [action]
  );
  const getAction = React.useCallback(
    (actionName: string) => action?.[actionName],
    [action]
  );
  return {
    dialogVisible,
    showDialog,
    hideDialog,
    setActions,
    triggerAction,
    getAction,
  };
}

export default useDialog;
