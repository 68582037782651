import { useFetcher } from "react-router-dom";
import * as React from "react";
import { LayoutCategory } from "@/types/api/accountTemplateLayouts";
import { SubmissionActionResult } from "./types";
import { useFormValidation } from "./formValidation.hooks";

interface FieldRefs {
  accountDetailsRef: React.MutableRefObject<string>;
  selectedToneRef: React.MutableRefObject<string>;
  selectedLanguageRef: React.MutableRefObject<string>;
  selectedLayoutRef: React.MutableRefObject<LayoutCategory>;
  selectedPrimaryColorRef: React.MutableRefObject<string>;
  selectedSecondaryColorRef: React.MutableRefObject<string>;
  actionRoute: string;
  method: "post" | "put";
}
const useFormSubmission = ({
  accountDetailsRef,
  selectedToneRef,
  selectedLayoutRef,
  selectedLanguageRef,
  actionRoute,
  selectedPrimaryColorRef,
  selectedSecondaryColorRef,
  method,
}: FieldRefs) => {
  const fetcher = useFetcher();
  const { validate, errors } = useFormValidation({ accountDetailsRef });
  const { ok } = (fetcher.data as SubmissionActionResult) ?? {};
  const isSubmitting = fetcher.state === "submitting";
  const submitCompleted = ok;

  const computeFormData = React.useCallback(
    () => ({
      accountDetails: (
        accountDetailsRef.current as unknown as HTMLTextAreaElement
      ).value,
      toneId: (selectedToneRef.current as unknown as HTMLInputElement).value,
      languageId: (selectedLanguageRef.current as unknown as HTMLInputElement)
        .value,
      layoutCategory: selectedLayoutRef.current,
      primaryColor: selectedPrimaryColorRef.current,
      secondaryColor: selectedSecondaryColorRef.current,
    }),
    [
      accountDetailsRef,
      selectedToneRef,
      selectedLayoutRef,
      selectedPrimaryColorRef,
      selectedLanguageRef,
      selectedSecondaryColorRef,
    ]
  );

  // TODO accept argument `withConfirmation` and move Dialog rendering logic here
  const submitHandler = React.useCallback(() => {
    const { isValid } = validate();
    if (isValid) {
      const validatedFormData = { ...computeFormData() };
      fetcher.submit(validatedFormData, {
        method,
        action: actionRoute,
      });
    }
  }, [fetcher, actionRoute, computeFormData, validate, method]);

  return {
    submitHandler,
    validate,
    submitCompleted,
    isSubmitting,
    errors,
  };
};

export { useFormSubmission };
