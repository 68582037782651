import React from "react";
import { Box, Typography } from "@mui/material";
import { OnboardingPageTitleProps } from "./types";

function OnboardingPageTitle(props: OnboardingPageTitleProps) {
  const { title, subTitle } = props;

  return (
    <Box
      id="onboarding-page-title"
      display="flex"
      justifyContent="flex-start"
      flexDirection="column"
      mb={{ xs: 2, sm: 2 }}
      width="100%"
      mt={0}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" m={0} color="grey.100" noWrap>
          {title}
        </Typography>
      </Box>

      {subTitle && (
        <Typography color="grey.70" variant="p3" display="block" mt={1}>
          {subTitle}
        </Typography>
      )}
    </Box>
  );
}

export default OnboardingPageTitle;
