import { useTextSearch } from "@/components/PaginationRouted/usePagination.hook";
import { SxProps, TextField } from "@mui/material";
import { useRef } from "react";
import Search from "@tiny/icons/generated/Search";

/**
 * Use React Router to keep state of the currently searched keyword
 * Also use React Router's loader feature to make automatically api requests
 * when the query param is changed
 * Make sure to debounce the callback while user types to avoid lots of api requests
 *
 * @param paramName
 * @param id
 * @constructor
 */
function TextSearch({
  paramName,
  id,
  sx,
}: {
  paramName: string;
  id: string;
  sx?: SxProps;
}) {
  const [keyword, updateUrlQueryParam] = useTextSearch(paramName);
  const textSearchValue = useRef(keyword);

  return (
    <TextField
      id={id}
      variant="outlined"
      size="small"
      sx={sx}
      defaultValue={keyword}
      placeholder="Search"
      inputRef={textSearchValue}
      onChange={(e) => {
        const text = e.target.value.trim();
        updateUrlQueryParam(text);
      }}
      InputProps={{
        endAdornment: <Search sx={{ color: "grey.70" }} fontSize="small" />,
      }}
    />
  );
}

export default TextSearch;
