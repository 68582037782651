import React from "react";
import { Await, useLoaderData } from "react-router-dom";
import PageSize from "@/components/PaginationRouted/PageSize";
import TinyPagination from "@/components/PaginationRouted/Pagination";
import { Box, Theme } from "@mui/material";
import { Search } from "@tiny/icons";
import { FilterOptions } from "@/components/PageFilter";
import EmptyState from "@/components/EmptyState";
import OrdersTableRevenueHeader from "@/routes/OrdersTableRevenue/OrdersTableRevenueHeader";
import OrdersTable from "@/routes/OrdersTableRevenue/OrdersTable";
import { GetGeneratedOrdersResponse } from "@/types/api/generatedRevenueOrders";

export const ordersFilterOptions: FilterOptions[] = [
  { key: "ALL_TIME", label: "All" },
  { key: "LAST_7_DAYS", label: "This Week" },
  { key: "LAST_30_DAYS", label: "This Month" },
  { key: "YTD", label: "This Year" },
  { key: "LAST_1_WEEK", label: "Last Week" },
  { key: "LAST_MONTH", label: "Last Month" },
  { key: "LAST_YTD", label: "Last Year" },
];

function OrdersTableRevenue() {
  const { generatedOrders } = useLoaderData() as {
    generatedOrders: GetGeneratedOrdersResponse;
  };

  return (
    <React.Suspense>
      <Await resolve={generatedOrders}>
        {(orders: GetGeneratedOrdersResponse) => (
          <>
            <OrdersTableRevenueHeader />
            {orders.empty ? (
              <EmptyState
                Icon={
                  <Search
                    sx={(theme: Theme) => ({
                      color: "grey.80",
                      width: theme.spacing(6),
                      height: theme.spacing(6),
                    })}
                  />
                }
                message="Please try again later or adjust your search criteria."
                title="No revenue generated yet or no results found."
              />
            ) : (
              <>
                <OrdersTable />
                <Box display="flex" justifyContent="space-between" mt={5}>
                  <PageSize />
                  <TinyPagination pagesCount={orders.totalPages} />
                </Box>
              </>
            )}
          </>
        )}
      </Await>
    </React.Suspense>
  );
}

export default OrdersTableRevenue;
