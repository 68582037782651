import React from "react";
import { isArrayEquals } from "../../utils/arrayUtils";
import AutocompleteField from "../AutocompleteField";
import { Option, SelectFieldProps } from "./SelectField.types";

function SelectField(props: SelectFieldProps) {
  const {
    options,
    value,
    onChangeValue,
    disableInputChange = true,
    ...other
  } = props;

  const selectValue = React.useMemo(() => {
    const selectedOption = options.find((option) => {
      if (Array.isArray(option.value) && Array.isArray(value)) {
        return isArrayEquals(option.value, value);
      } else {
        return option.value === value;
      }
    });
    return selectedOption || "";
  }, [options, value]);

  const getOptionLabel = React.useCallback(
    (option: Option) => option.label || "",
    []
  );

  const getOptionSelected = React.useCallback(
    (option: Option, optionValue: any) => {
      if (!optionValue) {
        return false;
      }
      return option.value === optionValue.value;
    },
    []
  );

  const handleChangeValue = React.useCallback(
    (option: Option) => {
      onChangeValue?.(option.value);
    },
    [onChangeValue]
  );
  return (
    <AutocompleteField
      {...other}
      options={options}
      value={selectValue}
      disableClearable
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChangeValue={handleChangeValue}
      disableInputChange={disableInputChange}
    />
  );
}

export default React.memo(SelectField);
