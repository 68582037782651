import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChevronDown, CloseSmall, InfoOutline } from "@tiny/icons";
import { Campaign, CampaignAudience } from "@/types/api/campaigns";
import { GetAudienceResponse } from "@/types/api/audience";
import { fetchApi } from "@tiny/utils";
import { Controller, useFormContext } from "react-hook-form";

function Audiences({ formData }: { formData?: Campaign }) {
  const { setValue, control } = useFormContext();
  const [audiences, setAudiences] = useState<CampaignAudience[]>([]);

  useEffect(() => {
    setValue(
      "includeNonSubscribedAsReceivers",
      formData?.includeNonSubscribedAsReceivers
    );
  }, [formData?.audiences, formData?.includeNonSubscribedAsReceivers]);

  const fetchAudiences = () => {
    fetchApi<GetAudienceResponse>("/customerservice/audience?sort=name,asc", {
      method: "get",
    })
      .then((response) => {
        const campaignAudience = response.audiences.map((audience) => ({
          campaignId: formData?.id || "",
          audienceId: audience.id,
          audienceName: audience.name,
        }));
        setAudiences(campaignAudience);
        if (formData?.audiences) {
          setValue("audiences", formData?.audiences);
        }
      })
      .catch((error) => {
        console.log("Error fetching audiences:", error);
      });
  };

  useEffect(() => {
    fetchAudiences();
  }, []);

  return (
    <Box my={5}>
      <Typography variant="h4" color="grey.100">
        Audience
      </Typography>
      <Box mt={5}>
        <Controller
          name="audiences"
          control={control}
          defaultValue={[
            { audienceName: "All", campaignId: "0", audienceId: "0" },
          ]}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              options={audiences}
              value={value}
              limitTags={3}
              isOptionEqualToValue={(
                option: CampaignAudience,
                val: CampaignAudience
              ) =>
                option.campaignId === val.campaignId &&
                option.audienceId === val.audienceId
              }
              filterSelectedOptions
              popupIcon={<ChevronDown />}
              onChange={(event, newValue) => {
                if (!newValue?.length) {
                  onChange([
                    { audienceName: "All", campaignId: "0", audienceId: "0" },
                  ]);
                } else {
                  onChange(newValue.filter((nv) => nv.audienceName !== "All"));
                }
              }}
              componentsProps={{
                paper: {
                  sx: {
                    fontSize: 14,
                  },
                },
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Audience"
                  placeholder="Search Audience"
                  variant="outlined"
                />
              )}
              getOptionLabel={(option: string | CampaignAudience | null) => {
                if (typeof option === "string") {
                  return option;
                }
                return option?.audienceName || "";
              }}
              renderTags={(val, getTagProps) =>
                val.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={
                      typeof option === "string" ? option : option?.audienceName
                    }
                    sx={(theme) => ({
                      background: theme.palette.grey[30],
                      color: theme.palette.grey[90],
                      fontSize: 12,
                      border: 0,
                      fontWeight: 400,
                    })}
                    variant="outlined"
                    deleteIcon={<CloseSmall />}
                  />
                ))
              }
            />
          )}
        />
      </Box>
      <Box mt={4} mx={2}>
        <Controller
          name="includeNonSubscribedAsReceivers"
          control={control}
          defaultValue
          render={({ field }) => (
            <RadioGroup {...field} row>
              <FormControlLabel
                value="false"
                control={<Radio />}
                sx={(theme: Theme) => ({ fontSize: theme.spacing(3.5) })}
                label={
                  <>
                    Only subscribers
                    <Tooltip
                      title={
                        <Typography sx={{ p: 2 }}>
                          Emails will be sent only to customers who have opted
                          to receive the marketing campaign.
                        </Typography>
                      }
                      sx={{ px: 2 }}
                    >
                      <IconButton size="small">
                        <InfoOutline fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                sx={(theme: Theme) => ({ fontSize: theme.spacing(3.5) })}
                label={
                  <>
                    All
                    <Tooltip
                      title={
                        <Typography sx={{ p: 2 }}>
                          Emails will be sent to all customers present in your
                          audience list, even if they haven't opted to receive
                          marketing campaigns.
                        </Typography>
                      }
                      sx={{ px: 2 }}
                    >
                      <IconButton size="small">
                        <InfoOutline fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            </RadioGroup>
          )}
        />
      </Box>
    </Box>
  );
}

export default Audiences;
