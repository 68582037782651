import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M10 4V11H4V4H2V20H4V13H10V20H12V4H10Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M22 17.96V16H21V12.92H19V16H16L19.5 8H17.4202L14 16V17.96H19V20H21V17.96H22Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "HeadingH4"
);
