import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import PageSize from "@/components/PaginationRouted/PageSize";
import { Pagination } from "@/types/api/pagination";
import TinyPagination from "@/components/PaginationRouted/Pagination";
import PageFilter from "@/components/PaginationRouted/PageFilter";
import { DropzoneDialog } from "mui-file-dropzone";
import { useToast } from "@/components/ToastService/useToast";
import NoHeroImage from "./NoHeroImage";
import HeroImageCard from "../../../../components/HeroImage/HeroImageCard/HeroImageCard";
import { HeroImage } from "../types";
import { useHeroImage } from "../Hooks/useHeroimage";
import { HeroImageContext, ImageContext } from "../Context/imageContext";

function HeroImageGrid({
  imagesObject,
}: {
  imagesObject: Pagination<HeroImage>;
}) {
  const {
    heroImagePagination,
    heroImages,
    setHeroImagePaginationData,
    setHeroImagesData,
  } = useContext(ImageContext) as HeroImageContext;
  const { uploadHeroImage } = useHeroImage();
  const { showToast } = useToast();
  const [showUpload, setShowUpload] = useState(false);

  useEffect(() => {
    setHeroImagePaginationData(imagesObject);
    setHeroImagesData(imagesObject?.content);
  }, [imagesObject]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "flex-end", md: "space-between" },
          alignItems: { xs: "flex-start", md: "flex-end" },
        }}
        mt={2}
        mb={4}
        height={58}
      >
        <Box id="grid-container-toast">{}</Box>
        <Box display="flex" gap={5} alignItems="center">
          <Button
            sx={{ px: 5, py: 1.6 }}
            variant="outlined"
            color="primary"
            onClick={() => setShowUpload(true)}
            id="hero-image-addnew-cta"
          >
            Add new
          </Button>

          <DropzoneDialog
            acceptedFiles={["image/png", "image/jpeg"]}
            cancelButtonText="Cancel"
            submitButtonText="Upload"
            open={showUpload}
            fileObjects={[]}
            onClose={() => setShowUpload(false)}
            onSave={async (files) => {
              setShowUpload(false);
              await uploadHeroImage(files);
            }}
            dialogTitle={
              <Typography variant="h3" mb={4}>
                Upload Hero Image
              </Typography>
            }
            showPreviewsInDropzone
            filesLimit={5}
            dropzoneText="Drag a file here or Select a file from your computer."
            maxFileSize={1048576}
            showAlerts={false}
            showPreviews={false}
            previewGridProps={{ container: { p: 4, rowGap: 0 } }}
            onAlert={(message, variant) => {
              if (variant === "error") {
                showToast({
                  duration: 3000,
                  message,
                  type: variant,
                });
              }
            }}
            maxWidth="sm"
          />
          <PageFilter />
        </Box>
      </Box>
      {heroImagePagination?.empty ? (
        <NoHeroImage />
      ) : (
        <Grid
          container
          id="heroimage-cards-section"
          spacing={{ xs: 3, md: 5 }}
          columns={{ xs: 12, md: 12 }}
        >
          {heroImages?.map((heroImage) => (
            <Grid item xs={12} md={4} key={heroImage.id}>
              <HeroImageCard heroImage={heroImage} key={heroImage.id} />
            </Grid>
          ))}
        </Grid>
      )}
      <Box display="flex" justifyContent="space-between" mt={5}>
        <PageSize />
        <TinyPagination pagesCount={heroImagePagination?.totalPages || 0} />
      </Box>
    </>
  );
}

export { HeroImageGrid };
