import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <path
      d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14ZM12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10C10 8.89543 10.8954 8 12 8Z"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      d="M4 22C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4ZM4 4V20H7C7 17.2386 9.23858 15 12 15C14.7614 15 17 17.2386 17 20H20V4H4ZM15 20C15 18.3431 13.6569 17 12 17C10.3431 17 9 18.3431 9 20H15Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "UserSquare"
);
