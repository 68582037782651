import { Box, CircularProgress } from "@mui/material";
import React from "react";

const ComponentLoader = ({
  loading = true,
  height = "100%",
}: {
  loading?: boolean;
  height?: string | number;
}) => {
  return (
    <>
      {loading && (
        <Box
          width="100%"
          height={height}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ComponentLoader;
