import React from "react";
import {
  Container,
  Box,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import { CloseSmall } from "@tiny/icons/generated";
import { useDrawerInvokers } from "@tiny/lib";
import {
  HeroImage,
  HeroImageHook,
} from "@/routes/AccountSettings/HeroImage/types";

const ImagePreview = ({
  heroImage,
  useHeroImage,
}: {
  heroImage: HeroImage;
  useHeroImage: HeroImageHook;
}) => {
  const { id, imageType } = heroImage;
  const { hideDrawer } = useDrawerInvokers();
  const sm = useMediaQuery("(max-width:600px)");
  const { isLoading, deleteHeroImage, selectHeroImage } = useHeroImage;

  const handleClose = () => {
    hideDrawer();
  };

  const handleDelete = async () => {
    await deleteHeroImage(heroImage?.id);
    handleClose();
  };

  const handleSelect = async () => {
    await selectHeroImage(id, imageType);
    handleClose();
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        width: sm ? "100vw" : "40vw",
        borderRadius: "20px 0px 0px 0px",
        overflow: "hidden",
        padding: 6,
      }}
      disableGutters
      maxWidth={false}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h2" color="grey.100">
          Preview Hero Image
        </Typography>

        <Box
          sx={(theme) => ({
            border: `0.5px solid ${theme.palette.grey[40]}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: 24,
            height: 24,
          })}
          onClick={handleClose}
        >
          <CloseSmall
            sx={() => ({
              cursor: "pointer",
            })}
          />
        </Box>
      </Box>

      <Box
        my={6}
        id={`hero-image-preview-${heroImage?.id}`}
        sx={(theme) => ({
          bgcolor: "common.white",
          display: "flex",
          flexDirection: "column",
          border: `0.5px solid ${theme.palette.grey[60]}`,
          borderRadius: 2,
          gap: 2,
          boxShadow: "none",
          position: "relative",
          justifyContent: "center",
        })}
        flex={1}
      >
        <img
          src={heroImage?.attributes?.src}
          alt={heroImage?.attributes?.alt}
          height="auto"
          width="100%"
          style={{ padding: 20, objectFit: "contain", maxHeight: "70vh" }}
        />
      </Box>

      <Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mr: theme.spacing(16),
          })}
        >
          <Button
            id="hero-image-preview-delete"
            variant="outlined"
            className="regenerate-cta"
            sx={{ px: 5, py: { xl: 2.5, sm: 1.5 } }}
            onClick={handleDelete}
            disabled={isLoading}
          >
            Delete
          </Button>
          <Button
            id={
              heroImage?.imageType === "HERO"
                ? "hero-image-preview-deselect"
                : "hero-image-preview-select"
            }
            variant="contained"
            className="activate-close"
            onClick={handleSelect}
            sx={{ px: 5, py: { xl: 2.5, sm: 1.5 } }}
            disabled={isLoading}
          >
            {heroImage?.imageType === "HERO" ? "Deselect" : "Select"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ImagePreview;
