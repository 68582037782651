export interface ErrorEntity {
  errorCode: string;
  errorMessage: string;
}

export interface BackEndError {
  message?: string;
  messageDetails?: string;
  errorCodes?: ErrorEntity[];
  status?: number;
}

export const managed_errors_codes = [
  "4.1.001",
  "4.2.001",
  "4.2.002",
  "4.2.003",
  "4.2.004",
  "4.5.001",
  "4.5.002",
  "6.1.001",
];

export function isManagedError(error?: ErrorEntity[]): boolean {
  return (
    (error?.filter((e) => managed_errors_codes.includes(e.errorCode))?.length ||
      0) > 0
  );
}

export const DEFAULT_ERROR_MESSAGE =
  "Looks like you got some connectivity issues, please reload the page.";

export async function extractBackendError(response: {
  [key: string]: any;
}): Promise<BackEndError> {
  if (response?.data instanceof Blob) {
    return {
      ...JSON.parse(await response?.data.text()),
      status: response?.status,
    };
  } else {
    return { ...response?.data, status: response?.status };
  }
}

export function getErrorMessage(
  error: BackEndError,
  skipStatuses = [401]
): string {
  let message = DEFAULT_ERROR_MESSAGE;
  if (error?.status === 401 && skipStatuses.includes(error?.status)) {
    return "";
  } else if (error?.status === 412 && error?.message) {
    message = error.message;
  }
  return message;
}

export class AppError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
  }
}

export function extractErrorMessage(error: any, skipStatuses = [401]) {
  if (!error) {
    return;
  }
  if (error instanceof AppError) {
    return error.message;
  }
  if ("response" in error!) {
    const response = (error as any).response;
    const status = response?.status;
    const message = response?.data.message;
    const errorCodes = response?.data?.errorCodes;
    if (isManagedError(errorCodes) || skipStatuses.includes(error?.status)) {
      return undefined;
    }
    if (status === 412 && message) {
      return message;
    }
  }
  return DEFAULT_ERROR_MESSAGE;
}
