import React from "react";
import { Divider } from "@mui/material";

function CardStack<T>({ children }: { children: Array<any> }) {
  const result: React.ReactElement<Array<T>>[] = [];

  React.Children.map(children, (child, index) => {
    const cloneElement = React.cloneElement(child, {
      key: child.key,
      ...child.props,
      isFirst: index === 0,
      isLast: !Array.isArray(children) || index === children.length - 1,
    });

    result.push(cloneElement);
    result.push(
      <Divider sx={{ borderColor: "purple.50", my: 4 }} key={index} />
    );
  });
  result.pop();
  return result;
}

export default CardStack;
