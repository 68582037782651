import {
  getRandomAddress1,
  getRandomAddress2,
  getRandomCity,
  getRandomCompany,
  getRandomCountry,
  getRandomCurrency,
  getRandomDate,
  getRandomEmail,
  getRandomFirstName,
  getRandomId,
  getRandomLastName,
  getRandomOrders,
  getRandomPhone,
  getRandomProductName,
  getRandomSource,
  getRandomState,
  getRandomTags,
  getRandomLifetimeValue,
  getRandomZipCode,
  getRandomOrderPrice,
} from "@tiny/utils/fakerUtils";

const TinyCsvImporterSampleValues = [
  { header: "Email", value: getRandomEmail() },
  {
    header: "First Name",
    value: getRandomFirstName(),
  },
  {
    header: "Last Name",
    value: getRandomLastName(),
  },
  {
    header: "Source",
    value: getRandomSource(),
  },
  {
    header: "Birthday",
    value: getRandomDate("MM/dd/yyyy"),
  },
  {
    header: "Tags",
    value: getRandomTags(),
  },
  {
    header: "Currency",
    value: getRandomCurrency(),
  },
  {
    header: "Country",
    value: getRandomCountry(),
  },
  {
    header: "State/Province",
    value: getRandomState(),
  },
  {
    header: "City",
    value: getRandomCity(),
  },
  {
    header: "Address 1",
    value: getRandomAddress1(),
  },
  {
    header: "Address 2",
    value: getRandomAddress2(),
  },
  {
    header: "Phone number",
    value: getRandomPhone(),
  },
  {
    header: "Zip/Postal Code",
    value: getRandomZipCode(),
  },
  {
    header: "Company",
    value: getRandomCompany(),
  },
  {
    header: "Number of orders",
    value: getRandomOrders(),
  },
  {
    header: "Lifetime value",
    value: getRandomLifetimeValue(),
  },
  {
    header: "Latest order ID",
    value: getRandomId(),
  },
  {
    header: "Last purchased order",
    value: getRandomProductName(),
  },
  {
    header: "Last purchased date",
    value: getRandomDate("MM/dd/yyyy"),
  },
  {
    header: "Last purchased price",
    value: getRandomOrderPrice(),
  },
];

export function getSampleCsvData() {
  const headerRow: string[] = [];
  const dataRow: string[] = [];
  TinyCsvImporterSampleValues.forEach((element) => {
    headerRow.push(element.header);
    dataRow.push(element.value);
  });
  return [headerRow.join(";"), dataRow.join(";")].join("\n");
}
