import { Campaign } from "@/types/api/campaigns";
import { useInterval } from "@tiny/utils/react/useInterval";
import { useRevalidator } from "react-router-dom";

function useCampaignsRefetch(campaigns: Campaign[]) {
  const revalidator = useRevalidator();

  const shouldRefetch = campaigns.find((campaign) => {
    const outdated = !!(new Date() > new Date(campaign.startTime));
    return (
      !outdated &&
      campaign.status !== "COMPLETED" &&
      campaign.status !== "DRAFT" &&
      campaign.status !== "RECURRING" &&
      campaign.status !== "STOPPED"
    );
  });

  useInterval(
    () => {
      revalidator.revalidate();
    },
    shouldRefetch ? 3000 : null
  );
}

export { useCampaignsRefetch };
