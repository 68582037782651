import * as React from "react";
import { SubmissionErrorProps } from "@/routes/BrandVoice/types";

interface ValidatedFieldRefs {
  accountDetailsRef: React.MutableRefObject<string>;
}
const useFormValidation = ({ accountDetailsRef }: ValidatedFieldRefs) => {
  const [errors, setErrors] = React.useState<
    SubmissionErrorProps | undefined
  >();

  const validate = React.useCallback(() => {
    const accountDetailsValue = (
      accountDetailsRef.current as unknown as HTMLTextAreaElement
    ).value.trim();
    const foundErrors: SubmissionErrorProps = {};
    if (accountDetailsValue.length === 0) {
      foundErrors.accountDetails = "'Company details' is a required field";
    }
    if (accountDetailsValue.length >= 1000) {
      foundErrors.accountDetails = "MAX length is 1000";
    }
    const hasErrors = !!Object.keys(foundErrors).length;
    if (hasErrors && !errors) {
      setErrors(foundErrors);
    }
    if (!hasErrors && errors) {
      setErrors(undefined);
    }
    return {
      isValid: !hasErrors,
      errors: hasErrors ? foundErrors : undefined,
    };
  }, [accountDetailsRef, errors]);

  return { validate, errors };
};

export { useFormValidation };
