import React, { ForwardedRef } from "react";
import { TextFieldProps } from "./TextField.types";
import TextFieldBase from "../TextFieldBase";
import useDebounce from "../../hooks/useDebounce";

const TextField = React.forwardRef(function TextField(
  props: TextFieldProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    endIcon,
    info,
    infoIcon,
    InputProps: InputPropsProp,
    onChange,
    onChangeValue,
    startIcon,
    toolTipModifiers,
    ...other
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    if (onChangeValue) {
      onChangeValue(event.target.value);
    }
  };

  const InputProps = React.useMemo(() => {
    // let startAdornment = undefined;
    // if (startIcon) {
    //   const icon = startIcon as React.ReactElement;
    //   const className = icon.props?.sx;
    //   startAdornment = React.cloneElement(icon, {
    //     className: className,
    //   });
    // }

    // let endAdornment = undefined;
    // if (endIcon) {
    //   let endIconStyled = null;
    //   if (endIcon) {
    //     const icon = endIcon as React.ReactElement;
    //     const className = icon.props?.sx;
    //     endIconStyled = React.cloneElement(icon, {
    //       className,
    //     });
    //   }

    //   endAdornment = <>{endIcon}</>;
    // }
    let startAdornment = <>{startIcon}</>;
    let endAdornment = <>{endIcon}</>;

    return {
      ...InputPropsProp,
      startAdornment,
      endAdornment,
    };
  }, [startIcon, endIcon, InputPropsProp]);

  return (
    <TextFieldBase
      {...other}
      InputProps={InputProps}
      ref={ref}
      onChange={handleChange}
      margin={undefined}
    />
  );
});

export default TextField;
