export interface EndpointConfig {
  path: string;
  requireAuthorization?: boolean;
}

export interface ErrorEntity {
  errorCode: string;
  errorMessage: string;
}

export interface BackEndError {
  message?: string;
  messageDetails?: string;
  errorCodes?: ErrorEntity[];
  status?: number;
}

export function isBackendError(response: any): response is BackEndError {
  return response && "message" in response;
}

export interface TokenModel {
  access_token: string;
  refresh_token: string;
}
