import React, { useEffect } from "react";
import { useDialogInvokers, useScreenLoaderInvokers } from "@tiny/lib";
import SenderDetailsConfirmationDialogContent from "@/components/SenderDetailsConfirmationDialogContent";
import { fetchApi } from "@tiny/utils";
import { ApiError, SenderNotConfirmedError } from "@tiny/utils/errors";
import { useRevalidator } from "react-router-dom";
import { CampaignProps } from "./types";

export const CAMPAIGN_SENDER_DETAILS_NOT_CONFIRMED_ERROR_CODE = "4.1.004";

function useCampaign(props: CampaignProps) {
  const { enabled, id } = props;
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderInvokers();
  const { requestMediumDialog } = useDialogInvokers();
  const reevalidator = useRevalidator();

  useEffect(() => {
    if (reevalidator.state === "idle") {
      hideScreenLoader();
    }
  }, [reevalidator.state]);

  const onChangeActivation = React.useCallback(
    (isActive: boolean) => {
      showScreenLoader();
      fetchApi(
        `/campaignservice/campaign/${id}/enabled/${isActive.toString()}`,
        {
          method: "put",
        }
      )
        .then(() => {
          reevalidator.revalidate();
        })
        .catch((error: ApiError) => {
          if (error instanceof SenderNotConfirmedError) {
            requestMediumDialog({
              titleProps: {},
              contentProps: {
                dividers: false,
                sx: {
                  border: "none",
                },
                children: <SenderDetailsConfirmationDialogContent />,
              },
            });
          }
          hideScreenLoader();
        });
    },
    [id, requestMediumDialog]
  );

  return {
    activeState: enabled,
    onChangeActivation,
  };
}

export { useCampaign };
