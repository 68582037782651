import React from "react";
import { Box } from "@mui/material";

function SidebarWrapper({
  children,
  collapsed,
}: {
  children: React.ReactNode;
  collapsed: boolean;
}) {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        minHeight: { sm: "100vh" },
        backgroundColor: theme.palette.common.white,
        p: { xs: 4, sm: 6 },
        pb: { xs: 3 },
        pt: { xs: 3 },
        justifyContent: "space-between",
        width: { xs: "100%", sm: collapsed ? 80 : 258 },
        borderRadius: { xs: "0", sm: "0px 20px 0 0" },
        transition: "width 150ms",
        position: "relative",
        flexDirection: "column",
        alignItems: { xs: "center" },
      })}
    >
      {children}
    </Box>
  );
}

export default SidebarWrapper;
