import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDrawerInvokers } from "@tiny/lib";
import { ChevronLeft, ChevronRight } from "@tiny/icons";
import { formatDate, getStartAndEndOfMonth } from "@tiny/utils/dateUtils";
import AddNewCampaign from "@/components/AddNewCampaign";
import { useIsLargerThan } from "@tiny/theme/utils";
import { useSearchParams } from "react-router-dom";
import { addMonths, isSameMonth, subMonths } from "date-fns";
import AddCampaignForm from "../../CampaignForm";

function CampaignTableHeader({
  selectedDate,
  updateSelectedDate,
}: {
  selectedDate: Date;
  updateSelectedDate: (date: Date) => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(new Date(selectedDate));
  const { showDrawer } = useDrawerInvokers();

  const handleAddNew = () => {
    showDrawer({
      children: <AddCampaignForm />,
    });
  };

  const handleDateChange = (scheduledFrom: string, scheduledTo: string) => {
    searchParams.set("scheduled-from", scheduledFrom);
    searchParams.set("scheduled-to", scheduledTo);
    searchParams.delete("page");
    setSearchParams(searchParams);
  };
  const isMdUp = useIsLargerThan("md");
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={5}
      flexDirection={{ xs: "column", sm: "row" }}
      gap={{ xs: 2, sm: 0 }}
    >
      <Box
        display="flex"
        gap={6}
        flexDirection="row"
        justifyContent={{ xs: "space-between", sm: "flex-start" }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            background: "white",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "grey.60",
            borderRadius: 2,
          }}
        >
          <IconButton
            onClick={() => {
              const updatedDate = subMonths(date, 1);
              setDate(updatedDate);
              updateSelectedDate(updatedDate);
              const { to, from } = getStartAndEndOfMonth(updatedDate, false);
              handleDateChange(from, to);
            }}
            disabled={isSameMonth(new Date(), date)}
          >
            <ChevronLeft />
          </IconButton>
          <Typography
            variant="p2"
            textAlign="center"
            sx={(theme) => ({ minWidth: theme.spacing(16) })}
          >
            {formatDate(date, "MMM yyyy")}
          </Typography>
          <IconButton
            onClick={() => {
              const updatedDate = addMonths(date, 1);
              setDate(updatedDate);
              updateSelectedDate(updatedDate);
              const { from, to } = getStartAndEndOfMonth(updatedDate, false);
              handleDateChange(from, to);
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      {isMdUp && (
        <Box display="flex" gap={4} justifyContent="space-between">
          <AddNewCampaign />
        </Box>
      )}
    </Box>
  );
}

export default CampaignTableHeader;
