const silencedIds: string[] = [];

function add(id: string) {
  silencedIds.push(id);
}

function isSilenced(id: string) {
  return silencedIds.includes(id);
}

function isNotSilenced(id: string) {
  return !isSilenced(id);
}

function list() {
  return [...silencedIds];
}

export { add, isSilenced, isNotSilenced, list };
