import React, { useEffect, useState } from "react";
import { fetchApi } from "@tiny/utils";
import {
  useDialogInvokers,
  useDrawerInvokers,
  useScreenLoaderInvokers,
} from "@tiny/lib";
import { GetReportOverviewResponse } from "@/types/api/reportsOverview";
import { metricMapper } from "@/components/MetricCard/metric.mapper";
import { useStatisticsDialog } from "@/hooks/statistics.hooks";
import {
  useLocation,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import { ActionMenu } from "@/components/ActionMenu/types";
import { RootLoader } from "@/types/RootLoader";
import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddCampaignForm from "@/routes/Campaigns/CampaignForm";
import { Campaign } from "@/types/api/campaigns";
import { getCampaignStatus } from "@/routes/Campaigns/Calendar/helpers";
import { ApiError, SenderNotConfirmedError } from "@tiny/utils/errors";
import SenderDetailsConfirmationDialogContent from "@/components/SenderDetailsConfirmationDialogContent";
import CampaignPreview from "../CampaignPreview";
import RecurringDialog from "../RecurringDialog";

export interface Condition {
  conditionName: string;
  condition: boolean;
}

export function useCampaignMenu(props: Campaign, conditions: Condition[] = []) {
  const location = useLocation();
  const { id, info, name, status, enabled, generatedBy, startTime } = props;
  const outdated = !!(new Date() > new Date(startTime));
  const { showStatisticsDialog } = useStatisticsDialog();
  const [isRegenerated, setIsRegenerated] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;
  const { requestMediumDialog, exitDialog } = useDialogInvokers();
  const { showDrawer } = useDrawerInvokers();
  const sm = useMediaQuery("(max-width:600px)");
  const { showScreenLoader, hideScreenLoader } = useScreenLoaderInvokers();

  useEffect(() => {
    if (isRegenerated && refetch) {
      revalidator.revalidate();
      setIsRegenerated(false);
      setRefetch(false);
    }
    if (refetch) {
      setRefetch(false);
    }
  }, [isRegenerated, refetch]);

  const showDialogBox = (description: string, title: string) => {
    requestMediumDialog({
      titleProps: { children: title, variant: "h3" },
      contentProps: {
        sx: { pb: 4 },
        children: (
          <Typography variant="p1" mt={4}>
            {description}
          </Typography>
        ),
      },
      actionProps: {
        children: (
          <Box alignItems="flex-end" sx={{ display: "flex", columnGap: 4 }}>
            <Button
              disableElevation
              onClick={() => {
                exitDialog();
              }}
              variant="contained"
            >
              Ok
            </Button>
          </Box>
        ),
      },
    });
  };

  const handleInformation = React.useCallback(async () => {
    const campaignReportsData = await fetchApi<GetReportOverviewResponse>(
      `/reportservice/campaign/${id}/overview`,
      { method: "GET" }
    );
    const campaignReportsMapped = metricMapper(
      campaignReportsData,
      "specificCampaignMessages",
      accountInfo
    );
    showStatisticsDialog(
      info,
      campaignReportsMapped ?? [],
      name,
      () =>
        navigate(
          `/../main/dashboard/generated-revenue/orders?activities=${id}`
        ),
      "campaigns-overview-information-see-reports"
    );
  }, [id, info, name, navigate, showStatisticsDialog, accountInfo]);

  const handlePreview = (regenerate = false) => {
    exitDialog();
    showDrawer({
      children: (
        <CampaignPreview
          name={name}
          campaignId={id}
          campaignStatus={
            getCampaignStatus({ status, enabled, outdated })?.status
          }
          regenerate={regenerate}
          onRegenerate={() => {
            setIsRegenerated(true);
          }}
          onClose={() => {
            setRefetch(true);
          }}
          campaignGeneratedBy={generatedBy}
        />
      ),
      onClose: () => {
        setRefetch(true);
      },
    });
  };

  const handleShowRecurringDialog = (campaignId: string) => {
    exitDialog();
    requestMediumDialog({
      titleProps: {},
      contentProps: {
        dividers: false,
        sx: {
          border: "none",
        },
        children: (
          <RecurringDialog
            open
            onClose={() => {
              exitDialog();
              revalidator.revalidate();
            }}
            campaign={props}
          />
        ),
      },
    });
  };

  const handleSettings = () => {
    showDrawer({
      children: <AddCampaignForm editMode formData={props} />,
    });
  };

  const shouldExcludeRegenerate = status !== "DRAFT" || outdated || enabled;
  const showRegenerate = generatedBy === "System" && !shouldExcludeRegenerate;
  const showDelete =
    (generatedBy === "User" && status === "DRAFT") || status === "STOPPED";
  const isRecurring = status === "RECURRING" || status === "STOPPED";
  const showSetRecurring =
    !isRecurring && status !== "PAUSED" && status !== "COMPLETED";
  const showResume = status === "STOPPED";
  const showStop = status === "RECURRING";
  const failed = status === "FAILED";
  const showActivate = false;
  const showDeactivate = false;

  const defaultConditions: {
    [key: string]: boolean;
  } = {
    Preview: true,
    Information: outdated && status !== "DRAFT",
    Edit:
      showRegenerate ||
      (generatedBy === "User" &&
        (status === "DRAFT" || status === "SCHEDULED")),
    Regenerate: showRegenerate,
    Settings: !outdated && !failed && generatedBy === "System" && !isRecurring,
    Delete: showDelete,
    Activate: showActivate,
    Deactivate: showDeactivate,
    "Set as recurring": showSetRecurring,
    Resume: showResume,
    Stop: showStop,
  };

  const mergedConditions = { ...defaultConditions };
  conditions.forEach(({ conditionName, condition }) => {
    mergedConditions[conditionName] = condition;
  });

  const handleUserGeneratedEdit = () => {
    if (sm) {
      return showDialogBox(
        "Editing is not supported in the mobile view.",
        "Cannot Edit"
      );
    }
    navigate(`/main/campaigns/builder?id=${id}`, {
      state: {
        startPath: location.pathname,
      },
    });
  };

  const handleSystemGeneratedEdit = () => {
    if (sm) {
      return showDialogBox(
        "Editing is not supported in the mobile view.",
        "Cannot Edit"
      );
    }
    navigate(
      `/main/campaigns/${id}/edit/details${
        searchParams ? `?${searchParams.toString()}` : ""
      }`,
      {
        state: {
          startPath: location.pathname,
        },
      }
    );
  };

  const handleSystemGeneratedDelete = () => {
    showScreenLoader();
    fetchApi(`/campaignservice/campaign/${id}`, { method: "DELETE" })
      .then((reps) => {
        revalidator.revalidate();
        hideScreenLoader();
      })
      .catch((error) => {
        hideScreenLoader();
      });
  };

  const handleStopRecurring = () => {
    showScreenLoader();
    fetchApi(`/campaignservice/campaign/${id}/stop-recurring`, {
      method: "PUT",
    })
      .then((reps) => {
        revalidator.revalidate();
        hideScreenLoader();
      })
      .catch((error) => {
        hideScreenLoader();
      });
  };

  const handleResumeRecurring = () => {
    exitDialog();
    requestMediumDialog({
      titleProps: {},
      contentProps: {
        dividers: false,
        sx: {
          border: "none",
        },
        children: (
          <RecurringDialog
            open
            onClose={() => {
              exitDialog();
              revalidator.revalidate();
            }}
            campaign={props}
          />
        ),
      },
    });
  };

  const toggleActivation = (statusFlag: boolean) => {
    showScreenLoader();
    fetchApi(
      `/campaignservice/campaign/${id}/enabled/${statusFlag.toString()}`,
      {
        method: "put",
      }
    )
      .then(() => {
        revalidator.revalidate();
        hideScreenLoader();
      })
      .catch((err: ApiError) => {
        if (err instanceof SenderNotConfirmedError) {
          requestMediumDialog({
            titleProps: {},
            contentProps: {
              dividers: false,
              sx: {
                border: "none",
              },
              children: <SenderDetailsConfirmationDialogContent />,
            },
          });
        }
        hideScreenLoader();
      });
  };

  const campaignMenuConfig = [
    {
      name: "Activate",
      onClickAction: () => toggleActivation(true),
      id: "campaigns-overview-list-preview",
    },
    {
      name: "Deactivate",
      onClickAction: () => toggleActivation(false),
      id: "campaigns-overview-list-information",
    },
    {
      name: "Preview",
      onClickAction: handlePreview,
      id: "campaigns-overview-list-preview",
    },
    {
      name: "Information",
      onClickAction: handleInformation,
      id: "campaigns-overview-list-information",
    },
    {
      name: "Edit",
      onClickAction:
        generatedBy === "System"
          ? handleSystemGeneratedEdit
          : handleUserGeneratedEdit,
      id: "campaigns-overview-list-edit",
    },
    {
      name: "Regenerate",
      showRegenerate,
      onClickAction: () => {
        handlePreview(true);
      },
      id: "campaigns-overview-list-regenerate",
    },
    {
      name: "Settings",
      showRegenerate,
      onClickAction: () => {
        handleSettings();
      },
      id: "campaigns-overview-list-settings",
    },
    {
      name: "Delete",
      showDelete,
      onClickAction: () => {
        handleSystemGeneratedDelete();
      },
      id: "campaigns-overview-list-delete",
    },
    {
      name: "Set as recurring",
      showSetRecurring,
      onClickAction: () => {
        handleShowRecurringDialog(id);
      },
      id: "campaigns-overview-set-recurring",
    },
    {
      name: "Resume",
      showResume,
      onClickAction: () => {
        handleResumeRecurring();
      },
      id: "campaigns-overview-recurring-start",
    },
    {
      name: "Stop",
      showStop,
      onClickAction: () => {
        handleStopRecurring();
      },
      id: "campaigns-overview-recurring-stop",
    },
  ];

  const campaignMenu: ActionMenu = campaignMenuConfig.filter(
    (item) => mergedConditions[item.name]
  );

  return { campaignMenu, handlePreview };
}
