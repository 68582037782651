import EmptyState from "@/components/EmptyState";
import PageSize from "@/components/PaginationRouted/PageSize";
import TinyPagination from "@/components/PaginationRouted/Pagination";
import { Box, Theme } from "@mui/material";
import Search from "@tiny/icons/generated/Search";
import React, { useEffect, useState } from "react";
import {
  Await,
  useLoaderData,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Campaign, GetCampaignResponse } from "@/types/api/campaigns";
import CampaignTableHeader from "./CampaignTableHeader";
import CampaignTable from "./CampaignTable";
import { CalendarLoaderProps } from "../loader";

function CampaignTableContainer() {
  const { campaigns } = useLoaderData() as CalendarLoaderProps;
  const [selectedCampaigns, setSelectedCampaigns] = React.useState<Campaign[]>(
    []
  );
  const location = useLocation();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const isRecurringListView =
    location.pathname === "/main/campaigns/calendar/recurring/list";

  useEffect(() => {
    setSelectedCampaigns([]);
    setIsAllSelected(false);
  }, [page]);

  const renderListView = (awaitedData: GetCampaignResponse) => {
    if (awaitedData.campaigns.empty) {
      return (
        <EmptyState
          Icon={
            <Search
              sx={(theme: Theme) => ({
                color: theme.palette.grey[80],
                width: theme.spacing(6),
                height: theme.spacing(6),
              })}
            />
          }
          message="Please try again later or adjust your search criteria."
          title="No Campaign found."
        />
      );
    }

    return (
      <>
        <CampaignTable
          selected={selectedCampaigns}
          setSelected={setSelectedCampaigns}
          isAllSelected={isAllSelected}
          setIsAllSelected={setIsAllSelected}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          mt={2}
          sx={{
            backgroundColor: "common.white",
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            px: 6,
            py: 4,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "grey.50",
          }}
        >
          <PageSize />
          <TinyPagination pagesCount={awaitedData.campaigns.totalPages} />
        </Box>
      </>
    );
  };

  return (
    <React.Suspense>
      <Await resolve={campaigns}>
        {(awaitedData: GetCampaignResponse) => (
          <>
            <CampaignTableHeader
              selectedCampaigns={selectedCampaigns}
              setSelectedCampaigns={setSelectedCampaigns}
              setIsAllSelected={setIsAllSelected}
              showActions={!isRecurringListView}
              showDateRange={!isRecurringListView}
            />
            {renderListView(awaitedData)}
          </>
        )}
      </Await>
    </React.Suspense>
  );
}

export default CampaignTableContainer;
