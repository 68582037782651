import React, { ReactNode, useMemo, useState } from "react";
import { AlertColor } from "@mui/material";
import { ToastContext } from "./ToastContext";
import { Toast } from "./Toast";
import { ToastContextType } from "./ToastService.types";

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<AlertColor | string>("success");
  const [heading, setHeading] = useState<string>("");
  const [portalId, setPortalId] = useState<string>("");
  const [autoHideDuration, setAutoHideDuration] = useState<number>(5000);

  const value: ToastContextType = useMemo(
    () => ({
      open,
      message,
      type,
      autoHideDuration,
      heading,
      portalId,
      setPortalId,
      setHeading,
      setOpen,
      setMessage,
      setType,
      setAutoHideDuration,
    }),
    [open, message, type, autoHideDuration, heading, portalId]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Toast
        setOpen={setOpen}
        type={type}
        open={open}
        heading={heading}
        message={message}
        autoHideDuration={autoHideDuration}
        portalId={portalId}
      />
    </ToastContext.Provider>
  );
};
