import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

export interface MessageDialogProps {
  open: boolean;
  onClose: () => void;
  message?: string;
}

function MessageDialog(props: MessageDialogProps) {
  const { open, onClose, message } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography color="grey">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog;
